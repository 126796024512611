<div mat-dialog-title class="dialog-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>  
    <span id="action-title" style="color: #1A567A; align-self: flex-start;">{{template}}</span>
  </div>
  <div class="dialog-header-right">
      <mat-icon id="close-x" class="dialog-title"
	      (click)="closeDialog()">close</mat-icon>
   </div>
 </div>

<mat-dialog-content class="panel-class" style="margin-top: 1rem;">


  <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A; display: none"></div>

<!--    
    <form formGroupName="linkForm" (ngSubmit)="submitForm()" style="display: none">
      <div class="row">
      <mat-form-field appearance="outline">
         <mat-hint position="floating">Link Text</mat-hint> 
         <input matInput formControlName="title" [value]="linkTitle" type="text" required/>
      </mat-form-field>
      <span class="error ion-padding" *ngIf="isSubmitted && errorControl.title.errors?.required">
        Link text is required.
      </span>
      <span class="error ion-padding" *ngIf="isSubmitted && errorControl.title.errors?.minlength">
        Link text must be at least 1 or more characters
      </span>
      </div>      
      <div class="row">
      <mat-form-field appearance="outline">      
         <mat-hint position="floating">Link URL</mat-hint> 
         <input matInput formControlName="url" [value]="linkURL" type="url" required />
      </mat-form-field>
      </div>
      <div class="row">
      <span class="error ion-padding" *ngIf="isSubmitted && errorControl.title.errors?.required">
        Link URL is required.
      </span>
      <span class="error ion-padding" *ngIf="isSubmitted && errorControl.title.errors?.minlength">
        Invalid URL format
      </span>
      </div>
      <div class="row">
      <mat-form-field appearance="outline">
      <mat-select formControlName="groupid" [formControl]="linkGroupID" required>
           <option value="nes">NES</option>
           <option value="n64">Nintendo64</option>
           <option value="ps">PlayStation</option>
           <option value="genesis">Sega Genesis</option>
           <option value="saturn">Sega Saturn</option>
           <option value="snes">SNES</option>
      </mat-select>
      <mat-hint>Group</mat-hint>
      </mat-form-field>      
      </div>
      <div class="row">
      <mat-form-field appearance="outline">  
      <mat-select formControlName="docid" [formControl]="linkDocID" required>
           <option value="nes">NES</option>
           <option value="n64">Nintendo64</option>
           <option value="ps">PlayStation</option>
           <option value="genesis">Sega Genesis</option>
           <option value="saturn">Sega Saturn</option>
           <option value="snes">SNES</option>
        </mat-select>
        <mat-hint>Document</mat-hint>
      </mat-form-field>      
      </div>
  </form>
    -->
    
  <div class="left-content">
    <app-create-link
       #createLink
       [selectedGroupObj]="data.selectedGroupObj"
       [origin]="data.linkOrigin"
       [userInfo]="data.userInfo"
       [linkSrcData]="data.linkSrcData"
       [documentUID]="documentsvc.activeDocument"      
       [isDialog]="true"
       (templateChange)="templateChange($event)"
       (helpContextChange)="helpContextChange($event)"
       (documentDataChange)="documentDataChange($event)"            
       (markerDataChange)="markerDataChange($event)"
       (closeEvent)="saveDialog($event)"            
      >
     </app-create-link>
  </div>

</mat-dialog-content>
  
<mat-dialog-actions style="align: end">
  <!--
  <div *ngIf="template===templateLinkExternal">
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		  class="group-btn"
                  (click)="navCreateLink()"
                  matTooltip="Done">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button 
		    mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		    class="group-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createExternalLink();"
                    matTooltip="Done">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
  </div>
  <div *ngIf="template===templateLinkFile">
            <button mat-button 
		  class="link-btn"
                  (click)="navCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"		    
                    (click)="navLinkPage()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">file_open</mat-icon>
                    <div class="label-text">Add Page#</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="navLinkMarker()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">location_on</mat-icon>
                    <div class="label-text">Add Marker</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="navLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
  </div>
  <div *ngIf="template===templateLinkMarker">
            <button mat-button 
		  class="icon-btn"
                  (click)="navLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null || linkMarkerData === null"
                    (click)="navLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
  </div>

  <div *ngIf="template===templateLinkInternal">
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		  class="link-btn"
                  (click)="navCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button *ngIf="linkMarkerData!==null; else savePageLink"
	            mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createInternalMarkerLink()">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    <ng-template #savePageLink>
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createInternalPageLink()">		    
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    </ng-template>

  </div>
  --->
  
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.closeDialog()'>Cancel</button>
  
</mat-dialog-actions>    
