import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges,
         OnDestroy,  Input, Output, ViewChild, EventEmitter, ElementRef,
         ComponentRef } from '@angular/core';

import { ChangeDetectorRef } from '@angular/core';
import { NgFor, AsyncPipe} from '@angular/common';

// import { lastValueFrom } from 'rxjs';
import { Subscription, Subject, combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, tap } from "rxjs/operators";

import { SelectionModel } from '@angular/cdk/collections';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ToasterService } from '../../service/toaster.service';

// import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { AuthService } from '../../service/auth.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
import { OrgContactService } from '../../service/orgcontact.service';

import { GroupService } from '../../service/group.service';
import { UserService } from '../../service/user.service';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
// import { GroupData } from '../../model/groupdata.model';

import { OrgComponent } from '../org/org.component';
import { OrgUserComponent } from '../org-user/org-user.component';

import { OrgData } from '../model/orgdata.model';
import { OrgUserData } from '../model/orguserdata.model';
import { OrgUserInfo } from '../model/orguserinfo.model';
import { OrgContactData } from '../model/orgcontactdata.model';

import { SortKey } from '../../pipe/sortkey.pipe';
import { FilterKeys } from '../../pipe/filterkeys.pipe';
import { NameFilter } from '../../pipe/name-filter.pipe';

// import * as GRPLIST from './group-list.component'
import * as ORG from '../org/org.component'
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

@Component({
   selector: 'app-org-contact-list',
   templateUrl: './org-contact-list.component.html',
   styleUrls: ['./org-contact-list.component.css'],
})
export class OrgContactListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   ObjDisplayMode: typeof STATIC.Mode = STATIC.Mode;

   @Input()  mode = this.DisplayMode.SELECT;
   @Input()  iam = false;
   @Input()  showTitleFilter = true;
   @Input()  showSearchButton = true;
   @Input()  showSelectButton = true;
   @Input()  useMenuActionDialog = true;
   @Input()  showActions = true;
   @Input()  showAddButton = true;
   @Input()  showInfoButton = false;
   @Input()  showEditButton = false;
   @Input()  showDeleteButton = false;
   @Input()  showMenuButton = true;
   @Input()  showListViewButton = true;
   @Input()  template = this.DisplayTemplate.SELECT;
   @Input()  objUID: string;
   @Input()  userInfo: UserInfo;
   @Input()  userData: UserData;
   @Input()  selectedObj: any | null = null;
   @Input()  selectedOrg: OrgData | null = null;
   @Input()  objData: any[];
   @Input()  title: string;
   @Input()  titleFilter: string;
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   // @Output() getMembersEvent = new EventEmitter<any>();
   // @Output() addMemberEvent = new EventEmitter<any>();
   // @Output() removeMemberEvent = new EventEmitter<any>();

   @Output() refreshListEvent = new EventEmitter<any>();
   @Output() viewObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();

   @ViewChild(MatTable, {static: false})table: MatTable<any>;
   @ViewChild(MatSort, { static: false }) sort: MatSort;
   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
   @ViewChild('orgTable', {static: false}) orgTable: ElementRef;
   @ViewChild('orglist', {static: false}) orglist: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('orgListItem', {static: false}) grpListItem: OrgComponent;
   @ViewChild('orgFormItem', {static: false}) grpFormItem: OrgComponent;
   //@ViewChild('orgListItem', {static: false}) orgListItem: OrgComponent;
   //@ViewChild('orgFormItem', {static: false}) orgFormItem: OrgComponent;
   @ViewChild('autoCombo') public autoComplete: MatAutocomplete;
   @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

   // Fields & Labels
   objLabel = 'Company/Org. Contact';
   objField = 'org_contact_email';
   objField2 = 'user_name';
   objField3 = 'user_last_name';

   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;
   helpDialogRef: any = null;

   // Form
   comboForm: FormGroup;
   objNameInput = new FormControl('');
   orgSelectForm: FormGroup;
   formFieldStyle = 'fill';  // or outline
   viewOnly = false;
   showObjSelect = true;

   // Mode
   prevMode: string = null;

   // Combo Filter
   filteredOptions: Observable<any[] | null>;
   filteredOrgs: Observable<OrgData[]>;

   // Org and User Info
   objMode = this.ObjDisplayMode.LIST;
   selectedUser;
   selectedUserList: any[] = [];

   // Obj/Org Admin
   isObjAdmin = false;
   isOrgAdmin = false;

   // Form Data
   objSelectForm: FormGroup;
   tableForm: FormGroup;
   // orgControl = new FormControl(this.selectedOrg);

   // Table Data
   displayedColumns: string[];
   adminDisplayedColumns: string[];
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;
   searchText: string;

   // View vars
   toggleFiberOrgs = false;
   isOrgsVisible = false;
   filterValue: string;

   // Instance vars
   orgContactCount: number;
   orgContactList: OrgContactData[] = [];

   loading: boolean;

   // subscriptions
   orgListSubscription: any;
   groupListSubscription: any;
   // orgUserSubscription: any;

   constructor(
      public toast: ToasterService,
      private fb: FormBuilder,
      private auth: AuthService,
      private usersvc: UserService,
      public groupsvc: GroupService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public orgcontactsvc: OrgContactService,
      public changeDetectorRef: ChangeDetectorRef,
      public formDialog: MatDialog,
      public helpDialog: MatDialog,
      ) {
      } // constructor

   async ngOnInit(): Promise<void> {
      // console.log('org-user-list called.');
      this.userData = this.auth.getTokenInfo();

      if (! this.userData) {
        console.error('OrgContactListComponent WARNING userData not defined.');
      }

      // if (! this.userInfo) {
      //  console.error('OrgContactListComponent WARNING userInfo not defined.');
      // }

      console.log('OrgContactListComponent selectedOrg=', this.selectedOrg);

      this.refreshList();

      if ( !this.template ) {
         this.template = this.DisplayTemplate.SELECT;
      }

      this.objNameInput = new FormControl();
      this.comboForm = new FormGroup( {
         objNameInput: new FormControl()
      });

      if (this.selectedObj) {
         // console.log('org-contact-list afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      }
      if (this.objData) {
         this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);      
      }
      if (this.selectedOrg) {
        this.isObjAdmin = await this.getIsOrgAdmin(this.selectedOrg, this.userData);
      }

      // Subscribe to action observables
      //
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           console.log('org-contact-list onInit doReset v=', v);
           if ( v === true ) {
             // this.submitObj();
             // this.doSubmit = false;
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             // this.resetObj();
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           if ( v === true ) {
             // this.editObj();
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             this.deleteObj();
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           console.log('org-tab onInit doView v=', v);
           if ( v === true ) {
             // this.viewObj();
           }
        });
      }
   }

   async ngAfterViewInit(): Promise<void> {
      // this.refreshOrgList();
      this.userData = this.auth.getTokenInfo();

      if (this.selectedObj) {
       this.setSelectedObj(this.selectedObj);
      }

      //console.log('group-list _auto=',
      //             this._auto.autocomplete.options.toArray());

   }

   async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      console.log('org-contact-list changes=', changes);
      if ( changes.selectedOrg ) {
         this.selectedOrg = changes.selectedOrg.currentValue;
         this.isOrgAdmin = await this.getIsOrgAdmin(this.selectedOrg, this.userData);
         this.isObjAdmin = this.isOrgAdmin;
         console.log('org-contact-list selectedOrg change=', this.selectedOrg);
         console.log('org-contact-list selectedOrg change isObjAdmin=', this.isObjAdmin);
         this.refreshList();
      }
      if ( changes.selectedObj )  {
         this.setSelectedObj(changes.selectedObj.currentValue);
         // this.isObjAdmin = await this.getIsObjAdmin(this.selectedObj, this.userData);    
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
         this.setMode(this.mode);
      }
      if ( changes.titleFilter ) {
         this.applyTableFilter( changes.titleFilter.currentValue );
      }
   }

   applyTableFilter(val) {
     if ( this.dataSource && val ) {
        this.dataSource.filter = val.trim().toLowerCase();
     }
   }

   clearTableFilter(val) {
     if (this.dataSource) {
        this.dataSource.filter = '';
     }
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     if( this.orgListSubscription ) {
        this.orgListSubscription.unsubscribe();
     }
     if( this.groupListSubscription ) {
        this.groupListSubscription.unsubscribe();
     }
     /***
     if( this.doSubmit$ ) {
        this.doSubmit$.unsubscribe();
     }
     if( this.doEdit$ ) {
        this.doEdit$.unsubscribe();
     }
     if( this.doDelete$ ) {
        this.doDelete$.unsubscribe();
     }
     if( this.doReset$ ) {
        this.doReset$.unsubscribe();
     }
     ****/
     console.log('group-list ondestroy');
   }

   private _filter(value: string): OrgData[] {
     return this.orgsvc.OrgList.filter(obj =>
       obj[this.objField].toLowerCase().includes(value.toLowerCase())
     );
   }

   private _filterOrgNames(value: string): any[] {
     // if ( value == '' ) { return this.orgsvc.OrgList }
     // console.log('filter org names filterValue =', filterValue);
     // const newlist = this.orgsvc.OrgList;
     // const olist = newlist.filter (o => {
     //   if (o.org_contact_email.toLowerCase().includes(filterValue)) {
     //    return o;
     //   }
     // });
     // console.log('filter olist = ', olist );
     // return olist;
     // if( value && value !== null) {
     //   if (value == '')
     //     { return new Array<OrgData>(); }
        const filterValue = (''+value).toLowerCase();
        if ( this.orgcontactsvc.OrgContactList ) {
           return this.orgcontactsvc.OrgContactList.filter(o => o[this.objField].toLowerCase().includes(filterValue));
        } else {
             return null;
          }
     //}
   }

   clearComboInput() {
      this.objNameInput.patchValue('');
   }

   setComboOption(uid: string) {
      const opts = this._auto.autocomplete.options.toArray();
      if ( opts ) {
        const item = opts.find( o => o.value.uid === uid );
        if (item) {
          this.objNameInput.setValue(item.value);
        }
      }
   }

   async refreshList() {
     this.isObjAdmin = false;
     
     if (this.selectedOrg) {
       this.isObjAdmin = await this.getIsOrgAdmin(this.selectedOrg, this.userData);
     } 

     this.setMode(this.mode);

     console.log('org-user-list refreshList...');

      if ( this.userData && this.userData.iam && this.iam) {
         console.log('App Mgr get org users...');
         if ( this.mode === this.DisplayMode.TABLE ) {
            this.refreshObjTable();
         } else if (this.selectedOrg) {
                  console.log('org-list refresh get all orgs...');
                  this.orgcontactsvc.getOrgContactList$( this.selectedOrg.uid );
              }
      } else {
            console.log('Get org users...');
            if ( this.mode === this.DisplayMode.TABLE ) {
                this.refreshObjTable();
            } else if (this.selectedOrg) {
                  this.orgcontactsvc.getOrgContactList$( this.selectedOrg.uid );
              }
        }
   }

   createForm() {
      this.orgSelectForm = this.fb.group({
               org_select_field: this.viewOnly ?
                                 [{value: '', disabled: true}] :
                                 [''] });
   }

   setForm() {
     if ( this.objSelectForm && this.selectedObj ) {
        this.objSelectForm.patchValue(
           { obj_select_field: this.selectedObj ? this.selectedObj.uid : null
           }
        );
     }
   }

   async refreshObjTable() {
      if ( this.userData && this.userData.iam && this.iam) {
          this.orgContactList = await this.orgcontactsvc.getOrgContactListPromise( this.selectedOrg.uid );
      } else {
          this.orgContactList = await this.orgcontactsvc.getOrgContactListPromise( this.selectedOrg.uid );
        }

      this.dataSource = new MatTableDataSource<OrgContactData>(this.orgcontactsvc.OrgContactList);

      if ( this.paginator ) {
         this.dataSource.paginator = this.paginator;
      }
      if ( this.sort ) {
         this.dataSource.sort = this.sort;
      }
   }

   setTemplate(t) {
      this.template = t;
      console.log('setTemplate t=', t);
   }

   setPrevMode() {
      if ( this.prevMode !== null ) {
          this.setMode( this.prevMode );
      }
   }

   toggleShowObjSelect() {
      this.showObjSelect = !this.showObjSelect;
   }

   setMode(m) {
      this.prevMode = this.mode;
      this.mode = m;
      console.log('setMode m=', this.mode);

      /***
      if ( this.userData && this.userData.iam) {
         this.displayedColumns = ['org_client_nbr', 'org_contact_email', 'org_type', 'org_contact_email', 'org_actions', 'org_selector'];
         // this.displayedColumns = ['org_client_nbr', 'org_contact_email', 'org_type', 'org_contact_email', 'org_status'];
      } else {
         this.displayedColumns = ['group_name', 'org_contact_email'];
        }
      ***/

      this.displayedColumns = ['org_contact_email', 'org_contact_type', 'org_contact_phone', 'obj_actions', 'obj_selector'];

      this.modeEvent.emit(this.mode);

      switch (this.mode) {
         case this.DisplayMode.SELECT: {
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.COMBO: {
            // this.selectedObj = null;
            this.template = this.DisplayTemplate.COMBO;
            this.objMode = this.ObjDisplayMode.COMBO;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.EXPAND_LIST: {
            this.template = this.DisplayTemplate.EXPAND_LIST;
            this.objMode = this.ObjDisplayMode.EXPAND_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         case this.DisplayMode.SELECT_LIST: {
            this.template = this.DisplayTemplate.SELECT_LIST;
            this.objMode = this.ObjDisplayMode.SELECT_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.LIST: {
            this.template = this.DisplayTemplate.LIST;
            this.objMode = this.ObjDisplayMode.LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.VIEW: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.VIEW;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.EDIT: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.EDIT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.ADD: {
            this.showObjSelect = false;
            this.selectedObj = null;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.ADD;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            //console.log('GRPLIST ADD mode=', this.mode);
            //console.log('GRPLIST ADD template=', this.template);
            //console.log('GRPLIST ADD orgMode=', this.objMode);
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.TABLE || 'T': {
            this.template = this.DisplayTemplate.TABLE;
            this.objMode = this.ObjDisplayMode.TABLE;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         case this.DisplayMode.DELETE || 'DEL': {
            this.template = this.DisplayTemplate.DELETE;
            this.objMode = this.ObjDisplayMode.DELETE;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         default: {
            console.error('Error org-list default mode=', this.mode);
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = true;
            this.showMenuButton = true;
            this.showSelectButton = false;
            break;
         }
      }
      console.log('this.mode=', this.mode);
      console.log('this.template=', this.template);
      console.log('this.objMode=', this.objMode);
  }

  /****
  onOrgNameChange(evt) {
      // evt.target.value
      let org = null;
      org = this.orgsvc.OrgList.find(o => {
          let obj = null;
          if (o[this.objField].toLowerCase() === evt.target.value.toLowerCase()) {
              obj = o;
          }
          return obj;
      });
      console.log('orgNameChange orgSelectionChange evt=', evt.target.value);
      console.log('orgNameChange orgSelectionChange org=', org);
      if (org) {
          // this.objNameInput.patchValue(org);
      } else {
          this.setSelectedObj(null);
      }
  }
  ****/

  toggleChecked(o) {
     console.log('org-list toggle checked e=', o);
     if ( this.selectedObj && this.selectedObj.uid === o.uid ) {
         this.setSelectedObj(null);
     } else {
          this.setSelectedObj(o);
       }
  }

  onSelectionChange(evt) {
      console.log('group-list grpSelectionChange evt=', evt);
      this.setSelectedObj(evt.value);
  }

  setSelectedObjEvent(event) {
      console.log('org-user-list setSelectedObjEvent called event=', event);
      this.setSelectedObj(event.option.value);
      this.selectObjEvent.emit(event.option.value);
  }

  async setSelectedObj(e) {
      console.log('org-user-list setSelectedObj e=', e);

      if ( e === "NONE" ) {
         this.selectedObj = null;
      } else {
           this.selectedObj = e;
           this.setForm();
        }
      this.selectObjEvent.emit(e);

      if ( this.objNameInput && this.selectedObj ) {
        this.objNameInput.patchValue(this.selectedObj.org_contact_email);
        // this.comboForm.patchValue({objNameInput: this.selectedObj[this.objField]});
        console.log('patched value for objNameInput=', this.selectedObj[this.objField]);
      } else {
           this.objNameInput.patchValue('');
           if ( this.comboForm ) {
             this.comboForm.patchValue({objNameInput: ''});
           }
           console.log('patched EMPTY value for objNameInput');
        }

      if ( this.mode == this.DisplayMode.SELECT ) {
         this.template = this.DisplayTemplate.SELECT;
      }
      if ( this.grpFormItem ) {
         this.grpFormItem.setObjData(this.selectedObj);
      }
      // this.isObjAdmin = await this.getIsObjAdmin(this.selectedObj, this.userData);
      // this.isOrgAdmin = this.isObjAdmin;
  }

  nextObj() {
    let idx = 0;

    // console.log('next: value = ', this.orgsvc.getOrgListData());

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid == this.selectedObj.uid) {
                                   return true;
                                }
       })
       console.log('nextObj current idx=', idx);
       console.log('nextObj selected=', this.selectedObj);
    }
    if ( idx >= 0 && (idx+1) < this.groupsvc.getUserGroupList().length ) {
       this.setSelectedObj(this.orgsvc.OrgList[idx+1]);
       console.log('new selectedObj =', this.selectedObj);
    }
  }

  prevObj() {
    let idx = 0;

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid === this.selectedObj.uid) {
                                   return true;
                                }
       })
       console.log('prevOrg current idx=', idx);
    }
    if ( idx >= 0 ) {
       if ( idx === 0 ) {
           this.selectedObj = null;
       } else {
           this.setSelectedObj(this.orgsvc.OrgList[idx-1]);
         }
    }
  }

  viewObj() {
     console.log('view obj.');
     this.viewObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.VIEW);
     this.doView$.next(true);
  }

  resetObj() {
     // console.log('reset obj.');
     // this.viewObjEvent.emit(this.selectedObj);
     // this.setMode(this.DisplayMode.VIEW);
     this.doReset$.next(true);
  }

  createObj() {
     console.log('org-contact-list create obj.');
     this.createObjEvent.emit(this.selectedObj);
     if (this.useMenuActionDialog) {
        this.showActions = false;     
        this.openFormDialog( this.DisplayModel.ORG_CONTACT, this.DisplayMode.ADD, this.objData);        
     } else {
          this.setMode(this.DisplayMode.ADD);
       }
  }

  editObj() {
     this.objData = this.selectedObj;
     this.selectObjEvent.emit(this.objData);
     this.editObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.EDIT);
     console.log('org-user-list edit obj. objMode=', this.objMode);
     console.log('org-user-list edit obj. objData=', this.objData);
     console.log('org-user-list edit obj. selectedObj=', this.selectedObj);
     console.log('org-user-list edit obj. selectedOrg=', this.selectedOrg);
     console.log('org-user-list edit obj. showActions=', this.showActions);
     console.log('org-user-list edit obj. useMenuActionDialog=', this.useMenuActionDialog);
     // this.doEdit$.next(true);
     if (this.useMenuActionDialog) {
        this.showActions = false;
        this.openFormDialog( this.DisplayModel.ORG_CONTACT, this.DisplayMode.EDIT, this.objData);       
     } else {
          this.setMode(this.DisplayMode.EDIT);
       }
  }

  deleteObj() {
     console.log('delete obj.');
     this.deleteObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.DELETE);
     this.doDelete$.next(true);
  }

  submitObj() {
    this.doSubmit$.next(true);
  }

  onDelete(e) {
     if ( this.selectedOrg && this.selectedOrg.uid ) {
       this.orgcontactsvc.getOrgContactList$(this.selectedOrg.uid);
     }
     this.toast.pop('success', 'Delete Company/Org. Contact', 'Deleted Company/Org Contact ' +  e.org_contact_email);
  }

  onDeleteError(e) {
     if ( this.selectedOrg && this.selectedOrg.uid ) {
       this.orgcontactsvc.getOrgContactList$(this.selectedOrg.uid);
     }
     this.toast.pop('error', 'Error Deleting Company/Org. Contact', 'Error Deleting Company/Org Contact ' +  e.name);
  }

  onSubmit(obj: any) {
    console.log('org-contact-list onSubmit obj=', obj);
    this.showObjSelect = true;
    this.selectedObj = obj;
    this.refreshList();
    // this.setPrevMode();
    this.setMode(this.DisplayMode.SELECT_LIST);
    if ( this.objNameInput ) {
       this.objNameInput.patchValue( obj[this.objField] );
    }
    // this.doneEvent.emit(this.selectedObj);
    this.selectObjEvent.emit(this.selectedObj);
    console.log('org-contact-list onSubmit selectedObj=', this.selectedObj);
  }

  onCancel() {
     console.log('group-list onCancel event.');
     this.showObjSelect = true;
     this.setPrevMode();
  }

  onDone() {
     console.log('org-user-list onDone event.');
     this.doneEvent.emit(this.selectedObj);
     this.showObjSelect = true;
     this.setPrevMode();
  }

  getOptionName(o) {
     console.log('org-list getOptionName o=', o);
     const name = o ? o[this.objField] : undefined;
     /****
     let owns = '';
     const star = '&#9733';
     if (this.userData && this.userData.uid && o && o.owner &&
         this.userData.uid===o.owner) {
         owns = star;
     }
     ****/
     console.log('org-list getOptionName name=', name);
     return name;
  }

  /***
  displayGroupName(grp){
     if(grp) {
        return grp.name;
     }
  }
  ***/

  /***
  updateLocalItem(newItem){
    //let idx = this.objList.findIndex(obj => obj.uid === newItem.uid);
    //this.objList[idx] = newItem;
    //this.objList = Object.assign([], this.objList);
  }
  ***/

  updateItem(newItem) {
     this.groupsvc.updateItem(newItem);
     this.setSelectedObj(newItem);
  }

  compareObjs(o1: any, o2: any): boolean {
     if ( o1 == null || o2 == null )
        { return false; }
     const result = o1.uid === o2.uid;
     // console.log('compare objs result=', result);
     return result;
  }

  async getIsObjAdmin(obj: any, uData: UserData) {
     let obj_admin = false;
     if (obj && 'org_uid' in obj && uData && 'uid' in uData) {
        const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(obj.org_uid, uData.uid);
        console.log('org-contact-list orgusr=', orgusr);
        if ( orgusr && ('org_admin' in orgusr) && orgusr.org_admin ) {
            obj_admin = true;
        } else {
            obj_admin = false;
          }
     }
     if ( uData.iam && this.iam ) {
        return true;
     }
     console.log('org-contact-list isObjAdmin=', obj_admin);   
     return obj_admin;
  }

  async getIsOrgAdmin(org: any, uData: UserData) {
     let org_admin = false;
     if (org && 'uid' in org && uData && 'uid' in uData) {
        const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(org.uid, uData.uid);
        console.log('org-contact-list orgusr=', orgusr);	
        if ( orgusr && ('org_admin' in orgusr) && orgusr.org_admin ) {
            org_admin = true;
        } else {
            org_admin = false;
          }
     }
     if ( uData.iam && this.iam ) {
        org_admin = true;
     }
     console.log('org-contact-list isOrgAdmin isObjAdmin=', org_admin);     
     return org_admin;
  }

  // Table Pagination Change
  onTableChangePage(pe:PageEvent) {
    console.log(pe.pageIndex);
    console.log(pe.pageSize);
  }

  // Table manipulation functions
  //
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue;
    console.log('ObjListComponent filterValue=', filterValue);
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(): void {
      // console.log('OLC orglist = ', this.orglist);
      const md = this.orglist.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(): void {
       const md = this.orglist.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       // const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

   openFormDialog(model, mode, obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Company/Org. Contact';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View ' + this.objLabel;
              break;
        case this.DisplayMode.ADD:
              title = 'Create ' + this.objLabel;
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit ' + this.objLabel;
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete ' + this.objLabel;
              break;
      }

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_CONTACT,
         objMode: mode,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         selectedOrg: this.selectedOrg,
         // selectedOrgUser: this.selectedOrgUser,
         // selectedOrgContact: this.selectedObj,
         /***
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);

      // Close event
      // this.formDialogRef.componentInstance.closeEvent.subscribe(
      //    this.refreshList();
      // );

      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               console.log('org-contact-list formdialog refresh...');
               this.refreshList();
            }, 1100);
          } // data
      );
    } // openFormDialog


} // org-user-list
