// 56-248 CJ - convert, merge and upload files dialog
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, Inject,
         EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { FormBuilder, FormGroup } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';
import { map } from 'rxjs/operators';

import { ToasterService } from '../service/toaster.service';

import { DocumentService } from '../service/document.service';

import { environment } from '../../environments/environment';

import { OrgModule } from '../org/org.module';
import { GroupModule } from '../group/group.module';
import { GroupData } from '../model/groupdata.model';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { DocumentData } from '../model/documentdata.model';
import { ImageSettings } from '../model/imagesettings.model';
import { FileSelector } from '../model/fileselector.model';

import * as FSC from '../model/fileselector.constants';
import * as HELP from '../service/help.service';
import * as STATIC from '../mdtools/statics.common';

import moment from 'moment';

import { FormDialogComponent } from '../dialog/form/form.dialog';
import { ImageSpecDialogComponent } from '../dialog/image-spec/image-spec.dialog';

import { PDFDocument, PDFPage, PDFImage, popGraphicsState, pushGraphicsState,
         concatTransformationMatrix, degrees } from 'pdf-lib';

export interface UploadFilesData {
    groupList: any;
    organization: any;
    owner: any;
    group: any;
    imagesize: number;
    imagerotation: number;
    addmargins: boolean;
    userInfo: any;
}

export const ERR_OK         =  0;
export const ERR_FILE_TYPE  = -1;
export const ERR_EMBED_FILE = -2;

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})

export class UploadFilesComponent implements OnInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   @Input() groups: GroupData[];
   @Input() userData: UserData;   
   @Input() userInfo: UserInfo;
   @Input() selectedOrganization: any;
   @Input() selectedOrgUnit: any;
   @Input() selectedGroup: GroupData | null;
   @Input() selectedImageSize: any;
   @Input() selectedImageRotation: any;
   @Input() selectedAddMargins: any;

   @Output() updateDocListEvent = new EventEmitter<any>();
   @Output() createGroupEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() helpEvent = new EventEmitter<any>();
   @Output() backEvent = new EventEmitter<any>();
   @Output() saveEvent = new EventEmitter<any>();
   @Output() closeEvent = new EventEmitter<any>();

//    @ViewChild(AddDocumentComponent, {static: true}) add;

    documents: DocumentData[];

    // passed in from parent
    // userInfo: any;
    // groups: any;

// CJ - pdf-lib variables pdfFile, pdfDoc - pdf-lib converter/editor
    pdfFile: any;
    pdfDoc: any;
    pdfDims: any;
    pdfPage: any;
    pdfImage: any;
    pdfImageBytes: any;
    pdfCopiedPages: any;
    uploadProgress = {
        status: 'progress',
        message: 0
    };

// CJ - showUpload used for multi-select & merge
    showUpload      = 'none';
    showMultiFile   = 'none';
    showImageForm   = 'none';
    showMergeBtn    = false;
    showImageBtn    = false;
    disableUploadBtn = false;
// CJ - Move the input type=file in data info FileInfo format and
// persist in fileData array now replacing fileNames so it can be used in
// conversions and the new file uploader
    sendUploadCnt = 0;
    sendErrCode = 0;
    sendErrMsg = '';
    sendDocUID: any = null;
    sendDocObj: DocumentData = null;
    inputFiles: any;
    fileData: FileSelector[];  // selected file names
    mergeFileName   = 'markadoc-merge.pdf';
    uploadBtnTitle  = 'UPLOAD FILE';
    uploadFileNames = '';
    uploadMessage   = '';
    completed = false;

    selectedAddMarginsText = 'Off';
    selectedImageSizeText = 'default';
    selectedImageRotationText = 'none';

  constructor(
      // private fb: FormBuilder,
      private http: HttpClient,
      private toast: ToasterService,
      private documentsvc: DocumentService,
      private imgDialog: MatDialog,
      private formDialog: MatDialog,      
      private imgDialogRef: MatDialogRef<ImageSpecDialogComponent>,
      private formDialogRef: MatDialogRef<FormDialogComponent>,      
      ) {
          this.reset();
          // console.log('uploadfiles groups=', data.groupList);
          // console.log('uploadfiles imgsize=', data.imagesize);
          if (this.selectedAddMargins) {
            this.selectedAddMarginsText = 'On';
          } else {
              this.selectedAddMarginsText = 'Off';
            }
         }

  ngOnInit() {
     console.log('upload files called. selectedGroup=', this.selectedGroup);
  }

  reset() {
     this.uploadMessage = '';
     this.selectedImageSize = FileSelector.getDefaultImageSize();
     this.selectedImageRotation = FileSelector.getDefaultImageRotation();
     this.selectedImageSizeText = FileSelector.getDefaultImageSizeText();
     this.selectedImageRotationText = FileSelector.getDefaultImageRotationText();
     this.selectedAddMargins = FileSelector.getDefaultImageAddMargins();
  }

  // CJ - Send message to main to updateDocumentList()
  sendUpdateDocList(d) {
      // Send updateDocumentList  event to any subscribers
      this.updateDocListEvent.emit(d);
  }

  saveUploadDialog() {
      const output = {
                     errCode:  this.sendErrCode,
                     errMsg:   this.sendErrMsg,
                     docUID:   this.sendDocUID,
                     docObj:   this.sendDocObj,
                     uploadCnt: this.sendUploadCnt
                   };

      // console.log('saveUploadDialog component output=', output);
      this.reset();
      // this.dialogRef.close(output);
      // this.saveEvent.emit(output);
      // console.log('upload component saveEvent emit output=', output);
      this.saveEvent.emit(output);
      this.completed = true;
      }

  closeUploadDialog() {
     this.reset();
     // this.dialogRef.close();
     this.closeEvent.emit('close');
  }

  goBack() {
     this.backEvent.emit('back');
  }

  toggle_multiFile() {
      if (this.showMultiFile === 'block') {
        this.showMultiFile = 'none';
      } else {
        this.showMultiFile = 'block';
      }
      document.getElementById('merge-files-container').style.display = this.showUpload;
    }

// 46-248 CJ - New dialog to upload, convert, merge forms

    groupChange(g) {
       console.log('group selected = ', g);
       this.selectGroupEvent.emit(g);
       this.selectedGroup = g;
    }

    async helpDialog() {
        this.helpEvent.emit(HELP.UPLOAD_CONTENT);
    }

    async createGroupDialog() {
        this.createGroupEvent.emit(true);
    }

    async imageSpecDialog(i, fselect, sz, rot, m) {
        const dialogConfig        = new MatDialogConfig();
        let fname = '';
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '50vw';
        dialogConfig.maxWidth     = '95vw';
        dialogConfig.maxHeight    = '99vh';

        if (fselect) {
          fname = fselect.file.name;
        }

        dialogConfig.data         = {
            fileName:   fname,
            fs:         fselect,
            idx:        i,
            size:       sz,
            rotation:   rot,
            margins:    m
        };
        this.imgDialogRef = this.imgDialog.open(ImageSpecDialogComponent
                                                     , dialogConfig);

        // Callback after individual settings change in dialog
        // this.imgDialogRef.componentInstance.updateSettings.subscribe(
        //    data => {
        //    } // data
        // );

        // Callback after save from dialog
        this.imgDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                 if (data.idx === -1) {
                    this.selectedImageSize     = data.size;
                    this.selectedImageRotation = data.rotation;
                    this.selectedImageSizeText = data.stext;
                    this.selectedImageRotationText = data.rtext;
                    this.selectedAddMargins = data.margins;
                    const x = FileSelector.getImageSizes();
                    // console.log(x);
                    for (const f of this.fileData) {
                       f.setImageSize(data.size);
                       f.setImageRotation(data.rotation);
                       f.setAddMargins(data.margins);
                       // this.updateImg(f, data.rotation);
                    }
                    if (this.selectedAddMargins) {
                       this.selectedAddMarginsText = 'On';
                    } else {
                        this.selectedAddMarginsText = 'Off';
                     }
                } else {
                         // console.log('data.idx=', data.idx);
                         // console.log('fdata=', this.fileData[data.idx]);
                         this.fileData[data.idx].imageSize = data.size;
                         this.fileData[data.idx].setImageRotation(data.rotation);
                         this.fileData[data.idx].setAddMargins(data.margins);
                         // this.updateImg(this.fileData[data.idx], data.rotation);
                }
             }
            } // data
        );
   }

// 56-248 CJ - Handlers for file selection, conversion, merge and uploads

   // File selector list drop event
   async fileDrop(event: CdkDragDrop<string[]>) {
      // console.log('fileDrop before=', this.fileData);
      // console.log('file drop=', event);

      moveItemInArray(this.fileData, event.previousIndex, event.currentIndex);
      // console.log(this.fileData[event.currentIndex]);
      // console.log('fileDrop after=', this.fileData);
      // console.log("fileDrop image=", this.filedata[event.currentIndex].imgURL);
   }

   // CJ - Gets one or more files returned by the UI file input and moves
   // the file objects to an array that can be manipulated in the UI
   // selector
   async getSelectedFiles(fList: any) {
       // console.log('getSelectedFiles fList=', fList);
       // reset count of file uploads
       this.sendUploadCnt = 0;

       this.inputFiles = fList;
       if (fList.target.files.length <= 1) {
         this.showMergeBtn = false;
       }
       else {
          this.showMergeBtn = true;
       }

       this.fileData  = [];
       this.showImageBtn = false;

       for (const f of this.inputFiles.target.files) {
          const buf = await f.arrayBuffer();
          const fs = await new FileSelector(f);
          fs.imageSize = this.selectedImageSize;
          fs.imageRotation = this.selectedImageRotation;
          fs.addMargins = this.selectedAddMargins;
          fs.fileName = f.name;
          fs.fileType = f.type;
          console.log('file type=', f.type);

          // 329 fix must be PDF or Image type
          const b2 = buf.slice( 0, 1024 );
          // Check the PDF file header
          const validPDF = fs.isValidPDF(b2);
          console.log('Valid PDF file = ', validPDF);
          if ((fs.isPDF() && validPDF) || fs.isImage()) {
             this.fileData.push(fs);
          } else {
              // Dont push the file onto the list if error in type.
              this.toast.pop('error',
                             'Error: Bad File Type Selected',
                             f.name + ' is not a valid file type.',
                             5000 );
            }
          if (fs.isImage()) {
            this.showImageBtn = true;
            // console.log('ufd fs.imgURL=', fs.imgURL);
          }
       }
       // CJ - For multi-file upload function.
       if (this.fileData.length > 1) {
          this.uploadBtnTitle = 'UPLOAD ALL FILES';
       } else {
           this.uploadBtnTitle = 'UPLOAD FILE';
         }
       // console.log('getSelectedFiles this.fileData=', this.fileData);
       // console.log('showMerge=', this.showMergeBtn);
       // console.log('showImage=', this.showImageBtn);
   }

   // Handles the delete button on the selected files list
   removeSelectedFile(fs: FileSelector) {
       const idx = this.fileData.indexOf(fs);
       this.fileData.splice(idx, 1);
       if (this.fileData.length === 1) {
          this.showMergeBtn = false;
       }
       if (!this.fileData && this.fileData.length === 0) {
          this.showImageBtn = false;
       }
   }

   // CJ - Handles Upload Files buttons with no document merging.
   async handleFileUploads() {
      const debug = false;
      this.showMergeBtn = false;
      this.disableUploadBtn = true;

      // CJ - Must verify group is defined, not null, exists first!!
      if (! this.selectedGroup ) {
         this.toast.pop('error', 'ERROR!',
         'Please Select or Create a Group First.');
         return;
      }
      // Make sure filenames are less than 64 characters
      if (this.fileData.filter((f) => f.fileName.length > 63).length) {
         this.toast.pop('error', 'ERROR!',
         'Filename is longer than 64 characters.');
         return;
      }

      // Upload the all the files in the this.fileNames array starting
      // with the first file.
      await this.uploadFiles();
      if (debug) {console.log('handleFileUploads fileData=', this.fileData); }

      // reset inputFileList and fileNames
      this.inputFiles  = null;
      this.disableUploadBtn = false;
   }

   async doUpload(apiInput, idx) {
        const fs = this.fileData[idx];
        return await this.http.post(environment.apiUrl + '/upload', apiInput, {
           reportProgress: true,
           observe: 'events'
        })
        .pipe(map( (event) => {
                let progress = 0;
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        progress = Math.round(100 * event.loaded / event.total);
                        fs.fileProgress = progress;
                        this.uploadProgress.message = progress;
                        if (progress === 100) {
                           document.getElementById('merge-status-' + idx).innerHTML = 'done';
                        }
                        console.log('upload event progress completed.');
                        return DocumentData;
                    case HttpEventType.Response:
                        // console.log('upload event response completed.', event);
                        return event.body;
                    default:
                        console.log(`Unhandled event: ${event}`);
                        console.log('event: ', event);
                        return DocumentData;
                }

            }
        ))
        .toPromise()
        .then(
            (x: DocumentData) => {
                if (this.uploadProgress.message === 100) {
                    // TODO will need to do this on return.
                    // Hide this.uploadProgress this.sendUpdateDocs(x);
                }
                this.sendUpdateDocList(x);
                this.sendUploadCnt++;
                let uid = null;
                if ('uid' in x) {
                   uid = x.uid;
                   // If this is the last file in the list set variables
                   // and close the dialog.
                   if (idx === (this.fileData.length - 1)) {
                      // this.fileData=null;
                      this.sendErrCode = 0;
                      this.sendErrMsg = '';
                      this.sendDocUID = x.uid;
                      this.sendDocObj = x;
                      // console.log('doUpload fileData.length=', this.fileData.length);
                      // console.log('doUpload idx=', idx);
                   }
                }
                // console.log('upload subscribe completed.');
                return uid;
            },
            (e) => { console.log('ERROR: ', e); }
        );
   }

    async doTimestamp(docuid) {
    // since python is not converting input timestamp right now touch the file
    // timestamp.
       await this.http.post(environment.apiUrl + '/document/id/' + docuid, {timestamp: 'update'})
                 .toPromise()
                 .then(
                    (x) => console.log(x),
                    (e) => console.log(e)
                 );
   }

   // CJ - changed to async to allow await with promise
   async uploadFiles() {

      if (! this.selectedGroup ) {
        this.toast.pop('error', 'ERROR!',
        'Please Select or Create a Group First.');
        return;
      }

      let code = ERR_OK;

      for ( let idx = 0; idx < this.fileData.length; idx++ ) {

         const fs = this.fileData[idx];
         // Check for valid MIME type on the file...
         if ( fs.isPDF() === false && fs.isImage() === false ) {
           this.toast.pop('error',
                          'ERROR: Bad File Type Detected (ignoring file)',
                          fs.fileName +
                          ' is not a valid file type (index=' + idx + ')',
                          4000 );
           // return to for with continue..
           continue;
         }
         // Convert the file if necessary
         code = ERR_OK;
         try {
            code = await this.convertFile(fs);
         } catch (e) {
             this.toast.pop('error',
                         'ERROR: Bad File Type Detected (ignoring file)',
                         fs.fileName +
                         ' cannot be converted to PDF (index=' + idx + ')',
                         4000 );
             continue;
         }
         // Also check the return code
         if ( code !== ERR_OK ) {
            this.toast.pop('error',
                         'ERROR: Bad File Type Detected (ignoring file)',
                         fs.fileName +
                         ' cannot be converted to PDF (index=' + idx + ')',
                         4000 );
            continue;
         }

         const blob = fs.uploadBlob;
         const fname = fs.fileName;
         const ftype = fs.fileType;
         // Set status and message
         this.uploadMessage = 'Uploading file ' + fname + '...';
         fs.fileProgress = 0;
         this.uploadProgress.message = 0;
         // console.log('uploadFiles blob=', blob);
         // window.alert('File Upload: fname=' + fname + ' type=' + ftype );
         const groupid  = this.selectedGroup.uid;
         //#### ####### NEED TO ADD ORG_UID AS OPTION HERE ##########	 
         const orgid  = this.selectedGroup.org_uid ? this.selectedGroup.org_uid : null;
         const apiInput = new FormData();
         apiInput.append('file', blob, fname);
         apiInput.append('group_uid', groupid);
         apiInput.append('org_uid', orgid);	 
         apiInput.append('users_uid', this.userInfo.uid);
         apiInput.append('owner', this.userInfo.email);
         // const dt = new Date().now();
         // const mstimestamp = Date.now();
         const mstimestamp = moment().unix();
         apiInput.append('timestamp', mstimestamp.toString());
         // console.log('uploadFile name=', fname);

         code = ERR_OK;
         try {
            this.sendDocUID = await this.doUpload(apiInput, idx);
            // Hack to set the document timestamp if python is not fixed.
            // this.doTimestamp(this.sendDocUID);
            console.log('uploadfiles docid=', this.sendDocUID);
            this.documentsvc.getDocumentList();
            const wait = 250;
            setTimeout(() => {
              console.log('Waited for next upload: ', wait);
              this.sendUploadCnt++;
            }, wait);
         } catch (e) {
             console.log('File upload error=', e);
         }
      } // for

      // console.log('upload files function completed. sendDocId=', this.sendDocUID);

      // emit to load document
      if ( this.sendDocUID != null ) {
         this.saveUploadDialog();
      }
      return;
   }

  // CJ - Single File conversion routine. Assumes the file requires conversion
  // to PDF from PNG or JPG. No TIFF support yet. Returns Blob
  async convertFile(fs: FileSelector): Promise<number> {

      const ispdf = fs.isPDF();
      const ispng = fs.isPNG();
      const isjpg = fs.isJPG();
      const istif = fs.isTIFF();

      this.uploadMessage  = 'Converting File ' + fs.fileName + '...';
      fs.showFileProgress = true;
      fs.fileProgress     = 10;
      this.uploadProgress.message = fs.fileProgress;
      // Wait for file buffer to be fully loaded.
      const fbuffer = await fs.file.arrayBuffer();
      // console.log('convert fs=', fs);

      // If this is already a PDF just return the file
      if (ispdf) {
        fs.fileProgress     = 100;
        this.uploadProgress.message = fs.fileProgress;
        fs.showFileProgress = false;
        //
        return ERR_OK;
      }

      const index = 0;
      // console.log('convert index=', index);

      // create a new PDF document
      this.pdfDoc = await PDFDocument.create();
      fs.fileProgress    = 20;
      this.pdfImageBytes = fbuffer;
      fs.fileProgress    = 30;
      this.uploadProgress.message = fs.fileProgress;

      let code = ERR_OK;
      code = await this.convertImage(this.pdfDoc, fs);
      if ( code !== ERR_OK ) {
          this.toast.pop('error',
                         'ERROR: Bad File Type Detected (ignoring file)',
                         fs.fileName + ' cannot convert image to PDF',
                         4000 );
          return code;
        }

      // Save the new PDF document to pdfSrc as Uint8Array
      // this.userMessage = "Saving the new PDF file...";
      // for debug can do this.pdfSrc =  await this.pdfDoc.save();
      const   buffer  = await this.pdfDoc.save();
      fs.fileProgress = 75;
      this.uploadProgress.message = fs.fileProgress;

      // console.log('convert buffer size =', buffer.byteLength);
      const newName = fs.fileName.substr(0, fs.file.name.lastIndexOf('.')) + '.pdf';
      const newType = 'application/pdf';
      let newBlob = null;

      newBlob = await this.doc2Blob(buffer, newType);

      fs.fileProgress    = 90;
      this.uploadProgress.message = fs.fileProgress;

      // replace the uploadBlob in the FileSelector
      // console.log('convert newBlob=', newBlob);
      // console.log('convert newBlob size=', newBlob.size);
      fs.uploadBlob = newBlob;
      fs.fileName = newName;
      fs.fileType = newType;

      fs.fileProgress    = 100;
      this.uploadProgress.message = fs.fileProgress;

      // Clean up pdf-lib allocations
      this.pdfDoc = null;
      this.pdfPage = null;
      this.pdfImage = null;
      this.pdfImageBytes = null;

      // Hide the file progress
      fs.showFileProgress = false;

      // return ok status
      return ERR_OK;
  }

  // CJ - Convert Image to a PDF page based on specs

  async convertImage(doc, fs: FileSelector): Promise<number> {
      // Assuming this.pdfDoc was created previously and passed as doc

      // Update Status/Progress
      this.uploadMessage = 'Converting image ' + fs.fileName + ' to PDF...';
      fs.fileProgress = 0;
      this.uploadProgress.message = fs.fileProgress;

      let fbuffer = null;
      // Get the image Blob or error bad image type/file.
      try {
         fbuffer = await fs.uploadBlob.arrayBuffer();
      } catch (e) {
          return ERR_FILE_TYPE;
        }

      // console.log('convert2 angle=', fs.imageRotation);

      // Get the pdfImageBytes
      this.pdfImageBytes = fbuffer;
      // Update Status/Progress
      fs.fileProgress = 40;
      this.uploadProgress.message = fs.fileProgress;


      let imageWidth = 0;
      let imageHeight = 0;
      let pageWidth = 0;
      let pageHeight = 0;
      // Assume Portrait orientation at first
      let landscape = false;

      // Convert either PNG or JPG format
      try {
         if (fs.isPNG()) {
           this.pdfImage = await this.pdfDoc.embedPng( this.pdfImageBytes );
         }
         else if (fs.isJPG()) {
               this.pdfImage = await this.pdfDoc.embedJpg( this.pdfImageBytes );
              }
      } catch (e) {
          return ERR_EMBED_FILE;
        }

      fs.fileProgress = 60;
      this.uploadProgress.message = fs.fileProgress;

      // get the page dimensions
      this.pdfDims = this.pdfImage.size();
      // console.log('convert2 dims=', this.pdfDims);
      imageWidth = this.pdfDims.width;
      imageHeight = this.pdfDims.height;

      // Check default orientation
      landscape = false;
      if (imageWidth > imageHeight) {
           landscape = true;
      }
      // get the paper size dims from FileSelector
      const psr = FSC.IMAGE_SIZES.find(x => x.size === fs.imageSize);
      // console.log('cvtimage pageDims=', psr);

      // set page width,height or use default for actual size
      if (psr.dims) {
        if (landscape) {
           pageWidth = psr.dims[1];
           pageHeight = psr.dims[0];
        } else {
           pageWidth = psr.dims[0];
           pageHeight = psr.dims[1];
        }
      } else {
         // Actual Size
           pageWidth = imageWidth;
           pageHeight = imageHeight;
         }

      // console.log('cvtimage pageWidth=', pageWidth);
      // console.log('cvtimage pageHeight=', pageHeight);

      // console.log('cvtimage pageWidth=', pageWidth);
      // console.log('cvtimage pageHeight=', pageHeight);

      // get the scale before rotation
      const scaleWidth  = pageWidth  / imageWidth;
      const scaleHeight = pageHeight / imageHeight;

      if (scaleWidth < scaleHeight) {
         imageWidth  = Math.round(imageWidth * scaleWidth);
         imageHeight = Math.round(imageHeight * scaleWidth);
      } else {
         imageWidth  = Math.round(imageWidth * scaleHeight);
         imageHeight = Math.round(imageHeight * scaleHeight);
      }

      if (fs.addMargins) {
        imageWidth = imageWidth - (FSC.PDF_MARGIN_DOTS * 2);
        imageHeight = imageHeight - (FSC.PDF_MARGIN_DOTS * 2);
      } else {
              pageWidth = imageWidth;
              pageHeight = imageHeight;
        }

      // Change paper & image sizing orientation based on Rotation here
      if (fs.imageRotation === 90 || fs.imageRotation === 270 ) {
           const t = pageWidth;
           pageWidth = pageHeight;
           pageHeight = t;
           // t=imageWidth;
           // imageWidth=imageHeight;
           // imageHeight=t;
      }

      // console.log('cvtimage pageWidth=', pageWidth);
      // console.log('cvtimage pageHeight=', pageHeight);
      // console.log('cvtimage scaleWidth=', scaleWidth);
      // console.log('cvtimage scaleHeight=', scaleHeight);
      // console.log('cvtimage imageWidth=', imageWidth);
      // console.log('cvtimage imageHeight=', imageHeight);

      // Add the page, set size to image size
      this.pdfPage = this.pdfDoc.addPage();
      this.pdfPage.setSize(pageWidth, pageHeight);

      const originX = pageWidth / 2;
      const originY = pageHeight / 2;

      // console.log('fs.imagerot=', fs.imageRotation);
      // console.log('FSC imagerot=', FSC.IMAGE_ROTATIONS[fs.imageRotation]);

      // pdf-lib rotates left so use negative angles
      const angle = fs.imageRotation * -1;

      // console.log('ci2 angle=', angle);

      if (angle !== FSC.ROTATE_NONE) {
         await this.pdfPage.pushOperators(
            pushGraphicsState(),
            concatTransformationMatrix(
              1,
              0,
              0,
              1,
              originX,
              originY
            ),
            concatTransformationMatrix(
              Math.cos(angle * Math.PI / 180),
              Math.sin(angle * Math.PI / 180),
             -Math.sin(angle * Math.PI / 180),
              Math.cos(angle * Math.PI / 180),
              0,
              0,
            ),
            concatTransformationMatrix(
              1,
              0,
              0,
              1,
              -1 * originX,
              -1 * originY
            ),
        );
      }

      // Draw the JPG image in the center of the page
      await this.pdfPage.drawImage(this.pdfImage, {
         x: originX - (imageWidth / 2),
         y: originY - (imageHeight / 2),
         width: imageWidth,
         height: imageHeight,
      });
      fs.fileProgress = 80;
      this.uploadProgress.message = fs.fileProgress;

      await this.pdfPage.pushOperators(
         popGraphicsState(),
      );
      fs.fileProgress = 100;
      this.uploadProgress.message = fs.fileProgress;

      // Return OK status
      return ERR_OK;
  }

  // CJ - Merge Files Routine - Merges all in this.fileNames array
  async handleMergeAndUpload() {
      // CJ - Must verify group is defined, not null, exists first!!
      if (! this.selectedGroup ) {
         this.toast.pop('error', 'ERROR!',
         'Please Select or Create a Group First.');
         return;
      }
      // console.log("merge fileData=", this.fileData);
      // console.log("merge fileName", this.mergeFileName);

      // Get the merge file name from the UI
      if (!this.mergeFileName || this.mergeFileName === '') {
        this.mergeFileName = 'merged_file.pdf';
      }

      // get the document/file count
      const count = this.fileData.length;
      this.uploadMessage = 'Merging ' + count + ' files into merge file '
                           + this.mergeFileName;
      this.uploadProgress.message = 0;

      // Check the mergeFileName and fix it if required
      if (this.mergeFileName === '') {
         this.uploadMessage = 'ERROR: Merge File Name Can\'t be Blank!';
         return;
      } else {
               const i = this.mergeFileName.lastIndexOf('.');
               if (i === -1) {
                  this.mergeFileName = this.mergeFileName + '.pdf';
               }
               else {
                  this.mergeFileName = this.mergeFileName.substr(0, i) + '.pdf';
               }
               this.uploadMessage = 'Merging ' + count + ' files into ' +
                             this.mergeFileName;
        }

      // create a new PDF document
      this.pdfDoc = await PDFDocument.create();
      this.uploadProgress.message = 20;

      const increment = (60 / count);
      let total = 20 + increment;
      for (const [i, fs] of this.fileData.entries())  {
         fs.showFileProgress = true;
         fs.fileProgress = 0;
         document.getElementById('merge-status-' + i).innerHTML = '';
         const ispng = fs.isPNG();
         const isjpg = fs.isJPG();
         const istif = fs.isTIFF();
         const ispdf = fs.isPDF();

         // console.log('onFileDetect isPNG=', ispng);
         // console.log('onFileDetect isJPG=', isjpg);
         // console.log('onFileDetect isPDF=', ispdf);

         // wait for the target file array buffer to get loaded with file data
         const fbuffer = await fs.file.arrayBuffer();

         if (ispdf) {
            this.uploadMessage = 'Loading PDF Document...';
            // console.log('this.arrayBuffer=', fbuffer);
            this.pdfFile = await PDFDocument.load(fbuffer);
            // console.log('this.pdfFile=', this.pdfFile);

            // this.userMessage = "Copying PDF Document Pages...";
            this.pdfCopiedPages = await this.pdfDoc.copyPages(this.pdfFile, this.pdfFile.getPageIndices());

            this.pdfCopiedPages.forEach((page) => this.pdfDoc.addPage(page));
            fs.fileProgress = 100;
         } else {
              // Important to await completion here.
              this.uploadMessage = 'Converting image ' + fs.fileName + ' to PDF...';
              const code = await this.convertImage(this.pdfDoc, fs);
              if ( code !== ERR_OK ) {
                 this.toast.pop('error',
                          'ERROR: Bad File Type Detected (ignoring file)',
                          fs.fileName + ' cannot convert image to PDF',
                          4000 );
              }
              fs.fileProgress = 100;
         } // if(isPDF) else

         total = total + increment;
         this.uploadProgress.message = total;
         fs.showFileProgress = false;
         document.getElementById('merge-status-' + i).innerHTML = 'done';

       } // END FOR LOOP

      // Save the new PDF document to pdfSrc as Uint8Array
      // this.userMessage = "Saving the new PDF file...";
      // for debug can do this.pdfSrc =  await this.pdfDoc.save();

      this.uploadMessage = 'Saving merge file ' + this.mergeFileName + ',,,';
      this.uploadProgress.message = 80;

      const   buffer  = await this.pdfDoc.save();
      this.uploadProgress.message = 90;

      // console.log("merge buffer=", buffer);
      // console.log('merge buffer size =', buffer.byteLength);

      // Create the File object for the merged file
      this.uploadMessage = 'Creating file object ' + this.mergeFileName + ',,,';
      let mergeFile = null;
      const mergeName = this.mergeFileName;
      const mergeFileType = 'application/pdf';

      mergeFile = await this.doc2Blob(buffer, mergeFileType);
      this.uploadProgress.message = 100;

      // Create FileSelector with file and will also create the
      // uploadBlob
      const mfs  = new FileSelector(mergeFile);
      mfs.fileName = mergeName;
      mfs.fileType = mergeFileType;

      // replace fileNames with single file
      if (mfs && mergeFile) {
        // push the merge file, free up old file objects
        this.fileData = [];
        this.fileData.push(mfs);
        // upload the merged file
        this.uploadMessage = 'Uploading file ' + this.mergeFileName + ',,,';
        console.log('mergeFile name= ' + mfs.fileName);
        this.uploadFiles();
      }

      // console.log('mergefile=', mergeFile);

      // Dont do this
      // this.fileNames  = null;
      // reset inputFileList and fileNames
      this.inputFiles    = null;
      // this.mergeFileName = null;
      this.uploadMessage = '';

      // Clean up pdf-lib allocations
      this.pdfDoc         = null;
      this.pdfPage        = null;
      this.pdfImage       = null;
      this.pdfImageBytes  = null;
      this.pdfCopiedPages = null;

  } // handleMergeAndUpload

  // File constructor likely wont work on IE so probably using Blobs to
  // convert and upload images.
  async doc2Blob(buffer, btype) {
      let b = null;
      try {
            b = await new Blob([buffer], {type: btype});
          } catch (e) {
               const emsg = 'Error creating Blob in doc2blob';
               console.log(emsg, e);
               window.alert(emsg);
            }
      return b;
  }
  // File constructor likely wont work on IE so probably using Blobs to
  // convert and upload images.
  async doc2File(buffer, fname, ftype) {
      let f = null;
      try {
            f = await new File([buffer], fname, {type: ftype});
          } catch (e) {
               const emsg = 'Error creating File in doc2File';
               console.log(emsg, e);
               window.alert(emsg);
            }
      return f;
  }

   openAddGroupFormDialog() {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const grpData = new GroupData();
      
      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.GROUP,
         objMode: this.DisplayMode.ADD,
         objData: grpData,
         dialogTitle: 'Create Group',
         button1Text: 'Save It',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // const intent = null;
      // const choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
          } // data
      );
    } // openFormDialog

}
