<div mat-dialog-title class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>
    <span id="action-title" style="color: #1A567A;">{{dialogTitle}}</span>
  </div>
  <div class="dialog-header-right">
    <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
  </div>
</div>

<mat-dialog-content class="panel-class" style="margin-top: 1rem;">

  <div class="dialog-message" style="margin-top: 1%; font-size: 1em; color: black">{{dialogMessage}}</div>
  <mat-progress-bar
    *ngIf="dialogMode==='determinate'; else indeterminate"
    style="margin-top: 0.8rem; margin-bottom: 0.2rem"
                    id="pdf-progress" 
                    mode="determinate"
                    [value]="data.progress"
    ></mat-progress-bar>
  <ng-template #indeterminate>
  <mat-progress-bar
    style="margin-top: 0.8rem; margin-bottom: 0.2rem"
                    id="pdf-progress"
                    mode="indeterminate"
        ></mat-progress-bar>
  </ng-template>
</mat-dialog-content>
<div *ngIf="dialogMode === 'determinate'"
     align="center" class="dialog-message"
     style="margin-top: 1%; font-size: 1em; color: black; align: center;">
     {{data.progress}}%</div>
<mat-dialog-actions>
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.close()'>OK</button>
</mat-dialog-actions>
