import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Input, Output, ViewChild }
       from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { OrgModule } from '../org/org.module';
import { GroupModule } from '../group/group.module';

// import { MDToolsModule } from '../mdtools/mdtools.module';
import { PipeModule } from '../pipe/pipe.module';

// import { AppRoutingModule } from '../app-routing.module';
// import { AuthInterceptor } from '../app.intercept';

// import { AuthService } from '../service/auth.service';
// import { NotifyQueueService } from '../service/notify-queue.service';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ToasterService } from '../service/toaster.service';
import { PlatformService } from '../service/platform.service';
import { DocumentService } from '../service/document.service';
import { HelpService } from '../service/help.service';
import { ValidationService } from '../service/validation.service';
import { GroupService } from '../service/group.service';
import { GroupMemberService } from '../service/group-member.service';
import { UserService } from '../service/user.service';
import { UserSubAccountService } from '../service/user-subaccount.service';
import { MailService } from '../service/mail.service';
import { OrganizationService } from '../service/organization.service';
import { OrgUserService } from '../service/orguser.service';


import { environment } from '../../environments/environment';

//import { SortKey } from '../pipe/sortkey.pipe';
//import { FilterKeys } from '../pipe/filterkeys.pipe';
//import { NameFilter } from '../pipe/name-filter.pipe';


import { DocumentComponent } from './document/document.component';
import { DocumentListComponent } from './document-list/document-list.component';

// import { HelpDialogComponent } from '../dialog/help/help.dialog';

// const mgrRoutes = [{path: '', component: MdAppMgrComponent}];

@NgModule({
  declarations: [
    DocumentComponent,
    DocumentListComponent,    
  ],
  imports: [
    OrgModule,
    GroupModule,    
    CommonModule,
    // RouterModule.forChild(mgrRoutes),
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    ToastrModule.forRoot({
       positionClass :'toast-center-center'
    }),
    HammerModule,
        MatAutocompleteModule,  
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,  
        MatInputModule,
        MatListModule,  
        MatMenuModule,
        MatPaginatorModule,     
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSidenavModule,       
        MatSlideToggleModule,
        MatStepperModule,       
        MatToolbarModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,       
  ],
  exports: [
    DocumentComponent,
    DocumentListComponent,    
  ],
  providers: [
        // AdminService,
        /***
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        ***/
        {   provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
            deps: []
        },
        ToasterService,
        ToastrService,
        // NotifyQueueService,
        DocumentService,
        GroupService,
        GroupMemberService,
        OrganizationService,
        OrgUserService, 
        UserService,
        UserSubAccountService,
        HelpService,
        ValidationService,
        MailService,
        {  provide: MatDialogRef,
           useValue: {}
        },
        {  provide: MAT_DIALOG_DATA,
           useValue: {}
        },
    ],
    entryComponents: [
                     ]
  
})
export class DocumentModule { }
