import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterkeys'
})
export class FilterKeys implements PipeTransform {
  transform(items: any[], keys: string[], searchText: string): any[] {
    // console.log('sortkey items=', items);  
    if (!items) return [];
    if (!searchText) return items;
    return items.filter(item => {
      return keys.some(key => {
        const val = String(item[key]).toLowerCase();
        const result = String(item[key]).toLowerCase().includes(searchText.toLowerCase());
	console.log('filterkeys val =', val);
	console.log('filterkeys result =', result);
        return result;
      });
    });
   }
}
