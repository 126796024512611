<div #markerDiv>
  <img #markerImg
       class="right-side-icon"
       [src]="iconPath"
       [style]="markerStyle"
       [style.margin.px]="margin"
       [style.padding.px]="padding"
       [style.border.px]="border"
       (click)="onClick($event)"/>
  <!--
  <div [id]="markerDialogId" *ngIf="template===this.templateEditDescription" [style.position]="stylePosition">
  </div>
  -->
</div>

<div #markerDiv
     [id]="id"
     [class]="className"
     *ngIf="template===templateClosed"
     style="width: 32px; height: 40px'; z-index: 1000;"
     >
  <img #markerImg
       class="right-side-icon"
       [src]="iconPath"
       [style]="markerStyle"
       [style.margin.px]="margin"
       [style.padding.px]="padding"
       [style.border.px]="border"
       (click)="onClick($event)"/>
  <!--
  <div [id]="markerDialogId" *ngIf="template===this.templateEditDescription" [style.position]="stylePosition">
  </div>
  -->
</div>

<div [id]="dialogId"
     *ngIf="template===templateEditDescription"
     style="margin: 0; padding: 0;">
</div>

