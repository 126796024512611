import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { AuthService } from '../service/auth.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { TosComponent } from '../tos/tos.component';
// import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { SubaccountEditComponent } from '../subaccount-edit/subaccount-edit.component';

import { UserService } from '../service/user.service';
import { ValidationService } from '../service/validation.service';

@Component({
    selector: 'app-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['./settings-dialog.component.css']
})

export class SettingsDialogComponent implements OnInit {
   @ViewChild(SubaccountEditComponent, {static: true}) subAcctEdit;
//   @ViewChild(PasswordResetComponent, {static: true}) pwdReset;

    showForgot = true;
    shown = 'block';
    userData: UserData;
    userInfo: UserInfo;
    settings: FormGroup;
    infoSettings: FormGroup;
    changeStatus: string;
    url = '';

    pwdStrength = 0;
    pwdType = 'password';
    pwdIcon = 'visibility';
    cpwdType = 'password';
    cpwdIcon = 'visibility';

    isReadonly = true;
    isDisabled = false;
    show = false;

    selectedIndex = 1;
    SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

    constructor(
        private auth: AuthService,
        private usersvc: UserService,
        private validationsvc: ValidationService,
        // private http: HttpClient,
        private fb: FormBuilder,
        private toast: ToasterService
    ) {
        this._create_form();
    }

    private _create_form(): void {
        this.userInfo = new UserInfo();
        this.settings = this.fb.group({
            email: new FormControl(),
            password: new FormControl(),
            passwordconfirm: new FormControl()
        });

        this.infoSettings = this.fb.group({
            name: new FormControl(),
            last_name: new FormControl(),
            company: new FormControl(),
            phone: new FormControl(),
            notify: new FormControl()
        });

        this.userData = this.auth.getTokenInfo();
    }

    ngOnInit() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone,
                    notify: this.userInfo.notify || false
                });
            },
            (e) => console.log('ERROR: ', e)
        );
    }

    // CJ - added to forward main event action
    refresh_subs() {
       if (this.subAcctEdit) {
         this.subAcctEdit.refresh_subs();
       }
    }

    getUserInfo() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone,
                    notify: this.userInfo.notify || false
                });
            },
            (e) => console.log('ERROR: ', e)
        );
    }

    ctimeDate(timestamp) {
        return new Date(timestamp * 1000);
    }

    // Profile image change
    onSelectFile(file: any) {
        if (file.target.files && file.target.files[0]) {
        const fReader = new FileReader();
        fReader.readAsDataURL(file.target.files[0]);
        fReader.onload = (event: any ) => {
            this.url = event.target.result as string;
            };
        }
    }

    async updateInfo() {
      /****
        this.http.post(environment.apiUrl + '/user/' + this.userInfo.uid, this.infoSettings.value)
            .subscribe(
                (x) => {
                    this.toast.pop('success', 'Update User', 'User information updated.');
                },
                (e) => {
                    this.toast.pop('error', 'Update User', 'Failed to update user information.');
                }
            );
       ***/
       let x = null;
       try {
           x = await this.usersvc.updateUserInfo(this.userInfo.uid, this.infoSettings.value);
           this.toast.pop('success', 'Update User', 'User information updated.');     } catch (e) {
              this.toast.pop('error', 'Update User', 'Failed to update user information.');
              console.error('user settings update user info error e=', e);
              return;
       }
       this.isReadonly = !this.isReadonly;
       this.isDisabled = !this.isDisabled;
       this.show = false;
    }

    editInfo() {
        this.isReadonly = !this.isReadonly;
        this.isDisabled = !this.isDisabled;
        this.show = true;
        document.getElementById('edit_focus').focus();
    }

    validate_password(event) {
        const password = event.target.value;
        if (password.length === 0) {
            // Show nothing when empty
            this.changeStatus = '';
            return;
        }

        let ctr = 0;
        ctr = this.validationsvc.validatePassword(password);
        let color = '';
        let strength = 'Password Strength: ';
        switch (ctr) {
            case 0:
            case 1:
            case 2:
                strength += 'Very Weak';
                color = 'red';
                this.pwdStrength = ctr;
                break;
            case 3:
                strength += 'Medium';
                color = 'orange';
                this.pwdStrength = ctr;
                break;
            case 4:
                strength += 'Strong';
                color = 'green';
                this.pwdStrength = ctr;
                break;
        }

        this.changeStatus = strength;
        document.getElementById('change-status').style.color = color;
    }

    clear_status(event) {
        this.changeStatus = '';
        document.getElementById('change-status').style.color = 'black';
    }



    async toggle_notify() {
        const notify = !this.userInfo.notify;
        // console.log('setting notify =', notify);
        let x = null;
        try {
          x = await this.usersvc.updateUserNotificationsPromise(this.userInfo.uid, notify);
          this.userInfo.notify = notify;
          if ( notify === true ) {
            this.toast.pop('success', 'Update User',
                           'User notifications are now enabled');
          } else {
            this.toast.pop('success', 'Update User',
                           'User notifications are disabled');
            }
          console.log('userInfo.notify=', this.userInfo.notify);
        } catch (e) {
            this.toast.pop('error', 'Update User', 'Failed to update user notification preference.');
            console.error('user settings update notify error=', e);
          }
    }


    async submit_form() {
        if (this.settings.value.password !== this.settings.value.passwordconfirm) {
            this.changeStatus = 'Passwords do not match.';
            setTimeout((s) => s.changeStatus = '', 3000, this);

            return;
        }

        console.log('reset pwdStrength=', this.pwdStrength);
        if ( this.pwdStrength < 1 ) {
           this.changeStatus = 'Weak Password, Mix caps and lower case or numbers or special characters ($@$!%*#?&)';
           return;
        }

        let x = null;
        try {
             x = await this.usersvc.updateUserPassword(this.userInfo.uid,this.settings.value.password);
             this.toast.pop('success', 'Password Update', 'Password updated successfully.');
             this.toggle_settings();
             this.auth.clearReset();
        } catch (e) {
              this.toast.pop('error', 'Password Update','Failed to update password.');
              console.error('user settings update pwd error =', e);
              return;
        }

    /****
        this.http.post(environment.apiUrl + '/user/' + this.userInfo.uid, this.settings.value.password)
            JSON.stringify({ password: this.settings.value.password })
        ).subscribe(
            (x) => {
                this.toast.pop('success', 'Password Update', 'Password updated successfully.');
                this.toggle_settings();
                this.auth.clearReset();
            },
            (e) => {
                this.toast.pop('error', 'Password Update', 'Failed to update password.');
            }
        );
      ****/

    } // submit_form()

    toggle_pwd() {
        if (this.pwdType === 'password') {
            this.pwdType = 'text';
            this.pwdIcon = 'visibility_off';
        } else {
            this.pwdType = 'password';
            this.pwdIcon = 'visibility';
        }
    }
    toggle_cpwd() {
        if (this.cpwdType === 'password') {
            this.cpwdType = 'text';
            this.cpwdIcon = 'visibility_off';
        } else {
            this.cpwdType = 'password';
            this.cpwdIcon = 'visibility';
        }
    }

    toggle_settings() {
      /*
        if (this.shown === 'block') {
            this.shown = 'none';
        } else {
            this.shown = 'block';
            this.getUserInfo();
        }
       */
       this.getUserInfo();
       // document.getElementById('settings-container').style.display = this.shown;
    }
    closeThis(){
        this.toggle_settings();
    }

    selectChange() {
        // console.log('Selected INDEX: ' + this.selectedIndex);
        // if (this.selectedIndex === 1) {
        //   this.pwdReset.shown = 'block';
        // }
        // this.refresh_subs();
        // return to validate no exception
        return true;
    }
    get_index(){
        return this.selectedIndex;
    }

    swiperight() {
        this.selectedIndex++;
    }
    swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.selectedIndex < 0 || this.selectedIndex > 2 ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.selectedIndex === 2;
            this.selectedIndex = isLast ? 0 : this.selectedIndex + 1;
            console.log('Swipe right - INDEX: ' + this.selectedIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.selectedIndex === 0;
        this.selectedIndex = isFirst ? 2 : this.selectedIndex - 1;
        console.log('Swipe left - INDEX: ' + this.selectedIndex);
        }
    }

}
