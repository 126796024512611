// CJ - Dialog to show app about information

import { Component, OnInit, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PlatformService } from '../../service/platform.service';

import { Device, DeviceInfo } from '@capacitor/device';

@Component({
    selector: 'app-about-dialog',
    templateUrl: './about.dialog.html',
    styleUrls: ['./about.dialog.css']
})

export class AboutDialogComponent implements OnInit, AfterViewInit {

   public version: string = environment.version;
   public name: string  = environment.name;
   public devInfo: DeviceInfo;
   public devName = '';
   public devModel = '';
   public devMfr = '';
   public devMemUsed = 0;   

   constructor(
        public platformsvc: PlatformService,
        private dialogRef: MatDialogRef<AboutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data )
        {
           if (data) {
              console.log('about data is set.');
           } // if (data)
        }

   async ngOnInit() {
      // If UserInfo is not defined retrieve the userInfo
      if (this.version === undefined || this.version === null) {
          console.log('AboutDialog: error no version info available');
      }
      try {
          this.devInfo = await Device.getInfo();
          this.devName = this.devInfo.name;
          this.devModel = this.devInfo.model;
          this.devMfr = this.devInfo.manufacturer;
          this.devMemUsed = this.devInfo.memUsed / 1048576;
      } catch (e) {
           console.log('Error: cant retrieve device info e=', e);
      }
   }

   ngAfterViewInit() {
   }

   close() {
      // User canceled or quit operation
      this.dialogRef.close();
   }

   async save() {
      // bundle the intent and data
   }

}
