<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
   <mat-icon style="margin-left: 0; padding-left: 0;">drag_indicator</mat-icon>
   <span class="title" style="align-self: flex-start;">Send Temporary Access</span>
  </div>
  <div class="dialog-header-right">  
  <button #settingsMgrMoreBtn mat-icon-button class="icon-menu-button"
	  [matMenuTriggerFor]="settingsMgrMenu"
	  style="align-self: flex-start; align: top; margin-right:0;">
          <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #settingsMgrMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="helpDialog()"
          matTooltip="Manage Groups Help"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>help_outline</mat-icon>Help
      </button>
      <button mat-menu-item id="close-x" (click)="close()"
          matTooltip="Close Settings Manager"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>close</mat-icon>Close
      </button>
  </mat-menu>
  <button mat-icon-button id="close-x" (click)="close()"
          matTooltip="Close Settings Manager"
          style="margin-left: 0.2rem; align-self: flex-top;">
          <mat-icon>close</mat-icon>
   </button>
  
  </div>
</div>

<mat-dialog-content id="send-token" #content>
  <!-- *ngIf="activeDocument != null && checkOwnership()" -->
  <app-send-data 
		 [overlayData]="noteData"
		 [activeDocument]="activeDocument"
		 [activeUser]="activeUser"></app-send-data>
</mat-dialog-content>

<mat-dialog-actions>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center; border-top: 1px solid #1A567A; width: 100%" >

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%; font-size: 0.8rem">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>

  
 </div>
  
</mat-dialog-actions>
