<div class="ob-container" id="container">
    <div class="content">
        <span class="material-icons" style="float: right; cursor: pointer;" (click)="close()">close</span>
        <div id="card">
            <button class="change-slide-button" id="button-left" (click)="panLeft()"><span class="material-icons">navigate_before</span></button> 
            <div id="text-inside">

                <div id="vertical-tittle"> Save Time </div>
                <div id="subtittle">Save time by correcting on the go</div>
                <div class="link-alignment">
                    <div id="getstarted"></div>
                    <label (click)="changeImg(1)" >
                        <input type="radio" class="links" name="nav-dots" value="1" id="page-link1" checked>
                        <div class="button"></div>
                    </label>
                    <label (click)="changeImg(2)">
                        <input type="radio" class="links" name="nav-dots" value="2" id="page-link2">
                        <div class="button"></div>
                    </label>
                    <label (click)="changeImg(3)">
                        <input type="radio" class="links" name="nav-dots" value="3" id="page-link3">
                        <div class="button"></div>
                    </label>
                </div>
                <button mat-button id="close-button" (click)="close()">Get Started</button>
            </div>
            <div id="text-under">
                <div id="vertical-tittle"> Save Time </div>
                <div id="subtittle">Save time by correcting on the go.</div>
                <div class="link-alignment">
                    <div id="getstarted"></div>
                    <label (click)="changeImg(1)" >
                        <input type="radio" class="links" name="nav-dots" value="1" id="page-link1" checked>
                        <div class="button"></div>
                    </label>
                    <label (click)="changeImg(2)">
                        <input type="radio" class="links" name="nav-dots" value="2" id="page-link2">
                        <div class="button"></div>
                    </label>
                    <label (click)="changeImg(3)">
                        <input type="radio" class="links" name="nav-dots" value="3" id="page-link3">
                        <div class="button"></div>
                    </label>
                </div>
                <button mat-button id="close-button2" (click)="close()">Get Started</button>
            </div>
            
            <button class="change-slide-button" id="button-right" (click)="panRight()"><span class="material-icons">navigate_next</span></button>
        </div><!-- end of card div -->
        
    </div> <!-- end of div content-->
</div>
