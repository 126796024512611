import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filteroutkey'
})
export class FilterOutKey implements PipeTransform {
  transform(items: any[], keys: string[], searchText: string): any[] {
    // console.log('sortkey items=', items);  
    if (!items) return [];
    if (!searchText) return items;
    return items.filter(item => {
      return keys.some(key => {
        const val = String(item[key]).toLowerCase();
	const result = ! val.includes(searchText.toLowerCase());
	console.log('filteroutkey searchText=', searchText);	
	console.log('filteroutkey val=', val);
	console.log('filteroutkey result=', result);	
        return result;
      });
    });
   }
}
