import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

import { AuthService } from '../service/auth.service';
import { TosComponent } from '../tos/tos.component';

@Component({
    selector: 'app-tos-accept',
    templateUrl: './tos-accept.component.html',
    styleUrls: ['./tos-accept.component.css']
})

export class TosAcceptComponent {
    @Input() userInfo;
    accepted = false;

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    logout() {
        this.auth.logout();

        this.router.navigate(['/login'], {replaceUrl: true});
    }
}
