<div #content class="panel-class" style="margin: 0; padding: 0; border-top: 1px solid #1A567A;">  
  <div class="title" style="margin-left: 0rem; border-bottom: 1px solid #1A567A;"></div>  
  <div (swipeleft)="swipe(get_index(), $event.type)" (swiperight)="swipe(get_index(), $event.type)">
  <mat-tab-group #content dynamicHeight style="margin-left: 0rem; width:100%; height: 100%;"
		 [(selectedIndex)]="groupTabIndex" (selectedTabChange)="onTabChanged($event)">
    <mat-tab (swipeleft)="swipe(1, $event.type)" (swiperight)="swipe(1, $event.type)" style="margin: 0rem; padding: 0;">
      <ng-template mat-tab-label>
	<button mat-button style="padding: 0; margin: 0;">
	  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">group</mat-icon>
	<div style="margin: 0; padding: 0;">Edit Groups</div>
	</button>
      </ng-template>
<!--
  <span class="showall"
	 *ngIf="groupTabIndex===0"
         style="font-size: 0.8rem; align-self: flex-start; margin-right: 0.5rem"
         (click)="showAllGroups = !showAllGroups;handleShowGroups()">Show All
  <mat-checkbox class="showall"
       *ngIf="groupTabIndex===0"
       [(ngModel)]="showAllGroups"
       (ngModelChange)="showAllGroups = !showAllGroupsnnnnn;handleShowGroups()">
  </mat-checkbox>
  </span>
-->      
    <mat-table #groupTable [dataSource]="this.dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Group Name</mat-header-cell>
      <mat-cell *matCellDef="let group">
		{{group.name}} <mat-icon *ngIf="group.owner === userInfo.uid" id="admin-star">star</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Type  -->
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
      <mat-cell *matCellDef="let group"
                style="word-wrap: break-word; width: 25%">
	    <div *ngIf="group.owner===userInfo.uid;else mbr">
	      <b>owner</b></div>
              <ng-template #admin>
                 <div>administrator</div>
              </ng-template>
              <ng-template #mbr>
                 <div>member</div>
              </ng-template>
      </mat-cell>      
    </ng-container>      
    
    <!-- Group Members -->
    <ng-container matColumnDef="members">
      <mat-header-cell *matHeaderCellDef>Members</mat-header-cell>      
      <mat-cell *matCellDef="let group"
                style="word-wrap: break-word; width: 10%">		
        <button mat-button 
		(click)="this.showMembers(group.uid);" >
                <mat-icon id="li-more-icon">manage_accounts</mat-icon>	  
	</button>
      </mat-cell>      
    </ng-container>      
    
    <!-- Rename Group -->
    <ng-container matColumnDef="rename">
      <mat-header-cell *matHeaderCellDef>Rename</mat-header-cell>      
      <mat-cell *matCellDef="let group"
                style="word-wrap: break-word; width: 10%">				
        <button mat-button *ngIf="group.owner === userInfo.uid"
		(click)="renameGroupDialog(group.uid)">
                <mat-icon id="li-more-icon">edit</mat-icon>	  
	</button>
      </mat-cell>      
    </ng-container>      
    
    <!-- Delete Group -->
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>      
      <mat-cell *matCellDef="let group"
                style="word-wrap: break-word; width: 10%; margin-right: 0">
        <button mat-button *ngIf="group.owner === userInfo.uid"
		style="color: red;"
		(click)="deleteGroupDialog(group.uid, group.name)">
                <mat-icon id="li-more-icon">delete_forever</mat-icon>	  
	</button>
      </mat-cell>      
    </ng-container>      
    
    <mat-header-row *matHeaderRowDef="displayedGroupColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedGroupColumns;"
	     class="group-row"
	     [class.hidden]="row.type==='member'"
	     ></mat-row>

   // firstFormGroup: FormGroup;
   // secondFormGroup: FormGroup;
   // thirdFormGroup: FormGroup;
   emailText: any;
    </mat-table>
      
    </mat-tab>

    <mat-tab (swipeleft)="swipe(1, $event.type)" (swiperight)="swipe(1, $event.type)">
      <ng-template mat-tab-label>
	<!-- <mat-icon>manage_accounts</mat-icon> -->
      <button mat-button style="padding: 0; margin: 0;">
	<mat-icon style="margin: 0; padding: 0;">groups</mat-icon>
	<div style="margin: 0; padding: 0;">Edit Members</div>
	</button>
      </ng-template>
      
    <div style="display: flex; flex-flow: column; justify-content-flex-start; align-content: center">
    <div style="display: flex; flex-flow: row wrap; justify-content: flex-start; align-items: center; width: 100%">

    <app-group-member-list #memberView style="width: 100%; height: 100%"
    (refreshMembersEvent)="groupTabIndex=1;groupChange($event)"
    (removeMemberEvent)="this.removeMember($event)"
    (displayUserInfoEvent)="this.displayUserInfo($event)"		   
    [template]="this.memberTemplate"
    [userInfo]="this.userInfo"
    [group]="this.group"
    [groupUID]="this.selectedGroup"
    [(groupList)]="this.groupList"
    >
    </app-group-member-list>
   </div>
  </div>
  </mat-tab>


  <mat-tab (swipeleft)="swipe(1, $event.type)" (swiperight)="swipe(1, $event.type)">
    <ng-template mat-tab-label>
	<button mat-button style="padding: 0; margin: 0;" (click)="showAddMember()">
	<mat-icon style="margin: 0; padding: 0;">person_add</mat-icon>
	<div style="margin: 0; padding: 0;">Add Member</div>
	</button>
    </ng-template>
    <app-add-group-member #addMemberView style="width: 100%; height: 100%; margin-top: 1rem"
    [template]=1
    [userInfo]="userInfo"
    [group]="selectedGroupObj"
    >
    </app-add-group-member>
   </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <!-- <mat-icon>group_add</mat-icon> -->
      <button mat-button style="padding: 0; margin: 0;"
	      (click)="showAddMultiple()">
	<mat-icon style="margin: 0; padding: 0;">group_add</mat-icon>
	<div style="margin: 0; padding: 0;">Add MemberList</div>
	</button>
    </ng-template>
    <app-add-group-member #addMultipleView style="width: 100%; height: 100%; margin-top: 1rem"
    [template]=2
    [userInfo]="this.userInfo"
    [group]="this.selectedGroupObj"			  
    >
    </app-add-group-member>
   </mat-tab>

  </mat-tab-group>
 </div> <!-- swipe -->
      
  <!-- </mat-dialog-content> -->
 </div>

<!--
<mat-dialog-actions style="bottom: 0; border-top: 1px solid #1A567A; margin-top: 0.2rem; align-self: flex-end">
 <div id="actions" style="display:flex; flex-flow: column;">
  <div id="step-message" style="display: block; font-size=0.7rem: margin-bottom: 0.2rem;">{{stepMessage}}</div>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center">

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%; font-size: 0.8rem;">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>    
  </button>

  
 </div>
 </div>
</mat-dialog-actions>

-->
