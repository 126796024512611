<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="width: 100%">
  <div class="dialog-header-left">
    <mat-icon style="padding-top: 0.25rem;">drag_indicator</mat-icon>
    <span class="title" >Marker Report</span>
  </div>
  <div class="dialog-header-right">
    <mat-icon [matMenuTriggerFor]="markerMenu"
	      style="align: right; margin-right: 1.5rem;">more_vert</mat-icon>
    <mat-icon (click)="close()" style="align: right">close</mat-icon>
  </div>
    <mat-menu #markerMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="printPDF()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>print</mat-icon>Print
      </button>
      <button mat-menu-item (click)="downloadPDF()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>download</mat-icon>Download
      </button>
      <!--
      <button mat-menu-item (click)="getPDF()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>download</mat-icon>GetPDF
      </button>
      <button mat-menu-item (click)="getPDFArray()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>download</mat-icon>GetPDFArray
      </button>
      -->
      <button mat-menu-item (click)="helpDialog()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>help_outline</mat-icon>Help
      </button>
      <button mat-menu-item (click)="close()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>close</mat-icon>Close Dialog
      </button>
    </mat-menu>

</div>
<mat-dialog-content #content class="panel-class" style="margin: 0; padding: 0; border-top: 1px solid #1A567A; border-bottom: 1px solid #1A567A; width: 100% overflow: auto;">
  <app-marker-list #markerList
      style="margin: 0; padding: 0; width: 100%;"
      [template]=this.template
      [userInfo]=this.userInfo
      [noteData]=data.noteData
      [documentUID]=this.documentUID
      [documentName]=this.documentName 
      (scrollToNoteEvent)=scrollToNote($event)
      (scrollToPageEvent)=scrollToPage($event)
      (getPdfEvent)=emitPDF($event)
      (displayUserInfoEvent)=displayUserInfo($event)
      >
   </app-marker-list>
   
</mat-dialog-content>

  
<mat-dialog-actions style="width: 100%">

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0rem;"
          (click)="close()">
    <span style="padding-left: 1%; font-size: 0.8rem">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="margin-left: 0.5rem;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="margin-left: 0.5rem;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>

  <button mat-raised-button class="action-button"
	  style="margin-left: 0.5rem;"
          (click)="printPDF()">
    <span style="padding-left: 1%; font-size: 0.8rem">Print</span>    
  </button>

  <button mat-raised-button class="action-button"
	  style="margin-left: 0.5rem;"
          (click)="downloadPDF()">
    <span style="padding-left: 1%; font-size: 0.8rem">Download</span>    
  </button>
  
</mat-dialog-actions>    
