/* eslint-disable */
export class pageData {
  pdfPageNbr:  number;
  width:    number;
  height:   number;
  div:      string;
  scale:    number;
  rotation: number;
  windowDPR: number;
  viewport: any;
  pdfPageWidth:  number;
  pdfPageHeight: number;
  }
