<div class="header">
  <div class="title">
    <mat-icon>print</mat-icon>
    <span class="title" style="margin-left: 1rem">Print</span>
  </div>
  <span class="subtitle">{{docname}}</span>
</div>
  <mat-divider></mat-divider>  
<mat-nav-list>
  <a mat-list-item (click)="sendEvent('print')">    
      <span mat-line>'Print Document'</span>
      <span mat-line>'Print Original Document'</span>
   </a>	   
   <mat-divider></mat-divider>		
  <a mat-list-item (click)="sendEvent('print-both')">
      <span mat-line>'Print Document with Marker Report'</span>
      <span mat-line>'Print Original Document + Marker Report'</span>
   </a>	   
   <mat-divider></mat-divider>		
  <a mat-list-item (click)="sendEvent('print-annotated')">
      <span mat-line>'Print Document with Markup & Marker Report'</span>
      <span mat-line>'Print Annotated Document + Marker Report'</span>
   </a>	   
   <mat-divider></mat-divider>		
   <a mat-list-item class="footer" style="color: red;"
        (click)="close()">
     <span mat-line>Cancel</span>
   </a>
</mat-nav-list>
