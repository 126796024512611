 <mat-menu #objMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
   <button *ngIf="(isObjAdmin || iam && userData.iam)"
	    mat-menu-item  (click)="createObj()">
     <mat-icon>person_add</mat-icon>        
     <span style="font-size: 0.9em">Add New Company/Org. User</span>
   </button>
   <button *ngIf="(isObjAdmin || iam && userData.iam)"
           mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit Company/Org. User</span>
   </button>
   <button mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>person</mat-icon>  
      <span style="font-size: 0.9em">View Company/Org. User</span>
   </button>
   <button *ngIf="(isObjAdmin || iam && userData.iam)"
	  mat-menu-item disabled (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete Company/Org. User</span>
  </button>
  </mat-menu>

  <mat-menu #listViewMenu="matMenu">
    <button mat-menu-item enabled (click)="setMode(DisplayMode.COMBO)">
          <mat-icon>input</mat-icon>  
          <span style="font-size: 0.9em">Combo Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT)">
          <mat-icon>arrow_drop_down</mat-icon>  
          <span style="font-size: 0.9em">Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.LIST)">
          <mat-icon>list</mat-icon>  
          <span style="font-size: 0.9em">User List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT_LIST)">
          <mat-icon>view_list</mat-icon>  
          <span style="font-size: 0.9em">User Select List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT_LIST)">
          <mat-icon>check</mat-icon>  
          <span style="font-size: 0.9em">User Select List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.TABLE); refreshList()">
          <mat-icon>table_rows_narrow</mat-icon>  
          <span style="font-size: 0.9em">Table</span>
    </button>
  </mat-menu>
  
  <mat-menu #rowMenu="matMenu">    
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="setSelectedObj(obj); viewObj()">
         <mat-icon>person</mat-icon>  
         <span style="font-size: 0.9em">View Info</span>
      </button>
<!--      *ngIf="getIsObjAdmin(obj, userData)" -->
      <button *ngIf="(isObjAdmin || iam && userData.iam)"
	      mat-menu-item (click)="setSelectedObj(obj); editObj()">
         <mat-icon>edit</mat-icon>  
         <span style="font-size: 0.9em">Edit {{objLabel}}</span>
      </button>
      <button *ngIf="(isObjAdmin || iam && userData.iam)"
	      mat-menu-item (click)="setSelectedObj(obj); deleteObj()">
         <mat-icon>delete</mat-icon>
         <span style="font-size: 0.9em">Delete {{objLabel}}</span>
      </button>
    </ng-template>    
  </mat-menu>
  
  <mat-menu #objListMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
    <!-- *ngIf="userData.iam" -->
    <button *ngIf="(isObjAdmin || iam && userData.iam)"
	    mat-menu-item  (click)="createObj()">
     <mat-icon>person_add</mat-icon>        
     <span style="font-size: 0.9em">Add {{objLabel}}</span>
   </button>
   <button *ngIf="(isObjAdmin || iam && userData.iam) && selectedObj && selectedObj !== null"
	   mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"   
            mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>person</mat-icon>  
      <span style="font-size: 0.9em">View {{objLabel}}</span>
   </button>
   <!---
   <button *ngIf="userData && userData.uid && selectedObj && (selectedObj['owner']===userData.uid)"
	  mat-menu-item (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
    --->
  </mat-menu>

  <ng-template #hidden>
  </ng-template>

  <ng-template #objSelect>
   <div style="display: flex; flex-flow: row; min-width: 60vw;">
	 <mat-select 
          class="org-select"
           style="z-index: 900; color: #2274A5; font-size: 1em; width: 80%; border-bottom: 2px solid #2274A5;; margin-left: 1rem; margin-right: 1rem; min-width: 50vw;"
           [ngModelOptions]="{standalone: true}"
	   [(ngModel)]="selectedObj"
           [compareWith]="compareObjs"	   
	   placeholder="Select {{objLabel}}"
           ngDefaultControl selected="selectedObj"	   
           (selectionChange)="setSelectedObj(selectedObj)">
	   <mat-select-trigger>
            <mat-icon matPrefix *ngIf="selectedObj && selectedObj.uid" style="margin-right: 1rem">person</mat-icon>
	    <span *ngIf="selectedObj">
	      {{selectedObj.org_user_email ? selectedObj.org_user_email : ''}}
	    </span>
          </mat-select-trigger>
          <mat-option id="no_org" style="color: blue; font-size: 1em" [value]="null" style="color: #2274A5;: font-size: 1em">None</mat-option>	  
          <mat-option *ngFor="let o of orgusersvc.orgUserList$ | async" [value]="o" style="color: #2274A5;: font-size: 1em">
            <mat-icon matPrefix *ngIf="o && o.uid" style="margin-right: 1rem">person</mat-icon>
	    UID {{o['org_uid'] ? o['org_uid'] : 'Unknown'}} Email: {{o['org_user_email'] ? o['org_user_email'] : 'Unknown'}}
	  </mat-option>
	 </mat-select>
	 <button mat-stroked-button
		 (click)="setTemplate(DisplayTemplate.LIST)"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">
	   <mat-icon style="width: 0.8em; color: #2274A5;">search</mat-icon>
	 </button>
         <button *ngIf="isObjAdmin"
		 mat-stroked-button 
                 (click)="createObj()"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">
            <mat-icon style="width: 0.8em; color: #2274A5;">person_add</mat-icon>
         </button>
	 <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">
	   <mat-icon style="width: 0.8em; color: #2274A5;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">
	   <mat-icon style="width: 0.8em; height: 1em; color: #2274A5;">list</mat-icon>
	 </button>
      </div>
  </ng-template>
  
  <ng-template #objCombo>
    <div style="display: flex; flex-flow: row; min-width: 60vw;">
      <form class="combo-form" [formGroup]="comboForm">
	<mat-form-field class="combo-form-field"
                        (click)="clearComboInput()"			
			[appearance]="formFieldStyle"
			[floatLabel]="'always'" style="font-size: 0.8rem; min-width: 50vw;">
          <mat-label class="form-label">{{objLabel}} Name</mat-label>
	  <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
          <input #objName
                 matInput		 
		 type="text"
                 placeholder="Enter or Select User"
                 style="font-size: 1rem;"
                 [formControl]="objNameInput"
                 aria-label="Company/Org. User"
                 [matAutocomplete]="autoCombo">
          <mat-autocomplete #autoCombo="matAutocomplete"
                            (optionSelected)="setSelectedObjEvent($event)"
                            placeholder="Company/Org. User">
	    <div *ngFor="let obj of (orgusersvc.orgUserList$ | async)">
	      <mat-option 
			  [value]="obj" [id]="obj.uid">
               <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>	
                {{obj.org_user_email ? obj.org_user_email : 'Unknown'}}
	      </mat-option>
	    </div>
	  </mat-autocomplete>
	  
	  <button matSuffix (click)="clearComboInput()"
		  style="margin-left: 1rem">
	  <mat-icon>arrow_drop_down</mat-icon></button>
    </mat-form-field>
      </form>
      <div #comboButtonDiv class="combo-button-div" style="display: flex; flex-flow: row nowrap; align-items: center;">
	 <button *ngIf="showAddButton"
		 mat-stroked-button
                 (click)="createObj()"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">person_add</mat-icon>
	 </button>
	 <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">list</mat-icon>
	 </button>
	</div>
      </div>
  </ng-template>
  
   <!-- SELECT_ORG TEMPLATE -->
   <div *ngIf="template === DisplayTemplate.SELECT"
	style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
     <ng-container *ngTemplateOutlet="objSelect">
     </ng-container>
   </div>
   <!-- END SELECT_ORG Template  -->

   <!-- COMBO TEMPLATE -->
   <div *ngIf="template === DisplayTemplate.COMBO"
	style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
     <ng-container *ngTemplateOutlet="objCombo">
     </ng-container>
  </div>  <!-- END COMBO Template  -->

  <div #grpList *ngIf="template === DisplayTemplate.LIST" style="overflow-y: none;">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center; ">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
        <button *ngIf="showAddButton"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
  	        (click)="createObj()">
            <mat-icon>person_add</mat-icon>        
        </button>
        <button *ngIf="selectedObj!==null && showMenuButton"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
  	        [matMenuTriggerFor]="objListMenu">
            <mat-icon>more_vert</mat-icon>        
        </button>
        <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">list</mat-icon>
	 </button>
      </div>
      
    <div style="margin-bottom: 200px; overflow-y: auto;">
      <div *ngFor="let obj of orgusersvc.orgUserList$ | async | filterkeys: ['org_user_email']: titleFilter | sortkey: 'org_user_email': 'asc'"
           style="display: flex; flex-flow: column; justify-content: space-beteen; width: 100%; color: black;">
	<mat-divider style="min-width: 100%"></mat-divider>
	<div style="display: flex; flex-flow: row; align-content: center">
	  <span>list excludeEmail: {{excludeEmail}}</span>
       <app-org-user #orgListItem
           style="width: 100%"
           [iam]="iam"		     
	   [isObjAdmin]="isObjAdmin"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="obj"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"
           [useMenuActionDialog]="useMenuActionDialog"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doView$]="doView$"
           (modeEvent)="setMode($event)"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedObj($event)"
           (editObjEvent)="editObj()"
  	   (deleteObjEvent)="onDelete($event)"		     
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()"	 
	   >
       </app-org-user>
       </div>
       <mat-divider [inset]="true"></mat-divider>       
      </div>
     </div>
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>

    <div #grpList *ngIf="template === DisplayTemplate.XFER_LIST" style="overflow-y: none;">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
      </div>
      
      <div style="margin-bottom: 200px; overflow-y: auto;">
	<!--- | filterkeys: ['org_user_email']: titleFilter  --->
      <div *ngFor="let ou of orgusersvc.orgUserList$ | async | filterkeys: ['org_user_email']: titleFilter | sortkey: 'org_user_email': 'asc'"
           style="display: flex; flex-flow: column; justify-content: space-beteen; width: 100%; color: black">
	<mat-divider style="min-width: 100%"></mat-divider>
	<div *ngIf="ou.org_user_email !== excludeEmail"
	     style="display: flex; flex-flow: row; align-content: center;">
	  <!-- <span>xfer excludeEmail: {{excludeEmail}}</span> -->

       <app-org-user #orgListItem
           style="width: 100%; min-height: 3rem;"
           [iam]="iam"		     
	   [isObjAdmin]="isObjAdmin"
           [mode]="'XL'"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="ou"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"
           [useMenuActionDialog]="useMenuActionDialog"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doView$]="doView$"
           (modeEvent)="setMode($event)"
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
  	   (editObjEvent)="editObj()"
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()"	 
	   >
       </app-org-user>
       </div>
       <mat-divider [inset]="true"></mat-divider>       
      </div>
     </div>
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  </div>

  <div #grpList *ngIf="template === DisplayTemplate.SELECT_LIST" style="overflow-y: none;">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
  <!-- style="margin-top: -1em; background-color: #2274A5; color: white" -->
        <button *ngIf="(isObjAdmin || iam && userData.iam)"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
  	        (click)="createObj()">
            <mat-icon>person_add</mat-icon>        
        </button>
        <button *ngIf="showRefreshButton"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
                (click)="refreshList()">
            <mat-icon>refresh</mat-icon>        
        </button>
        <button *ngIf="selectedObj!==null && showMenuButton"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
  	        [matMenuTriggerFor]="objListMenu">
            <mat-icon>more_vert</mat-icon>        
        </button>
        <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">list</mat-icon>
	 </button>
    </div>

    <div style="margin-bottom: 200px; overflow-y: auto;">
      <div *ngFor="let obj of orgusersvc.orgUserList$ | async | filterkeys: ['org_user_email']: titleFilter | sortkey: 'org_user_email': 'asc'"
           style="display: flex; flex-flow: column; justify-content: space-beteen; width: 100%; color: black;">
	<mat-divider style="min-width: 100%"></mat-divider>
       <app-org-user #orgUserListItem
           style="width: 100%"
           [iam]="iam"
	   [isObjAdmin]="isObjAdmin"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="obj"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"		     
           [showActions]="false"
           [showMenuButton]="true"
           [showDeleteButton]="true"
           [useMenuActionDialog]="useMenuActionDialog"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doView$]="doView$"
           (modeEvent)="setMode($event)"
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
           (editObjEvent)="editObj()"
           (deleteObjEvent)="onDelete($event)"
           (deleteErrorEvent)="onDeleteError($event)"		     
  	   (doneEvent)="onDone()"
	   (cancelEvent)="onDone()"		  
	   >
       </app-org-user>
       <mat-divider [inset]="true"></mat-divider>       
      </div>
      </div>
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div *ngIf="showActions" class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>
  
  <div *ngIf="template === DisplayTemplate.FORM">
    <div *ngIf="showObjSelect">
       <ng-container *ngTemplateOutlet="objSelect">
       </ng-container>
    </div>
    <mat-divider></mat-divider>
    <mat-list>
     <div>
       <app-org-user #objFormItem
           style="width: 100%"
           [iam]="iam"
	   [isObjAdmin]="isObjAdmin"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedObj"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"
           [showActions]="showActions"
           [useMenuActionDialog]="useMenuActionDialog"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
	   [doView$]="doView$"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (modeEvent)="setMode($event)"		     
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedObj($event)"
  	   (editObjEvent)="editObj()"		     		     
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()">
        </app-org-user>
        <mat-divider [inset]="true"></mat-divider>	
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
  </mat-list>
</div>
  
 <!-- ORG_USER_LIST --> 

 <!--- TABLE Template --->
 <div *ngIf="template===DisplayTemplate.TABLE">

      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em; color: #2274A5">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 (keyup)="applyTableFilter(titleFilterInput.value)"
		 style="width: 60%; font-size: 1rem; color: #2274A5"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
        <button mat-stroked-button
            style="margin-top: -1em; margin-left: 2em; color: #2274A5;"
  	        (click)="createObj()">
          <mat-icon style="color: 2274A5">person_add</mat-icon>	    
	  Add {{objLabel}}
        </button>
	 <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="">list</mat-icon>
	 </button>
    </div>
    
  <div class="table-container">
	
 <mat-table #objTable [dataSource]="dataSource" matSort matSortActive="org_name" matSortDirection="asc" style="min-width: 80vw; max-width: 95vw; max-height: 95vh; margin-left: 0.7em"> 

    <!-- org user_email Column -->
    <ng-container matColumnDef="org_user_email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Client #</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;"
		>{{obj.org_user_email}}</mat-cell>
    </ng-container>
    
    <!-- org name Column -->
    <ng-container matColumnDef="org_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;"
		>{{obj.org_name}}</mat-cell>
    </ng-container>

    <!-- org user type Column -->
    <ng-container matColumnDef="org_user_type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;"
		>{{obj.org_user_type}}</mat-cell>
    </ng-container>

    <!-- org user status Column -->
    <ng-container matColumnDef="org_user_status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;"
		>{{obj.org_user_status}}</mat-cell>
    </ng-container>

    <!-- org user view-->
    <ng-container matColumnDef="obj_view">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default; color: blue">
        <button mat-stroked-button 
            (click)="setSelectedObj(obj); viewObj()"
            style="width: 0.8em; margin-left: 0.5em; margin-right: 0.4em; color: #2274A5;">	
          <mat-icon style="width: 0.8em; color: #2274A5;">preview</mat-icon>
        </button>	  
      </mat-cell>	
    </ng-container>          

    <!-- org user edit -->
    <ng-container matColumnDef="obj_edit">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default; color: #2274A5">
        <button *ngif="obj.isObjAdmin" mat-stroked-button 
            (click)="setSelectedObj(obj); editObj()"	
            style="width: 0.8em; margin-left: 0.5em; margin-right: 0.4em; color: #2274A5;">	
          <mat-icon style="width: 0.8em; color: #2274A5;">edit</mat-icon>
        </button>	  
      </mat-cell>	
    </ng-container>          

    <!-- org status actions -->
    <ng-container matColumnDef="obj_actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let obj"
                (click)="setSelectedObj(obj)"		
		style="word-wrap: break-word; width: 20%; cursor: default; color: #2274A5;">
        <button mat-stroked-button 
	    [matMenuTriggerFor]="rowMenu" [matMenuTriggerData]="{obj: obj}"
            style="width: 0.8em; margin-left: 0.5em; margin-right: 0.4em; color: #2274A5;">	
          <mat-icon style="width: 0.8em; color: #1A567A;">more_vert</mat-icon>
        </button>	  
      </mat-cell>	
    </ng-container>          

    <!-- org selector // (click)="setSelectedObj(obj)"	  -->
    <ng-container matColumnDef="obj_selector">
      <mat-header-cell *matHeaderCellDef>Selected</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default; color: #2274A5;">
        <mat-checkbox class="example-margin"
          [checked]="selectedObj && (obj.uid === selectedObj.uid)"
          (change)="toggleChecked(obj)"
        ></mat-checkbox>
      </mat-cell>	
    </ng-container>          
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  </div>
  
   <mat-paginator #paginator
     [length]="0"
     [pageSizeOptions]="[7, 15, 25, 50, 75, 100]"
     [showFirstLastButtons]="true"
     (page)="onTableChangePage($event)">
   </mat-paginator>  
 </div>

  <!-- ORG_TABLE Template -->

  <!-- Expand List Template -->

  <div #orgExpandList *ngIf="template === DisplayTemplate.EXPAND_LIST">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
        <button *ngIf="showMenuButton"
	        mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
  	        (click)="createObj()">
            <mat-icon>person_add</mat-icon>
        </button>
      </div>

   <div *ngFor="let grp of orgsvc.orgList$ | async | filterkeys: ['org_name']: titleFilter | sortkey: 'org_name': 'asc'"
	 style="display: flex; flex-flow: column; width: 100%">
     <mat-divider style="min-width: 100%"></mat-divider>      
       <app-org-user #orgListItem
           style="width: 100%"
           [iam]="iam"		    
           [mode]="objMode"
	   [isObjAdmin]="isObjAdmin"		     
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="grp"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doView$]="doView$"
           (modeEvent)="setMode($event)"
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()"	 
	   >
       </app-org-user>
       <mat-divider [inset]="true"></mat-divider>
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>

 <!-- Delete Template -->
  <div #orgDelete *ngIf="template === DisplayTemplate.DELETE">
       <app-org-user #grpFormItem
           style="width: 100%"
           [iam]="iam"		     		     
           [mode]="objMode"
	   [isObjAdmin]="isObjAdmin"		     
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedObj"
           [selectedObj]="selectedObj"
           [selectedOrg]="selectedOrg"
           [doSubmit$]="doSubmit$"
	   [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"		     
	   [doView$]="doView$"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedObj($event)"
           (deleteObjEvent)="refreshList()"
           (modeEvent)="setMode($event)"
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()">
        </app-org-user>
       
  </div>

<!--
</div> <!-- org-user-list component -->
