import { Component, OnInit, AfterViewInit, Output, ViewChild, EventEmitter,
         ElementRef, ComponentRef, Inject } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject, Observable, Subscription } from 'rxjs';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { AuthService } from '../../service/auth.service';

import { UserService } from '../../service/user.service';
import { ValidationService } from '../../service/validation.service';

import * as HELP from '../../service/help.service';
import * as STATIC from '../../mdtools/statics.common';

import { environment } from '../../../environments/environment';

import { TosComponent } from '../../tos/tos.component';
import { SubaccountEditComponent } from '../../subaccount-edit/subaccount-edit.component';

import { OrgModule } from '../../org/org.module';
import { GroupModule } from '../../group/group.module';

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form.dialog.html',
    styleUrls: ['./form.dialog.css']
})
export class FormDialogComponent implements OnInit, AfterViewInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() modelEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   @Output() helpEvent = new EventEmitter<any>();
   @Output() closeEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();
   @Output() resetEvent = new EventEmitter<any>();
   @Output() saveEvent = new EventEmitter<any>();

   @ViewChild(SubaccountEditComponent, {static: false}) subAcctEdit;
   @ViewChild('content', {static: false}) content: ElementRef;
   @ViewChild('formItem', {static: false}) formItemRef: ElementRef;
   @ViewChild('formItem', {read: ComponentRef}) formItem;
   @ViewChild('orgTab', {static: false}) orgTab: any;
   // @ViewChild(OrganizationComponent, {static: true}) orgForm;
   // @ViewChild(OrgUserComponent, {static: true}) orgusrForm;
   // @ViewChild(GroupComponent, {static: true}) groupForm;

   // app mgr flat
   iam = false;
   isObjAdmin = false;

   // User vars
   userData: UserData;
   userInfo: UserInfo;

   settings: FormGroup;
   infoSettings: FormGroup;
   changeStatus: string;
   url = '';

   // Form vars
   showNextButton = false;
   contentXferType = 0;
   contentXferFromOrgUser: any;
   contentXferToOrgUser: any;

   // Dialog Template Vars
   dialogTitle = 'User Settings';
   useObjMenu = true;
   selectedIndex = 0;
   maxIndex      = 2;
   isReadonly = true;
   isDisabled = false;
   show = false;
   shown = 'none';
   excludeEmail: string = null;

   // Child Object Events
   doSubmit$: Subject<boolean> = new Subject();
   doReset$: Subject<boolean> = new Subject();
   doEdit$: Subject<boolean> = new Subject();
   doDelete$: Subject<boolean> = new Subject();
   doView$: Subject<boolean> = new Subject();

   // Object/Form Vars
   mode: any = null;
   formEvents: Subject<number> = new Subject<number>();
   selectedObj: any = null;
   selectedOrg: any = null;
   selectedOrgUser: any = null;
   selectedOrgContact: any = null;
   selectedGroup: any = null;
   selectedDocObj: any = null;   
   selectedProduct: any = null;
   selectedBillingPlan: any = null;
   selectedSubscription: any = null;
   objData: any = null;
   objLabel = 'Group';
   doSubmit = false;
   doReset = false;
   allowView = false;
   allowAdd = false;
   allowEdit = false;
   allowDelete = false;
   objModel = this.DisplayModel.GROUP;
   objMode  = this.DisplayMode.VIEW;
   template  = this.DisplayTemplate.FORM;
   isAddMode = false;
   viewOnly  = true;

   SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

   constructor(
        private auth: AuthService,
        private usersvc: UserService,
        private validationsvc: ValidationService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<FormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
   ) {
        // No dialog data to inject yet...
        if ( data ) {
           this.dialogTitle = data.dialogTitle;
           this.isObjAdmin = data.isObjAdmin;
           this.userData = data.userData;
           this.userInfo = data.userInfo;
           this.objModel = data.objModel;
           this.objMode = data.objMode;
           this.objData = data.objData;
           if ( this.objData && this.objData.org_user_email ) {
             this.excludeEmail = this.objData.org_user_email;
             console.log('form dialog excludeEmail=', this.excludeEmail);
           }
           // this.objLabel = data.objLabel;
           this.selectedObj = data.selectedObj;
           this.selectedOrg = data.selectedOrg;
           this.selectedOrgUser = data.selectedOrgUser;
           if ( data.iam ) {
             this.iam = data.iam;
           }
           if ( data.allowView ) {
             this.allowView = data.allowView;
           }
           if ( data.allowAdd ) {
             this.allowAdd = data.allowAdd;
           }
           if ( data.allowEdit ) {
             this.allowEdit = data.allowEdit;
           }
           if ( data.allowDelete ) {
             this.allowDelete = data.allowDelete;
           }
        }
   }

   ngOnInit() {
        this.userData = this.auth.getTokenInfo();

        /***
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone
                });
            },
            (e) => console.log('ERROR: ', e)
        );
        ****/
        this.setObjModel(this.objModel);
        console.log('formdialog OnInit objModel=', this.objModel);

        if (!this.objMode || this.objMode === null) {
           this.objMode = this.DisplayMode.VIEW;
        }
        console.log('form-dialog OnInit objMode=', this.objMode);
        this.setObjMode(this.objMode);

        if ( this.objModel===this.DisplayModel.USER ) {
           this.useObjMenu = false;
           this.objLabel = "User";
           this._create_form();
        } else {
             this.useObjMenu = true;
          }
    }

   ngAfterViewInit() {
     console.log('form-dialog selectedObj=', this.selectedObj);
     console.log('form-dialog selectedOrg=', this.selectedOrg);
     console.log('form-dialog selectedOrgUser=', this.selectedOrgUser);
   }

   /***
   setMode(e) {
     console.log('form-dialog mode=', e);
     this.mode = e;
   }
   ***/

   setObjAdmin(e) {
     this.isObjAdmin = e;
     console.log('setIsAdmin e=', e);
     console.log('setIsAdmin isObjAdmin=', this.isObjAdmin);
   }

   private _create_form(): void {
        this.userInfo = new UserInfo();
        this.settings = this.fb.group({
            email: new FormControl(),
            password: new FormControl(),
            passwordconfirm: new FormControl()
        });

        this.infoSettings = this.fb.group({
            name: new FormControl(),
            last_name: new FormControl(),
            company: new FormControl(),
            phone: new FormControl()
        });

        this.userData = this.auth.getTokenInfo();
   }

   // CJ - added to forward main event action
   refresh_subs() {
      if (this.subAcctEdit) {
        this.subAcctEdit.refresh_subs();
      }
   }

   getUserInfo() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone
                });
            },
            (e) => console.log('ERROR: ', e)
        );
   }

   ctimeDate(timestamp) {
       return new Date(timestamp * 1000);
   }

   updateInfo() {
      let x = null;
      x = this.usersvc.updateUserInfo( this.userInfo.uid, this.infoSettings.value );
      this.isReadonly = !this.isReadonly;
      this.isDisabled = !this.isDisabled;
      this.show = false;
   }

   editInfo() {
        this.isReadonly = !this.isReadonly;
        this.isDisabled = !this.isDisabled;
        this.show = true;
        document.getElementById('edit_focus').focus();
   }

   validate_password(event) {
        const password = event.target.value;
        const ctr = this.validationsvc.validatePassword(password);
        let color = '';
        let strength = 'Password Strength: ';
        switch (ctr) {
            case 0:
            case 1:
            case 2:
                strength += 'Very Weak';
                color = 'red';
                break;
            case 3:
                strength += 'Medium';
                color = 'orange';
                break;
            case 4:
                strength += 'Strong';
                color = 'green';
                break;
       }

        this.changeStatus = strength;
        console.log('User Settings: changeStatus=(' + this.changeStatus + ')');
        const status = document.getElementById('change-status');
        if (status) {
          status.style.color = color;
       }
   }

   clear_status(event) {
       this.changeStatus = '';
       const status = document.getElementById('change-status');
       if (status) {
          status.style.color = 'black';
       }
   }

   async submit_form() {
       if (this.settings.value.password !== this.settings.value.passwordconfirm) {
            this.changeStatus = 'Passwords do not match.';
            setTimeout((s) => s.changeStatus = '', 3000, this);
            return;
       }

       if (this.settings.value.password === '' ) {
            this.changeStatus = 'Password can not be blank!';
            setTimeout((s) => s.changeStatus = '', 3000, this);
            return;
       }

       const x = await this.usersvc.updateUserPassword(this.userInfo.uid, this.settings.value.password);
       this.getUserInfo();
   }

   toggle_settings() {
     /***
        if (this.shown === 'block') {
            this.shown = 'none';
        } else {
            this.shown = 'block';
            this.getUserInfo();
        }
      ***/
      this.getUserInfo();

        // document.getElementById('settings-container').style.display = this.shown;
   }

   closeThis(){
        this.toggle_settings();
   }

   setSelectedTabIndex(i) {
      this.selectedIndex = i;
      this.content.nativeElement.scrollTop = 0;
   }

   selectChange() {
        // console.log('Selected INDEX: ' + this.selectedIndex);
        this.refresh_subs();
        // return to validate no exception
        return true;
   }

    toggle_notify() {
        this.userInfo.notify = !this.userInfo.notify;
        console.log(this.userInfo.notify);
        this.usersvc.updateUserNotifications(this.userInfo.uid, this.userInfo.notify);
    }

   get_index(){
        return this.selectedIndex;
   }

   swiperight() {
        this.selectedIndex++;
   }

   swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.selectedIndex < 0 || this.selectedIndex > this.maxIndex ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.selectedIndex === this.maxIndex;
            this.selectedIndex = isLast ? 0 : this.selectedIndex + 1;
            console.log('Swipe right - INDEX: ' + this.selectedIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.selectedIndex === 0;
        this.selectedIndex = isFirst ? this.maxIndex : this.selectedIndex - 1;
        console.log('Swipe left - INDEX: ' + this.selectedIndex);
        }
   }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }

   async helpDialog() {
       this.helpEvent.emit(HELP.USER_SETTINGS);
   }

   setObjModel( model ) {
      console.log('form-dialog setObjModel called model=', model);
      this.objModel = model;
      switch(model) {
         case this.DisplayModel.ORG_TAB: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.ORG: {
             // this.isObjAdmin = this.getIsObjAdmin(this.objData, this.userData);
             // this.setObjMode(this.DisplayMode.VIEW);
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.ORG_USR: {
             // this.setObjMode(this.DisplayMode.VIEW);
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.ORG_USR_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayModel.ORG_USR_XFER_LIST: {
             this.showNextButton = true;
             this.setObjMode(this.DisplayMode.XFER_LIST);
             this.template = this.DisplayTemplate.XFER_LIST;
             break;
         }
         case this.DisplayModel.ORG_USR_CONTENT_XFER: {
             this.showNextButton = true;
             this.setObjMode(this.DisplayMode.FORM);
             this.template = this.DisplayTemplate.FORM;
             this.showNextButton = true;
             break;
         }
         case this.DisplayModel.ORG_CONTACT: {
             // this.setObjMode(this.DisplayMode.VIEW);
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.ORG_CONTACT_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayModel.PRODUCT: {
             // this.setObjMode(this.DisplayMode.VIEW);
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.PRODUCT_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayModel.SUBSCRIPTION: {
             // this.setObjMode(this.DisplayMode.VIEW);
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.SUBSCRIPTION_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayModel.GROUP: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.GROUP_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayModel.GROUP_FILE: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayModel.GROUP_FILE_LIST: {
             this.setObjMode(this.DisplayMode.SELECT_LIST);
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         default: {
             this.objModel = this.DisplayModel.USER;
         }
     }
   }

   setObjMode(mode) {
      console.log('form-dialog setObjMode called mode=', mode);
      this.objMode = mode;
      this.isAddMode = false;
      this.viewOnly = false;

      switch(mode) {
         case this.DisplayMode.SELECT: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.COMBO: {
             this.template = this.DisplayTemplate.COMBO;
             break;
         }
         case this.DisplayMode.EXPAND_LIST: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             break;
         }
         case this.DisplayMode.SELECT_LIST: {
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayMode.LIST: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.XFER_LIST: {
             this.template = this.DisplayTemplate.XFER_LIST;
             break;
         }
         case this.DisplayMode.FORM: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayMode.ADD: {
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = false;
             this.isAddMode = true;
             /****
             if (this.userData.uid === this.objData.owner ) {
                 this.viewOnly = false;
                 this.isAddMode = true;
             } else {
                 this.viewOnly = true;
                 this.isAddMode = false;
             }
             ****/
             break;
         }
         case this.DisplayMode.EDIT: {
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = false;
             this.isAddMode = false;
             /***
             if (this.userData.uid === this.objData.owner ) {
                 this.viewOnly = false;
             } else {
                 this.viewOnly = true;
             }
             ***/
             break;
         }
         case this.DisplayMode.VIEW: {
             this.template = this.DisplayTemplate.FORM;
             this.isAddMode = false;
             this.viewOnly = true;
             break;
         }
         case this.DisplayMode.DELETE: {
             this.template = this.DisplayTemplate.DELETE;
             this.isAddMode = false;
             break;
         }
         /****
         case this.DisplayMode.FIELD_NAME: {
             this.template = this.DisplayTemplate.FIELD_NAME;
             break;
         }
         ****/
      }
   }

   setSelectedOrg(e) {
     this.selectedOrgUser = e;
   }
   setSelectedOrgUser(e) {
     this.selectedOrgUser = e;
     if ( this.objModel === this.DisplayModel.ORG_USR_XFER_LIST ) {
        this.contentXferToOrgUser = e;
     }
   }
   setSelectedOrgContact(e) {
     this.selectedOrgContact = e;
   }
   setSelectedGroup(e) {
     this.selectedGroup = e;
   }

   viewObj() {
   }

   createObj() {
   }

   editObj() {
   }

   deleteObj() {
   }

   setSelectedObj(e) {
      this.selectedObj = e;
      if ( this.showNextButton ) {
         this.contentXferToOrgUser = e;
      }
      this.selectObjEvent.emit(e);
   }

   resetForm() {
     if( this.formItem ) {
        this.formItem.resetForm();
     }
   }

   submit() {
     console.log('form-dialog submit formItem=', this.formItem);
     console.log('form-dialog submit formItemRef=', this.formItemRef);
     // Send subject to child in html binding
     this.doSubmit$.next(true);
     setTimeout(() => {
       this.doSubmit$.next(false);
     }, 500);

     // instead listen for a submitEvent from form component
     // and link to onSubmit();
     // this.save();
   }

   onSubmit(e) {
      console.log('form-dialog onSubmit e=', e);
      // return objData
      this.objData = e;
      this.save();
   }

   reset() {
     console.log('form-dialog reset formItem=', this.formItem);
     console.log('form-dialog reset formItemRef=', this.formItemRef);
     // Send subject to child in html binding
     this.doReset$.next(true);
   }

   formNext() {
     console.log('form-dialog formNext formItem=', this.formItem);
     console.log('form-dialog formNext objModel=', this.objModel);
     console.log('form-dialog formNext excludeEmail=', this.excludeEmail);

     // Send subject to child in html binding
     // this.doEdit$.next(true);
     if ((this.objModel === this.DisplayModel.ORG_USR_XFER_LIST) && this.showNextButton) {
        this.save();
     }
     if ( (this.objModel === this.DisplayModel.ORG_USR_CONTENT_XFER) &&
          (this.contentXferType == 1) )                              {
        console.log('form dialog next model xfer list');
        this.setObjModel(this.DisplayModel.ORG_USR_XFER_LIST);
     }
     if ( (this.objModel === this.DisplayModel.ORG_USR_CONTENT_XFER) &&
          (this.contentXferType == 0) )                              {
          this.save();
     }
   }

   edit() {
     console.log('form-dialog edit formItem=', this.formItem);
     // Send subject to child in html binding
     this.doEdit$.next(true);
   }

   close() {
     // If opened as dialog close it
     if ( this.dialogRef ) {
        this.dialogRef.close();
     }
   }

   onDone() {
     this.close();
   }

   async save() {
      const dialogData = {
        objModel: this.objModel,
        objMode: this.objMode,
        objData: this.objData,
        objContentXferType: this.contentXferType,
        objContentXferToOrgUser: this.contentXferToOrgUser
      }
      this.modelEvent.emit(this.objModel);
      this.modeEvent.emit(this.objMode);
      this.saveEvent.emit(dialogData);
      this.dialogRef.close(dialogData);
   }

   refreshList() {
      this.refreshEvent.emit();
   }

} // form-dialog
