import { Component, OnInit, Output, ViewChild, EventEmitter,
         ElementRef, Inject } from '@angular/core';
import { ToasterService } from '../../service/toaster.service';
import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { NoteData } from '../../model/notedata.model';

import { AuthService } from '../../service/auth.service';

import { UserService } from '../../service/user.service';
import { ValidationService } from '../../service/validation.service';

import * as HELP from '../../service/help.service';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';

import { SendDataComponent } from '../../send-data/send-data.component';


import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-send-token-dialog',
    templateUrl: './send-token.dialog.html',
    styleUrls: ['./send-token.dialog.css']
})

export class SendTokenDialogComponent implements OnInit {
   @Output() helpEvent = new EventEmitter<any>();

   @ViewChild('content', {static: true}) content: ElementRef;

   userData: UserData;
   userInfo: UserInfo;

   activeUser: string;
   activeDocument: string;
   noteData: NoteData[];

   shown = 'none';
   settings: FormGroup;
   infoSettings: FormGroup;
   changeStatus: string;
   url = '';

   isReadonly = true;
   isDisabled = false;
   show = false;

   selectedIndex = 0;
   maxIndex      = 2;

   SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

   constructor(
        private auth: AuthService,
        private usersvc: UserService,
        private validationsvc: ValidationService,
        private fb: FormBuilder,
        private toast: ToasterService,
        private dialogRef: MatDialogRef<SendTokenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
   ) {
        // No dialog data to inject yet...
        if ( data ) {
           this.userInfo = data.userInfo;
           this.activeDocument = data.activeDocument;
           this.noteData = data.noteData;
           this.activeUser = this.userInfo.uid;
        }
        console.log('SendTokenDialog userInfo=', this.userInfo);
        console.log('SendTokenDialog activeDocument=', this.activeDocument);
        console.log('SendTokenDialog noteData=', this.noteData);
   }

   ngOnInit() {
   }

   getUserInfo() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone
                });
            },
            (e) => console.log('ERROR: ', e)
        );
   }

   ctimeDate(timestamp) {
       return new Date(timestamp * 1000);
   }


   swiperight() {
        this.selectedIndex++;
   }

   swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.selectedIndex < 0 || this.selectedIndex > this.maxIndex ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.selectedIndex === this.maxIndex;
            this.selectedIndex = isLast ? 0 : this.selectedIndex + 1;
            console.log('Swipe right - INDEX: ' + this.selectedIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.selectedIndex === 0;
        this.selectedIndex = isFirst ? this.maxIndex : this.selectedIndex - 1;
        console.log('Swipe left - INDEX: ' + this.selectedIndex);
        }
   }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }

   async helpDialog() {
       this.helpEvent.emit(HELP.SEND_TOKEN);
   }

   close() {
     // If opened as dialog close it
     if ( this.dialogRef ) {
        this.dialogRef.close();
     }
   }

} // send-token-dialog
