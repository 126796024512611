/* eslint-disable */

// Temporary data to track marker UI objects

export class MarkerUIData {
  uuid: string;
  type: string;
  index: number;
  markerRef: any;
  dialogRef: any;
 }
