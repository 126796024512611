import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserInfo } from '../model/userinfo.model';
import { AuthService } from './auth.service';

@Injectable()
export class AdminService {

   isValid: any;
   users: UserInfo[];

   id = "30798385-bcff-4e80-b327-a009a19cd976";

   constructor(
      private auth: AuthService,
      private http: HttpClient
      ) { }

   // GET USER INFO BY UUID
   //
   getUserAdminStatus$(uuid: string) {
      return this.http.get(environment.apiUrl + '/user/' + uuid );
   }

   getUsserAdminStatusSubscribe(uuid: string) {
      return this.http.get<UserInfo>
             (environment.apiUrl + '/usergroup/' + this.id + '/' + uuid )
             .subscribe(
                (x) => {
                    this.isValid = x;
                    return x;
                },
                (e) => {
                    console.log('adminsvc failed to get admin status, error: ', e);
                }
            );
   }

   // rxjs <= v7
   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.usersvc.getUserInfoPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getGroups Observable
   // and use the following on the component:
   // }
   // async loadGroups() {
   //   const userInfo$ = this.usersvc.getUserInfoObservable(uuid);
   //   this.groups = await lastValueFrom(userInfo$);
   // }
   getUserAdminStatusPromise(uuid: string): Promise<any> {
      return this.http.get<any>
             (environment.apiUrl + '/usergroup/' + this.id + '/' + uuid ).toPromise()
             .then(
                (x) => {
                    this.isValid = x;
                    return x;
                },
                (e) => {
                    console.log('adminsvc failed to get user(s), error: ', e);
                    throw e;
                }
            );
   }

   async getUserAdminStatus(uuid: string) {
      let x = null;
      try {
         x = await this.getUserAdminStatusPromise(uuid);
      } catch (e) {
          console.log('adminsvc getUserInfo error=', e);
          throw e;
        }
      return x;
   }

} // admin.service
