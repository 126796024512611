<div class="new-user-container" *ngIf=newUser>
    <div class="content">
        <form id='new-user-form' [formGroup]="newUserForm" >
            <div>
                <img src="/assets/Markadoc-logo-200TR.png" id="img-logo" alt="Mark a doc logo">
            </div>
            <p class="title">
                Create Account
            </p>
            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="firstName" autocomplete="off" required>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="lastName" autocomplete="off">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Company</mat-label>
                <input matInput type="text" formControlName="company" autocomplete="off">
            </mat-form-field>
            <mat-form-field autocomplete="off">
                <mat-label>Phone Number</mat-label>
                <input matInput type="text" formControlName="phone" autocomplete="off">
            </mat-form-field>
            <mat-form-field autocomplete="off">
                <mat-label>Password</mat-label>
                <input matInput #password type="password" autocomplete="off" required
                    (keyup)="validate_password($event)"
                    (focusout)="clear_status($event)"
                    formControlName="password">
                <mat-icon matSuffix>https</mat-icon>
            </mat-form-field>
            <mat-form-field autocomplete="off">
                <mat-label>Confirm Password</mat-label>
                <input matInput #passwordconfirm type="password" autocomplete="off" required
                    (keyup)="password_match($event)"
                    (keyup.enter)="onSubmit()"
                    (formControlName)="passwordconfirm">
                <mat-icon matSuffix>https</mat-icon>
            </mat-form-field>

            <button id='create-user-button' mat-button (click)="onSubmit()" type="submit" [disabled]="!newUserForm.valid">Create Account</button>
            <div class="invalid-notice" id='change-status'>{{changeStatus}}</div>
            <div class="invalid-notice" *ngIf="newUserForm.get('firstName').invalid">
                <div *ngIf="newUserForm.get('firstName').hasError('required')"> First Name is required. </div>
            </div>
            <div class="invalid-notice" *ngIf="newUserForm.get('password').invalid">
                <div *ngIf="newUserForm.get('password').hasError('required')"> Password must be at least 8 characters. </div>
            </div>
            <div class="invalid-notice" *ngIf="newUserForm.get('password').invalid">
                <div *ngIf="newUserForm.get('password').hasError('complexity')"> Password not strong enough.  </div>
            </div>
            <div class="invalid-notice" *ngIf="newUserForm.get('password').invalid">
                <div *ngIf="newUserForm.get('password').hasError('match')"> Passwords do not match. </div>
            </div>
        </form>
    </div>
</div>
