import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { OrgData } from '../org/model/orgdata.model';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';
import { StatData } from '../model/statdata.model';
import { AvgData } from '../model/avgdata.model';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  baseUrl = environment.apiUrl;

  public Org: OrgData = null;
  public OrgList: OrgData[] = null;
  
  public OrgSystemCnt: CountData = null;
  public OrgSystemStorage: SumData = null;
  
  // public OrgAvg: AvgData = null;

  private orgListItems$: BehaviorSubject<OrgData[]> = new BehaviorSubject<OrgData[]>(this.OrgList);

//  private orgUserInfoItems$: BehaviorSubject<OrgData[]> = new BehaviorSubject<OrgData[]>([]);


private orgItem$: BehaviorSubject<OrgData> = new BehaviorSubject<OrgData>(this.Org);

  orgList$: Observable<OrgData[]> = this.orgListItems$.asObservable();

  orgData$: Observable<OrgData> = this.orgItem$.asObservable();


  private orgSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgSystemCnt);
  orgSystemCount$: Observable<CountData> = this.orgSystemCountSubj$.asObservable();  

  private orgStatusCountSubj$: BehaviorSubject<StatData[]> = new BehaviorSubject<StatData[]>(null);
  orgStatusCount$: Observable<StatData[]> = this.orgStatusCountSubj$.asObservable();  

  constructor(
    private http: HttpClient,
    private toast: ToasterService,
  ) {}

  public getOrgListData() {
     return this.orgListItems$.value;
  }

  public getOrgItemData() {
     return this.orgItem$.value;
  }

  public getOrganization$(oid: string): Observable<OrgData> {
     return this.http.get<OrgData> (this.baseUrl + '/org/' + oid);
//            .pipe(tap((res) => { 
//                return res as OrgData;
//            }));
  }
      
  public getOrganizationData$(oid: string){
   this.http.get<OrgData> (this.baseUrl + '/org/' + oid)
    .subscribe( res =>
                      { const item = this.orgItem$.next(res);
                        console.log('org = ', res);
                        return item;
                      });
  }

  getAllOrganizations$(){
   this.http.get<OrgData[]> (this.baseUrl + '/org')
   .subscribe(res => {
                       // let item = this.orgListItems$;
                       this.OrgList = res;
                       this.orgListItems$.next(res);
                       console.log('org list =', res);
                       return res as OrgData[];
             })
  }

  getAllOrganizationsPromise(){
   return this.http.get<any[]> (this.baseUrl + '/org')
   .toPromise()
   .then( res => { console.log('org list =', res);
                   this.OrgList = res;
                   this.orgListItems$.next(res);
                   return res as OrgData[];
           },
           (e) => {
              console.log('orgsvc failed to get all orgs promise, error: ', e);
              throw e;
           });
  }

  getOrganizationPromise(oid: string): Promise<any> {
   return this.http.get<OrgData> (this.baseUrl + '/org/' + oid).toPromise()
    .then( (res) => { console.log('org = ', res);
                      this.Org = res;
                      this.orgItem$.next(res);
                      return res as OrgData;
           },
           (e) => {
               console.log('orgsvc failed to get org promise, error: ', e);
               throw e;
           });
  }

  async getOrganization(oid: string): Promise<any> {
     try {
       const org = await this.getOrganizationPromise(oid);
       return org;
     } catch (e) {
          console.error('getOrganization error e=', e);
          throw e;
       }
  }

  getAllOrganizations(){
   return this.http.get<OrgData[]> (this.baseUrl + '/org');
  }

  getUserOrganizationsObs$(uid: string) {
   return this.http.get<OrgData[]> (this.baseUrl + '/org/userorgs/' + uid);
  }
  
  getUserOrganizations$(uid: string) {
   this.http.get<OrgData[]> (this.baseUrl + '/org/userorgs/' + uid)
    .subscribe( res => { // console.log('user org list =', res);
                         this.OrgList = res;
                         this.orgListItems$.next(res);
                         return res as OrgData[];
                       });
  }

  getUserOrganizationsPromise(uid: string){
   return this.http.get<any[]> (this.baseUrl + '/org/userorgs/' + uid)
    .toPromise()
    .then ( res => { // this.currentOrgList = res;
                     // May need to merge orglist array with res here
                     this.OrgList = res;
                     this.orgListItems$.next(res);
                     return res as OrgData[];
                    },
            (e) => {
             console.log('orgsvc failed to get user orgs promise, error: ', e);
             throw e;
           });
  }

   // *** Add, update, delete operations

   addListItem(new_data: any) {
     this.OrgList.push(new_data);
     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgList = Object.assign([], this.OrgList);
   }

   async addOrganizationPromise(orgdata: OrgData) {
        // Add the org from orgdata (json) on the server
        const jdata = JSON.stringify( orgdata );
        return this.http.post(environment.apiUrl + '/org', jdata )
        .toPromise()
        .then(
            (x : OrgData) => {
                console.log('orgsvc add org x=: ', x);
                // Next update the OrgList item
                this.addListItem(x);
                // Update List Behavior Subject
                this.orgListItems$.next( this.OrgList );
                // Update BehaviorSubject
                this.orgItem$.next( x );
                // return data
                return x as OrgData;
            },
            (e) => {
                console.log('orgsvc failed to create org: ', jdata,
                            ' error:', e);
                throw e;
            }
        );
   }

   updateListItem(new_data: any) {
     const idx = this.OrgList.findIndex(obj => obj.uid === new_data.uid);
     this.OrgList[idx] = new_data;
     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgList = Object.assign([], this.OrgList);
   }

   async updateOrganizationPromise( oid: string, orgdata: OrgData ) {
        // Add the org user from orgdata (json) on the server
        const jdata = JSON.stringify( orgdata );
        return this.http.put(environment.apiUrl + '/org/' + oid, jdata )
        .toPromise()
        .then(
            (x : OrgData) => {
                console.log('orgsvc edit org x=: ', x);
                // Next update the OrgList item
                this.updateListItem(x);
                // Update List Behavior Subject
                this.orgListItems$.next( this.OrgList );
                // Update Observable
                this.orgItem$.next( x );
                // return data
                return x as OrgData;
            },
            (e) => {
                console.log('orgsvc failed to edit org: ', jdata,
                            ' error:', e);
                throw e;
            }
        );
   }

  removeListItem(del_uid: any) {
    const idx = this.OrgList.findIndex(obj => obj.uid === del_uid);
    this.OrgList.splice(idx, 1);
    this.OrgList = Object.assign([], this.OrgList);
  }

  // Remove Organization
  removeOrganizationPromise(uid): any {
    // remove the organization record
    return this.http.delete(environment.apiUrl + '/org/' + uid)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgsvc updt doc  x=: ', x);
                  // Next remove the DocumentList item
                  this.removeListItem(uid);
                  // Update List Subject/Behavior Subject
                  this.orgListItems$.next( this.OrgList );
                  // Update BehaviorSubject
                  // this.orgItem$.next( x );
                  return x;
              },
              (e) => {
                console.error('orgsvc remove org uid =', uid);
                console.error('orgsvc remove org error=', e);
                throw e;
             }
        );
  }

  // *** Stats

  getOrgSystemCount$(){
   this.http.get<CountData> (this.baseUrl + '/org/count/system')
    .subscribe( cnt => { console.log('org system cnt =', cnt);
                         this.orgSystemCountSubj$.next(cnt);
                         return cnt as CountData;
                       });
  }

  getOrgStatusCounts$(){
   this.http.get<StatData[]> (this.baseUrl + '/org/count/status')
    .subscribe( cnt => { console.log('org status cnts =', cnt);
                         this.orgStatusCountSubj$.next(cnt);
                         return cnt as StatData[];
                       });
  }


}
