<div #markerDialogDiv
     [attr.id]="id"     
     [id]="id"
     [class]="className"
     [style.position]="position"
     [style.top.px]="top"
     [style.left.px]="left"
     [style.backgroundColor]="backgroundColor"
     [style.zIndex]="zIndex"
     [style.borderTopRightRadius.px]="borderTopRightRadius"
     style="scroll-margin-top: 100px; scroll-margin-right: 10px;">


  <mat-menu #markerMenu="matMenu">
   <button mat-menu-item enabled (click)="emitHelp(helpContext);">
    <mat-icon>help_outline</mat-icon>
    <span style="font-size: 0.8rem">Marker Help</span>
   </button>
  <button mat-menu-item enabled (click)="emitEdit($event)">
    <mat-icon>edit</mat-icon>
    <span style="font-size: 0.8rem">Edit Marker (Title/Description)</span>
  </button>
  <button mat-menu-item enabled (click)="lastTemplate=template;template=templateDeleteMarker;scrollToDialog('delete-marker-dialog')">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.8rem">Delete Marker</span>
  </button>
  </mat-menu>


  <!------ COMMON TEMPLATES ----->

  <ng-template #helpTemplate>
      <mat-icon (click)="emitHelp(helpContext);"
        style="margin-right: 1rem; font-size: 24px">
        help_outline
      </mat-icon>
  </ng-template>

  <!---------- REGULAR MARKER TEMPLATES  -------------->


  <div class="detail-container"
       *ngIf="template===templateNewMarker">
        <div class="marker-title color-{{itemType}}-dark pad4">    
          <div>{{itemDisplay}}</div>
	  <ng-content *ngTemplateOutlet="helpTemplate">
          </ng-content>
        </div>
        <div class="left-right-space pad8">
           <label id="add-comment-title" >Title</label>
           <input id="title-{{name}}" class="input-title" type="textbox" maxlength="28" [(ngModel)]="itemData.title" required>
           <label id="add-comment-title">Description</label>
           <textarea #descrText id="description-{{name}}" class="input-description" rows="10" [(ngModel)]="itemData.description"></textarea>
	   <div class="marker-button-row">
             <input id="submit-{{name}}" class="add-item-button" type="submit" value="Add {{itemDisplay}}" (click)="emitSubmit($event)"/>
             <input id="link-{{name}}" class="add-item-button" type="submit" value="Add Link" (click)="emitLink($event)"/>
	     <!--
             <button mat-stroked-button id="select-text-btn-{{name}}"
		     style="padding: 0"
		     class="add-item-button" (click)="emitSelectText($event)">
	       <mat-icon
		 style="margin: 0px;"
		 fontSet="material-symbols-outlined">
		 text_select_move_forward_character
	       </mat-icon>
	       Text
	     </button>
             -->
             <input id="cancel-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="emitCancel($event)"/>
	   </div>
        </div>
	
  </div> <!--- NEW MARKER --->


  <div class="detail-container"
       *ngIf="template===templateEditDescription">
        <div class="marker-title color-{{itemType}}-dark pad4">    
          <div>{{itemData.title}}</div>
            <ng-content *ngTemplateOutlet="helpTemplate">
	    </ng-content>
	    <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email">
	      <a style="color:white; text-decoration: none;"
	         href='#' id="remove_{{id}}"
	         (click)="lastTemplate=template;template=templateDeleteMarker;scrollToDialog('delete-marker-dialog')">		 
                <span class="material-icons">delete</span>
              </a>
	    </div>
        </div>
        <div class="left-right-space pad8">
           <label id="add-comment-title" >Title</label>
           <input id="title-{{name}}" class="input-title" type="textbox" maxlength="28" [(ngModel)]="itemData.title" required>
           <label id="add-comment-title">Description</label>
           <textarea #descrText id="description-{{name}}" class="input-description" rows="10" [(ngModel)]="itemData.description"></textarea>
	   
	   <div class="marker-button-row">
             <input id="submit-{{name}}" class="edit-item-button" type="submit" value="Save" (click)="submitEdit($event)"/>	     	     
             <input id="link-{{name}}" class="edit-item-button" type="submit" value="Add Link" (click)="emitLink($event)"/>
             <input id="cancel-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="emitCancel($event)"/>
	   </div>
        </div>
	
	<ng-container *ngIf="itemData.comments">
          <div *ngFor="let cmt of itemData.comments; index as i"
	       class="color-{{itemType}}-comment margin8">
	    <div style="display: flex; flex-flow: row;">
           <div class="left-right-space pad8">
             <div><b>{{cmt.owner}}</b><br>
	       <span id="time">{{cmt.created_at | date:'short'}}</span>
	     </div>
           </div>
  	    <div *ngIf="cmt.owner!=='' && cmt.owner===userInfo.email">
              <span class="material-icons"
		    style="color: #C10712;"
		    (click)="selectedComment=i;template=templateDeleteComment;scrollToDialog('delete-comment-dialog')">delete</span>
	    </div>
	   </div>
<!--         <div class="left-right-space pad8">
                {{cmt.comment}}
		</div> -->	   
             <div class="left-right-space pad8" [innerHTML]="cmt.comment | linkify">
             </div>
        </div>
	</ng-container>
  </div> <!--- EDIT DESCRIPTION --->

  <div class="detail-container"
       *ngIf="template===templateAddComment">
        <div class="marker-title color-{{itemType}}-dark pad4">
          <div>{{itemData.title}}</div>
<!--          <div>{{itemData.uid}}</div> -->
	  <div style="display: flex; flex-flow: row; justify-content: flex-end;">
	    <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email; else helpTemplate">
	      <a style="color:white; text-decoration: none;"
	         href='#' id="menu_{{id}}"
		 [matMenuTriggerFor]="markerMenu">
                <span class="material-icons">menu</span>
              </a>
	    </div>
	    <!--
            <mat-icon (click)="emitHelp(helpContext);"
	         style="margin-right: 1rem; font-size: 24px"
                 >help_outline</mat-icon>
            <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email">
              <a style="color:white; text-decoration: none; margin-right: 1.5rem;"
	         href='#' id="remove_{{id}}"
	        (click)="emitEdit($event)">
                 <span class="material-icons">edit</span>
              </a>
	    </div>
	    <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email">
	      <a style="color:white; text-decoration: none;"
	         href='#' id="remove_{{id}}"
	         (click)="template=templateDeleteMarker;scrollToDialog('delete-marker-dialog')">		 
                <span class="material-icons">delete</span>
              </a>
	    </div>
            -->
	  </div>
        </div>
        <div class="left-right-space pad6" >
          <div><b>Description by {{itemData.owner}}</b><br>
	    <span id="time">{{itemData.created_at | date:'short'}}</span>
	  </div>
        </div>
        <div *ngIf="itemData.description && itemData.description !== null"
	     class="left-right-space pad8" >
	  <span class="description"
		[innerHTML]="'<div>' + itemData.description + '</div>' | linkify">
	  </span>
        </div>

	<ng-container *ngIf="itemData.comments">
          <div *ngFor="let cmt of itemData.comments; index as i"
	       class="color-{{itemType}}-comment margin8">
	    <div style="display: flex; flex-flow: row;">	    
           <div class="left-right-space pad2">
             <div><b>{{cmt.owner}}</b><br>
	       <span id="time">{{cmt.created_at | date:'short'}}</span>
	     </div>
           </div>
	    <div *ngIf="cmt.owner!=='' && cmt.owner===userInfo.email">
              <span class="material-icons"
		    style="color: #C10712;"
		    (click)="selectedComment=i;template=templateDeleteComment;scrollToDialog('delete-comment-dialog')">delete</span>
	    </div>
	   </div>
<!--         <div class="left-right-space pad8">
                {{cmt.comment}}
             </div> -->
             <div class="left-right-space pad2" [innerHTML]="cmt.comment | linkify">
             </div>
        </div>
	</ng-container>
	
        <div class="marker-button-row">
          <div class="marker-button-column">
            <label id="add-comment-title">Add Comment</label>	  
<!--            <input class="input-add-comment" type="text" inputmode="text" placeholder="add comment" [(ngModel)]="comment"> -->
            <input #commentText class="input-add-comment" type="text" inputmode="text" placeholder="" [(ngModel)]="comment">	    
	  </div>
        </div>
	  
        <div class="marker-button-row">
          <input id="submit-{{name}}" class="updt-item-button" type="submit" value="Save" (click)="lastTemplate=template; submitComment($event); template=lastTemplate;"/>
          <input id="link-{{name}}" class="updt-item-button" type="submit" value="Add Link" (click)="emitCommentLink($event)"/>	  
          <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="emitCancel($event)"/>
        </div>	
  </div> <!--- ADD COMMENT --->

  <!---------- APPROVAL TEMPLATES  -------------->  

  <ng-template #selectApprovalScope>
      <div style="display: flex; flex-flow: row; justify-content: center; align-items: center; margin-top: 5px;">      
        <mat-form-field appearance="outline">
         <mat-label id="approval-scope-label">Select Approval Type:</mat-label>
             <mat-select 
              [(ngModel)]="itemData.approvalScope" 
              [ngModelOptions]="{standalone: true}"
              ngDefaultControl selected="itemData.approvalScope"
              style="z-index: 900; color: #2274A5; font-size: 1.0rem; width: 90%; border-bottom: 2px solid #2274A5;; margin-left: 1rem; margin-right: 1rem;">
              <mat-option value="D" (onSelectionChange)="approvalScopeChange($event)" style="color: #2274A5;: font-size: 0.9rem">Document</mat-option>
              <mat-option value="P" (onSelectionChange)="approvalScopeChange($event)" style="color: #2274A5;: font-size: 0.9rem">This Page</mat-option>
<!--
              <mat-option value="T" (onSelectionChange)="approvalScopeChange($event)" style="color: #2274A5;: font-size: 0.9rem">Text</mat-option>
 -->
             </mat-select>
	  </mat-form-field>
      </div>
  </ng-template>

  <ng-template #displayApprovalScope>
    <div style="display: flex; flex-flow: row;">
      <span *ngIf="itemData.approvalScope && itemData.approvalScope===this.approvalTypeDocument">Document Approval</span>
      <span *ngIf="itemData.approvalScope && itemData.approvalScope===this.approvalTypePage">Page {{itemData.pageNbr}} Approval</span>
      <span *ngIf="itemData.approvalScope && itemData.approvalScope===this.approvalTypeText">Text Approval</span>
      <span *ngIf="itemData.approvalScope && itemData.approvalScope===this.approvalTypeArea">Area Approval</span>      
    </div>
  </ng-template>

  <ng-template #deSelectAll>
     <span>De-Select All</span>    
  </ng-template>

  <ng-template #editApprovalRequests>
    <div *ngIf="showRequests" style="margin-top: 3px;">
      <div class="left-right-space pad2">
	<span>Request Approvals From:</span>
	<mat-checkbox
	  [checked]="selectAllMembers"
	  (change)="setAll($event.checked)">
	  <span *ngIf="!selectAllMembers; else deSelectAll">Select All</span>
	</mat-checkbox>
        <mat-selection-list #grpMemberList
   style="width 85%; max-height: 45vh; overflow-y: auto; overflow-x: none; font-size: 0.5rem; margin-left: 1rem; margin-right: 0.5rem"
  (selectionChange)="onRequestSelected($event, grpMemberList.selectedOptions)">
          <mat-list-option *ngFor="let mbr of groupMembers"
		   [value]="mbr"
		   [selected]="approvalRequestExists(mbr.email)"
		   style="font-size: 0.9rem; padding 2px; margin-right: 5%">
            {{mbr.email}}
	    <span *ngIf="approvalRequestExists(mbr.email)"
		  style="font-size: 0.8rem; margin-left: 2px">Response: ({{getUserApprovalResponse(mbr.email)}})</span>
           </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </ng-template>

  
  <ng-template #selectApprovalResponse>
      <mat-form-field appearance="outline"
        style="margin-top: 3px; margin-bottom: 0px; padding-bottom: 3px">
        <mat-label style="font-size 0.75rem">Approve?</mat-label>
             <mat-select
	       [ngModel]="userApprovalResponse"
               [ngModelOptions]="{standalone: true}"
	       ngDefaultControl selected="getUserApprovalResponse(userInfo.email)"
	       [value]="getUserApprovalResponse(userInfo.email)"
               style="z-index: 900; color: #2274A5; font-size: 0.8rem; width: 85%; font-size: 1rem; border-bottom: 2px solid #2274A5;; margin-left: 1rem; margin-right: 1rem;">
       <mat-option value="P"
              (onSelectionChange)="approvalResponseChange($event)"
	      style="color: #2274A5; font-size: 0.9rem">
              Pending Review</mat-option>
       <mat-option value="Y"
              (onSelectionChange)="approvalResponseChange($event)"	      
	      style="color: #2274A5; font-size: 0.9rem">
              Yes (Approve)</mat-option>
       <mat-option value="N"
              (onSelectionChange)="approvalResponseChange($event)"	      
	      style="color: #2274A5; font-size: 0.9rem">
	      No (Disapprove)</mat-option>
             </mat-select>
      </mat-form-field>
  </ng-template>

  <!-- Edit Approval Requests -->
  <ng-template #editRequestsBtn>
     <span style="align: center">
       Edit Approver List
     </span>
  </ng-template>

  <ng-template #approvalCounters>
     <button id="approval-counters-btn" mat-stroked-button	      
       style="display: flex; flex-flow: row; justify-content: center; align-items: center; background: #DDDDDD; width: 100%;"
	      (click)="showApprovalStatus($event)">
<!--       <div style="display: flex; flex-flow: row; justify-content: space-between; align-items: center; background: #DDDDDD; width: 100%;">	 -->
         <span style="margin-left: 0px;">Pending:</span>
         <span style="margin-left: 3px;">{{getResponseCount(approvalsPending)}}</span>
         <span style="margin-left: 16px;" id="approval-scope-title">Yes:</span>
        <span style="margin-left: 3px; color: green;">{{getResponseCount(approvalsYes)}}</span>
         <span style="margin-left: 16px;" id="approval-scope-title">No:</span>
         <span style="margin-left: 3px; color: red;">{{getResponseCount(approvalsNo)}}</span>
<!--      </div> -->
     </button>
  </ng-template>

  
  <ng-template #approvalOwnerTemplate let-text>
<!--    <div style="display: flex; flex-flow: column; justify-content: center; align-items: center">
 -->
   <div style="display: flex; flex-flow: column" class="left-right-space pad8">
     <div *ngIf="template!==templateNewApproval"
          style="margin-top: 5px; margin-bottom: 5px;">
         <ng-content *ngTemplateOutlet="approvalCounters">
         </ng-content>
      </div>	   
      <div *ngIf="template===templateNewApproval">
 	<div *ngTemplateOutlet="selectApprovalScope">
	</div>
      </div>
    <div style="display: flex; flex-flow: column; justify-content: space-between; align-items: stretch;">

<!-- Add Approval Requests *ngIf="template!==templateNewApproval" -->      
     <button 
	id="edit-requests" mat-stroked-button	      
       style="display: flex; flex-flow: row; justify-content: center; align-items: center; background: #DDDDDD;"
	      (click)="lastTemplate=template;showApprovalRequests($event)">
            <mat-icon>group</mat-icon>	
	    <span *ngIf="template===templateNewApproval; else editRequestsBtn">
                Select Approver List
            </span>
        <!-- <mat-icon style="align-self: flex-end;">
	  {{ showRequests ? 'arrow_drop_up' : 'arrow_drop_down' }}
	  </mat-icon> -->
      </button>

      <div *ngIf="template!==templateNewApproval && showRequests">
	<div *ngTemplateOutlet="selectApprovalScope">
	</div>
      </div>
      
    </div>
	<!--        <input style="align-self: flex-end"  id="edit-requests" type="submit" value="Requests" (click)="showRequests = !showRequests"/> -->
  <ng-container *ngIf="approvalRequestExists(userInfo.email)">
      <ng-content *ngTemplateOutlet="selectApprovalResponse">
      </ng-content>
    </ng-container>
   </div>
  </ng-template>

  <ng-template #approvalUserTemplate>
    <div style="display: flex; flex-flow: column" class="left-right-space pad8">
    <!--
    <div style="display: flex; flex-flow: row; margin-top: 5px;">
        <ng-container *ngTemplateOutlet="displayApprovalScope">
        </ng-container>
    </div>
    -->
    <ng-content *ngTemplateOutlet="approvalCounters">
    </ng-content>

   <ng-container *ngIf="!showRequests && approvalRequestExists(userInfo.email)">
      <ng-content *ngTemplateOutlet="selectApprovalResponse">
      </ng-content>
   </ng-container>
    </div>
  </ng-template>

  
  <div class="detail-container"
       *ngIf="template===templateNewApproval">
    <div class="marker-title color-{{itemType}}-dark pad4">
     <div style="display: flex; flex-flow: column; justify-content: flex-start">
        <div>{{itemData.title}}</div>
        <div *ngTemplateOutlet="displayApprovalScope"></div>
     </div>
      <ng-content *ngTemplateOutlet="helpTemplate">
      </ng-content>
    </div>
    
        <div class="left-right-space pad8">
	  <!-- (selectionChange)="setApprovalsScope(itemData.approvalsScope)"> -->
	  <ng-container *ngTemplateOutlet="approvalOwnerTemplate">
	  </ng-container>
	</div>
        <div *ngIf="showRequests===false" class="left-right-space pad8">
           <label style="margin-top: 5px;" id="add-comment-title" >Title</label>
           <input id="title-{{name}}" class="input-title" type="textbox" maxlength="28" [(ngModel)]="itemData.title" required>
           <label id="add-comment-title">Description</label>
           <textarea #descrText id="description-{{name}}" class="input-description" rows="10" [(ngModel)]="itemData.description"></textarea>
	   <div class="marker-button-row">
             <input id="submit-{{name}}" class="add-item-button" type="submit" value="Add {{itemDisplay}}" (click)="emitSubmit($event)"/>
             <input id="link-{{name}}" class="add-item-button" type="submit" value="Add Link" (click)="emitLink($event)"/>
	     <!--
             <button mat-stroked-button id="select-text-btn-{{name}}"
		     style="padding: 0"
		     class="add-item-button" (click)="emitSelectText($event)">
	       <mat-icon
		 style="margin: 0px;"
		 fontSet="material-symbols-outlined">
		 text_select_move_forward_character
	       </mat-icon>
	       Text
	     </button>
             -->
             <input id="cancel-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="emitCancel($event)"/>
	   </div>
        </div>
  </div> <!--- NEW APPROVAL MARKER --->

  
    <div class="detail-container"
       *ngIf="template===templateEditApproval">
      <div class="marker-title color-{{itemType}}-dark pad4">
	  <div style="display: flex; flex-flow: column; justify-content: flex-start">
            <div>{{itemData.title}}</div>
            <div *ngTemplateOutlet="displayApprovalScope"></div>
          </div>
           <ng-content *ngTemplateOutlet="helpTemplate">
           </ng-content>
           <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email">
	      <a style="color:white; text-decoration: none;"
	         href='#' id="remove_{{id}}"
	         (click)="lastTemplate=template;template=templateDeleteMarker;scrollToDialog('delete-marker-dialog')">		 
                <span class="material-icons">delete</span>
              </a>
           </div>
        </div>
        <div class="left-right-space pad8">
	  <ng-container *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email; else approvalUserTemplate" >
	    <ng-container *ngTemplateOutlet="approvalOwnerTemplate">
	    </ng-container>
	  </ng-container>
           <label id="add-comment-title" >Title</label>
           <input id="title-{{name}}" class="input-title" type="textbox" maxlength="28" [(ngModel)]="itemData.title" required>
           <label id="add-comment-title">Description</label>
           <textarea #descrText id="description-{{name}}" class="input-description" rows="10" [(ngModel)]="itemData.description"></textarea>
	   
	   <div class="marker-button-row">
             <input id="submit-{{name}}" class="edit-item-button" type="submit" value="Save" (click)="submitEdit($event)"/>	     	     
             <input id="link-{{name}}" class="edit-item-button" type="submit" value="Add Link" (click)="emitLink($event)"/>
             <input id="cancel-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="emitCancel($event)"/>
	   </div>
        </div>
	
	<ng-container *ngIf="itemData.comments">
          <div *ngFor="let cmt of itemData.comments; index as i"
	       class="color-{{itemType}}-comment margin8">
	    <div style="display: flex; flex-flow: row;">
           <div class="left-right-space pad8">
             <div><b>{{cmt.owner}}</b><br>
	       <span id="time">{{cmt.created_at | date:'short'}}</span>
	     </div>
           </div>
  	    <div *ngIf="cmt.owner!=='' && cmt.owner===userInfo.email">
              <span class="material-icons"
		    style="color: #C10712;"
		    (click)="lastTemplate=template;selectedComment=i;template=templateDeleteComment;scrollToDialog('delete-comment-dialog')">delete</span>
	    </div>
	   </div>
<!--         <div class="left-right-space pad8">
                {{cmt.comment}}
		</div> -->	   
             <div class="left-right-space pad8" [innerHTML]="cmt.comment | linkify">
             </div>
        </div>
	</ng-container>
  </div> <!--- EDIT APPROVAL --->

  <div class="detail-container"
       *ngIf="template===templateAddApprovalComment">
    <div class="marker-title color-{{itemType}}-dark pad4">
    <div style="display: flex; flex-flow: column; justify-content: flex-start">
         <div>{{itemData.title}}</div>
         <div *ngTemplateOutlet="displayApprovalScope"></div>
    </div>
        <div style="display: flex; flex-flow: row; justify-content: flex-end;">
	    <div *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email; else helpTemplate">
	      <a style="color:white; text-decoration: none;"
	         href='#' id="menu_{{id}}"
		 [matMenuTriggerFor]="markerMenu">
                <span class="material-icons">menu</span>
              </a>
	    </div>
         </div>
      </div>
      <div class="left-right-space pad8" >
	  <ng-container *ngIf="itemData.owner!=='' && itemData.owner===userInfo.email; else approvalUserTemplate" >
            <ng-container *ngTemplateOutlet="approvalOwnerTemplate">
          </ng-container>
	  </ng-container>
          <div><b>Description by {{itemData.owner}}</b><br>
	    <span id="time">{{itemData.created_at | date:'short'}}</span>
	  </div>
        </div>
        <div *ngIf="itemData.description && itemData.description !== null"
	     class="left-right-space pad8" >
	  <span class="description"
		[innerHTML]="itemData.description | linkify">
	  </span>    
        </div>

	<ng-container *ngIf="itemData.comments">
          <div *ngFor="let cmt of itemData.comments; index as i"
	       class="color-{{itemType}}-comment margin8">
	    <div style="display: flex; flex-flow: row;">	    
           <div class="left-right-space pad2">
             <div><b>{{cmt.owner}}</b><br>
	       <span id="time">{{cmt.created_at | date:'short'}}</span>
	     </div>
           </div>
	    <div *ngIf="cmt.owner!=='' && cmt.owner===userInfo.email">
              <span class="material-icons"
		    style="color: #C10712;"
		    (click)="lastTemplate=template;selectedComment=i;template=templateDeleteComment;scrollToDialog('delete-comment-dialog')">delete</span>
	    </div>
	   </div>
<!--         <div class="left-right-space pad8">
                {{cmt.comment}}
             </div> -->
             <div class="left-right-space pad2" [innerHTML]="cmt.comment | linkify">
             </div>
        </div>
	</ng-container>
	
        <div class="marker-button-row">
          <div class="marker-button-column">
            <label id="add-comment-title">Add Comment</label>	  
<!--            <input class="input-add-comment" type="text" inputmode="text" placeholder="add comment" [(ngModel)]="comment"> -->
            <input #commentText class="input-add-comment" type="text" inputmode="text" placeholder="" [(ngModel)]="comment">	    
	  </div>
        </div>
	  
        <div class="marker-button-row">
          <input id="submit-{{name}}" class="updt-item-button" type="submit" value="Save" (click)="lastTemplate=template;submitComment($event);template=lastTemplate"/>
          <input id="link-{{name}}" class="updt-item-button" type="submit" value="Add Link" (click)="emitCommentLink($event)"/>	  
          <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Close" (click)="emitCancel($event)"/>
        </div>
	
  </div> <!--- ADD APPROVAL COMMENT --->


  <div id="edit-approval-requests" class="detail-container"
       *ngIf="template===templateEditApprovalRequests">
    <div class="marker-title color-{{itemType}}-dark pad4">
      <div style="display: flex; flex-flow: column; justify-content: flex-start">
         <div>{{itemData.title}}</div>
         <div *ngTemplateOutlet="displayApprovalScope"></div>
      </div>
      <div style="display: flex; flex-flow: row; justify-content: flex-end;">
          <ng-content *ngTemplateOutlet="helpTemplate">
          </ng-content>
      </div>
    </div>
     <div class="left-right-space pad8">
        <div *ngIf="showRequests">
          <ng-container *ngTemplateOutlet="editApprovalRequests">
          </ng-container>
        </div>
      </div>
      <div class="marker-button-row">
         <input id="submit-{{name}}" class="add-item-button" type="submit" value="Done" (click)="hideApprovalRequests();template=lastTemplate"/>
<!--
         <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="hideApprovalRequests();template=lastTemplate"/>
 -->
      </div>
   </div> <!--- EDIT APPROVAL REQUESTS --->

  <div id="show-approval-status" class="detail-container"
       *ngIf="template===templateShowApprovalStatus">
    <div class="marker-title color-{{itemType}}-dark pad4">
      <div style="display: flex; flex-flow: column; justify-content: flex-start">
         <div>{{itemData.title}}</div>
         <div *ngTemplateOutlet="displayApprovalScope"></div>
      </div>
      <div style="display: flex; flex-flow: row; justify-content: flex-end;">
          <ng-content *ngTemplateOutlet="helpTemplate">
          </ng-content>
      </div>
    </div>
     <div class="left-right-space pad8">
      <div style="display: flex; flex-flow: row; justify-content: space-around; margin-top: 3px;">
        <mat-nav-list #approvalStatusList
   style="width 85%; max-height: 50vh; overflow-y: auto; overflow-x: none; font-size: 0.5rem; margin-left: 1rem; margin-right: 0.5rem">
          <mat-list-item *ngFor="let ap of itemData.approvals"
		   style="font-size: 0.9rem; padding 2px; margin-right: 5%">
                  {{ap.responderEmail}}
	    <span *ngIf="approvalRequestExists(ap.responderEmail)"
		  style="font-size: 0.8rem; margin-left: 2px">Response: ({{getUserApprovalResponse(ap.responderEmail)}})</span>
           </mat-list-item>
        </mat-nav-list>
       </div>
     </div>
      <div class="marker-button-row">
         <input id="submit-{{name}}" class="add-item-button" type="submit" value="Done" (click)="hideApprovalStatus()"/>
<!--
         <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="hideApprovalRequests();template=lastTemplate"/>
 -->
      </div>
   </div> <!--- SHOW APPROVAL STATUS --->
  
  <!---------- TEMPLATES FOR DELETE OPERATIONS -------------->
  
    <div id="delete-marker-dialog" class="detail-container"
       *ngIf="template===templateDeleteMarker">
        <div class="marker-title color-{{itemType}}-dark pad4">    
          <div>Delete this Marker?</div>
        </div>
        <div class="left-right-space pad8">
          <span id="add-comment-title" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
        <div class="marker-button-row">
             <input id="delete-{{name}}" class="delete-item-button" type="submit" value="Yes" (click)="emitRemove($event)"/>
             <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="template=lastTemplate"/>
        </div>
    </div>

    <div id="delete-comment-dialog" class="detail-container"
       *ngIf="template===templateDeleteComment">
        <div class="marker-title color-{{itemType}}-dark pad4">    
          <div>Delete this Comment?</div>
        </div>
        <div class="left-right-space pad8">
          <span id="add-comment-title" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
        <div class="marker-button-row">
             <input id="delete-{{name}}" class="delete-item-button" type="submit" value="Yes" (click)="removeComment($event)"/>
             <input id="cancel-comment-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="template=lastTemplate"/>
        </div>
    </div>


    <div id="confirm-scope-dialog" class="detail-container"
       *ngIf="template===templateConfirmScope">
        <div class="marker-title color-{{itemType}}-dark pad4">    
          <div>{{confirmTitle}}</div>
        </div>
        <div class="left-right-space pad8">
          <span style="font-size: 0.8rem; margin-top: 1em; margin-left: 10%; font-size: 1em;">
	    {{confirmMessage1}}
	  </span>
          <span style="font-size: 0.8rem; margin-top: 1em; margin-left: 10%; font-size: 1em;">
	    {{confirmMessage2}}
	  </span>
        </div>
        <div class="marker-button-row">
             <input id="confirm-scope-{{name}}" class="delete-item-button" type="submit" value="Yes" (click)="template=lastTemplate; changeApprovalScope(newApprovalType);"/>
             <input id="cancel-confirm-{{name}}" class="cancel-button" type="button" value="Cancel" (click)="itemData.approvalScope=oldApprovalType; template=lastTemplate"/>
        </div>
    </div>
    
    
</div>  <!-------- MarkerDialogDiv -------->


