<!-- <ng-template [ngIf]="uiMode===UI_DESKTOP"> -->
  
  <app-main #desktop
    [uiMode]="uiMode"
    [platformType]="platformsvc.platformType"
    [deviceType]="platformsvc.deviceType"	    
    [platformOS]="platformsvc.OS"
    [platformENV]="platformsvc.ENV"
    (uiModeChange)="uiChange($event)"
    >
  </app-main>

<!-- </ng-template> --->

<!--
<ng-template [ngIf]="uiMode===UI_MOBILE"> 
  <app-main-mobile #mobile
    [uiMode]="uiMode"
    [platformType]="platformsvc.platformType"
    [platformOS]="platformsvc.OS"
    [platformENV]="platformsvc.ENV"
    (uiModeChange)="uiChange($event)"
    >    
  </app-main-mobile>  
</ng-template>
 -->


<!--
<ng-template [ngIf]="uiMode===UI_DESKTOP">
  <app-main #desktop
    [uiMode]="uiMode"
    [platformType]="platformsvc.platformType"
    [platformOS]="platformsvc.OS"
    [platformENV]="platformsvc.ENV"
    [pdfURL]="documentsvc.pdfURL"
    [pdfSrc]="documentsvc.pdfSrc"
    [pdfZoom]="documentsvc.pdfZoom"
    [pdfZoomPct]="documentsvc.pdfZoomPct"
    [pdfRotation]="documentsvc.pdfRotation"
    [pdfProgress]="documentsvc.pdfProgress"
    [snapRotation]="documentsvc.snapRotation"	    
    [activeDocument]="documentsvc.activeDocument"
    (uiModeChange)="uiChange($event)"
    >
  </app-main>
</ng-template>

<ng-template [ngIf]="uiMode===UI_MOBILE"> 
  <app-main-mobile #mobile
    [uiMode]="uiMode"
    [platformType]="platformsvc.platformType"
    [platformOS]="platformsvc.OS"
    [platformENV]="platformsvc.ENV"
    [pdfURL]="documentsvc.pdfURL"
    [pdfSrc]="documentsvc.pdfSrc"
    [pdfZoom]="documentsvc.pdfZoom"
    [pdfZoomPct]="documentsvc.pdfZoomPct"
    [pdfRotation]="documentsvc.pdfRotation"
    [pdfProgress]="documentsvc.pdfProgress"
    [snapRotation]="documentsvc.snapRotation"	    
    [activeDocument]="documentsvc.activeDocument"
    (uiModeChange)="uiChange($event)"
    >    
  </app-main-mobile>  
</ng-template>
-->

<app-settings-dialog id="settings-container"
		     style="display: none">
</app-settings-dialog>
