<div mat-dialog-title class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>
    <span class="title" >User Guest Sub-Accounts</span>
  </div>
  <span class="filler"></span>
  <div class="dialog-header-right">
   <mat-icon (click)="helpDialog()"
             matTooltip="Manage Groups Help"
             style="margin-left: 8rem;">help_outline</mat-icon>
    <mat-icon (click)="close()" style="margin-left: 0.5rem">close</mat-icon>
  </div>
  <div style="margin-left: 0rem; margin-bottom: 0.2rem; border-bottom: 1px solid #1A567A;"></div>        
</div>

<mat-dialog-content #content class="panel-class" style="margin-top: 0.2rem;">
 <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>
<!--      <div class="mobile-look"> -->
          <div id="tab-content">
            <app-subaccount-edit
	                 #subEdit
			 (displayUserInfoEvent)="displayUserInfo($event)"
			 style="overflow-y: none;">
            </app-subaccount-edit>
         </div>
<!--      </div> -->

</mat-dialog-content>
  
<mat-dialog-actions>

  <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>
  
  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 2%; font-size: 0.8rem;">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 0.em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="align-self: flex-end; margin-left: 0.8em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>
  
</mat-dialog-actions>    
