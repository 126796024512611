import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-feedback-data',
    templateUrl: './feedback-data.component.html',
    styleUrls: ['./feedback-data.component.css']
})

export class FeedbackDataComponent {
   @Output() submitEvent = new EventEmitter<any>();

    shown = 'block';
    userData: UserData;
    userInfo: UserInfo;

    /* fields */
    feedbackData = {
        experience: '',
        industry: '',
        recommend: '',
        suggest: ''
    };

    constructor(
        private http: HttpClient,
        private toast: ToasterService
    ) { }

    submitForm(e) {
       this.submitEvent.emit(e);
    }

    toggle_feedback_layer() {
        const styleid = document.getElementById('feedback-container').style.display;
        if (styleid === 'block') {
            document.getElementById('feedback-container').style.display = 'none';
        } else {
            document.getElementById('feedback-container').style.display = 'block';
        }
    }

    otherField() {
        document.getElementById('other-text-field').style.display = 'block';
    }

    cancelOther() {
        document.getElementById('other-text-field').style.display = 'none';
    }

    send_feedback() {
        this.http.post(environment.apiUrl + '/feedback',
                       JSON.stringify(this.feedbackData)
                      ).subscribe(
                        (x) => {
                            this.toast.pop('success', 'Feedback', 'Feedback submitted.');
                            this.toggle_feedback_layer();
                            this.clearFeedbackData();
                            this.submitForm('success');
                        },
                        (e) => {
                            this.toast.pop('error', 'Feedback', 'Failed to submit feedback.');
                            this.submitForm('error');
                        }
                      );

    }
    clearFeedbackData(){
        this.feedbackData = {
            experience: '',
            industry: '',
            recommend: '',
            suggest: ''
        };


    }

    // toggle_feedback() {
    //   if (this.shown === 'block') {
    //       this.shown = 'none';
    //   } else {
    //       this.shown = 'block';
    //   }

    //   document.getElementById('feedback-container').style.display = this.shown;
    // }
}
