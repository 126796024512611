                                                          import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';


export function passwordStrengthValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const hasUpperCase = /[A-Z]+/.test(value);

        const hasLowerCase = /[a-z]+/.test(value);

        const hasNumeric = /[0-9]+/.test(value);

        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;

        return !passwordValid ? {passwordStrength:true}: null;
    }
}


export function dateTimeValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const dtLength = value.length;
        const hasLength = (dtLength == 16 || dtLength == 19)
        console.log('dateTime hasLength=', hasLength);
        
        const fieldLength = value.length;

        const hasDash = /[-]+/.test(value);
        console.log('hasDash =', hasDash);

        const hasColon = /[-]+/.test(value);
        console.log('hasColon =', hasColon);

        const hasT = /[T]+/.test(value);
        console.log('hasT =', hasT);

        const hasNumeric = /[0-9]+/.test(value);
        console.log('hasNumeric =', hasNumeric);
        
        const hasMilliseconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
        console.log('hasMilliseconds =', hasMilliseconds);      
        
        const hasNoMilliseconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
        console.log('hasNoMilliseconds =', hasNoMilliseconds);
        
        const hasNoSeconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
        console.log('hasNoSeconds =', hasNoSeconds);
        
        // const hasLowerCase = /[a-z]+/.test(value);

        // const dateTimeIsValid = hasNoSeconds || hasNoMilliseconds;
        const dateTimeIsValid = (hasNoMilliseconds || hasNoSeconds)
                                && hasLength;
        return dateTimeIsValid ? {dateTime:true}: null;
    }
}

/****
updateExpirationDateField(frm: any, fld: string) {
  const epoch_time = new Date().getTime();
  const now = new Date();
  const nextMonth = new Date(now);
  
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  let snm = nextMonth.toISOString();
  snm = snm.substring(0, snm.indexOf('.'));
  let expiration = null;

  if (this.objForm) {
    if (this.objData && (this.objData.org_user_expires !== null)) {
      console.log('org-user checking ISO DateTime...');
      expiration = FORMTOOLS.checkISODateTime(this.objData.org_user_expires);
      this.objForm.get(fld).patchValue(expiration);
      console.log('updateExpirationDate set expiration=', expiration);   
    } else if (snm) {
             console.log('updateExpirationDate set expires snm=', snm);
             this.objForm.get(fld).patchValue(snm);
           } else {
               console.log('updateExpirationDate set expires to null');
               this.objForm.get(fld).patchValue(null);
             }
  }
}
****/

export function hasNoSeconds(value: string) {
       const hasNoSeconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
       return hasNoSeconds;
}

export function hasNoMilliseconds(value: string) {
       const hasNoMilliseconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
       console.log('hasNoMilliseconds =', hasNoMilliseconds);
       return hasNoMilliseconds;
}

export function hasMilliseconds(value: string) {
       const hasMilliseconds = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)/.test(value);
       console.log('hasMilliseconds =', hasMilliseconds);       
       return hasMilliseconds;
}

export function validISODate(value: string) {
       return hasNoMilliseconds(value);
}

export function checkISODateTime(value: string) {

       if (!value || value === '') {
          return null;
       }
       
       let newValue = value;
       const chkHasMilliseconds = hasMilliseconds(value);
       const chkHasNoSeconds = hasNoSeconds(value);
       const chkHasTime = /[T]+/.test(value);
       const dtLength = newValue.length;
       const chkHasLength = (dtLength == 19)

       console.log('chkHasTime =', chkHasTime);
       console.log('chkHasMilliseconds =', chkHasMilliseconds);
       console.log('chkHasNoSeconds =', chkHasNoSeconds);
       console.log('chkHasLength =', chkHasLength);
       console.log('chkHasLength =', dtLength);       
       
       if (!chkHasTime && dtLength === 10) {
         // yyyy-mm-dd
         console.log('checkISODateTime adding time to date field');
         newValue += 'T00:00:00';
       }

       if (chkHasMilliseconds) {
         console.log('checkISODateTime removing milliseconds..');
         newValue = newValue.substring(0, newValue.indexOf("."));
       }

       if (chkHasNoSeconds || (!chkHasLength && dtLength == 13)) {
         console.log('checkISODateTime adding missing minutes/seconds..');
         // YYYY-mm-ddTHH
         newValue += ':00:00';
       }

       if (chkHasNoSeconds || (!chkHasLength && dtLength == 16)) {
         console.log('checkISODateTime adding missing seconds..');       
         // YYYY-mm-ddTHH:MM
         newValue += ':00';
       }
       
       console.log('checkISODateTime newValue=', newValue);
       return newValue;
}