<div class='loginpage'>
    <div class="animation" > 
        <img src="/assets/intro-animation.gif" alt="MarkaDot Visual Intro" class="gif">
    </div>
    <div class="credentials"> 
        <img src="/assets/Markadoc-logo.200OP.png" id="img">
        <form id='loginform' [formGroup]="loginForm">
            <label>Username</label>
            <mat-form-field appearance="outline">
                <input matInput #user type="email" formControlName="user" placeholder="name@email.com">
            </mat-form-field>
            <label>Password</label>
<!--	    
            <mat-form-field appearance="outline"> 
                <input matInput #password type="password" formControlName="password" placeholder="••••">
            </mat-form-field>
 -->
             <mat-form-field appearance="outline">
                 <!-- <mat-label>Password</mat-label> -->
                 <input matInput #password type="{{pwdType}}"
                     formControlName="password" placeholder="••••"
                     autocomplete="off">
                   <mat-icon matSuffix (click)="toggle_pwd()">{{pwdIcon}}</mat-icon>
            </mat-form-field>
            <button mat-raised-button  type="submit" (click)="login(loginForm.value)" id="submit-go" style="font-size: 1.2rem">Sign In</button>
            <br/>
            <button mat-raised-button id="forgot-password" (click)="reset(loginForm.value)">Forgot password?</button>
            <div id='error-box' class='error-box'> &nbsp; </div>	    
            <button type="submit" id="submit-free" onclick="window.location.href='https://app.markadoc.com/signup'" style="font-size: 1rem;">Click here to signup for a FREE 30 day trial account</button>
            </form>

    </div>
</div>
