import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserInfo } from '../model/userinfo.model';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'any'})

export class UserService {

   baseUrl = environment.apiUrl;
   isValid: any;

   public User: UserInfo = null;
   public UserList: UserInfo[] = null;

   UserSystemCnt: CountData = null;
   UserStorageSum: SumData = null;
   
   //User: UserInfo;   
   //UserList: UserInfo[];

   private userListItems$: BehaviorSubject<UserInfo[]> = new BehaviorSubject<UserInfo[]>(this.UserList);

   public userList$: Observable<UserInfo[]> = this.userListItems$.asObservable();

   private userItem$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(this.User);

   public userInfo$: Observable<UserInfo> = this.userItem$.asObservable();

  
   private userSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.UserSystemCnt);

   userSystemCount$: Observable<CountData> = this.userSystemCountSubj$.asObservable();

   constructor(
      private auth: AuthService,
      private http: HttpClient
      ) { }


  getAllUserAccts$(){
   this.http.get<UserInfo[]> (this.baseUrl + '/user')
   .subscribe(res => {
                       // let item = this.orgListItems$;
                       this.UserList = res;
                       this.userListItems$.next(res);
                       console.log('user acct list =', res);
                       return res as UserInfo[];
             })
  }
   // GET ALL USERS AS UserInfo[]
   //
   getAllUsers$(uuid: string) {
      return this.http.get(environment.apiUrl + '/user' );
   }


   getAllUsersPromise(): Promise<any> {
      return this.http.get<UserInfo[]>
             (environment.apiUrl + '/user').toPromise()
             .then(
                (x) => {
                    this.UserList = x;
                    this.userListItems$.next(x);
                    return x as UserInfo[];
                },
                (e) => {
                    console.log('usersvc failed to get all user(s), error: ', e);
                }
            );
   }

   async getAllUsers(): Promise<UserInfo[]> {
      let x: UserInfo[] = [];
      try {
         x = await this.getAllUsersPromise();
         // console.log('usersvc getAllUsers x=', x);
      } catch (e) {
          console.log('usersvc getAllUsers error=', e);
          throw e;
        }
      return x;
   }

   // GET USER INFO BY EMAIL
   //
   getUserByEmail$(email: string) {
      return this.http.get(environment.apiUrl + '/user/email/' + email );
   }

   getUserByEmailPromise(email: string): Promise<any> {
      console.log('usersvc getUserByEmail email =', email);
      return this.http.get<UserInfo>
             (environment.apiUrl + '/user/email/' + email ).toPromise()
             .then(
                (x) => {
                    console.log('getuserbyemail x=', x);
                    this.User = x as UserInfo;
                    this.userItem$.next(x);
                    return x as UserInfo;
                },
                (e) => {
                    console.log('usersvc failed to get user by email, error: ', e);
                }
            );
   }

   async getUserByEmail(email: string) {
      let x = null;
      try {
         x = await this.getUserInfoPromise(email);
      } catch (e) {
          console.log('usersvc getUserByEmail error=', e);
          throw e;
        }
      return x;
   }

   // GET USER INFO BY UID
   // Returns Observable of UserInfo
   getUserInfo$(uid: string): Observable<UserInfo> {
      // console.log('usersvc getUserInfo$ uid=', uid);
      return this.http.get<UserInfo>(environment.apiUrl + '/user/' + uid );
   }

   // GET USER INFO BY email
   // Returns Observable of UserInfo
   getUserInfoByEmail$(em: string): Observable<UserInfo> {
      // console.log('usersvc getUserInfoByEmail$ em=', em);
      const obs = this.http.get<UserInfo>(environment.apiUrl + '/user/email/' + em);
      // console.log('usersvc getUserInfoByEmail$ obs=', obs);      
      return obs;
   }

   /** GET hero by id. Will 404 if id not found 
   getHero(id: number): Observable<Hero> {
     const url = `${this.heroesUrl}/${id}`;
     return this.http.get<Hero>(url).pipe(
     tap(_ => this.log(`fetched hero id=${id}`)),
     catchError(this.handleError<Hero>(`getHero id=${id}`))
    );
   } ***/

   getUserInfoSubscribe(uuid: string) {
      return this.http.get<UserInfo>
             (environment.apiUrl + '/user/' + uuid )
             .subscribe(
                (x) => {
                    return x as UserInfo;
                },
                (e) => {
                    console.log('usersvc failed to get user(s), error: ', e);
                }
            );
   }

   // Alternate example to get observable..
   // getCategories(): Observable<Category[]> {
   // const url = 'https://www.themealdb.com/api/json/v1/1/categories.php';
   //    return this.httpClient.get<CategoriesResponse>(url).pipe(
   //     map(response => response.categories)
   //     );
   // }

   getUserInfoObservable(uuid: string): Observable<UserInfo> {
      return this.http.get<UserInfo>
             (environment.apiUrl + '/user/' + uuid )
             .pipe( map(x => {
               return x as UserInfo;
             }
             ));
   }

   // rxjs <= v7
   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.usersvc.getUserInfoPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getGroups Observable
   // and use the following on the component:
   // }
   // async loadGroups() {
   //   const userInfo$ = this.usersvc.getUserInfoObservable(uuid);
   //   this.groups = await lastValueFrom(userInfo$);
   // }
   getUserInfoPromise(uuid: string): Promise<any> {
      return this.http.get<UserInfo>
             (environment.apiUrl + '/user/' + uuid ).toPromise()
             .then(
                (x) => {
                    this.User = x;
                    this.userItem$.next(x);
                    return x;
                },
                (e) => {
                    console.log('usersvc failed to get user(s), error: ', e);
                    throw e;
                }
            );
   }

   async getUserInfo(uuid: string) {
      let x = null;
      try {
         x = await this.getUserInfoPromise(uuid);
      } catch (e) {
          console.log('usersvc getUserInfo error=', e);
          throw e;
        }
      return x;
   }

   addListItem(new_data: any) {
     this.UserList.push(new_data);
     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.UserList = Object.assign([], this.UserList);
   }

   addUserInfoPromise(info): Promise<any> {
        return this.http.post(environment.apiUrl + '/user', info)
            .toPromise()
            .then(
                (x) => {
                // console.log('usersvc add org x=: ', x);
                // Next update the OrgList item
                this.addListItem(x);
                // Update List Behavior Subject
                this.userListItems$.next( this.UserList );
                // Update Item BehaviorSubject
                this.userItem$.next( x as UserInfo );
                // return data
                return x;
                },
                (e) => {
                    console.log('usersvc failed to create user: ', info.email,
                            ' error:', e);
                    throw e;
                }
            );
   }

   async addUserInfo(info) {
      let x = null;
      try {
        x = await this.addUserInfoPromise(info);
      } catch (e) {
          console.log('usersvc: updateUserInfo error=', e);
          throw(e);
        }
      return x;
   }

   updateListItem(newItem) {
     if ( this.UserList ) {
        let idx = -1;
        idx = this.UserList.findIndex(item => item.uid === newItem.uid);
        if ( idx >= 0 ) {
          this.UserList[idx] = newItem;
          // some angular libraries require breaking the array reference
          // to pick up the update in the array and trigger change detection.
          // In that case, you can do following
          this.UserList = Object.assign([], this.UserList);
        }
     }   
   }

   updateUserInfoPromise(uuid, info) {
        const jdata = JSON.stringify( info );
        // Updated to use JSON format data
        return this.http.put(environment.apiUrl + '/user/' + uuid, jdata)
            .toPromise()
            .then(
                (x) => {
                    // console.log('usersvc: updateUser uid=', uuid);              
                    // Update item if it is in UserList         
                    this.updateListItem(x);
                    // Update UserList behavior subject
                    this.userListItems$.next( this.UserList )
                    // Update Item subject
                    this.userItem$.next( x as UserInfo );
                    // return updated data
                    return x as UserInfo;                   
                },
                (e) => {
                    console.error('usersvc failed to edit user: ', info.email,
                            ' error:', e);
                    throw e;
                }
            );
   }

   async updateUserInfo(uuid, info) {
      let x = null;
      try {
        x = await this.updateUserInfoPromise(uuid, info);
      } catch (e) {
          console.log('usersvc: updateUserInfo error=', e);
          throw(e);
        }
      return x;
   }

   removeListItem(del_uid: any) {
     const idx = this.UserList.findIndex(obj => obj.uid === del_uid);
     this.UserList.splice(idx, 1);
     this.UserList = Object.assign([], this.UserList);
   }

   deleteUserInfoPromise(uuid) {
        return this.http.delete(environment.apiUrl + '/user/' + uuid)
            .toPromise()
            .then(
                (x) => {
                  // Next remove the DocumentList item
                  this.removeListItem(uuid);
                  // Update List Subject/Behavior Subject
                  this.userListItems$.next( this.UserList );
                  // return result
                  return x;
                },
                (e) => {
                    console.log(e);
                    throw e;
                }
            );
   }

   async deleteUserInfo(uuid) {
      let x = null;
      try {
        x = await this.deleteUserInfoPromise(uuid);
      } catch (e) {
          console.log('usersvc: deleteUserInfo error=', e);
          throw e;
        }
      return x;
   }

   updateUserPasswordPromise(uuid, pwd) {
      const pwdinfo = JSON.stringify({ password: pwd });
      return this.http.put(environment.apiUrl + '/user/' + uuid, pwdinfo)
            .toPromise()
            .then(
                (x) => {
                    return x;
                },
                (e) => {
                    throw e;
                }
            );
   }

   async updateUserPassword(uuid, pwd) {
      let x = null;
      try {
         x = await this.updateUserPasswordPromise(uuid, pwd);
         return x;       
      } catch (e) {
          console.log('usersvc: updateUserPassword error=', e);
          throw e;
        }
   }

   updateUserNotificationsPromise(uuid: string, setting: boolean) {
      const notify = JSON.stringify({ notify: setting });
      return this.http.put(environment.apiUrl + '/user/' + uuid, notify)
            .toPromise()
            .then(
                (x) => {
                    return x;
                },
                (e) => {
                    throw e;
                }
            );
   }

   async updateUserNotifications(uuid, notify) {
      let x = null;
      try {
         x = await this.updateUserNotificationsPromise(uuid, notify);
         // this.toast.pop('success', 'Update User', 'User notification preference updated.');
         this.auth.clearReset();
      } catch (e) {
          // this.toast.pop('error', 'Update User', 'Failed to update user notification preference.');
          console.log('usersvc: updateUserPassword error=', e);
        }
      return x;
   }

   private handleError<T>(operation = 'operation', result?: T) {
     return (error: any): T => {

     // TODO: better job of transforming error for user consumption
     console.log(`${operation} failed: ${error.message} error: ${error}`);

     // Let the app keep running by returning an empty result.
     return (result as T);
  };
  }

  // *** Stats methods

   getUserSystemCount$(){
      this.http.get<any>(environment.apiUrl + '/user/count/system')
      .subscribe(cnt => {
                       console.log('user cnt =', cnt);
                       this.userSystemCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }

} // user.service
