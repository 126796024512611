/* eslint-disable */
export class DocumentData {
    uid: string;
    md5: string;
    filename: string;    // wasabi object name
    filetype?: string;   // NEW
    version_id?: string; // NEW wasabi object version id
    name: string;        // users file name
    description: string; // users file description
    org_uid?: string;    // NEW
    groups_uid: string;  
    users_uid: string;
    timestamp: number;
    size: number;
    url?: string;        // NEW for future web page url markup
    owner?: string;      // Not persisted in DB, mapped in UI (for now)
}
