// CJ -Class for driving file selection, conversion, merge and upload
import * as FSC from './fileselector.constants';

export class FileSelector {


    constructor(f: File) {
      this.IMAGE_ROTATIONS = FSC.IMAGE_ROTATIONS;
      this.IMAGE_SIZES   = FSC.IMAGE_SIZES;
      this.imageSize     = FSC.SIZE_ACTUAL;
      this.imageRotation = FSC.ROTATE_NONE;
      this.imageRotation = FSC.EXIF_ORIENTATION_TOP_LEFT;
      this.addMargins    = FSC.NO_MARGINS;
      this.file = f;
      if (f.name) {
         this.fileName = f.name;
      }
      if (f.type) {
         this.fileType = f.type;
      }
      this.createUploadBlob(this.file);
    }
    // These will equate to pdf-lib api/sizes.ts

    file: File    = null;
    uploadBlob: Blob    = null;
    fileName: string  = null;
    fileType: string  = null;
    imageSize: number  = FSC.SIZE_ACTUAL;    imageRotation: number  = FSC.ROTATE_NONE;
    imageOrientation: number  = FSC.EXIF_ORIENTATION_TOP_LEFT;
    pdfPages: string  = null;
    public validType: true;
    processed = false;
    showFileProgress = false;
    addMargins = false;
    fileProgress  = 0;
    // imgPath: any;
    public tmpURL: any;
    public imgURL: any;


    exifData: any;
    exifTags: any;
    IMAGE_SIZES: any;
    IMAGE_ROTATIONS: any;

    static getDefaultImageSizeText() {
     // Eventualy check Org/User preference here
      return FSC.DESC_SIZE_LETTER;
    }
    static getDefaultImageRotationText() {
     // Eventualy check Org/User preference here
      return FSC.DESC_ROTATE_NONE;
    }
    static getDefaultImageSize() {
      return FSC.SIZE_LETTER;
    }
    static getDefaultImageRotation() {
      return FSC.ROTATE_NONE;
    }
    static getDefaultImageAddMargins() {
      return false;
    }
    static getDims(s) {
      const data = FSC.IMAGE_SIZES.find(x => x.size === s);
      return data.dims;
    }
    static getImageSizes() {
        return FSC.IMAGE_SIZES;
    }
    static getImageRotations() {
        return FSC.IMAGE_ROTATIONS;
    }

    async createUploadBlob(f) {
      this.uploadBlob = await new Blob( [f], {type: this.fileType} );
      if (this.isImage()) {
         await this.getImageURL(this.uploadBlob);
      }
      console.log('fs new blob=', this.uploadBlob);
      console.log('fs new imgURL=', this.imgURL);
    }
    isImage() {
      if ( this.isPNG() || this.isJPG() ) {
         return true;
      }
      else {
         return false;
      }
    }
    isPNG() {
       if (this.file) {
         if (  ( this.file.type === FSC.MIME_TYPE_PNG  ||
                 this.file.type === FSC.MIME_TYPE_XPNG )) {
                return true;
         }
         else {
                return false;
         }
      } else {
          return null;
       }
    }
    isJPG() {
       if (this.file) {
         if (  ( this.file.type === FSC.MIME_TYPE_JPG  ||
                 this.file.type === FSC.MIME_TYPE_XJPG )) {
                return true;
         }
         else {
                return false;
         }
      } else {
          return null;
       }
    }
    isTIFF() {
       if (this.file) {
         if ( this.file.type === FSC.MIME_TYPE_TIF ) {
                return true;
         }
         else {
                return false;
         }
      } else {
          return null;
       }
    }
    isPDF() {
       if (this.file) {
         if ( this.file.type === FSC.MIME_TYPE_PDF ) {
                return true;
         }
         else {
                return false;
         }
      } else {
          return null;
       }
    }
    ab2str(buf): string {
      // Note: CJ for NLS/ILS char sets might have to go Uint16 but for
      // majority installations this works
      return String.fromCharCode.apply(null, new Uint8Array(buf));
    }
    isValidPDF(buf: ArrayBuffer) {
       // Test a buffer to see if it has a PDF header
       const header = this.ab2str(buf);
       // console.log('header=', header);
       const code = header.indexOf('%PDF');
       // console.log('code=', code);
       if ( code === -1 ) {
         return false;
       } else {
           return true;
         }
    }
    isImageSizeActual(){
      if (this.imageSize === FSC.SIZE_ACTUAL) {
         return true;
      }
      else {
         return false;
      }
    }
    isSize4X6(){
      if (this.imageSize === FSC.SIZE_4X6) {
         return true;
      }
      else {
         return false;
      }
    }
    isSize5x7(){
      if (this.imageSize === FSC.SIZE_5X7) {
         return true;
      }
      else {
         return false;
      }
    }
    isSize6x9(){
      if (this.imageSize === FSC.SIZE_6X9) {
         return true;
      }
      else {
         return false;
      }
    }
    isSize8X10(){
      if (this.imageSize === FSC.SIZE_8X10) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeLedger(){
      if (this.imageSize === FSC.SIZE_LEDGER) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeLetter(){
      if (this.imageSize === FSC.SIZE_LETTER) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeLegal(){
      if (this.imageSize === FSC.SIZE_LEGAL) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeExecutive(){
      if (this.imageSize === FSC.SIZE_EXECUTIVE) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeA2(){
      if (this.imageSize === FSC.SIZE_A2) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeA3(){
      if (this.imageSize === FSC.SIZE_A3) {
         return true;
      }
      else {
         return false;
      }
    }
    isSizeA4(){
      if (this.imageSize === FSC.SIZE_A4) {
         return true;
      }
      else {
         return false;
      }
    }
    setImageSize(s: number) {
      this.imageSize = s;
    }
    setImageRotation(r: number) {
      this.imageRotation = r;
    }
    setAddMargins(m: boolean) {
      this.addMargins = m;
    }
    getAddMargins() {
      return this.addMargins;
    }
    getImageSize() {
      return this.imageSize;
    }
    getImageRotation(r) {
      return this.imageRotation;
    }
    getFile() {
      return this.file;
    }
    setUploadBlob(b) {
      this.uploadBlob = b;
      // this.getImgURL(this.uploadBlob);
      // this.loadImg(this.selectedImageRotation);
    }
    getUploadBlob() {
      return this.uploadBlob;
    }
    async getImageURL(bloborfile) {
      // if isImg load the File Preview
      const isImg = this.isImage();
      let url   = null;
      // console.log("getImageURL isimg=", isImg);
      if (isImg) {
        const reader = new FileReader();
        reader.onload = () => {
           url = reader.result as string;
           this.imgURL = url;
           // console.log("getImageURL finished url=", this.imgURL);
        };
        reader.onerror = () => {
           console.log('getTmpURL treader error=');
        };
        await reader.readAsDataURL(bloborfile);
      }
      // console.log("getImageURL this=", this);
      // console.log("getImageURL imgURL=", this.imgURL);
      // console.log("getImageURL typeof tmpURL=", typeof this.imgURL);
    }
    async getTmpURL(bloborfile) {
      console.log('getTmpURL for=', bloborfile);
      // if isImg load the File Preview
      const isImg = this.isImage();
      let url   = null;
      if (isImg) {
        const treader = new FileReader();
        console.log('getTmpURL begin read');
        treader.onload = () => {
           // console.log("getTmpURL loaded");
           url = treader.result as string;
           this.tmpURL = url;
           // console.log("getTmpURL finished url=", this.tmpURL);
        };
        treader.onerror = ()  => {
           console.log('getTmpURL treader error=');
        };
        await treader.readAsDataURL(bloborfile);
      }
      // console.log("getTmpURL this=", this);
      // console.log("getTmpURL tmpURL=", this.tmpURL);
      // console.log("getTmpURL typeof tmpURL=", typeof this.tmpURL);
    }
}
