import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortkey",
})
export class SortKey implements PipeTransform {
  transform(items: any[], key: string, order: "asc" | "desc" = "asc"): any[] {
    // console.log('sortkey items=', items);
    return items.sort((n1, n2) => {
      if (order === "asc") {    
         if (n1[key] > n2[key]) {
           return 1;
         }
         if (n1[key] < n2[key]) {
           return -1;
         }
      } else if (order === "desc") {
           if (n2[key] > n1[key]) {
             return 1;
           }
           if (n2[key] < n1[key]) {
             return -1;
           }
        }
      return 0;
    });
  }
}
