<!-- <toaster-container></toaster-container> -->
<div id='share-container' class="share-container">
    <div class='share-content'>
      <!-- BLUE/GREEN HEADER -->
      <!-- omit for mobile and dialog
        <div class="share-header"  id="header-color">
            SHARE DOCUMENT <mat-icon id="close" (click)="close()" >close</mat-icon>
        </div>
       -->
        <!-- ON CONFIRM ANIMATION -->
        <div id="confirm-content">
            <div style="text-align: center; font-size: 1.5rem;">SENT</div>
            <div style="text-align: center; padding-top: 15px;">
                <img src="/assets/send-animation-icon.svg" id="send-animation-icon"style=" height:auto; width: 128px;">
            </div>
            <div id="sent-dialogue">
                Your Document was sent! <br><br> 
                We sent an email to each recipient, so it may take some time to show up in your email.
            </div>
        </div>
        <!-- ON ERROR ANIMATION -->
        <div id="error-content"
	     style="display: flex; flex-flow: column; justify-content: center; align-items: center; background: red;">
          <div style="text-align: center; font-size: 1.5rem; color: white;">
	    ERROR</div>
	    <div>
            <mat-icon id="error-icon"
		style="margin-left: 42%; padding-top: 1rem; font-size: 4rem; color: white;">
		error_outline
            </mat-icon>
	    </div>
            <div id="sent-dialogue" style="color: white">
                Your Document invitation was NOT sent! <br><br> 
                Please check the email address(es) try again.<br>
		Or try again later.
		<BR><BR>
            </div>
        </div>
        <!-- SEND CONTENT -->
        <div id="content_1">
            <div class="document-label pad16">
                <b *ngIf="documentData">Document "{{documentData.name}}" </b>
                <b *ngIf="groupData">Group "{{groupData.name}}" contains:</b>
            </div>
            <div class="count-container horizontal-pad16">
                <div class="pad8">
                    <div class="count-box issue-count">{{overlayData.issue.length}}</div>
                    <div class="count-label issue-label">ISSUES</div>
                </div>
                <div class="pad8">
                    <div class="count-box question-count">{{overlayData.question.length}}</div>
                    <div class="count-label question-label">QUESTIONS</div>
                </div>
                <div class="pad8">
                    <div class="count-box note-count">{{overlayData.note.length}}</div>
                    <div class="count-label note-label">NOTES</div>
                </div>
            </div>
            <div class="horizontal-pad8">
              <p>Please enter the email addresses of people you to want to send a link to so they can collaborate on this document. They will also be able to access other documents in the group folder</p>
              <p>NOTE FOR ANDROID: To make another email text field appear, please press the enter key when you finish entering the email on a text form</p>	      
                <div class="pad16 recipient-list">
                    Select recipients:
                    <div *ngFor="let user of recipientEmails; let i = index">
                        <input matInput placeholder="Enter Email" (keydown)="update_recipients()" [(ngModel)]="user.email" class="email-input margin4" type="email">
                        <mat-icon *ngIf="i" (click)="remove_recipient(i)" style="margin: auto">close</mat-icon>
                    </div>
                </div>
            </div>
            <div class="vertical-pad8 horizontal-pad16">
                <div class="horizontal-pad16">
                    A unique link will be sent to all recipients, along
                    with instructions for uploading a new version of
                    the document.
                </div>
            </div>
            <div class="pad16">
                <div class="button-send horizontal-pad16" (click)="_send_notes()">
                    Send
                </div>
            </div>
        </div>
    </div>
</div>

