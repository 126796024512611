import { Component, OnInit, ViewChild } from '@angular/core';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { AuthService } from '../service/auth.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService, ToastrConfig } from 'ngx-toastr';

import { UserService } from '../service/user.service';
import { ValidationService } from '../service/validation.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css']
})

export class PasswordResetComponent implements OnInit {

    shown = 'none';
    userData: UserData;
    userInfo: UserInfo;
    reset: FormGroup;
    changeStatus: string;
    pwdStrength = 0;

    url = '';

    pwdType = 'password';
    pwdIcon = 'visibility';
    cpwdType = 'password';
    cpwdIcon = 'visibility';

    isReadonly = true;
    isDisabled = false;
    show = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private usersvc: UserService,
        private validationsvc: ValidationService,	
        // private http: HttpClient,
        private fb: FormBuilder,
        private toast: ToasterService
    ) {
        this._create_form();
    }

    private _create_form(): void {
        this.reset = this.fb.group({
            email: new FormControl(),
            password: new FormControl(),
            passwordconfirm: new FormControl()
        });

        this.userData = this.auth.getTokenInfo();
    }

    ngOnInit() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.reset.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
            },
            (e) => console.log('ERROR: ', e),
        );
        console.log('prc shown=', this.shown);
    }

    close() {
       window.close();
    }

    getUserInfo() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.reset.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
            },
            (e) => console.log('ERROR: ', e)
        );
    }

    ctimeDate(timestamp) {
        return new Date(timestamp * 1000);
    }

    validate_password(event) {
        const password = event.target.value;
        if (password.length === 0) {
            // Show nothing when empty
            this.changeStatus = '';
            return;
        }

        let ctr = 0;
        ctr = this.validationsvc.validatePassword(password);
        let color = '';
        let strength = 'Password Strength: ';
        switch (ctr) {
            case 0:
            case 1:
            case 2:
                strength += 'Very Weak';
                color = 'red';
                this.pwdStrength = ctr;
                break;
            case 3:
                strength += 'Medium';
                color = 'orange';
                this.pwdStrength = ctr;
                break;
            case 4:
                strength += 'Strong';
                color = 'green';
                this.pwdStrength = ctr;
                break;
        }

        this.changeStatus = strength;
        document.getElementById('change-status').style.color = color;
	/***
        if (status) {
          status.style.color = color;
       }
       ***/
    }

    clear_status(event) {
        this.changeStatus = '';
        document.getElementById('change-status').style.color = 'black';
    }

    submit_form() {
        if (this.reset.value.password !== this.reset.value.passwordconfirm) {
            this.changeStatus = 'Passwords do not match.';
            setTimeout((s) => s.changeStatus = '', 3000, this);

            return;
        }
        console.log('reset pwdStrength=', this.pwdStrength);
        if ( this.pwdStrength < 2 ) {
           this.changeStatus = 'Weak Password, Mix caps and lower case or numbers or special characters ($@$!%*#?&)';
           return;
        }

        try {
           this.usersvc.updateUserPassword(this.userInfo.uid,
                                           this.reset.value.password);
           this.toast.pop('success', 'Password Update', 'Password updated successfully.');
           this.auth.clearReset();
           this.toggle_reset();
         } catch (e) {
             this.toast.pop('error', 'Password Update', 'Failed to update password.');
             this.changeStatus = 'Failed to update password.';
      }
    }

    toggle_reset() {
        if (this.shown === 'block') {
            this.shown = 'none';
            this.show = false;
        } else {
            this.shown = 'block';
            this.show = true;	    
            this.getUserInfo();
        }

        console.log('prc toggle shown=', this.shown);
        const rc = document.getElementById('reset-container');
        // console.log('prc rc=', rc);
        document.getElementById('reset-container').style.display = this.shown;
    }
    toggle_pwd() {
        if (this.pwdType === 'password') {
            this.pwdType = 'text';
            this.pwdIcon = 'visibility_off';
        } else {
            this.pwdType = 'password';
            this.pwdIcon = 'visibility';
        }
    }
    toggle_cpwd() {
        if (this.cpwdType === 'password') {
            this.cpwdType = 'text';
            this.cpwdIcon = 'visibility_off';
        } else {
            this.cpwdType = 'password';
            this.cpwdIcon = 'visibility';
        }
    }

    closeThis(){
        this.toggle_reset();
    }

    selectChange() {
        // console.log('Selected INDEX: ' + this.selectedIndex);
        // return to validate no exception
        return true;
    }

    logout() {
        this.auth.logout();

// Cordova 10 and using router.navigate  are the fix for markadoc
// issues #86, #126
        this.router.navigate(['/login'], {replaceUrl: true});

// This wont work in cordova, expects an html page
//      window.location.href = '/login';
    }

}
