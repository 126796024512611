/* eslint-disable */
export class UserInfo {
    uid: string;
    name: string;
    last_name: string;
    email: string;
    phone: string;
    company: string;
    temporary: boolean;
    new_user: boolean;
    expires: string;
    accounts: number;
    free: number;
    subscription_id?: string;
    notify?: boolean;
    directory?: boolean;
    last_login?: string;
    last_logout?: string;
    failed_logins?: number;
}
