
import { Component, OnInit, Output, ViewChild, EventEmitter,
         ElementRef, Inject } from '@angular/core';
import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { AuthService } from '../../service/auth.service';

import { UserService } from '../../service/user.service';
import { ValidationService } from '../../service/validation.service';

import * as HELP from '../../service/help.service';

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';

import { TosComponent } from '../../tos/tos.component';
import { SubaccountEditComponent } from '../../subaccount-edit/subaccount-edit.component';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-user-settings-dialog',
    templateUrl: './user-settings.dialog.html',
    styleUrls: ['./user-settings.dialog.css']
})

export class UserSettingsDialogComponent implements OnInit {
   @Output() helpEvent = new EventEmitter<any>();

   @ViewChild(SubaccountEditComponent, {static: true}) subAcctEdit;
   @ViewChild('content', {static: true}) content: ElementRef;

   userData: UserData;
   userInfo: UserInfo;

   shown = 'none';
   settings: FormGroup;
   infoSettings: FormGroup;
   changeStatus: string;
   url = '';

   isReadonly = true;
   isDisabled = false;
   show = false;

   selectedIndex = 0;
   maxIndex      = 2;

   SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

   constructor(
        private auth: AuthService,
        private usersvc: UserService,
        private validationsvc: ValidationService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<UserSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
   ) {
        // No dialog data to inject yet...
        if ( data ) {
           this.userInfo = data.userInfo;
        }
        this._create_form();
   }

   ngOnInit() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone
                });
            },
            (e) => console.log('ERROR: ', e)
        );
    }

   private _create_form(): void {
        this.userInfo = new UserInfo();
        this.settings = this.fb.group({
            email: new FormControl(),
            password: new FormControl(),
            passwordconfirm: new FormControl()
        });

        this.infoSettings = this.fb.group({
            name: new FormControl(),
            last_name: new FormControl(),
            company: new FormControl(),
            phone: new FormControl()
        });

        this.userData = this.auth.getTokenInfo();
   }

   // CJ - added to forward main event action
   refresh_subs() {
      if (this.subAcctEdit) {
        this.subAcctEdit.refresh_subs();
      }
   }

   getUserInfo() {
        this.auth.getInfo().subscribe(
            (x: UserInfo) => {
                this.userInfo = x;
                this.settings.setValue({email: this.userInfo.email, password: '', passwordconfirm: ''});
                this.infoSettings.setValue({
                    name: this.userInfo.name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company,
                    phone: this.userInfo.phone
                });
            },
            (e) => console.log('ERROR: ', e)
        );
   }

   ctimeDate(timestamp) {
       return new Date(timestamp * 1000);
   }

   // Profile image change
   onSelectFile(file: any) {
        if (file.target.files && file.target.files[0]) {
        const fReader = new FileReader();
        fReader.readAsDataURL(file.target.files[0]);
        fReader.onload = (event: any ) => {
            this.url = event.target.result as string;
            };
        }
   }

   updateInfo() {
      let x = null;
      x = this.usersvc.updateUserInfo( this.userInfo.uid, this.infoSettings.value );
      this.isReadonly = !this.isReadonly;
      this.isDisabled = !this.isDisabled;
      this.show = false;
   }

   editInfo() {
        this.isReadonly = !this.isReadonly;
        this.isDisabled = !this.isDisabled;
        this.show = true;
        document.getElementById('edit_focus').focus();
   }

   validate_password(event) {
        const password = event.target.value;
        const ctr = this.validationsvc.validatePassword(password);
        let color = '';
        let strength = 'Password Strength: ';
        switch (ctr) {
            case 0:		
            case 1:		
            case 2:
                strength += 'Very Weak';
                color = 'red';
                break;
            case 3:
                strength += 'Medium';
                color = 'orange';
                break;
            case 4:
                strength += 'Strong';
                color = 'green';
                break;
       }

        this.changeStatus = strength;
        console.log('User Settings: changeStatus=(' + this.changeStatus + ')');
        const status = document.getElementById('change-status');
        if (status) {
          status.style.color = color;
       }
   }

   clear_status(event) {
       this.changeStatus = '';
       const status = document.getElementById('change-status');
       if (status) {
          status.style.color = 'black';
       }
   }

   async submit_form() {
       if (this.settings.value.password !== this.settings.value.passwordconfirm) {
            this.changeStatus = 'Passwords do not match.';
            setTimeout((s) => s.changeStatus = '', 3000, this);
            return;
       }

       if (this.settings.value.password === '' ) {
            this.changeStatus = 'Password can not be blank!';
            setTimeout((s) => s.changeStatus = '', 3000, this);
            return;
       }

       const x = await this.usersvc.updateUserPassword(this.userInfo.uid, this.settings.value.password);
       this.getUserInfo();
   }

   toggle_settings() {
     /***
        if (this.shown === 'block') {
            this.shown = 'none';
        } else {
            this.shown = 'block';
            this.getUserInfo();
        }
      ***/
      this.getUserInfo();

        // document.getElementById('settings-container').style.display = this.shown;
   }

   closeThis(){
        this.toggle_settings();
   }

   setSelectedTabIndex(i) {
      this.selectedIndex = i;
      this.content.nativeElement.scrollTop = 0;
   }

   selectChange() {
        // console.log('Selected INDEX: ' + this.selectedIndex);
        this.refresh_subs();
        // return to validate no exception
        return true;
   }

    toggle_notify() {
        this.userInfo.notify = !this.userInfo.notify;
        console.log(this.userInfo.notify);
        this.usersvc.updateUserNotifications(this.userInfo.uid, this.userInfo.notify);
    }

   get_index(){
        return this.selectedIndex;
   }

   swiperight() {
        this.selectedIndex++;
   }

   swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.selectedIndex < 0 || this.selectedIndex > this.maxIndex ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.selectedIndex === this.maxIndex;
            this.selectedIndex = isLast ? 0 : this.selectedIndex + 1;
            console.log('Swipe right - INDEX: ' + this.selectedIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.selectedIndex === 0;
        this.selectedIndex = isFirst ? this.maxIndex : this.selectedIndex - 1;
        console.log('Swipe left - INDEX: ' + this.selectedIndex);
        }
   }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }

   async helpDialog() {
       this.helpEvent.emit(HELP.USER_SETTINGS);
   }

   close() {
     // If opened as dialog close it
     if ( this.dialogRef ) {
        this.dialogRef.close();
     }
   }



} // user-settings-dialog
