import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { cleanHtml, fixNewlines, fixBreaks, removeElements } from '../mdtools/htmltools.common';
// import { MDToolsModule } from '../mdtools/mdtools.module';

@Pipe({ name: 'linkify' })
export class Linkify implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
//    return this._domSanitizer.bypassSecurityTrustHtml(this.stylize(value));
    const str = fixNewlines(value);
//    const st2 = this.htmlParse(str);
    const st3 = cleanHtml(str, true);
    // return this._domSanitizer.sanitize(SecurityContext.HTML, this.stylize(str));
    return st3;
  }

  // Modify this method according to your custom logic
  private stylize(text: string): string {
    let stylizedText = '';
    if (text && text.length > 0) {
        for (const t of text.split("@")) {
//          console.log("t=", t); 
          if (t.startsWith("<a href=")) {
            const a = t.split("<a href='")[1];
            const url = a.split("'");
            const b = t.split("'>");
//            console.log("a=", a);
//            console.log("url=", url);
//            console.log("b=", b);           
            stylizedText += "<a href='" + t + "'>" + t + "</a>";
          }
          else
            stylizedText += t;
        } // for
      return stylizedText;
    }
    else return text;
    }

    private htmlParse(text: string): string {
       const parser = new DOMParser();
       const htmlDoc = parser.parseFromString(text, 'text/html');
       const children = htmlDoc.children;
       const childCnt = htmlDoc.childElementCount;
       console.log('linkify child cnt=', childCnt);       
       console.log('linkify children=', children);

       // do whatever you want with htmlDoc.getElementsByTagName('a');
       const applets = htmlDoc.getElementsByTagName('applet'); 
       const scripts = htmlDoc.getElementsByTagName('script');
       const anchors = htmlDoc.getElementsByTagName('a');
       const brs = htmlDoc.getElementsByTagName('br');
       console.log('linkify applets=', applets);       
       console.log('linkify scripts=', scripts);
       console.log('linkify anchors=', anchors);
       console.log('linkify brs=', brs);
       removeElements(scripts);
       console.log('linkify htmlDoc=', htmlDoc);

       const ele = document.createElement('div');
       ele.setAttribute('type', 'hidden');
       ele.innerHTML = text;
       const sc = ele.getElementsByTagName('script');
       removeElements(sc);
       console.log('linkify ele innerText=', ele.innerText);
       console.log('linkify ele innerHTML=', ele.innerHTML);       
       return text;
    }

}

