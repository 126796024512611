import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NoteData } from '../model/notedata.model';
import { LayerData } from '../model/layerdata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';
import { LayerService } from '../service/layer.service';
import { ToasterService } from '../service/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  private NOTE_DATA: NoteData;

  private noteDataUpdates: Subject<any> = new Subject<NoteData>();

  private markerListUpdates: BehaviorSubject<MarkerData[]> = new BehaviorSubject<MarkerData[]>([]);

   constructor(
     private http: HttpClient,
     private layersvc: LayerService,     
     private toast: ToasterService,
   ) { }

   async sendNoteDataUpdate(noteData: NoteData) {
      this.noteDataUpdates.next(noteData);
   }
   clearNotedataUpdates() {
      this.noteDataUpdates.next(null);
   }
   getNoteDataUpdates(): Observable<any> {
      return this.noteDataUpdates.asObservable();
   }

   setNoteData(n) {
     this.NOTE_DATA = n;
     this.sendNoteDataUpdate(this.NOTE_DATA);
   }

   getNoteData() {
     return this.NOTE_DATA;
   }

   async loadNoteData(duid: string) {
      
      const x = await this.layersvc.loadLayerData( duid );
      console.log('markersvc.loadNoteData x=', x);
      
      // Need to flatten all incoming layers
      const newLayers = {
               note: x.map((m) => JSON.parse(m.layer_data).note.map((y) => { y.uid = m.uid; return y; })).flat(),
               question: x.map((m) => JSON.parse(m.layer_data).question.map((y) => { y.uid = m.uid; return y; })).flat(),
               issue: x.map((m) => JSON.parse(m.layer_data).issue.map((y) => { y.uid = m.uid; return y; })).flat(),
               approval: x.map((m) => JSON.parse(m.layer_data).approval.map((y) => { y.uid = m.uid; return y; })).flat()               
      };

      /* sort layer data by created date */
      newLayers.note.sort((a, b) => a.created_at - b.created_at);
      newLayers.question.sort((a, b) => a.created_at - b.created_at);
      newLayers.issue.sort((a, b) => a.created_at - b.created_at);
      newLayers.approval.sort((a, b) => a.created_at - b.created_at);      

      console.log('loadNoteData=', newLayers);
      return newLayers;
   }

   async getMarkerData(doc_uid: string, ldata: LayerData[]): Promise<NoteData> {
        // Used in main component for create-link
        const debug = false;
        if (!doc_uid || !ldata) {
           console.log('getMarkerData - bad arguments');
           return;
        }
        // empty result
        const nData = {
            note: [],
            question: [],
            issue: [],
            approval: []
        };

        if (debug) {console.log('markersvc ldata=', ldata);}

        // Need to flatten all incoming layers
        const newLayers = {
          note: ldata.map((m) => JSON.parse(m.layer_data).note.map((y) => { y.uid = m.uid; return y; })).flat(),
          question: ldata.map((m) => JSON.parse(m.layer_data).question.map((y) => { y.uid = m.uid; return y; })).flat(),
          issue: ldata.map((m) => JSON.parse(m.layer_data).issue.map((y) => { y.uid = m.uid; return y; })).flat(),
          approval: ldata.map((m) => JSON.parse(m.layer_data).approval.map((y) => { y.uid = m.uid; return y; })).flat()   
        };

       /* get rid of elements with no uid */
       newLayers.note = newLayers.note.filter(item => {
          return (item.uuid !== undefined && item.uuid !== null && item.uuid !== '')
       });
       newLayers.question = newLayers.question.filter(item => {
          return (item.uuid !== undefined && item.uuid !== null && item.uuid !== '')
       });
       newLayers.issue = newLayers.issue.filter(item => {
          return (item.uuid !== undefined && item.uuid !== null && item.uuid !== '')
       });
       newLayers.issue = newLayers.approval.filter(item => {
          return (item.uuid !== undefined && item.uuid !== null && item.uuid !== '')
       });
       console.log('newLayers=', newLayers);

       /* sort layer data by created date */
       newLayers.note.sort((a, b) => a.created_at - b.created_at);
       newLayers.question.sort((a, b) => a.created_at - b.created_at);
       newLayers.issue.sort((a, b) => a.created_at - b.created_at);
       newLayers.approval.sort((a, b) => a.created_at - b.created_at);       

       return newLayers;
    }

    async getSortedMarkerList(doc_uid: string) {
        const debug = false;
        if (!doc_uid) {
           console.log('getSortedMarkerData - bad arguments');
           return;
        }
        // empty result
        let sortedMarkerList: MarkerData[] = [];

        let ldata: LayerData[] = [];
        try { 
           ldata = await this.layersvc.loadLayerData(doc_uid);
        } catch(e) {
            console.error('markersvc getSortedMarkerData error=', e);
            return;
        }
        
        // Merge data from all layers
        let issues: MarkerData[] = [];
        let notes: MarkerData[] = [];
        let questions: MarkerData[] = [];
        let approvals: MarkerData[] = [];       

        if (debug) {console.log('ldata=', ldata);}
        ldata.forEach((value, index) => {
           // combine all layers without duplicates
           if (debug) {console.log('index=', index);}
           if (debug) {console.log('layer=', value.layer_data);}
           const l = JSON.parse(value.layer_data);
           if (debug) {console.log('l=', l);}
           issues = [...new Set([...issues,...l.issue])];
           notes = [...new Set([...notes,...l.note])];
           questions = [...new Set([...questions,...l.question])];
           approvals = [...new Set([...approvals,...l.approval])];         
        });

//            // Need to flatten all incoming layers
//            const newLayers = {
//               note: ldata.map((m) => JSON.parse(m.layer_data).note.map((y) => { y.uid = m.uid; return y; })).flat(),
//               question: ldata.map((m) => JSON.parse(m.layer_data).question.map((y) => { y.uid = m.uid; return y; })).flat(),
//               issue: ldata.map((m) => JSON.parse(m.layer_data).issue.map((y) => { y.uid = m.uid; return y; })).flat()
//            };


       /* sort layer data by created date */

       sortedMarkerList = [...new Set([...notes,...questions,...issues,...approvals])];
       sortedMarkerList.sort((a, b) => a.created_at - b.created_at);

       const newList = sortedMarkerList.filter(object => {
          return (object.uuid !== null && object.uuid != '');
       });

       return newList;
    }

   updateComments(uid, data) {
      // uid is the layerData uid
      // data in the form of
      // const data = JSON.stringify({
      //     layer_data: indLayerData,
      //     doc_orientation: this.documentsvc.pdfRotation
      // });
      if ( uid === null ) {
        this.toast.pop('Error', 'markersvc updateComments No layer uid provided or is null');
        return;
      }
      if ( data === null ) {
        this.toast.pop('Error', 'markersvc updateComments no layer data provided or is null');
        return;
      }
      // return the Promise is important!
      return this.http.post(environment.apiUrl + '/layer/' + uid, data)
               .toPromise()
               .then (
                   (x) => {
                           console.log('markersvc updateComments success=', x);
//                         this.toast.pop('success', 'markersvc updateComments', `Returned value ${x}`);
                           return x;
                          },
                   (e) => {
                           // THIS IS NOT THROWING ERROR TO TRY BLOCK
                           console.log('markersvc updateComments error=', e);
//                           this.toast.pop('error', 'markersvc updateComments', `Failed error ${e}`);
                           throw e;
                          }
               );              
   } // updateComments

   async updateNoteData(noteData, itemData) {
            // noteData is NoteData
            // itemData is MarkerData
            const rotation = 0;
            const debug = true;
            if (debug) {console.log('markersvc updateMarkerData itemData=', itemData);}

            if ( itemData && itemData.orientation ) {
               const rotation = itemData.orientation;
            }
            if ( !itemData || itemData === null ) {
               console.log('markersvc updateMarkerData itemdata is null!');
               return;      
            }
            const indLayerData = {
                note: noteData.note.filter((f) => f.owner === itemData.owner),
                question: noteData.question.filter((f) => f.owner === itemData.owner),
                issue: noteData.issue.filter((f) => f.owner === itemData.owner),
                approval: noteData.approval.filter((f) => f.owner === itemData.owner)           
            };

            if (debug) {console.log('updateNoteData indLayerData1=', indLayerData);}

            // Remove any object references before stringify
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
              indLayerData[ntype].forEach((note, index) => {
                 if ( note.markerRef && note.markerRef !== null ) {
                   note.markerRef.destroy();
                   note.markerRef = null;
                 }
                 if ( note.dialogRef && note.dialogRef !== null ) {
                   note.dialogRef.destroy();
                   note.dialogRef = null;
                 }
              });
            });

            if (debug) {console.log('updateComments strip indLayerData=', indLayerData);}

            const data = JSON.stringify({
                layer_data: indLayerData,
                doc_orientation: rotation
            });

            if (debug) {console.log('LAYER: ', noteData);}
            try {
               // Should be itemData.uuid to find the right layer id.      
               await this.layersvc.updateLayerData(itemData.uid, data);
            } catch (e) {
                 console.log('ERROR: markersvc.updateComments failed! e=', e);
                 throw e;
              }
   }

} // MarkerService
