// CJ - Intended for confirmation before action or message acknowlegement
// An intent is passed in as well as any data object for the action
// returns true or false for caller to act on intent and data
// passed back.
//

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

export interface ActionInterface {
  intent?: string;
  data?: any;
}

@Component({
    selector: 'app-action-prompt-dialog',
    templateUrl: './action-prompt.dialog.html',
    styleUrls: ['./action-prompt.dialog.css']
})

export class ActionPromptDialogComponent implements OnInit {
   @Output() actionPromptEvent  = new EventEmitter<any>();

    dialogIntent: any;
    dialogTitle: string;
    dialogMessage: string;
    dialogMessage2: string;
    dialogMessage3: string;
    dialogMsg1Color: string;
    dialogMsg2Color: string;
    dialogMsg3Color: string;
    dialogCancelText: string;
    dialogBtn1Text: string;
    dialogBtn2Text: string;
    dialogBtn3Text: string;
    dialogBtn1Color: string;
    dialogBtn2Color: string;
    dialogBtn3Color: string;
    dialogData: any;

    constructor(
        // private http: HttpClient,
        private dialogRef: MatDialogRef<ActionPromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
        ) {
           if (data) {
              this.dialogIntent = data.intent;
              this.dialogTitle = data.title;
              this.dialogMessage = data.message;
              this.dialogMessage2 = data.message2;
              this.dialogMessage3 = data.message3;
              this.dialogCancelText = data.cancelTitle ||= 'Cancel';
              this.dialogBtn1Text = data.button1Text ||= 'Ok';
              this.dialogBtn2Text = data.button2Text;
              this.dialogBtn3Text = data.button3Text;
              this.dialogMsg1Color = data.message1Color ||= '#1A567A';
              this.dialogMsg2Color = data.message2Color ||= '#1A567A';
              this.dialogMsg3Color = data.message3Color ||= '#1A567A';
              this.dialogBtn1Color = data.button1Color  ||= '#1A567A';
              this.dialogBtn2Color = data.button2Color  ||= '#1A567A';
              this.dialogBtn3Color = data.button3Color  ||= '#1A567A';
              this.dialogData = data.dialogData;
              if ( this.dialogCancelText === null ) {
                this.dialogCancelText = 'Cancel';
              }
           } // if (data)
           // console.log('action-prompt dialogIntent=', this.dialogIntent);
           // console.log('action-prompt dialogTitle=', this.dialogTitle);
           // console.log('action-prompt dialogMessage=', this.dialogMessage);
           // console.log('action-prompt dialogMessage2=', this.dialogMessage2);
           // console.log('action-prompt dialogMessage2=', this.dialogMessage2);
           // console.log('action-prompt dialogCancelTitle=', this.dialogCancelText);
           // console.log('action-prompt dialogActionTitle=', this.dialogBtn1Text);
           // console.log('action-prompt dialogActionTitle=', this.dialogBtn2Text);
           // console.log('action-prompt dialogActionTitle=', this.dialogBtn3Text);
    }

    ngOnInit() {
    }

    close() {
      // User canceled or quit operation
      this.dialogRef.close();
    }

    async save(action: number) {
      // bundle the intent and data
      if ( !action ) {
        action = BUTTON_1;
      }
      const ActionIntent = {
        intent: this.dialogIntent,
        choice: action,
        data: this.dialogData
      };
      // Emit Action back to caller for execution
      this.actionPromptEvent.emit(ActionIntent);
      this.dialogRef.close(ActionIntent);
    }

}
