/* eslint-disable */
export class GroupData {
    uid: string;
    name: string;
    owner: string;
    admins?: string[];    
    org_uid?: string;
    created_at?: number;    
    timestamp?: number;
};
