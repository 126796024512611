// htmltools common typescript functions
//
import { CommonModule } from '@angular/common';

export function cleanHtml(text: string, html=true): string {
   const debug = false;
   
   // Create hidden element
   const ele = document.createElement('div');
   ele.setAttribute('type', 'hidden');

   if (html) {
      text = fixNewlines(text);
   } else {
        text = fixBreaks(text);   
   }
   // console.log('cleanHtml input text fixed=', text);

   ele.innerHTML = text;

   // console.log('cleanHtml ele innerText=', ele.innerText);
   
   // Find elements to remove
   const applets = ele.getElementsByTagName('applet'); 
   const scripts = ele.getElementsByTagName('script');
   const buttons = ele.getElementsByTagName('button');
   const inputs = ele.getElementsByTagName('input');
   const embeds = ele.getElementsByTagName('embed');
   const objects = ele.getElementsByTagName('object');
   const videos = ele.getElementsByTagName('video');
   const audios = ele.getElementsByTagName('audio');

   // const anchors = ele.getElementsByTagName('a');
   // const brs = ele.getElementsByTagName('br');

   if (debug) {
      console.log('cleanHtml applets=', applets);       
      console.log('cleanHtml scripts=', scripts);
      console.log('cleanHtml buttons=', buttons);
      console.log('cleanHtml inputs=', inputs);
      console.log('cleanHtml embeds=', embeds);
      console.log('cleanHtml objects=', objects);
      console.log('cleanHtml videos=', videos);
      console.log('cleanHtml audios=', audios);      
   }

   // remove unwanted elements for now
   // we might implement some safely at a later date
   removeElements(scripts);
   removeElements(applets);
   removeElements(buttons);
   removeElements(inputs);
   removeElements(embeds);
   removeElements(objects);
//   removeElements(videos);
//   removeElements(audios);
   
   if ( !html ) {
      removeElements(videos);
      removeElements(audios);
   }

   if (debug) {
     console.log('cleanHtml ele innerText=', ele.innerText);
     console.log('cleanHtml ele innerHTML=', ele.innerHTML);       
   }
   
   if ( html ) {
     return ele.innerHTML || '';
   } else {
       if ( ele.innerText ) {
         return fixBreaks(ele.innerText);
       } else {
           return '';
         }
     }
}

export function removeElements(list: HTMLCollection) {
  if( list && list !== null && list.length > 0 ) {
     Array.from(list).forEach( (item: HTMLElement, i) => {
        item.remove();
     });
  }
}

export function fixNewlines(text: string): string {
   // Replace all newlines \n or \r\n with <br> and <BR> with <br>
   let s = text.replace(/\r?\n/g,'<br>');
   // console.log('fixNewlines 1 =', s);
   s = s.replace(/<BR*[^>]>/gi,'<br>');   
   // console.log('fixNewlines 2 =', s);
   return s;
}

export function fixBreaks(text: string): string {
   // Replace all <BR> or <br> with \n
   const s = text.replace(/<BR*[^>]>/gi,'\n');
   return s;
}
