import { Component, OnInit, ViewChild } from '@angular/core';
// import { lastValueFrom } from 'rxjs';

import { ActivatedRoute } from '@angular/router';

import { MainComponent } from '../main/main.component';
// import { MainMobileComponent } from '../main-mobile/main-mobile.component';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';

import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { PlatformService } from '../service/platform.service';
import * as PLATFORM from '../service/platform.service';

import { AuthService } from '../service/auth.service';
import { DocumentService } from '../service/document.service';
import { ToasterService } from '../service/toaster.service';

@Component({
    selector: 'app-ui',
    templateUrl: './ui.component.html',
    styleUrls: ['./ui.component.css']
})

export class UIComponent implements OnInit {
    @ViewChild(MainComponent, {static: false}) desktop;
//    @ViewChild(MainMobileComponent, {static: false}) mobile;
    @ViewChild(SettingsDialogComponent, {static: false}) settings;

    networkHandler: any = null;

    UI_DESKTOP = PLATFORM.UI_DESKTOP;
    UI_MOBILE = PLATFORM.UI_MOBILE;

    uiMode = PLATFORM.UI_MOBILE;
    platformType = PLATFORM.TYPE_UNKNOWN;
    platformOS = PLATFORM.OS_UNKNOWN;

    urlParams: any = null;

    // activeDocument = null;
    // pdfSrc = null;
    // pdfZoom = 1.0;
    // pdfZoomPct = '100%';
    // pdfURL = '';
    // pdfProgress = 0;
    urlDocumentID: string = null;
    urlPageNbr = 1;
    urlMarkerType: string = null;
    urlMarkerNbr = 0;

    BROWSER_IS_FIREFOX = false;
    BROWSER_IS_CHROME = false;
    BROWSER_IS_SAFARI = false;
    BROWSER_IS_OPERA = false;
    BROWSER_IS_IE = false;
    BROWSER_IS_EDGE = false;
    BROWSER_IS_BLINK = false;
    BROWSER_IS_SILK = false;
    BROWSER_IS_UNKNOWN = false;


    constructor(
                private auth: AuthService,
                public documentsvc: DocumentService,
                public platform: Platform,
                public platformsvc: PlatformService,
                public toast: ToasterService,
                public route: ActivatedRoute )
    {
        // console.log('UI constructor');
    }

    async ngOnInit(): Promise<void> {
        // console.log('ui ngOnInit');
        await this.platform.ready();
        console.log( 'platform=', this.platform );
        console.log( 'platform.names=', this.platform.platforms() );

        const devID = await Device.getId();
        console.log( 'devInfo=', devID );

        const devInfo = await Device.getInfo();
        console.log( 'devInfo=', devInfo );
        this.platformsvc.setDeviceType(devInfo.platform);

        if ( devInfo.platform !== 'web' ) {
           const batteryInfo = await Device.getBatteryInfo();
           console.log( 'batteryInfo=', batteryInfo );
        }

        if ( this.platform?.is(PLATFORM.TYPE_MOBILE) ) {
          this.uiMode = PLATFORM.UI_MOBILE;
          this.platformsvc.platformType = PLATFORM.TYPE_MOBILE;
          this.platformsvc.setUIMode(this.uiMode);
          console.log('platform is mobile');
        }
        if ( this.platform?.is(PLATFORM.TYPE_TABLET) ) {
            this.platformsvc.isTablet = true;
            this.uiMode = PLATFORM.UI_MOBILE;
            this.platformsvc.platformType = PLATFORM.TYPE_TABLET;
        }
        if ( this.platform?.is(PLATFORM.TYPE_PHABLET) ) {
            this.platformsvc.isPhablet = true;
            this.uiMode = PLATFORM.UI_MOBILE;
            this.platformsvc.platformType = PLATFORM.TYPE_PHABLET;
        }
        if ( this.platform?.is(PLATFORM.TYPE_IPHONE) ) {
            this.platformsvc.isIPhone = true;
            this.uiMode = PLATFORM.UI_MOBILE;
            this.platformsvc.platformType = PLATFORM.TYPE_IPHONE;
        }
        if ( this.platform?.is(PLATFORM.OS_IOS) ) {
            this.platformsvc.isIOS = true;
            this.platformsvc.OS = PLATFORM.OS_IOS;
        }
        if ( this.platform?.is(PLATFORM.OS_ANDROID) ) {
            this.platformsvc.isAndroid = true;
            this.uiMode = PLATFORM.UI_MOBILE;
            this.platformsvc.OS = PLATFORM.OS_ANDROID;
        }
        if ( this.platform?.is(PLATFORM.TYPE_DESKTOP) ) {
            this.uiMode = PLATFORM.UI_DESKTOP;
            this.platformsvc.setUIMode(this.uiMode);
            this.platformsvc.platformType = PLATFORM.TYPE_DESKTOP;
            console.log('platform is desktop');
        }
        if ( this.platform?.is(PLATFORM.ENV_MOBILEWEB) ) {
            this.platformsvc.isMobileWeb = true;
            this.platformsvc.ENV = PLATFORM.ENV_MOBILEWEB;
        }
        if ( this.platform?.is(PLATFORM.ENV_CAPACITOR) ) {
            this.platformsvc.isCapacitor = true;
            this.platformsvc.ENV = PLATFORM.ENV_CAPACITOR;
        }
        if ( this.platform?.is(PLATFORM.ENV_CORDOVA) ) {
            this.platformsvc.isCordova = true;
            this.uiMode = PLATFORM.UI_MOBILE;
            this.platformsvc.ENV = PLATFORM.ENV_CORDOVA;
        }
        if ( this.platform?.is(PLATFORM.ENV_HYBRID) ) {
            this.platformsvc.isHybrid = true;
            this.platformsvc.ENV = PLATFORM.ENV_HYBRID;
        }
        if ( this.platform?.is(PLATFORM.ENV_ELECTRON) ) {
            this.platformsvc.isElectron = true;
            this.uiMode = PLATFORM.UI_DESKTOP;
            this.platformsvc.ENV = PLATFORM.ENV_ELECTRON;
        }

        this.platformOS = this.platformsvc.getOS();
        console.log('ui uiMode=', this.uiMode);
        console.log('ui platformType=', this.platformType);
        console.log('ui platformOS=', this.platformOS);


        if (this.uiMode === PLATFORM.UI_MOBILE) {
           this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
        } else {
             this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
          }

        // Handle Android & similar hardware back button on
        // with capacitor on native apps.
        // We may not need this if the browser back button trap works
        // on Android native.
/***
        this.platform.backButton.subscribeWithPriority(10, () => {
           if (this.uiMode === PLATFORM.UI_MOBILE && this.mobile ) {
               this.mobile.handleBackBtn();
           } else if (this.u
           uiMode === PLATFORM.UI_DESKTOP && this.desktop ) {
                 this.desktop.handleBackBtn();
             }
        });
 ***/
        // this.getRouteParams();
    }

    // Store parameter values on URL changes
    getRouteParams(): void {

        // Route parameters
        // this.activatedRoute.params.subscribe( params => {
        //    this.routeParams = params;
        //    console.log('route params=', params);
        // });
        this.urlDocumentID = null;
        this.urlPageNbr = 1 ;
        this.urlMarkerType = null;
        this.urlMarkerNbr = 0;

        // URL query parameters
        console.log('activatedRoute=', this.route);
        this.route.queryParams.subscribe( params => {
           this.urlParams = params;
           console.log('url params=', params);
           console.log('url docid=', params.docid);
           console.log('url page=', params.page);
           console.log('url mtype=', params.mtype);
           console.log('url mnbr=', params.mnbr);
           this.documentsvc.activeDocument = params.docid;
           this.urlDocumentID = params.docid;
           this.urlPageNbr = params.page;
           this.urlMarkerType = params.mtype;
           this.urlMarkerNbr = params.mnbr;
        });
    }

    uiChange(e: string): void {
        this.uiMode = e;
        console.log('uiComponent uiMode changed=', this.uiMode);
        switch (this.uiMode) {
          case PLATFORM.UI_DESKTOP:
             this.platformsvc.setUIMode(PLATFORM.UI_DESKTOP);
             this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
             break;
          case PLATFORM.UI_MOBILE:
             this.platformsvc.setUIMode(PLATFORM.UI_MOBILE);
             this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
             break;
        }
    }

    activeDocumentChange( e: string ): void {
      this.documentsvc.activeDocument = e;
      console.log('uiComponent activeDocument changed=',
                   this.documentsvc.activeDocument);
    }


} // uiComponent
