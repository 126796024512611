import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
//import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { LayerData } from '../model/layerdata.model';
import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';

import { AuthService } from './auth.service';
import { DocumentService } from '../service/document.service';
import { LayerService } from '../service/layer.service';
import { MarkerService } from '../service/marker.service';
import { UserService } from '../service/user.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { DocumentData } from '../model/documentdata.model';
import { GroupData } from '../model/groupdata.model';

import { environment } from '../../environments/environment';

@Injectable()
export class HyperlinkService {

   
   private hyperlinkUpdates: Subject<any> = new Subject<string>();

   isValid: any;

   userInfo: UserInfo = null;
   userData: UserData = null;

   linkUserInfo: UserInfo = null;
   linkOrigin = '';
   linkTitle = '';
   linkDocumentList: DocumentData[] = [];
   linkDocumentData: DocumentData = null;
   linkLayerData: LayerData[] = null;
   linkNoteData: NoteData = null;
   linkMarkerData: MarkerData = null;
   linkPageNbr = 0;
   linkURL = '';

   xLinkDocumentData: DocumentData = null;
   xLinkSrcDocumentData: DocumentData = null;
   xLinkLayerData: any = null;   
   xLinkNoteData: any = null;
   xLinkMarkerData: any = null;
   xLinkComments: MarkerComment[] = null;
   xLinkCommentData: MarkerComment = null;

   constructor(
     private auth: AuthService,
     private toast: ToasterService,
     private usersvc: UserService,
     private documentsvc: DocumentService,
     private layersvc: LayerService,     
     private markersvc: MarkerService,
   ) {
   }

   // Set up observable and functions to subscripe

   getHyperlinkUpdates(): Observable<any> {
      return this.hyperlinkUpdates.asObservable();
   }

   async sendHyperlinkUpdate(title: string, url: string, clData) {
      const newLink = this.buildHyperlink( title, url );
      const linkData = {
         link: newLink,
         cldata: clData
      };
      // const data = JSON.stringify(linkData);
      this.hyperlinkUpdates.next(linkData);
   }

   clearHyperlinkUpdates() {
      this.hyperlinkUpdates.next(null);
   }

   createExternalLink( title, url) {
     this.sendHyperlinkUpdate( title, url, null );
   }

   createInternalLink( origin, title, duid, muid, pg ) {
     let url = '';
     if ( muid == '' || muid == null) {
        url = origin + '?docid=' + duid + '&page=' + pg;
     } else {
         url = origin + '?docid=' + duid + '&noteid=' + muid;
     }
     this.sendHyperlinkUpdate( title, url, null );
   }

   createInternalFileLink( origin, title, duid ) {
     const url = origin + '?docid=' + duid;
     this.sendHyperlinkUpdate( title, url, null );
   }

   createInternalPageLink( origin, title, duid, pg ) {
     const url = origin + '?docid=' + duid + '&page=' + pg;
     this.sendHyperlinkUpdate( title, url, null );
   }

   async createInternalMarkerLink(fromdocname, fromdata, origin, title, duid, muid, srcdocuid, srcdata, crossLink ) {
     console.log('marker hyperlinksvc origin=', fromdata);   
     console.log('marker hyperlinksvc origin=', origin);
     console.log('marker hyperlinksvc crossLink=', crossLink);
     console.log('marker hyperlinksvc srcdata=', srcdata);     
     const url = origin + '?docid=' + duid + '&noteid=' + muid;
     const clData = {
       "origin": origin,
       "title": title,
       "duid": duid,
       "muid": muid,
       "fromdocname": fromdocname,
       "fromdata": fromdata,
       "srcdocuid": srcdocuid,
       "srcdata": srcdata
     };
     
     if ( crossLink ) {
       this.sendHyperlinkUpdate( title, url, clData ); 
//       await this.addCrossLinkComment(origin, title, duid, muid, srcDocUID, srcdata)
     } else {
           this.sendHyperlinkUpdate( title, url, null ); 
     }
   }

   async addCrossLinkComment(origin, title, duid, muid, srcDocUID, srcdata) {
       console.log('hyperlinksvc: add crosslink called.');
       console.log('hyperlinksvc: add crosslink origin=', origin);
       console.log('hyperlinksvc: add crosslink srcdata=', srcdata);
       // Get UserInfo - do we need this?
       try {
          this.userData = this.auth.getTokenInfo();
          this.userInfo = await this.usersvc.getUserInfo(this.userData.uid);
          console.log('hyperlinksvc userInfo=', this.userInfo);
       } catch (e) {
             console.log('hyperlinksvc error getUserInfo() e=', e);
       }

       // intialize instance variables
       let xLinkSrcDocumentData = null;
       let xLinkDocumentData = null;
       let xLinkNoteData = null;       
       let xLinkMarkerData = null;
       
       // Get Source DocumentData
       try {
          xLinkSrcDocumentData = await this.documentsvc.getDocumentData(srcDocUID);
          console.log('hyperlinksvc src docdata=', xLinkSrcDocumentData);
       } catch (e) {
             console.log('hyperlinksvc error getDocumentData() source e=', e);
       }
       // Get xlink DocumentData
       try {
          xLinkDocumentData = await this.documentsvc.getDocumentData(duid);
          console.log('hyperlinksvc docdata=', xLinkDocumentData);
       } catch (e) {
             console.log('hyperlinksvc error getDocumentData() e=', e);
       }
       // Get Document Layer Data
//       try {
//          xLinkLayerData = await this.layersvc.loadLayerData( xLinkDocumentData.uid);
//          console.log('hyperlinksvc layerdata=', xLinkLayerData);
//       } catch (e) {
//             console.log('hyperlinksvc error loadLayerData() e=', e);
//       }
       
       try {
          xLinkNoteData = await this.markersvc.loadNoteData( xLinkDocumentData.uid);
          console.log('hyperlinksvc xLinkNoteData=', xLinkNoteData);
       } catch (e) {
             console.log('hyperlinksvc error loadNoteData() e=', e);
       }

       // Find the marker and assign the marker user uuid for the layer ops
       let markerUUID = null;
       ['note', 'question', 'issue'].forEach((ntype) => {
          xLinkNoteData[ntype].forEach((note, index) => {
            if ( note.uuid === muid ) {
              console.log('hyperlinksvc marker found=', note);
              xLinkMarkerData = note;
              markerUUID = xLinkMarkerData.uuid;
              this.xLinkComments = xLinkMarkerData.comments;
            console.log('hyperlinksvc marker comments=', this.xLinkComments);
            }
          });
       });

       if ( markerUUID === null ) {
           const emsg = 'Error addling link to ' + xLinkDocumentData.name +
           ' uuid not found.';
           this.toast.pop('error', 'Add Crosslink', emsg);
           return;
       }
       let tmpurl = 'markadoc?docid=' + xLinkSrcDocumentData.uid;
       tmpurl += '&noteid=' + srcdata.uuid;
       const tmptitle = srcdata.title;
       const url = this.buildHyperlink(tmptitle, tmpurl);
       const cmt = 'See ' + url + 'in ' + xLinkSrcDocumentData.name;
       xLinkMarkerData.comments.push( {
         owner: this.userInfo.email,
         comment: cmt,
         created_at: Date.now()
       });
       
       console.log('hyperlinksvc marker updated marker=', xLinkMarkerData);
       console.log('hyperlinksvc marker updated notedata=', xLinkNoteData);
//       console.log('hyperlinksvc marker updated layer=', xLinkLayerData);
       try {
           await this.markersvc.updateNoteData( xLinkNoteData, xLinkMarkerData);
           const msg = 'Added crosslink to ' + xLinkDocumentData.name;       
           this.toast.pop('success', 'Add Crosslink', msg);
       } catch(e) {
           console.log('hyperlinksvc updateNoteData error=', e);
           const emsg = 'Error adding crosslink to ' + xLinkDocumentData.name;
           this.toast.pop('error', 'Add Crosslink', emsg);
       }
   }

//   addComment(itemData: MarkerData, c: string) {
//     const cmt = new MarkerComment();
//     cmt.comment = c;
//     cmt.owner = this.userInfo.email;
//     cmt.created_at = Date.now();
//     return cmt;
//     this.itemData.comments.push( {
//        owner: this.userInfo.email,
//        comment: c,
//        created_at: Date.now()
//     });
//   }


   getCommentCount(m): number {
     if (m.comments && Array.isArray(m.comments)) {
         return m.comments.length;
     } else {
          return 0;
       }
   }

  buildHyperlink( title, url ): string {
      const link = "<a class='mdlink' href='" +  url + "'>" + title  + "</a>";
      return link;
  }

} // hyperlink.service
