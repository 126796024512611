import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output,
         ViewChild, EventEmitter, ElementRef, OnDestroy }
         from '@angular/core';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm,
         Validators } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';

// import { lastValueFrom } from 'rxjs';
import { Subject, Observable, Subscription } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';

// import { SelectLists } from '../../mdtools/select-lists.common';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AuthService } from '../../service/auth.service';
import { ToasterService } from '../../service/toaster.service';
import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
//import { DialogService } from '../../service/dialog.service';

import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { OrgData } from '../model/orgdata.model';
import { OrgUserInfo } from '../model/orguserinfo.model';
import { OrgUserData } from '../model/orguserdata.model';


// import * as GRP from './group.component';
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';
import * as FORMTOOLS from '../../mdtools/formtools.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;


/** Error when invalid control is dirty, touched, or submitted. */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
   selector: 'app-org',
   templateUrl: './org.component.html',
   styleUrls: ['./org.component.css'],
})
export class OrgComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   private oid: string;

   @Input()  iam = false;
   @Input()  isObjAdmin = false;
   @Input()  mode: string = this.DisplayMode.SELECT;
   @Input()  userInfo: UserInfo | null;
   @Input()  userData: UserData | null;
   @Input()  template: number;
   @Input()  groupData: GroupData | null;
   @Input()  selectedObj: any | null;
   @Input()  objData: any | null;
   @Input()  selectedOrg: OrgData | null;
   @Input()  isSelected = false;
   @Input()  doSubmit = false;
   @Input()  doReset = false;
   @Input()  uid: string | null = null;
   @Input()  nameLabel: string | null  = 'Group Name';
   @Input()  useMenuActionDialog = true;
   @Input()  showActions = true;
   @Input()  showFormTitle = false;
   @Input()  showInfoButton = false;
   @Input()  showMenuButton = false;
   @Input()  showDeleteButton = false;
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   @Input()
   public get org_uid() {
       return this.oid;
   }
   public set org_uid(oid: string) {
       this.oid = oid;
       // this.get_org(this.oid);
       // this.changeDetector.markForCheck();
   }

   @Output() toggleObjDetail = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() prevEvent = new EventEmitter<any>();
   @Output() nextEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   @Output() isObjAdminEvent = new EventEmitter<any>();   

   // Dialog vars
   // public actionPromptDialog: MatDialog;
   // public objectDialog: MatDialog;
   // public helpDialog: MatDialog;

   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;
   helpDialogRef: any = null;

   // View vars
   objLabel = 'Company/Org.';
   showDetail = false;
   viewOnly = false;
   isExpanded = false;
   showHidden = false;
   // isSelected = false;

   // Org Admin
   orgData: OrgData = null;
   // isObjAdmin = false;

   // Form vars
   // domainPattern = "(?(?<= )(?=[^ ])|^)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]"; // eslint-disable-line
   domainPattern = "^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$" // eslint-disable-line
   formFieldStyle = 'outline';  // fill or outline
   objForm: FormGroup;
   // orgStatusForm: FormGroup;
   isAddMode: boolean;
   isFormReady: boolean;
   loading = false;
   submitted = false;


//   public orgTypes = LISTS.SelectLists.orgTypes;
//   public orgCategories = LISTS.SelectLists.orgCategories;
//   public statusCodes = LISTS.SelectLists.orgStatuses;
   // public stateCodes = LISTS.SelectLists.stateCodes;
//   public countryCodes = LISTS.SelectLists.countryCodes;

   public objData$: Observable<any | null> = null;

   public orgData$: Observable<any | null> = null;
   public ownerData$: Observable<any | null> = null;

   public orgTypes = LISTS.orgTypes;
   public orgCategories = LISTS.orgCategories;
   public statusCodes = LISTS.orgStatuses;
   // public stateCodes = LISTS.stateCodes;
   public countryCodes = LISTS.countryCodes;

   public errorMatcher = new FormErrorStateMatcher();

   orgNbrStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; margin-right: 1rem",
     selected: "font-weight: bold; color: #2274A5; margin-right: 1rem"
   }

   orgNameStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; width: 100%;",
     selected: "font-weight: normal; color: #2274A5; margin-right: 1rem"
   }

   orgNbrStyle=this.orgNbrStyles.default;
   orgNameStyle=this.orgNameStyles.default;

   // Instance vars
   is_app_mgr = false;

   orgUserData: OrgUserData;
   orgUserSubscription: any;
   doSubmitSubscription: any;

   constructor(
      public toast: ToasterService,
      private el: ElementRef,
      private fb: FormBuilder,
      private auth: AuthService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public groupsvc: GroupService,
      public usersvc: UserService,
      // public dialogsvc: DialogService,
      public actionPromptDialog: MatDialog,
      public formDialog: MatDialog,
      public helpDialog: MatDialog,
      ) {
      } // constructor

   async ngOnInit() {
      // console.log('org iam=', this.iam);
      // console.log('org objData=', this.objData);
      this.userData = this.auth.getTokenInfo();
      this.refreshData();
      // this.orgsvc.orgItem$.next(null);
      if ( this.uid ) {
         this.objData$ = this.orgsvc.getOrganization$( this.uid );
      }
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           // console.log('org doSubmit$ is v=', v);
           if ( v === true ) {
             this.onSubmit();
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             // console.log('org doReset$ is true.');
             this.onReset();
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           // console.log('org doSubmit$ is v=', v);
           if ( v === true ) {
             // console.log('org doEdit$ is true.');
             this.onEdit();
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             // console.log('org doDelete$ is true.');
             this.onDelete();
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           if ( v === true ) {
             // console.log('org doView$ is true.');
             this.onView();
           }
        });
      }

      this.setModeTemplate(this.mode);
      this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);
      // console.log('org isObjAdmin=', this.isObjAdmin);
   }

   ngAfterViewInit() {
      // console.log('org avi selectedObj=', this.selectedObj);
      // console.log('org avi objData=', this.objData);
   }

   async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('org changes=', changes);
      if ( changes.doEdit$ ) {
         console.log('org changes doEdit$=', this.doEdit$);
      }
      if ( changes.uid ) {
         this.objData$ = this.orgsvc.getOrganization$( changes.uid.currentValue );
         this.uid = changes.uid.currentValue;
         // console.log('org changes uid=', this.uid);
      }
      if ( changes.selectedObj ) {
         this.selectedObj = changes.selectedObj.currentValue;
      }
      if ( changes.objData ) {
         this.setObjData(changes.objData.currentValue);
         this.getObjFieldRefs(changes.objData.currentValue);
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
      }
      this.refreshData();
   }


ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     /****
     if ( this.doSubmit$ ) {
        this.doSubmit$.unsubscribe();
     }
     if ( this.doEdit$ ) {
        this.doEdit$.unsubscribe();
     }
     if ( this.doDelete$ ) {
        this.doDelete$.unsubscribe();
     }
     if ( this.doReset$ ) {
        this.doReset$.unsubscribe();
     }
     ****/
   }

   async get_org(uid) {
       this.orgData  = await this.orgsvc.getOrganization(uid);
   }

   getObjFieldRefs(obj) {
      if ( obj && obj.owner ) {
       this.ownerData$ = this.usersvc.getUserInfo$(obj.owner);
      }
      if ( obj && obj.org_uid ) {
       this.orgData$ = this.orgsvc.getOrganization$(obj.org_uid);
      }
   }

   async refreshData() {
      if (this.userData) {
          // console.log('org-list userdata=', this.userData);
          this.is_app_mgr = this.userData.iam;
          this.orgUserData = null;
      } else {
             console.log('OrgListComponent WARNING userData not defined.');
        }

      // this.setModeTemplate(this.mode);
      // console.log('mode=', this.mode);
      // console.log('template=', this.template);
      // console.log('DisplayMode=', this.DisplayMode);
   }

   async setModeTemplate(m) {
      // console.log('org setMode called m=', m);
      this.isAddMode = false;
      this.viewOnly = false;

      this.modeEvent.emit(m);

      switch(m) {
         case this.DisplayMode.SELECT: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.COMBO: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.EXPAND_LIST: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             break;
         }
         case this.DisplayMode.SELECT_LIST: {
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayMode.LIST: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.FORM: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayMode.ADD: {
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = true;
             this.isAddMode = true;
             if (this.userData.iam) {
                 this.viewOnly = false;
             }
             this.isFormReady = false;
             this.createForm();
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.EDIT: {
             this.template = this.DisplayTemplate.FORM;
             // Allow if iam or org_admin
             if ((this.iam && this.userData.iam) || this.isObjAdmin) {
                 this.viewOnly = false;
             } else {
                 this.viewOnly = true;
             }
             this.isAddMode = false;
             this.isFormReady = false;
             this.createForm();
             this.setForm();
             /***
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             ***/
             if ( this.objData ) {
                this.getObjFieldRefs(this.objData);
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.VIEW: {
             this.template = this.DisplayTemplate.FORM;
             this.isAddMode = false;
             this.isFormReady = false;
             this.viewOnly = true;
             this.createForm();
             this.setForm();
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.DELETE: {
             this.template = this.DisplayTemplate.DELETE;
             this.isAddMode = false;
             this.isFormReady = false;
             break;
         }
         case this.DisplayMode.FIELD_NAME: {
             this.template = this.DisplayTemplate.FIELD_NAME;
             this.isAddMode = false;
             break;
         }
      }
      // console.log('org setModeTemplate mode=', this.mode);
      // console.log('org setModeTemplate template=', this.template);
      this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);      
      // this.updateFormControls(this.isObjAdmin);
   }

   public async setObjData(obj) {
      this.objData = obj;
      this.isObjAdmin = await this.getIsObjAdmin(obj, this.userData);
      // console.log('org setObjData isObjAdmin=', this.isObjAdmin);
   }

   async setSelectedObj() {
      this.selectedObj = this.objData;
      this.selectObjEvent.emit(this.objData);
   }

   setSelectListObj(evt) {
      // console.log('setSelectListObj evt=', evt);
      this.setSelectedObj();
      // this.doneEvent.emit(this.objData);
   }

   async getIsObjAdmin(obj: any, uData: UserData) {
      const isAdmin = this.isObjAdmin;
      let obj_admin = false;
      if (obj && 'uid' in obj && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(obj.uid, uData.uid);
         // console.log('org getIsObjAdmin orgusr=', orgusr);
         if ( orgusr && orgusr.org_admin ) {
             obj_admin = true;
         } else {
             obj_admin = false;
           }
      }
      if ( uData.iam ) {
         obj_admin=true;
      }
      this.isObjAdminEvent.emit(obj_admin);
      // console.log('org getIsOrgAdmin isObjAdmin=', obj_admin);
      if ( this.objForm ) {
        this.updateFormControls(obj_admin);   
      }
      return obj_admin;
   }

   compareObjs( o1: any, o2: any ) {
     if( !o1 || !o2 )
       { return false; }
     else {
        return o1.uid === o2.uid;
     }
   }

   onCancel() {
     this.cancelEvent.emit(this.objForm);
   }

   async onSubmit() {
     // evt.stopPropagation();
     // console.log('onSubmit form=', this.objForm);
     // console.log('onSubmit form value=', this.objForm.value);
     // console.log('onSubmit raw form=', this.objForm.getRawValue());
     // console.log('onSubmit expires=', this.objForm.controls.expires);
     // console.log('onSubmit expires type=', typeof this.objForm.controls.expires);

     this.submitted = true;
     if (this.objForm.valid) {
       // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
       console.log('FORM IS VALID');
       console.table(this.objForm.value);

       if ( this.isAddMode ) {
          this.objData = await this.addRecord( this.objForm );
       } else {
             this.objData = await this.editRecord( this.objForm );
       }
       // console.log('org-user onSubmit this.objData=', this.objData);
       // this.submitEvent.emit(this.objForm);
       this.setSelectedObj();
       this.submitEvent.emit(this.objData);
     } else {
          console.error('FORM IS NOT VALID');
          this.objForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
       }
   }

   onDelete() {
     this.deleteRecord( this.selectedObj );
   }

   onReset() {
     this.resetForm();
   }

   onEdit() {
     this.setModeTemplate(this.DisplayMode.EDIT);
   }

   onView() {
     this.setModeTemplate(this.DisplayMode.VIEW);
   }

   private scrollToFirstInvalidControl1() {
     // "form .ng-invalid"
     const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
       "mat-form-field-invalid"
     );

     firstInvalidControl.focus(); //without smooth behavior
  }

   private scrollToFirstInvalidControl() {
      const form = document.getElementById('objForm'); // <-- your formID
      const firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
   }

   viewObj() {
      console.log('view obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.VIEW );
      } else {
         this.viewObjDialog();
        }
   }

   createObj() {
      // console.log('create obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.ADD );
      } else {
         this.createObjDialog();
        }
   }

   editObj(dontUseDialog=false) {
      // console.log('edit obj called. dontUseDialog=', dontUseDialog);
      if ( dontUseDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.mode = this.DisplayMode.EDIT;
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
        return;
      }
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
      } else {
         this.editObjDialog();
        }
   }

   deleteObj() {
      // console.log('delete obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.DELETE );
      } else {
         this.deleteObjDialog();
        }
   }

   titleAction() {
      if ( this.mode === this.DisplayMode.SELECT ||
           this.mode === this.DisplayMode.LIST  ||
           this.mode === this.DisplayMode.EXPAND_LIST ) {
         this.setSelectedObj();
      }
   }

   toggleDetail() {
      switch(this.template) {
          case this.DisplayTemplate.EXPAND_LIST: {
             this.template = this.DisplayTemplate.DETAIL;
             this.showDetail = true;
             break;
          }
          case this.DisplayTemplate.DETAIL: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             this.showDetail = false;
             break;
          }
      }
   }

   cancelObj() {
      this.cancelEvent.emit(true);
      console.log('Cancel');
   }

   prevObj() {
      this.prevEvent.emit(true);
      console.log('prevObj');
   }

   nextObj() {
      this.nextEvent.emit(true);
      console.log('nextObj');
   }

   setSelectedOrg(o) {
     this.selectedOrg = o;
     if ( this.objForm ) {
        this.objForm.patchValue( {org_uid: o.uid} );
        this.objForm.patchValue( {uid: o.uid} );
        this.objForm.patchValue( {org_name: o.org_name} );
     }
   }

   createForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('createForm snow=', snow);
     // console.log('createForm snm=', snm);

     //
     //  NOTE: may need to change isObjAdmin to appmgr and org_owner
     //
     this.objForm = this.fb.group({
           uid: [{value: '', disabled: true}],
           org_client_nbr: [{value: '', disabled: true}],

           org_name: (!this.viewOnly && this.isObjAdmin) ?
                     ['', [Validators.required]] :
                     [{value: '', disabled: true}],

           org_type: (!this.viewOnly && this.isObjAdmin) ?
                     ['NA'] :
                     [{value: 'NA', disabled: true}],

           org_category: (!this.viewOnly && this.isObjAdmin) ?
                         ['NA'] :
                         [{value: 'NA', disabled: true}],

           org_primary_domain_name: (!this.viewOnly && this.isObjAdmin) ?
                         ['', [Validators.required,
                               Validators.pattern(this.domainPattern)]] :
                         [{value: '', disabled: true}],

           org_taxable: (!this.viewOnly && this.isObjAdmin) ?
                        [true, Validators.required] :
                        [{value: true, disabled: true}],

           org_owner_email: (!this.viewOnly && this.isObjAdmin) ?
                            ['', [ Validators.required, Validators.email]] :
                            [{value: '', disabled: true}, [Validators.required, Validators.email]],

           org_owner_name: (!this.viewOnly && this.isObjAdmin) ?
                           [''] :
                           [{value: '', disabled: true}],

           org_owner_last_name: (!this.viewOnly && this.isObjAdmin) ?
                             [''] :
                             [{value: '', disabled: true}],

           org_owner_phone: (!this.viewOnly && this.isObjAdmin) ?
                            ['', [ Validators.pattern('[- +()0-9]+')]] :
                            [{value: '', disabled: true}],

           org_owner_mobile_phone: (!this.viewOnly && this.isObjAdmin) ?
                              ['', [Validators.pattern('[- +()0-9]+')]] :
                              [{value: '', disabled: true}],

           org_owner_fax: (!this.viewOnly && this.isObjAdmin) ?
                          ['', [ Validators.pattern('[- +()0-9]+')] ] :
                          [{value: '', disabled: true}],

           org_addr: (!this.viewOnly && this.isObjAdmin) ?
                     ['']  :
                     [{value: '', disabled: true}],

           org_addr_2: (!this.viewOnly && this.isObjAdmin) ?
                       [''] :
                       [{value: '', disabled: true}],

           org_city: (!this.viewOnly && this.isObjAdmin) ?
                     [''] :
                     [{value: '', disabled: true}],

           org_state: (!this.viewOnly && this.isObjAdmin) ?
                      [''] :
                      [{value: '', disabled: true}],

           org_postal_code: (!this.viewOnly && this.isObjAdmin) ?
                            [''] :
                            [{value: '', disabled: true}],

           org_country_code: (!this.viewOnly && this.isObjAdmin) ?
                             ['US'] :
                             [{value: 'US', disabled: true}],

           org_ee_account_limit: this.userData.iam && !this.viewOnly ?
                                    [9, [ Validators.required,
                                          Validators.pattern("^[0-9]*$"),
                                          Validators.min(0)] ]
                                  : [{value: 10, disabled: true}],
           org_active_ee_account_limit: this.userData.iam && !this.viewOnly ?
                                    [3, [ Validators.required,
                                           Validators.pattern("^[0-9]*$"),
                                           Validators.min(0)] ]
                                    : [{value: 3, disabled: true}],

           org_guest_account_limit: this.userData.iam && !this.viewOnly ?
                                    [90, [ Validators.required,
                                           Validators.pattern("^[0-9]*$"),
                                           Validators.min(0)] ]
                                    : [{value: 100, disabled: true}],

           org_active_guest_account_limit: this.userData.iam && ! this.viewOnly ?
                                    [30, [ Validators.required,
                                           Validators.pattern("^[0-9]*$"),
                                           Validators.min(0)] ]
                                    : [{value: 30, disabled: true}],

           org_storage_limit: this.userData.iam && ! this.viewOnly ?
                                     [120, [ Validators.required,
                                     Validators.pattern("^[0-9]*$"),
                                     Validators.min(30) ]  ]
                                     : [{value: 120, disabled: true}],
           org_sales_referral_email: (this.userData.iam && ! this.viewOnly) ?
                                     ['']
                                     : [{value: '', disabled: true}],
           org_status: (this.userData.iam && ! this.viewOnly) ?
                       ['', Validators.required] :
                       [{value: '', disabled: true}],

           expires: this.userData.iam && ! this.viewOnly ?
                    [snm, [Validators.required, FORMTOOLS.dateTimeValidator]] :
                    [{value: '', disabled: true}, [Validators.required, FORMTOOLS.dateTimeValidator]],
           created: [{value: snow, disabled: true}],
     },{updateOn: 'blur'});

     // console.log('createForm =', this.objForm);

     this.objForm.get("org_active_ee_account_limit")
                 .valueChanges.subscribe(x => {
        console.log('org_active_ee_account_limit changed to: ', x);
        this.objForm.patchValue( {
           org_ee_account_limit: (x * 3),
           org_guest_account_limit: (x * 10),
           org_active_guest_account_limit: (x * 30),
           org_storage_limit: (x * 30)
        } );
     });

     /****
     this.objForm.valueChanges.subscribe(change=>{
         // console.log('formChange=', change)
         for (const [key, value] of Object.entries(change)) {
              // console.log(`${key}: ${value}`);
              this.orgData[key] = value;
         }
         this.setForm();
         for (const [key, value] of Object.entries(this.orgData)) {
              console.log(`orgData.${key}: ${value}`);
         }
     });
     ***/

     // console.log('createForm =', this.objForm);

   }

   resetForm() {
      this.setForm();
      this.objForm.markAsPristine();
      this.objForm.markAsUntouched();
   }

   updateFormControls(isAdmin) {
      // console.log('updateFormControls isAdmin=', isAdmin);
      const enableFields = (!this.viewOnly && isAdmin);
      // console.log('updateFormControls enableFields=', enableFields);

      const adminList = ['org_name', 'org_type', 'org_category',
                         'org_primary_domain_name',
                         'org_taxable', 'org_owner_email', 'org_owner_name',
                         'org_owner_last_name', 'org_owner_phone',
                         'org_owner_mobile_phone', 'org_owner_fax',
                         'org_addr', 'org_addr', 'org_addr_2',
                         'org_city', 'org_state',
                         'org_postal_code', 'org_country_code'];
      const iamList = ['org_sales_referral_email', 'org_status', 'expires',
                       'org_ee_account_limit', 'org_active_ee_account_limit',
                       'org_guest_account_limit',
                       'org_active_guest_account_limit',
                       'org_storage_limit',
                       ];

      if (this.objForm) {
         for (const fld of adminList) {
            console.log('enableFields fld=', fld);
            const ctrl = this.objForm.get(fld);
            console.log('enableFields ctrl=', ctrl);
            if (enableFields) {
               ctrl.enable();
            } else {
                 ctrl.disable();
              }
         }
         // console.log('updateFormCtrl userData.iam=', this.userData.iam);
         for (const fld of iamList) {
            // console.log('iamFields fld=', fld);
            const ctrl = this.objForm.get(fld);
            // console.log('iamFields ctrl=', ctrl);
            if (this.userData.iam && !this.viewOnly) {
               ctrl.enable();
            } else {
                 ctrl.disable();
              }
         }
      }
   }

   updateExpirationDate() {
     const now = new Date();
     const nextMonth = new Date(now);

     nextMonth.setMonth(nextMonth.getMonth() + 1);
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));
   
     let expiration = null;
     if (this.objForm) {
       if (this.objData && (this.objData.expires !== null)) {
         console.log('org checking ISO DateTime...');
         const fld = this.objForm.get('expires');
         expiration = FORMTOOLS.checkISODateTime(this.objData.expires);
         this.objForm.patchValue({expires: expiration});
         console.log('org set expiration=', expiration);         
       } else if (snm) {
                console.log('org set expires snm=', snm);
                this.objForm.patchValue({expires: snm});
              } else {
                  console.log('org set expires to null');
                  this.objForm.patchValue({expires: null});
                }
     }
   }

/***
   requireExpirationDate() {
      this.updateExpirationDate();
      // FORMTOOLS.updateExpirationDate('org_user_expires');
      this.objForm.controls.org_user_expires.setValidators([Validators.required, Validators.min(16), FORMTOOLS.dateTimeValidator]);
      this.objForm.controls.org_user_expires.updateValueAndValidity();
    }

    clearExpirationDate() {
      this.objForm.controls.org_user_expires.setValidators(null);
      this.objForm.controls.org_user_expires.setValue(null);
      this.objForm.controls.org_user_expires.updateValueAndValidity();
    }
***/

   setForm() {

     if(!this.objData || !this.objData.uid || this.objData.uid == null) {
        return;
     }

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('setForm snow=', snow);
     // console.log('setForm snm=', snm);
     // console.log('setForm viewOnly=', this.viewOnly);
     // console.log('setForm isObjAdmin=', this.isObjAdmin);
     // console.log('setForm objData:')
     // console.table(this.objData);

     this.objForm.patchValue({uid: this.objData.uid });

     this.objForm.patchValue({org_client_nbr: this.objData.org_client_nbr});
     this.objForm.patchValue({org_name: this.objData.org_name});
     this.objForm.patchValue({org_type: this.objData.org_type ? this.objData.org_type : 'NA'});
     this.objForm.patchValue({org_category: this.objData.org_category ? this.objData.org_category : 'NA'});
     this.objForm.patchValue({org_primary_domain_name: this.objData.org_primary_domain_name ? this.objData.org_primary_domain_name : ''});
     this.objForm.patchValue({org_status: this.objData.org_status ? this.objData.org_status : 'PROSPECT'});
     this.objForm.patchValue({org_taxable: this.objData.org_taxable});
     this.objForm.patchValue({org_sales_referral_email: this.objData.org_sales_referral_email});


     this.updateExpirationDate();
     // this.objForm.patchValue({expires: this.objData.expires ? this.objData.expires : snm});
     
     this.objForm.patchValue({created: snow});
     this.objForm.patchValue({org_owner_name: this.objData.org_owner_name});
     this.objForm.patchValue({org_owner_last_name: this.objData.org_owner_last_name});
     this.objForm.patchValue({org_owner_email: this.objData.org_owner_email});
     this.objForm.patchValue({org_owner_phone: this.objData.org_owner_phone});
     this.objForm.patchValue({org_owner_mobile_phone: this.objData.org_owner_mobile_phone});
     this.objForm.patchValue({org_owner_fax: this.objData.org_owner_fax});
     this.objForm.patchValue({org_addr: this.objData.org_addr});
     this.objForm.patchValue({org_addr_2: this.objData.org_addr_2});
     this.objForm.patchValue({org_city: this.objData.org_city});
     this.objForm.patchValue({org_state: this.objData.org_state});
     this.objForm.patchValue({org_postal_code: this.objData.org_postal_code});
     this.objForm.patchValue({org_country_code: this.objData.org_country_code ?
                             this.objData.org_country_code : 'US'});
     this.objForm.patchValue({org_ee_account_limit: this.objData.org_ee_account_limit});
     this.objForm.patchValue({org_active_ee_account_limit: this.objData.org_active_ee_account_limit});
     this.objForm.patchValue({org_guest_account_limit: this.objData.org_guest_account_limit});
     this.objForm.patchValue({org_active_guest_account_limit: this.objData.org_active_guest_account_limit});
     this.objForm.patchValue({org_storage_limit: this.objData.org_storage_limit});
     /***
     if ( this.isAddMode && this.userData ) {
        this.objForm.patchValue({owner: this.userData.uid});
     }
     ****/
     if ( this.objData ) {
        this.getObjFieldRefs(this.objData);
     }
   } // setForm()

   clearForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date().setMonth(now.getMonth() + 1);

     this.objForm.patchValue(
        {  uid: null,
           org_client_nbr: null,
           org_name: '',
           org_type: 'NA',
           org_catetory: 'NA',
           org_status: 'A',
           org_taxable: true,
           org_ee_account_limit: 3,
           org_active_ee_account_limit: 9,
           org_guest_account_limit: 30,
           org_active_guest_account_limit: 90,
           org_storage_limit: 90,
           org_sales_referral_email: '',
           expires: now,
           created: nextMonth,
           org_owner_name: '',
           org_owner_last_name: '',
           org_owner_email: '',
           org_owner_phone: '',
           org_owner_mobile_phone: '',
           org_owner_fax: '',
           org_addr: '',
           org_addr_2: '',
           org_city: '',
           org_state: '',
           org_postal_code: '',
           org_country_code: 'US'
         });

     /***
     if ( this.isAddMode && this.userData ) {
        this.objForm.patchValue({owner: this.userData.uid});
     }
     ***/
   }

   /***
   async updateOrgOwnerUser(email: string) {
     const orgusr = await this.orgusersvc.getOrgUserByOrgAndEmailPromise(org.uid, org.org_owner_email);
     if (orgusr) {
       const uid = orgusr.uid;
       delete orgusr.uid;
       orgusr.org_admin = true;
       orguser.org_billing_admin = true;
       const ou = await this.orgusersvc.updateOrgUserPromise(orgusr.uid, orguser);
       console.log('org - updated org user owner ou=', ou);
     }
     const usr = await this.usersvc.getUserByEmailPromise( email );
     if (! usr ) {
     }
   }
   *****/

   async addOrgUserForOwner(org: OrgData) {
     const newou = new OrgUserData();
     newou.org_uid = org.uid;
     newou.org_user_email = org.org_owner_email;
     newou.org_user_type = 'E';
     newou.org_admin = true;
     newou.org_billing_admin = true;
     newou.org_user_expires = null;
     newou.modified_by = this.userData.uid;
     try {
        const ou = await this.orgusersvc.addOrgUserPromise(newou);
        this.toast.pop('success', 'Add Company/Org. User Created For ' + org.org_owner_email);
     } catch (e)  {
      -   console.error('org - cant add orgdata e=', e);
         this.toast.pop('error', 'Add Company/Org.', 'Add Company/Org. failed! e=', e);
       }
   }

   async addRecord( form ) {
      // console.log('group addRecord:', form.getRawValue());
      const data = form.getRawValue();
      delete data.uid;
      delete data.created_at;
      delete data.timestamp;
      if (data.expires) {
        data.expires = FORMTOOLS.checkISODateTime(data.expires);
      }
      // console.log('org addRecord data:', data);
      const org = await this.addObjData( data );
      // console.log('org addRecord org:', org);
      if ( org ) {
        const orgusr = await this.orgusersvc.getOrgUserByOrgAndEmailPromise(org.uid, org.org_owner_email);
        // console.log('add org, owner orgusr: ', orgusr);
        let ou = null;
        if (!orgusr || (orgusr && Object.keys(orgusr).length===0)) {
          // console.log('org adding org user for: ', org.org_owner_email);
          ou = await this.addOrgUserForOwner(org);
        }
      }
   }

   async editRecord( form ) {
      const uid = form.getRawValue().uid;
      const data = form.getRawValue();
      delete data.uid;
      delete data.created_at;
      delete data.timestamp;
      if (data.expires) {
        data.expires = FORMTOOLS.checkISODateTime(data.expires);
      }
      // console.log('org editRecord:', data );
      const org = await this.editObjData( uid, data );
      if ( org ) {
        const orgusr = await this.orgusersvc.getOrgUserByOrgAndEmailPromise(org.uid, org.org_owner_email);
        // console.log('edit org, owner orgusr: ', orgusr);
        let ou = null;
        if (!orgusr || (orgusr && Object.keys(orgusr).length===0)) {
          // console.log('org edit adding orguser for: ', org.org_owner_email);
          ou = await this.addOrgUserForOwner(org);
        }
      }
   }

   async deleteRecord(obj) {
      if ( obj ) {
        // console.log('group deleteRecord:', obj);
        await this.deleteObjData( obj );
      }
   }

   refreshList(obj) {
      // console.log('refreshList obj=', obj);
      this.refreshEvent.emit(obj);
   }

   async getOrgUserInfo(oid, uid) {
      let result = null;
      try {
         result = await this.orgusersvc.getOrgUserByOrgAndUidPromise(oid, uid);
      } catch (e)  {
          console.error('org - cant get orguserinfo e=', e);
      }
      return result;
   }

   async addObjData( data ) {
      let result = null;
      try {
          result = await this.orgsvc.addOrganizationPromise( data );
          this.submitEvent.emit(result);
          this.toast.pop('success', 'Add Company/Org.', 'Company/Org. Saved');           // console.log('org add refreshOrgList result=', result);
      } catch (e)  {
           console.error('org - cant add orgdata e=', e);
           this.toast.pop('error', 'Add Company/Org.', 'Add Company/Org. failed! e=', e);
      }
      // console.log('add org data=', result);
      return result;
   }

   async editObjData( oid, data ) {
      let result = null;
      try {
         result = await this.orgsvc.updateOrganizationPromise(oid, data);
         this.setObjData(result);
         this.setSelectedObj();
         this.submitEvent.emit(result);
         this.getObjFieldRefs(result);
         // console.log('org edit result=', result);
         this.toast.pop('success', 'Update Company/Org.', 'Company/Org. Updated');
      } catch (e)  {
           console.error('org - cant edit orgdata e=', e);
           this.toast.pop('error', 'Update Company/Org.', 'Company/Org. Update failed! e=', e);
      }
      // console.log('edit org data=', result);
      return result;
   }

   async deleteObjData( obj ) {
      let result = null;
      try {
         // console.table( 'delete org uid =', obj.uid );
         if ( ! this.userData.iam ) {
           console.error('No Permission to delete company/org.!');
           return;
         }
         result = await this.orgsvc.removeOrganizationPromise(obj.uid);
         this.deleteObjEvent.emit(result);
         console.table( 'delete org result =', result );
         this.toast.pop('success', 'Delete Company/Org.', 'Deleted Company/Org.', obj.org_name);
         if ( this.selectedOrg && this.selectedOrg.uid === this.orgData.uid ) {
            this.selectObjEvent.emit( null );
         }
      } catch (e)  {
           console.error('company/org - cant delete error e=', e);
           this.toast.pop('error', 'Delete Company/Org.', 'Delete Company/Org. Failed! for ', obj.name);
      }
      return result;
   }


   viewObjDialog() {
      this.openFormDialog( this.DisplayModel.ORG, this.DisplayMode.VIEW,
                           this.objData);
   }

   createObjDialog() {
      this.openFormDialog( this.DisplayModel.ORG, this.DisplayMode.ADD,
                           this.objData);
   }

   editObjDialog() {
      this.openFormDialog( 'ORG', 'E', this.objData);
   }

   deleteObjDialog() {
      this.openDeleteObjDialog( this.objData.uid, this.objData.org_name );
  }

   openDeleteObjDialog(uid, name) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Are you sure you want to permanently delete ' +
                  this.objLabel + name + '?';
      const msg2 = 'Note: All {{objLabel}} users, groups and documents will also be removed.';

      const obj_data = {
         uid: uid,
         name: name
      }

      dialogConfig.data = {
         intent: 'deleteOrg',
         title: 'Delete ' + this.objLabel,
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Delete',
         dialogData: obj_data
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open( ActionPromptDialogComponent, dialogConfig);
      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               /***
               console.log('deleteObj action-prompt intent=',
                            data.intent);
               console.log('deleteObj action-prompt return=',
                            data.choice);
               console.log('deleteObj action-prompt return=',
                            data.dialogData);
               ***/
            }
            // if data returned tax default action
            this.deleteObjData( this.objData );
          } // data
      );
    } // openDeleteFileDialog

   openFormDialog(model, mode, obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Company/Org.';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View ' + this.objLabel;
              break;
        case this.DisplayMode.ADD:
              title = 'Create ' + this.objLabel;
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit ' + this.objLabel;
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete ' + this.objLabel;
              break;
      }

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG,
         objMode: mode,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         /****
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
          } // data
      );
    } // openFormDialog

} // org component
