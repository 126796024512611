import { Component, OnInit, Output, Inject, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-download-sheet',
  templateUrl: './download-sheet.component.html',
  styleUrls: ['./download-sheet.component.css']
})
export class DownloadSheetComponent {
  @Output() bottomSheetEvent = new EventEmitter<any>();

  docname: 'markadoc.pdf';

  constructor(
      private bottomSheet: MatBottomSheet,
      @Inject (MAT_BOTTOM_SHEET_DATA) public data
  )
  {
     this.docname = data.docname;

     console.log('bottom data=', data );
  }

  sendEvent(evt) {
     console.log('sendEvent=', evt);
     this.bottomSheetEvent.emit(evt);
     this.close();
  }

  close() {
    this.bottomSheet.dismiss();
  }
}
