import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { UserService } from '../service/user.service';
// import { UserSubAccountService } from '../service/user-subaccount.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { AuthService } from '../service/auth.service';


export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export const VIEW_USERS = 1;
export const SELECT_USERS = 2;
export const MAT_NAV_LIST = 3;

@Component({
   selector: 'app-user-list',
   templateUrl: './user-list.component.html',
   styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit, OnDestroy, AfterViewInit {
   @Input()  template: number;
   @Input()  orgUID: string;
   @Input()  userInfo: UserInfo;

   // @Output() getMembersEvent = new EventEmitter<any>();
   // @Output() addMemberEvent = new EventEmitter<any>();
   // @Output() removeMemberEvent = new EventEmitter<any>();

   @Output() refreshUsersEvent = new EventEmitter<any>();
   @Output() countUsersEvent = new EventEmitter<number>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() filesUploadedEvent = new EventEmitter<any>();   

   @ViewChild('userlist', {static: false}) userlist: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild(MatSort) sort: MatSort;

   // Constants
   VIEW_USERS = 1;
   SELECT_USERS = 2;
   MAT_NAV_LIST = 3;

   // Service Messaging Subscription
   userSubscription: Subscription;

   allUsers: UserInfo[] = [];

   // Table Data
   // displayedColumns = ['email', 'name', 'last_name', 'company', 'phone'];
   displayedColumns = ['email', 'name', 'last_name', 'company', 'subaccounts', 'expires'];
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;

   // View vars
   toggleFiberUsers = false;
   isUsersVisible = false;
   emailText: any;
   emailAddress: string;
   stepMessage: string;
   filterValue: string;
   
   // Instance vars
   emailAddressList: string[];
   userUID: string;
   userData: UserData;
   userCount: number;
   groupName: string;

   loading: boolean;

   // group member info
   selectedOrganization;
   selectedOrgUnit;
   selectedUser;
   selectedUserList: any[] = [];

   constructor(
      private auth: AuthService,
      private usersvc: UserService,
      ) {
          if (this.userInfo) {
                 // this.userSubLimit = this.userInfo.accounts;
                 console.log('GM uinfo=', this.userInfo);
          } else {
                 console.log('UserListComponent WARNING userInfo not defined.');
                 }

      } // constructor

   ngOnInit(): void {
      console.log('user list called.');
   }

   async ngAfterViewInit(): Promise<void> {
      this.allUsers = await this.usersvc.getAllUsers();

      this.dataSource = new MatTableDataSource(this.allUsers);
      this.dataSource.sort = this.sort;

      // console.log(' userInfo=', this.userInfo);
      // console.log('GMLC this.groupUID=', this.groupUID );

      /***
      this.userSubscription = this.usersvc
                               .getAllUsersUpdates().subscribe( list => {
         console.log('userlist, refreshing..');
         if (list) {
            this.allUsers === list;
         } else {
             console.log('usersvc: empty message sent.');
           }
      });
      ***/
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     // if (this.userSubscription) {
     //   this.userSubscription.unsubscribe();
     // }
     console.log('user list ondestroy');
   }

  // Table manipulation functions
  //
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filterValue = filterValue;
    if ( this.dataSource ) {
        this.dataSource.filter = filterValue;
    }
    console.log('OrgListComponent filterValue=', filterValue);
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(): void {
      // console.log('ULC userlist = ', this.userlist);
      const md = this.userlist.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(): void {
       const md = this.userlist.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       // const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

    show_users(): void {
        this.isUsersVisible = !this.isUsersVisible;
    }

    countUsers(): number {
        let cnt = 0;
        if (this.allUsers != null && this.allUsers.length > 0) {
              cnt = this.allUsers.length;
        }
        this.countUsersEvent.emit(cnt);
        return cnt;
    }

  async displayUserInfo(uuid: string): Promise<void> {
     const mbrInfo = await this.usersvc.getUserInfo(uuid);
     // console.log('GMLC mbrInfo =', mbrInfo);
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  getUserInfo(): Promise<any> {
      return this.auth.getInfo().toPromise().then(
            (x: UserInfo) => {
                console.log('x=', x);
                return x;
               },
               (e) => {
                  console.log('ERROR: ', e);
                  throw e;
               });
  }


} // user-list
