// CJ - Dialog to show UserInfo data
// must pass a UserInfo record to the dialog on open or
// the user uid.  No actions display only at present.
//

import { Component, OnInit, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { DocumentService } from '../../service/document.service';
import { ToasterService } from '../../service/toaster.service';

import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { DocumentData } from '../../model/documentdata.model';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;


@Component({
    selector: 'app-file-info-dialog',
    templateUrl: './file-info.dialog.html',
    styleUrls: ['./file-info.dialog.css']
})

export class FileInfoDialogComponent implements OnInit, AfterViewInit {
   @Output() displayUserInfoEvent = new EventEmitter<any>();

   documentUID: string;
   documentInfo: DocumentData;
   lastAccessDt: string;
   userInfo: UserInfo;
   userUID: string;
   group: GroupData;
   groupUID: string;


   constructor(
        private http: HttpClient,
        private usersvc: UserService,
        private groupsvc: GroupService,
        private documentsvc: DocumentService,
        private toast: ToasterService,
        private dialogRef: MatDialogRef<FileInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data )
        {
           if (data) {
              this.documentUID = data.documentUID;
           } // if (data)
           console.log('fileInfoDialog data=', data);
        }

   async ngOnInit() {
      // Get the documentdata for the file
      try {
        this.documentInfo = await this.documentsvc.getDocumentData( this.documentUID );
        console.log('this.documentInfo=', this.documentInfo);
      } catch (de) {
          console.log('getDocumentData error=', de);
          this.toast.pop('error', 'Error!', 'Cant retrieve document data!');
        }

      // Get the User Info for the file
      try {
        this.userInfo = await this.usersvc.getUserInfo(this.documentInfo.users_uid);
        console.log('this.userInfo=', this.userInfo);
      } catch (ue) {
          console.log('getUserInfo error=', ue);
          this.toast.pop('error', 'Error!', 'Cant retrieve user/owner data!');
        }

      // Get the User Info for the file
      try {
        this.group = await this.groupsvc.getGroup(this.documentInfo.groups_uid);
        console.log('this.group=', this.group);
      } catch (ge) {
           console.log('getGroup error=', ge);
           this.toast.pop('error', 'Error!', 'Cant retrieve group data!');
        }

      this.lastAccessDt = '';
      let accessDt: Date  = null;
      const pipe = new DatePipe('en-us');
      if ( this.documentInfo.timestamp ) {
         accessDt = new Date( this.documentInfo.timestamp * 1000 );
         this.lastAccessDt =   pipe.transform(accessDt,'MM/dd/yyyy HH:mm:ss');
      }
   }

   ngAfterViewInit() {
   }

   displayUserInfo(e) {
      this.displayUserInfoEvent.emit(e);
   }
   close() {
      // User canceled or quit operation
      this.dialogRef.close();
   }

   async save() {
      // bundle the intent and data
      this.dialogRef.close();
   }

}
