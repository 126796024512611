import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
// import * as moment from 'moment';
import moment from 'moment';
// import { DatePipe } from '@angular/common';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
    isValid: any;

    constructor(private http: HttpClient) { }

    login(user: string, password: string) {
        return this.http.post(environment.apiUrl + '/auth', {user, password})
            .pipe(tap(res => this.setSession(res)));
    }

    reset(user: string, password: string) {
        return this.http.post(environment.apiUrl + '/auth/reset', {user, password})
            .pipe(tap(res => this.setSession(res)));
    }

    setSession(authResult) {
        const token = this._decodeToken(authResult.token);
        console.log('token=',token);

        if (authResult.reset) {
            localStorage.setItem('reset', authResult.reset);
        }
        if (authResult.signup) {
            localStorage.setItem('signup', authResult.signup);
        }
        localStorage.setItem('id_token', authResult.token);
        localStorage.setItem('expires_at', JSON.stringify(token.exp * 1000));
    }

    private _decodeToken(token) {
        try {
            return JSON.parse(window.atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    getReset() {
        return localStorage.getItem('reset');
    }

    clearReset() {
        localStorage.removeItem('reset');
    }

    logout() {
        this.updateUserLogoutInfo();
        localStorage.removeItem('reset');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    getInfo() {
        const uid = this.getTokenInfo() ? this.getTokenInfo().uid : '';
        return this.http.get(environment.apiUrl + '/user/' + uid);
    }

    getTokenInfo() {
        return this._decodeToken(this.getToken());
    }

    async validateToken() {
        let valid;

        try {
            valid = await this.http.get(environment.apiUrl + '/status').toPromise();
        } catch (e) {
            console.log('ERROR: ' + e);
        }

        if (valid && 'status' in valid && valid.status === 'ok') {
            return true;
        } else {
            return false;
        }
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

   updateUserLogoutInfo() {
        // const now = this.datepipe.transform(new Date(), "yyyy-MM-ddThh:mm:ss.SSS");
        const now = new Date().toISOString().substr(0,23);
        const token = this.getTokenInfo();
        if (!token) {
          return;
        }
        const uid = token['uid'];
        if (!uid) {
          return;
        }
        const lodata = {'last_logout': now };
        const jdata = JSON.stringify( lodata );
        return this.http.put(environment.apiUrl + '/user/' + uid, jdata)
            // .toPromise()
            // .then(
            .subscribe(
                (x) => {
                    console.log('auth logout at', now);
                    // return updated data
                    return x;
                },
                (e) => {
                    console.error('auth failed to edit user: ', uid,
                            ' error:', e);
                    throw e;
                }
            );
   }

}
