import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent {
    loginForm: FormGroup;

    /** variables to show/hide password **/
    pwdType = 'password';
    pwdIcon = 'visibility';
    
    constructor(
        private auth: AuthService,
        private activeRouter: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private zone: NgZone
    ) {
        this.loginForm = this.fb.group({
            user: ['', [Validators.email, Validators.required]],
            password: ''
        });

        this.activeRouter.queryParams
            .subscribe( params => {
                const mdParams = {
                        docid: params.docid,
                        page: params.page,
                        group: params.group,
                        noteid: params.noteid,
                };
                console.log('login mdParams=', mdParams);
            
                if (params.t) {
                    console.log('login params=', params);
                    const authobj = Object();
                    authobj.token = params.t;
                    if (params.r === '1') {
                        authobj.reset = true;
                    }
                    if (params.n === '1') {
                        authobj.signup = true;
                    }
                    // this.auth.setSession({token: params.t});
                    this.auth.setSession(authobj);
                    this.router.navigate(['/'], {queryParamsHandling: 'preserve', replaceUrl: true});
                }
            });
    }

    login(formData) {
        const errel = document.getElementById('error-box');
        const forget = document.getElementById('forgot-password');
        errel.innerHTML = '&nbsp';
        this.auth.login(formData.user, formData.password).subscribe(
            (x) => {
                     this.zone.run(() => this.router.navigate(['/'], {queryParamsHandling: 'preserve', replaceUrl: true}));
            },
            (e) => {
                forget.style.visibility = 'visible';
                errel.innerHTML = 'Authentication Failed';
            }
        );
    }

    reset(formData) {
        const errel = document.getElementById('error-box');
        const forget = document.getElementById('forgot-password');
        errel.innerHTML = '&nbsp';
        if ( this.loginForm.valid )
        {
        this.auth.reset(formData.user, formData.password).subscribe(
            (x) => {
                // console.log(x);
            },
            (e) => {
                forget.style.visibility = 'visible';
                errel.innerHTML = 'Email sent to stored address.';
            }
        );
        }
        else{
            errel.innerHTML = 'Please enter a valid username/email';
        }
    }

    getid() {
        return this.auth.isLoggedIn();
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['/login'], {replaceUrl: true});
    }

    toggle_pwd() {
        if (this.pwdType === 'password') {
            this.pwdType = 'text';
            this.pwdIcon = 'visibility_off';
        } else {
            this.pwdType = 'password';
            this.pwdIcon = 'visibility';
        }
    }

}
