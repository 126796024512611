// CJ - Dialog to show UserInfo data
// must pass a UserInfo record to the dialog on open or
// the user uid.  No actions display only at present.
//

import { Component, OnInit, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ToasterService } from '../../service/toaster.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserService } from '../../service/user.service';
import { UserInfo } from '../../model/userinfo.model';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

export interface ActionInterface {
  intent?: string;
  data?: any;
}

@Component({
    selector: 'app-user-info-dialog',
    templateUrl: './user-info.dialog.html',
    styleUrls: ['./user-info.dialog.css']
})

export class UserInfoDialogComponent implements OnInit {

   userInfo: UserInfo;
   userUID: string;

   constructor(
        private http: HttpClient,
        private toast: ToasterService,
        private usersvc: UserService,
        private dialogRef: MatDialogRef<UserInfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data )
        {
           if (data) {
              this.userInfo = data.UserInfo;
              this.userUID  = data.UserUID;
           } // if (data)
           console.log('userInfoDialog data=', data);
        }

   async ngOnInit() {
      // If UserInfo is not defined retrieve the userInfo
      if (this.userInfo === undefined || this.userInfo === null) {
         if (this.userUID) {
            this.userInfo = await this.usersvc.getUserInfo(this.userUID);
         } else {
             console.log('UserInfoDialog: error no user data passed to dialog');
           }
      }
   }

   close() {
      // User canceled or quit operation
      this.dialogRef.close();
   }

   async save() {
      // bundle the intent and data
   }

}
