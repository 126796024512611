<div #userlist style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; height: 100%; !important; width: 100% !important; height:100% !important border: 1px solid #1A567A">
    <!--
     <div class="filter-div">
       <mat-form-field>
         <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
       </mat-form-field>
    </div>      
    -->

   <mat-table *ngIf="template===this.VIEW_USERS" #table [dataSource]="this.dataSource" matSort matSortActive="email" matSortDirection="asc" style="min-width: 75vw; max-width: 95vw; max-height: 90vh; margin-left: 0.7em">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let user"
		(click)="displayUserInfo(user.uid)"
		style="word-wrap: break-word; width: 20%; cursor: default; color: blue"
		>{{user.email}}</mat-cell>
    </ng-container>
    
    <!-- First Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
      <mat-cell *matCellDef="let user"
                (click)="displayUserInfo(user.uid)"
		style="word-wrap: break-word; width: 20%; cursor: default"	
	        >{{user.name}}</mat-cell>
    </ng-container>

    <!-- last Name Column -->
    <ng-container matColumnDef="last_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let user"
                (click)="displayUserInfo(user.uid)"
		style="word-wrap: break-word; width: 20%; cursor: default"
		>{{user.last_name}}</mat-cell>
    </ng-container>

    <!-- Full Name Column 
    <ng-container matColumnDef="full_name">
      <mat-header-cell *matHeaderCellDef>Group Member Name</mat-header-cell>
      <mat-cell *matCellDef="let member"
		style="cursor: default"
                (click)="displayUserInfo(member.uid)">
	        {{member.name}} {{member.last_name}}</mat-cell>
    </ng-container>
    -->

    <!-- company Column -->
    <ng-container matColumnDef="company">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
      <mat-cell *matCellDef="let user"
		style="word-wrap: break-word; width: 20%; cursor: default"
		> {{user.company}} </mat-cell>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
      <mat-cell *matCellDef="let user"
		style="word-wrap: break-word; width: 20%; cursor: default"
		> {{user.phone}} </mat-cell>
    </ng-container>

    <!-- Subaccount Column -->
    <ng-container matColumnDef="subaccounts">
      <mat-header-cell *matHeaderCellDef>SubAccount Limit </mat-header-cell>
      <mat-cell *matCellDef="let user"
		style="word-wrap: break-word; width: 20%; cursor: default"
		> {{user.accounts}} </mat-cell>
    </ng-container>

    <!-- Subaccount Column -->
    <ng-container matColumnDef="expires">
      <mat-header-cell *matHeaderCellDef>Expires</mat-header-cell>
      <mat-cell *matCellDef="let user"
		style="word-wrap: break-word; width: 20%; cursor: default"
		> {{user.expires}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>


  <!-- MAT_NAV_LIST Template -->
    <div *ngIf="template === MAT_NAV_LIST"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
      <mat-nav-list>
	<mat-divider></mat-divider>
<!--	
	<mat-list-item>
          <div>{{countMembers()}} Users</div>
	</mat-list-item>
-->
	<mat-divider></mat-divider>
      </mat-nav-list>
      <mat-nav-list #mbluserlist
		    class="mbl-users"
		    id="mbl-user-div">		    
	<mat-list-item id="nav-list-user"
		       *ngFor="let u of this.allUsers">
          <div *ngIf="u.last_name;else disp_email"
               (click)="displayUserInfo(u.uid)">
               {{u.name}} {{u.last_name}}
               <mat-icon *ngIf="selectedUser.uid === u.uid"
               id="admin-star">star</mat-icon> 
          </div>                               
          <ng-template #disp_email>
               <div (click)="displayUserInfo(u.uid)">{{u.email}}
               </div>
          </ng-template>
          <mat-divider></mat-divider>	  
      </mat-list-item>
    </mat-nav-list>
  </div>  <!-- END MAT_NAV_LIST Template  -->
    
</div> <!-- group-member component -->
