import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Component, OnInit, Output, ViewChild, AfterViewInit, Inject,
         ViewContainerRef, EventEmitter, ElementRef} from '@angular/core';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import { ToasterService } from '../../service/toaster.service';

import { environment } from '../../../environments/environment';
import { ActionPromptDialogComponent } from '../action-prompt/action-prompt.dialog';
import { SubaccountEditComponent } from '../../subaccount-edit/subaccount-edit.component';
import * as GMC from '../../group-member-list/group-member-list.component';

import { GroupData } from '../../model/groupdata.model';
import { FileSelector} from '../../model/fileselector.model';
import { UserInfo } from '../../model/userinfo.model';

import { AuthService } from '../../service/auth.service';
import { GroupService } from '../../service/group.service';

import * as HELP from '../../service/help.service';
import { GroupManagerComponent } from '../../group-manager/group-manager.component';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export interface MemberData {
  uid: string;
  groups_uid: string;
  name: string;
  last_name: string;
  email: string;
  company: string;
  phone: string;
  privs: string;
}

@Component({
   selector: 'app-group-manager-dialog',
   templateUrl: './group-manager.dialog.html',
   styleUrls: ['./group-manager.dialog.css']
})
export class GroupManagerDialogComponent implements OnInit, AfterViewInit {
   @Output() updateDocListEvent = new EventEmitter<any>();
   @Output() getUserSubAcctsEvent = new EventEmitter<any>();
   @Output() getGroupsEvent = new EventEmitter<any>();
   @Output() getMembersEvent = new EventEmitter<any>();
   @Output() createGroupEvent = new EventEmitter<any>();
   @Output() renameGroupEvent = new EventEmitter<any>();
   @Output() deleteGroupEvent = new EventEmitter<any>();
   @Output() removeMemberEvent = new EventEmitter<any>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() helpEvent = new EventEmitter<any>();

   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('content', {static: true}) content: ElementRef;
   @ViewChild('memberView', {static: true}) memberView;
   @ViewChild('addMemberView', {static: true}) addMemberView;
   @ViewChild('addMultipleView', {static: true}) addMultipleView;
   @ViewChild('sideNavView', {static: true}) sideNavView;
   @ViewChild('sideNavView2', {static: true}) sideNavView2;

    // 279 CJ - UserGroupList subscription
    userGroupListSubscription: Subscription;

   // checked: boolean;
   // indeterminate: boolean;

   // View vars
   // firstFormGroup: FormGroup;
   // secondFormGroup: FormGroup;
   // thirdFormGroup: FormGroup;
   stepMessage: string;

   showAllGroups = true;

   groupTabIndex = 0;
   groupTabMax = 3;
   SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

   // Instance vars
   userInfo: any;
   subList: any;
   subLimit: number;
   groupList: GroupData[];
   groupMembers: MemberData[];
   group: any;
   groupName: string;
   groupUID: string;
   newUserCnt: number;
   newMemberCnt: number;
   newUserErr: number;
   newMemberErr: number;

   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedName;
   selectedGroupID: any;
   selectedGroup: any;
   selectedGroupObj: any;

   saveGroup: any;

   constructor(
      private groupsvc: GroupService,
      private auth: AuthService,
      // private formBuilder: FormBuilder,
      private toast: ToasterService,
      private dialogRef: MatDialogRef<GroupManagerDialogComponent>,
      public  actionPromptDialog: MatDialog,
      public  actionPromptDialogRef: MatDialogRef<ActionPromptDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data
      ) {
          if ( data ) {
             this.groupList = data.groupList;
             this.userInfo  = data.userInfo;
             this.selectedGroup = data.selectedGroup;
             this.selectedOrganization = data.org;
             this.selectedOrgUnit = data.orgunit;
             this.groupTabIndex = data.groupTabIndex ?? 0;
             this.group = data.group;
             this.groupMembers = data.groupMembers;
          }
          if (data.groupList) {
             console.log('group-manager-dialog this.data.groupList.length=',
                         this.data.groupList.length);
          } else {
            console.log('group-manager-dialog WARNING - groupList not defined');
          }
          if (this.userInfo) {
             this.subLimit = this.userInfo.accounts;
             console.log('groupmgr uinfo=',
                         this.userInfo);
          } else {
            console.log('group-manager-dialog WARNING - userInfo not defined');
          }

          this.stepMessage = '';

          // Change to selected Group
          this.groupChange(this.selectedGroup);

        } // constructor

  ngOnInit() {
  }

  async ngAfterViewInit() {
     if ( !this.selectedGroup && this.groupList.length > 0 ) {
       this.selectedGroup = this.groupList[0].uid;
     }

     // 279 CJ - Group Service Subscribe to group list changes
     this.userGroupListSubscription = this.groupsvc.getUserGroupListUpdates().subscribe(
         async glist => {
            console.log('group-manager: groupListSubscription, refreshing..');
            // 279 CJ - Dialog should subscribe for group list updates.
            // change required.
            this.getUserGroups();
            // console.log('groupmgr subscription: groupList=', this.groupList);
        });

     this.getUserGroups();
     this.groupChange(this.selectedGroup);
  }

  // Checkbox Handler
  async handleShowGroups() {
     this.showAllGroups = !this.showAllGroups;
     console.log('showAllGroups=', this.showAllGroups);
     // console.log('dataSource=', this.dataSource);
     console.log('userInfo.uid=', this.userInfo.uid);
     const groupListRoleOwner = await this.groupList.filter(g => g.owner === this.userInfo.uid);
     const groupListWithAdmins = await this.groupList.filter(g => Boolean(g.admins));
     const groupListRoleAdmin = await groupListWithAdmins.filter( g => g.admins.includes(this.userInfo.uid) );

     if ( this.showAllGroups === false ) {
         // this.applyFilter(this.userInfo.uid);
         console.log('showAllGroups is false, filter activated.');
         /***
           console.log('data=', data);
           console.log('filter=', filter);
           if(data.owner!==this.userInfo.uid) {
              return false;
              } else {
                  return true;
                }
         };
         ***/
      }
  }

  // Tab Change Handlers

   get_index(){
        return this.groupTabIndex;
   }

   swiperight() {
        this.groupTabIndex++;
   }

   swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.groupTabIndex < 0 || this.groupTabIndex > this.groupTabMax ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.groupTabIndex === this.groupTabMax;
            this.groupTabIndex = isLast ? 0 : this.groupTabIndex + 1;
            console.log('Swipe right - INDEX: ' + this.groupTabIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.groupTabIndex === 0;
        this.groupTabIndex = isFirst ? this.groupTabMax : this.groupTabIndex - 1;
        console.log('Swipe left - INDEX: ' + this.groupTabIndex);
        }
   }

  onTabChanged(e) {
    console.log('tab changed e=', e);
    console.log('this.selectedGroup: ', this.selectedGroup);
    if ( e.index === 0 ) {
      this.getUserGroups();
      }
    if ( e.index === 1 ) {
      // this.getUserGroups();
      this.groupChange(this.selectedGroup);
    }
    /***
    if (e.index === 2) {
    }
    if (e.index === 3) {
    }
    ***/
  }

  scrollTop() {
    if ( this.content.nativeElement ) {
          this.content.nativeElement.scrollTop = 0;
       }
  }

  scrollBottom() {
    if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
    }
  }

  async getUserGroups() {
     // console.log('group-mgr refreshGroups()..');
     console.log('group-mgr refreshGroups() selectedGroup=', this.selectedGroup);
     this.groupList = await this.groupsvc.getUserGroups(this.userInfo.uid);
     // console.log('grpmgr refresh groupList=', this.groupList);
     // console.log('grpmgr refresh dataSource=', this.dataSource);
     this.groupChange(this.selectedGroup);
  }

  showMembers(guid) {
     console.log('grpmgr showMembers guid=', guid);
     this.groupChange(guid);
     this.groupTabIndex = 1;
     this.content.nativeElement.scrollTop = 0;
  }

  // Called from grp-member-component (removeMemberEvent)= event
  removeMember(data) {
      this.removeMemberEvent.emit(data);
  }

  // Called from grp-member-component (displayUserInfoEvent)= event
  displayUserInfo(data) {
      this.displayUserInfoEvent.emit(data);
  }

    // Data/change handlers
   groupChange(g) {
      // console.log('userInfo = ', this.userInfo);
      // console.log('mgr groupChange g = ', g);
      console.log('mgr groupChange this.selectedGroup = ', this.selectedGroup);
      // console.log('mgr groupChange this.groupList = ', this.groupList);

      // ERROR: This is not found on createGroup/refreshGroup
      //
      this.selectedGroupObj = this.groupList.find((p) => p.uid === g);
      // set the table data source
      // console.log('groupChange selectedGroupObj=', this.selectedGroupObj);

      // console.log('groupChange dataSource=', this.dataSource);
      if (this.selectedGroupObj) {
         this.selectedGroup = this.selectedGroupObj.uid;
         this.selectedOwner = this.selectedGroupObj.owner;
         this.selectedName = this.selectedGroupObj.name;
         console.log('dialog groupobj = ', this.selectedGroupObj);
         console.log('dialog groupowner = ', this.selectedGroupObj.owner);
         console.log('groupname = ', this.selectedGroupObj.name);
         this.getMembersEvent.emit(g);
         } else {
             this.selectedGroup = null;
             this.selectedOwner = null;
             this.selectedName = null;
             }
  }

  async getMembers(g) {
     this.getMembersEvent.emit(g);
  }

  async createGroupDialog() {
      this.createGroupEvent.emit(true);
  }

  async renameGroupDialog(guid) {
      this.renameGroupEvent.emit(guid);
  }

  async deleteGroupDialog(guid, gname) {
      const result = {
         groupUID: guid,
         groupName: gname
      };
      this.deleteGroupEvent.emit(result);
  }

  deleteGroup(data) {
      this.deleteGroupEvent.emit(data);
  }

  helpDialog() {
      this.helpEvent.emit(HELP.GROUP_MGR);
  }

  // CJ - Send message to main to updateDocumentList()
  sendUpdateDocList(d) {
      // Send updateDocumentList  event to any subscribers
      this.updateDocListEvent.emit(d);
  }

  handleGroupTabEvent(e) {
     this.groupTabIndex = e;
     console.log('groupTabIndex=', this.groupTabIndex);
  }

  close() {
    if ( this.dialogRef ) {
       this.dialogRef.close();
    }
  }
} // group-manager
