<div mat-dialog-title class="dialog-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon style="padding-top: 0.2rem">drag_indicator</mat-icon>  
    <span id="action-title" style="color: #1A567A; align-self: flex-start;">About</span>
  </div>
  <div class="dialog-header-right">
      <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
   </div>
 </div>

<mat-dialog-content class="panel-class" style="margin-top: 1rem;">

  <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>
  <div class="row">
    <div style="font-size: 1em; color: black">Name:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{name}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Version:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{version}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">UI Mode</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{platformsvc.uiMode}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">HW Type:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{platformsvc.platformType}}</div>        
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">OS:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{platformsvc.OS}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">ENV:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{platformsvc.ENV}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Dev. Name:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{devName}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Dev. Model:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{devModel}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Mfr:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{devMfr}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Mem Used:</div>
    <div style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{devMemUsed}} MB</div>
  </div>
  
</mat-dialog-content>
  
<mat-dialog-actions style="align: end">
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.close()'>Close</button>
  
</mat-dialog-actions>    
