<div id='reset-container' class='reset-container'>

  <div class='reset-content' [formGroup]="reset">
    <div>
      <img src="/assets/Markadoc-logo-200TR.png" id="img-logo" alt="Markadoc logo" style="max-width: 25%; height: auto">
      <div id="title"> <mat-icon style="padding-right:3px;">https</mat-icon> Password Reset</div>    
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email" readonly autocomplete="off">
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>
		
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput #password type="{{pwdType}}"
                        (keyup)="validate_password($event)"
                        (focusout)="clear_status($event)"
                           formControlName="password" autocomplete="off">
                      <mat-icon matSuffix (click)="toggle_pwd()">{{pwdIcon}}</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput #passwordconfirm type="{{cpwdType}}" (keyup.enter)="submit_form()" formControlName="passwordconfirm" autocomplete="off">
                    <mat-icon matSuffix (click)="toggle_cpwd()">{{cpwdIcon}}</mat-icon>		    
                </mat-form-field>

                <button mat-raised-button type="submit" (click)="submit_form()">Update Password</button>
                <button mat-raised-button (click)="logout()"
			style="margin-left: 2em">Cancel</button>
                <div id='change-status' class='change-status'>{{changeStatus}}</div>
        </div>
    </div>
</div>    

