// CJ - Dialog to show UserInfo data
// must pass a UserInfo record to the dialog on open or
// the user uid.  No actions display only at present.
//

import { Component, OnInit, Inject, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';

import { Form, FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { IonInput, IonSelect, IonSelectOption  } from '@ionic/angular';

import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { DocumentService } from '../../service/document.service';
import { ToasterService } from '../../service/toaster.service';

import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { DocumentData } from '../../model/documentdata.model';

import * as HELP from '../../service/help.service';
import * as CreateLink from '../../create-link/create-link.component';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;


@Component({
    selector: 'app-create-link-dialog',
    templateUrl: './create-link.dialog.html',
    styleUrls: ['./create-link.dialog.css']
})

export class CreateLinkDialogComponent implements OnInit, AfterViewInit {
   @Input() inputDocUID: string = null;
   @Input() inputMarkerName: string = null;

   @Output() quitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() removeEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() updateEvent = new EventEmitter<any>();

   @ViewChild('createLink', {static: false}) createLink;

   linkForm: FormGroup;
   isSubmitted = false;
   defaultTitle = 'here';
   
   doc = null;
   docUID = null;
   docPageNbr = null;
   docMarkerName = null;
   userInfo: UserInfo;
   userUID: string;
   group: GroupData;
   groupUID: string;

   linkOrigin = '';
   linkTitle = '';
   linkURL = '';
   linkGroupID = '';
   linkDocID = '';
   linkMarkerID = '';
   linkDocumentData = null;
   linkMarkerData = null;   
   linkPageNbr = 1;

   // Template
   templateCreateLink = CreateLink.NAV_CREATE_LINK;
   templateLinkExternal = CreateLink.NAV_LINK_EXTERNAL;
   templateLinkInternal = CreateLink.NAV_LINK_INTERNAL;
   templateLinkPage = CreateLink.NAV_LINK_PAGE;   
   templateLinkFile = CreateLink.NAV_LINK_FILE;
   templateLinkMarker = CreateLink.NAV_LINK_MARKER;

   template: string = CreateLink.NAV_CREATE_LINK;

   helpContext = HELP.CREATE_LINK;
   
   urlRegEx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
   
   constructor(
        private usersvc: UserService,
        private groupsvc: GroupService,
        public  documentsvc: DocumentService,
        private toast: ToasterService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CreateLinkDialogComponent>,
        @Inject (MAT_DIALOG_DATA) public data )
        {
           console.log('create link Dialog data=', data);      
           if (data) {
              if (! data.linkOrigin ) {
                 console.log("ERROR: linkOrigin not specified!");
              }
              if (! data.selectedGroupObj ) {
                 console.log("ERROR: selectedGroupObj not specified!");
              }
              if (! data.userInfo ) {
                 console.log("ERROR: userInfo not specified!");
              }
           }
   }

    async ngOnInit() {
      // Get the documentdata for the file
      this.linkForm = this.formBuilder.group({
         title: ['', [Validators.required, Validators.minLength(1)]],
         url: ['', [Validators.required, Validators.pattern(this.urlRegEx)]],
         groupid: ['', []],
         docid: ['', []]
      });
      // this.linkForm = this.formBuilder.group({items: [['Link', 'http://', '1', '2']]});
      /***
      try {
        this.doc = await this.documentsvc.getDocumentData( this.docUID );
        console.log('link this.doc=', this.doc);
      } catch (de) {
          console.log('link getDocumentData error=', de);
          // this.toast.pop('error', 'Error!', 'Cant retrieve document data!');
        }
      ***/
      // Get the User Info for the file
      /****
      try {
        this.userInfo = await this.usersvc.getUserInfo(this.doc.users_uid);
        console.log('this.userInfo=', this.userInfo);
      } catch (ue) {
          console.log('link getUserInfo error=', ue);
          this.toast.pop('error', 'Error!', 'Cant retrieve user/owner data!');
        }
      ***/
      
      // Get the Group Info for the file
      /***
      try {
        this.group = await this.groupsvc.getGroup(this.doc.groups_uid);
        console.log('this.group=', this.group);
      } catch (ge) {
           console.log('getGroup error=', ge);
           this.toast.pop('error', 'Error!', 'Cant retrieve group data!');
        }
      ***/
   }

   ngAfterViewInit() {
   }

   get errorControl() {
      return this.linkForm.controls;
   }

   templateChange( t ) {
      this.template = t;
   }

   markerDataChange( m ) {
      this.linkMarkerData = m;   
   }

   documentDataChange( d ) {
      this.linkDocumentData = d;   
   }
   
   helpContextChange( c ) {
      this.helpContext = c;
   }

   createExternalLink() {
      this.createLink.createExternalLink();
   }

   createInternalMarkerLink() {
      this.createLink.createInternalMarkerLink();
   }
   createInternalPageLink() {
      this.createLink.createInternalPageLink();
   }

   submitForm() {
      console.log(this.linkForm.value)
      this.isSubmitted = true;
      if (!this.linkForm.valid) {
         console.log('Please provide all the required values!')
         return false;
      } else {
         console.log('linkForm is valid');
        }      
   }

//   displayUserInfo(e) {
//      this.displayUserInfoEvent.emit(e);
//   }

  async saveDialog(event) {
      // bundle the intent and data
      this.dialogRef.close(event);
   }

  closeDialog() {
      // User canceled or quit operation
      this.dialogRef.close();
   }

  navCreateLink() {
    this.createLink.setTemplate( CreateLink.NAV_CREATE_LINK);
    this.createLink.sidenavCreateLink();
  }

  navLinkExternal() {
    this.createLink.setTemplate( CreateLink.NAV_LINK_EXTERNAL);
  }
  navLinkInternal() {
    this.createLink.setTemplate( CreateLink.NAV_LINK_INTERNAL);
  }
  navLinkFile() {
    this.createLink.setTemplate( CreateLink.NAV_LINK_FILE);
  }
  navLinkMarker() {
    this.createLink.setTemplate( CreateLink.NAV_LINK_MARKER);
  }
  navLinkPage() {
    this.createLink.setTemplate( CreateLink.NAV_LINK_PAGE);
  }
}
