import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import * as PLATFORM from './platform.service';

export const UI_UNKNOWN = 'Unknown';
export const UI_DESKTOP = 'Desktop';
export const UI_MOBILE = 'Mobile';
export const UI_TABLET = 'Tablet';

export const TYPE_UNKNOWN = 'unknown';
export const TYPE_MOBILE = 'mobile';
export const TYPE_IPHONE = 'iphone';
export const TYPE_TABLET = 'tablet';
export const TYPE_PHABLET = 'phablet';
export const TYPE_DESKTOP = 'desktop';
export const TYPE_ELECTRON = 'electron';

export const DEVICE_TYPE_IOS = 'ios';
export const DEVICE_TYPE_ANDROID = 'android';
export const DEVICE_TYPE_WEB = 'web';

export const OS_UNKNOWN = 'unknown';
export const OS_IOS = 'ios';
export const OS_ANDROID = 'android';
export const OS_WINDOWS = 'windows';

export const ENV_UNKNOWN = 'unknown';
export const ENV_MOBILEWEB = 'mobileweb';
export const ENV_CAPACITOR = 'capacitor';
export const ENV_CORDOVA = 'cordova';
export const ENV_HYBRID = 'hybrid';
export const ENV_ELECTRON = 'electron';
export const ENV_PWA = 'pwa';

export const BROWSER_OPERA = 'opera';
export const BROWSER_FIREFOX = 'firefox';
export const BROWSER_CHROME = 'chrome';
export const BROWSER_SAFARI = 'safari';
export const BROWSER_IE = 'ie';
export const BROWSER_EDGE = 'edge';
export const BROWSER_BLINK = 'blink';
export const BROWSER_SILK = 'silk';
export const BROWSER_UNKNOWN = 'unknown';

export const VIEWPORT_LANDSCAPE = 'Landscape';
export const VIEWPORT_PORTRAIT = 'Portrait';

export const MARGIN_TOP_MOBILE  = 50;
export const MARGIN_TOP_DESKTOP = 0;

export const MARGIN_TOP_TOOLBAR  = 0;
export const MARGIN_BOTTOM = 0;
export const MARGIN_BOTTOM_TOOLBAR = 48;

/** singleton service ***/

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private uiModeUpdates: Subject<string> = new Subject<string>();
  private OSUpdates: Subject<string> = new Subject<string>();
  private TypeUpdates: Subject<string> = new Subject<string>();
  private DeviceTypeUpdates: Subject<string> = new Subject<string>();  

  uiMode = PLATFORM.UI_DESKTOP;
  orientation = PLATFORM.VIEWPORT_LANDSCAPE;
  
  platformType = PLATFORM.TYPE_UNKNOWN;
  deviceType = PLATFORM.DEVICE_TYPE_WEB;
  
  OS = OS_UNKNOWN;
  ENV = ENV_UNKNOWN;
  BROWSER = PLATFORM.BROWSER_UNKNOWN;

  isMobile = false;
  isIOS = false;
  isAndroid = false;
  isDesktop = false;

  isIPhone = false;
  isPhablet = false;
  isTablet = false;

  isMobileWeb = false;
  isCapacitor = false;
  isCordova = false;
  isHybrid = false;
  isElectron = false;

  isOpera = false;
  isFirefox = false;
  isChrome = false;
  isSafari = false;
  isIE = false;
  isEdge = false;
  isBlink = false;
  isSilk = false;
  isUnknown = false;

  mainMarginTop = MARGIN_TOP_DESKTOP;
  sidenavMarginTop = MARGIN_TOP_DESKTOP;
  sidenavMarginBottom = MARGIN_BOTTOM;
  
  constructor(
     ) {
         this.uiMode = PLATFORM.UI_DESKTOP;
         this.platformType = PLATFORM.TYPE_UNKNOWN;
         this.OS = PLATFORM.OS_UNKNOWN;
         this.ENV = PLATFORM.ENV_UNKNOWN;
       }

  // CJ - Changes to uiMode will sendUIModeUpdate(gid) as an observable.
  // Components using the service can subscribe using the
  // getUIModeUpdates() method and check and/or save the uiMode

  sendUIModeUpdate(e) {
     this.uiModeUpdates.next(e);
  }
  clearUIModeUpdates() {
    this.uiModeUpdates.next(null);
  }
  getUIModeUpdates(): Observable<any> {
    return this.uiModeUpdates.asObservable();
  }
  sendTypeUpdate(e) {
     this.TypeUpdates.next(e);
  }
  clearTypeUpdates() {
    this.TypeUpdates.next(null);
  }
  getTypeUpdates(): Observable<any> {
    return this.TypeUpdates.asObservable();
  }

  sendDeviceTypeUpdate(e) {
     this.DeviceTypeUpdates.next(e);
  }
  clearDeviceTypeUpdates() {
    this.DeviceTypeUpdates.next(null);
  }
  getDeviceTypeUpdates(): Observable<any> {
    return this.DeviceTypeUpdates.asObservable();
  }

  sendOSUpdate(e) {
     this.OSUpdates.next(e);
  }
  clearOSUpdates() {
    this.OSUpdates.next(null);
  }
  getUIOSUpdates(): Observable<any> {
    return this.OSUpdates.asObservable();
  }

  setUIMode(m) {
     this.uiMode = m;
     this.sendUIModeUpdate(m);
  }
  getUIMode() {
     return this.uiMode;
  }

  getPlatformType() {
     return this.platformType;
  }
  setPlatformType(t) {
     this.platformType = t;
     this.sendTypeUpdate(t);
  }

  getDeviceType() {
     return this.deviceType;
  }
  setDeviceType(t) {
     this.deviceType = t;
     this.sendDeviceTypeUpdate(t);
  }

  getOS() {
     return this.OS;
  }
  setOS(os) {
     this.OS = os;
     this.sendOSUpdate(os);
  }

  getBrowser(window) {
    console.log('window.navigator=', window.navigator);

    if ( this.OS === 'unknown' ) {
       if ( window.navigator.userAgent.indexOf('iPhone') >= 0 ||
            window.navigator.userAgent.indexOf('iPad') >= 0   ||
            window.navigator.userAgent.indexOf('iPod') >= 0 )  {
            this.OS = 'ios';
       }
       if ( window.navigator.userAgent.indexOf('Linux') >= 0 ) {
           this.OS = 'linux';
       }
       if ( window.navigator.userAgent.indexOf('Macintosh') >= 0 ) {
           this.OS = 'macos';
       }
       if ( window.navigator.userAgent.indexOf('Windows') >= 0 ) {
           this.OS = 'windows';
       }
       if ( window.navigator.userAgent.indexOf('Android') >= 0 ) {
           this.OS = 'android';
       }
    }
    // Browser UNKNOWN
    this.isUnknown = true;

    // Opera 8.0+
    // this.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    this.isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    // this.isFirefox = typeof InstallTrigger !== 'undefined';
    this.isFirefox = navigator.userAgent.indexOf('Firefox/') >= 0;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // this.isSafari = /constructor/i.test(window.HTMLElement) ||
    //  (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

    this.isSafari = ( this.OS === 'macos') ||
                    ( this.OS === 'ios' );

    // Internet Explorer 6-11
    // this.isIE = /*@cc_on!@*/false || !!document.documentMode;
    this.isIE = navigator.userAgent.indexOf('MSIE') >= 0 ||
                navigator.userAgent.indexOf('Trident') >= 0;

    // Edge 20+
    this.isEdge = !this.isIE && !!window.StyleMedia;

    // Chrome 1+
    // this.isChrome = !!window.chrome && !!window.chrome.webstore;

    // If isChrome is undefined, then use:
    this.isChrome = navigator.userAgent.indexOf('Chrome/') >= 0;

    // Blink engine detection
    this.isBlink = (this.isChrome || this.isOpera) && !!window.CSS;

    // Silk Browser
    this.isSilk = navigator.userAgent.indexOf(' Silk/') >= 0;

    // Blink engine detection
    this.isUnknown = true;

    let output = 'Detecting browsers by ducktyping:<hr>';
    output += 'isFirefox: ' + this.isFirefox + '<br>';
    output += 'isChrome: ' + this.isChrome + '<br>';
    output += 'isSafari: ' + this.isSafari + '<br>';
    output += 'isOpera: ' + this.isOpera + '<br>';
    output += 'isIE: ' + this.isIE + '<br>';
    output += 'isEdge: ' + this.isEdge + '<br>';
    output += 'isBlink: ' + this.isBlink + '<br>';
    output += 'isSilk: ' + this.isSilk + '<br>';
    console.log('getBrowser=', output);

    if ( this.isChrome ) {
       this.BROWSER = PLATFORM.BROWSER_CHROME;
       this.isUnknown = false;
    }
    if ( this.isFirefox ) {
       this.BROWSER = PLATFORM.BROWSER_FIREFOX;
       this.isUnknown = false;
    }
    if ( this.isEdge ) {
       this.BROWSER = PLATFORM.BROWSER_EDGE;
       this.isUnknown = false;
    }
    if ( this.isIE ) {
       this.BROWSER = PLATFORM.BROWSER_IE;
       this.isUnknown = false;
    }
    if ( this.isSafari ) {
       this.BROWSER = PLATFORM.BROWSER_SAFARI;
       this.isUnknown = false;
    }
    if ( this.isOpera ) {
       this.BROWSER = PLATFORM.BROWSER_OPERA;
       this.isUnknown = false;
    }
    if ( this.isBlink ) {
       this.BROWSER = PLATFORM.BROWSER_BLINK;
       this.isUnknown = false;
    }
    if ( this.isSilk ) {
       this.BROWSER = PLATFORM.BROWSER_SILK;
       this.isUnknown = false;
    }

  }

} // PlatformService
