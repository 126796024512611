<!-- upload files component -->
<div class="upload-files" style="overflow: auto; min-height: 50vh;">
  <div class="upload-files-content">
      <div #uploadBtns class="upload-files" style="display: flex; flex-flow: column; justify-content: flex-start; align-content: center; align-items: flex-sftart;">
	<div class="group-selector" style="display: flex; flex-flow: row; align-content: center; width: 90%">
        <app-group-list #grpList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
         [mode]="'C'"
         [showMenuButton]="false"			
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showAddButton]="false"		
         [userData]="userData"			
         [userInfo]="userInfo"
         [selectedObj]="selectedGroup"
         (selectObjEvent)="groupChange($event)"
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
	</app-group-list>
	 <!---
         <mat-form-field style="width: 80% !important; margin-bottom: 1%; font-size: 0.8rem"
			 appearance="standard" class="mat-form-field" id="choose_groups">
                      <mat-label style="font-size: 1rem">Group</mat-label>
                      <mat-select [(ngModel)]="selectedGroup"
			  [ngModelOptions]="{standalone: true}"
			  ngDefaultControl selected="selectedGroup"
			  style="font-size: 0.8rem"
			  (selectionChange)="groupChange(selectedGroup)">
                         <mat-select-trigger>
                           <mat-icon *ngIf="selectedGroup && selectedGroup.org_uid">domain
			   </mat-icon>
			   &nbsp;{{selectedGroup ? selectedGroup.name : ''}}
                         </mat-select-trigger>		
                         <mat-option (click)="createGroupDialog()" id="new_group" style="font-size: 0.85rem"> + new group...</mat-option>			
                         <mat-option *ngFor="let g of groups" [value]="g" style="font-size: 0.85rem">
                            <mat-icon matPrefix *ngIf="g && g.org_uid" style="margin-right: 1rem">domain</mat-icon>			     
                            {{g ? g.name : ''}}
			    <span *ngIf="userInfo.uid===g.owner" style="font-size: 0.8rem"> &#9733;</span></mat-option>
                 </mat-select>
         </mat-form-field>
         ---->
	 <button mat-icon-button (click)="openAddGroupFormDialog()"
		 style="margin-left: 1rem; align-self: bottom">
	   <mat-icon>group_add</mat-icon>
         </button>
	</div>
	<!--
        <mat-form-field 
			class="form-field" [floatLabel]="'always'"
			appearance="">	  
           <mat-label class="form-label">Company/Org. Name</mat-label>
           <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
           <input matInput placeholder="Company/Org. Name"
                  type="text" name="org_name" class="form-control"
		  [value]="selectedGroup.org_uid"
		  readonly>
        </mat-form-field>
        -->
	<app-org #groupOrg *ngIf="selectedGroup && selectedGroup.org_uid"
		 [uid]="selectedGroup.org_uid"
		 [mode]="'FN'"
		 [nameLabel]="'Group Company/Org.'"
		 [userData]="userData"
		 [userInfo]="userInfo"		 
		 >
	</app-org>
	  <div class="file-selectors" style="display:flex; flex-flow: column; justify-content: center; align-items: stretch">

            <div class="picker-prompt" style="margin-top=1%; font-size: 0.9rem">Select File(s) (pdf documents or png or jpeg images) for Upload<br>To select multiple files Click or tap on the select files button below to open the file selector, then hold Ctrl (or Cmd on Mac) while clicking on multiple files to add or remove them from the file selection list. Or click a file and then hold Shift and click another file to select a range of files.<p>Note:n mobile devices you may have to long press on a file name to select multiple files or photos.</p></div>
                  <input #fileInput type='file' multiple
accept="image/jpeg, image/png, application/pdf, .jpg, .jpeg, .jfif, .jpe, .png .pdf" (change)="getSelectedFiles($event)" hidden="true" multiple />
                    <button mat-raised-button (click)="fileInput.click()"
			    class="upload-action-button"
                            [disabled]="!selectedGroup"
			    matTooltip="Select an image or PDF file to upload"
			    ><span style="font-size: 0.75rem">Select File(s)</span>
		      <mat-icon style="display: inline-block;">
			add
		      </mat-icon>
		    </button>
            <div class="drag-prompt" *ngIf="showMergeBtn" style="margin-top: 1%; font-size: 0.8rem"><small>Drag and Drop a File Name to Re-Order the Files</small></div>
              <div cdkDropList class="file-selector-list" (cdkDropListDropped)="fileDrop($event)" style="max-height: 60%; overflow: auto">
                <div class="file-selector-box" [(ngModel)]="fileData" ngDefaultControl *ngFor="let fs of fileData; index as i" cdkDrag>
                  <div class="file-selector-custom-placeholder" style="display: flex; flex-flow: row; justify-content: space-between" *cdkDragPlaceholder>
		  </div>

                  <div class="file-selector-box-left"
       style="display: flex; flex-flow: row nowrap; justify-content: flex-start; align-items: center"
		       >
                     <mat-icon id="drag-ind-{{i}}" 
			       >drag_indicator</mat-icon>
                      <span class="file-name" style="padding-left: 0.1rem">{{fs.fileName}}</span>
		  </div>
		   <div class="file-selector-icons"
			style="display:flex; flex-flow: row; justify-content: flex-end; align-items: center;">
     		     <img [src]="fs.imgURL" *ngIf="fs.isImage()"
			  style="height: 2.5rem; transform: rotate({{fs.imageRotation}}deg); image-orientation: none;"
			  (click)="imageSpecDialog(i, fs, fs.imageSize, fs.imageRotation, fs.addMargins)"/>
		      <button mat-icon-button *ngIf="fs.isImage()"
			  (click)="imageSpecDialog(i, fs, fs.imageSize, fs.imageRotation, fs.addMargins)">
    		              <mat-icon>photo</mat-icon>
	              </button>
                      <mat-progress-bar *ngIf="fs.showFileProgress===true"
		                        id="merge-progress-{{i}}" 
		                        mode="determinate"
				        value={{fs.fileProgress}}
			                ></mat-progress-bar>
                     <mat-icon id="merge-status-{{i}}" 
			     style="color: blue"
			       ></mat-icon>
		     <button mat-icon-button (click)="removeSelectedFile(fs)">
		        <mat-icon style="color: red">cancel</mat-icon>
	             </button>		      
		    </div>
	        </div>
              </div>
            <div class="images-prompt" *ngIf="showImageBtn" style="font-size: 0.7em; margin-top: 2%; margin-bottom: 1%;">Set the Default Image Conversion Settings (Image to PDF)</div>	      
	      <div id="file-image-btns" *ngIf="showImageBtn"
		   style="display: flex; flex-flow: row wrap; justify-content: space-between; align-items: center">
              <button mat-raised-button *ngIf="showImageBtn" hidden
			style="font-size: 0.85rem; margin-right: 1%"
		        (click)="imageSpecDialog(-1, null, this.selectedImageSize, this.selectedImageRotation, this.selectedAddMargins)">
    	               <mat-icon>photo</mat-icon>Defaults
		</button>
	      <div style="width:24% !important; display: flex; flex-flow: column; justify-content: center; align-content: flex-start; margin-left: 1%; padding: 0.2rem" (click)="imageSpecDialog(-1, null, this.selectedImageSize, this.selectedImageRotation, this.selectedAddMargins)">
		    <span id="default-image-size" style="font-size: 0.8rem">{{selectedImageSizeText}}</span>
                    <mat-hint style="font-size: 0.7rem">Default Size</mat-hint>		      </div>
	      <div style="width:23% !important; display: flex; flex-flow: column; justify-content: center; align-content: flex-start; padding: 0.2rem" (click)="imageSpecDialog(-1, null, this.selectedImageSize, this.selectedImageRotation, this.selectedAddMargins)">
		  <span id="default-image-rotation" style="font-size: 0.8rem">{{selectedImageRotationText}}</span>
                <mat-hint style="font-size: 0.7rem">Default Rotation</mat-hint>		      </div>

	      <div style="width:22% !important; display: flex; flex-flow: column; justify-content: center; align-content: flex-start;"
		   (click)="imageSpecDialog(-1, null, this.selectedImageSize, this.selectedImageRotation, this.selectedAddMargins)">
		    <span id="default-add-margins" style="font-size: 0.8rem">{{selectedAddMarginsText}}</span>
                    <mat-hint style="font-size: 0.7rem">Page Borders/Margins</mat-hint>		  </div>
	      </div> <!-- file-image-btns -->

              <mat-form-field *ngIf="showMergeBtn" style="width:80% !important; margin-bottom: 5px; display: block;" appearance=standard  class="mat-form-field" id="enter_merge_name" >
                <mat-label style="display:block">Merge File Name (if using Merge)</mat-label>
                   <input matInput *ngIf="showMergeBtn" [(ngModel)]="mergeFileName" [ngModelOptions]="{standalone: true}" ngDefaultControl id="merge_file_name" style="display: block; font-size: 0.8rem"/>
              </mat-form-field>
	      <!---
	      <mat-icon *ngIf="showMergeBtn">help</mat-icon>
               --->
            </div> <!-- file selectors -->	  
	    </div>
    <!-- upload files form -->
      
 </div>

 <div class="upload-files-actions">
  <div id="upload-message" style="display: block; font-size=0.8rem: margin-bottom: 0.3rem;"><small>
	   {{uploadMessage}}</small>
  </div>
       <mat-progress-bar id="merge-progress-bar"
			 class="upload-progress"
			 style="background: #00ff00; foreground: #00ff00; margin-bottom: 0.5rem;"
			 mode="determinate"
			 [value]="uploadProgress.message"></mat-progress-bar>
    <div style="display: flex; flex-flow row; justify-content: space-between; align-items: center">
    <div style="display: flex; flex-flow row; justify-content: flex-start; align-items: center">
              <button mat-raised-button
	               *ngIf="!completed"
	               class="upload-action-button"			
		       (click)='handleFileUploads()'
                       [disabled]="!selectedGroup || !fileData || disableUploadBtn"
		       id="add-to-button">
                      <span>{{uploadBtnTitle}}</span>
		      <mat-icon style="display: inline-block;">upload_file
		      </mat-icon>
	      </button>
              <button mat-raised-button *ngIf="showMergeBtn && !completed"
		      style="margin-left: 0.5rem"
                      (click)='handleMergeAndUpload()'
	              class="upload-action-button"
                     [disabled]="!selectedGroup || !fileData || !mergeFileName"
		      ><span style="font-size: 1.0em">MERGE & UPLOAD TO FILE</span>
		      <mat-icon style="display: inline-block;">merge_type
		      </mat-icon>
	      </button>
    </div>
    
   <div *ngIf="completed===true"
        style="display: flex; flex-flow row; justify-content: flex-end; align-items: center">
     <button mat-raised-button
	     class="bottom-buttons"
	     style="margin-left: 0.5rem"
             (click)="closeUploadDialog()">Done</button>
   </div>
   
   </div>

 </div>

</div>
<div id="bottom">
</div>
