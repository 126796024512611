/* eslint-disable */
// CJ -Class for sending image settings on dialogRefs and emitters
import { FileSelector } from './fileselector.model';

export class ImageSettings {
    idx:        number;
    fileName:   string;
    fs:         FileSelector;
    size:       number;
    rotation:   number;
    margins:    boolean;
    stext:      string;
    rtext:      string;
    sdims:      number[];
}
