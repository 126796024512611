import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { ToasterService } from '../service/toaster.service';
import { environment } from '../../environments/environment';
import { UserInfo } from '../model/userinfo.model';

export interface HelpInterface {
    context: number;
}

export interface HelpData {
  context: number;
  url: string;
  page: string;
}

export const MAIN                   = 0;
export const RECENTS                = 5;
export const USER_SETTINGS          = 10;
export const USER_LIST              = 15;
export const GROUP                  = 20;
export const GROUP_MGR              = 22;
export const GROUP_LIST             = 25;
export const UPLOAD_CONTENT         = 30;
export const USER_SUBACCOUNTS       = 40;
export const MARKER_REPORT          = 50;
export const USER_FEEDBACK          = 60;
export const SEND_TOKEN             = 70;
export const QUICK_START            = 100;
export const CREATE_LINK            = 110;
export const MARKER_TOOL            = 120;
export const APPROVAL_REQUEST       = 130;
export const ORGANIZATION           = 140;
export const ORG_LIST               = 145;
export const ORG_USER               = 150;
export const ORG_USER_LIST          = 155;

export const HELP_PAGES: Array<HelpData> = [
   { context: MAIN, url: 'markadoc.com/', page: 'help/' },
   { context: RECENTS, url: 'assets/help/', page: 'recents.html' },
   { context: USER_SETTINGS, url: 'assets/help/', page: 'user-settings.html' },
   { context: USER_LIST, url: 'assets/help/', page: 'user-list.html' },
   { context: GROUP, url: 'assets/help/', page: 'group.html' },
   { context: GROUP_MGR, url: 'assets/help/', page: 'group-manager.html' },
   { context: GROUP_LIST, url: 'assets/help/', page: 'group-list.html' },
   { context: UPLOAD_CONTENT, url: 'assets/help/', page: 'upload-content.html'},
   { context: USER_SUBACCOUNTS, url: 'assets/help/', page: 'user-subaccounts.html'},
   { context: MARKER_REPORT, url: 'assets/help/', page: 'marker-report.html'},
   { context: USER_FEEDBACK, url: 'assets/help/', page: 'user-feedback.html'},
   { context: QUICK_START, url: 'assets/help/', page: 'quick-start.html'},
   { context: CREATE_LINK, url: 'assets/help/', page: 'create-link.html'},
   { context: MARKER_TOOL, url: 'assets/help/', page: 'marker-tool.html'},
   { context: APPROVAL_REQUEST, url: 'assets/help/', page: 'approval-request.html'},
   { context: ORG_LIST, url: 'assets/help/', page: 'org-list.html' }
];

@Injectable()
export class HelpService {

   context: number;
   asset: string;

   constructor(private http: HttpClient) { }

   async getHelpContent(ctx: number) {
      const helpData = await HELP_PAGES.find(x => x.context === ctx);
      let   path = helpData.url + helpData.page;

      // CHECK path must begin with assets
      if ( ! path.startsWith('assets/help/') ) {
         path = 'assets/help/help_error.html';
      }

      // Note: check above should prevent remote URL for now.
      return this.http.get(path, {responseType: 'text'})
      .toPromise()
      .then(
              (data) => {
                 // now you have the file content in 'data'
                 // console.log('helpsvc data=', data);
                 return data;
              },
              (e) => {
                 console.log('helpservice error: e=', e);
                 throw e;
              }
            );
   }

} // help.service
