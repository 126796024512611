<div mat-dialog-title class="modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>
    <span id="action-title" style="color: #1A567A;">{{dialogTitle}}</span>
  </div>
  <div class="dialog-header-right">
    <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
  </div>
</div>

<mat-dialog-content class="panel-class" style="margin-top: 1rem;">
<!--  <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>  
 -->
  <div class="dialog-message" style="margin-top: 1%; font-size: 1em; color: {{dialogMsg1Color}}">{{dialogMessage}}</div>
  <div class="dialog-message" style="margin-top: 1%; font-size: 0.8em; color: {{dialogMsg2Color}}">{{dialogMessage2}}</div>
  <div class="dialog-message" style="margin-top: 1%; font-size: 0.8em; color: {{dialogMsg3Color}}">{{dialogMessage3}}</div>    
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="dialogBtn1Text!==''"
	  style="background: {{dialogBtn1Color}}"
	  mat-raised-button class="bottom-buttons" id="create-color"
	  (click)='this.save(1)'>{{dialogBtn1Text}}</button>
  <button *ngIf="dialogBtn1Text!==''"
	  style="background: {{dialogBtn2Color}}"	  
	  mat-raised-button class="bottom-buttons" id="create-color"
	  (click)='this.save(2)'>{{dialogBtn2Text}}</button>
  <button *ngIf="dialogBtn1Text!==''"
	  style="background: {{dialogBtn3Color}}"	  	  
	  mat-raised-button class="bottom-buttons" id="create-color"
	  (click)='this.save(3)'>{{dialogBtn3Text}}</button>
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.close()'>{{dialogCancelText}}</button>
</mat-dialog-actions>    
