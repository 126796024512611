import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserInfo } from '../model/userinfo.model';
import { AuthService } from './auth.service';

@Injectable()
export class MailService {

   isValid: any;
   userData: any;
   users: UserInfo[];

   constructor(
      private auth: AuthService,
      private http: HttpClient
      ) { }

   // rxjs <= v7
   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.usersvc.getUserInfoPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getGroups Observable
   // and use the following on the component:
   // }
   // async loadGroups() {
   //   const userInfo$ = this.usersvc.getUserInfoObservable(uuid);
   //   this.groups = await lastValueFrom(userInfo$);
   // }
   sendFreeUserInvitationPromise(data) {
        this.http.put(environment.apiUrl + '/linkeduser/signup', data)
        .toPromise()
        .then(
            (x) => {
                return x;
            },
            (e) => {
                console.log(e);
                throw e;
              }
        );
   }

   async sendFreeUserInvitation(data) {
      let x = null;
      try {
         x = await this.sendFreeUserInvitationPromise(data);
      } catch (e) {
          console.log('invitationsvc free user error=', e);
          throw e;
        }
      return x;
   }

   // For sending 24 hour access to document/group to email user based
   // on a document uid, typically the activeDocument
   //
   sendDocumentTokenPromise(email, docUID) {
      console.log('mailsvc docUID=', docUID);
      console.log('mailsvc email=', JSON.stringify({ mail: email }));
      return this.http.post(environment.apiUrl + '/send/' + docUID,
                            JSON.stringify({ mail: email })
                ).toPromise()
                 .then(
                    (x) => {
                        return x;
                    },
                    (e) => {
                        throw e;
                    }
                );
   }

} // mail.service
