import { CommonModule, formatDate } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, ViewChildren, Inject, EventEmitter, ElementRef,
         OnChanges, SimpleChanges, OnDestroy, QueryList } from '@angular/core';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ToasterService } from '../service/toaster.service';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';

import { AuthService } from '../service/auth.service';
import { MarkerService } from '../service/marker.service';
import { UserService } from '../service/user.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';

//import { SelectListsModule } from '../select-lists/select-lists.module';
//import { MDToolsModule } from '../mdtools/mdtools.module';
//
import { cleanHtml } from '../mdtools/htmltools.common';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import * as MarkerList from './marker-list.component';

export const TABLE_MARKERS    = 1;
export const SIDENAV_MARKERS  = 2;
export const MOBILE_MARKERS   = 3;
export const LINK_MARKERS     = 4;

export const ICON_PATH_ISSUE = '/assets/issue_icon.png';
export const ICON_PATH_QUESTION = '/assets/question_icon.png';
export const ICON_PATH_COMMENT = '/assets/note_icon.png';
export const ICON_PATH_APPROVAL = '/assets/approval_icon.png';

/***
export interface Marker {
  comments?: Comment[] | MatTableDataSource<Comment>;
  created_at: number;
  description: string;
  orientation: number;
  owner: string;
  pageNbr: number;
  pdfPageWidth: number;
  pdfPageHeight: number;
  pdfPageX: number;
  pdfPageY: number;
  shown: boolean;
  title: string;
  type: string;
  uid: string;
  uuid: string;
  viewPageHeight: number;
  viewPageWidth: number;
  viewPageX: number;
  viewPageY: number;
  viewerX: number;
  viewerY: number;
  x: number;
  y: number;
}
***/

export interface Comment {
  comment: string;
  created_at: number;
  owner: string;
}

@Component({
   selector: 'app-marker-list',
   templateUrl: './marker-list.component.html',
   styleUrls: ['./marker-list.component.css'],
//   animations: [
//      trigger('detailExpand', [
//         state('collapsed', style({ height: '0px', minHeight: '0' })),
//         state('expanded', style({ height: '*' })),
//         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
//      ]),
//   ],
})
export class MarkerListComponent implements OnInit, OnDestroy, AfterViewInit {

   private NOTE_DATA: NoteData;
   private DOCUMENT_NAME = '';

   @Input()  set noteData(val: NoteData) {
      // console.log('previous noteData = ', this.NOTE_DATA);
      // console.log('current noteData =', val);
      this.NOTE_DATA = val;
      this.noteDataChange(this.NOTE_DATA);
   }

   get noteData(): NoteData {
      return this.NOTE_DATA;
   }

   @Input()  set documentName(val: string) {
      console.log('previous documentName = ', this.DOCUMENT_NAME);
      console.log('current documentName =', val);
      this.DOCUMENT_NAME = val;
   }
   get documentName(): string {
      return this.DOCUMENT_NAME;
   }


   @Input()  template: number;
   @Input()  userInfo: UserInfo;
   @Input()  orgUID: string;
   @Input()  groupUID: string;
   @Input()  documentUID: string;

   @Output() selectMarkerEvent = new EventEmitter<any>();
   @Output() scrollToNoteEvent = new EventEmitter<any>();
   @Output() scrollToPageEvent = new EventEmitter<any>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() getPdfEvent = new EventEmitter<any>();

   @ViewChild(MatSort) sort: MatSort;
   @ViewChild('markerlist', {static: false}) markerListView: ElementRef;
   // @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   // @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   // @ViewChild('outerSort', { static: true }) sort: MatSort;
   @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
   @ViewChildren('innerTables') innerTables: QueryList<MatTable<Comment>>;

   // Constants
   TABLE_MARKERS    = 1;
   SIDENAV_MARKERS  = 2;
   MOBILE_MARKERS  = 3;
   LINK_MARKERS  = 4;

   iconPathComment = MarkerList.ICON_PATH_COMMENT;
   iconPathQuestion = MarkerList.ICON_PATH_QUESTION;
   iconPathIssue = MarkerList.ICON_PATH_ISSUE;
   iconPathApproval = MarkerList.ICON_PATH_APPROVAL;      

   // Service Messaging Subscription
   markerSubscription: Subscription;

   // For linking markers
   linkMarkerList: MarkerData[] = [];
   
   // Table Data
   // displayedColumns = ['pageNbr', 'id', 'type', 'owner', 'title', 'description', 'Comments'];
   displayedColumns = ['pageNbr', 'id', 'type', 'title', 'description', 'comments'];   

   innerDisplayedColumns = ['comment', 'owner', 'created_at'];
   moreInnerDisplayedColumns = ['comment', 'owner', 'created_at'];   
   expandedElement: MarkerData | null;

   // dataSource: any;
   matSource: MatTableDataSource<MarkerData>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;

   // View vars
   /* Main MARKERS toggle */
   toggleFiber = false;
   /* Questions toggle */
   toggleStateQ = false;
   /* Comments/Notes toggle */
   toggleState = false;
   /* Issues toggle */
   toggleStateI = false;

   // table themes
   tableThemes = ['striped', 'grid', 'plain'];
   tableTheme = 'striped';

   // Instance vars
   stepMessage: string;
   userUID: string;
   userData: UserData;
   groupName: string;

   loading: boolean;

   // group member info
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedDocUID;
   selectedDocName;
   selectedGroupObj: any;

   // MarkerList for Table View
   markerList: MarkerData[] = [];

   constructor(
      private auth: AuthService,
      private markersvc: MarkerService,
      private usersvc: UserService,
      private toast: ToasterService
      ) {
          this.markerList = [] as MarkerData[];

          // Add sort predicate for alphanumeric
          this.markerList.sort( (a, b) => {
             return a.id.length - b.id.length;
          });

          /*********
          if (this.NOTE_DATA) {
                 console.log('marker-list this.noteData=',
                             this.NOTE_DATA);
          } else {
                 console.log('MarkerListComponent WARNING noteData not defined.');
            }
          if (this.userInfo) {
                 console.log('GM uinfo=', this.userInfo);
          } else {
                  console.log('MarkerListComponent WARNING userInfo not defined.');
                 }
          if (this.groupUID) {
                 console.log('GM groupUID=', this.groupUID);
          } else {
                  console.log('MarkerListComponent WARNING groupUID not defined.');
                 }
          ****/

      } // constructor

   ngOnInit() {
      // console.log('MLC userInfo=', this.userInfo);
      this.matSource = new MatTableDataSource(this.markerList);
      this.matSource.sort = this.sort;
      // this.matSource.paginator = this.paginator;
      this.linkMarkerList = [];
      this.loading = true;
   }

   async ngAfterViewInit() {
      this.loading = true;   
      if( this.template != LINK_MARKERS ) {
         this.reload();
         this.loading = false;
      } else if (this.documentUID &&
                 this.documentUID !== null &&
                 this.documentUID != ''){
                 this.linkMarkerList = await this.markersvc.getSortedMarkerList(this.documentUID);
                 this.loading = false;
        }
      // this.setClickLinkHandlers();
   }

   async loadMarkerList(duid) {
      this.linkMarkerList = await this.markersvc.getSortedMarkerList(duid);
   }

   reload() {
      this.matSource.data = this.markerList;
      this.matSource.sort = this.sort;
      // this.matSource.paginator = this.paginator;
   }

   ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     if (this.markerSubscription) {
        this.markerSubscription.unsubscribe();
     }
   }


  // Table manipulation functions
  //
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.matSource.filter = filterValue;
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.matSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.matSource.data.forEach(row => this.selection.select(row));
  }

   // noteData change handler
   //
   noteDataChange(n) {
      // console.log('MLC noteData Change n = ', n);

      // console.log('MLC noteData Change noteData = ', this.noteData);
      // console.log("markerlist=", this.markerList);

      // Load the markerList for the table view
      if ( this.noteData ) {
          if (this.noteData.question) {
            this.addMarkers(this.noteData.question);
          }
          if (this.noteData.note) {
            this.addMarkers(this.noteData.note);
          }
          if (this.noteData.issue) {
            this.addMarkers(this.noteData.issue);
          }
          if (this.noteData.approval) {
            this.addMarkers(this.noteData.approval);
          }
      }
      // console.log('MLC markerlist=', this.markerList);
      // set the table data source
      try {
         this.matSource.data = this.markerList;
         this.matSource.sort = this.sort;
         // this.matSource.paginator = this.paginator;
      } catch (e) {
         console.log('MLC set datasource error.');
      }
  }

  addMarkers(data) {
     // console.log('addMarkers data=', data);
     let i = 1;
     data.forEach( m => {
             m.element = 'user-' + m.type + '-' + i;
             switch ( m.type ) {
                 case 'question':
                       m.id = 'Q' + i;
                       break;
                 case 'note':
                       m.id = 'C' + i;
                       break;
                 case 'issue':
                       m.id = 'I' + i;
                       break;
                 case 'approval':
                       m.id = 'A' + i;
                       break;
             }
             this.markerList.push(m);
             i++;
     });
  }

  async displayUserInfo(uuid: string) {
     let mbrInfo = '';
     console.log('MLC uuid=', uuid);
     try{
        mbrInfo = await this.usersvc.getUserInfo(uuid);
        console.log('MLC mbrInfo=', mbrInfo);
     } catch (e) {
        console.log('MLC error retrieving userInfo for uuid=' + uuid +
                    'e=' + e);
       }
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  getCommentCount(m): number {
     if (m.comments && Array.isArray(m.comments)) {
         return m.comments.length;
     } else {
          return 0;
       }
  }

  getApprovalCount(m): number {
     if (m.approvals && Array.isArray(m.approvals)) {
         return m.approvals.length;
     } else {
          return 0;
       }
  }

  getDate(m): string {
     let utcSeconds = 0;
     let dt   = '';
     if (m?.created_at) {
           utcSeconds = m.created_at;
           const d = new Date(0);
           d.setUTCSeconds(utcSeconds);
           dt = d.toISOString();
     }
     return dt;
  }

  getDateString(time): string {
     if ( !time ) {return 'unknown';}
     const pattern = 'MM/dd/yy HH:mm:ss z';
     const locale = 'en-US'
     const dts = formatDate( time, pattern, locale );
     return dts;
  }

  htmlClean( t, h ) {
    return cleanHtml(t, h);
  }

  getPDF() {
    const pdfDoc = this.createPDF();

    // Ouput PDf doc
    const pdf = pdfDoc.output();
    console.log('getPDF() emit out=');
    this.getPdfEvent.emit(pdf);
    return pdf;
  }

  getPDFBase64() {
    const pdfDoc = this.createPDF();

    // Open PDf doc in new tab to print..
    // base64
    const pdf = pdfDoc.output('dataurlstring');
    console.log('getPDF() emit out=');
    this.getPdfEvent.emit(pdf);
    return pdf;
  }

  getPDFArray() {
    const pdfDoc = this.createPDF();

    // Open PDf doc in new tab to print..
    const pdf = pdfDoc.output('arraybuffer');
    console.log('getPDFArray() emit out=');
    this.getPdfEvent.emit(pdf);
    return pdf;
  }

  printPDF() {
    const pdfDoc = this.createPDF();

    // Open PDf doc in new tab to print..
    pdfDoc.output('dataurlnewwindow');
  }

  downloadPDF() {
    const pdfDoc = this.createPDF();

    // Download Doc
    pdfDoc.save('marker-report.pdf');
  }

  compareRows(a, b) {
    // By default sorts first value pageNbr
    // Might want to enhance later to choose sort order or match
    // sort column with dialog preview. CJ.
    //
    if ( Number(a[0]) > Number(b[0]) ) {
       return 1;
    }
    if ( Number(a[0]) < Number(b[0]) ) {
       return -1;
    }
    return 0;
  }

  createPDF(): any {
    const data = [];
    const doc  = new jsPDF({ orientation: 'landscape',
                             format: 'letter'        }
                           );
    doc.setFontSize(11);
    doc.text(this.documentName, 10, 8);
    doc.text('Annotation Marker Report', 225, 8);
    doc.setFontSize(10);
    doc.setTextColor(100);

    // Create Array of Arrays
    this.markerList.forEach(rec => {
      const row = [];
      console.log('markerList rec=', rec);
      row.push(rec.pageNbr);
      row.push(rec.id);
      row.push(rec.type);
      // row.push(rec.owner);
      row.push(rec.title);
      const desc = rec.owner + '\n' + this.getDateString(rec.created_at) +
                   '\n\n' + this.htmlClean(rec.description,false);
      row.push(desc);
      let comments = '';
      if ( rec.comments && rec.comments.length > 0 ) {
         rec.comments.forEach(cmt => {
            const txt = cmt.owner + '\n' + this.getDateString(cmt.created_at) +
                        '\n\n' + this.htmlClean(cmt.comment,false) + '\n\n';
            comments = comments + txt;
         });
      }
      row.push(comments);
      data.push(row);
    });

    // Sort the Data
    data.sort(this.compareRows);

    /*** This works on normal mat-table
    this.matSource.filteredData.forEach(obj => {
       let arr = [];
       this.displayedColumns.forEach(col => {
          arr.push(obj[col]);
       });
       data.push(arr);
    });
    ***/

    // console.log('dataSource filtered data=', data);
    // const headings = [['Page', 'ID', 'Type', 'Creator', 'Title', 'Description', 'Comments']];
    const headings = [['Page', 'ID', 'Type', 'Title', 'Description', 'Comments']];
    autoTable(doc, {
       head: headings,
       theme: 'striped',
       body: data,
       margin: { top: 10, left: 8, bottom: 10, right: 8 },
       columnStyles: {
       0: {cellWidth: 15},
       1: {cellWidth: 15},
       2: {cellWidth: 20},
       3: {cellWidth: 55},
       4: {cellWidth: 80},
       5: {cellWidth: 80},
       }
    });

    return doc;
  }

  // table
  scrollToPage(e) {
    console.log('ml: scroll to page=', e);
    this.scrollToPageEvent.emit(e);
  }

  // table and sidenav
  //
  scrollToNote(e) {
    console.log('ml: scroll to note=', e);
    this.scrollToNoteEvent.emit(e);
  }

  // SideNav functions
  //

  minimize_comments(idSection: string){
     const displayState = document.getElementById(idSection).style.height;
     if (displayState === '0px') {
         document.getElementById(idSection).style.opacity = '1';
         document.getElementById(idSection).style.height = 'auto';
         document.getElementById(idSection).style.width = 'auto';
     }else {
         document.getElementById(idSection).style.opacity = '0';
         document.getElementById(idSection).style.height = '0px';
         document.getElementById(idSection).style.width = '0px';
     }
  }

  collapseComments(idHeaderSection: string) {
     const openState = document.getElementById(idHeaderSection).style.height;
     if (openState === '0px') {
         document.getElementById(idHeaderSection).style.opacity = '1';
         document.getElementById(idHeaderSection).style.height = 'auto';
         document.getElementById(idHeaderSection).style.width = 'auto';
     } else {
         document.getElementById(idHeaderSection).style.opacity = '0';
         document.getElementById(idHeaderSection).style.height = '0px';
         document.getElementById(idHeaderSection).style.width = '0px';
     }
  }

  emitSelectMarker(n) {
     this.selectMarkerEvent.emit(n);
  }

/****
  setClickLinkHandlers() {
     // Sets up the hyperlink interceptors for the marker content
     //
     //console.log('finding element for id=', this.id);
     const dialog = document.getElementById(this.id);
     //console.log('setting click handlers for:', dialog);
     const intLinks = dialog.getElementsByTagName('a');
     //console.log('intLinks=', intLinks);
     for( let i=0; i < intLinks.length; i++) {
        //console.log('intLinks[i]=', intLinks[i]);
        intLinks[i].addEventListener('click', this.linkIntercept.bind(this), false);
     }
  }

  linkIntercept( _event ) {
     // Interceptor for hyperlinks contained in note marker text.
     // Important or will navigate to link without pre-processing.
     _event.preventDefault();
     _event.stopPropagation();
     const tEvent = _event || window.event;
     const ele = tEvent.target || tEvent.srcElement;
     //console.log('handleClickLink evt=', tEvent);
     //console.log('handleClickLink ele=', ele);
     //console.log('handleClickLink tag=', ele.tagName);

     const href = ele.getAttribute('href');
     let target = ele.getAttribute('target');
     const linkClass = ele.className;

     //console.log('linkIntercept href=', href);
     //console.log('linkIntercept target=', target);
     //console.log('linkIntercept class=', linkClass);

     if ( href === null ) {
       return;
     }

     if ( ( target === undefined ) ||
          ( target === null )      ||
          ( target === '' )        ||
          ( target !== '_blank' ) ) {
          target = '_top';
          //console.log('target=', target);
     }

     //console.log('marker-dialog linkIntercept href=', href);
     const url = href.split("?")[0];
     let server = url.split("//")[1];
     if ( server ) {
       server = server.replace('/','');
     } else {
         server = url.split("//")[0];
     }
     const paramsBlock = href.split("?")[1];

     let argsBlock;

     if ( paramsBlock ) {
        argsBlock = paramsBlock.split('&');
     }

     const origin = window.location.origin;

     let docid = '';
     let noteid = '';
     let page = '';

     if ( argsBlock ) {
       for (let i=0; i < argsBlock.length; i++) {
         const map = argsBlock[i].split('=');
         if ( map && map[0] === 'docid' && map[1] ) {
            docid = map[1];
         }
         if ( map && map[0] === 'noteid' && map[1] ) {
            noteid = map[1];
         }
         if ( map && map[0] === 'page' && map[1] ) {
            page = map[1];
         }
       }
     }
     //console.log('server=', server);
     //console.log('origin=', origin);

     //console.log('argsBlock=', argsBlock);
//     console.log('argVals=', argVals);
     //console.log('docid=', docid);
     //console.log('noteid=', noteid);
     //console.log('page=', page);
     //console.log('activeDocument=', this.documentsvc.activeDocument );

     const docobj = this.documentsvc.DocumentList.find(d => d.uid === docid);

     const linkData = {
        "docid": docid,
        "docobj": docobj,
        "noteid": noteid,
        "page": page,
        "server": server,
        "origin": origin,
        "href" : href
     }
     if ( docid && docid === this.documentsvc.activeDocument ) {
        // Internal link, same document
        //console.log('document link docid=', docid);
        if ( noteid ) {
           this.scrollToNote( noteid );
        }
        if ( page && !noteid ) {
           this.scrollToPage( page );
        }
     } else if ( server === 'markadoc' ) {
               const new_href = href.replace( server, origin );
               //console.log('internal link new_href=', new_href);
               this.handleInternalLinkEvent.emit(linkData);
// NOTE window methods with internal link ref will reload the app
//               window.open( new_href, target, 'noopener,noreferrer');
//               window.location.href = new_href;
            } else if ( server === 'markadoc-nt' ) {
                   //console.log('open md tab link data=', linkData);
                   this.openConfirmNewTab( linkData, 'mdTab' );
              } else {
                   //console.log('external link href=', href);
                   this.openConfirmExternalLink( href, 'extLink' );
                }

     return false;
  }
****/

} // marker-list
