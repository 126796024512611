import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Input, Output, ViewChild }
       from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../environments/environment';

// import { MDToolsModule } from '../mdtools/mdtools.module';

import { SortKey } from './sortkey.pipe';
import { FilterKeys } from './filterkeys.pipe';
import { FilterOutKey } from './filteroutkey.pipe';
import { NameFilter } from './name-filter.pipe';
import { FilterPipe } from './filter.pipe';
import { Linkify } from './linkify.pipe';

@NgModule({
  declarations: [
    SortKey,
    FilterKeys,
    FilterOutKey,
    NameFilter,
    FilterPipe,
    Linkify,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SortKey,
    FilterKeys,
    FilterOutKey,    
    NameFilter,
    FilterPipe,
    Linkify,
  ],
  providers: [
        // AdminService,
        /**
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {   provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
            deps: []
        },      
        ToasterService,
        ToastrService,
        **/
        SortKey,
        FilterKeys,
        FilterOutKey,	
        NameFilter,
        FilterPipe,
        Linkify,        
    ],
    entryComponents: [
                     ]
  
})
export class PipeModule { }
