import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { MainComponent } from './main/main.component';
// import { MgrMainComponent } from './mgr/mgr-main/mgr-main.component';
import { UIComponent } from './ui/ui.component';
import { LoginComponent } from './login/login.component';
import { NewUserEmailComponent } from './new-user-email/new-user-email.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    { path: 'mdmgr',
      loadChildren: () =>
                    import('./mdmgr/mdmgr.module').then(m=>m.AppMdMgrModule),
      canActivate: [AuthGuard] },
    { path: '', component: UIComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: NewUserEmailComponent },
];

// Need useHash: true instead of relativeLinkResolution for URL parameters
// 
@NgModule({
//    imports: [RouterModule.forRoot(routes, { useHash: 'true' })],
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected', paramsInheritanceStrategy: 'always'})],

    exports: [RouterModule]
})

export class AppRoutingModule { }
