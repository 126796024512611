<ng-template #loading>
  <div>loading...</div>
</ng-template>

  <mat-menu #objListMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
    <!-- *ngIf="userData.iam" -->
   <button mat-menu-item  (click)="createObj()">
     <mat-icon>add</mat-icon>        
     <span style="font-size: 0.9em">Add {{objLabel}}.</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"
	   mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"   
            mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>domain</mat-icon>  
      <span style="font-size: 0.9em">View {{objLabel}}</span>
   </button>
   <button *ngIf="userData && userData.uid && selectedObj && (selectedObj['owner']===userData.uid)"
	  mat-menu-item (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

  <mat-menu #listViewMenu="matMenu">
    <button mat-menu-item enabled (click)="setMode(DisplayMode.COMBO)">
          <mat-icon>input</mat-icon>  
          <span style="font-size: 0.9em">Combo Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT)">
          <mat-icon>arrow_drop_down</mat-icon>  
          <span style="font-size: 0.9em">Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT_LIST)">
          <mat-icon>view_list</mat-icon>  
          <span style="font-size: 0.9em">Select List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.TABLE); refreshList()">
          <mat-icon>table_rows_narrow</mat-icon>  
          <span style="font-size: 0.9em">Table</span>
    </button>
  </mat-menu>
  
  <mat-menu #rowMenu="matMenu">
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="setSelectedObj(obj); viewObj()">
         <mat-icon>domain</mat-icon>  
         <span style="font-size: 0.9em">View Info</span>
      </button>
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); editObj()">
         <mat-icon>edit</mat-icon>  
         <span style="font-size: 0.9em">Edit {{objLabel}}</span>
      </button>
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); deleteObj()">
         <mat-icon>delete</mat-icon>
         <span style="font-size: 0.9em">Delete {{objLabel}}</span>
      </button>
    </ng-template>    
  </mat-menu>
  
  <ng-template #hidden>
  </ng-template>
  
  <ng-template #objSelect>
    <div style="display: flex; flex-flow: row; min-width: 60vw;">
    <mat-icon *ngIf="selectedObj && selectedObj.org_uid" style="padding-left: 0.5rem; padding-top: 0.2em; padding-right: 0.1em" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="selectedObj && ! selectedObj.org_uid" style="padding-left: 0.5rem; padding-top: 0.2em; padding-right: 0.1em" (click)="viewObjDialog()">person</mat-icon>    
	 <mat-select 
          class="org-select"
           style="z-index: 900; color: #2274A5; font-size: 1em; width: 80%; border-bottom: 2px solid #2274A5;; margin-left: 1rem; margin-right: 1rem; min-width: 50vw;"
           [ngModelOptions]="{standalone: true}"
	   [(ngModel)]="selectedObj"
           [compareWith]="compareObjs"	   
	   placeholder="Select {{objLabel}}"
           ngDefaultControl selected="selectedOrg"	   
           (selectionChange)="setSelectedObj(selectedObj)">
	   <mat-select-trigger>
          <!--- <mat-icon matPrefix *ngIf="selectedObj && selectedObj.org_uid" style="margin-right: 1rem">domain</mat-icon> --->
	    <span *ngIf="selectedObj">
	      {{selectedObj.name ? selectedObj.name : ''}}
	    </span>
            <span *ngIf="selectedObj && (userData && userData.uid===selectedObj.owner)"
		  style="font-size: 0.8rem; align-self: end; align: end"> &#9733;</span>
	   </mat-select-trigger>
          <mat-option id="no_org" style="color: blue; font-size: 1em" [value]="null" style="color: #2274A5;: font-size: 1em">None</mat-option>	  
          <mat-option *ngFor="let o of groupsvc.userGroupListUpdates | async" [value]="o" style="color: #2274A5;: font-size: 1em">
            <mat-icon matPrefix *ngIf="o && o.org_uid" style="margin-right: 1rem">domain</mat-icon>	    	    
	    {{o['name'] ? o['name'] : ''}}
            <span *ngIf="userData && userData.uid===o.owner"
		  style="font-size: 0.8rem; align-self: end; align: end"> &#9733;</span>
	  </mat-option>
	 </mat-select>
	 <button mat-stroked-button
		 (click)="setTemplate(DisplayTemplate.LIST)"
		 style="width: 0.8em; margin-right: 0.4em; background-color: white; color: white;">
	   <mat-icon style="width: 0.8em; color: black;">search</mat-icon>
	 </button>
	 <!-- 		 *ngIf="userData.iam" -->
         <button mat-stroked-button
                 (click)="createObj()"
		 style="width: 0.8em; margin-right: 0.4em; background-color: white; color: black;">		 
            <mat-icon style="width: 0.8em; color: black;">add</mat-icon>
         </button>
	 <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">		 
	   <mat-icon style="width: 0.8em; color: #2274A5;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="width: 0.8em; margin-right: 0.4em; color: #2274A5;">		 
	   <mat-icon style="width: 0.8em; color: #2274A5;">list</mat-icon>
	 </button>
   </div>	 
  </ng-template>

  <!-- for input [formControl]="myControl" -->
  <!-- (input)="onOrgNameChange($event)" 
       (input)="onOrgNameChange($event)"
	   name="orgNameInput"
              (optionChange)="setSelectedOrg(selectedOrg)"
	      [ngClass]="(orgNameInput.value===org.org_name)?'mat-selecte-default':''"
  -->
  <ng-template #objCombo>
    <div style="display: flex; flex-flow: row; min-width: 50vw;">
    <mat-icon *ngIf="selectedObj && selectedObj.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.1em" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="selectedObj && ! selectedObj.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.1em" (click)="viewObjDialog()">person</mat-icon>    
      <form class="combo-form" [formGroup]="comboForm">
	<mat-form-field class="combo-form-field"
                        (click)="clearComboInput()"			
			[appearance]="formFieldStyle"
			[floatLabel]="'always'" style="font-size: 0.8rem">
          <mat-label class="form-label">{{objLabel}} Name</mat-label>

	  <!--  [ngClass]="(objNameInput.value===obj['name'])?'mat-selecte-default':''"	 
	  <mat-icon matPrefix *ngIf="selectedObj && selectedObj.org_uid" style="margin-right: 1rem">domain</mat-icon> -->
          <input #objName
		 type="text"
                 placeholder="Enter or Select Name"
                 matInput		 
                 style="font-size: 1rem;"
                 [formControl]="objNameInput"
                 aria-label="Group Name"
		 [value]=""
                 [matAutocomplete]="autoCombo">
          <mat-autocomplete #autoCombo="matAutocomplete"
			    [displayWith]="getOptionName"
                            (optionSelected)="setSelectedObjEvent($event)"
                            placeholder="Group">
	    <div *ngFor="let obj of (groupsvc.userGroupListUpdates | async)">
	      <mat-option *ngIf="obj.name.includes(objName.value)"
                          [value]="obj" [id]="obj.uid">
               <mat-icon matPrefix *ngIf="obj && obj.org_uid" style="margin-right: 1rem">domain</mat-icon>			      		
                {{obj.name ? obj.name : ''}}
                <span *ngIf="userData && userData.uid===obj.owner"
		      style="font-size: 0.8rem"> &#9733;</span>		
	      </mat-option>
	    </div>
	    <!---
            <mat-option
	      *ngFor="let obj of (this.filteredOptions | async)"
	       [value]="obj" [id]="obj.uid">
              {{obj.name ? obj.name : ''}}
              <span *ngIf="userData && userData.uid===obj.owner" style="font-size: 0.8rem"> &#9733;</span>
	    </mat-option>	      
            --->
	  </mat-autocomplete>
	  
	  <button matSuffix (click)="clearComboInput()"
		  style="margin-left: 1rem">
	  <mat-icon>arrow_drop_down</mat-icon></button>
    </mat-form-field>
      </form>
      <div #comboButtonDiv class="combo-button-div" style="display: flex; flex-flow: row nowrap; align-items: center;">
	 <button *ngIf="showAddButton"
		 mat-stroked-button
                 (click)="createObj()"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">
	   <mat-icon style="color: black">group_add</mat-icon>
	 </button>
	 <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
                 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">		 
	   <mat-icon style="color: black;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
                 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">		 		 
	   <mat-icon style="color: black;">list</mat-icon>
	 </button>
	</div>
      </div>
  </ng-template>
  
   <!-- SELECT_ORG TEMPLATE -->
   <div *ngIf="template === DisplayTemplate.SELECT"
	style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
     <ng-container *ngTemplateOutlet="objSelect">
     </ng-container>
  </div>  <!-- END SELECT_ORG Template  -->

   <!-- COMBO TEMPLATE -->
   <div *ngIf="template === DisplayTemplate.COMBO"
	style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
     <ng-container *ngTemplateOutlet="objCombo">
     </ng-container>
  </div>  <!-- END COMBO Template  -->
   
  <div #grpList *ngIf="template === DisplayTemplate.LIST">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">						
            (click)="onDone()">
              Done
        </button>
  <!-- style="margin-top: -1em; background-color: #2274A5; color: white" -->
        <button mat-stroked-button
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">				
  	        (click)="createObj()">
            <mat-icon>add</mat-icon>        
        </button>
        <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		[matMenuTriggerFor]="objListMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">		
	   <mat-icon style="width: 0.8em; color: black;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">				 
	   <mat-icon style="width: 0.8em; color: black;">list</mat-icon>
	 </button>
    </div>

      <div *ngFor="let obj of groupsvc.userGroupListUpdates | async | filterkeys: ['name']: titleFilter | sortkey: 'name': 'asc'"
           style="display: flex; flex-flow: column; justify-content: space-beteen; width: 100%; color: black">
	<mat-divider style="min-width: 100%"></mat-divider>
        <mat-icon matPrefix *ngIf="obj && obj.org_uid" style="margin-right: 1rem">domain</mat-icon>

       <app-group #grpListItem
	   style="width: 100%"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="obj"
           [selectedObj]="selectedObj"
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
           (selectGroupEvent)="setSelectedObj($event)"		  
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()"	 
	   >
       </app-group>

	<!--       
       <mat-list-item *ngIf="selectedObj && (obj.uid===selectedObj.uid); else unSelectedItem"
	 style="display: flex; flex-flow: row; justify-content: space-between; background: #2274A5; color: white;" (click)="setSelectedObj(obj)">
	 <span style="margin-left: 1rem; width: 95%;">{{obj['name']}}</span>
         <span *ngIf="userData && userData.uid===obj.owner" style="font-size: 0.8rem; justify-content: space-between;"> &#9733;</span>
       </mat-list-item>		      
       <ng-template #unSelectedItem>
	 <mat-list-item
	 style="display: flex; flex-flow: row; justify-content: space-between; width: 100%; background: white; color: #2274A5" (click)="setSelectedObj(obj);">
	 <span style="margin-left: 1rem">{{obj['name']}}</span>
         <span *ngIf="userData && userData.uid===obj.owner" style="font-size: 0.8rem"> &#9733;</span>	 
       <mat-icon *ngIf="selectedObj && (obj.uid===selectedObj.uid)"
		 style="align: end; align-self: center; margin-left: 0.4em; margin-right: 0.5em; color: #2274A5">
	         check
       </mat-icon>
       </mat-list-item>
       </ng-template>
       -->
       <mat-divider [inset]="true"></mat-divider>       
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>


  <div #grpList *ngIf="template === DisplayTemplate.SELECT_LIST">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
  <!-- style="margin-top: -1em; background-color: #2274A5; color: white" -->
        <button mat-stroked-button
		style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;"
		(click)="createObj()">
            <mat-icon>add</mat-icon>        
        </button>
        <button *ngIf="showRefreshButton"
		mat-stroked-button
            style="margin-top: -1em; margin-left: 2em"
                (click)="refreshList()">
            <mat-icon>refresh</mat-icon>        
        </button>
        <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">	
	   <mat-icon style="width: 0.8em; color: black;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">				 		 
	   <mat-icon style="width: 0.8em; color: black;">list</mat-icon>
	 </button>
    </div>

      <div *ngFor="let obj of groupsvc.userGroupListUpdates | async | filterkeys: ['name']: titleFilter | sortkey: 'name': 'asc'"
           style="display: flex; flex-flow: column; justify-content: space-beteen; width: 100%; color: black">
	<mat-divider style="min-width: 100%"></mat-divider>
       <app-group #grpListItem
	   style="width: 100%"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="obj"
           [selectedObj]="selectedObj"
           [showActions]="false"
           [showMenuButton]="true"
	   [showDeleteButton]="true"		  
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
           (selectGroupEvent)="setSelectedObj($event)"		  
  	   (doneEvent)="onDone()"
	   (cancelEvent)="onDone()"		  
	   >
       </app-group>
       <mat-divider [inset]="true"></mat-divider>       
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>
  
  <div *ngIf="template === DisplayTemplate.FORM">
    <div *ngIf="showObjSelect">
       <ng-container *ngTemplateOutlet="objSelect">
       </ng-container>
    </div>
    <mat-divider></mat-divider>
    <mat-list>
     <div>
       <app-group #grpFormItem
	   style="width: 100%"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedObj"
           [selectedObj]="selectedObj"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedObj($event)"
           (selectGroupEvent)="setSelectedObj($event)"		  		  
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()">
        </app-group>
        <mat-divider [inset]="true"></mat-divider>	
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
  </mat-list>
</div>
  
 <!-- GROUP_TABLE --> 

  <div *ngIf="template===DisplayTemplate.TABLE">

      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field 
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em; color: #2274A5">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 (keyup)="applyTableFilter(titleFilterInput.value)"
		 style="width: 60%; font-size: 1rem; color: #2274A5"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
  <!-- style="margin-top: -1em; background-color: #2274A5; color: white" -->
        <button mat-stroked-button
            style="margin-top: -1em; margin-left: 2em; color: #2274A5;"
  	        (click)="createObj()">
          <mat-icon style="color: 2274A5">add</mat-icon>	    
	  Add {{objLabel}}
        </button>
        <button *ngIf="selectedObj!==null && showMenuButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="objListMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">				 		 
	   <mat-icon style="width: 0.8em; color: black;">more_vert</mat-icon>
	 </button>
	 <button *ngIf="showListViewButton"
		 mat-stroked-button
		 [matMenuTriggerFor]="listViewMenu"
		 style="margin-left: 0.7rem; margin-right: 0.7rem; margin-top: -0.7rem; height: 3rem;">				 		 
	   <mat-icon style="width: 0.8em; color: black;">list</mat-icon>
	 </button>
    </div>

 <div class="table-container">
	
 <mat-table #objTable [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" style="min-width: 80vw; max-width: 95vw; max-height: 95vh; margin-left: 0.7em"> 
   <!--   <mat-table #objTable [dataSource]="this.orgList" style="min-width: 75vw; max-width: 95vw; min-height: 95vh; margin-left: 0.7em">      -->     

    <!-- group name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell *matCellDef="let obj" 
		style="word-wrap: break-word; width: 20%; cursor: default;">
	<div (click)="setSelectedObj(obj); viewObj()">
	     <mat-icon *ngIf="obj.org_uid" style="">
	       domain
	     </mat-icon>
	     {{obj.name}}
	     <span *ngIf="userData.uid===obj.owner" style="font-size: 0.8rem"> &#9733;</span>
	</div>
      </mat-cell>
    </ng-container>

    <!-- org uid Column -->
    <ng-container matColumnDef="org_uid">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Org UID</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;">
	{{obj.org_uid}}
      </mat-cell>
    </ng-container>

    <!-- owner Column -->
    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;">
	{{obj.owner}}
      </mat-cell>
    </ng-container>

    <!-- owner email column -->
    <ng-container matColumnDef="owner_email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
      <mat-cell *matCellDef="let obj;"
		style="word-wrap: break-word; width: 20%; cursor: default;">
	<div *ngIf="(usersvc.getUserInfo$(obj.owner) | async) as usr">
	<div *ngIf="usr && usr.email; else loading">
	  {{usr.email}}
	</div>
	</div>
      </mat-cell>
    </ng-container>
    
    <!-- group actions  (click)="setSelectedObj(obj)" -->
    <ng-container matColumnDef="obj_actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;">
        <button mat-stroked-button 
	    [matMenuTriggerFor]="rowMenu" [matMenuTriggerData]="{obj: obj}"
            style="width: 0.8em; margin-left: 0.5em; margin-right: 0.4em; color: #2274A5;">	
          <mat-icon style="width: 0.8em; color: #2274A5;">more_vert</mat-icon>
        </button>	  
      </mat-cell>	
    </ng-container>
    
    <!-- group selector (click)="setSelectedObj(obj)" -->
    <ng-container matColumnDef="obj_selector">
      <mat-header-cell *matHeaderCellDef>Selected</mat-header-cell>
      <mat-cell *matCellDef="let obj"
		style="word-wrap: break-word; width: 20%; cursor: default;">
        <mat-checkbox class="example-margin"
          [checked]="selectedObj && (obj.uid === selectedObj.uid)"
          (change)="toggleChecked(obj)">
        </mat-checkbox>
      </mat-cell>	
    </ng-container>          
    
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

 </div>
 
   <mat-paginator #paginator 
     [length]="0"
     [pageSizeOptions]="[8, 15, 25, 50, 75, 100]"
     [showFirstLastButtons]="true"
     (page)="onTableChangePage($event)">
   </mat-paginator>  
  </div>
  <!-- ORG_TABLE Template -->

  <!-- Expand List Template -->

  <div #orgExpandList *ngIf="template === DisplayTemplate.EXPAND_LIST">
    <mat-list>
      <div style="display: flex; flex-flow: row; justify-content: space-around; align-items: center;">
	
	<mat-form-field *ngIf="showTitleFilter"
			class="mat-header-row"
			style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
          <input matInput #titleFilterInput
		 [(ngModel)]="titleFilter" type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
	</mat-form-field>
        <button *ngIf="showSelectButton" mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
		(click)="onDone()">
              Done
        </button>
        <button *ngIf="showMenuButton"
	        mat-stroked-button
            style="margin-top: -1em; background-color: #2274A5; color: white;"
  	        (click)="createObj()">
            <mat-icon>add</mat-icon>
        </button>
      </div>

   <div *ngFor="let grp of groupsvc.userGroupListUpdates | async | filterkeys: ['name']: titleFilter | sortkey: 'name': 'asc'"
	 style="display: flex; flex-flow: column; width: 100%">
     <mat-divider style="min-width: 100%"></mat-divider>      
       <app-group #grpListItem
	   style="width: 100%"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="grp"
           [selectedObj]="selectedObj"
           (refreshEvent)="refreshList()"	 
           (selectObjEvent)="setSelectedObj($event)"
           (selectGroupEvent)="setSelectedObj($event)"		  
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()"	 
	   >
       </app-group>
       <mat-divider [inset]="true"></mat-divider>
     </div>     
     <mat-divider style="color: gray; min-width: 100%"></mat-divider>
    </mat-list>
  <div class="list-bottom-div">
    <div class="list-btn-div">
       <button mat-stroked-button class="submit-btn" (click)="onDone()">
               Done</button>
    </div>
  </div>
  </div>

 <!-- Delete Template -->
  <div #grpDelete *ngIf="template === DisplayTemplate.DELETE">
       <app-group #grpFormItem
	   style="width: 100%"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedObj"
           [selectedObj]="selectedObj"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedObj($event)"
           (selectGroupEvent)="setSelectedObj($event)"		  
           (deleteObjEvent)="refreshList()"
           (submitEvent)="onDone()"
	   (cancelEvent)="onDone()">
        </app-group>
       
  </div>

<!--
</div> <!-- group-list component -->
