/* eslint-disable */
// in Marker
// scope is D for document
//          P for page
//          S for section
//
// in MarkerApproval
// response is P for Pending
//             Y for Yes
//             N for No

export class MarkerApproval {
  created_at: number;
  scope: string;
  ownerUID: string;
  ownerEmail: string;  
  responderUID: string;
  responderEmail: string;
  response: string;
  responseTimestamp: number;  
  }
