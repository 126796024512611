// #279 - CJ - This component is only built for ADD MEMBER and ADD MEMBERS
//             functionality. If a group-member component is built to display
//             group members in various formats it can emit an addMemberEvent
//             and addMultipleEvent so another component can handle the display
//             of this component to add members.
//
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, Inject, EventEmitter, ElementRef, OnChanges,
         SimpleChanges } from '@angular/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ToasterService } from '../service/toaster.service';

import { environment } from '../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { SubaccountEditComponent } from '../subaccount-edit/subaccount-edit.component';

import { GroupData } from '../model/groupdata.model';
import { GroupMember } from '../model/groupmember.model';
import { FileSelector} from '../model/fileselector.model';

import { AuthService } from '../service/auth.service';
import { GroupMemberService } from '../service/group-member.service';
import { UserService } from '../service/user.service';
import { UserSubAccountService } from '../service/user-subaccount.service';
import { ValidationService } from '../service/validation.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';


export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v<>';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export const ADD_MEMBER      = 1;
export const ADD_MULTIPLE    = 2;

export interface MemberData {
  uid: string;
  groups_uid: string;
  name: string;
  last_name: string;
  email: string;
  Company: string;
  phone: string;
}

@Component({
   selector: 'app-add-group-member',
   templateUrl: './add-group-member.component.html',
   styleUrls: ['./add-group-member.component.css'],
})
export class AddGroupMemberComponent implements OnInit, AfterViewInit {
   @Input()  template: number;
   // @Input()  orgUID: string;
   // @Input()  orgUnitUID: string;
   @Input()  userInfo: UserInfo;
   @Input()  group: GroupData;

   @Output() addMemberEvent = new EventEmitter<any>();
   @Output() refreshSubsEvent = new EventEmitter<any>();
   @Output() stepMessageEvent = new EventEmitter<any>();

   @ViewChild('groupmember', {static: false}) memberListView: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;

   // Constants
   ADD_MEMBER      = 1;
   ADD_MULTIPLE    = 2;

   // Service Messaging Subscription
   memberSubscription: Subscription;

   // View vars
   emailText: any;
   emailAddress: string;
   junkText: any;
   stepMessage: string;
   statusMessage = '';

   addGuestAccounts = false;
   addFreeAccounts = false;

   // Instance vars
   emailAddressList: string[];
   userUID: string;
   userData: UserData;
   userSubList: any;
   userSubLimit: number;
   userSubAvail: number;
   groupName: string;
   loading: boolean;

   newUserCnt: number;
   newUserErr: number;
   newMemberCnt: number;
   newMemberErr: number;
   newFreeCnt: number;
   newFreeErr: number;
   newTokenCnt: number;
   newTokenErr: number;

   // group member info
   selectedGroupMemberObj: GroupMember;
   selectedGroupMember: string;
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedName;
   selectedGroupObj: any;

   constructor(
      private auth: AuthService,
      private groupmembersvc: GroupMemberService,
      private usersvc: UserService,
      private usrsubacctsvc: UserSubAccountService,
      private validationsvc: ValidationService,
      private toast: ToasterService
      ) {
          this.userSubLimit = 0;
          if (this.userInfo) {
                 this.userSubLimit = this.userInfo.accounts;
                 // console.log('GM uinfo=', this.userInfo);
          } else {
                  console.warn('AddGroupMemberComponent WARNING userInfo not defined.');
                 }
          if (this.group) {
                 console.log('GM group=', this.group);
          } else {
                  console.warn('AddGroupMemberComponent WARNING group not defined.');
                 }
      } // constructor


   async ngOnInit() {
      console.log('AGM userInfo=', this.userInfo);
      console.log('AGM this.group=', this.group );

      try {
        if ( this.userInfo && this.userInfo.accounts ) {
           this.userSubLimit = this.userInfo.accounts;
        } else {
           this.userSubLimit = 0;
          }
        this.userSubList = await this.get_subs(this.userInfo.uid);
        if ( this.userSubList ) {
          const used = this.userSubList.length;
          this.userSubAvail = this.userSubLimit - used;
        } else {
            this.userSubAvail = 0;
        }
        console.log('subs=', this.userSubList );
      } catch (e) {
           console.log('subs=', e );
        }
   }

   async ngAfterViewInit() {
   }

   isOwnerAdmin(): boolean {
      // 279- CJ - Check if owner, will need to check if user is admin
      // also at a later date once we add admins
      if (this.group && this.userInfo) {
         if (this.group.owner === this.userInfo.uid) {
            return true;
         } else {
             return false;
             }
      }
   }

   checkAddMember() {
     const proceed = this.isOwnerAdmin();
     console.log('add-group-member proceed=', proceed);
     if (proceed ) {
           this.handleAddMember();
     } else {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           }
   }

   async handleAddMember() {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      // check to make sure a group is selected
      if (!addGroup || addGroup === null) {
         this.toast.pop('error', 'Add User to Group',
         'Cant find group id, please select a group.');
         this.stepMessage = 'Error cant find group id. Please try again.';
         this.stepMessageEvent.emit(this.stepMessage);
         console.log('handleAddUser error: Group is not selected');
         return;
      }

      // make sure the user owns the group (or has admin privs)
      const proceed = this.isOwnerAdmin();
      console.log('handleAddMember proceed=', proceed);
      if ( ! proceed ) {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           return;
        }

      this.doAddMember(this.emailAddress, true);
   }

   async handleAddMemberList() {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      // check to make sure a group is selected
      if (!addGroup || addGroup === null) {
         this.toast.pop('error', 'Add User to Group',
         'Cant find group id, please select a group.');
         this.stepMessage = 'Error cant find group id. Please try again.';
         this.stepMessageEvent.emit(this.stepMessage);
         console.log('handleAddUser error: Group is not selected');
         return;
      }

      // make sure the user owns the group (or has admin privs)
      const proceed = this.isOwnerAdmin();
      console.log('handleAddMember proceed=', proceed);
      if ( ! proceed ) {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           return;
      }
      this.doAddMemberList();
   }

   async doAddMember(userEmail, doToast) {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;
      console.log('doAddMember: ', userEmail);

      // validate email address and process
      if ( this.validationsvc.isValidEmailAddress(userEmail) === true) {

        // Future - See if user is already member of the group here..

        // if the option is selected add user as guest account
        if (this.addGuestAccounts === true ) {
           // add guest subaccounts
          const g = await this.doAddGuest(userEmail, doToast);
        }

        // if the option is selected send user free trial account email
        if (this.addFreeAccounts === true ) {
           // send free trial invitations
           // const f = await this.doAddFree(userEmail, doToast);
        }

        try {
           const x = await this.groupmembersvc.addMemberPromise(this.userInfo.uid, userEmail, addGroup);
           this.stepMessage = 'Added ' + userEmail + ' to ' +
                              addGroupName;
           this.stepMessageEvent.emit(this.stepMessage);
           if (doToast) {
             this.toast.pop('success', 'Group Member Added',
             'User ' + userEmail + ' was added to group ' + this.group.name );
           }
           userEmail = '';
           this.newMemberCnt++;
         } catch (error) {
                console.log('processAddMember: add group member error=', error);
                this.stepMessage = 'Error Adding ' + userEmail + ' to '
                                   + addGroupName;
                this.stepMessageEvent.emit(this.stepMessage);
                if (doToast) {
                 this.toast.pop('error', 'Error Adding Group Member!',
                 'Unable to add ' + userEmail + ' to group ' + this.group.name);
                }
                this.newMemberErr++;
                throw error;
          }
      } else {
          if (doToast) {
             this.toast.pop('error', 'Malformed Email Address!',
             'Please correct the error and try again');
          }
          this.newMemberErr++;
          throw false;
      }
  }

  async doAddMemberList() {
      console.log('doAddMemberList this.group =', this.group.uid);

      this.newMemberCnt = 0;
      this.newMemberErr = 0;
      this.newUserCnt = 0;
      this.newUserErr = 0;
      this.newFreeCnt = 0;
      this.newFreeErr = 0;

      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      console.log('add-group-member: handleAddUserList group=', this.group);
      console.log('add-group-member: handleAddUserList emailText=', this.emailText);
      // clean up the text
      // this.emailText = this.validationsvc.extractEmailAddresses(this.emailText);
      console.log('agm: extracted emailText=', this.emailText);

      // parseEmailAddressList constructs the emailAddressList
      const cnt = this.parseEmailAddressList();
      console.log('add-group-member: emailAddressList=', this.emailAddressList);
      console.log('add-group-member: email cnt=', cnt);
      for (const a of this.emailAddressList) {
           console.log('doAddMemberList a=', a);
           try {
             const x = await this.doAddMember(a, false);
             this.emailAddressList = this.emailAddressList.filter(i => i !== a);
           } catch (e) {
               console.log('doAddMemberList error=', e);
           }

      } // for

      console.log('agm addmemberlist newMemberCnt =', this.newMemberCnt);
      console.log('agm addmemberlist newMemberErr =', this.newMemberErr);
      console.log('agm addmemberlist emailAddressList =', this.emailAddressList);

      if ( this.newMemberCnt === cnt ) {
         this.stepMessage = 'Added ' + this.newMemberCnt + ' accounts to ' + addGroupName;
         this.stepMessageEvent.emit(this.stepMessage);
         this.toast.pop('success', 'Add User to Group',
            'Added ' + this.newMemberCnt + ' accounts to ' + addGroupName);
      } else {
            this.stepMessage = 'Correct the errors and click Add Members or Click Add as Guest Accounts to create guest accounts for these users.';
            this.stepMessageEvent.emit(this.stepMessage);
            if ( this.newMemberCnt > 0 ) {
              this.toast.pop('success', 'Add User to Group',
              'Added ' + this.newMemberCnt + ' members to ' + addGroupName);
            }
            this.toast.pop('error', 'Add User to Group',
            'Failed to add ' + this.newMemberErr + ' members to ' + addGroupName);
            }

      if (this.addGuestAccounts === true) {
        if (this.newUserCnt === cnt) {
         this.stepMessage = 'Added ' + this.newUserCnt + ' accounts to ' + addGroupName;
         this.stepMessageEvent.emit(this.stepMessage);
         this.toast.pop('success', 'Add Guest Accounts',
            'Added ' + this.newUserCnt + ' guest accounts');
        } else {
            this.stepMessage = 'Correct the errors and click Add Members to try again';
            this.stepMessageEvent.emit(this.stepMessage);
            if ( this.newUserCnt > 0 ) {
              this.toast.pop('success', 'Add Guest Accounts',
              'Added ' + this.newUserCnt + ' user guest accounts');
            }
            this.toast.pop('error', 'Add Guest Accounts',
            'Failed to add ' + this.newUserErr + ' guest accounts');
        }
      }

      // clean up the email text box leaving any errors
      this.emailText = '';
      if ( this.emailAddressList && this.emailAddressList.length > 0 ) {
         for ( const adr of this.emailAddressList ) {
            this.emailText += adr + ';\n';
         }
      }
      this.emailText += this.junkText;
    }

    async doAddGuest(userEmail, doToast) {
        this.stepMessage = 'Adding guest account...';
        this.stepMessageEvent.emit(this.stepMessage);
        let nbrAccts = 0;
        // CJ - NOTE - NEED A CHECK HERE ON NBR AVAILABLE SUB ACCTS
        try {
           this.userSubList = await this.get_subs(this.userInfo.uid);
           nbrAccts = this.userSubList.length;
           console.log('agm: subList=', this.userSubList);
           console.log('agm: nbrAccts=', nbrAccts);
        } catch (error) {
            this.stepMessage = 'ERROR: Cant get the sub account list.';
            console.log('hau: cant get sublist');
          }
        if ( this.userInfo.accounts > nbrAccts ) {
          try {
            this.stepMessage = 'Adding Guest Account for ' + this.emailAddress;
            this.stepMessageEvent.emit(this.stepMessage);
            // const s = await this.addSub(userEmail);
            const s = await this.usrsubacctsvc.addUserSubAccount(this.userInfo.uid, userEmail);
            console.log('s=', s);
            setTimeout(() => {
            }, 50);
            if (doToast) {
              this.toast.pop('success', 'Add Guest Account', 'Added guest account ' + userEmail);
            }
            this.newUserCnt++;
          } catch (e) {
              this.stepMessage = 'Account exists for ' + this.emailAddress
              + ' or maximum guest accounts exceeded.';
              this.stepMessageEvent.emit(this.stepMessage);
              if (doToast) {
                this.toast.pop('error', 'Add Guest Account', 'Error adding guest account ' + userEmail);
              }
              this.newUserErr++;
          }
        } else {
            this.stepMessage = 'Guest Account limit reached: ' + this.userInfo.accounts + ' accounts exceeded.';
            if (doToast) {
              this.toast.pop('error', 'Add Guest Account',
              'Error guest account limit of ' + this.userInfo.accounts +
              ' accounts exceeded.');
            }
            this.stepMessageEvent.emit(this.stepMessage);
            this.newUserErr++;
        }
    }

    async doAddFree(userEmail, doToast) {
    }

    clearEmailAddress() {
      this.emailAddress = '';
    }
    clearEmailText() {
      this.emailText = '';
    }

    extractEmailAddresses(text) {
      const t = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      console.log(t.join('\n'));
    }

    parseEmailAddressList() {
      this.junkText = '';
      console.log(this.emailInput);
      // this.extractEmailAddresses(this.emailText);
      const tmp: string = this.emailText.toString( ).replace(STRIP_CHARS, '').replace(/[\s\b\f\n\r\t\v<>]+/, ' ');
      console.log('tmp=', tmp);
      const arr = tmp.split(/[,;]+/);
      const dups = [];

      console.log('parseEmailAddressList=', arr);
      for ( const s of arr ) {
         const e = s.trim();
         // console.log('e=', e);
         if (this.validationsvc.isValidEmailAddress(e) === true) {
           dups.push(e);
         } else {
                  this.junkText += e;
                }
      }
      // this.emailText = junkText;

      this.emailAddressList = [];

      dups.forEach((c) => {
          if (!this.emailAddressList.includes(c)) {
             this.emailAddressList.push(c);
          }
      });
      const cnt = this.emailAddressList.length;
      console.log('valid emails=', cnt);
      console.log('valid junk=[', this.junkText + ']');
      this.stepMessage = 'Found ' + cnt + ' valid email addresses';
      // using extractEmailAddresses
      if (this.junkText !== '') {
        this.stepMessage += ' Did NOT process remaining text.';
        this.emailText = this.junkText;
        }
      return cnt;
    }


    getUserInfo(): Promise<any> {
      return this.auth.getInfo().toPromise().then(
            (x: UserInfo) => {
                console.log('x=', x);
                return x;
               },
               (e) => {
                  console.log('ERROR: ', e);
                  throw e;
               });
    }

    async addSub(userEmail) {
        console.log('addSub userEmail=', userEmail);
        console.log('addSub userInfo=', this.userInfo);
        const data = {
            email: userEmail
        };
        let x = null;
        try {
           x = await this.usrsubacctsvc.addUserSubAccount(this.userInfo.uid, userEmail);
           this.newUserCnt++;
           console.log('add-user-group addSub:', userEmail);
        } catch (e) {
             this.newUserErr++;
             console.log('add-user-group addSub ${{userEmail}} ERROR:', e);
             // this.toast.pop('error', 'ERROR', 'Error adding sub..');
             throw e;
        }
        return x;
    }

    async get_subs(uid) {
       let sublist = [];
       try  {
          sublist = await this.usrsubacctsvc.getUserSubAccts(uid);
          // console.log('add-group-member sublist:', sublist);
          return sublist;
       } catch (e) {
           console.log(e);
           throw e;
       }
    }

   toggleAddGuestAccounts() {
         console.log('toggle addguestaccts =', this.addGuestAccounts);
         this.addFreeAccounts = false;
   }

   toggleAddFreeAccounts() {
         console.log('toggle addfreeaccts =', this.addFreeAccounts);
         this.addGuestAccounts = false;
   }

} // add-group-member
