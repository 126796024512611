<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="width: 100%">
  <div class="dialog-header-left">
    <mat-icon style="padding-top: 0.25rem;">drag_indicator</mat-icon>    
    <span class="title" >Help</span>
  </div>
  <div class="dialog-header-right">
    <mat-icon (click)="close()" style="align: right;">close</mat-icon>
  </div>
</div>

<mat-dialog-content #content class="panel-class" style="margin: 0; padding: 0; border-top: 1px solid #1A567A; border-bottom: 1px solid #1A567A;">

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%; font-size: 0.8rem">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="margin-left: 1em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="margin-left: 1em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>
  
</mat-dialog-actions>    
