   <div class="left-content" *ngIf="template===templateCreateLink">
     <mat-nav-list>
       <mat-list-item (click)="sidenavLinkExternal()">
	 External Web Link
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)="sidenavLinkFile()">	 
         Link to a Document (page or marker)
       </mat-list-item>
       <mat-divider></mat-divider>       
     </mat-nav-list>	  
   </div>

   <div class="left-content" *ngIf="template===templateLinkExternal">
     <div class="link-toolbar">
      <!-- *ngIf="!isDialog" -->
      <div class="link-buttons" >
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		  class="group-btn"
                  (click)="sidenavCreateLink()"
                  matTooltip="Done">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button 
		    mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		    class="group-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createExternalLink()"
                    matTooltip="Done">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
      </div>
     </div>
     <mat-nav-list>
       <span class="input-link-title">Link URL</span><br>
       <mat-list-item>
	 <input id="link-url" class="input-link" type="textbox" [(ngModel)]="linkURL" required (paste)="pasteMarkerUrl($event)" size=38>
       </mat-list-item>
       <span class="input-link-title">Link Name</span><br>
       <mat-list-item>       
	 <input id="link-name" class="input-link" type="textbox" [(ngModel)]="linkTitle" required (paste)="pasteMarkerTitle($event)" size=38>
       </mat-list-item>	 
     </mat-nav-list>

   </div>

   
   <div class="left-content" *ngIf="template===templateLinkFile">
       <div class="link-toolbar">
          <div class="link-file-path">
            <span style="color: white">{{selectedGroupObj.name}}</span>
            <span style="color: white" *ngIf="linkDocumentData">
                  {{linkDocumentData.name}}</span>
	  </div>
          <div class="link-buttons">
            <button mat-button 
		  class="link-btn"
                  (click)="sidenavCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"		    
                    (click)="sidenavLinkPage()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">file_open</mat-icon>
                    <div class="label-text">Add Page#</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="sidenavLinkMarker()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">location_on</mat-icon>
                    <div class="label-text">Add Marker</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
	  </div>
       </div>
      <mat-nav-list>
       <mat-divider></mat-divider>       
	<ng-container *ngFor="let d of documentList">
          <mat-list-item *ngIf="d.groups_uid===selectedGroupObj.uid"
			 [ngClass]="{'active': d.uid===documentsvc.activeDocument}"	
                         class="file-name">
            <mat-divider></mat-divider>
            <span style="width: 100%; margin-top: 0.3em"
		  (click)="saveLinkDocument(d);">{{d.name}}
            </span>
          </mat-list-item>
      </ng-container>
	  </mat-nav-list>	  
   </div>

   <div class="left-content" *ngIf="template===templateLinkMarker">
     <div class="link-toolbar">
          <div class="link-file-path">
            <span style="color: white">{{selectedGroupObj.name}}</span>
            <span style="color: white" *ngIf="linkDocumentData">
                  {{linkDocumentData.name}}</span>
	  </div>
          <div class="link-buttons">
            <button mat-button 
		  class="icon-btn"
                  (click)="sidenavLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null || linkMarkerData === null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
	  </div>
	 </div>	  
          <mat-divider></mat-divider>	  
	    <app-marker-list #markerList 
	      [template]=4
	      [userInfo]=userInfo
	      [documentUID]=linkDocumentData.uid
	      (selectMarkerEvent)=saveLinkMarker($event)
	      > 
	    </app-marker-list>
   </div> <!-- NAV_LINK_MARKER -->

   <div class="left-content" *ngIf="template===templateLinkPage">
     <div class="link-toolbar">
          <div class="link-file-path">
            <span style="color: white">{{selectedGroupObj.name}}</span>
            <span style="color: white" *ngIf="linkDocumentData">
                  {{linkDocumentData.name}}</span>
	  </div>
          <div class="link-buttons">	  
            <button mat-button 
		  class="icon-btn"
                  (click)="sidenavLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null"
                    (click)="saveLinkPage(linkPageNbr);sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
	  </div>
        </div>
          <mat-divider></mat-divider>
          <form #f style="display: flex; justify-content: center; align-items: center;">
    Page: <input required min="1" max="9999" type="number" [(ngModel)]="linkPageNbr"  name="page"><br><br>
	  </form>
	  
   </div> <!-- NAV_LINK_PAGE -->

   <div class="left-content" *ngIf="template===templateLinkInternal">
     <div class="link-toolbar">
      <div class="link-buttons">       
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		  class="link-btn"
                  (click)="sidenavCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button *ngIf="linkMarkerData!==null; else savePageLink"
	            mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createInternalMarkerLink()">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    <ng-template #savePageLink>
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="createInternalPageLink()">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    </ng-template>
	</div>
     </div>
     <mat-nav-list>
       <span class="input-link-title">Link URL</span><br>
       <mat-list-item>
	 <input id="link-url" class="input-link" type="textbox" [(ngModel)]="linkURL" required (paste)="pasteMarkerUrl($event)" size=38>
       </mat-list-item>
       <span class="input-link-title">Link Name</span><br>       
       <mat-list-item>       
	 <input id="link-name" class="input-link" type="textbox" [(ngModel)]="linkTitle" required (paste)="pasteMarkerTitle($event)" size=38>
       </mat-list-item>
       <mat-list-item>
	 <mat-checkbox *ngIf="linkType===LINK_TYPE_INTERNAL"
		       [(ngModel)]="newTab">Request open in new tab</mat-checkbox>
       </mat-list-item>
       <mat-list-item>
	 <mat-checkbox *ngIf="linkType===LINK_TYPE_INTERNAL && linkSrcData && linkSrcData.uid !==''; else noUID"
		       [(ngModel)]="crossLink">Add Crosslink Comment to Linked Marker</mat-checkbox>
	 <ng-template #noUID>
	   <span *ngIf="linkType===LINK_TYPE_INTERNAL">Note: to request a cross link comment save and then edit the marker first</span>
	 </ng-template>
       </mat-list-item>
<!--       <mat-list-item>
	 {{linkSrcData.title}}
       </mat-list-item>              	
 -->
     </mat-nav-list>
   </div> <!-- NAV_LINK_INTERNAL -->
   
