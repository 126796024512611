import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Component, OnInit, OnChanges, Input, Output, ViewChild,
         AfterViewInit, Inject, ViewContainerRef, EventEmitter,
         SimpleChanges, ElementRef} from '@angular/core';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import { ToasterService } from '../service/toaster.service';

import { environment } from '../../environments/environment';
import { ActionPromptDialogComponent } from '../dialog/action-prompt/action-prompt.dialog';
// import { SubaccountEditComponent } from '../../subaccount-edit/subaccount-edit.component';
import * as GMC from '../group-member-list/group-member-list.component';

import { GroupData } from '../model/groupdata.model';
import { FileSelector} from '../model/fileselector.model';
import { UserInfo } from '../model/userinfo.model';

import { AuthService } from '../service/auth.service';
import { GroupService } from '../service/group.service';

import * as HELP from '../service/help.service';

export interface GroupManagerInput {
   groupList?: GroupData[];
   organization?: any;
   groupUID?: string;
}

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export interface MemberData {
  uid: string;
  groups_uid: string;
  name: string;
  last_name: string;
  email: string;
  company: string;
  phone: string;
  privs: string;
}

@Component({
   selector: 'app-group-manager',
   templateUrl: './group-manager.component.html',
   styleUrls: ['./group-manager.component.css']
})
export class GroupManagerComponent implements OnInit, OnChanges {
   @Input()  groupList: GroupData[];
   @Input()  userInfo: UserInfo;
   @Input()  group: GroupData;
   @Input()  selectedGroup: string;
   @Input()  selectedOwner: string;
   @Input()  selectedOrganization: string;
   @Input()  selectedOrgUnit: string;
   @Input()  groupTabIndex: number;
   @Input()  groupMembers: MemberData[];
   // data.groupMembers[data.selectedGroup];

   @Output() groupTabEvent = new EventEmitter<any>();
   @Output() updateDocListEvent = new EventEmitter<any>();
   @Output() getGroupsEvent = new EventEmitter<any>();
   @Output() getMembersEvent = new EventEmitter<any>();
   @Output() createGroupEvent = new EventEmitter<any>();
   @Output() renameGroupEvent = new EventEmitter<any>();
   @Output() deleteGroupEvent = new EventEmitter<any>();
   @Output() removeMemberEvent = new EventEmitter<any>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() helpEvent = new EventEmitter<any>();      
   @Output() closeEvent = new EventEmitter<any>();   

   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('content', {static: true}) content: ElementRef;
   @ViewChild('memberView', {static: true}) memberView;
   @ViewChild('addMemberView', {static: true}) addMemberView;
   @ViewChild('addMultipleView', {static: true}) addMultipleView;
   @ViewChild('sideNavView', {static: true}) sideNavView;
   @ViewChild('sideNavView2', {static: true}) sideNavView2;

    // 279 CJ - UserGroupList subscription
    userGroupListSubscription: Subscription;

   // Table Data
   displayedColumns = ['email', 'name', 'last_name', 'company', 'role', 'access', 'action'];
   // displayedGroupColumns = ['name', 'role', 'members', 'rename', 'delete'];
   displayedGroupColumns = ['name', 'role', 'rename', 'delete'];
   dataSource: MatTableDataSource<GroupData>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;

   // View vars
   memberTemplate: number = GMC.ADD_MEMBER;
   sidenavTemplate: number = GMC.SIDENAV_MEMBERS;
   isLinear: false;
   expertMode: boolean;
   // firstFormGroup: FormGroup;
   // secondFormGroup: FormGroup;
   // thirdFormGroup: FormGroup;
   emailText: any;
   emailAddress: string;
   stepMessage: string;

   showAllGroups = true;

   groupTabMax = 3;
   SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

   // Instance vars
   emailAddressList: string[];

   subList: any;
   subLimit: number;

   groupName: string;
   newUserCnt: number;
   newMemberCnt: number;
   newUserErr: number;
   newMemberErr: number;

   selectedName;
   selectedGroupID: any;
   selectedGroupObj: any;

   saveGroup: any;

   constructor(
      private groupsvc: GroupService,
      private auth: AuthService,
      // private formBuilder: FormBuilder,
      private toast: ToasterService,
      public  actionPromptDialog: MatDialog,
      public  actionPromptDialogRef: MatDialogRef<ActionPromptDialogComponent>,
      ) {
          this.expertMode = true;
          if (this.groupList) {
             console.log('group-manager this.data.groupList.length=',
                         this.groupList.length);
          }
          if (this.userInfo) {
             this.subLimit = this.userInfo.accounts;
             console.log('groupmgr uinfo=',
                         this.userInfo);
          }

          this.dataSource  = new MatTableDataSource(this.groupList);
          this.stepMessage = '';


        } // constructor

  ngOnInit() {

     console.log('group-mgr-component groupList=', this.groupList);
     console.log('group-mgr-component userInfo=', this.userInfo);
     console.log('group-mgr-component uid=', this.userInfo.uid);
     console.log('group-mgr-component selectedGroup=', this.selectedGroup);
     console.log('group-mgr-component tabIdx=', this.groupTabIndex);

     if ( !this.selectedGroup && this.groupList.length > 0 ) {
       this.selectedGroup = this.groupList[0].uid;
     }

     // 279 CJ - Group Service Subscribe to group list changes
     this.userGroupListSubscription = this.groupsvc.getUserGroupListUpdates().subscribe(
         async glist => {
            console.log('group-manager: groupListSubscription, refreshing..');
            // 279 CJ - Dialog should subscribe for group list updates.
            // change required.
            this.getUserGroups();
            // console.log('groupmgr subscription: groupList=', this.groupList);
        });

     this.getUserGroups();
     this.groupChange(this.selectedGroup);
  }

  ngOnChanges( changes: SimpleChanges ) {
     // console.log('group-manager changes=', changes);
     if ( changes.selectedGroup ) {
        const newGrpId = changes.selectedGroup.currentValue;
        this.groupChange( newGrpId );
     }
     if ( changes.groupList ) {
        this.groupList = changes.groupList.currentValue;
        this.dataSource.data = this.groupList;
     }
  }

  // Table manipulation functions
  //
  applyFilter(filterValue: string) {
     filterValue = filterValue.trim(); // Remove whitespace
     // MatTableDataSource defaults to lowercase matches
     filterValue = filterValue.toLowerCase();
     this.dataSource.filter = filterValue;
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // Checkbox Handler
  async handleShowGroups() {
     this.showAllGroups = !this.showAllGroups;
     console.log('showAllGroups=', this.showAllGroups);
     // console.log('dataSource=', this.dataSource);
     console.log('userInfo.uid=', this.userInfo.uid);
     const groupListRoleOwner = await this.groupList.filter(g => g.owner === this.userInfo.uid);
     const groupListWithAdmins = await this.groupList.filter(g => Boolean(g.admins));
     const groupListRoleAdmin = await groupListWithAdmins.filter( g => g.admins.includes(this.userInfo.uid) );

     if ( this.showAllGroups === false ) {
         // this.applyFilter(this.userInfo.uid);
         console.log('showAllGroups is false, filter activated.');
         this.dataSource.data = groupListRoleOwner;
         /***
         this.dataSource.filterPredicate = (data: any, filter: string) => {
           console.log('data=', data);
           console.log('filter=', filter);
           if(data.owner!==this.userInfo.uid) {
              return false;
              } else {
                  return true;
                }
           this.dataSource.data = this.groupList;
           this.dataSource.filter= 'owner';
         };
         ***/
      } else {
            // this.applyFilter('');
            this.dataSource.data = this.groupList;
            /***
            this.dataSource.filterPredicate = (data: any, filter: string) => {
               return true;
               };
            this.dataSource.filter='';
            ***/
            }
  }

  // Tab Change Handlers

   get_index(){
        return this.groupTabIndex;
   }

   swiperight() {
        this.groupTabIndex++;
   }

   swipe(selectedIndex: number, action = this.SWIPE_ACTION.RIGHT) {
        // Out of range
        if (this.groupTabIndex < 0 || this.groupTabIndex > this.groupTabMax ) { return; }

        // Swipe left, next tab
        if (action === this.SWIPE_ACTION.LEFT) {
            //                                === #-1 OF TABS
            const isLast = this.groupTabIndex === this.groupTabMax;
            this.groupTabIndex = isLast ? 0 : this.groupTabIndex + 1;
            console.log('Swipe right - INDEX: ' + this.groupTabIndex);
        }

        // Swipe right, previous tab
        if (action === this.SWIPE_ACTION.RIGHT) {
        const isFirst = this.groupTabIndex === 0;
        this.groupTabIndex = isFirst ? this.groupTabMax : this.groupTabIndex - 1;
        console.log('Swipe left - INDEX: ' + this.groupTabIndex);
        }
   }

  onTabChanged(e) {
    console.log('tab changed e=', e);
    console.log('this.selectedGroup: ', this.selectedGroup);
    this.groupTabEvent.emit(e.index);
    if ( e.index === 0 ) {
      this.getUserGroups();
      }
    if ( e.index === 1 ) {
      this.memberTemplate = GMC.EDIT_MEMBERS;
      // this.getUserGroups();
      this.groupChange(this.selectedGroup);
    }
    if (e.index === 2) {
      this.memberTemplate = GMC.ADD_MEMBER;
      this.groupChange(this.selectedGroup);      
    }
    if (e.index === 3) {
      this.memberTemplate = GMC.ADD_MULTIPLE;
      this.groupChange(this.selectedGroup);      
    }
  }

  scrollTop() {
    if ( this.content.nativeElement ) {
          this.content.nativeElement.scrollTop = 0;
       }
  }

  scrollBottom() {
    if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
    }
  }

  async getUserGroups() {
     // console.log('group-mgr refreshGroups()..');
     console.log('group-mgr refreshGroups() selectedGroup=', this.selectedGroup);
     this.groupList = await this.groupsvc.getUserGroups(this.userInfo.uid);
     // console.log('grpmgr refresh groupList=', this.groupList);
     this.dataSource.data = this.groupList;
     // console.log('grpmgr refresh dataSource=', this.dataSource);
     this.groupChange(this.selectedGroup);
  }

  showMembers(guid) {
     console.log('grpmgr showMembers guid=', guid);
     this.groupChange(guid);
     this.groupTabIndex = 1;
     this.content.nativeElement.scrollTop = 0;
  }

  // Called from grp-member-component (removeMemberEvent)= event
  removeMember(data) {
      this.removeMemberEvent.emit(data);
  }

  // Called from grp-member-component (displayUserInfoEvent)= event
  displayUserInfo(data) {
      this.displayUserInfoEvent.emit(data);
  }
  showEditGroups() {
     this.groupTabIndex = 0;
  }
  showEditMembers() {
     this.groupTabIndex = 1;
     this.memberTemplate = GMC.EDIT_MEMBERS;
  }
  showAddMember() {
     this.groupTabIndex = 2;
  }
  showAddMultiple() {
     this.groupTabIndex = 3;
     // this.memberTemplate=GMC.ADD_MULTIPLE;
  }

    // Data/change handlers
  async groupChange(g) {
      // console.log('userInfo = ', this.userInfo);
      console.log('mgr groupChange g = ', g);
      console.log('mgr groupChange this.selectedGroup = ', this.selectedGroup);
      console.log('mgr groupChange this.groupList = ', this.groupList);

      // ERROR: This is not found on createGroup/refreshGroup
      //
      this.selectedGroupObj = this.groupList.find(p => p.uid === g);
      // set the table data source
      // console.log('groupChange selectedGroupObj=', this.selectedGroupObj);

      this.dataSource.data = this.groupList;

      // console.log('groupChange dataSource=', this.dataSource);
      if (this.selectedGroupObj) {
         this.selectedGroup = this.selectedGroupObj.uid;
         this.selectedOwner = this.selectedGroupObj.owner;
         this.selectedName = this.selectedGroupObj.name;
         // console.log('groupobj = ', this.selectedGroupObj);
         // console.log('groupowner = ', this.selectedGroupObj.owner);
         // console.log('groupname = ', this.selectedGroupObj.name);
         this.getMembersEvent.emit(g);
         } else {
             this.selectedGroup = null;
             this.selectedOwner = null;
             this.selectedName = null;
             }
      // Update subcomponent with change
      this.memberView.groupUID = g;
      this.memberView.groupList = this.groupList;
      this.memberView.userID = this.userInfo.uid;

      this.addMemberView.groupUID = g;
      this.addMemberView.groupList = this.groupList;
      this.addMemberView.userUID  = this.userInfo.uid;
      this.addMemberView.userID = this.userInfo.uid;

      this.addMultipleView.groupUID = g;
      this.addMultipleView.groupList = this.groupList;
      this.addMultipleView.userUID = this.userInfo.uid;
      this.addMultipleView.userID = this.userInfo.uid;

      // this.memberView.groupChange(this.userInfo.uid, g);
      // this.addMemberView.groupChange(this.userInfo.uid, g);
      // this.addMultipleView.groupChange(this.userInfo.uid, g);
  }

  async createGroupDialog() {
      this.createGroupEvent.emit(true);
  }

  async renameGroupDialog(guid) {
      this.renameGroupEvent.emit(guid);
  }

  async deleteGroupDialog(guid, gname) {
      const result = {
         groupUID: guid,
         groupName: gname
      };
      this.deleteGroupEvent.emit(result);
  }

  helpDialog() {
      this.helpEvent.emit(HELP.GROUP_MGR);
  }

  // CJ - Send message to main to updateDocumentList()
  sendUpdateDocList(d) {
      // Send updateDocumentList  event to any subscribers
      this.updateDocListEvent.emit(d);
  }


  close() {
      this.closeEvent.emit('');
  }

} // group-manager
