// 56-248 CJ - convert, merge and upload files dialog
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Output, ViewChild, AfterViewInit, Inject,
         EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UpperCasePipe } from '@angular/common';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { GroupData } from '../../model/groupdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { DocumentData } from '../../model/documentdata.model';
import { ImageSettings } from '../../model/imagesettings.model';
import { FileSelector } from '../../model/fileselector.model';

import * as FSC from '../../model/fileselector.constants';
import * as HELP from '../../service/help.service';

import {ImageSpecDialogComponent} from '../image-spec/image-spec.dialog';


export const DIALOG_DISABLE_CLOSE = true;
export const DIALOG_AUTO_FOCUS    = true;
export const DIALOG_CLASS         = 'panel-class';
export const DIALOG_MIN_WIDTH     = '50vw';
export const DIALOG_MAX_WIDTH     = '95vw';
export const DIALOG_MAX_HEIGHT    = '99vh';

export interface UploadFilesData {
    groupList: any;
    organization: any;
    owner: any;
    group: any;
    imagesize: number;
    imagerotation: number;
    addmargins: boolean;
    userInfo: any;
}

export const ERR_OK         =  0;
export const ERR_FILE_TYPE  = -1;
export const ERR_EMBED_FILE = -2;

@Component({
  selector: 'app-upload-files-dialog',
  templateUrl: './upload-files.dialog.html',
  styleUrls: ['./upload-files.dialog.css']
})

export class UploadFilesDialogComponent {
    @Output() updateDocListEvent = new EventEmitter<any>();
    @Output() createGroupEvent = new EventEmitter<any>();
    @Output() selectGroupEvent = new EventEmitter<any>();
    @Output() helpEvent = new EventEmitter<any>();
    @Output() saveEvent = new EventEmitter<any>();    

//    @ViewChild(AddDocumentComponent, {static: true}) add;

    documents: DocumentData[];

    // passed in from parent
    userInfo: any;
    groups: any;

// CJ - pdf-lib variables pdfFile, pdfDoc - pdf-lib converter/editor
    pdfFile: any;
    pdfDoc: any;
    pdfDims: any;
    pdfPage: any;
    pdfImage: any;
    pdfImageBytes: any;
    pdfCopiedPages: any;
    uploadProgress = {
        status: 'progress',
        message: 0
    };

// CJ - showUpload used for multi-select & merge
    showUpload      = 'none';
    showMultiFile   = 'none';
    showImageForm   = 'none';
    showMergeBtn    = false;
    showImageBtn    = false;
// CJ - Move the input type=file in data info FileInfo format and
// persist in fileData array now replacing fileNames so it can be used in
// conversions and the new file uploader
    sendUploadCnt = 0;
    sendErrCode = 0;
    sendErrMsg = '';
    sendDocUID: any    = null;
    inputFiles: any;
    fileData: FileSelector[];  // selected file names
    mergeFileName   = 'markadoc-merge.pdf';
    uploadBtnTitle  = 'UPLOAD FILE';
    uploadFileNames = '';
    uploadMessage   = '';

// CJ - Add owner selection to file upload
    selectedImageSize;
    selectedImageRotation;
    selectedImageSizeText;
    selectedImageRotationText;
    selectedAddMargins = false;
    selectedAddMarginsText = 'Off';
    selectedOrganization: any;
    selectedOrgUnit: any;
    selectedOwner: any;
    selectedGroup: any;

  constructor(
      private http: HttpClient,
      private imgDialog: MatDialog,
      private imgDialogRef: MatDialogRef<ImageSpecDialogComponent>,
      private dialogRef: MatDialogRef<UploadFilesDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data
      ) {
          this.groups            = data.groupList;
          this.userInfo          = data.userInfo;
          this.selectedOrganization = data.org;
          this.selectedOwner     = data.owner;
          this.selectedGroup     = data.groupObj;
          this.selectedImageSize = data.imagesize;
          this.selectedImageRotation = data.imagerotation;
          this.selectedAddMargins = data.addmargins;
          this.selectedImageSizeText = FileSelector.getDefaultImageSizeText();
          this.selectedImageRotationText = FileSelector.getDefaultImageRotationText();
          // console.log('upload dialog groups=', data.groupList);
          // console.log('upload dialog imgsize=', data.imagesize);
          this.uploadMessage = ' ';
          if (this.selectedAddMargins) {
            this.selectedAddMarginsText = 'On';
          } else {
              this.selectedAddMarginsText = 'Off';
            }
  }

  resetDialog() {
     this.uploadMessage = '';
     this.selectedImageSize = FileSelector.getDefaultImageSize();
     this.selectedImageRotation = FileSelector.getDefaultImageRotation();
     this.selectedImageSizeText = FileSelector.getDefaultImageSizeText();
     this.selectedImageRotationText = FileSelector.getDefaultImageRotationText;
     this.selectedAddMargins = FileSelector.getDefaultImageAddMargins();
  }

  // CJ - Send message to main to updateDocumentList()
  sendUpdateDocList(d) {
      // Send updateDocumentList  event to any subscribers
      this.updateDocListEvent.emit(d);
  }

   saveUploadDialog(event) {
      // console.log('upload dialog event=', event);
      const output = {
                     errCode:  event.errCode,
                     errMsg:   event.errMsg,
                     docUID:   event.docUID,
                     docObj:   event.docObj,
                     uploadCnt: event.uploadCnt
                   };

      this.resetDialog();
      // console.log('upload-dialog: output=', output);
      this.saveEvent.emit(output);
      // Uncomment the following to auto-close the dialog after the last file
      // has uploaded...
      setTimeout( () => {
          this.dialogRef.close(output);      
      }, 200);
   }

   closeUploadDialog() {
     this.resetDialog();
     this.dialogRef.close();
   }


   groupChange(g) {
       console.log('group selected = ', g);
       this.selectGroupEvent.emit(g);
       this.selectedGroup = g;
   }

   async helpDialog() {
       this.helpEvent.emit(HELP.UPLOAD_CONTENT);
   }

    async createGroupDialog() {
        this.createGroupEvent.emit(true);
    }

}
