import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { ActivatedRoute } from '@angular/router';

import { MouseTrack } from './model/mouse-track.model';
import { AuthService } from './service/auth.service';
import { ToasterService } from './service/toaster.service';
import { Network } from '@capacitor/network';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

    title = 'Markadoc';

    networkHandler: any = null;
    urlParams: any = null;
    urlDocumentID: string = null;
    urlPageNbr = 1;
    urlMarkerType: string = null;
    urlMarkerNbr = 0;

    constructor(
                 public toast: ToasterService,
                 public route: ActivatedRoute
               ) {  }

    async ngAfterViewInit() {
        this.networkHandler =
           Network.addListener('networkStatusChange', (status) => {
              console.log("Network status changed", status);
              this.checkNetworkStatus(status);
           });

       // To stop listening:
       // handler.remove();

       // Get the current network status
       const status = await Network.getStatus();
       console.log("Network status changed", status);
       this.checkNetworkStatus(status);

       // Example output:
       // {
       //   "connected": true,
       //     "connectionType": "wifi"
       // }

    }

    // Store parameter values on URL changes
    getRouteParams(): void {
        // Route parameters
        // this.activatedRoute.params.subscribe( params => {
        //    this.routeParams = params;
        //    console.log('route params=', params);
        // });
        this.urlDocumentID = null;
        this.urlPageNbr = 1 ;
        this.urlMarkerType = null;
        this.urlMarkerNbr = 0;

        // URL query parameters
        console.log('activatedRoute=', this.route);             
        this.route.queryParams.subscribe( params => {
           this.urlParams = params;
           console.log('app url params=', params);
           console.log('app url docid=', params.docid);
           console.log('app url page=', params.page);
           console.log('app url mtype=', params.mtype);
           console.log('app url mnbr=', params.mnbr);
           this.urlDocumentID = params.docid;
           this.urlPageNbr = params.page;
           this.urlMarkerType = params.mtype;
           this.urlMarkerNbr = params.mnbr;
           console.log('app docid=', this.urlDocumentID);
        });
    }

    checkNetworkStatus(status: any) {
       if (status.connected === false) {
          this.toast.notify('error', 'Network Error!', 'Please enable networking or data services and connect to a network!');
       }
       // else {
       //     this.toast.notify('info', 'Network Status', 'Connected to ' + status.connectionType);
       // }
    }

}
