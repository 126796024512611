import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output,
         ViewChild, EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef }
         from '@angular/core';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm,
         Validators } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';

// import { lastValueFrom } from 'rxjs';
import { Subject, Observable, Subscription } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';

// import { SelectLists } from '../../mdtools/select-lists.common';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AuthService } from '../../service/auth.service';
import { ToasterService } from '../../service/toaster.service';
import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
import { OrgContactService } from '../../service/orgcontact.service';

import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { OrgData } from '../model/orgdata.model';
import { OrgUserInfo } from '../model/orguserinfo.model';
import { OrgUserData } from '../model/orguserdata.model';
import { OrgContactData } from '../model/orgcontactdata.model';

// import * as GRP from './group.component';
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;


/** Error when invalid control is dirty, touched, or submitted. */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
   selector: 'app-org-contact',
   templateUrl: './org-contact.component.html',
   styleUrls: ['./org-contact.component.css'],
})
export class OrgContactComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   private oid: string;

   @Input()  iam = false;
   @Input()  isObjAdmin = false;   
   @Input()  mode: string = this.DisplayMode.SELECT;
   @Input()  userInfo: UserInfo | null;
   @Input()  userData: UserData | null;
   @Input()  template: number;
   @Input()  groupData: GroupData | null;
   @Input()  selectedObj: any | null;
   @Input()  objData: any | null;
   @Input()  selectedOrg: OrgData | null;
   @Input()  isSelected = false;
   @Input()  doSubmit = false;
   @Input()  doReset = false;
   @Input()  uid: string | null = null;
   @Input()  nameLabel: string | null  = 'Group Name';
   @Input()  useMenuActionDialog = true;
   @Input()  showActions = true;
   @Input()  showFormTitle = false;
   @Input()  showInfoButton = false;
   @Input()  showMenuButton = false;
   @Input()  showDeleteButton = false;
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   @Input()
   public get org_uid() {
       return this.oid;
   }
   public set org_uid(oid: string) {
       this.oid = oid;
       // this.get_org(this.oid);
       // this.changeDetector.markForCheck();
   }

   @Output() toggleObjDetail = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() deleteErrorEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() prevEvent = new EventEmitter<any>();
   @Output() nextEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   @Output() isObjAdminEvent = new EventEmitter<any>();
   
   // Dialog vars
   // public actionPromptDialog: MatDialog;
   // public objectDialog: MatDialog;
   // public helpDialog: MatDialog;

   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;
   helpDialogRef: any = null;

   // View vars
   objLabel = 'Company/Org. Contact';
   showDetail = false;
   viewOnly = false;
   isExpanded = false;
   showHidden = false;
   // isSelected = false;

   // Org Admin
   orgData: OrgData = null;

   // Form vars
   // domainPattern = "(?(?<= )(?=[^ ])|^)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]"; // eslint-disable-line
   domainPattern = "^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$" // eslint-disable-line
   formFieldStyle = 'outline';  // fill or outline
   objForm: FormGroup;
   // orgStatusForm: FormGroup;
   isAddMode: boolean;
   isFormReady: boolean;
   loading = false;
   submitted = false;


//   public orgTypes = LISTS.SelectLists.orgTypes;
//   public orgCategories = LISTS.SelectLists.orgCategories;
//   public statusCodes = LISTS.SelectLists.orgStatuses;
   // public stateCodes = LISTS.SelectLists.stateCodes;
//   public countryCodes = LISTS.SelectLists.countryCodes;

   public objData$: Observable<any | null> = null;

   public orgData$: Observable<any | null> = null;
   public ownerData$: Observable<any | null> = null;

   public contactTypes = LISTS.orgContactTypes;
   public orgTypes = LISTS.orgTypes;
   public orgCategories = LISTS.orgCategories;
   public statusCodes = LISTS.orgStatuses;
   // public stateCodes = LISTS.stateCodes;
   public countryCodes = LISTS.countryCodes;

   public errorMatcher = new FormErrorStateMatcher();

   orgNbrStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; margin-right: 1rem",
     selected: "font-weight: bold; color: #2274A5; margin-right: 1rem"
   }

   orgNameStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; width: 100%;",
     selected: "font-weight: normal; color: #2274A5; margin-right: 1rem"
   }

   orgNbrStyle=this.orgNbrStyles.default;
   orgNameStyle=this.orgNameStyles.default;

   // Instance vars
   is_app_mgr = false;

   orgUserData: OrgUserData;
   orgUserSubscription: any;
   doSubmitSubscription: any;

   constructor(
      public toast: ToasterService,
      private el: ElementRef,
      private fb: FormBuilder,
      private auth: AuthService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public orgcontactsvc: OrgContactService,      
      public groupsvc: GroupService,
      public usersvc: UserService,
      // public dialogsvc: DialogService,
      public actionPromptDialog: MatDialog,
      public formDialog: MatDialog,
      public helpDialog: MatDialog,
      ) {
      } // constructor

   async ngOnInit() {
      console.log('org-contact iam=', this.iam);   
      this.userData = this.auth.getTokenInfo();
      this.refreshData();
      // this.orgsvc.orgItem$.next(null);

      this.setModeTemplate(this.mode);
      
      if ( this.uid ) {
         this.objData$ = this.orgsvc.getOrganization$( this.uid );
      }
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           console.log('org doSubmit$ is v=', v);
           if ( v === true ) {
             this.onSubmit();
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             console.log('org doReset$ is true.');
             this.onReset();
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           console.log('org doSubmit$ is v=', v);
           if ( v === true ) {
             console.log('org doEdit$ is true.');
             this.onEdit();
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             console.log('org doDelete$ is true.');
             this.onDelete();
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           if ( v === true ) {
             console.log('org doView$ is true.');
             this.onView();
           }
        });
      }

      console.log('org-contact init isObjAdmin=', this.isObjAdmin);
   }

   ngAfterViewInit() {
      console.log('group avi selectedObj=', this.selectedObj);
      console.log('group avi objData=', this.objData);
   }

   async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      console.log('org-contact changes=', changes);
      if ( changes.doEdit$ ) {
         console.log('org changes doEdit$=', this.doEdit$);
      }
      if ( changes.selectedObj ) {
         this.selectedObj = changes.selectedObj.currentValue;
         // this.getObjFieldRefs(this.selectedObj);
      }
      if ( changes.selectedOrg ) {
         this.selectedOrg = changes.selectedOrg.currentValue;
         if (this.selectedOrg) {
            this.isObjAdmin = await this.getIsOrgAdmin( this.selectedOrg, this.userData);
         } else {
              this.isObjAdmin = false;
           }
         console.log('org-contact selectedOrg=', this.selectedOrg);
         console.log('org-contact isObjAdmin=', this.isObjAdmin);
         // this.refreshList(this.setSelectedOrg);
      }
      if ( changes.objData ) {
         this.setObjData(changes.objData.currentValue);
         console.log('obj isObjAdmin=', this.isObjAdmin);
         this.getObjFieldRefs(changes.objData.currentValue);
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
         // this.setModeTemplate(changes.mode.currentValue);
      }
      if ( changes.doReset ) {
         if ( changes.doReset.currentValue === true ) {
            this.resetForm();
         }
      }
      if ( changes.doSubmit ) {
         console.log('changes doSubmit objData=', this.objData);
      }
      this.refreshData();
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     /*****     
     if ( this.doSubmit$ ) {
        this.doSubmit$.unsubscribe();
     }
     if ( this.doEdit$ ) {
        this.doEdit$.unsubscribe();
     }
     if ( this.doDelete$ ) {
        this.doDelete$.unsubscribe();
     }
     if ( this.doReset$ ) {
        this.doReset$.unsubscribe();
     }
     ***/
   }

   async get_org(uid) {
       this.orgData  = await this.orgsvc.getOrganization(uid);
   }

   getObjFieldRefs(obj) {
      /***
      if ( obj && obj.owner ) {
       this.ownerData$ = this.usersvc.getUserInfo$(obj.owner);
      }
      ****/
      if ( obj && obj.org_uid ) {
       this.orgData$ = this.orgsvc.getOrganization$(obj.org_uid);
      }
   }

   async refreshData() {
      if (this.userData) {
          console.log('org-list userdata=', this.userData);
          this.is_app_mgr = this.userData.iam;
          this.orgUserData = null;
      } else {
             console.log('OrgListComponent WARNING userData not defined.');
        }

      // this.getObjFieldRefs(this.objData);

      // console.log('mode=', this.mode);
      // console.log('template=', this.template);
      // console.log('DisplayMode=', this.DisplayMode);
   }

   setModeTemplate(m) {
      // console.log('setMode called m=', m);
      this.isAddMode = false;
      this.viewOnly = false;

      this.modeEvent.emit(m);

      switch(m) {
         case this.DisplayMode.SELECT: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.COMBO: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.EXPAND_LIST: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             break;
         }
         case this.DisplayMode.SELECT_LIST: {
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayMode.LIST: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.FORM: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayMode.ADD: {
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = true;
             this.isAddMode = true;
             if (this.userData.iam || this.isObjAdmin) {
                 this.viewOnly = false;
             }
             this.isFormReady = false;
             this.createForm();
             this.isFormReady = true;
             console.log('org-contact ADD viewOnly=', this.viewOnly);
             break;
         }
         case this.DisplayMode.EDIT: {
             this.template = this.DisplayTemplate.FORM;
             // Allow if iam or org_admin
             if (this.userData.iam || this.isObjAdmin) {
                 this.viewOnly = false;
             } else {
                 this.viewOnly = true;
             }
             this.isAddMode = false;
             this.isFormReady = false;
             this.createForm();
             this.setForm();
             /***
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             ***/
             if ( this.objData ) {
                this.getObjFieldRefs(this.objData);
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.VIEW: {
             this.template = this.DisplayTemplate.FORM;
             this.isAddMode = false;
             this.isFormReady = false;
             this.viewOnly = true;
             this.createForm();
             this.setForm();
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.DELETE: {
             this.template = this.DisplayTemplate.DELETE;
             this.isAddMode = false;
             this.isFormReady = false;
             break;
         }
         case this.DisplayMode.FIELD_NAME: {
             this.template = this.DisplayTemplate.FIELD_NAME;
             this.isAddMode = false;
             break;
         }
      }
      // console.log('org setModeTemplate mode=', this.mode);
      // console.log('org setModeTemplate template=', this.template);
   }

   public setObjData(obj) {
      this.objData = obj;
      // this.setSelectedObj();
   }

   async setSelectedObj() {
      this.selectedObj = this.objData;

     /*****
     if ( this.objForm ) {
        this.objForm.patchValue( {org_uid: this.selectedObj.uid} );
        this.objForm.patchValue( {uid: this.selectedObj.uid} );
        this.objForm.patchValue( {org_name: this.selectedObj.org_name} );
     }
     *****/
     this.selectObjEvent.emit(this.selectedObj);
   }

   setSelectListObj(evt) {
      console.log('setSelectListObj evt=', evt);
      this.setSelectedObj();
      // this.doneEvent.emit(this.objData);
   }

   async getIsObjAdmin(obj: any, uData: UserData) {
      let obj_admin = false;
      if (obj && 'uid' in obj && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(obj.org_uid, uData.uid);
         if ( orgusr && orgusr.org_admin ) {
             obj_admin = true;
         } else {
             obj_admin = false;
           }
      }
      if ( uData.iam && this.iam ) {
         obj_admin=true;
      }
      this.isObjAdminEvent.emit(obj_admin);      
      return obj_admin;
   }

   async getIsOrgAdmin(obj: any, uData: UserData) {
      const isAdmin = this.isObjAdmin;
      let obj_admin = false;
      if (obj && 'uid' in obj && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(obj.uid, uData.uid);
         // console.log('org-usr.org_admin=', orgusr.org_admin);
         if ( orgusr && orgusr.org_admin ) {
             obj_admin = true;
         } else {
             obj_admin = false;
           }
      }
      if ( uData.iam && this.iam ) {
         obj_admin=true;
      }
      if ( isAdmin !== obj_admin ) {
         this.updateFormControls(obj_admin);
      }
      // console.log('org-contact getIsOrgAdmin=', obj_admin);
      this.isObjAdminEvent.emit(obj_admin);      
      return obj_admin;
   }

   compareObjs( o1: any, o2: any ) {
     if( !o1 || !o2 )
       { return false; }
     else {
        return o1.uid === o2.uid;
     }
   }

   onCancel() {
     this.cancelEvent.emit(this.objForm);
   }

   async onSubmit() {
     // evt.stopPropagation();
     console.log('onSubmit form=', this.objForm);
     console.log('onSubmit form value=', this.objForm.value);
     console.log('onSubmit raw form=', this.objForm.getRawValue());

     this.submitted = true;
     if (this.objForm.valid) {
       // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
       console.log('FORM IS VALID');
       console.table(this.objForm.value);
       console.log('org-contact onSubmit mode=', this.mode);
       console.log('org-contact onSubmit isAddMode=', this.isAddMode);

       if ( this.isAddMode ) {
          this.objData = await this.addRecord( this.objForm );
       } else {
             this.objData = await this.editRecord( this.objForm );
       }
       console.log('org-contact onSubmit this.objData=', this.objData);
       this.setSelectedObj();
       this.submitEvent.emit(this.objData);
     } else {
          console.error('FORM IS NOT VALID');
          this.objForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
       }
   }

   onDelete() {
     this.deleteRecord( this.selectedObj );
   }

   onReset() {
     this.resetForm();
   }

   onEdit() {
     this.setModeTemplate(this.DisplayMode.EDIT);
   }

   onView() {
     this.setModeTemplate(this.DisplayMode.VIEW);
   }

   private scrollToFirstInvalidControl1() {
     // "form .ng-invalid"
     const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
       "mat-form-field-invalid"
     );

     firstInvalidControl.focus(); //without smooth behavior
  }

   private scrollToFirstInvalidControl() {
      const form = document.getElementById('objForm'); // <-- your formID
      const firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
   }

   viewObj() {
      console.log('view obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.VIEW );
      } else {
         this.viewObjDialog();
        }
   }

   createObj() {
      console.log('org-contact create obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.ADD );
      } else {
         this.createObjDialog();
        }
   }

   editObj(dontUseDialog=false) {
      console.log('org-contact edit obj called. dontUseDialog=', dontUseDialog);
      if ( dontUseDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.mode = this.DisplayMode.EDIT;
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
        return;
      }
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
      } else {
         this.editObjDialog();
        }
   }

   deleteObj() {
      console.log('org-contact delete obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.DELETE );
      } else {
         this.deleteObjDialog();
        }
   }

   titleAction() {
      if ( this.mode === this.DisplayMode.SELECT ||
           this.mode === this.DisplayMode.LIST  ||
           this.mode === this.DisplayMode.EXPAND_LIST ) {
         this.setSelectedObj();
      }
   }

   toggleDetail() {
      switch(this.template) {
          case this.DisplayTemplate.EXPAND_LIST: {
             this.template = this.DisplayTemplate.DETAIL;
             this.showDetail = true;
             break;
          }
          case this.DisplayTemplate.DETAIL: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             this.showDetail = false;
             break;
          }
      }
   }

   cancelObj() {
      this.cancelEvent.emit(true);
      console.log('Cancel');
   }

   prevObj() {
      this.prevEvent.emit(true);
      console.log('prevObj');
   }

   nextObj() {
      this.nextEvent.emit(true);
      console.log('nextObj');
   }

   setSelectedOrg(o) {
     this.selectedOrg = o;
     if ( this.objForm ) {
        this.objForm.patchValue( {org_uid: o.uid} );
        this.objForm.patchValue( {uid: o.uid} );
        this.objForm.patchValue( {org_name: o.org_name} );
     }
   }

   createForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('createForm snow=', snow);
     // console.log('createForm snm=', snm);
     // console.log('org-contact createForm mode=', this.mode);
     console.log('org-contact createForm selectedOrg=', this.selectedOrg);     
     console.log('org-contact createForm viewOnly=', this.viewOnly);
     console.log('org-contact createForm isObjAdmin=', this.isObjAdmin);

     //
     //  NOTE: may need to change isObjAdmin to appmgr and org_owner
     //
     this.objForm = this.fb.group({
           uid: [{value: '', disabled: true}],
           org_uid: [{value: this.selectedOrg ? this.selectedOrg.uid : '', disabled: true}],       

           org_contact_type: (!this.viewOnly && this.isObjAdmin) ?
                     ['', [Validators.required]] :
                     [{value: '', disabled: true}, [Validators.required]],

           org_contact_email: (!this.viewOnly && this.isObjAdmin) ?
                            [null, [ Validators.required, Validators.email] ] :
                            [{value: null, disabled: true}, [Validators.required, Validators.email]],

           org_contact_name: (!this.viewOnly && this.isObjAdmin) ?
                           [''] :
                           [{value: '', disabled: true}],

           org_contact_last_name: (!this.viewOnly && this.isObjAdmin) ?
                             [''] :
                             [{value: '', disabled: true}],

           org_contact_phone: (!this.viewOnly && this.isObjAdmin) ?
                            ['', [ Validators.pattern('[- +()0-9]+')]] :
                            [{value: '', disabled: true}],

           org_contact_mobile_phone: (!this.viewOnly && this.isObjAdmin) ?
                              ['', [Validators.pattern('[- +()0-9]+')]] :
                              [{value: '', disabled: true}],

           org_contact_fax: (!this.viewOnly && this.isObjAdmin) ?
                          ['', [ Validators.pattern('[- +()0-9]+')] ] :
                          [{value: '', disabled: true}],

           org_contact_addr: (!this.viewOnly && this.isObjAdmin) ?
                     ['']  :
                     [{value: '', disabled: true}],

           org_contact_addr_2: (!this.viewOnly && this.isObjAdmin) ?
                       [''] :
                       [{value: '', disabled: true}],

           org_contact_city: (!this.viewOnly && this.isObjAdmin) ?
                     [''] :
                     [{value: '', disabled: true}],

           org_contact_state: (!this.viewOnly && this.isObjAdmin) ?
                      [''] :
                      [{value: '', disabled: true}],

           org_contact_postal_code: (!this.viewOnly && this.isObjAdmin) ?
                            [''] :
                            [{value: '', disabled: true}],

           org_contact_country_code: (!this.viewOnly && this.isObjAdmin || this.userData.iam) ?
                             ['US'] :
                             [{value: 'US', disabled: true}],

           org_contact_notes: (!this.viewOnly && this.isObjAdmin || this.userData.iam) ?
                     [null] :
                     [{value: null, disabled: true}],
                     
           created: this.userData.iam && ! this.viewOnly ?
                      [snow]
                    : [{value: '', disabled: true}],
           modified: this.userData.iam && ! this.viewOnly ?
                      [snow]
                    : [{value: '', disabled: true}],
           timestamp: [{value: '', disabled: true}],
     },{updateOn: 'blur'});

     console.log('createForm =', this.objForm);

     // console.log('createForm =', this.objForm);

   }

   resetForm() {
      this.setForm();
      this.objForm.markAsPristine();
      this.objForm.markAsUntouched();
   }

   updateFormControls(isAdmin) {
      console.log('updateFormControls isAdmin=', isAdmin);
      const enableFields = (!this.viewOnly && isAdmin);
      console.log('updateFormControls enableFields=', enableFields);      
      if (this.objForm) {
         enableFields ? this.objForm.controls.org_contact_type.enable() :
                        this.objForm.controls.org_contact_type.disable();
         enableFields ? this.objForm.controls.org_contact_email.enable() :
                        this.objForm.controls.org_contact_email.disable();
         enableFields ? this.objForm.controls.org_contact_name.enable() :
                        this.objForm.controls.org_contact_name.disable();
         enableFields ? this.objForm.controls.org_contact_last_name.enable() :
                        this.objForm.controls.org_contact_last_name.disable();
         enableFields ? this.objForm.controls.org_contact_phone.enable() :
                        this.objForm.controls.org_contact_phone.disable();
         enableFields ? this.objForm.controls.org_contact_mobile_phone.enable() :
                        this.objForm.controls.org_contact_mobile_phone.disable();
         enableFields ? this.objForm.controls.org_contact_fax.enable() :
                        this.objForm.controls.org_contact_fax.disable();
         enableFields ? this.objForm.controls.org_contact_addr.enable() :
                        this.objForm.controls.org_contact_addr.disable();
         enableFields ? this.objForm.controls.org_contact_addr_2.enable() :
                        this.objForm.controls.org_contact_addr_2.disable();
         enableFields ? this.objForm.controls.org_contact_city.enable() :
                        this.objForm.controls.org_contact_city.disable();
         enableFields ? this.objForm.controls.org_contact_state.enable() :
                        this.objForm.controls.org_contact_state.disable();
         enableFields ? this.objForm.controls.org_contact_postal_code.enable() :
                        this.objForm.controls.org_contact_postal_code.disable();
         enableFields ? this.objForm.controls.org_contact_country_code.enable() :
                        this.objForm.controls.org_contact_country_code.disable();
         enableFields ? this.objForm.controls.org_contact_notes.enable() :
                        this.objForm.controls.org_contact_notes.disable();
     }
   }

   setForm() {

     if(!this.objData || !this.objData.uid || this.objData.uid == null) {
        return;
     }

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     console.log('setForm snow=', snow);
     console.log('setForm snm=', snm);
     console.log('setForm viewOnly=', this.viewOnly);
     console.log('setForm isObjAdmin=', this.isObjAdmin);
     console.log('setForm objData:')
     console.table(this.objData);

     this.objForm.patchValue({uid: this.objData.uid });
     this.objForm.patchValue({org_uid: this.objData.org_uid ? this.objData.uid : this.selectedOrg.uid});

     this.objForm.patchValue({org_contact_type: this.objData.org_contact_type ? this.objData.org_contact_type : null});
     this.objForm.patchValue({org_contact_email: this.objData.org_contact_email ? this.objData.org_contact_email : null});     
     this.objForm.patchValue({org_contact_name: this.objData.org_contact_name});
     this.objForm.patchValue({org_contact_last_name: this.objData.org_contact_last_name});
     this.objForm.patchValue({org_contact_phone: this.objData.org_contact_phone});
     this.objForm.patchValue({org_contact_mobile_phone: this.objData.org_contact_mobile_phone});
     this.objForm.patchValue({org_contact_fax: this.objData.org_contact_fax});
     this.objForm.patchValue({org_contact_addr: this.objData.org_contact_addr});
     this.objForm.patchValue({org_contact_addr_2: this.objData.org_contact_addr_2});
     this.objForm.patchValue({org_contact_city: this.objData.org_contact_city});
     this.objForm.patchValue({org_contact_state: this.objData.org_contact_state});
     this.objForm.patchValue({org_contact_postal_code: this.objData.org_contact_postal_code});
     this.objForm.patchValue({org_contact_country_code: this.objData.org_contact_country_code ?
                             this.objData.org_contact_country_code : 'US'});
     this.objForm.patchValue({org_contact_notes: this.objData.org_contact_notes});
     this.objForm.patchValue({created: snow});
     this.objForm.patchValue({modified: snow});     
 
     if ( this.objData ) {
        this.getObjFieldRefs(this.objData);
     }
   } // setForm()

   clearForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date().setMonth(now.getMonth() + 1);

     this.objForm.patchValue(
        {  uid: null,
           org_uid: null,       
           org_contact_type: null,
           org_contact_email: null,          
           org_contact_name: '',
           org_contact_last_name: '',
           org_contact_phone: '',
           org_contact_mobile_phone: '',
           org_contact_fax: '',
           org_contact_addr: '',
           org_contact_addr_2: '',
           org_contact_city: '',
           org_contact_state: '',
           org_contact_postal_code: '',
           org_contact_country_code: 'US',
           created: now,
           modified: now,
         });

     /***
     if ( this.isAddMode && this.userData ) {
        this.objForm.patchValue({contact: this.userData.uid});
     }
     ***/
   }

   /***
   async updateOrgContactUser(email: string) {
     const orgusr = await this.orgusersvc.getOrgUserByOrgAndEmailPromise(org.uid, org.org_contact_email);
     if (orgusr) {
       const uid = orgusr.uid;
       delete orgusr.uid;
       orgusr.org_admin = true;
       orguser.org_billing_admin = true;
       const ou = await this.orgusersvc.updateOrgUserPromise(orgusr.uid, orguser);
       console.log('org - updated org user contact ou=', ou);
     }
     const usr = await this.usersvc.getUserByEmailPromise( email );
     if (! usr ) {
     }
   }
   *****/

   async addRecord( form ) {
      console.log('org-contact addRecord:', form.getRawValue());
      const data = form.getRawValue();
      delete data.uid;
      delete data.timestamp;
      console.log('orgcontact addRecord data:', data);
      const orgcontact = await this.addObjData( data );
      console.log('orgcontact addRecord contat:', orgcontact);
      return orgcontact;
   }

   async editRecord( form ) {
      const uid = form.getRawValue().uid;
      const data = form.getRawValue();
      delete data.uid;
      delete data.created;
      delete data.timestamp;
      console.log('orgcontact editRecord:', data );
      const orgcontact = await this.editObjData( uid, data );
      return orgcontact;
   }

   async deleteRecord(obj) {
      let result = null;
      if ( obj ) {
        console.log('group deleteRecord:', obj);
        result = await this.deleteObjData( obj );
      }
      return result;
   }

   refreshList(obj) {
      console.log('refreshList obj=', obj);
      this.refreshEvent.emit(obj);
   }

   async getOrgUserInfo(oid, uid) {
      let result = null;
      try {
         result = await this.orgusersvc.getOrgUserByOrgAndUidPromise(oid, uid);
      } catch (e)  {
          console.error('org - cant get orguserinfo e=', e);
      }
      return result;
   }

   async addObjData( data ) {
      let result = null;
      try {
          result = await this.orgcontactsvc.addOrgContactPromise( data );
          this.submitEvent.emit(result);
          this.toast.pop('success', 'Add Company/Org. Contact', 'Company/Org. ContactSaved');
          console.log('orgcontact add refreshOrgList result=', result);
      } catch (e)  {
           console.error('org - cant add contact orgdata e=', e);
           this.toast.pop('error', 'Add Company/Org. Contact', 'Add Company/Org. Contact failed! e=', e);
      }
      console.log('add orgcontact data=', result);
      return result;
   }

   async editObjData( oid, data ) {
      let result = null;
      try {
         result = await this.orgcontactsvc.updateOrgContactPromise(oid, data);
         this.setObjData(result);
         this.setSelectedObj();
         this.submitEvent.emit(result);
         this.getObjFieldRefs(result);
         console.log('orgcontact edit result=', result);
         this.toast.pop('success', 'Update Company/Org. Contact', 'Company/Org. Contact Updated');
      } catch (e)  {
           console.error('org - cant edit orgdata e=', e);
           this.toast.pop('error', 'Update Company/Org. Contact', 'Company/Org. Contact Update failed! e=', e);
      }
      console.log('edit org data=', result);
      return result;
   }

   async deleteObjData( obj ) {
      console.log('org-contact deleteObjData obj=', obj);
      let result = null;
      try {
         if ( ! this.userData.iam ) {
           console.error('No Permission to delete company/org.!');
           return;
         }
         result = await this.orgcontactsvc.removeOrgContactPromise(obj.uid);
         console.log( 'delete orgcontact result =', result );    
         this.toast.pop('success', 'Delete Company/Org. Contact', 'Deleted Company/Org. Contact', obj.org_contact_email);
         setTimeout(() => {
           console.log('delete org-user success.');
         }, 2000);
         this.deleteObjEvent.emit(obj);
         if ( this.selectedObj && this.selectedObj.uid &&
            ( this.selectedObj.uid === obj.uid ) )  {
               this.selectObjEvent.emit( null);
         }
      } catch (e)  {
           console.error('company/org - cant delete error e=', e);
           this.toast.pop('error', 'Delete Company/Org. Contact', 'Delete Company/Org. Contact Failed! for ', obj.org_contact_email);
           const erec = { name: obj.org_contact_email,
                          error: e};
           this.deleteErrorEvent.emit(erec);
      }
      return result;
   }


   viewObjDialog() {
      this.openFormDialog( this.DisplayModel.ORG_CONTACT, this.DisplayMode.VIEW,
                           this.objData);
   }

   createObjDialog() {
      this.openFormDialog( this.DisplayModel.ORG_CONTACT, this.DisplayMode.ADD,
                           this.objData);
   }

   editObjDialog() {
      // this.openFormDialog( 'ORG', 'E', this.objData);
      this.openFormDialog( this.DisplayModel.ORG_CONTACT, this.DisplayMode.EDIT,
                           this.objData);
   }

   deleteObjDialog() {
      this.openDeleteObjDialog( this.objData.uid, this.objData.org_contact_email );
   }

   openDeleteObjDialog(uid, name) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Are you sure you want to permanently delete contact ' +
                  name + '?';
      const msg2 = 'Note: this will NOT delete any user account for ' + name;

      const obj_data = {
         uid: uid,
         name: name
      }

      dialogConfig.data = {
         intent: 'deleteOrgContact',
         title: 'Delete ' + this.objLabel,
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Delete',
         dialogData: obj_data
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open( ActionPromptDialogComponent, dialogConfig);
      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         async data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('deleteObj action-prompt intent=',
                            data.intent);
               console.log('deleteObj action-prompt return=',
                            data.choice);
               console.log('deleteObj action-prompt return=',
                            data.dialogData);
            }
            // if data returned tax default action
            const ds = await this.deleteObjData( this.objData );
            setTimeout(() => {
               console.log('org-contact delete status=', ds);
            }, 700);
            // this.doneEvent.emit(this.objData);
          } // data
      );
    } // openDeleteFileDialog

   openFormDialog(model, mode, obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Company/Org. Contact';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View ' + this.objLabel;
              break;
        case this.DisplayMode.ADD:
              title = 'Create ' + this.objLabel;
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit ' + this.objLabel;
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete ' + this.objLabel;
              break;
      }

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_CONTACT,
         objMode: mode,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         selectedOrg: this.selectedOrg,
         /****
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
              this.refreshEvent.emit();
            }, 1100);
          } // data
      );
    } // openFormDialog

} // org-contact component
