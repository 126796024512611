import { Injectable } from '@angular/core';
import { ToastrService, ToastrConfig } from 'ngx-toastr';
import { environment } from '../../environments/environment';

class ToasterStub {
 showSuccess(message: string, title: string): void {}
 showError(message: string, title: string): void {}
 showInfo(message: string, title: string): void {}
 showWarning(message: string, title: string): void {}
}

@Injectable()
export class ToasterService {

   popTypes: string[] = [ 'error', 'info', 'success', 'warning' ];
   popPositions: string[] = [ 'toast-center-center',
                              'toast-top-center',
                              'toast-bottom-center',
                              'toast-top-full-width',
                              'toast-bottom-full-width',
                              'toast-top-left',
                              'toast-top-right',
                              'toast-bottom-left',                            
                              'toast-bottom-right' ];

   defaultTimeout = 3000;
    
   constructor(private toastr: ToastrService)  { }

   clear(toastId?: number) {
      if (toastId !== undefined) {
         this.toastr.clear(toastId);  
      } else {
           this.toastr.clear();
        }
   }

   pop( ptype = 'info',
        ptitle = '',
        pmessage = '',
        ptimeOut: number = this.defaultTimeout,
        pcloseButton = false,
        pposition = 'toast-bottom-center') {

       let type = ptype;
       if (ptype === 'warn') {
          type = 'warning';
       }

       let obj = null;
       
       switch ( type ) {
          case 'error':
             obj = this.toastr.error(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'info':
             obj = this.toastr.info(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'success':
             obj = this.toastr.success(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'warning':
             obj = this.toastr.warning(pmessage, ptitle,          
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
       }
       return obj;
    }


   notify( ptype = 'info',
        ptitle = '',
        pmessage = '',
        ptimeOut: number = this.defaultTimeout) {

       const pcloseButton = true;
       const pposition = 'toast-center-center';
       
       let type = ptype;
       if (ptype === 'warn') {
          type = 'warning';
       }

       let obj = null;
       
       switch ( type ) {
          case 'error':
             obj = this.toastr.error(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'info':
             obj = this.toastr.info(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'success':
             obj = this.toastr.success(pmessage, ptitle,
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
          case 'warning':
             obj = this.toastr.warning(pmessage, ptitle,          
                 { timeOut: ptimeOut, closeButton: pcloseButton,
                   positionClass: pposition } );
             break;
       }
       return obj;
    }

   message(ptitle = 'Info', pmessage = '',
           ptimeOut: number = this.defaultTimeout) {

       const pcloseButton = true;
       const pposition = 'toast-bottom-center';  // 'toast-center-center';

       const type = 'info';
       let obj = null;

       obj = this.toastr.info(pmessage, ptitle,
             { timeOut: ptimeOut, closeButton: pcloseButton,
               positionClass: pposition } );
               
       return obj;
    }

}