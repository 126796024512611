<div *ngIf="!accepted" id="tos">
    <div class="content">
        <div class="header">
            <div class="header-label">
                <mat-icon>assistant_photo</mat-icon><p>Terms of Service</p>
            </div>
        </div>
        <div id="tos-content">
            <app-tos></app-tos>
        </div>
        <div class="footer">
            <button class='button-accept' mat-raised-button (click)="accepted=true" style="float: left" type="submit">Accept</button>
            <button class='button-decline' mat-raised-button (click)="logout()" style="float: right" type="submit">Decline</button>
        </div>
    </div>
</div>
