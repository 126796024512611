import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotifyQueueService {

    constructor(private http: HttpClient) { }

    add(data) {
      console.log(data);
      return this.http.put(environment.apiUrl + '/queue',
        data);
    }
}
