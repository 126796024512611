import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, ViewChildren, Inject, EventEmitter, ElementRef,
         OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef, QueryList }
         from '@angular/core';
import { ToasterService } from '../service/toaster.service';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LayerData } from '../model/layerdata.model';
import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';

import { DocumentService } from '../service/document.service';
import { UserService } from '../service/user.service';
import { HyperlinkService } from '../service/hyperlink.service';
import { LayerService } from '../service/layer.service';
import { MarkerService } from '../service/marker.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { DocumentData } from '../model/documentdata.model';
import { GroupData } from '../model/groupdata.model';

import * as HELP from '../service/help.service';

import * as CreateLink from './create-link.component';

export const MODE_COMPONENT = 0;
export const MODE_DIALOG = 1;

export const NAV_CREATE_LINK = 'Create A Hyperlink';
export const NAV_LINK_EXTERNAL = 'Create External Link';
export const NAV_LINK_INTERNAL = 'Create Internal Link';
export const NAV_LINK_FILE = 'Select a File to Link To';            
export const NAV_LINK_PAGE = 'Enter a Page Nbr to Link To';
export const NAV_LINK_MARKER = 'Select a Marker to Link To';    


@Component({
   selector: 'app-create-link',
   templateUrl: './create-link.component.html',
   styleUrls: ['./create-link.component.css'],
})
export class CreateLinkComponent implements OnInit, OnDestroy, AfterViewInit {

   @Input()  isDialog = false;
   @Input()  mode: number;
   @Input()  origin: string;
   @Input()  userInfo: UserInfo;
   @Input()  orgUID: string;
   @Input()  groupUID: string;
   @Input()  selectedGroupObj: GroupData;
   @Input()  documentUID: string;
   @Input()  linkSrcData: MarkerData;

   @Output() closeEvent = new EventEmitter<any>();
   @Output() templateChange = new EventEmitter<any>();
   @Output() markerDataChange = new EventEmitter<any>();
   @Output() documentDataChange = new EventEmitter<any>();      
   @Output() helpContextChange = new EventEmitter<any>();   
   @Output() displayUserInfoEvent = new EventEmitter<any>();

   @ViewChild('appMarker', {static: false}) appMarker: ElementRef;

   helpContext = HELP.CREATE_LINK;

   // Template
   templateCreateLink = CreateLink.NAV_CREATE_LINK;
   templateLinkExternal = CreateLink.NAV_LINK_EXTERNAL;
   templateLinkInternal = CreateLink.NAV_LINK_INTERNAL;
   templateLinkPage = CreateLink.NAV_LINK_PAGE;   
   templateLinkFile = CreateLink.NAV_LINK_FILE;
   templateLinkMarker = CreateLink.NAV_LINK_MARKER;

   template: string = CreateLink.NAV_CREATE_LINK;

   crossLink: boolean;
   crossLinkDisabled: boolean;   

   newTab: boolean;
   newTabDisabled: boolean;

   LINK_TYPE_EXTERNAL = 'E';
   LINK_TYPE_INTERNAL = 'I';

   linkType: string = null;
   linkOrigin = '';
   linkTitle = '';
   linkDocumentList: DocumentData[] = [];
   linkDocumentData: DocumentData = null;
   linkLayerData: LayerData[] = null;
   linkNoteData: NoteData = null;
   linkMarkerData: MarkerData = null;
   linkPageNbr = 0;
   linkURL = '';

   documentList: DocumentData[] = [];
   
   // Service Messaging Subscription
   markerSubscription: Subscription;
   markerData: MarkerData;

   itemData: NoteData;
   commentData: any;

   // View vars

   // Instance vars
   stepMessage: string;
   userUID: string;
   userData: UserData;
   groupName: string;

   loading: boolean;

   // group member info
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;

   // MarkerList for Table View
   markerList: MarkerData[] = [];

   constructor(
      public hyperlinksvc: HyperlinkService,
      public documentsvc: DocumentService,
      private layersvc: LayerService,
      private markersvc: MarkerService,
      private usersvc: UserService,
      private toast: ToasterService,
      private cd: ChangeDetectorRef
      ) {
            this.crossLink = false;
            this.crossLinkDisabled = true;          
            this.newTab = false;
            this.newTabDisabled = true;          
        } // constructor

   ngOnInit() {
      // console.log('Marker userInfo=', this.userInfo);
      this.template = this.templateCreateLink;
      this.linkOrigin = this.origin;
   }

   async ngAfterViewInit() {
//      let width = this.appMarker.nativeElement.offsetWidth;
//      let height = this.appMarker.nativeElement.offsetHeight;
//      console.log('srcWidth:' + width);
//      console.log('srcHeight: ' + height);
      this.documentList = await this.documentsvc.getDocumentList();
   }

   ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     if (this.markerSubscription) {
        this.markerSubscription.unsubscribe();
     }
   }

   async displayUserInfo(uuid: string) {
     let mbrInfo = '';
     console.log('MLC uuid=', uuid);
     try{
        mbrInfo = await this.usersvc.getUserInfo(uuid);
        console.log('MLC mbrInfo=', mbrInfo);
     } catch (e) {
        console.log('MLC error retrieving userInfo for uuid=' + uuid +
                    'e=' + e);
       }
     this.displayUserInfoEvent.emit(mbrInfo);
   }

   getDate(m): string {
     let utcSeconds = 0;
     let dt   = '';
     if (m?.created_at) {
           utcSeconds = m.created_at;
           const d = new Date(0);
           d.setUTCSeconds(utcSeconds);
           dt = d.toISOString();
     }
     return dt;
   }

   // *** Navigation functions ***

   sidenavClose() {
      this.closeEvent.emit(0);
   }
   
   sidenavCreateLink() {
     this.linkType = null;
     this.linkOrigin = '';
     this.linkTitle = '';
     this.linkURL = '';
     this.linkPageNbr = 1;
     this.linkLayerData = [];
     this.linkNoteData = null;      
     this.setMarkerData(null);
     this.setDocumentData(null);     
     console.log('sidenavCreateLink..');
     console.log('sidenavCreateLink linkSrcData=', this.linkSrcData);     
     this.setTemplate(CreateLink.NAV_CREATE_LINK);
     this.setHelpContext(HELP.CREATE_LINK);
   }
   sidenavLinkExternal() {
     console.log('sidenavExternalLink..');
     this.linkType = this.LINK_TYPE_EXTERNAL;
     this.setTemplate(CreateLink.NAV_LINK_EXTERNAL);
     this.setHelpContext(HELP.CREATE_LINK);
   }
   sidenavLinkInternal() {
     console.log('sidenavInternalLink..');
     this.linkType = this.LINK_TYPE_INTERNAL;     
     this.getInternalURL();
     this.setTemplate(CreateLink.NAV_LINK_INTERNAL);
     this.setHelpContext(HELP.CREATE_LINK);
   }
   sidenavLinkFile() {
     this.linkLayerData = [];
     this.linkNoteData = null; 
     this.linkMarkerData = null;
     this.linkPageNbr = 1;
     console.log('sidenavLinkFile..');
     this.linkMarkerData = null;
     this.setTemplate(CreateLink.NAV_LINK_FILE);
     this.setHelpContext(HELP.CREATE_LINK);
   }
   sidenavLinkPage() {
     console.log('sidenavLinkPage..');
     this.setTemplate(CreateLink.NAV_LINK_PAGE);
     this.setHelpContext(HELP.CREATE_LINK);
   }
   async sidenavLinkMarker() {
     console.log('sidenavLinkMarker..');
     this.loading = true;
     this.linkLayerData = await this.layersvc.loadLayerData(this.linkDocumentData.uid);
     console.log('linkLayerData=', this.linkLayerData);            
     this.linkNoteData = await this.markersvc.getMarkerData(this.linkDocumentData.uid, this.linkLayerData);
     console.log('linkNoteData=', this.linkNoteData);
     this.setTemplate(CreateLink.NAV_LINK_MARKER);
     this.setHelpContext(HELP.CREATE_LINK);
  }

  
  // *** Emit changes ***

  setMarkerData( m: MarkerData) {
     this.linkMarkerData = m;
     this.markerDataChange.emit(m);
  }
  setDocumentData( d: DocumentData) {
     this.linkDocumentData = d;
     this.documentDataChange.emit(d);
  }
  setTemplate( t: string) {
     this.template = t;
     this.templateChange.emit(t);
  }
  setHelpContext( ctx: number) {
     this.helpContext = ctx;
     this.helpContextChange.emit(ctx);
  }

  createExternalLink() {
    this.hyperlinksvc.createExternalLink(this.linkTitle, this.linkURL);
    this.sidenavClose();
  }
  
  async createInternalMarkerLink() {
    if ( this.newTab ) {
        this.linkOrigin = 'markadoc-nt';
    } else {
          this.linkOrigin = 'markadoc';     
    }
    console.log('cl linkLayerData=', this.linkLayerData);
    const docuid = this.linkLayerData[0].documents_uid;
    const docData = await this.documentsvc.getDocumentData( docuid );
    const fromDocName = docData.name;
    this.hyperlinksvc.createInternalMarkerLink(fromDocName, this.linkMarkerData, this.linkOrigin, this.linkTitle, this.linkDocumentData.uid, this.linkMarkerData.uuid, this.documentUID, this.linkSrcData, this.crossLink);
    this.sidenavClose();
  }
  createInternalPageLink() {
    if ( this.newTab ) {
        this.linkOrigin = 'markadoc-nt';
    } else {
          this.linkOrigin = 'markadoc';     
    }
    this.hyperlinksvc.createInternalPageLink(this.linkOrigin, this.linkTitle, this.linkDocumentData.uid, this.linkPageNbr);
    this.sidenavClose();
  }
    
  // *** Hyperlink functions ***
  getInternalURL() {
     console.log('getInternalURL doc=', this.linkDocumentData);
     console.log('getInternalURL mark=', this.linkMarkerData);
     console.log('getInternalURL title=', this.linkTitle);
     console.log('getInternalURL page=', this.linkPageNbr);       
     // this.linkOrigin = window.location.origin;
     console.log('create-link getInternalURL() newTab=', this.newTab);
     if ( this.newTab ) {
        this.linkOrigin = 'markadoc-nt';
     } else {
          this.linkOrigin = 'markadoc';     
     }
     let args = '';
     if ( this.linkDocumentData != null ) {
        args = "?docid=" + this.linkDocumentData.uid;
        if ( this.linkMarkerData !== null ) {
          args += '&noteid=' + this.linkMarkerData.uid;
        } else {
            args += '&page=' + this.linkPageNbr;
        }
     }
     this.linkURL = this.linkOrigin + '/' + args;       
  }

  markerLink(e) {
     console.log('mobile markerLink e=', e);
     this.sidenavCreateLink();    
  }

  saveLinkURL(duid) {
     this.linkOrigin = window.location.origin;    
     const args = "?docid=" + duid;
     this.linkURL = this.linkOrigin + '/' + args;    
  }
  saveLinkDocument(d: DocumentData) {
    console.log('saveLinkDocument d=', d);
    this.linkOrigin = window.location.origin;
    this.setDocumentData(d);
    this.linkTitle = d.name;
    this.saveLinkURL(d.uid);
  }
  saveLinkMarker(m) {
    console.log('saveLinkMarker m=', m);
    this.setMarkerData(m);    
    this.linkTitle = m.title;
  }
  saveLinkPage(p) {
    this.linkPageNbr = p;
    this.linkTitle = 'Page: ' + p;
  }
  setMarkerUrl(url: string) {
    this.linkURL = url;    
  }

  setMarkerTitle(t: string) {
    this.linkTitle = t;    
  }

  pasteMarkerUrl(e: ClipboardEvent) {
     console.log("pasteTitle=", e);
//       let clipboardData = e.clipboardData || window.clipboardData;
//     const clipboardData = e.clipboardData;
//     console.log("clipboardData=", e.clipboardData);     
//     const pastedText = clipboardData.getData('text');
//     this.setMarkerUrl( pastedText );
     console.log("linkURL=", this.linkURL);
  }
    
  pasteMarkerTitle(e) {
     console.log("pasteTitle=", e);
     console.log("linkTitle=", this.linkTitle);       
  }


} // marker
