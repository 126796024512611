<div mat-dialog-title class="dialog-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>  
    <span id="action-title" style="color: #1A567A; align-self: flex-start;">User Information</span>
  </div>
  <div class="dialog-header-right">
      <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
   </div>
 </div>

  <mat-dialog-content class="panel-class" style="margin-top: 1rem;">
  <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>
  <div class="row">
    <div style="font-size: 1em; color: black">Company:</div>
    <div *ngIf="userInfo && userInfo.company" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.company}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Name:</div>
    <div *ngIf="userInfo && userInfo.name" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.name}}</div>
    <div *ngIf="userInfo && userInfo.last_name" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.last_name}}</div>        
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Email:</div>
    <div *ngIf="userInfo && userInfo.email" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.email}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Phone:</div>
    <div *ngIf="userInfo && userInfo.phone" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.phone}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Type:</div>
    <div *ngIf="userInfo && userInfo.name === '' && userInfo.last_name === '';else enrolled" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">Unenrolled</div>
    <ng-template #enrolled>
      <div class="dialog-message" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">Enrolled</div>
    </ng-template>
    <div *ngIf="userInfo && userInfo.expires!==null;else guest" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">Subscriber Account</div>
    <ng-template #guest>
      <div class="dialog-message" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">Guest Account</div>      
    </ng-template>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Renews/Expires:</div>    
    <div *ngIf="userInfo && userInfo.expires!==null" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.expires}}</div>  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Notifications:</div>        
    <div *ngIf="userInfo && userInfo.notify!==null && userInfo.notify===true; else off" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">On</div>     <ng-template #off>
      <div class="dialog-message" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">Off</div>      
    </ng-template>
   </div>
  
</mat-dialog-content>
  
<mat-dialog-actions style="align: end">
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.close()'>Close</button>
  
</mat-dialog-actions>    
