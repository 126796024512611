<div class="header">
  <div class="title">
    <mat-icon>download</mat-icon>
    <span class="title" style="margin-left: 1rem">Download</span>
  </div>
  <span class="subtitle">{{docname}}</span>
  <mat-divider></mat-divider>  
</div>
  <mat-divider></mat-divider>  
<mat-nav-list>
  <a mat-list-item (click)="sendEvent('download')">    
      <span mat-line>'Download Document'</span>
      <span mat-line>'Download Original Document'</span>
   </a>	   
   <mat-divider></mat-divider>		
  <a mat-list-item (click)="sendEvent('download-both')">
      <span mat-line>'Download Document with Marker Report'</span>
      <span mat-line>'Download Original Document + Marker Report'</span>
   </a>	   
   <mat-divider></mat-divider>		
  <a mat-list-item (click)="sendEvent('download-annotated')">
      <span mat-line>'Download Document with Markup & Marker Report'</span>
      <span mat-line>'Download Annotated Document + Marker Report'</span>
   </a>	   
  <mat-divider></mat-divider>
   <a mat-list-item class="footer" style="color: red;"
        (click)="close()">
     <span mat-line>Cancel</span>
   </a>
</mat-nav-list>
