<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<mat-menu #actionMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="helpDialog()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>help_outline</mat-icon>Help
      </button>
      <ng-container *ngIf="objModel===DisplayModel.USER">
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(0)"
          matTooltip="Edit User Information"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>account_circle</mat-icon>Edit User Information
      </button>
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(1)"
          matTooltip="Change Password"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>https</mat-icon>Password Reset
      </button>
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(2)"
          matTooltip="Terms of Service"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>assistant_photo</mat-icon>View Terms of Service
      </button>
      </ng-container>
      <ng-container *ngIf="objModel!==DisplayModel.USER">
	<button *ngIf="allowView"
		mat-menu-item enabled (click)="viewObj()">
        <mat-icon>preview</mat-icon>              
        <span style="font-size: 0.9em">View {{objLabel}}</span>
      </button>
      <button *ngIf="userData && allowAdd"	  
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon>        
          <span style="font-size: 0.9em">Create New {{objLabel}}</span>
      </button>
      <button *ngIf="allowEdit && isObjAdmin"
	  mat-menu-item enabled (click)="editObj()">
          <mat-icon>edit</mat-icon>  
          <span style="font-size: 0.9em">Edit {{objLabel}}</span>
      </button>
      <button *ngIf="userData && allowDelete"
	  mat-menu-item enabled (click)="deleteObj()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete {{objLabel}}</span>
      </button>
      </ng-container>
      <button mat-menu-item id="close-x" (click)="close()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>close</mat-icon>Close
      </button>
</mat-menu>

<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
   <mat-icon style="margin-left: 0; padding-left: 0;">drag_indicator</mat-icon>
   <span class="title" style="align-self: flex-start;">{{dialogTitle}}</span>
  </div>
  <div class="dialog-header-right">
  <button #settingsMgrMoreBtn mat-icon-button
	  [matMenuTriggerFor]="actionMenu"
	  style="align-self: flex-start; align: top; margin-right: 2rem;">
          <mat-icon>more_vert</mat-icon>
  </button>
  <button mat-icon-button id="close-x" (click)="close()"
          style="margin-left: 0.2rem; align-self: flex-top;">
          <mat-icon>close</mat-icon>
  </button>
  </div>
</div>

<div mat-dialog-content id="settings" #content>

  <ng-container *ngIf="objModel===DisplayModel.USER">
     <app-settings-dialog class="left-content-scroll"
         style="margin-left: 1rem; width: 60vw; max-width: 100vw; height: 90%; margin-top: 0; padding-top: 0;">
     </app-settings-dialog>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.GROUP">
       <app-group #formItem
		  style="margin-left: 1rem; width: 60vw; max-width: 100vw; height: 90%; margin-top: 0; padding-top: 0;"
           [iam]="iam"	  
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [uid]="objData.uid"
           [objData]="objData"
           [showActions]="false"
           [showMenuButton]="false"
           [showDeleteButton]="true"
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"			  
           (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)"	
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
	   (doneEvent)="onDone()">
        </app-group>
   </ng-container>

   <ng-container *ngIf="objModel===DisplayModel.GROUP_FILE">
       <app-group-file #formItem
		  style="margin-left: 1rem; width: 60vw; max-width: 100vw; height: 90%; margin-top: 0; padding-top: 0;"
           [iam]="iam"	  
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [uid]="objData.uid"
           [objData]="objData"
           [showActions]="false"
           [showMenuButton]="false"
           [showDeleteButton]="true"
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"			  
           (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)"	
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
	   (doneEvent)="onDone()">
        </app-group-file>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.GROUP_FILE_LIST">
       <app-group-file-list #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedOrg]="selectedOrg"
           [selectedGroup]="selectedGroup"
           [selectedObj]="selectedDocObj"
           [showActions]="false"
           [showMenuButton]="true"
           [showDeleteButton]="true"			  
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (modeEvent)="setObjMode($event)"	  
           (selectObjEvent)="setSelectedObj($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (doneEvent)="onDone()"
	   >
       </app-group-file-list>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG_TAB">
       <app-org-tab #orgTab
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="objData"
           [showActions]="false"		    
           [selectedObj]="objData"
           [selectedOrg]="selectedOrg"
           [useMenuActionDialog]="false"		    
           [showMenuButton]="true"
           [showDeleteButton]="true"
           [doSubmit$]="doSubmit$"		  
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"			  
	   (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)"
           (selectOrgEvent)="setSelectedOrg($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (refreshEvent)="refreshList()"	 
	   >
       </app-org-tab>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG">
       <app-org #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="objData"
           [showActions]="false"
           [showMenuButton]="true"		     
           [showDeleteButton]="true"
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (isObjAdminEvent)="setObjAdmin($event)"
           (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (refreshEvent)="refreshList()">
       </app-org>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG_USR">
       <app-org-user #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="objData"
           [selectedOrg]="selectedOrg" 
           [showActions]="false"
           [showOrgInfo]="true"
           [showMenuButton]="true"		     
	   [showDeleteButton]="true"		  
           [doSubmit$]="doSubmit$"		  
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (isObjAdminEvent)="setObjAdmin($event)"
           (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)" 
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (refreshEvent)="refreshList()"	 
	   >
       </app-org-user>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG_USR_LIST">
       <app-org-user-list #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedOrg]="selectedOrg"			  
           [selectedObj]="selectedOrgUser"
           [showActions]="false"
           [showMenuButton]="true"
           [showDeleteButton]="true"			  
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (modeEvent)="setObjMode($event)"	  
           (selectObjEvent)="setSelectedObj($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (doneEvent)="onDone()"
	   >
       </app-org-user-list>
   </ng-container>

   <ng-container *ngIf="objModel===DisplayModel.ORG_USR_XFER_LIST">
       <app-org-user-list #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedOrg]="selectedOrg"
           [selectedObj]="selectedOrgUser"
	   [excludeEmail]="excludeEmail"
           [showActions]="false"
           [showMenuButton]="false"
           [showListViewButton]="false"			  
           [showAddButton]="false"			  
           [showDeleteButton]="false"
           [doSubmit$]="doSubmit$"
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (modeEvent)="setObjMode($event)"	  
           (selectObjEvent)="setSelectedOrgUser($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (doneEvent)="onDone()"
	   >
       </app-org-user-list>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG_CONTACT">
       <app-org-contact #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="objData"
           [selectedOrg]="selectedOrg" 
           [showActions]="false"
           [showMenuButton]="true"		     
           [showDeleteButton]="true"
           [doSubmit$]="doSubmit$"		  
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (isObjAdminEvent)="setObjAdmin($event)"			
           (modeEvent)="setObjMode($event)"	  
           (selectObjEvent)="setSelectedObj($event)"
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
           (doneEvent)="onDone()"
	   >
       </app-org-contact>
   </ng-container>
   
   <ng-container *ngIf="objModel===DisplayModel.ORG_CONTACT_LIST">
       <app-org-contact-list #formItem
           style="width: 100%;"
           [mode]="objMode"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedOrg]="selectedOrg"
           [selectedObj]="selectedOrgContact"	  	  
           [showActions]="false"
           [showMenuButton]="true"		     
	   [showDeleteButton]="true"		  
           [doSubmit$]="doSubmit$"		  
           [doReset$]="doReset$"
           [doEdit$]="doEdit$"
           [doDelete$]="doDelete$"			  
           [doView$]="doView$"
           (modeEvent)="setObjMode($event)"
           (selectObjEvent)="setSelectedObj($event)"	
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()"
	   (doneEvent)="onDone()"
	   >
       </app-org-contact-list>
   </ng-container>

   <ng-container *ngIf="objModel===DisplayModel.ORG_USR_CONTENT_XFER">
     <div>
     <!-- <label id="radio-group-label" class="radio-group-label" >
	 Before removing this Company/Org. User:</label> -->
     <label id="radio-group-label" class="radio-group-label"
	    style="font-size 0.7rem">
       Transfer the Selected Company/Org. User Content (Company/Org. groups/files)
     </label>
     <mat-radio-group
       aria-labelledby="example-radio-group-label"
       class="radio-group"
       [(ngModel)]="contentXferType">
       <mat-radio-button class="radio-button" [value]="0">Transfer Content to Company/Org. Owner
       </mat-radio-button>
       <mat-radio-button class="radio-button" [value]="1">Transfer Content to Another Company/Org. User
       </mat-radio-button>
       <mat-radio-button class="radio-button" [value]="2" disabled>
	 Delete Company/Org. User Content
       </mat-radio-button>
     </mat-radio-group>
<!-- <div>Your favorite selection is: {{contentXferType}}</div> --->
    </div>
   </ng-container>
   
</div>

<div mat-dialog-actions>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center; border-top: 1px solid #1A567A; width: 100%" >

    <button *ngIf="(objMode===DisplayMode.ADD || objMode===DisplayMode.EDIT) && isObjAdmin"
	    mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="submit();">
    <span style="font-size: 1rem">Submit</span>    
    </button>

    <button *ngIf="showNextButton"
	    mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="formNext();">
    <span style="font-size: 1rem">Next</span>    
    </button>
    
    <button *ngIf="objMode===DisplayMode.VIEW && isObjAdmin"
	    mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 2em;"
          (click)="edit();">
       <span style="font-size: 1rem">Edit</span>    
    </button>

    <button *ngIf="objMode===DisplayMode.ADD && isObjAdmin"
	    mat-raised-button class="clear-button"
	  style="align-self: flex-end; margin-left: 2rem; font-size: 1rem;"
          (click)="formItem.resetForm()">
       <span style="font-size: 1rem">Reset</span>    
    </button>

    <button mat-raised-button class="clear-button"
	  style="align-self: flex-end; margin-left: 2em;"
          (click)="close()">
      <span style="font-size: 1rem">Close</span>    
    </button>
  
    <button mat-icon-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 2em;"
          (click)="scrollTop()">
       <mat-icon>vertical_align_top</mat-icon>    
    </button>
    <button mat-icon-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	    style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollBottom()">
      <mat-icon>vertical_align_bottom</mat-icon>
    </button>
  
 </div>
  
</div>
