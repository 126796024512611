import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from '../service/toaster.service';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

declare let grecaptcha: any;

@Component({
    selector: 'app-new-user-email',
    templateUrl: './new-user-email.component.html',
    styleUrls: ['./new-user-email.component.css']
})
export class NewUserEmailComponent implements OnInit {
    emailSignupForm = this.fb.group({
        email: ['', Validators.email]
    });
    formCompleted = false;

    constructor(
        private fb: FormBuilder,
        private toast: ToasterService,
        private router: Router,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        if (typeof grecaptcha !== 'undefined') {
            grecaptcha.render('cap');
        }
    }

    onSubmit() {
        const data = {
            email: this.emailSignupForm.get('email').value,
            captcha: grecaptcha.getResponse()
        };

        this.toast.pop('wait', 'Processing');
        console.log('free signup data=', data);
        this.http.post(environment.apiUrl + '/linkeduser/signup', data).subscribe(
            (x) => {
                this.toast.clear();
                this.toast.pop('success', 'Email sent.');
                this.router.navigate(['/login'], {replaceUrl: true});
                this.formCompleted = true;
            },
            (e) => {
                this.toast.clear();
                this.toast.pop('error', 'Signup failed.');
                this.router.navigate(['/login'], {replaceUrl: true});
                console.log(e);
            }
        );
    }
}
