import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, ViewChildren, Inject, EventEmitter, ElementRef,
         OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef, QueryList } from '@angular/core';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ToasterService } from '../service/toaster.service';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';

import { AuthService } from '../service/auth.service';
import { MarkerService } from '../service/marker.service';
import { UserService } from '../service/user.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';

import * as Marker from './marker.component'

export const TYPE_UNKNOWN = 0;
export const TYPE_QUESTION = 1;
export const TYPE_COMMENT = 2;
export const TYPE_ISSUE = 3;

export const TEMPLATE_TOOL = 0;
export const TEMPLATE_CLOSED = 1;
export const TEMPLATE_EDIT_DESCRIPTION = 2;
export const TEMPLATE_EDIT_COMMENTS = 3;


@Component({
   selector: 'app-marker',
   templateUrl: './marker.component.html',
   styleUrls: ['./marker.component.css'],
//   animations: [
//      trigger('detailExpand', [
//         state('collapsed', style({ height: '0px', minHeight: '0' })),
//         state('expanded', style({ height: '*' })),
//         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
//      ]),
//   ],
})
export class MarkerComponent implements OnInit, OnDestroy, AfterViewInit {

   private NOTE_DATA: NoteData;
   private DOCUMENT_NAME = '';

   @Input()  set noteData(val: NoteData) {
      // console.log('previous noteData = ', this.NOTE_DATA);
      // console.log('current noteData =', val);
      this.NOTE_DATA = val;
      this.noteDataChange(this.NOTE_DATA);
   }

   get noteData(): NoteData {
      return this.NOTE_DATA;
   }

   @Input()  set documentName(val: string) {
      console.log('previous documentName = ', this.DOCUMENT_NAME);
      console.log('current documentName =', val);
      this.DOCUMENT_NAME = val;
   }
   get documentName(): string {
      return this.DOCUMENT_NAME;
   }

   @Input()  type: number;
   @Input()  template: number;
   @Input()  userInfo: UserInfo;
   @Input()  orgUID: string;
   @Input()  groupUID: string;
   @Input()  documentUID: string;

   @Output() scrollToNoteEvent = new EventEmitter<any>();
   @Output() scrollToPageEvent = new EventEmitter<any>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() getPdfEvent = new EventEmitter<any>();

   @ViewChild('appMarker', {static: false}) appMarker: ElementRef;
   // @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   // @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   // @ViewChild('outerSort', { static: true }) sort: MatSort;
   // @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
   // @ViewChildren('innerTables') innerTables: QueryList<MatTable<Comment>>;

   iconPathIssue = '/assets/issue_icon.png';
   iconPathQuestion = '/assets/question_icon.png';
   iconPathComment = '/assets/note_icon.png';   
   iconPath = '/assets/issue_icon.png';
   
   // Service Messaging Subscription
   markerSubscription: Subscription;
   markerData: MarkerData;

   id = 'tool-marker';
   dialogId = 'tool-marker-dialog';   

   className = 'sidenav-section-content sidnav-icon note-added';
   position = 'absolute';
   width = 32;
   height = 40;
   top = 0;
   left = 0;
   margin = 0;
   padding = 0;
   border = 0;   
   
   itemData: NoteData;
   commentData: any;

   // Template
   templateTool = Marker.TEMPLATE_TOOL;   
   templateClosed = Marker.TEMPLATE_CLOSED;
   templateEditDescription = Marker.TEMPLATE_EDIT_DESCRIPTION;
   templateEditComments = Marker.TEMPLATE_EDIT_COMMENTS;

   // Style Variables
   markerStyle = 'width: 32px; height: 40px;';
   
   // View vars
   /* Main MARKERS toggle */
   toggleFiber = false;
   /* Questions toggle */
   toggleStateQ = false;
   /* Comments/Notes toggle */
   toggleState = false;
   /* Issues toggle */
   toggleStateI = false;

   // Instance vars
   stepMessage: string;
   userUID: string;
   userData: UserData;
   groupName: string;

   loading: boolean;

   // group member info
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedDocUID;
   selectedDocName;
   selectedGroupObj: any;

   // MarkerList for Table View
   markerList: MarkerData[] = [];

   constructor(
      private auth: AuthService,
      private markersvc: MarkerService,
      private usersvc: UserService,
      private toast: ToasterService,
      private cd: ChangeDetectorRef
      ) {
      } // constructor

   ngOnInit() {
      // console.log('Marker userInfo=', this.userInfo);
      this.template = this.templateTool;
      if (this.type) {
        switch(this.type) {
           case Marker.TYPE_QUESTION:
                this.iconPath = this.iconPathQuestion;     
                break;
           case Marker.TYPE_COMMENT:
                this.iconPath = this.iconPathComment;      
                break;
           case Marker.TYPE_ISSUE:
                this.iconPath = this.iconPathIssue;        
                break;
           default:
                this.iconPath = this.iconPathIssue;
                break;
        }
      }
   }

   async ngAfterViewInit() {
//      let width = this.appMarker.nativeElement.offsetWidth;
//      let height = this.appMarker.nativeElement.offsetHeight;
//      console.log('srcWidth:' + width);
//      console.log('srcHeight: ' + height);      
   }

   ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     if (this.markerSubscription) {
        this.markerSubscription.unsubscribe();
     }
   }

   // noteData change handler
   //
   noteDataChange(n) {
      console.log('Marker noteData Change n = ', n);

      // console.log('MLC noteData Change noteData = ', this.noteData);
      // console.log("markerlist=", this.markerList);

  }

  async displayUserInfo(uuid: string) {
     let mbrInfo = '';
     console.log('MLC uuid=', uuid);
     try{
        mbrInfo = await this.usersvc.getUserInfo(uuid);
        console.log('MLC mbrInfo=', mbrInfo);
     } catch (e) {
        console.log('MLC error retrieving userInfo for uuid=' + uuid +
                    'e=' + e);
       }
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  getCommentCount(m): number {
     if (m.comments && Array.isArray(m.comments)) {
         return m.comments.length;
     } else {
          return 0;
       }
  }

  getDate(m): string {
     let utcSeconds = 0;
     let dt   = '';
     if (m?.created_at) {
           utcSeconds = m.created_at;
           const d = new Date(0);
           d.setUTCSeconds(utcSeconds);
           dt = d.toISOString();
     }
     return dt;
  }

  // table
  scrollToPage(e) {
    console.log('ml: scroll to page=', e);
    this.scrollToPageEvent.emit(e);
  }

  // table and sidenav
  //
  scrollToNote(e) {
    console.log('ml: scroll to note=', e);
    this.scrollToNoteEvent.emit(e);
  }

  // SideNav functions
  //

  minimize_comments(idSection: string){
  /***
     const displayState = document.getElementById(idSection).style.height;
     if (displayState === '0px') {
         document.getElementById(idSection).style.opacity = '1';
         document.getElementById(idSection).style.height = 'auto';
         document.getElementById(idSection).style.width = 'auto';
     }else {
         document.getElementById(idSection).style.opacity = '0';
         document.getElementById(idSection).style.height = '0px';
         document.getElementById(idSection).style.width = '0px';
     }
     ***/
  }

  collapseComments(idHeaderSection: string) {
     /***
     const openState = document.getElementById(idHeaderSection).style.height;
     if (openState === '0px') {
         document.getElementById(idHeaderSection).style.opacity = '1';
         document.getElementById(idHeaderSection).style.height = 'auto';
         document.getElementById(idHeaderSection).style.width = 'auto';
     } else {
         document.getElementById(idHeaderSection).style.opacity = '0';
         document.getElementById(idHeaderSection).style.height = '0px';
         document.getElementById(idHeaderSection).style.width = '0px';
     }
     ***/
  }

  onClick(e) {
     console.log("marker onClick e=", e);
     // this.iconPath = this.iconPathQuestion;
     window.alert('marker clicked.');
  }

} // marker
