<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>
    <span class="dialog-title">Image Conversion Settings</span>
  </div>
  <div class="dialog-header-right">  
    <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
  </div>
 </div>
  
<mat-dialog-content class="panel-class">
  <div style="display: flex; flex-flow: column wrap; justify-content: flex-start;">
    <div>
<!---      
      <img *ngIf="fs" #srcImg id="srcImg" class="no_border" [src]="fs.imgURL" alt="" style="height: 3rem; image-orientation: none; margin-bottom: 5px; border-width: {{borderPX}}px; border-style: solid; padding: 2%"/>
 --->
      <img *ngIf="fs" #srcImg id="srcImg" class="no_border" [src]="fs.imgURL" alt="" style="height: 7rem; transform: rotate({{selectedRotation}}deg); image-orientation: none; margin-bottom: 5px; border-width: {{borderPX}}px; border-style: solid; padding: 2%"/>

    </div>
    <!---
    <div *ngIf="fs" #preview id="preview" class="preview" style="width: 3rem; max-width: 3rem; image-orientation: none; margin-bottom: 5px; border-width: {{borderPX}}px; border-color: black; border-style: solid; padding: 2%;">
    </div>
    --->


    <mat-form-field style="width:80% !important; margin-bottom: 0%; font-size: 0.9rem" appearance="standard" class="mat-form-field" id="choose_size">
       <mat-label style="font-size: 0.8rem">Fit Image Size To:</mat-label>
           <mat-select [(ngModel)]="selectedSize" [ngModelOptions]="{standalone: true}" style="font-size: 0.9rem; margin-bottom=5px;" [(value)]="selectedSize" (selectionChange)="setImgSize(selectedSize)">
               <mat-option *ngFor="let s of imageSizes" [value]="s.size" style="font-size: 0.9rem">{{s.descr}}</mat-option>
         </mat-select>

    </mat-form-field>
    <mat-form-field style="width:80% !important; margin-bottom: 1%; font-size: -.8rem" appearance="standard" class="mat-form-field" id="choose_rotation">
       <mat-label style="font-size: 1em">Image Rotation:</mat-label>
       <mat-select [(ngModel)]="selectedRotation" [ngModelOptions]="{standalone: true}"
		   [(value)]="selectedRotation" style="font-size: 0.9rem"
                   (selectionChange)="setImgRotation(selectedRotation)"> 
                   <mat-option *ngFor="let r of imageRotations" [value]="r.angle"
		                style="font-size: 0.9rem">{{r.descr}}</mat-option>
       </mat-select>
    </mat-form-field>
    
    <div style="width:80% !important; margin-bottom: 1%; font-size: 0.9rem" class="mat-form-field-toggle" id="choose_margins">
      <mat-label style="font-size: 1em">Add Margins:</mat-label>
      <mat-slide-toggle class="add-margin-toggle"
		  style="font-size: 0.9rem"
		  (change)="toggleBorder()"
		  [(ngModel)]="addMargins"
	          [ngModelOptions]="{standalone: true}"
	          color='primary'
                  [checked]="false"> Off/On
       </mat-slide-toggle>      
    </div>
   </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button  class="action-button"
	  style="align-self: flex-start; margin-left: 1em;"	  
          style="margin-left: 1rem;"
          (click)="save()">Save</button>
  <button mat-raised-button class="bottom-buttons"
	  style="align-self: flex-start; margin-left: 1em;"
	  (click)="close()">Cancel</button>
</mat-dialog-actions>

