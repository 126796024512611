import {Component, OnInit, AfterViewInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import {FormBuilder, FormGroup} from '@angular/forms';
import {ImageSettings} from '../../model/imagesettings.model';
import {FileSelector} from '../../model/fileselector.model';
import * as FSC from '../../model/fileselector.constants';

export interface ImageSpecData {
  fileName: string;
  fs: FileSelector;
  idx: number;
  size: number;
  rotation: number;
  addMargins: boolean;
}

export interface SizeData {
  size: number;
  descr: string;
}
export interface RotationData {
  angle: number;
  descr: string;
}

@Component({
  selector: 'app-image-spec-dialog',
  templateUrl: './image-spec.dialog.html',
  styleUrls: ['./image-spec.dialog.css']
})

export class ImageSpecDialogComponent implements OnInit, AfterViewInit{
  @Output() updateSettings = new EventEmitter<any>();

  @ViewChild('srcImg', {static: true}) srcimg;

  sourceImage: any;
  fullImage: any;
  previewImage: any;
  imgWidth = 0;
  imgHeight = 0;
  form: FormGroup;
  fileName = '';
  fs: FileSelector;
  idx = 0;
  borderPX = '0';
  imgURL: any = null;
  imageSizes: any;
  imageRotations: any;
  addMargins: any;
  borderClass = 'no_border';
  selectedSize: number = FSC.SIZE_LEDGER;
  selectedRotation: number = FSC.ROTATE_NONE;
  sizes: Array<SizeData>;
  rotations: Array<RotationData>;

  size: number;
  rotation: number;

  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<ImageSpecDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data
      ) {
          this.idx          = data.idx;
          this.fileName     = data.fileName;
          this.fs               = data.fs;
          this.selectedSize     = data.size;
          this.selectedRotation = data.rotation;
          this.addMargins       = data.margins;
          this.imageSizes       = FSC.IMAGE_SIZES;
          this.imageRotations   = FSC.IMAGE_ROTATIONS;
          this.imgURL           = data.imgURL;
          this.imgWidth  = 0;
          this.imgHeight = 0;
         }

  ngOnInit() {
      this.form = this.fb.group({
      });
  }

  ngAfterViewInit() {
      this.previewImage = document.getElementById('preview');
      // console.log('image-spec preview=', this.previewImage);
  }

  getSettings() {
      const is = new ImageSettings();
      const stext  = this.getSizeText(this.selectedSize);
      const rtext  = this.getRotationText(this.selectedRotation);
      const dims   = this.getSizeDims(this.selectedSize);
      is.idx = this.idx;
      is.fileName = this.fileName;
      is.size = this.selectedSize;
      is.rotation = this.selectedRotation;
      is.margins = this.addMargins;
      is.stext = stext;
      is.rtext = rtext;
      is.sdims = dims;
      return is;
  }
  toggleBorder() {
      if (this.addMargins) {
        // this.borderClass="border";
        this.borderPX = '1';
      }
      else {
        // this.borderClass="no_border";
        this.borderPX = '0';
      }
      // let s = this.getSettings();
      // this.sendUpdateSettings(s);
  }
  setImgRotation(r) {
     console.log('rotateImg r=', r);
     this.selectedRotation = r;
     if (this.idx >= 0) {
       this.fs.imageRotation = r;
     }
  }
  setImgSize(s) {
     this.selectedSize = s;
     if (this.idx >= 0) {
       this.fs.imageSize = s;
     }
  }
  getSizeText(size) {
     const s = this.imageSizes.find(x => x.size === size);
     if (s) {
        return s.descr;
     }
     else {
        return '';
     }
  }
  getRotationText(angle) {
     const s = this.imageRotations.find(x => x.angle === angle);
     if (s) {
        return s.descr;
     }
     else {
        return '';
     }
  }
  getSizeDims(size) {
     const s = this.imageSizes.find(x => x.size === x.size);
     if (s) {
        return s.dims;
     }
     else {
        return '';
     }
  }
  // CJ - Send image settings change message back to uploadFilesDialog()
  sendUpdateSettings(data) {
     // must pass current idx back also
     this.updateSettings.emit(data);
  }
  save() {
      const output = this.getSettings();
      console.log('imgdialog: output=', output);
      this.dialogRef.close(output);
      }

  close() {
      this.dialogRef.close();
      }


}
