import { PageSizes } from 'pdf-lib';

export interface SizeData {
  size: number;
  descr: string;
  dims: [number, number];
}
export interface RotationData {
  angle: number;
  descr: string;
}

export const MIME_TYPE_PDF = 'application/pdf';
export const MIME_TYPE_TIF = 'image/tif';
export const MIME_TYPE_JPG = 'image/jpeg';
export const MIME_TYPE_XJPG = 'image/x-jpeg';
export const MIME_TYPE_PNG = 'image/png';
export const MIME_TYPE_XPNG = 'image/x-png';

export const SIZE_ACTUAL = 0;
export const SIZE_4X6 = 1;
export const SIZE_5X7 = 2;
export const SIZE_6X9 = 3;
export const SIZE_8X10 = 4;
export const SIZE_LETTER = 5;
export const SIZE_LEDGER = 6;
export const SIZE_LEGAL = 7;
export const SIZE_EXECUTIVE = 8;
export const SIZE_A2 = 9;
export const SIZE_A3 = 10;
export const SIZE_A4 = 11;

export const DESC_SIZE_ACTUAL = 'Actual Size';
export const DESC_SIZE_4X6 = '4x6 in.';
export const DESC_SIZE_5X7 = '5x7 in.';
export const DESC_SIZE_6X9 = '6x9 in.';
export const DESC_SIZE_8X10 = '8x10 in.';
export const DESC_SIZE_LETTER = 'Letter 8.5x11';
export const DESC_SIZE_LEDGER = 'Ledger/Tabloid 11x17';
export const DESC_SIZE_LEGAL = 'Legal 8.5x14';
export const DESC_SIZE_EXECUTIVE = 'Executive 7.5x10';
export const DESC_SIZE_A2 = 'A2 420x594mm';
export const DESC_SIZE_A3 = 'A3 297x420mm';
export const DESC_SIZE_A4 = 'A4 210x297mm';

export const ROTATE_NONE = 0;
export const ROTATE_90 = 90;
export const ROTATE_180 = 180;
export const ROTATE_270 = 270;

export const DESC_ROTATE_NONE = 'None(0)  ^';
export const DESC_ROTATE_90 = '90 Deg.  >';
export const DESC_ROTATE_180 = '180 Deg. v';
export const DESC_ROTATE_270 = '270 Deg. <';

// Landscape (row,cols)
export const EXIF_ORIENTATION_TOP_LEFT    = 1;
// Portrait (row, cols)
export const EXIF_ORIENTATION_LEFT_BOTTOM = 8;

export const PDF_MARGIN_DOTS = 18;

export const NO_MARGINS = false;
export const MARGINS    = true;

export const IMAGE_SIZES: Array<SizeData> = [
 { size: SIZE_ACTUAL,    descr: DESC_SIZE_ACTUAL, dims: null},
 { size: SIZE_4X6,       descr: DESC_SIZE_4X6, dims: [288, 432] },
 { size: SIZE_5X7,       descr: DESC_SIZE_5X7, dims: [360, 504] },
 { size: SIZE_6X9,       descr: DESC_SIZE_6X9, dims: [432, 648] },
 { size: SIZE_8X10,      descr: DESC_SIZE_8X10, dims: [576, 720] },
 { size: SIZE_LETTER,    descr: DESC_SIZE_LETTER, dims: PageSizes.Letter },
 { size: SIZE_LEDGER,    descr: DESC_SIZE_LEDGER, dims: PageSizes.Tabloid },
 { size: SIZE_LEGAL,     descr: DESC_SIZE_LEGAL,  dims: PageSizes.Legal },
 { size: SIZE_EXECUTIVE, descr: DESC_SIZE_EXECUTIVE, dims: PageSizes.Executive},
 { size: SIZE_A2,        descr: DESC_SIZE_A2, dims: PageSizes.A2},
 { size: SIZE_A3,        descr: DESC_SIZE_A2, dims: PageSizes.A3},
 { size: SIZE_A4,        descr: DESC_SIZE_A4, dims: PageSizes.A4}
 ];

export const IMAGE_ROTATIONS: Array<RotationData> = [
 { angle: ROTATE_NONE, descr: DESC_ROTATE_NONE },
 { angle: ROTATE_90,   descr: DESC_ROTATE_90 },
 { angle: ROTATE_180,  descr: DESC_ROTATE_180 },
 { angle: ROTATE_270,  descr: DESC_ROTATE_270 }
 ];


