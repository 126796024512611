<div mat-dialog-title class="dialog-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon style="margin-top: 0.2rem">drag_indicator</mat-icon>
    <span id="quick-start-title" style="margin-left: 0.5rem">Quick Start</span>
  </div>
  <div class="dialog-header-right">
    <mat-icon id="close-x" class="upload-title"
	      (click)="close()">close</mat-icon>
  </div>
</div>

<ng-template #groupSelector>
  <div style="display: flex; flex-flow: row wrap; justify-content: flex-start; align-items: center; width: 100%">
         <mat-form-field style=" margin-bottom: 1rem; margin-left: 2rem; font-size: 0.8rem; width=100% !important" appearance="standard" class="mat-form-field" id="choose_groups">

                      <mat-select [(ngModel)]="selectedGroup"
				  [ngModelOptions]="{standalone: true}"
				  ngDefaultControl selected="selectedGroup"
				  style="font-size: 1.0rem; width: 100%"
				  (selectionChange)="groupChange(selectedGroup)">
                                  <mat-label style="font-size: 1rem">Create or Select a Group</mat-label>			
                             <mat-option (click)="createGroupEmit()" id="new_group" style="font-size: 0.85rem"> + new group...</mat-option>			
                             <mat-option *ngFor="let g of data.groupList" [value]="g.uid" style="font-size: 0.85rem">{{g.name}}<span *ngIf="userInfo.uid===g.owner" style="font-size: 0.8rem"> &#9733;</span></mat-option>
                      </mat-select>
		      <mat-hint>Click to Select or Add a Group</mat-hint>
         </mat-form-field>
         <button mat-raised-button (click)="createGroupEmit()"
                 style="margin-left: 1rem; height: 50%">
                  <mat-icon>post_add</mat-icon>
         </button>
    </div>
</ng-template>
<ng-template #createGroup>
         <button mat-raised-button (click)="createGroupEmit()"
                 style="margin-left: 1rem; height: 4vh;">
                  <mat-icon style="height 1em;">group_add</mat-icon>
         </button>
</ng-template>



<ng-template #addMembers>  
    <mat-tab-group dynamicHeight style="border: 1px solid #1A567A; margin-left: 1rem">
       <mat-tab label="Add Member">
          <app-add-group-member #addMemberView style="width: 100%; height: 100%"          [template]=1
           [userInfo]="this.userInfo"
           [group]="this.selectedGroupObj"
          >
          </app-add-group-member>
       </mat-tab>
       <mat-tab label="Add Members" >
          <app-add-group-member #addMultipleView style="width: 100%; height: 100%"
          [template]=2
          [userInfo]="this.userInfo"
          [group]="this.selectedGroupObj"			  
          >
          </app-add-group-member>
       </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #addFiles>
            <button #uploadbtn mat-raised-button class="icon-action-button"
              (click)="handleAddFiles();" style="margin-right: 1em; width=100%">
             <mat-icon style="margin-right: auto;">add_circle_outline</mat-icon>
               <span style="padding-left: 3%;">ADD FILES</span>
            </button>
</ng-template>
<!-- Expert Mode - Quick Start Dialog   --->
 <mat-dialog-content  *ngIf="expertMode;else stepMode" id="show-expert" style="overflow-y: auto !important; overflow-x: auto !important">
  <div style="margin-top: 0.7rem; margin-bottom: 0.5rem; color: #1A567A;">1. Select or Create a Group
  </div>
  <div style="margin-left: 1rem; border-top: 1px solid #1A567A;"></div>
  <div style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center">
    <ng-container *ngTemplateOutlet="groupSelector"
		  style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 80% !important"></ng-container>
  </div>
  <div style="margin-top: 0rem; color: #1A567A;">2. Add One or More Users to the Group
  </div>
      <ng-container *ngTemplateOutlet="addMembers"
		  style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important"></ng-container>

  <div style="margin-top: 1rem; color: #1A567A;">3. Add Files to the Group
	 </div>
  <div style="margin-left: 1rem; border-top: 1px solid #1A567A;">
     <ng-container *ngTemplateOutlet="addFiles"></ng-container>
	    <div style="font-size: 0.7rem; margin-top: 0.5rem">Click the ADD FILES button to add files to the {{groupName}} group</div>
  </div>
  <div style="margin-left: 1rem; border-top: 1px solid #1A567A;">
    <div style="font-size: 0.7rem; margin-top: 0.5rem">Now refer to the TOOLS section of the right menu panel to drag and drop the markers onto the document to begin the markup process.</div>
  </div>
</mat-dialog-content> <!--- expert mode --->

 <!--- step mode --->
<ng-template #stepMode>
  <mat-dialog-content id="show-steps" style="overflow-y: auto !important; overflow-x: auto !important">
<mat-vertical-stepper [linear]="isLinear" #stepper>
  <mat-step>
    <ng-template matStepLabel>Welcome & Overview</ng-template>
    <p>Welcome to Markadoc <span *ngIf="userInfo">{{userInfo.name}}</span>!<p>
    <div style="font-size=0.85rem">Using this <b>Quick Start</b> utility you will <b>Select or Create A Group</b>, then <b>Add Users</b> (the utility will automatically create invitations as your Guest Accounts (Client/Associate Accounts in Professional) if they are not already Markadoc Users) and then let you <b>Add files</b> to the Group. Once you complete these steps you can then select a file from the left menu panel and start your document mark-up and collaboration by dragging and dropping a marker from the TOOLS section on the right menu panel. If you are ready to begin click or tap the Next button below. Also note that you can jump to expert mode by using the toggle at the bottom of the panel or exit this utility at any time with the <b>DONE</b> button.</div>
   <div>
     <button mat-button matStepperNext style="font-size: 1rem;">Next</button>
   </div>
  </mat-step>  
  <mat-step [stepControl]="selectedGroup !== null && selectedGroup !== ''">
    <ng-template matStepLabel>Create or Select a Group</ng-template>
    <ng-container *ngTemplateOutlet="groupSelector"
		  style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 80% !important"></ng-container>
    <div style="margin-left: 2rem; font-size: 0.9rem">Use the controls above to select or create a group. In order to add members to a group you need to be the owner or administrator of the group. Groups that have the &#9733; symbol next to them are the only groups you can add members to. They indicate the groups you own or have administrator rights to. You can upload documents to all groups on the list so if you just want to add files to a group you can skip the <b>Add Users</b> step and click or tap on the <b>Add Files</b> step to jump ahead and add the files or use the <b>Add / Upload Files</b> button next time from the left menu panel for the same task.</div>
 <div>
  <button mat-button matStepperPrevious style="font-size: 1.0rem;">Back</button>
  <button mat-button matStepperNext style="font-size: 1.0rem;">Next</button>
 </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Add Users</ng-template>
    <ng-container *ngTemplateOutlet="addMembers"></ng-container>
    <div style="margin-left: 1rem; font-size: 0.95rem">Use the <b>Add User</b> tab if you want to add the person associated with that email as a member of the group. If they are not already a registered Markadoc user this utility will add them as a Guest sub-account for you before adding them as a group member. You can also maintain Guest accounts from your account setup window by tapping your name <span *ngIf="userInfo">({{userInfo.name}})</span> on the lower left menu panel in the main app window. Click/Tap the <b>Add Many Users</b> tab if you want to enter or paste a list of comma or ; delimited email addresses and add them all at once.</div>
 <div>
  <button mat-button matStepperPrevious style="font-size: 1.0rem;">Back</button>
  <button mat-button matStepperNext style="font-size: 1.0rem;">Next</button>
 </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Add Files</ng-template>
    <ng-container *ngTemplateOutlet="addFiles"></ng-container><br>
    <div style="margin-left: 1rem; font-size: 0.95rem">Click/Tap on the <b>Add Files</b> button above to open the dialog that will allow you to select and upload files from your device to Markadoc, then follow the directions on how to select files. This button is also available on the upper left menu panel of the main Markadoc app window. Markadoc supports adding PDF format files as well as PNG and JPEG images for markup. The upload utility will also merge multiple files (or photos/images) into a single file if needed. For other file formats you can typically print/save or convert your files online to one if these three formats before uploading to Markadoc. More supported file formats may be announced in the future. Give it a try if you'd like and then we will finish up here.</div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Markers</ng-template>
    <div>Thats it <span *ngIf="userInfo">{{userInfo.name}}</span>. If you completed these steps successfully you can now exit this utility using the <b>DONE</b> button below. Using the TOOLS section on the right menu panel you can drag and drop markers onto the loaded document adding a title and description. You will also notice the marker show up in the MARKERS section on the right menu panel after you tap the Add Marker button.<BR>
      <BR>Remember you can also use this utility without the Tutorial Mode next time to run through these steps quickly. After returning to the main window you will can click on a file name in recent files or find it under the group you uploaded the file to. After the document loads you can use the right menu panel to drag and drop markers onto the document to note and track questions, comments or issues and begin the collaboration process.</div>
    <div>    
      <button mat-button (click)="stepper.reset()">Start Over</button>      
      <button mat-button matStepperPrevious>Back</button>
    </div>
  </mat-step>
</mat-vertical-stepper>
</mat-dialog-content> <!--- step mode --->   
</ng-template>
<mat-dialog-actions style="bottom: 0; border-top: 1px solid #1A567A; margin-top: 1rem">
 <div id="actions" style="display:flex; flex-flow: column;">
  <div id="step-message" style="display: block; font-size=0.7rem: margin-bottom: 0.5rem;">{{stepMessage}}</div>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center">

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%;">CLOSE</span>    
  </button>
      <mat-label style="font-size: 0.9rem; margin-left: 1rem">Tutorial Mode:</mat-label>

      <mat-slide-toggle class="add-margin-toggle"
		  style="font-size: 0.9rem"

		  [(ngModel)]="expertMode"
	          [ngModelOptions]="{standalone: true}"
                  color='primary'
		  >
	<span *ngIf="expertMode">Off</span>
	<span *ngIf="!expertMode">On</span>	
       </mat-slide-toggle>
  
 </div>
 </div>
</mat-dialog-actions>
