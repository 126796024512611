import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NoteData } from '../model/notedata.model';
import { LayerData } from '../model/layerdata.model';

@Injectable({
  providedIn: 'root'
})
export class LayerService {

  private LAYER_DATA: LayerData;

  private layerDataUpdates: Subject<LayerData> = new Subject<LayerData>();

  noteData = {
      note: [],
      question: [],
      issue: [],
      approval: []
  };
  oldNoteData = {
      note: [],
      question: [],
      issue: [],
      approval: []      
  };

   constructor(
     private http: HttpClient,
     private toast: ToasterService,
   ) { }
   
   async sendLayerDataUpdate(layerData: LayerData) {
      this.layerDataUpdates.next(layerData);
   }
   clearNotedataUpdates() {
      this.layerDataUpdates.next(null);
   }
   getLayerDataUpdates(): Observable<any> {
      return this.layerDataUpdates.asObservable();
   }

   setLayerData(n) {
     this.LAYER_DATA = n;
     this.sendLayerDataUpdate(this.LAYER_DATA);
   }

   getStoredLayerData() {
     return this.LAYER_DATA;
   }

   getLayerPromise(luid: string): Promise<LayerData> {
        if (!luid) { return; }
        console.log('layersvc getLayerPromise luid=', luid);   
        return this.http.get(environment.apiUrl + '/layer/' + luid).toPromise().then(
            (x: LayerData) => {
                // console.log('x=', x);
                // Get this users layer data
                // And add approval array if missing
                const l = JSON.parse(x.layer_data);             
                if ( ! l.approval ) {
                      l.approval = [];
                      x.layer_data = JSON.stringify(l);
                }
                return x as LayerData;
            },
            (e) => {
                console.log('layersvc failed to get layer:', e);
                throw e;
            }
        );
   }

   async getLayer(luid: string): Promise<LayerData> {
        console.log('markersvc getLayer luid=', luid);
        let layer: LayerData;
        try {
          layer = await this.getLayerPromise(luid);
        } catch (e) {
            console.log('layersvc: getLayer error=', e);
            throw e;
        }
        return layer;
   }

   async loadLayerData(duid: string): Promise<LayerData[]> {
        //console.log('markersvc getLayer duid=', duid);
        let layers: LayerData[] = [];
        try {
          layers = await this.loadLayerDataPromise(duid);
        } catch (e) {
            console.log('merkersvc: loadLayerData error=', e);
            throw e;
        }
        return layers;
    }

    loadLayerDataPromise(duid: string, force = false) {
        // duid is the document uid
        if (!duid) { return; }
        return this.http.get(environment.apiUrl + '/layer/docid/' + duid)
                        .toPromise()
                        .then(
            (x: LayerData[]) => {
                // Get this users layer data
                // And add approval array if missing
                x.forEach( (layer, i) => {
                   const l = JSON.parse(layer.layer_data);
                   if ( ! l.approval ) {
                      l.approval = [];
                      layer.layer_data = JSON.stringify(l);
                   }
                });
                return x as LayerData[];
            },
            (e) => {
                console.log('ERROR: ', e);
                throw e;
            }
        );
    }

   async addLayerData( ndata ) {
      if ( ndata == null ) {
        console.error('layersvc addLayerData Error', 'No marker noteData data provided or is null');
      }
      try {
         const x = await this.addLayerDataPromise( ndata );
         return x;
      } catch(e) {
           console.log('layersvc addLayerData ERROR: ', e);
           throw e;
      }
   }
   
   addLayerDataPromise( ndata ) {
      // ndata is NoteData, returns layeruid
      if ( ndata == null ) {
        console.error('layersvc addLayerData Error', 'No marker noteData data provided or is null');
      }
      return this.http.put(environment.apiUrl + '/layer', ndata)
                      .toPromise()
                      .then (
          (lx: LayerData) => {
               return lx.uid;
          },
          (e) => {
               console.log('load ERROR: ', e);
               throw e;
          }
      );
   
   }

   async updateLayerData(luid, ndata ) {
      // luid is the layerData uid
      // data in the form of:
      // const data = JSON.stringify({
      //     layer_data: indLayerData,
      //     doc_orientation: this.documentsvc.pdfRotation
      // });
      
      if ( luid == null ) {
        console.error('layersvc updateLayerDataPromise Error', 'No marker noteData uid provided or is null');
      }
      if ( ndata == null ) {
        console.error('layersvc updateLayerDataPromise Error', 'No marker noteData data provided or is null');
      }
      try {
         const x = await this.updateLayerDataPromise(luid, ndata );
         console.log('layersvc updateLayerData success=', x);
         return x;
      } catch(e) {
           console.error('layersvc updateLayerData error=', e);
           throw e;
      }
   }

   async updateLayerDataPromise(luid, data) {
      if ( luid == null ) {
        console.error('layersvc updateLayerDataPromise Error', 'No marker noteData uid provided or is null');
      }
      if ( data == null ) {
        console.error('layersvc updateLayerDataPromise Error', 'No marker noteData data provided or is null');
      }
      return this.http.post(environment.apiUrl + '/layer/' + luid, data)
               .toPromise()
               .then (
                   (x) => { console.log(x);
                            return x;
                   },
                   (e) => { console.log(e);
                            throw(e);
                          }
                );
   }
   
} // LayerService
