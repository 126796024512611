/* eslint-disable */
/* org_user_id is org assigned emp_id or guest_id */
export class OrgUserData {
  uid: string;
  org_uid: string;
  org_user_uid: string;
  org_user_email: string;  
  org_user_type: string;
  org_user_active: boolean;
  org_user_emp_id: string;
  org_admin: boolean;
  org_billing_admin: boolean;
  org_user_role: string;
  org_user_subscription_id?: string;  
  org_user_expire_by_date: boolean;
  org_user_expire_by_last_group: boolean
  org_user_expires: string;
  created?: string;  
  modified?: string;
  modified_by?: string;  
  timestamp?: number;
}
