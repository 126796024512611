import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NameFilter' })
export class NameFilter implements PipeTransform {
  public transform(items: any[], searchText: any): any {
    if (searchText == null || items == null) {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  }
}
