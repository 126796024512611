<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon style="padding-top: 0.2rem; margin-left: 0; padding-left: 0;">drag_indicator</mat-icon>
    <span class="title" style="margin-left: 0.2rem" >Upload Files</span>
  </div>
  <div class="dialog-header-right">
   <mat-icon (click)="helpDialog()"
             matTooltip="User Feedback Help"
             >help_outline</mat-icon>
   <mat-icon (click)="closeUploadDialog()"
             matTooltip="Close Upload Dialog"
	     style="margin-left: 0.5rem; align: right">close</mat-icon>
  </div>
</div>

<!-- upload files Dialog -->

<mat-dialog-content style=""> 
        <app-upload-files #uploadFiles
			  style="overflow: none; margin-left: 1rem; margin-right: 1rem; max-width: 100%;"
           [groups]=this.groups
           [userInfo]=this.userInfo
           [selectedGroup]=this.selectedGroup
           [selectedOrganization]=this.selectedOrganization
           [selectedOrgUnit]=this.selectedOrgUnit
           [selectedImageSize]=this.selectedImageSize
           [selectedImageRotation]=this.selectedImageRotation
           [selectedAddMargins]=this.selectedAddMargins
           (updateDocListEvent)="updateDocListEvent.emit($event);"
           (saveEvent)="saveUploadDialog($event)"
           (closeEvent)="closeUploadDialog();"
           (backEvent)="closeUploadDialog();"
           (createGroupEvent)="createGroupDialog();"			  
           >
      </app-upload-files>

</mat-dialog-content>

<!--
 <mat-dialog-actions>
  <div id="upload-message" style="display: block; font-size=0.8rem: margin-bottom: 0.3rem;"><small>
	   {{uploadMessage}}</small>
  </div>
       <mat-progress-bar id="merge-progress-bar"
			 class="upload-progress"
			 style="background: #00ff00; foreground: #00ff00; margin-bottom: 0.5rem;"
			 mode="determinate"
			 [value]="uploadProgress.message"></mat-progress-bar>
    <div style="display: flex; flex-flow row; justify-content: space-between; align-items: center">
    <div style="display: flex; flex-flow row; justify-content: flex-start; align-items: center">
              <button mat-raised-button
	               class="upload-action-button"			
		       (click)='handleFileUploads()'
                       [disabled]="!selectedGroup || !fileData"
		       id="add-to-button">
                      <span>{{uploadBtnTitle}}</span>
		      <mat-icon style="display: inline-block;">upload_file
		      </mat-icon>
	      </button>
              <button mat-raised-button *ngIf="showMergeBtn"
                      (click)='handleMergeAndUpload()'
	              class="upload-action-button"
                     [disabled]="!selectedGroup || !fileData || !mergeFileName"
		      ><span style="font-size: 1.0em">MERGE & UPLOAD TO FILE</span>
		      <mat-icon style="display: inline-block;">merge_type
		      </mat-icon>
	      </button>
   </div>
   <div style="display: flex; flex-flow row; justify-content: flex-end; align-items: center">
              <button mat-raised-button class="bottom-buttons"
		      style="align-self: flex-end;"
		      (click)="closeUploadDialog()">Cancel</button>
   </div>
   </div>

</mat-dialog-actions>
 -->
