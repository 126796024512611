<div id="feedback-container" >
  <div id='feedback-content'>
    <p class="question">Do you have some feedback or have run into issue?</p>
    <p class="question">Let us know below.</p>          
    <form class="feedback-form">
      <p class="question">1. How was your overall experience?</p>
        <div class="wrapper">
            <label>
                <input type="radio" name="experience" [(ngModel)]="feedbackData.experience" value="4">
                <div class="button" matToolTip="Excellent"><span class="material-icons" id="icons">
                    sentiment_satisfied_alt
                </span></div>
		<p class="question" style="margin-left: 0rem; padding: 0">
		Excellent</p>
            </label>
            <label>
                <input type="radio" name="experience" [(ngModel)]="feedbackData.experience" value="3">
                <div class="button" matToolTip="Good"><span class="material-icons" id="icons">
                    sentiment_satisfied
                </span></div>
		<p class="question" style="margin-left: 0.7rem; padding: 0">
		Good</p>
            </label>
            <label>
                <input type="radio" name="experience" [(ngModel)]="feedbackData.experience" value="2">
                <div class="button" matToolTip="Fair"><span class="material-icons" id="icons">
                    sentiment_dissatisfied
                </span></div>
		<p class="question" style="margin-left: 0.9rem; padding: 0">
		Fair</p>
            </label>
            <label>
                <input type="radio" name="experience" [(ngModel)]="feedbackData.experience" value="1">
                <div class="button" matToolTip="Poor"><span class="material-icons" id="icons">sentiment_very_dissatisfied</span></div>
		<p class="question" style="margin-left: 0.8rem; padding: 0">
		Poor</p>
            </label>
        </div>

        <p class="question">2. What industry do you work in?</p>
        <div class="wrapper">
            <label (click)="cancelOther()">
                <input type="radio" name="industry" [(ngModel)]="feedbackData.industry" value="Engineering">
                <div class="button2">Engineering</div>
            </label>
            <label (click)="cancelOther()">
                <input type="radio" name="industry" [(ngModel)]="feedbackData.industry" value="Real Estate">
                <div class="button2">Real Estate</div>
            </label>
            <label (click)="cancelOther()">
                <input type="radio" name="industry" [(ngModel)]="feedbackData.industry" value="Marketing">
                <div class="button2">Marketing</div>
            </label>
            <label (click)="otherField()">
                <input type="radio" name="industry" >
                <div class="button2">Other</div>
            </label>
            <!-- <input type="text" name="industry" [(ngModel)]="feedbackData.industry" id="other-text-field" value=""> -->
        </div>
        <input type="text" name="industry" [(ngModel)]="feedbackData.industry" id="other-text-field" value="">

        <p class="question">3. How likely are you to recommend markadoc &copy; to another person in your industry? </p>
        <div class="wrapper">
            <label>
                <input type="radio" name="recommend" [(ngModel)]="feedbackData.recommend" value="Very Likely">
                <div class="button2">Very Likely</div>
            </label>
            <label>
                <input type="radio" name="recommend" [(ngModel)]="feedbackData.recommend" value="Likely">
                <div class="button2">Likely</div>
            </label>
            <label>
                <input type="radio" name="recommend" [(ngModel)]="feedbackData.recommend" value="Possible">
                <div class="button2">Possible</div>
            </label>
            <label>
                <input type="radio" name="recommend" [(ngModel)]="feedbackData.recommend" value="Unlikely">
                <div class="button2">Unlikely</div>
            </label>
        </div>
        
        <p class="question">4. Any other suggestions to improve our product?</p>
        <div class="wrapper">
           <textarea id="issues" name="issues" rows="4" cols="80" [(ngModel)]="feedbackData.suggest"></textarea>
        </div>
        <div class="div-submit">
            <input type="submit" (click)="send_feedback()" value="Submit" >
        </div>

        </form>
    </div>
</div>
