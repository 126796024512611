import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { DocumentData } from '../model/documentdata.model';
import { environment } from '../../environments/environment';
import { GroupData} from '../model/groupdata.model';
import { DocumentService } from '../service/document.service';
import { GroupService } from '../service/group.service';
import { MailService } from '../service/mail.service';


@Component({
    selector: 'app-send-data',
    templateUrl: './send-data.component.html',
    styleUrls: ['./send-data.component.css']
})
export class SendDataComponent implements OnInit {
    shown = 'block';
    recipientEmails: any[] = [{ email: '' }];

    @Input() overlayData;
    @Input() activeDocument;
    @Input() activeUser;

    documentData: DocumentData;
    groupData: GroupData;

    constructor(
        private http: HttpClient,
        private toast: ToasterService,
        private documentsvc: DocumentService,
        private groupsvc: GroupService,
        private mailsvc: MailService
    ) {
    }

    ngOnInit() {
      this.show_notes(true);
      console.log('send-data activeDocument=', this.activeDocument);
    }

    remove_recipient(index) {
        this.recipientEmails.splice(index, 1);
    }

    update_recipients() {
        if (this.recipientEmails.length === 1 || this.recipientEmails[this.recipientEmails.length - 1].email !== '') {
            this.recipientEmails.push({email: ''});
    //        document.getElementById('header-color').style.backgroundColor = '#1E9A6D';
        }
    }

    async show_notes(ev) {
        /***
        if (this.shown === 'block') {
            this.shown = 'none';
        } else {
            this.shown = 'block';
        }
        ****/
        document.getElementById('confirm-content').style.display = 'none';
        document.getElementById('error-content').style.display = 'none';
        document.getElementById('content_1').style.display = 'block';

        // console.log("the name" +this.document.name);
        document.getElementById('share-container').style.display = this.shown;
        document.getElementById('share-container').style.top = document.querySelector('.share-container').scrollTop + 'px';
        try {
          this.documentData = await this.documentsvc.getDocumentData( this.activeDocument );
          console.log('this.document=', this.documentData);
        } catch (de) {
            console.log('getDocument error=', de);
          }
        try {
           this.groupData = await this.groupsvc.getGroup(this.documentData.groups_uid);
           console.log('this.groupData=', this.groupData);
        } catch (ge) {
             console.log('getGroup error=', ge);
          }
    }

    close() {
        this.shown = 'none';
        document.getElementById('share-container').style.display = this.shown;
//        document.getElementById('header-color').style.backgroundColor = '#2274A5';
        document.getElementById('confirm-content').style.display = 'none';
        document.getElementById('error-content').style.display = 'none';
        document.getElementById('content_1').style.display = 'block';
    }

    _send_notes() {
        const data = JSON.stringify({
            users_uid: this.activeUser,
            documents_uid: this.activeDocument,
            layer_data: this.overlayData,
            document_data: this.documentData,
            group_data: this.groupData
        });

        // const emails = Array.from(document.querySelectorAll('.email-input'))
        //     .map(x => (x as HTMLInputElement).value)
        //     .filter(x => x.length > 0);
        const emails = this.recipientEmails
             .map(x => x.email);

        if (emails.length > 0 ){
            document.getElementById('confirm-content').style.display = 'none';
            document.getElementById('error-content').style.display = 'none';
            document.getElementById('content_1').style.display = 'none';
            document.getElementById('send-animation-icon').style.transform = 'rotate(390deg)';
        }

        emails.forEach(
          async (email) => {
             try {
                const x = await this.mailsvc.sendDocumentTokenPromise(email,
                          this.activeDocument);
                // this.shown = 'none';
                // const el = document.getElementById('share-container');
                // if (el?.style.display) {
                //   el.style.display = this.shown;
                // }
                document.getElementById('send-animation-icon').style.transform = 'rotate(390deg)';
                this.toast.pop('success', 'One Time Token',
                               'Token(s) sent successfully.');
                this.recipientEmails = [{ email: '' }];
                document.getElementById('confirm-content').style.display = 'block';
             } catch (e) {
                  this.toast.pop('error', 'One Time Token',
                                 'Failed to send token(s).');
                  document.getElementById('error-content').style.display = 'block';
               }
         }
       );
   }
}
