import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit,
         OnDestroy, Renderer2, ElementRef, ViewChild, AfterViewInit,
         HostListener, Input, Output, EventEmitter, ViewContainerRef,
         ComponentRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Location, LocationStrategy, ViewportScroller } from '@angular/common';
import { CdkDragDrop, CdkDragEnd, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from '../service/toaster.service';
import { Router, ActivatedRoute, ParamMap, NavigationStart, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { pdfDefaultOptions, IPDFViewerApplication, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { FindState, FindResultMatchesCount } from 'ngx-extended-pdf-viewer';

import { Observable } from 'rxjs';
// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { UserListComponent } from '../user-list/user-list.component';
import { MouseTrack } from '../model/mouse-track.model';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
// import { OrgData } from '../model/orgdata.model';
import { DocumentData } from '../model/documentdata.model';
import { LayerData } from '../model/layerdata.model';
import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerUIData } from '../model/markeruidata.model';
// import { MarkerComment } from '../model/markercomment.model';
import { GroupData } from '../model/groupdata.model';
import { RawLayerData } from '../model/rawlayerdata.model';

import { AuthService } from '../service/auth.service';
import { GroupService } from '../service/group.service';
import { NotifyQueueService } from '../service/notify-queue.service';

// Capacitor plugins
import { App as CapacitorApp } from '@capacitor/app';
import { Share, ShareResult, CanShareResult } from '@capacitor/share';
import { Network } from '@capacitor/network';
import { Clipboard } from '@capacitor/clipboard';

// 56-248 CJ - FileSelector constants added
import { FileSelector } from '../model/fileselector.model';

// 56-248 CJ Added Dialogs for Convert/Upload
import { UploadFilesDialogComponent } from '../dialog/upload-files/upload-files.dialog';
import { FormDialogComponent } from '../dialog/form/form.dialog';
import { GroupNameDialogComponent } from '../dialog/group-name/group-name.dialog';
import { ActionPromptDialogComponent } from '../dialog/action-prompt/action-prompt.dialog';
import { ProgressDialogComponent } from '../dialog/progress/progress.dialog';

// 279 - CJ - Group Manager
import { GroupMemberService } from '../service/group-member.service';
import { UserService } from '../service/user.service';
import { HelpService } from '../service/help.service';
import { LayerService } from '../service/layer.service';
import { MarkerService } from '../service/marker.service';
import { DocumentService } from '../service/document.service';
import { PdfService } from '../service/pdf.service';
import { HyperlinkService } from '../service/hyperlink.service';

import { PlatformService } from '../service/platform.service';
import { AdminService } from '../service/admin.service';
import { OrganizationService } from '../service/organization.service';
import { OrgUserService } from '../service/orguser.service';
import { OrgUserInfoService } from '../service/orguserinfo.service';

import { GroupFileListComponent } from '../group/group-file-list/group-file-list.component';
import { GroupMemberListComponent } from '../group-member-list/group-member-list.component';
import { GroupManagerDialogComponent } from '../dialog/group-manager/group-manager.dialog';

import { FileInfoDialogComponent } from '../dialog/file-info/file-info.dialog';
import { CreateLinkDialogComponent } from '../dialog/create-link/create-link.dialog';
import { UserInfoDialogComponent } from '../dialog/user-info/user-info.dialog';
import { UserSettingsDialogComponent } from '../dialog/user-settings/user-settings.dialog';
import { UserSubAccountsDialogComponent } from '../dialog/user-subaccounts/user-subaccounts.dialog';
import { HelpDialogComponent } from '../dialog/help/help.dialog';

import { AboutDialogComponent } from '../dialog/about/about.dialog';

import { QuickStartDialogComponent } from '../dialog/quick-start/quick-start.dialog';

import { MarkerComponent } from '../marker/marker.component';
import { MarkerDialogComponent } from '../marker-dialog/marker-dialog.component';
import { MarkerListComponent } from '../marker-list/marker-list.component';
import { MarkerReportDialogComponent } from '../dialog/marker-report/marker-report.dialog';
import { FeedbackDialogComponent } from '../dialog/feedback/feedback.dialog';
import { SendTokenDialogComponent } from '../dialog/send-token/send-token.dialog';

// Issue 243 pageData
import { pageData } from '../model/pagedata.model';

import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { NewUserSignupComponent } from '../new-user-signup/new-user-signup.component';
import { PrintSheetComponent } from '../sheet/print/print-sheet.component';
import { DownloadSheetComponent } from '../sheet/download/download-sheet.component';
// import { FindSheetComponent } from '../sheet/find/find-sheet.component';

import { environment } from '../../environments/environment';
import { UpperCasePipe } from '@angular/common';

import * as FSC from '../model/fileselector.constants';
import * as UFD from '../dialog/upload-files/upload-files.dialog';
import * as STATIC from '../mdtools/statics.common';

import * as Marker from '../marker/marker.component';
import * as MarkerDialog from '../marker-dialog/marker-dialog.component';

import * as PLATFORM from '../service/platform.service';
import * as HELP from '../service/help.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MainComponent implements OnInit, AfterViewInit, OnDestroy {

    DisplayModel: typeof STATIC.Model = STATIC.Model;
    DisplayMode: typeof STATIC.Mode = STATIC.Mode;
    DisplayTemplate: typeof STATIC.Template = STATIC.Template;

    @Input() platformType: string;
    @Input() deviceType: string;
    @Input() platformOS: string;
    @Input() platformENV: string;
    @Input() uiMode: string;
    @Input() documents: DocumentData[];

/****
//    @Input() pdfURL: any;
//    @Input() pdfSrc: any;
//    @Input() pdfZoom: any;
//    @Input() pdfZoomPct: any;

    @Input() pdfPageNbr: any;
    @Input()
      get pdfPageNbr() { return this.documentsvc.pdfPageNbr; }
      set pdfPageNbr(pg: number) {

      this.documentsvc.pdfPageNbr = pg;
      // console.log('set pdfPageNbr=', this.documentsvc.pdfPageNbr);
    }
 ****/
//    @Input() pdfRotation: any;
//    @Input() pdfProgress: number;
//    @Input() snapRotation: any;
//    @Input() activeDocument: any;

    @Output() uiModeChange: EventEmitter<string> = new EventEmitter();
    @Output() groupListEvent: EventEmitter<any> = new EventEmitter();

    // public pdfPageNbr = 1;

    _searchText  = '';
    _searchText2 = '';
    _searchText3 = '';

    // set/get for search functions
    public set searchText(text: string) {
      if (this.ngxPdfService.find(
              text,
              { highlightAll: true,
                matchCase: false,
                wholeWords: false,
                ignoreAccents: true})) {
       this._searchText = text;
      }
   }

   public get searchText(): string {
     return this._searchText;
   }

   public get findStateText(): string {
     switch (this.findState) {
       case FindState.FOUND:
         return 'found';
       case FindState.NOT_FOUND:
         return 'not found';
       case FindState.PENDING:
         return 'pending';
       case FindState.WRAPPED:
         return 'wrapped';
     }
     return '';
   }

    // 350 - CJ These need to default to static:false to use
    // templates for ui mode.
    @ViewChild('sidenavleft', {static: false}) sidenavleft;
    @ViewChild('sidenavright', {static: false}) sidenavright;
    @ViewChild('sidenavcontent', {static: false}) sidenavcontent;
    @ViewChild('markerRpt', {static: false}) markerRpt;
    @ViewChild('printDiv', {static: false}) printDiv;
    @ViewChild('printImg', {static: false}) printImg;
    @ViewChild('maincontent', {static: false}) maincontent;
    @ViewChild('maincontainer', {static: false}) maincontainer;
    @ViewChild('contentlayer', {static: false}) contentlayer;
    @ViewChild('contentoverlay', {static: false}) contentoverlay;
    @ViewChild('toptoolbar', {static: false}) toptoolbar;
    @ViewChild('tbrow1', {static: false}) tbrow1;
    @ViewChild('tbrow2', {static: false}) tbrow2;
    @ViewChild('fileInput', {static: false}) fileInput;
    @ViewChild('passwordReset', {static: false}) pwdreset;
    @ViewChild('userSignup', {static: false}) userSignup;
    @ViewChild('onboarding', {static: false}) onboarding;
    @ViewChild('tosAccept', {static: false}) tosAccept;
    @ViewChild(NewUserSignupComponent, {static: false}) newuser;
    @ViewChild('pdf', {static: false}) pdf;
    @ViewChild('progress', {static: false}) progress;
    @ViewChild('userList', {static: false}) userList;
    @ViewChild(UserListComponent, {static: false}) userListObj;
    @ViewChild('orgList', {static: false}) orgList;
    @ViewChild('OrgListComponent', {static: false}) orgListObj;
    @ViewChild('groupMgr', {static: false}) groupMgr;    
    @ViewChild('grpFileList', {static: false}) grpFileList: GroupFileListComponent;
    @ViewChild('grpMemberList', {static: false}) grpMemberList: GroupMemberListComponent;    
    // the ViewContainerRef is used to create dynamic components
    @ViewChild('contentoverlay', { read: ViewContainerRef })
    overlayRef: ViewContainerRef;

    // markerRef: ComponentRef<MarkerComponent>;

    title = 'markadoc';
    headTop = 2;
    // Should get the actual client with from the classes
    headerWidth = 'calc(100% - 120px - 200px)';

    // Screen vars to be updated onResize()
    maxPageWidth = 288;
    overlayWidth = 1056;
    screenWidth = 100;
    screenHeight = 100;

    // **** MOBILE UI VARIABLES

    NAV_MAIN_MENU = 'Home';
    NAV_FILES = 'Files';
    NAV_RECENTS = 'Recent Files';
    NAV_ORG_TAB = 'Company/Organization';
    NAV_ORG_LIST = 'Company/Organization List';
    NAV_ORG_TABLE = 'Company/Organization Table';
    NAV_ORG_SELECT = 'Select Company/Organization Name';
    NAV_ORG_COMBO = 'Enter/Select Company/Organization Name';
    NAV_USR_ORG_SELECT = 'Company/Org.';
    NAV_ORG_EXPAND_LIST = 'Select Company/Org. List';
    NAV_GROUP = 'Group';
    NAV_GROUP_LIST = 'Groups';
    NAV_ADD_GROUP_MEMBER = 'Add Group Member';
    NAV_ADD_GROUP_MEMBERS = 'Add Group Members';
    NAV_GROUP_MEMBERS = 'Group Members';
    NAV_GROUP_UPLOAD = 'Upload Group Files';
    NAV_GROUP_MANAGER = 'Group Mgr';
    NAV_QUICK_START = 'Quick Start';
    NAV_QUICK_START_1 = 'Quick Step 1 - Select Group';
    NAV_QUICK_START_2 = 'Quick Step 2 - Add Member(s)';
    NAV_QUICK_START_3 = 'Quick Step 3 - Upload File(s)';
    NAV_QUICK_START_4 = 'Quick Step 4 - Menus & Markup Tools';
    NAV_SETUP = 'Setup/Utilities';
    NAV_SETUP_GROUP_MGR = 'Group Mgr';
    NAV_PREFERENCES = 'Preferences';
    NAV_UPLOAD_FILES = 'Upload Files';
    NAV_FEEDBACK = 'Send Feedback';
    NAV_SUBACCOUNTS = 'Guest Sub-Accounts';
    NAV_INVITE_FREE = 'Send Free User Account Invitation';
    NAV_INVITE_TOKEN = 'Send Temporary 24hr Invitation';
    NAV_USER_SETTINGS = 'My User Information';
    NAV_USER_LIST = 'User Directory';
    NAV_INVITATION = 'Send Invitation';
    NAV_ACTIONS = 'Actions';
    NAV_CREATE_LINK = 'Create A Hyperlink';
    NAV_LINK_FILE = 'Select a File to Link To';
    NAV_LINK_PAGE = 'Enter a Page Nbr to Link To';
    NAV_LINK_MARKER = 'Select a Marker to Link To';
    NAV_LINK_EXTERNAL = 'Create External Link';
    NAV_LINK_INTERNAL = 'Create Internal Link';
    NAV_APPROVAL_REQUEST = 'Approval Requests';

    RNAV_MENU = 'TOOLS';
    RNAV_MARKER_TOOLS = 'MARKUP';
    RNAV_FILE_TOOLS = 'FILE';
    RNAV_VIEW_TOOLS = 'VIEW';

    ACTION_MODE_TOOLS = 'TOOLS';
    ACTION_MODE_MARKUP = 'MARKUP';
    ACTION_MODE_FILE = 'FILE';
    ACTION_MODE_VIEW = 'VIEW';

    leftNavMode = this.NAV_MAIN_MENU;
    leftNavText = '';
    rightNavMode = this.RNAV_MENU;
    rightNavText = '';
    showActionBar = false;
    actionMode = this.ACTION_MODE_TOOLS;
    showSidenavHelp = true;
    titleFilter = "";
    showTitleFilter = false;
    showGroupEditBtn = false;
    showGroupSearchBtn = false;
    showAllGroups = true;
    groupTabIndex = 0;
    helpContext = 0;

    // **** create Link component
    linkOrigin = '';
    linkTitle = '';
    linkDocumentData: DocumentData = null;
    linkLayerData: LayerData[] = null;
    linkNoteData: NoteData = null;
    linkMarkerData: MarkerData = null;
    linkSrcData: MarkerData = null;
    linkPageNbr = 0;
    linkURL = '';

    loadPageNbr = null;
    loadNoteID = null;
    loadGroupID: string = null;

    approvalMarkerData: MarkerData = null;

    activeDocumentLink = '';
    activeDocumentName = '';
    groupName: string;
    groupUID: string;
    groupMemberCnt = 0;

    // 56-248, 279 CJ Ref to upload & group create mat-dialogs
    bottomSheetRef: any = null;
    uploadDialogRef: any = null;
    quickStartDialogRef: any = null;
// 279 - CJ - Group Manager
    groupManagerDialogRef: any = null;
    formDialogRef: any = null;
    groupNameDialogRef: any = null;
    orgTabDialogRef: any = null;
    orgUserDialogRef: any = null;
    orgContactDialogRef: any = null;
    actionPromptDialogRef: any = null;
    progressDialogRef: any = null;
    userSettingsDialogRef: any = null;
    userSubAccountsDialogRef: any = null;
    userInfoDialogRef: any = null;
    fileInfoDialogRef: any = null;
    createLinkDialogRef: any = null;
    helpDialogRef: any = null;
    aboutDialogRef: any = null;
    markerReportDialogRef: any = null;
    feedbackDialogRef: any = null;
    sendTokenDialogRef: any = null;
    baseURL: string = null;
    paymentURL = 'https://markadoc.com/my-account';

    // 279 CJ - UserGroupList subscription
    userGroupListSubscription: Subscription;
    selectedGroupSubscription: Subscription;
    userSubAccounts = 0;

    orgListSubscription: Subscription;
    noteDataSubscription: Subscription;

    // C. Janick - Iss#243 These 3 Needed for PDF page # and coordinates
    pageHash: pageData;
    pdfPageInfo: pageData[] = [];
    lastNoteId     = null;

    // need to implement models for these
    noteData = {
        note: [],
        question: [],
        issue: [],
        approval: []
    };
    oldNoteData = {
        note: [],
        question: [],
        issue: [],
        approval: []
    };
    noteDialogs = {
        note: [],
        question: [],
        issue: [],
        approval: []
    }
    noteMarkers = {
        note: [],
        question: [],
        issue: [],
        approval: []
    }
    userData: UserData;
    userInfo: UserInfo;
    userOrgList: any[];
    layerUid: string;
    layerOffset: MouseTrack = new MouseTrack();

//    activeDocument: string;
    printImgSrc: any;
    printMarkerRpt = false;
    viewerContainer: any;
    PDFDocumentTransport: any;
    PDFViewerApplication: IPDFViewerApplication;
    // pdfProgress = 0;
    // pageCount = 0;
    updateInterval: any;
    panelOpenState: boolean;
//    newGroupData: GroupData[] = [];
    groups$: Observable<GroupData[]>;
    groups: GroupData[] = [];
    isMembersVisible: boolean ;
    groupMembers = [];
    memberUID: string;
    toggleState: boolean;
    toggleStateQ: boolean;
    toggleStateI: boolean;
    toggleLeftNav: boolean;
    toggleRightNav: boolean;
    toggleFiber: boolean;
    toggleFiber1: boolean;
    toggleFiber2: boolean;
    toggleTools: boolean;
    toggleMarkup: boolean;
    toggleFileTools: boolean;
    toggleViewTools: boolean;
    toggleActions = true;
    reloadLayers = false;
    ishiding: boolean;
    eyeIcon: boolean;
    commentIcon: boolean;
    groupMgrTabIndex: number;
    groupMgrMember: number;
    showingComments: boolean;
    showingRecents: boolean;
    showingFileTools: boolean;
    showFindTool = false;
    showPageTool = false;
    showZoomTool = false;
    showHandTool = true;
    pdfViewerZIndex = 100;

    displayTools = 'block';
    appSharePlugin = false;

    public searchResultPages: Array<number>;
    public fuzzy = false;
    public highlightAll = false;
    public currentPage = false;
    private _pageRange = '';
    public matchCase = false;
    public wholeWord = false;
    public ignoreAccents = true;
    public multipleSearchTerms = false;
    findState: any;
    currentMatchNumber = 0;
    totalMatches = 0;

    // Default tool icon size in px that gets resized by CSS
    tiWidth = 32;
    tiHeight = 40;

    userLayer: {
        note: MarkerData[],
        question: MarkerData[],
        issue: MarkerData[],
        approval: MarkerData[]
    };

    // pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    // pdfSrc = '/assets/Vehicle Schematic 27149p RevF.pdf';
    // pdfSrc = '/assets/DR Roll Threader Electrical_1.pdf';
    // pdfSrc = environment.apiUrl + '/document/d69b020a-02ab-4589-bf2c-3a5c54990130';
    // pdfSrc = {
    //     url: '',
    //     httpHeaders: {
    //         Authorization: 'Bearer ' + this.auth.getToken(),
    //         'Content-Type': 'application/json'
    //     },
    // };

// #350 - These moved to documentsvc
//
//    pdfSrc = null;
//    pdfZoom = 1.0;
//    pdfRotation = 0;
//    snapRotation = 0;

    showAdd = 'none';

// 56-248 CJ - Add to remember defaults for file upload/conversions dialog
    selectedImageSize     = FileSelector.getDefaultImageSize();
    selectedImageRotation = FileSelector.getDefaultImageRotation();
    selectedAddMargins    = FileSelector.getDefaultImageAddMargins();
    selectedOrg: any;
    selectedOrgUnit: any;
    selectedOrgUser: any;
    selectedOrgContact: any;    
    selectedDocUID: any;
    selectedDocObj: DocumentData;
    selectedGroup: any;
    selectedGroupObj: any;

// 350 CJ - mobile ui v1
    UI_DESKTOP = 'Desktop';
    UI_TABLET = 'Tablet';
    UI_MOBILE = 'Mobile';

    // uiMode = this.UI_MOBILE;

    sidenavOpen = true;
    sidenavRightOpen = false;
    groupPanelOpen = false;
    toolsPanelOpen = true;
    markerPanelOpen = true;
    imagePanelOpen = true;

    paramGroupID = null;
    paramDocID = null;
    paramMarkerID = null;
    paramNotificationID = null;
    paramRequestID = null;

// Window resize
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizeHandler(event);
    }

// mouse tracking

    @HostListener('document:pointermove', ['$event'])
    onMouseMove(e) {
        this.mouseTrack(e);
    }

// ** #243-add-page-nbr-to markups C. Janick
    @HostListener('document:mouseup', ['$event'])
    onMouseUp(e) {
        this.mouseUp(e);
    }

   constructor(
        public route: ActivatedRoute,
        public scroller: ViewportScroller,
        public toast: ToasterService,
        public render: Renderer2,
        public http: HttpClient,
        public auth: AuthService,
        public notify: NotifyQueueService,
        public documentsvc: DocumentService,
        public usersvc: UserService,
        public helpsvc: HelpService,
        public pdfsvc: PdfService,
        public groupsvc: GroupService,
        public groupmembersvc: GroupMemberService,
        public layersvc: LayerService,
        public markersvc: MarkerService,
        public linksvc: HyperlinkService,
        public platformsvc: PlatformService,
        public adminsvc: AdminService,
        public orgsvc: OrganizationService,
        public orgusersvc: OrgUserService,
        public orguserinfosvc: OrgUserInfoService,
        public fb: FormBuilder,
        public router: Router,
        public formDialog: MatDialog,
        public uploadDialog: MatDialog,
        public groupNameDialog: MatDialog,
        public orgTabDialog: MatDialog,
        public orgUserDialog: MatDialog,
        public orgContactDialog: MatDialog,     
        public quickDialog: MatDialog,
        public groupManagerDialog: MatDialog,
        public userSettingsDialog: MatDialog,
        public userSubAccountsDialog: MatDialog,
        public deleteGroupDialog: MatDialog,
        public actionPromptDialog: MatDialog,
        public progressDialog: MatDialog,
        public userInfoDialog: MatDialog,
        public fileInfoDialog: MatDialog,
        public createLinkDialog: MatDialog,
        public helpDialog: MatDialog,
        public markerReportDialog: MatDialog,
        public feedbackDialog: MatDialog,
        public sendTokenDialog: MatDialog,
        public aboutDialog: MatDialog,
        public location: Location,
        public locstrategy: LocationStrategy,
        public bottomSheet: MatBottomSheet,
        public domSanitizer: DomSanitizer,
        public ngxPdfService: NgxExtendedPdfViewerService,
        public cdr: ChangeDetectorRef,
    ) {
        // init showing comments
        this.showingComments = true;

        // Set the initial UI mode based on platform detection.
        this.uiMode = this.platformsvc.getUIMode();
        // console.log('uiMode=', this.uiMode);
        // console.log('orientation=', this.platformsvc.orientation);

        // Handle browser back button press
        history.pushState(null, null, window.location.href);
        this.baseURL = window.location.href;
        // console.log('location=', window.location.href);
        // check if back or forward button is pressed.
        this.locstrategy.onPopState( () => {
           // console.log('location pop=', window.location.href);
           history.pushState(null, null, window.location.href);
           // console.log('location pop=', window.location.href);
           // this.handleBackBtn();
        });
    }

    async ngOnInit() {
        CapacitorApp.addListener('backButton', ()=> {
           this.openLogoutDialog();
        });
        this.router.events.subscribe((event) => {
            // console.log('router event=', event);
            if (event instanceof NavigationStart) {
               console.log('locationstart=', this.location);
            }

            if (event instanceof NavigationEnd) {
               console.log('locationend=', this.location);
            }
        });

        // get the user data from the login token
        this.userData = this.auth.getTokenInfo();
        console.log('main: userData=', this.userData);

        this.selectedOrg = null;
        this.selectedOrgUser = null;

        if ( this.userData && this.userData.uid ) {
           const ouexp = await this.orguserinfosvc.getOrgUserExpirationsPromise( this.userData.uid );
           console.log('main orguser expirations=', ouexp);
        }

        /***
        const orgUserListSubscription = this.orgusersvc.orgUserList$.subscribe({
           next(oulist) {
        ***/
        const orgUserListSubscription = this.orgusersvc.orgUserList$.subscribe( oulist => {
             // console.log('org user list=: ', oulist);
             this.userOrgList = oulist;
             // this.selectedOrg = null;
             if (! this.selectedOrgUser && oulist && oulist.length > 0 ) {
                this.setSelectedOrgUser(oulist[0]);
                // this.setSelectedOrg(null);
             }
             // console.log('main orglist selectedOrg=', this.selectedOrg);
        }, err => {
                console.log('Error Getting All Org Users: ', err);
        });

        // Subscribe to OrgList$ changes so that default select value
        // can be assigned when it changes.
        this.orgListSubscription = this.orgsvc.orgList$.subscribe( val => {
             // console.log('main orglist val=', val);
             this.userOrgList = val;
             // this.selectedOrg = null;
             //console.log('main orglist val[0]=', val[0]);
             //console.log('main orglist val.length=', val.length);
             if (! this.selectedOrg && val && val.length > 0 ) {
                this.setSelectedOrg(val[0]);
             }
             // console.log('main orglist selectedOrg=', this.selectedOrg);
        });

        if ( this.userData && this.userData.uid ) {
           this.orgsvc.getUserOrganizations$( this.userData.uid );
        }

        this.orgusersvc.getAllOrgUsers$();
        //console.log('main isAppMgr=', this.userData.iam);
    }

    async ngAfterViewInit() {
        // console.log('activeDocument=', this.documentsvc.activeDocument);
        // Dont do this in spec tests (log size)
        // console.log('body=', document.body);

        this.getScreenSize();

        document.addEventListener("gesturestart", function (e) {
           e.preventDefault();
           document.body.style.transform = 'scale(1.0)';
        });

        document.addEventListener("gesturechange", function (e) {
           e.preventDefault();
           document.body.style.transform = 'scale(1.0)';
        });
        document.addEventListener("gestureend", function (e) {
           e.preventDefault();
           document.body.style.transform = 'scale(1.0)';
           // console.log('body gesture end');
        });

        // IOS toolbar scroll
        // window.scrollTo(0, 1);

        // 518 - Prevent scroll back - acts like back button
        /***
        // console.log('overlay=', this.contentoverlay);
        if ( this.contentoverlay) {
           this.contentoverlay.nativeElement.addEventListener(
           'touchstart', (e) => {
                // is not near edge of view, exit
                if (e.touches.pageX > 20 && e.touches.pageX < window.innerWidth - 20) return e;
                // prevent swipe to navigate back gesture
                // console.log("prevented default swipe e=", e);
                e.preventDefault();
           }, false);
        }
        ***/

        // If passsword reset link, display passwor-reset component
        //
        // console.log('getReset()=', this.auth.getReset());
        if (this.auth.getReset()) {
            this.pwdreset.toggle_reset();
            console.log('auth pwdreset=', this.pwdreset);
        }

        // Get the user org list again
        if ( this.userData && this.userData.uid ) {
           this.orgsvc.getUserOrganizations$( this.userData.uid );
        }

        // Get userInfo and subacct data
        this.userSubAccounts = 0;
        try {
           // Get the userInfo data, on failure navitate to login
           this.userInfo = await this.usersvc.getUserInfo(this.userData.uid);
           console.log('main userInfo=', this.userInfo);
           this.userSubAccounts = this.userInfo.accounts;
           console.log('main userSubAccounts=', this.userSubAccounts);
        } catch (e) {
           console.log('main error getUserInfo() e=', e);
           clearInterval(this.updateInterval);
           this.router.navigate(['/login'], {replaceUrl: true});
           throw e;
        }

        // Get updates on updateInterval every 2 seconds..
        this.updateInterval = setInterval(
            () => {
                /* Only update when window is actively focused */
                // if (document.hasFocus()) {
                if ( this.documentsvc.activeDocument &&
                     this.documentsvc.activeDocument !== null ) {
                    // console.log('interval: loadLayers');
                    this.loadLayers(this.documentsvc.activeDocument);
                    // updateDocumentList calls getUserGroups()
                    this.updateDocumentList();
                    this.checkUserAccess();                    
                    // console.log('interval: groups=', this.groups);
                    // console.log('interval: doc=', this.activeDocument);
                    this.cdr.markForCheck();
                }
                if ( this.userData && this.userData.uid ) {
                    this.refreshData();
                }
            }, 2000);

        // Do an immediate update after setting up the interval
        // ### 279 - getUserGroups replaces get_groups and other code that
        //          modifies this.groups that now points to the cached group
        //          list on group.service
        this.getUserGroups(this.userInfo.uid);
        this.updateDocumentList();

        // Subscribe to selectedGroup changes on groupService
        this.selectedGroupSubscription = this.groupsvc.selectedGroupSubject.subscribe( grp => {this.updateSelectedGroup(grp);} );

        // 279 CJ - Group Service Subscribe to group list changes
        this.userGroupListSubscription = this.groupsvc.getUserGroupListUpdates().subscribe(
           async glist => {
              console.log('main: groupListSubscription, refreshing..');
              // console.log('subscription: message=', glist);
              // 279 CJ - Dialog should subscribe for group list updates.
              // change required.
              // updateDocumentList calls updateUserGroups now.
              this.updateDocumentList();
              this.updateDialogGroupList(this.groups);
              // console.log('subscription: groups=', this.groups);
        });

        this.PDFViewerApplication = (window as any).PDFViewerApplication;

        // get the user directory
        await this.usersvc.getAllUsers();

        this.appSharePlugin = await this.appCanShare();
        // console.log('appSharePlugin=', this.appSharePlugin);

        // 350-UI Mode reload doc if uiMode changed
        //
        // For docid parameter passed, wait for the viewer to load
        // if ( this.activeDocument != null ) {
        //      setTimeout( ()=> {
        //        this.setDocument( this.activeDocument );
        //      }, 1000 );
        // }
        // For UI Mode Change
        // if ( this.documentsvc.pdfSrc != null &&
        //      this.documentsvc.activeDocument != null ) {
        //      this.loadLayers(this.documentsvc.activeDocument, true);
        //      this.pdfGoToPage(this.documentsvc.pdfPageNbr);
        //}

        // Subscribe to and process URL queryParameters
        this.getQueryParams();
    }

    async refreshData() {
       // console.log('main: refreshData...');
       this.orgsvc.getUserOrganizations$( this.userData.uid );
       // ######
       // ######  This might break group lists!
       // ######
       this.getUserGroups(this.userInfo.uid);
       // await this.usersvc.getAllUsers();
       
       /*** These mess up selectors
       if ( this.selectedGroup ) {
          this.selectedGroupObj = await this.groupsvc.getGroup(this.selectedGroup);
       }
       if ( this.selectedOrg ) {
          this.selectedOrg = await this.orgsvc.getOrganizationPromise(this.selectedOrg.uid);
       }
       if ( this.selectedOrgUser ) {
          this.selectedOrgUser = await this.orgusersvc.getOrgUserPromise(this.selectedOrgUser.uid);
       }
       ****/
    }

    refreshGroupFilesEvent(evt: Event) {
       event.stopPropagation();
       this.refreshGroupFiles();
    }
    refreshGroupMembersEvent(evt: Event) {
       event.stopPropagation();
       this.refreshGroupMembers();
    }
    refreshGroupFiles() {
      if (this.grpFileList) {
        // console.log('main refreshGroupFiles grpFileList=', this.grpFileList);
        this.grpFileList.refreshList();
      }
    }
    refreshGroupMembers() {
      if (this.grpMemberList) {
        // console.log('main refreshGroupMembers grpMemberList=', this.grpMemberList);
        this.grpMemberList.refreshList();
      }
    }

    // Subscribe to parameter values on URL changes and take
    // action if required. Note: May want to implement this in a guard
    getQueryParams(): void {
        this.documentsvc.pdfPageNbr = 1;
        // Route parameters
        let loadDocumentID = null;
        let loadDocumentOBJ = null;
        this.loadPageNbr = null;
        this.loadNoteID = null;
        // URL query parameters
        // console.log('activatedRoute=', this.route);
        this.route.queryParams.subscribe( params => {
           console.log('url params=', params);
           console.log('url docid=', params.docid);
           console.log('url page=', params.page);
           console.log('url noteid=', params.noteid);
           if ( params.docid ) {
              loadDocumentID = params.docid;
           }
           if ( params.page ) {
              this.loadPageNbr = params.page;
           }
           if ( params.noteid ) {
              this.loadNoteID = params.noteid;
           } else {
                this.loadNoteID = null;
             }
           // For docid parameter passed, wait for the viewer to load
           // If a noteID is passed it will be scrolled to after the
           // notes are loaded on the overlay.

           if ( loadDocumentID !== null ) {
              loadDocumentOBJ = this.documentsvc.DocumentList.find(d => d.uid === loadDocumentID);
              console.log('params loadDocumentOBJ=', loadDocumentOBJ);
              if ( this.loadPageNbr !== null && this.loadNoteID === null ) {
                 console.log('url navigating to page =', this.loadPageNbr);
                 this.documentsvc.pdfPageNbr = this.loadPageNbr;
                 this.loadDocument( loadDocumentID, this.loadPageNbr );
              }
              if ( this.loadPageNbr===null) {
                     this.documentsvc.pdfPageNbr = 1;
                     this.loadDocument( loadDocumentID, 1 );
              }
              // window.history.replaceState({}, "markadoc")
              this.location.go('');
           }
           // For page parameter without docid scroll to page
//           if ( this.loadPageNbr !== null && this.loadNoteID === null ) {
//                this.documentsvc.pdfPageNbr = this.loadPageNbr;
//                this.location.go('');
//           }
        });
    }

    ngOnDestroy() {
       // unsubscribe to ensure no memory leaks
       if (this.userGroupListSubscription) {
          this.userGroupListSubscription.unsubscribe();
       }
       if (this.selectedGroupSubscription) {
          this.selectedGroupSubscription.unsubscribe();
       }
       if (this.orgListSubscription) {
          this.orgListSubscription.unsubscribe();
       }
       CapacitorApp.removeAllListeners();
    }

/***
    async getUserInfo() {
        return this.auth.getInfo()
               .toPromise()
               .then(
                     (x) => x as UserInfo,
                     (e) => {
                              console.log('ERROR: ', e);
                              clearInterval(this.updateInterval);
                              this.router.navigate(['/login'], {replaceUrl: true});
                              throw e;
                     }
        );
   }
***/

   scrollToNote(noteid) {
     // Rotate view to that specified by note
     const content = this.contentoverlay.nativeElement;
     // console.log('scrollToNote overlay=', content);
     const [type, count] = noteid.split('-').slice(1, 3);
     const note = this.noteData[type][count - 1];
     this.documentsvc.pdfRotation = note.orientation;

     // This should be null...
     // console.log('scrollToNote note dialogRef=', note.dialogRef);

     // if the dialogRef exists clear it
     const dref = this.findDialogObj( note );
     if ( dref && dref !== null ) {
        // Clear/destroy all open dialogs
        this.clear_dialogs();
        this._hide_notes();
        this.reset_shown();
     } else {
          // dialogRef is null but clear other open dialogs first
          this.clear_dialogs();
          this._hide_notes();
          this.reset_shown();
          // Create the dialog and scroll to it.
          // Include slight delay to allow rotation change
          // console.log('scrollToNote creating dialogRef note=', note);
          setTimeout(() => {
              this._create_marker_dialog(document.getElementById('content-layer'),`user-${type}-${count}`, MarkerDialog.TEMPLATE_ADD_COMMENT);
          }, 100);
          // Then scroll to the note dialog
          // console.log('scrollToNote type=', type);
          // console.log('scrollToNote count=', count);
          console.log('scrollToNote noteid=', noteid);
          this.scrollToDialog(type, count);
          setTimeout(() => {
            console.log('scrollToDialog noteid=', noteid);
            if (this.platformOS === 'ios' || this.platformOS === 'macos' ){
              document.getElementById(noteid).scrollIntoView(true);
            } else {
                document.getElementById(noteid).scrollIntoView({block: 'start', inline: 'start'});
              }
          }, 100);
       } //else dref
   }

   scrollToDialog( type, count ) {
      setTimeout(() => {
        const dialogid = 'dialog-user-' + type + '-' + count;
        console.log('scrollToDialog id=', dialogid);
        if (this.platformOS === 'ios' || this.platformOS === 'macos' ){
          document.getElementById(dialogid).scrollIntoView(true);
        } else {
            document.getElementById(dialogid).scrollIntoView({block: 'start', inline: 'start'});
          }
      }, 100);
   }

   scrollToNoteUUID( uuid ) {
      // scroll to note based on uuid of note markerdata
      // console.log('scrollToNoteUUID=', uuid);
      let ele = '';
      ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
          this.noteData[ntype].forEach((note, index) => {
          if ( note.uuid === uuid ) {
              ele = `user-${ntype}-${index + 1}`;
              // console.log('scrollToNoteUUID ele=', ele);
              // console.log('scrollToNoteUUID dialogRef=', note.dialogRef);
              this.scrollToNote( ele );
          }
          });
      });
   }

   scrollToPage(pgnbr) {
       // Include slight delay to allow rotation change to be picked up first
       // Start using pdfGoToPage here with extended viewer
       const pages = document.getElementsByClassName('page');
       const content = document.getElementById('main-sidenav-content');

       let page = null;
       if ( pages ) {
          page = pages[pgnbr - 1];
       }
       if ( page ) {
          if ( pgnbr===1 && content) {
//             page.scrollIntoView({block: "start", inline: "start"} );
             content.scrollTo(0,0);
          } else {
             page.scrollIntoView( true );
            }
       }
   }

   openDocument(d) {
      console.log('openDocument d=', d);
      this.documentsvc.pdfPageNbr = 1;
      this.clear_dialogs();
      this.resetDocumentState();
      this.loadDocument(d.uid, 1);
   }

   async loadDocument(duid, page = 1, noteid = null) {
       this.clear_dialogs();
       this.resetDocumentState();
       this.sidenavright.open();
       this.sidenavleft.close();
       // this.documents = await this.documentsvc.getDocuments();
       // const doc = this.documents.filter((d) => d.uid === duid)[0];
       const doc = await this.documentsvc.getDocumentData( duid );
       if ( doc ) {
          this.selectedDocObj = doc;
          this.selectedDocUID = doc.uid;
       }
       if ( doc && doc !== null ) {
          this.loadGroupID = doc.groups_uid;
       } else {
            this.loadGroupID = null;
       }
       this.setDocument(duid, false);
       // setTimeout(() => {
       // this.pdfGoToPage(page);
       // }, 350);
       //if( noteid !== null ) {
       //  setTimeout(() => {
       //     this.scrollToNote(noteid);
       //  }, 350);
       //}
    }

    async setDocument(uid: string, quiet = false) {

        // Reset params on load (in resetDocumentState)
        this.resetDocumentState();

        // 350 - update the documentsvc
        this.documentsvc.activeDocument = uid;

        // Set the current document link for cut/paste
        this.setDocumentLink(uid);

        this.overlayWidth = 0;
        this.maxPageWidth = 0;

        if (uid) {
            // await this.loadLayers(uid, true);
            this.loadLayers(uid, true);

            this.noteDataSubscription = this.markersvc.getNoteDataUpdates().subscribe( data => { this.updateUIData( data ); } );

            console.log('main setDocument this.documents=', this.documents);
            const doc = this.documents.filter((d) => d.uid === uid)[0];
            if ( doc ) {
              this.selectedDocObj = doc;
              this.selectedDocUID = doc.uid;
            }
            this.activeDocumentName = doc.name;

            // Update selectedGroup and selectedGroupObj
            this.setSelectedGroup( doc.groups_uid );

            // CJ - We will dismiss this when it hits 30 secs or PDFLoadComplete
            // callback dismisses it. Especially for large files and images.
            if (!quiet) {
                // Open the progress dialog
                this.openProgressDialog( 'Loading...',
                                         'Loading document ' + doc.name,
                                         'determinate' );
                // Old toaser msg..
                // this.toast.pop('wait', 'Loading', `Loading document ${doc.name}`);
            }

            /*** this method loaded the old ng2-pdf-viewer
            this.pdfSrc = {
               url: environment.apiUrl + '/document/' + uid,
               httpHeaders: {
                   Authorization: 'Bearer ' + this.auth.getToken(),
                   'Content-Type': 'application/json'
               },
            };
            this.documentsvc.pdfSrc = this.pdfSrc;
            ***/

            // NgxExtendedPdfViewer URL & headers passed separarately.
            // Will retrieve the document
            const headers = {
                    Authorization: 'Bearer ' + this.auth.getToken(),
                    'Content-Type': 'application/json'
            };
            this.documentsvc.httpHeaders = headers;
            this.documentsvc.pdfURL = environment.apiUrl + '/document/' + uid;


            // Update the document timestamp
            await this.http.post(environment.apiUrl + '/document/id/' + uid, {timestamp: 'update'}).toPromise()
              .then(
                    (x) => console.log(x),
                    (e) => console.log(e)
              );

            // go to the page nbr
            console.log('setDocument page=', this.documentsvc.pdfPageNbr);
            setTimeout(() => {
               this.pdfGoToPage( this.documentsvc.pdfPageNbr );
            }, 400);

            // Apparent race condition in ng2-pdf-viewer module, needs stagger
            // to set rotation correctly.
            setTimeout(() => {
                  this.documentsvc.pdfRotation = this.documentsvc.snapRotation;
                      }, 1000);



            /*****
            console.log('setDocument rotation=', this.pdfRotation);
            console.log('setDocument ds rotation=', this.documentsvc.pdfRotation);
            console.log('setDocument ds httpHeaders=', this.documentsvc.httpHeaders);
            console.log('setDocument pdfSrc=', this.pdfSrc);
            console.log('setDocument ds pdfSrc=', this.documentsvc.pdfSrc);
            console.log('setDocument pdfURL=', this.pdfURL);
            console.log('setDocument ds pdfURL=', this.documentsvc.pdfURL);
            this.pdfGoToPage( this.pdfPageNbr );
            ****/
        }
    }

    refreshbrowse(){
       // Save the current document id if one is open
       // The save/restore is not working well right now
       /****************
       if(this.activeDocument) {
          localStorage.setItem("MDREFRESH", this.activeDocument);
       }
       ****************/
       location.reload();
    }

    get_current_document(uid) {
        // Retrieve the current document by it's uid
        return this.documents?.filter((f) => f.uid === uid)[0];
    }

    panelClick(guid) {
       console.log('A panel was clicked for g=', guid);
    }

    // 279 - CJ - Rewrite of get_groups to use group service
    // This replaces get_groups and any other code manipulating the users
    // group list which is now cached and updated on group.service
    async getUserGroups(uuid: string) {
       try {
          this.groups = await this.groupsvc.getUserGroups(uuid);
          // console.log('getUserGroups this.groups=', this.groups);
          this.updateDialogGroupList(this.groups);
          if (this.groups && this.groups.length > 0) {
            if (! this.selectedGroup) {
               this.setSelectedGroup(this.groups[0].uid);
               this.setSelectedGroupObj(this.groups[0]);
            }
          }
       } catch (e) {
            console.log('Error: main.getUsergroups() error=', e);
            clearInterval(this.updateInterval);
            this.router.navigate(['/login'], {replaceUrl: true});
       }
    }

    get_most_recent() {
        if (this.documents) {
            const recent = (this.documents as DocumentData[]).sort((a, b) => a.timestamp - b.timestamp).reverse().slice(0, 6);
// 279 changed to next line
//            recent.map((r) => r.owner = this.groups.filter((f) => r.groups_uid === f.uid)[0].owner);
//            recent.map((r) => r.owner = this.groups.filter((f) => r.groups_uid === f.uid).owner);
            return recent;
        }
    }

    get_recent_docs(cnt) {
        if (this.documents) {
            const recent = (this.documents as DocumentData[]).sort((a, b) => a.timestamp - b.timestamp).reverse().slice(0, cnt);
            //
            // NOTE: May be issue owner on GroupData
            //
            console.log('get_recent_docs recent=', recent);
// 279 changed to next line
//             recent.map((r) => r.owner = this.groups.filter((f) => r.groups_uid === f.uid)[0].owner);
//             recent.map((r) => r.owner = this.groups.find((f) => r.groups_uid === f.uid).owner);
//            recent.map((r) => r.owner = this.groups.filter((f) => r.groups_uid === f.uid).owner);
            return recent;
        }
    }

    async updateLayerData(luid: string) {
        if (this.documentsvc.activeDocument) {
            // const indLayerData = {
            //     note: this.noteData.note.filter((f) => f.owner === this.userInfo.email),
            //     question: this.noteData.question.filter((f) => f.owner === this.userInfo.email),
            //     issue: this.noteData.issue.filter((f) => f.owner === this.userInfo.email),
            // };
            const data = JSON.stringify({
                users_uid: this.userInfo.uid,
                documents_uid: this.documentsvc.activeDocument,
                // layer_data: indLayerData,
                layer_data: this.userLayer,
                doc_orientation: this.documentsvc.pdfRotation
            });

            console.log('updateLayerData uid=', luid);
            // console.log('updateLayerData uid=', this.layerUid);
            // console.log('updateLayerData data=', data);
            // this.http.post(environment.apiUrl + '/layer/' + this.luid, data)
            //    .subscribe(
            //        (x) => { console.log('updateLayers x=', x);
            //               },
            //        (e) => console.log(e)
            // );
            try {
               await this.layersvc.updateLayerData(this.layerUid, data);
            } catch(e) {
                 this.toast.pop('error', 'Update Layer Data', 'Error updating layer data!');
            }
            // #523 must force load layers for new entry
            // or you wont be able to add a comment until the list is updated
            // again (must open marker 2x if not forced)
            this.loadLayers(this.documentsvc.activeDocument, true);
        }
    }

    async loadLayers(uid: string, force = false) {
        // uid is the document uid
        if (!uid) { return; }
        try {
           const x = await this.layersvc.loadLayerDataPromise( uid );
           const data = x.filter((l) => l.users_uid === this.userInfo.uid);

           // console.log('userInfo=', this.userInfo);
           // console.log('x=', x);
           // console.log('data=', data);
           let layer: LayerData = new LayerData();
           if (data.length > 0) { layer = data[0]; }
           if ((!('uid' in layer) || layer.users_uid !== this.userInfo.uid) && this.documentsvc.activeDocument !== null) {
              // If empty, initialize and create user layer entry
              this.noteData = {
                  note: [],
                  question: [],
                  issue: [],
                  approval: []
              };

              // console.log('load noteData=', this.noteData);
              const ndata = JSON.stringify({
                       users_uid: this.userInfo.uid,
                       documents_uid: this.documentsvc.activeDocument,
                       layer_data: this.noteData,
                       doc_orientation: 0
              });
              // Add/update the user layer data
              if (this.documentsvc.activeDocument) {
                   // reload after initial entry creation (setDocument)
                   this.layerUid = await this.layersvc.addLayerData( ndata );
                 }
           } else {
              // Need to flatten all incoming layers
              // console.log('load x=', x);
//                 const newLayers = {
//                       note: x.map((m) => JSON.parse(m.layer_data).note.map((y) => { y.uid = m.uid; return y; })).flat(),
//                        question: x.map((m) => JSON.parse(m.layer_data).question.map((y) => { y.uid = m.uid; return y; })).flat(),
//                        issue: x.map((m) => JSON.parse(m.layer_data).issue.map((y) => { y.uid = m.uid; return y; })).flat(),
//                        approval: x.map((m) => JSON.parse(m.layer_data).approval.map((y) => { y.uid = m.uid; return y; })).flat()
//                    };

              const newLayers = {
                      note: [],
                      question: [],
                      issue: [],
                      approval: []
              }
              // console.log('x=', x);

              // Map the data to the layer
              newLayers.note = x.map((m) => JSON.parse(m.layer_data).note.map((y) => { y.uid = m.uid; return y; })).flat();
              newLayers.question = x.map((m) => JSON.parse(m.layer_data).question.map((y) => { y.uid = m.uid; return y; })).flat();
              newLayers.issue = x.map((m) => JSON.parse(m.layer_data).issue.map((y) => { y.uid = m.uid; return y; })).flat();
              newLayers.approval = x.map((m) => JSON.parse(m.layer_data).approval.map((y) => { y.uid = m.uid; return y; })).flat();

              /* sort layer data by created date */
              newLayers.note.sort((a, b) => a.created_at - b.created_at);
              newLayers.question.sort((a, b) => a.created_at - b.created_at);
              newLayers.issue.sort((a, b) => a.created_at - b.created_at);
              newLayers.approval.sort((a, b) => a.created_at - b.created_at);
              // console.log('MAIN: newLayers=', newLayers);

              this.oldNoteData.note.forEach((n)=>{n.dialogRef=null;});
              this.oldNoteData.issue.forEach((n)=>{n.dialogRef=null;});
              this.oldNoteData.question.forEach((n)=>{n.dialogRef=null;});
              this.oldNoteData.approval.forEach((n)=>{n.dialogRef=null;});
//              console.log('MAIN: oldNoteData strlen=', JSON.stringify(this.oldNoteData).length);
//              console.log('MAIN: newLayers strlen=', JSON.stringify(newLayers).length);

              if ( ( this.get_data_length(this.oldNoteData)  !==
                     this.get_data_length(newLayers)  ) || force) {
                // Checks for add/edit dialogs open
                if ((document.getElementsByClassName('add-item-button').length
                  + document.getElementsByClassName('edit-item-button').length)
                    === 0)  {

                    this.noteData.note = [...newLayers.note];
                    this.noteData.question = [...newLayers.question];
                    this.noteData.issue = [...newLayers.issue];
                    this.noteData.approval = [...newLayers.approval];
                    this.cdr.detectChanges();

                    this.oldNoteData = this.noteData;
                    this.layerUid = data[0].uid;

                    // *** NEED TO FIX IF NO APPROVALS ***
                    const ul = JSON.parse(data[0].layer_data);
                    this.userLayer = {
                           note: [],
                            question: [],
                            issue: [],
                            approval: []
                    };
                    this.userLayer.note = [...ul.note];
                    this.userLayer.question = [...ul.question];
                    this.userLayer.issue = [...ul.issue];
                    this.userLayer.approval = [...ul.approval];
                    //console.log('load userLayer updt=', this.userLayer);
                    //console.log('load noteData updt=', this.noteData);

                    this.reset_shown();

                    // Note: This triggers the subscription to call
                    //       updateUIData to update marker objects
                    // Only update if NOT adding or editing
                    this.markersvc.setNoteData(this.noteData);

                    this.clear_notes();
                    // this.clear_dialogs();

                    this.redraw_notes('note');
                    this.redraw_notes('question');
                    this.redraw_notes('issue');
                    this.redraw_notes('approval');
                    this.documentsvc.snapRotation = x[0].doc_orientation;
                    this._hide_notes();

                    // this.markersvc.sendNoteDataUpdate(this.noteData);
                    this.cdr.detectChanges();
//                    console.log('MAIN: NEW noteData=', this.noteData);
                 }
            }
        }

        this._hide_notes();
        // console.log('MAIN: noteData=', this.noteData);
        // this.markersvc.sendNoteDataUpdate(this.noteData);

     } catch(e) {
           this.clear_dialogs();
           this.clear_notes();
           this.updateDocumentList();
           this.documentsvc.pdfSrc = '';
           console.error('main: loadLayers error=', e);
       }
    }

/****
    notesAreEqual(a, b) {
       const result = this.arrayIsEqual(a.note, b.note) &&
                      this.arrayIsEqual(a.question, b.question) &&
                      this.arrayIsEqual(a.issue, b.issue) &&
                      this.arrayIsEqual(a.approval, b.approval);
       return result;
    }

    arrayIsEqual(a, b) {
       a = Array.isArray(a) ? a : [];
       b = Array.isArray(b) ? b : [];
       return a.length === b.length && a.every((el, ix) => el === b[ix]);
    }
 ****/

    get_data_length(data: NoteData) {
       // Old method did not always catch changes
       if (data) {
           // Count comments length
           const count = JSON.stringify(data.note).length +
                       JSON.stringify(data.question).length +
                       JSON.stringify(data.issue).length +
                       JSON.stringify(data.approval).length;
           return count;
       }
       return 0;
    }

/***
    get_data_length(data: NoteData) {
        if (data) {
            // Count comments length
            let count = data.note.length + data.question.length + data.issue.length + data.approval.length;
            for (const n of data.note) {
                if ('comments' in n) { count += Object(n).comments.length; }
            }
            for (const n of data.question) {
                if ('comments' in n) { count += Object(n).comments.length; }
            }
            for (const n of data.issue) {
                if ('comments' in n) { count += Object(n).comments.length; }
            }
            for (const n of data.approval) {
                if ('comments' in n) { count += Object(n).comments.length; }
            }

            // Count approvals length
            for (const n of data.note) {
                if ('approvals' in n) { count += Object(n).approvals.length; }
            }
            for (const n of data.question) {
                if ('approvals' in n) { count += Object(n).approvals.length; }
            }
            for (const n of data.issue) {
                if ('approvals' in n) { count += Object(n).approvals.length; }
            }
            for (const n of data.approval) {
                if ('approvals' in n) { count += Object(n).approvals.length; }
            }
            return count;
        }
        return 0;
    }
 ****/

    openDeleteFile(uid: string) {
        document.getElementById('deleteFile_container').style.top = document.querySelector('.mat-sidenav-content').scrollTop + 'px';
        document.getElementById('deleteFile_container').style.left = document.querySelector('.mat-sidenav-content').scrollLeft + 'px';
        document.getElementById('deleteFile_container').style.display = 'block';
        document.getElementById('fileNameDisplay').innerHTML =  this.documents.filter((d) => d.uid === uid)[0].name;
        this.render.listen(document.getElementById('deleteFile-color'), 'click', (ev) => {
            this.removeDocument(uid);
        });
    }

    async removeDocument(uid: string) {
        // TODO: use docsvc behavior subject
        // this.close('deleteFile_container');
        // this.clear_dialogs();
        this.clear_notes();
        try {
              await this.documentsvc.removeDocument(uid);
              const doc = this.documents.filter((d) => d.uid === uid)[0];
              this.toast.pop('success', 'Remove Document', `Document (${doc.name}) removed successfully.`);
              this.documents.forEach((d, index, obj) => {
                 if (d.uid === uid) {
                     obj.splice(index, 1);
                 }
                 this.noteData = {
                      note: [],
                      question: [],
                      issue: [],
                      approval: []
                 };
                });
              this.resetDocumentState();
              this.updateDocumentList();

        } catch (e) {
                const doc = this.documents.filter((d) => d.uid === uid)[0];
                this.toast.pop('error', 'Remove Document', `Failed to remove document (${doc.name}).`);
                console.error('ERROR: ', e);

        }
    }

// Get max page width
   getMaxPageWidth() {
     const pages = document.getElementsByClassName('page');
     // console.log('pages  =', pages);
     let maxw = 0;
     if( pages ) {
        for(let i=0; i<pages.length; i++) {
           const pw = pages[i].clientWidth;
           if( pw > maxw ) {
              maxw = pw;
           }
        }
        //   console.log('max page width =', maxw);
      }
      // let pct = Math.round(maxw * 0.2);
      const pct = 50;
      maxw = maxw + pct;
      this.maxPageWidth = maxw;
      return maxw;
   }

   getScreenSize(): void {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.platformsvc.orientation = PLATFORM.VIEWPORT_LANDSCAPE;
      if ( this.platformsvc.uiMode === PLATFORM.UI_MOBILE ) {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
      } else {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
        }
      console.log('getScreenSize mainMarginTop=', this.platformsvc.mainMarginTop );
   }

   NEW_getScreenSize(): void {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      if ( this.screenHeight > this.screenWidth ) {
        this.platformsvc.orientation = PLATFORM.VIEWPORT_PORTRAIT;
      } else {
           this.platformsvc.orientation = PLATFORM.VIEWPORT_LANDSCAPE;
        }

      if ( this.platformsvc.uiMode === PLATFORM.UI_MOBILE ) {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
         if ( this.platformsvc.orientation === PLATFORM.VIEWPORT_LANDSCAPE ) {
            this.platformsvc.sidenavMarginBottom = PLATFORM.MARGIN_BOTTOM;
         } else {
              this.platformsvc.sidenavMarginBottom = PLATFORM.MARGIN_BOTTOM_TOOLBAR;
//              this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_TOOLBAR;
           }
      } else {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
        }
   }


// Orientation change handler
   resizeHandler( evt ) {
      console.log('window resizeHandler evt=', evt);
      // const pagelist = document.getElementsByClassName('page');
      // const page1 = pagelist[0] as HTMLElement;

      // console.log('resize pagelist=', pagelist);
      // console.log('resize page1=', page1);

      this.getScreenSize();

      // console.log('dpr=', window.devicePixelRatio);
      // console.log('orientation=', window.orientation);
      // console.log('noteData=', this.noteData);
      // console.log('activeDocument=', this.documentsvc.activeDocument);

      // IMPORTANT: Dont loadLayers here on Android or it will mess up data
      // entry on markers as they will be redrawn while open.
      // if (this.documentsvc.activeDocument) {
      //   this.loadLayers(this.documentsvc.activeDocument, true);
      // }
   }

   mouseTrack(e) {
        const debug = false;

        // 350 - updated to account for mobile toolbar header.
        // Get offset from left of page, to contentlayer element
        const cle = document.getElementById('content-layer') as HTMLElement;
        if (debug) { console.log('content layer cle=', cle); }
        this.layerOffset.x = e.pageX - cle.offsetLeft;

        // get the left sidenav width
        if (this.sidenavleft.opened) {
            this.layerOffset.x -= (document.getElementsByClassName('mat-drawer-inner-container')[0] as HTMLElement).offsetWidth;
        }

        // Add scrollTop for the sidenav container
        this.layerOffset.y = e.pageY + document.querySelector('.mat-sidenav-content').scrollTop;
        this.layerOffset.x = (this.layerOffset.x < 0 ? 0 : this.layerOffset.x)
            + document.querySelector('.mat-sidenav-content').scrollLeft;

        // The following is for mobile ui offset for top toolbar but should
        // ALWAYS apply anyway. Use maincontainer NOT contentlayer.
        const tbr = document.getElementsByClassName('mat-toolbar')[0] as HTMLElement;
        if (debug) { console.log('mouseTrack tbr=', tbr); }

        if ( tbr ) {
           const tbh = tbr.offsetHeight;
           if (debug) { console.log('mouseTrack tbh=', tbh); }
           this.layerOffset.y = this.layerOffset.y - tbh;
        }
    }


// #379 fix marker moves on drop
//
    drop(event: CdkDragDrop<string[]>) {
        const debug = false;
        if (debug) { console.log('drop event=', event ); }

        if (debug) { console.log('drop x=', event.dropPoint.x ); }
        if (debug) { console.log('drop y=', event.dropPoint.y ); }

        let newId = '';
        const tWidth = (document.getElementsByClassName('right-side-icon')[0] as HTMLElement).offsetWidth;
        const tHeight = (document.getElementsByClassName('right-side-icon')[0] as HTMLElement).offsetHeight;

        if (debug) { console.log('drop tWidth=', tWidth); }
        if (debug) { console.log('drop tHeight=', tHeight); }

        const tOffsetWidth = document.getElementById('tools-note').offsetWidth;
        const tOffsetHeight = document.getElementById('tools-note').offsetHeight;

//        tOffsetWidth = tOffsetWidth * this.documentsvc.pdfZoom;
//        tOffsetHeight = tOffsetHeight * this.documentsvc.pdfZoom;

        const toolWidth = tOffsetWidth - tWidth;
        const toolHeight = tOffsetHeight - tHeight;

        if (debug) { console.log('drop tWidth=', tWidth); }
        if (debug) { console.log('drop tHeight=', tHeight); }
        if (debug) { console.log('drop tOffsetWidth=', tOffsetWidth); }
        if (debug) { console.log('drop tOffsetHeight=', tOffsetHeight); }

        // const ely = (event.item._dragRef as any)._pickupPositionInElement.y + 10;
        // const elx = (event.item._dragRef as any)._pickupPositionInElement.x + 2;\
        const elx = (event.item._dragRef as any)._pickupPositionInElement.x;
        const ely = (event.item._dragRef as any)._pickupPositionInElement.y;
        if (debug) { console.log('drop dragref=', event.item._dragRef); }

        // need for y=364,  315 y=364 - 48 = 316
        if (debug) { console.log('drop elx,ely=' + elx + ',' + ely ); }
        if (debug) { console.log('layer off x,y=' + this.layerOffset.x + ',' + this.layerOffset.y ); }


        // x 380, need 365, mouseUp x=381 + (62/2=31) - elx
        const offsetLeft = this.layerOffset.x + ( (toolWidth / 2) - ( elx + 2 ));
        const offsetTop = this.layerOffset.y + (toolHeight - (ely + 2));

        const viewer = document.getElementById('viewer') as HTMLElement;
        const ovlinfo = viewer.getBoundingClientRect();
        const zoom = this.documentsvc.pdfZoom;

        const ovwr = ovlinfo.width * ( 0.00485 / 2);
        const ovhr = ovlinfo.height * 0.00485;
        const ovwo = ovwr;
        const ovho = ovhr;

        if (debug) { console.log('ovlinfo=', ovlinfo);}

/*****
        // adjust for negative or positive zoom
        if ( zoom < 1.0 ) {
           ovwo = (((1 - zoom) * 8) * ovwr) * -1;
           ovho = (((1 - zoom) * 8) * ovhr) * -1;
           if ( zoom <= 0.25 ) {
              ovwo = ovwo * 2.5;
              ovho = ovho * 2.5;
           }
        } else {
             ovwo = ((zoom - 1) * 4) * ovwr;
             ovho = ((zoom - 1) * 4) * ovhr;
          }

//        offsetTop = offsetTop + ovhr;
//      offsetLeft = offsetLeft + ovwr;

*******/

//        offsetTop = offsetTop / zoom;
//        offsetLeft = offsetLeft / zoom;

        if (debug) { console.log('drop offset y,x=' + offsetTop + ',' + offsetLeft); }

   // ** C. Janick #243-add-page-nbr changes
   //
        let markupItem = null;
        if (event.item.data) {
            // include -16 offset for icon size of 32
            if (event.item.element.nativeElement.id === 'tools-note') {
               markupItem = this._create_item('note', offsetLeft, offsetTop);
               event.item.data.push(markupItem);
               newId = 'user-note-' + event.item.data.length;
               if (debug) {console.log('drop markupItem=', markupItem);}
            }
            if (event.item.element.nativeElement.id === 'tools-question') {
               markupItem = this._create_item('question', offsetLeft, offsetTop);
               event.item.data.push(markupItem);
               newId = 'user-question-' + event.item.data.length;
               if (debug) {console.log('drop markupItem=', markupItem);}
            }
            if (event.item.element.nativeElement.id === 'tools-issue') {
                markupItem = this._create_item('issue', offsetLeft, offsetTop);
                event.item.data.push(markupItem);
                newId = 'user-issue-' + event.item.data.length;
                if (debug) {console.log('drop markupItem=', markupItem);}
            }
            if (event.item.element.nativeElement.id === 'tools-approval') {
                markupItem = this._create_item('approval', offsetLeft, offsetTop);
                event.item.data.push(markupItem);
                newId = 'user-approval-' + event.item.data.length;
                if (debug) {console.log('drop markupItem=', markupItem);}
            }
        }

   // ** C. Janick #243-add-page-nbr and coords
   // ** We cant get the data here but mouseup event will fire next and update
   // ** and we will get the uuid for the markup from this.lastNoteId
        this.lastNoteId = markupItem;

        const newEl = event.item.element.nativeElement.cloneNode(true) as HTMLElement;
        if (debug) {console.log('newEl=', newEl);}
        newEl.id = newId;

        newEl.className += ' note-added';
        newEl.style.position = 'absolute';
        newEl.style.top = offsetTop + 'px';
        newEl.style.left = offsetLeft + 'px';
        // Added for icon drop placement fix
        newEl.style.margin = 0 + 'px';
        newEl.style.padding = 0 + 'px';
        newEl.style.border = 0 + 'px';
        //newEl.style.scroll-margin-top = 20 + 'px';

        this.render.listen(newEl, 'click', (ev) => {
            // console.log("ev.isTrusted =", ev.isTrusted);
            if (ev.isTrusted) {
                this._create_marker_dialog(ev.target.parentElement.parentElement, ev.target.parentElement.id, MarkerDialog.TEMPLATE_NEW_MARKER);
                document.getElementById(newId).scrollIntoView(true);
            } else {
                this._create_marker_dialog(document.getElementById(newId).parentElement, newId, MarkerDialog.TEMPLATE_NEW_MARKER);
                document.getElementById(newId).scrollIntoView(true);
            }
        });
        if (debug) {console.log('drop markupItem=', markupItem);}
        event.container.element.nativeElement.appendChild(newEl);
        document.getElementById(newId).click();
        // All added notes can be gathered
        // console.log(document.querySelectorAll('.note-added'));
   } // drop

   dragEnd(event: CdkDragEnd) {
      console.log('cdkdragend evt=', event);
   }


// #243-add-page-nbr - CJ
// Most of the extra work done here (for now) after the drop event this fires
//
   mouseUp(evt) {
     const debug = false;
     if (debug) { console.log('mouseUp evt=', evt); }
     if (debug) { console.log('mouseUp xy=' + evt.pageX, evt.pageY); }

     // The following logic only runs if a drop event occurred which left
     // the annotation data in lastNoteId which is used to update the
     // noteData with additional information.

     // Adding try/catch in the unlikely event someone cancels the markup
     // before the data is added.

     if (this.lastNoteId != null ) {
     try {
      let pge = null;
      let vce = null;
      let pgc = null;
      let pdfpagenbr = 0;
      let tryforward = true;
      let trybackward = true;
      let tryleft    = true;
      const tryoffset  = 51;
      let done       = false;
      let pgX = evt.pageX;
      let pgY = evt.pageY;

      // FIND MARKUP1: Find the annotation markup by uuid and make changes...
      let markup = null;
      ['issue', 'question', 'note', 'approval'].forEach((ntype) => {
           for (const note of this.noteData[ntype]) {
               const m = note;
               if (m.uuid === this.lastNoteId.uuid) {
                   if (debug) { console.log('found uuid!'); }
                   markup = m;
               }
           }
      });
      if (debug) { console.log('mouseUp markup=', markup); }

      // CJ - Fuzzy coordinate logic kludge in case they drop outside pages
      // then go forward, backward or left to find the closest page.
      // pageX,pageY are used to id the borders and underlying elements.

      while (done === false) {
        const elist = document.elementsFromPoint(pgX, pgY);
        if (debug) { console.log('elist=', elist); }
        for (const ele of elist) {
          if (ele.getAttribute('class') === 'page') {
            // console.log("page=true=", ele);
            pge = ele;
            pdfpagenbr = Number( ele.getAttribute('data-page-number') );
          }
          if (ele.getAttribute('id') === 'viewer') {
            vce = ele;
          }
          if (ele.tagName === 'CANVAS') {
            pgc = ele;
          }
        } // for

      if (debug) { console.log('vce=', vce); }
      if (debug) { console.log('pge=', pge); }
      if (debug) { console.log('pgc=', pgc); }

      if (pdfpagenbr > 0) {
             tryforward  = false;
             trybackward = false;
             done = true;
             if (debug) { console.log('ALL IS GOOD.'); }
      }
      if (tryforward  === false &&
          trybackward === false &&
          tryleft     === false) {
          done = true;
          if (debug) { console.log('try forward,left,back is done.'); }
      } else if (pdfpagenbr === 0 && trybackward === true && tryforward === false) {
                pgY = evt.pageY - tryoffset;
                trybackward = false;
                if (debug) { console.log('TRYING BACKWARD...'); }
             } else if (pdfpagenbr === 0 && tryforward === true) {
                         pgY = evt.pageY + tryoffset;
                         tryforward = false;
                         if (debug) { console.log('TRYING FORWARD...'); }
                      } else if (pdfpagenbr === 0 && tryleft === true) {
                                pgX = evt.pageX - tryoffset;
                                tryleft = false;
                                if (debug) { console.log('TRYING LEFT...'); }
                             }
      } // while

      // get the bounding rect of the view container
      const vcRect = vce.getBoundingClientRect();
      if (debug) {
          console.log('vcRect=', vcRect);
          console.log('vce.scrollLeft=', vce.scrollLeft);
          console.log('vce.scrollTop=', vce.scrollTop);
          console.log('vce.offsetLeft=', vce.offsetLeft);
          console.log('vce.offsetTop=', vce.offsetTop);
          console.log('window.scrollX=', window.scrollY);
          console.log('window.scrollY=', window.scrollY);
      }
      // compute the pdf-viewer-container overlay coordinates
      const vcOffsetX = (vce.scrollLeft + window.scrollX) - vcRect.left;
      const vcOffsetY = (vce.scrollTop  + window.scrollY) - vcRect.top;
      if (debug) { console.log('vce offset x,y=' + vcOffsetX + ',' + vcOffsetY); }

      // Get real coordinates within the overlay/pdf viewer container
      // Account for scrolling offset to the pdf view container mouse coords
      // as these are the real coords in the viewer container
      // These should be identical or close to the this.layerOffset values
      // in the mouse tracker.
      // const viewerX = (evt.clientX + vcOffsetX) - 4;
      const divY = (document.getElementById('tools-note') as HTMLElement).offsetHeight;
      const iconY = (document.getElementsByClassName('right-side-icon')[0] as HTMLElement).offsetHeight;
      const diffY = divY - iconY;

      // cltxy 565, 192
      // offsetxy 591, 1785
      // xy = 554, 1778
      // elx = 25, 7
      const vOffsetX = evt.offsetX - markup.x;
      const vOffsetY = evt.offsetY - markup.y;
      const viewerX = evt.offsetX;
      const viewerY = evt.offsetY;
      if (debug) { console.log( 'evt.clientX,Y  =' + evt.clientX + ','
                   + evt.clientY ); }
      if (debug) { console.log('vOffset X,Y = ' + vOffsetX + ', ' + vOffsetY); }
      if (debug) { console.log('viewer X,Y = ' + viewerX + ', ' + viewerY); }
      if (debug) { console.log('layerOffset X,Y = ' + this.layerOffset.x + ', '
                             + this.layerOffset.y);
      }

      // UPDATE MARKUP1: Update the markup pdfpagenbr and viewer coords
      markup.pageNbr = pdfpagenbr;
      markup.viewerX = viewerX;
      markup.viewerY = viewerY;

      // Get the bounding rect of the dropped on page element
      const pgRect = pge.getBoundingClientRect();
      if (debug) { console.log('pgRect=', pgRect); }

      // compute display coordinates in the page/canvas
      // This will be the top/left of the 32x40px box for a marker.
      // The center bottom of the icon point is at 16,40 from these coordinates
      //
      // 520 - Need to factor in zoom here...
      const zoom = this.documentsvc.pdfZoom;
      pgX = evt.clientX - ( pgRect.left + vOffsetX - 0.5 );
      pgY = evt.clientY - ( pgRect.top + vOffsetY );
      const pgWidth  = pgRect.width;
      const pgHeight = pgRect.height;
      if (pgX < 0) { pgX = 0; }
      if (pgY < 0) { pgY = 0; }
      if (pgX > pgWidth) {  pgX = pgWidth; }
      if (pgY > pgHeight) { pgY = pgHeight; }
      if (debug) {
        console.log('display page=' + pdfpagenbr + ' client pageX,Y=' + evt.clientX + ',' + evt.clientY);
        console.log('display page=' + pdfpagenbr + ' DOM pageX,Y=' + pgX + ',' + pgY);
        console.log('rect pgWidth=' +  pgWidth);
        console.log('rect pgHeight=' + pgHeight);
      }
      // UPDATE MARKUP2: Update the markup pdfpagenbr and viewer coords
      // 520 - need to divide by zoom factor..
      markup.viewPageX = pgX;
      markup.viewPageY = pgY;
      markup.viewPageWidth  = pgWidth;
      markup.viewPageHeight = pgHeight;

      // draw a rectangle (debug) in page coordinates should align the
      // left top of the rectangle where the page coordinate was dropped
      // before adjustments for icon size and offset.
      if (debug) { console.log('pgc=', pgc); }
      if (debug) {
         if (pgc) {
           const ctx = pgc.getContext('2d');
           ctx.beginPath();
           ctx.rect(pgX, pgY, 32, 40);
           ctx.stroke();
         }
      }
      // Get the page height and width in pdf 72dpi size
      // This will actually tell us how big the paper is / 72.
      // this.pdfPageInfo is updated during page rendered callback
      const pgInfo = this.pdfPageInfo.filter(obj => {
         return obj.pdfPageNbr === pdfpagenbr;
      });
      if (debug) { console.log('pgInfo=', pgInfo); }
      const pdfPageHeight   = pgInfo[0].pdfPageHeight;
      const pdfPageWidth    = pgInfo[0].pdfPageWidth;
      if (debug) { console.log('pdfWidth=', pdfPageWidth,
                            ' pdfHeight=', pdfPageHeight);
      }

      // get the ratio of the rendered dot on the rendered page size
      const ratioX   = pgX / pgWidth;
      const ratioY   = pgY / pgHeight;
      if (debug) { console.log('ratioX=', ratioX, ' ratioY=', ratioY); }

      // Finally transform the display coordinates to 72dpi coordinates
      const pdfPageX = Math.round(pdfPageWidth  * ratioX);
      let pdfPageY = Math.round(pdfPageHeight * ratioY);

      // Tranform the coordinates to origin (0,0) on lower left corner of page
      pdfPageY = pdfPageHeight - pdfPageY;
      if (debug) { console.log('page=' + pdfpagenbr + ' final pdf 72dpi X,Y=' + pdfPageX + ',' + pdfPageY); }

      // UPDATE MARKUP3: Update the markup pdfpagenbr and viewer coords
      // Note for embedded images without
      markup.pdfPageX       = pdfPageX;
      markup.pdfPageY       = pdfPageY;
      markup.pdfPageWidth   = pdfPageWidth;
      markup.pdfPageHeight  = pdfPageHeight;
      if (debug) { console.log('markup=', markup); }
      // CLEANUP: Important last step to prevent further updates to the markup.
      this.lastNoteId = null;
      if (debug) { console.log('noteData=', this.noteData); }

      } catch (e) {
          console.log('markup error=', e);
          window.alert('Warning markup data error! e=' + e);
          // window.alert('Error: Please open a file first!');
        }
     } // if lastNoteId

    } // mouseUp

    // Note: can change this to canDrop(item: CdkDrag, list: CdkDropList) also.
    // UNUSED AT PRESENT
    canDrop() {
      let res = false;
      if (this.documentsvc.activeDocument) {
         res = true;
      } else {
         window.alert("Error: Please load a file first!");
        }
      return res;
    }

    checkUserAccess(){
        if (this.documentsvc.activeDocument && this.documents.filter((f) => f.uid === this.documentsvc.activeDocument).length === 0){
            this.resetDocumentState();
        }
    }

    resetDocumentState() {
//        this.pdfSrc = { url: ''};
        this.documentsvc.pdfSrc = null;
        this.documentsvc.pdfURL = null;
        this.documentsvc.pdfSrc = null;
        this.documentsvc.pdfURL = null;
        this.documentsvc.pdfRotation = 0;
        // this.documentsvc.pdfZoom = 1.0;
        // Note commment the following line out to preserve last zoom
        // on the next document upload.
        this.pdf_set_zoom( 1.0 );
        this.documentsvc.activeDocument = null;
        this.activeDocumentName = null;
        this.activeDocumentLink = null;

        if ( this.noteDataSubscription ) {
           this.noteDataSubscription.unsubscribe();
        }

        this.clear_dialogs();
        this.clear_notes();

        this.noteData = {
            note: [],
            question: [],
            issue: [],
            approval: [],
        };
        this.oldNoteData = {
            note: [],
            question: [],
            issue: [],
            approval: [],
        };

        this.userLayer = null;
        this.layersvc.setLayerData(null);
        this.markersvc.setNoteData(null);
    }

    onScrollContent(evt: Event) {
        // console.log('scroll overlay=', evt);
        const debug = false;
        const pgX = this.screenWidth / 2;
        const pgY = this.screenHeight / 3;
        const elist = document.elementsFromPoint(pgX, pgY);
        // if (debug) { console.log('scroll elist=', elist); }
        for (const ele of elist) {
          if (ele.getAttribute('class') === 'page') {
            // console.log("page=true");
            const pge = ele;
            const pg = Number( ele.getAttribute('data-page-number') );
            if ( pg !== this.documentsvc.pdfPageNbr ) {
              this.documentsvc.pdfPageNbr = pg;
              this.documentsvc.displayPageNbr = pg;
            }
            if (debug) { console.log('pg=', pg); }
          }
        }
    }

    getNumberOfDocuments() {
        if (this.documents) {
            return (this.documents as DocumentData[]).length;
        }
    }

    getTotalFileSizeStr() {
        let bytes = 0;
        if (this.documents) {
            if (this.documents.length > 0) {
                bytes = this.documents.map((m) => m.size).reduce((a, b) => a + b);
            }

            if (bytes > 1000000000) {
                return (bytes / 1000000000).toFixed(2) + ' GB';
            }
            if (bytes > 1000000) {
                return (bytes / 1000000).toFixed(2) + ' MB';
            }
            if (bytes > 1000) {
                return (bytes / 1000).toFixed(2) + ' KB';
            }
            if (bytes < 1000){
                return (bytes) + ' Bytes';
            }
        } else {
            return bytes;
          }
    }


  setSelectedOrg(e) {
      console.log('main setSelectedOrg e=', e);

      if ( e === "NONE" ) {
         this.selectedOrg = null;
      } else {
           this.selectedOrg = e;
           const ou = this.getSelectedOrgUser(e);
           this.setSelectedOrgUser(ou);
        }
   }

  setSelectedOrgUser(e) {
      console.log('main setSelectedOrgUser e=', e);

      if ( e === "NONE" ) {
         this.selectedOrgUser = null;
      } else {
           this.selectedOrgUser = e;
        }
   }

  setSelectedOrgContact(e) {
      console.log('main setSelectedOrgContact e=', e);

      if ( e === "NONE" ) {
         this.selectedOrgContact = null;
      } else {
           this.selectedOrgContact = e;
        }
   }

   getSelectedOrgUser(org=this.selectedOrg) {
      let ou = null;
      if ( org && this.orgusersvc.OrgUserList ) {
        ou = this.orgusersvc.OrgUserList.find(x => x.org_uid === org.uid);
      }
      return ou;
   }

// 279 - Change selected group in other dialogs or components
    setSelectedGroup(guid) {
       // update other components that use this.groups
       this.selectedGroup = guid;
       this.selectedGroupObj = this.groups.find(g => g.uid === guid);
       this.groupsvc.setSelectedGroup(this.selectedGroupObj);
       // console.log('main: setSelectedGroup called groupobj=', this.selectedGroupObj);
       this.updateDocumentList();
       this.updateDialogGroupList(this.groups);
    }

   setSelectedGroupObj(g) {
       this.selectedGroupObj = g;
       // this.selectedGroup = g.uid;
       this.setSelectedGroup(g.uid);
       this.leftNavText=g.name;
    }

    updateSelectedGroup(grp: GroupData) {
       // console.log('main updateSelectedGroup grp=', grp);
       // console.log('main updateSelectedGroup grp.uid=', grp.uid);
       this.selectedGroupObj = grp;
       this.selectedGroup = grp.uid;
       // console.log('main updateSelectedGroupObj=', this.selectedGroupObj);
       // console.log('main updateSelectedGroup=', this.selectedGroup);
    }

    setSelectedDocObj(d) {
       if (d && d.uid) {
          this.selectedDocObj = d;
          this.selectedDocUID = d.uid;
          this.openDocument(d);
       }
     }

// 279 CJ - Rewrite for group service
    async updateDocumentList() {
       try {
            this.documents = await this.documentsvc.getDocuments();
            // Logic to refresh bound variable documents
            // Restricts update to initial (undefined), array size,
            // or timestamp changes.
            if (this.documents === undefined ||
                JSON.stringify(this.documents.map((m) => m.timestamp).sort()) !==
                JSON.stringify((this.documentsvc.getDocumentList() as DocumentData[]).map((m) => m.timestamp).sort())) {
                     this.documents = this.documentsvc.getDocumentList() as DocumentData[];
            }
            this.getUserGroups(this.userInfo.uid);
        } catch (e) {
                     console.log('ERROR: ', e);
                     clearInterval(this.updateInterval);
                     this.router.navigate(['/login'], {replaceUrl: true});
        }
    }

// 279 - CJ - Replaced old group/member functions with group & group-member
//            services and dialogs

    show_members(guid){
        this.isMembersVisible = !this.isMembersVisible;
    }

    checkOwnership() {
        if (this.documents.filter((d) => d.uid === this.documentsvc.activeDocument).length === 0 ){
            return false;
        } else {
            return this.groups.filter(
                (f) => f.owner === this.userData.uid).filter(
                    (f) => f.uid === this.documents.filter(
                        (d) =>  d.uid === this.documentsvc.activeDocument)[0].groups_uid).length > 0;
        }
    }

    // closes any container / use for close buttons
    close(containerID) {
        document.getElementById(containerID).style.display = 'none';
    }

    minimize_comments(idSection: string){
        const displayState = document.getElementById(idSection).style.height;
        if (displayState === '0px') {
            document.getElementById(idSection).style.opacity = '1';
            document.getElementById(idSection).style.height = 'auto';
            document.getElementById(idSection).style.width = 'auto';
        }else {
            document.getElementById(idSection).style.opacity = '0';
            document.getElementById(idSection).style.height = '0px';
            document.getElementById(idSection).style.width = '0px';
        }
    }
    // member list scroll
    scroll_down(memberDiv){
        const scrollPosition = document.getElementById(memberDiv).scrollTop;
        const scrollHeight = document.getElementById(memberDiv).scrollHeight;

        if ( scrollPosition === (scrollHeight - 135)){
            document.getElementById('scroll-down').style.opacity = '.4';
        } else {
            document.getElementById('scroll-down').style.opacity = '1';
        }
        document.getElementById('scroll-up').style.opacity = '1';
        document.getElementById(memberDiv).scrollTop += 20;
    }

    scroll_up(memberDiv){
       const scrollPositionTop = document.getElementById(memberDiv).scrollTop;

       if (scrollPositionTop === 0 ) {
           document.getElementById('scroll-up').style.opacity = '.4';
       } else {
           document.getElementById('scroll-up').style.opacity = '1';
       }
       document.getElementById('scroll-down').style.opacity = '1';
       document.getElementById(memberDiv).scrollTop -= 20;
    }

    collapseComments(idHeaderSection: string) {
    
           const openState = document.getElementById(idHeaderSection).style.height;
           if ( idHeaderSection && openState === '0px') {
               document.getElementById(idHeaderSection).style.opacity = '1';
               document.getElementById(idHeaderSection).style.height = 'auto';
               document.getElementById(idHeaderSection).style.width = 'auto';
           } else if ( idHeaderSection ) {
               document.getElementById(idHeaderSection).style.opacity = '0';
               document.getElementById(idHeaderSection).style.height = '0px';
               document.getElementById(idHeaderSection).style.width = '0px';
           }
    }

    testing() {
        // this.documents.forEach((d) => {
        //     this.group_lookup(d.groups_uid);
        // });
        this.getUserGroups(this.userInfo.uid);
        console.log(this.documents);
        console.log(this.groups);

        // this.settings.toggle_settings();
        this.clear_dialogs();
        this.redraw_notes('note');
        this.auth.getInfo();
        this.auth.getTokenInfo();
        console.log('layerUID: ', this.layerUid);
        console.log('ORIENT: ', this.documentsvc.pdfRotation, ' T: ', this.documentsvc.snapRotation);
        this.http.get(environment.apiUrl + '/document').subscribe(
            (x) => console.log(x),
            (e) => console.log(e)
        );
        console.log('USERDATA: ', this.userData);
        console.log('USERINFO: ', this.userInfo);
        console.log('NOTEDATA: ', this.noteData);
    }

    logout() {
        this.resetDocumentState();

        this.auth.logout();

// Cordova 10 and using router.navigate  are the fix for markadoc
// issues #86, #126
        this.router.navigate(['/login'], {replaceUrl: true});

// This wont work in cordova, expects an html page
//      window.location.href = '/login';
    }

    pdf_rotate(degree=0) {
        this.documentsvc.pdfRotation += degree;
        if (this.documentsvc.pdfRotation >= 360) {
            this.documentsvc.pdfRotation = 0;
        }
        console.log('rotation=', this.documentsvc.pdfRotation);
        console.log('degree=', degree);
        this._hide_notes();
        this.updateLayerData(this.layerUid);
    }

    _hide_notes() {
        if (!this.ishiding) {
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
                this.noteData[ntype].forEach((note, index) => {
                    if (document.getElementById(`user-${ntype}-${index + 1}`)) {
                    // console.log('note rotate =', note.rotation);
                    // console.log('pdf rotate =', this.pdfRotation);
                        if (note.orientation === this.documentsvc.pdfRotation) {
                            document.getElementById(`user-${ntype}-${index + 1}`).style.display = 'block';
                        } else {
                            document.getElementById(`user-${ntype}-${index + 1}`).style.display = 'none';
                        }
                    }
                });
            });
        }
    }

    adjust_header_width() {
        setTimeout(() => {
            let width = this.sidenavleft.opened ? (window.innerWidth * 0.155) : 0;  // left panel
            width += this.sidenavright.opened ? 66 : 0;  // right panel
            width += 20; // scrollbar width
            this.headerWidth = `${this.maincontainer.nativeElement.clientWidth}px`;
        },50);
    }

    adjust_overlay_width() {
        // console.log('overlay=', this.contentoverlay);
        setTimeout(() => {
            const viewer = document.getElementById('viewerContainer');
            // console.log('viewer=', viewer);
            // console.log('viewer width=', viewer.clientWidth);
            this.overlayWidth = viewer.clientWidth;
//          this.render.setStyle(this.contentoverlay.nativeElement,
//          'width', '${viewer.clientWidth}px');
        },20);
    }

   onKeydown(event) {
      if ( this.documentsvc.activeDocument ) {
         if (event.key === "PageUp") {
           this.pdfPageUp();
         }
         if (event.key === "PageDown") {
           this.pdfPageDown();
         }
      }
    }

    PDFPagesLoaded(event){
       // console.log('pdf pages loaded=', event);
       if ( event.pagesCount ) {
         this.documentsvc.pdfPageCount = event.pagesCount;
       }
       // console.log('PDFPagesLoaded loadPageNbr=', this.loadPageNbr);
       // console.log('PDFPagesLoaded loadNoteID=', this.loadNoteID);
       // handle loadPageNbr param
       if ( this.loadPageNbr !== null && this.loadNoteID === null ) {
        console.log('PDFLoadComplete goto page=', this.documentsvc.pdfPageNbr);
        this.documentsvc.pdfPageNbr = this.loadPageNbr;
        this.pdfGoToPage( this.documentsvc.pdfPageNbr );
        this.loadPageNbr = null;
       }
       if ( this.loadPageNbr===null && this.loadNoteID===null ) {
         this.pdfGoToPage( this.documentsvc.pdfPageNbr );
       }
    }
    PDFLoadingStarts(event){
       console.log('pdf load starts=', event);
    }
    PDFLoadingFailed(event){
       console.log('pdf load failed=', event);
    }

    PDFLoadComplete(event) {
       // console.log('ngx loadcomplete=', event);
       // this.pageCount = event;
       // console.log('pdf load complete=', event.pagesCount);
       this.documentsvc.pdfPageCount = event.pagesCount;
       this.adjust_overlay_width();
       this.getMaxPageWidth();
       // this.pdfGoToPage(this.documentsvc.pdfPageNbr);
       // 222-477 process noteID parameter
       //console.log('PDFLoadComplete loadPageNbr=', this.loadPageNbr);
       //console.log('PDFLoadComplete loadNoteID=', this.loadNoteID);
       if ( this.loadPageNbr !== null && this.loadNoteID === null ) {
         console.log('PDFLoadComplete goto page=', this.documentsvc.pdfPageNbr);
         this.documentsvc.pdfPageNbr = this.loadPageNbr;
         this.pdfGoToPage( this.documentsvc.pdfPageNbr );
       }
       //if ( this.loadPageNbr===null && this.loadNoteID!==null ) {
       //  this.scrollToNoteUUID( this.loadNoteID );
       //}
    }

   OLDPDFLoadComplete(event) {
       this.PDFDocumentTransport = event._transport;
       this.documentsvc.pdfPageCount = this.PDFDocumentTransport._numPages;
       // const pdfjs: PDFJSStatic;
       // console.log('PDFLoadComplete event=', event);
   }


    pdfSetPage(e: any) {
       // console.log('set page=', e);
       // const pg = Number( e.data );
       this.pdfGoToPage( e );
    }
    pdfPageNbrChange(e: Event) {
       console.log('page change=', e);
//       this.pdfPageNbr = this.e.value;
//       this.documentsvc.pdfPageNbr = this.e.value;
//         this.scrollToPage(pg);
    }
    pdfGoToPage(pg: number): number {
       this.documentsvc.pdfPageNbr = pg;
       this.documentsvc.displayPageNbr = pg;
       this.scrollToPage(this.documentsvc.pdfPageNbr);
       return this.documentsvc.pdfPageNbr;
    }
    pdfGoToFirstPage(): number {
       this.documentsvc.pdfPageNbr = 1;
       this.documentsvc.displayPageNbr = 1;
       this.scrollToPage(this.documentsvc.pdfPageNbr);
       return this.documentsvc.pdfPageNbr;
    }
    pdfGoToLastPage(): number {
       this.documentsvc.pdfPageNbr = this.documentsvc.pdfPageCount;
       this.documentsvc.displayPageNbr = this.documentsvc.pdfPageCount;
       this.scrollToPage(this.documentsvc.pdfPageNbr);
       return this.documentsvc.pdfPageNbr;
    }
    pdfPageUp(): number {
       if ( this.documentsvc.pdfPageNbr > 1 ) {
          this.documentsvc.pdfPageNbr = this.documentsvc.pdfPageNbr - 1;
          this.documentsvc.displayPageNbr = this.documentsvc.pdfPageNbr;
       }
       const app: IPDFViewerApplication = (window as any).PDFViewerApplication;
       // this.documentsvc.pdfPageNbr = this.documentsvc.pdfPageNbr;
       this.scrollToPage(this.documentsvc.pdfPageNbr);
       console.log('pdfPageNbr Up=', this.documentsvc.pdfPageNbr);
       // console.log('app=', app);
       return this.documentsvc.pdfPageNbr;
    }
    pdfPageDown(): number {
       if ( this.documentsvc.pdfPageNbr <
            this.documentsvc.pdfPageCount ) {
          this.documentsvc.pdfPageNbr++;
          this.documentsvc.displayPageNbr = this.documentsvc.pdfPageNbr;
       }
       console.log('pdfPageNbr Dn=', this.documentsvc.pdfPageNbr);
       // console.log('app=', window);
       this.scrollToPage(this.documentsvc.pdfPageNbr);
       return this.documentsvc.pdfPageNbr;
    }

   /** Iss#243 - Supports pageNbr and pdf coordinates calc.
    * ng2-pdf-viewer page rendered callback, which is called each time a page
    * is (re)rendered in the viewer, even to replace pages on load, zoom,
    * rotate, etc. @param e custom event
    */
    PDFPageRendered(e) {
      // CJ - Dismiss setDocument spinner dialog here eventually.

      if ( this.progressDialogRef ) {
         this.progressDialogRef.close();
      }

      // console.log('pagerendered event=', e);
      // this.pdfSrc = this.documentsvc.pdfSrc;

      // PDFPageView
      const src = e.source;
      // need this for true pdf page sizes
      const pgviewport = src.viewport;
      const pageRec   = new pageData();

      pageRec.pdfPageNbr    = e.pageNumber;
      pageRec.viewport      = pgviewport;
      pageRec.scale         = pgviewport.scale;
      pageRec.rotation      = pgviewport.rotation;
      // Get the 72dpi page width and height. divide by 72 for paper inches
      pageRec.pdfPageWidth  = Math.round(pgviewport.viewBox[2]);
      pageRec.pdfPageHeight = Math.round(pgviewport.viewBox[3]);

      // Either create or update the pageInfo data on load or reload
      if (this.pdfPageInfo[e.pageNumber - 1] === undefined) {
         this.pdfPageInfo.push(pageRec);
      }
      else {
         this.pdfPageInfo.push[e.pageNumber - 1] = pageRec;
      }
      this.adjust_overlay_width();
      this.getMaxPageWidth();
//      if ( e.pageNumber === 1 && this.loadNoteID !== null ) {
//          this.pdfGoToPage(this.documentsvc.pdfPageNbr);
//      }

      // this.pdfGoToPage(this.documentsvc.pdfPageNbr);

      // 222-477 process noteID parameter
      //         tried on loadLayers also
      if ( e.pageNumber === 1 ) {
         if ( this.loadPageNbr !== null ) {
             this.documentsvc.pdfPageNbr = this.loadPageNbr;
             this.pdfGoToPage( this.loadPageNbr );
             this.loadPageNbr = null;
         }
         if ( this.loadNoteID !== null ) {
             this.scrollToNoteUUID( this.loadNoteID );
             this.loadPageNbr = null;
             this.loadNoteID = null;
         }
         if ( this.loadPageNbr===null && this.loadNoteID===null ) {
             this.pdfGoToPage( this.documentsvc.pdfPageNbr );
         }
      }
   }

/*****
    PDFPageRendered(e) {
      // CJ - Dismiss setDocument spinner dialog here eventually.
      // this.pdfSrc = this.pdfSrc;

      // if ( this.progressDialogRef ) {
      //   this.progressDialogRef.componentInstance.close();
      // }
      const src = e.source;
      // need this for true pdf page sizes
      const pgviewport = src.viewport;
      const pageRec   = new pageData();

      pageRec.pdfPageNbr    = e.pageNumber;
      pageRec.viewport      = pgviewport;
      pageRec.scale         = pgviewport.scale;
      pageRec.rotation      = pgviewport.rotation;
      // Get the 72dpi page width and height. divide by 72 for paper inches
      pageRec.pdfPageWidth  = Math.round(pgviewport.viewBox[2]);
      pageRec.pdfPageHeight = Math.round(pgviewport.viewBox[3]);

      // Either create or update the pageInfo data on load or reload
      if (this.pdfPageInfo[e.pageNumber - 1] === undefined) {
         this.pdfPageInfo.push(pageRec);
      }
      else {
         this.pdfPageInfo.push[e.pageNumber - 1] = pageRec;
      }
   }
****/

   PDFLoadProgress(progressData: any) {
    //
    // progressData.total (bytes)
    // progressData.loaded (bytes)
    // progressData.percent (float)
    // progressData.type (load or print)
    // console.log('PDFLoadProgress event=', progressData);
    // const total = progressData.total; - for ng2-pdf-viewer
    // const pct = Math.round(( progressData.loaded / progressData.total ) * 100);
    const pct = Math.trunc(progressData.percent);

    // console.log('PDFLoadProgress pct=', pct);
    this.documentsvc.pdfProgress = pct;
    if ( pct === 100 ) {
        this.documentsvc.pdfProgress = 0;
    }
    if ( this.progressDialogRef ) {
       if ( this.progressDialogRef.componentInstance &&
            this.progressDialogRef.componentInstance.data ) {
              this.progressDialogRef.componentInstance.data = {progress: pct};
              if ( pct === 100 ) {
                 this.progressDialogRef.componentInstance.close();
              }

       }
    }
   }

   PDFSave(blobUrl) {
      const a = document.createElement('a');
      a.href        = blobUrl;
      if ( this.platformOS === 'ios' ) {
         a.target      = '_self';
      } else {
          a.target      = '_parent';
      }
      a.download    = this.activeDocumentName;
      document.body.appendChild(a);
      // a.click = () => { };
      // a.onload = () => { window.print(); };
      a.click();
      // a.remove();
      if (this.progressDialogRef?.componentInstance) {
        this.progressDialogRef?.componentInstance.close();
      }
   }
   async PDFDownload() {
      this.openProgressDialog('Processing',
                              'Preparing file for download...',
                              'indeterminate' );
      const fblob = await this.documentsvc.getFileAsBlob(this.documentsvc.activeDocument);
      const doc = this.documents.filter((d) => d.uid === this.documentsvc.activeDocument.uid)[0];
      const blobUrl = window.URL.createObjectURL(fblob);
      this.PDFSave(blobUrl);
   }

   async PDFDownloadBoth() {
      this.openProgressDialog('Processing',
                              'Preparing file for download...',
                              'indeterminate' );
      const doc = await this.documentsvc.getFileAsArrayBuffer(this.documentsvc.activeDocument);
      const mdoc = await this.mergeMarkerReport(doc);
      console.log('typeof mdoc=', typeof mdoc);
      const blob = new Blob([mdoc], {type: 'application/pdf' });
      const blobUrl = await window.URL.createObjectURL(blob);
      this.PDFSave(blobUrl);
   }

   async PDFDownloadAnnotated() {
      this.openProgressDialog('Processing',
                              'Preparing file for download...',
                              'indeterminate' );
      const doc = await this.annotateDocument();
      const mdoc = await this.mergeMarkerReport(doc);
      console.log('typeof mdoc=', typeof mdoc);
      const blob = await new Blob([mdoc], {type: 'application/pdf' });
      const blobUrl = await window.URL.createObjectURL(blob);
      this.PDFSave(blobUrl);
   }

   onPrintImgLoaded(e) {
     console.log('img load printImg=', this.printImg);
     console.log('img load e=', e);
     if (this.progressDialogRef?.componentInstance) {
       this.progressDialogRef?.componentInstance.close();
     }
   }

/***
   printPopup(data) {
     let myWindow = window.open('', 'my div', 'height=400,width=600');
     myWindow.document.write('<html>');
     myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
     myWindow.document.write('<body >');
     myWindow.document.write(data);
     myWindow.document.write('</body></html>');
     myWindow.document.close(); // necessary for IE >= 10
     myWindow.onload=function(){ // necessary if the div contain images
       myWindow.focus(); // necessary for IE >= 10
       myWindow.print();
       myWindow.close();
     };
   }
   ****/

   printSrc(src) {
      console.log('src=', src);
      const iframe = document.createElement('iframe');
      iframe.className = 'pdfIFrame';
      document.body.appendChild(iframe);
      iframe.style.display = 'block';
      iframe.onload = (): void  => {
        if (this.progressDialogRef?.componentInstance) {
          this.progressDialogRef?.componentInstance.close();
        }
        iframe.contentWindow.print();
        window.URL.revokeObjectURL(src);
      };
      iframe.src = src;
   }

   /***
    printSrc2(source) {
        const pagelink = "about:blank";
        let  pwa = window.open(pagelink, "_new");
        pwa.document.open();
        pwa.document.write(this.getPrintHTML(source));
        // pwa.document.close();
   }
   ***/

   getPrintHTML(source) {
         return '<html><head><script>function step1(){\n' +
                'setTimeout("step2()", 10);}\n' +
                'function step2(){window.print();window.close()}\n' +
                '</script></head><body onload="step1()">\n' +
                '<img src=' + source + ' /></body></html>';
    }

   async PDFPrint() {
      this.openProgressDialog('Loading',
                              'Loading Print Preview...',
                              'indeterminate' );
      const fblob = await this.documentsvc.getFileAsBlob(this.documentsvc.activeDocument);
      window.URL = window.URL || window.webkitURL;
      const blobUrl = await window.URL.createObjectURL(fblob);
      const doc = this.documents.filter((d) => d.uid === this.documentsvc.activeDocument.uid)[0];
      console.log('this.printDiv=', this.printDiv);
      console.log('this.printImg=', this.printImg);
      this.printSrc(blobUrl);
  }


   async PDFPrintBoth() {
      this.openProgressDialog('Loading',
                              'Loading Print Preview...',
                              'indeterminate' );
      const doc = await this.documentsvc.getFileAsArrayBuffer(this.documentsvc.activeDocument);
      // mdoc is a uint8array
      const mdoc = await this.mergeMarkerReport(doc);
      const len = mdoc.byteLength;
      let binary = '';
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( mdoc[ i ] );
      }
      // Constructor may be the problem...
      const blob = new Blob([mdoc], {type: 'application/pdf' });
      const blobUrl = await window.URL.createObjectURL(blob);
      this.printSrc(blobUrl);
   }

   async PDFPrintAnnotated() {
      this.openProgressDialog('Loading',
                              'Loading Print Preview...',
                              'indeterminate' );

      const doc = await this.annotateDocument();
      const mdoc = await this.mergeMarkerReport(doc);
      const blob = await new Blob([mdoc], {type: 'application/pdf' });
      const blobUrl = await window.URL.createObjectURL(blob);
      this.printSrc(blobUrl);
   }

   async mergeMarkerReport(doc) {
      // Instantiate marker report
      this.printMarkerRpt = true;
      const mrpt = this.markerRpt.getPDFArray();
      // console.log('mrpt=', mrpt);
      // console.log('doc=', doc);
      const mdoc = await this.pdfsvc.mergePdf(doc, mrpt);
      // console.log('mdoc=', mdoc);
      return mdoc;
   }

   async annotateDocument() {
      // Get the document
      const doc = await this.documentsvc.getFileAsArrayBuffer(this.documentsvc.activeDocument);
      // Add Annotations
      const adoc = await this.pdfsvc.annotatePdf(doc, this.noteData);
      return adoc;
   }

   _find_page(itemid) {
        this.documentsvc.pdfPageCount = this.pdf.pdfMultiPageViewer.pdfDocument._pdfInfo.numPages;
        const calc = Math.floor(parseInt(document.getElementById(itemid).style.top.replace('px', ''), 10)
            / (((document.getElementsByClassName('content-overlay')[0] as HTMLElement).offsetHeight
                + (this.documentsvc.pdfPageCount * 10))
                / this.documentsvc.pdfPageCount) + 1);
        return calc;
   }

/***
   _OLD_update_icon_location(type, index, sh) {
        const el = document.getElementById(`user-${type}-` + (index + 1));
        const overlayHeight = (document.getElementsByClassName('content-overlay')[0] as HTMLElement).offsetHeight;
        const vertRatio: number = parseInt(el.style.top.replace('px', ''), 10) / overlayHeight;
        const stepFactor = (this.documentsvc.pdfZoom - 1) * 4;

        setTimeout(
            () => {
                const height = ((document.getElementsByClassName('content-overlay')[0] as HTMLElement).offsetHeight);
                // el.style.top = (height * vertRatio) + sh + 'px';
                // el.style.left = (this.noteData[type][index].x * this.documentsvc.pdfZoom) + (8 * stepFactor) + 'px';
                el.style.top = (this.noteData[type][index].y * this.documentsvc.pdfZoom) + (6 * stepFactor) + 'px';
                el.style.left = (this.noteData[type][index].x * this.documentsvc.pdfZoom) + 'px';
            }, 50);
   }
***/

   _update_icon_location(m, type, index, sh) {
        const debug = false;
        const el = document.getElementById(`user-${type}-` + (index + 1));
        const overlay = document.getElementsByClassName('content-overlay')[0] as HTMLElement;
        const overlayHeight = overlay.offsetHeight;
        const overlayWidth = overlay.offsetWidth;
//        const vertRatio: number = parseInt(el.style.top.replace('px', ''), 10) / overlayHeight;
        const oldX = m.x;
        const oldY = m.y;


        if (debug) { console.log('m=', m); }
        if (debug) { console.log('el=', el); }

        // 0.5 - 1 = -0.5

        const zoom = this.documentsvc.pdfZoom;
        const stepFactor = (this.documentsvc.pdfZoom - 1) * 4;

        const ovwr = overlayWidth * ( 0.00485 / 2);
        const ovhr = overlayHeight * 0.00485;
//        const ovwo = ((this.documentsvc.pdfZoom - 1) * 4) * ovwr;
//        const ovho = ((this.documentsvc.pdfZoom - 1) * 4) * ovhr;

        let ovwo = 0;
        let ovho = 0;
        let newTop = 0;
        let newLeft = 0;

        const nx = this.noteData[type][index].x;
        const ny = this.noteData[type][index].y;

        // adjust for negative or positive zoom
        if ( zoom < 1.0 ) {
           ovwo = (((1 - zoom) * 8) * ovwr) * -1;
           ovho = (((1 - zoom) * 8) * ovhr) * -1;
           if ( zoom <= 0.25 ) {
              ovwo = ovwo * 2.5;
              ovho = ovho * 2.5;
           }
           newTop = (ny * this.documentsvc.pdfZoom) + ((ovho / 10) * 4);
           newLeft = (nx * this.documentsvc.pdfZoom)  +  ovwo;
        } else {
             ovwo = ((zoom - 1) * 4) * ovwr;
             ovho = ((zoom - 1) * 4) * ovhr;
             newTop = (ny * this.documentsvc.pdfZoom) + (ovho / 10);
             newLeft = (nx * this.documentsvc.pdfZoom)  + ovwo;
          }


/***
        const viewer = document.getElementById('viewer') as HTMLElement;
        const ovlinfo = viewer.getBoundingClientRect();
        const ovlh = ovlinfo.height;
        const ovlw = ovlinfo.width;

        const ovwr = ovlinfo.width * ( 0.00485 / 2);
        const ovhr = ovlinfo.height * ( 0.00485 / 2);
        let ovwo = ovwr;
        let ovho = ovhr;

        if (debug) { console.log('ovlinfo=', ovlinfo);}

****/
//        const newTop = (n.y * zoom) + yo + ovho;
//        const newLeft = (n.x * zoom) + xo + ovwo;
//        const newTop = (oldY * zoom) + ovho;
//        const newLeft = (oldX * zoom) + ovwo;

//        const newTop = (oldY * zoom);
//        const newLeft = (oldX * zoom);

        const height = ((document.getElementsByClassName('content-overlay')[0] as HTMLElement).offsetHeight);

        setTimeout(
            () => {
//                el.style.top = (height * vertRatio) + sh + 'px';
                el.style.top = newTop + 'px';
//                el.style.left = (newLeft * this.documentsvc.pdfZoom) + (6 * stepFactor) + 'px';
                el.style.left = newLeft + 'px';
                if ( this.noteData[type][index] && this.noteData[type][index].dialogRef !== null && this.noteData[type][index].dialogRef.instance ) {
                   this.noteData[type][index].dialogRef.instance.top = newTop + 20;
                   this.noteData[type][index].dialogRef.instance.left = newLeft + 16;
                }
            }, 50);
   }

    onPinchIn(event) {
//        this.pdf_zoom_out(0.0125);
        this.pdf_zoom_out(0.25);
    }

    onPinchOut(event) {
//        this.pdf_zoom_in(0.0125);
        this.pdf_zoom_in(0.25);
    }

    pdf_set_zoom(amt) {
       // amt is 0.25, 1.0, 3.0 etc.
       this.documentsvc.pdfZoom = amt;
       if ( typeof this.documentsvc.pdfZoom === 'number' ) {
          this.documentsvc.pdfZoomPct = this.documentsvc.pdfZoom * 100;
       }
       // this.clear_notes();
       setTimeout( () => {
          this._update_note_locations(1);
//          this.redraw_notes('note');
//          this.redraw_notes('question');
//          this.redraw_notes('issue');
//          this.redraw_notes('approval');
       }, 100);
    }

    _update_note_locations(n: number) {
            this.noteData.note.forEach((note, index) => {
                this._update_icon_location(note, 'note', index, n);
            });
            this.noteData.question.forEach((note, index) => {
                this._update_icon_location(note, 'question', index, n);
            });
            this.noteData.issue.forEach((note, index) => {
                this._update_icon_location(note, 'issue', index, n);
            });
            this.noteData.approval.forEach((note, index) => {
                this._update_icon_location(note, 'approval', index, n);
            });
    }

    pdf_zoom_in(amount) {
        let nz = 100;
        if (this.documentsvc.pdfZoom < this.documentsvc.pdfMaxZoom) {
            nz = this.documentsvc.pdfZoom + amount;
        } else {
              nz = this.documentsvc.pdfMaxZoom;
          }
        const z = Math.round((nz + Number.EPSILON) * 100) / 100
        this.pdf_set_zoom(z);
        return true;
    }

    pdf_zoom_out(amount) {
        let nz = 100;
        if (this.documentsvc.pdfZoom > this.documentsvc.pdfMinZoom) {
            nz = this.documentsvc.pdfZoom - amount;
        } else {
              nz = this.documentsvc.pdfMinZoom;
        }
        const z = Math.round((nz + Number.EPSILON) * 100) / 100
        this.pdf_set_zoom(z);
        return true;
    }

    toggle_left_nav() {
        this.sidenavleft.toggle();
        this.toggleLeftNav = ! this.toggleLeftNav;
        let hwidth = '100%';
        if (this.sidenavright.opened) { hwidth += ' - 120px'; }
        if (this.sidenavleft.opened) { hwidth += ' - 200px'; }
        this.headerWidth = 'calc(' + hwidth + ')';
    }

    toggle_right_nav() {
        this.sidenavright.toggle();
        this.toggleRightNav = ! this.toggleRightNav;
        const sicons = document.getElementById('send-icon');
        if (sicons) {
          if (sicons.style.right === (20 + 'px')) {
            const rsidnav = document.getElementById('right-sidenav-id').offsetWidth;
            if (rsidnav === 109) {
                sicons.style.right = '130px';
            } else {
                sicons.style.right = '94px';
            }
          } else {
              sicons.style.right = '20px';
            }
        }
        let hwidth = '100%';
        if (this.sidenavright.opened) { hwidth += ' - 120px'; }
        if (this.sidenavleft.opened) { hwidth += ' - 200px'; }
        this.headerWidth = 'calc(' + hwidth + ')';
    }

    hide_panels() {
        // document.getElementById('feedback-icon').style.left = 20 + 'px';
        // document.getElementById('send-icon').style.right = 20 + 'px';
        if (this.sidenavright.opened) { this.toggle_right_nav(); }
        if (this.sidenavleft.opened) { this.toggle_left_nav(); }
        // this.sidenavleft.close();
        // this.sidenavright.close();
        return true;
    }

    createUUID() {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        // eslint-disable-next-line no-bitwise
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';

        return s.join('');
    }


// *** Changes per issue #243 - C. Janick
// *** Need to add page number and other coordinates to model
    _create_item(itemType: string, x, y): MarkerData {
        const debug = false;
        // #520 normalize x,y to pdfZoom factor 1.0
        // at zoom 1.0 vx=1000, vy=500, x=500, y=250
        // Assume zoom 2.0
        // vx=2000, vy=1000, x=1000, y=500 zoom=2 x/2=500, y/2=250
        // assume zoom 0.5
        // vx=500, vy=250, x=250, y=125 zoom=0.5 x/0.5=500, y/0.5=250
        if (debug) {console.log('x,y = ', x, ',', y);}
        // *** BAD
        const newX = x / this.documentsvc.pdfZoom;
        const newY = y / this.documentsvc.pdfZoom;
        if (debug) {console.log('newX,Y = ', newX, ',',  newY);}
        return {
            comments: [],
            created_at: Date.now(),
            description: '',
            orientation: this.documentsvc.pdfRotation,
            owner: '',
            pageNbr: null,
            pdfPageWidth: 0,
            pdfPageHeight: 0,
            pdfPageX: 0,
            pdfPageY: 0,
            shown: false,
            title: '',
            type: itemType,
            uid: '',
            uuid: this.createUUID(),
            viewPageHeight: 0,
            viewPageWidth: 0,
            viewPageX: 0,
            viewPageY: 0,
            viewerX: 0,
            viewerY: 0,
            x: newX,
            y: newY,
            id: '',
            selectedText: '',
            approvals: [],
            approvalScope: 'D',
            element: '',
            markerRef: null,
            dialogRef: null,
        };
    }

    _create_marker(item: MarkerData, x, y): ComponentRef<MarkerComponent> {
        let marker: ComponentRef<MarkerComponent> = null;
        marker = this.overlayRef.createComponent(MarkerComponent);

        item.markerRef = marker;
        item.x = x;
        item.y = y;
        console.log('item=', item);
        console.log('x,y=', x + ',' + y);
        const markerStyle = `position: absolute; top: ${y}px; left: ${x}px; z-index: 1000; width: 32px; height: 40px;`;
        marker.instance.markerStyle = markerStyle;
        // console.log('style=', markerStyle);
        marker.instance.id = item.id;
        marker.instance.position = 'absolute';
        marker.instance.top = y;
        marker.instance.left = x;
        // console.log('marker ref=', marker);
//      marker.instance.className = 'right-side-icon';

        // Added for icon drop placement fix
        marker.instance.margin = 0;
        marker.instance.padding = 0;
        marker.instance.border = 0;
        marker.changeDetectorRef.detectChanges();
        return marker;
    }

    _create_item_dialog(elem: HTMLElement, name: string) {
        // Structural info from element id
        const sInfo = name.split('-');
        const itemType = sInfo[1];
        let itemDisplay;
        if (itemType === 'note') {
            itemDisplay = 'comment';
        }
        else {
            itemDisplay = itemType;
        }
        const itemIndex = parseInt(sInfo[2], 10) - 1;

        if (this.noteData[itemType][itemIndex] && this.noteData[itemType][itemIndex].shown === false) {
            const newEl = document.createElement('div');
            const itemData = this.noteData[itemType][itemIndex];
            let commentData = '';
            let remover = '';
            newEl.id = 'dialog-' + name;
            newEl.className += ' item-info';
            newEl.style.position = 'absolute';
            const srcEl = document.getElementById(`user-${itemType}-${itemIndex + 1}`);
            newEl.style.top = parseInt(srcEl.style.top.replace('px', ''), 10) + (srcEl.offsetHeight / 2) + 'px';
            newEl.style.left = parseInt(srcEl.style.left.replace('px', ''), 10) + (srcEl.offsetWidth / 2) + 'px';
            newEl.style.backgroundColor = '#FDFFFC';
            newEl.style.zIndex = '10000';
            const created = new Date(this.noteData[itemType][itemIndex].created_at);

            // Allow creator of annotation or document owner to remove annotation
            if (this.noteData[itemType][itemIndex].owner === this.userInfo.email ||
                this.userInfo.uid === this.get_current_document(this.documentsvc.activeDocument).users_uid
               ) {
                remover = `
                   <div><a style="color:white; text-decoration: none;" href='#' id='remove_${newEl.id}'
                            <span class="material-icons">delete</span>
                        </a></div>
                `;
            }

            elem.appendChild(newEl);
            if (this.platformOS === 'ios' || this.platformOS === 'macos' ) {
               newEl.scrollIntoView(true);
            } else {
               newEl.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
            }

            if (this.noteData[itemType][itemIndex].comments.length > 0) {
                this.noteData[itemType][itemIndex].comments.forEach((cmt) => {
                    const commentCreated = new Date(cmt.created_at);
                    commentData += `
                        <div class="color-${itemType}-comment margin8">
                            <div class="left-right-space pad8">
                                <div><b>${cmt.owner}</b> </br> <span id="time">${commentCreated.toLocaleString()}</span></div>
                            </div>
                            <div class="pad8">
                                ${cmt.comment}
                            </div>
                        </div>
                    `;
                });
            }
            if (this.noteData[itemType][itemIndex].owner !== '') {
                newEl.innerHTML = `
                    <div class="detail-container ">
                        <div class="left-right-space color-${itemType}-dark pad4">
                            <div>${itemData.title}</div>
                            ${remover}
                        </div>
                        <div class="left-right-space pad8">
                            <div><b>${itemData.owner}</b> </br> <span id="time">${created.toLocaleString()}</span></div>
                        </div>
                        <div class="pad8">
                            ${itemData.description}
                        </div>
                        ${commentData}
                        <div class="pad8">
                            <input class="input-add-comment" id="add-comment-${name}" type="text" inputmode="text" placeholder="add comment" >
                        </div>
                    </div>`;
            } else {
                newEl.innerHTML += `
                    <div class="detail-container">
                        <div class="left-right-space color-${itemType}-dark pad4">
                            <div>${itemDisplay}</div>
                        </div>
                        <div class="pad8">
                            <label id="add-comment-title" >Title</label>
                            <input id="title-${name}" class="input-title" type="textbox" maxlength="35" required>
                            <label id="add-comment-tittle">Description</label>
                            <textarea id="description-${name}" class="input-description" rows="10"></textarea>

                            <input id="submit-${name}" class="add-item-button" type="submit" value="Add ${itemDisplay}"></input>
                            <input id="cancel-${name}" class="cancel-button" type="button" value="Cancel"></input>
                        </div>
                    </div>
                    `;
            }

            const cancelEl = document.getElementById(`cancel-${name}`);
            if (cancelEl) {
                this.render.listen(cancelEl, 'click', (ev) => {
                    const dialog = cancelEl.parentNode.parentNode;
                    dialog.parentNode.removeChild(dialog);
                });
            }

            const removeEl = document.getElementById(`remove_${newEl.id}`);
            if (removeEl) {
                this.render.listen(removeEl, 'click', (ev) => {
                    // console.log('remove removeEl=', removeEl);
                    // console.log('remove itemType=', itemType);
                    // console.log('remove itemIndex=', itemIndex);
                    // console.log('remove uuid=', this.noteData[itemType][itemIndex].uuid);
                    this.remove_note(this.noteData[itemType][itemIndex].uuid);
                });
            }

            const submitEl = document.getElementById(`submit-${name}`);
            if (submitEl) {
                this.render.listen(submitEl, 'click', (ev) => {
                    const len = this.userLayer[itemType].length;
                    this.noteData[itemType][itemIndex].title =
                        (document.getElementById(`title-${name}`) as HTMLInputElement).value;
                    this.noteData[itemType][itemIndex].description =
                        (document.getElementById(`description-${name}`) as HTMLInputElement).value;
                    this.noteData[itemType][itemIndex].owner = this.userInfo.email;

                    this.userLayer[itemType][len] = this.noteData[itemType][itemIndex];

                    const el = document.getElementById('dialog-' + name);
                    if (el) {
                        el.parentNode.removeChild(el);
                        this.noteData[itemType][itemIndex].shown = false;
                    }
                    this.sendNotify(
                        {
                            document: this.get_current_document(this.documentsvc.activeDocument),
                            user: this.userInfo,
                            type: itemType
                        }
                    );
                    this.updateLayerData(this.layerUid);

                });
            }

            const commentEl = document.getElementById(`add-comment-${name}`);
            if (commentEl) {
                    console.log("is commentEl");
/****** changed to change event
                    this.render.listen(commentEl, 'keydown', (ev) => {
                        console.log('keydown ev = ', ev);
                          if (ev.keyCode === 13) {
                           console.log('Enter Key Pressed');
                          }
                      });
                    this.render.listen(commentEl, 'input', (ev) => {
                        console.log('input    ev = ', ev);
                      });
*********/
                    this.render.listen(commentEl, 'change', (ev) => {
                       console.log("change event ev=", ev);
                       console.log("change value=", ev.target.value);
                       this.noteData[itemType][itemIndex].comments.push(
                            {
                                owner: this.userInfo.email,
                                comment: ev.target.value,
                                created_at: Date.now()
                           });
                       const el = document.getElementById('dialog-' + name);
                       if (el) {
                           el.parentNode.removeChild(el);
                           this.noteData[itemType][itemIndex].shown = false;
                       }
                       this.sendNotify(
                           {
                               document: this.get_current_document(this.documentsvc.activeDocument),
                               user: this.userInfo,
                               type: 'comment'
                           }
                       );
                       console.log('updating comment data=', this.noteData[itemType][itemIndex]);
                       this.updateComments(this.noteData[itemType][itemIndex]);
                });
            }

            this.noteData[itemType][itemIndex].shown = true;
        } else {
            const el = document.getElementById('dialog-' + name);
            if (el) {
                el.parentNode.removeChild(el);
                this.noteData[itemType][itemIndex].shown = false;
            }
        }
        return;
    }

    updateUIData( data ) {
      // console.log('uiData noteMarkers=', this.noteMarkers);
      ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
        // Update Markers
        this.noteMarkers[ntype].forEach((uiObj, index) => {
           const item = this.findNoteData(ntype, uiObj.uuid, data);
           if (item === null ) {
              // marker was deleted, remove marker
              this.destroyMarkerRef(uiObj.uuid);
           }
        });
        // console.log('updtUI after noteMarkers=', this.noteMarkers);
        // Update Dialogs
        // console.log('updtUI noteDialogs=', this.noteDialogs);
        // console.log('updtUI noteData=', data);
        this.noteDialogs[ntype].forEach((uiObj, index) => {
           const item = this.findNoteData(ntype, uiObj.uuid, data);
           // console.log('updtUI item =', item);
           if (item === null ) {
               // If not adding, marker dialog was deleted, remove dialogRef
               console.log('updtUI destroy dialog=', uiObj.uuid);
               const ele = uiObj.dialogRef.location.nativeElement;
              const cnt = ele.getElementsByClassName('add-item-button').length;
               console.log('updtUI destroy cnt=', cnt);
               if ( cnt === 0 ) {
                  this.destroyMarkerDialogRef(uiObj.uuid);
               }
           } else {
               // update the dialog itemData
               console.log('updtUI updating dialog obj=', uiObj);
               console.log('updtUI updating item=', item);
               // Use observable to call updateUIData();
               this.cdr.detectChanges();
               // uiObj.dialogRef.instance.setItemData( null, false );
               const ele = uiObj.dialogRef.location.nativeElement;
               console.log('updtUI ele=', ele);
               let cnt = ele.getElementsByClassName('add-item-button').length;
               cnt = cnt + ele.getElementsByClassName('edit-item-button').length;
               console.log('updtUI cnt=', cnt);
               // Only update the dialog if not adding or editing
               if ( cnt === 0 ) {
                  uiObj.dialogRef.instance.setItemData( item, false );
                  // this.scrollToDialog(ntype, index+1);
               }
             }
        });
        // console.log('uiData after dialogMarkers=', this.noteDialogs);
      });
    }

    findNoteData(ntype: string, uuid: string, data=this.noteData): MarkerData | null {
      console.log('findNote uuid=', uuid);
      console.log('findNote ntype=', ntype);
      console.log('findNote noteData=', this.noteData[ntype]);
      let found = false;
      let note = null;
      data[ntype].forEach( (item, i) => {
         if ( item.uuid === uuid ) {
            found = true;
            note = item;
         }
      });
      //console.log('findNote found=', found);
      //console.log('findNote note=', note);
      return note;
    }

    findMarkerRef(item: MarkerData) {
      //console.log('findMarkerRef item=', item);
      this.noteDialogs[item.type].forEach( (obj, i) => {
         if ( obj.uuid === item.uuid ) {
            return obj.markerRef;
         }
      });
      return null;
    }

    findDialogRef(item: MarkerData) {
      //console.log('findDialogRef item=', item);
      let ref = null;
      this.noteDialogs[item.type].forEach( (obj, i) => {
         if ( obj.uuid === item.uuid ) {
            console.log('findDialogRef FOUND ref=', obj.dialogRef);
            ref = obj.dialogRef;
         }
      });
      return ref;
    }

    findDialogObj(item: MarkerData) {
      if ( !item || item === null) { return null; }
      //console.log('findDialogObj item=', item);
      let ref = null;
      this.noteDialogs[item.type].forEach( (obj, i) => {
         if ( obj.uuid === item.uuid ) {
            //console.log('findUIObjRef FOUND ref=', obj);
            ref = obj;
         }
      });
      return ref;
    }

    destroyMarkerRef(uuid: string){
      if ( uuid === null ) { return }
      // Will lose this part later
      //if ( item.markerRef && item.markerRef !== null ) {
      //   item.markerRef.destroy();
      //   item.markerRef = null;
      //}
      // Find and destroy from noteMarkers
      ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
           this.noteMarkers[ntype].forEach((obj, index) => {
              if ( obj.uuid === uuid ) {
                 if ( obj.markerRef && obj.markerRef !== null ) {
                    obj.markerRef.destroy();
                    obj.markerRef = null;
                    this.noteMarkers[ntype].splice(index, 1);
                 }
              }
           });
      });
    }

    destroyMarkerDialogRef(uuid: string){
      if ( uuid === null ) { return }
      // Will lose this part later
      //if ( item.dialogRef && item.dialogRef !== null ) {
      //   item.dialogRef.destroy();
      //   item.dialogRef = null;
      //}
      // Find and destroy from noteDialogs
      ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
           this.noteDialogs[ntype].forEach((obj, index) => {
              if ( obj.uuid === uuid ) {
                 console.log('destroying dialogRef uuid=', uuid);
                 if ( obj.dialogRef && obj.dialogRef !== null ) {
                    obj.dialogRef.destroy();
                    obj.dialogRef = null;
                    this.noteDialogs[ntype].splice(index, 1);
                 }
              }
           });
      });
    }

    markerCancel(e) {
      console.log('markerCancel e=', e);
      this.destroyMarkerDialogRef(e.uuid);
      //if(e.dialogRef) {
      //   e.dialogRef.destroy();
      //   e.dialogRef = null;
      //}
    }

    async markerRemove(e) {
      console.log('markerRemove e=', e);
        //if(e.dialogRef) {
        //  e.dialogRef.destroy();
        //  e.dialogRef = null;
        //}
        this.destroyMarkerDialogRef(e.uuid);
        await this.remove_note(e.uuid);
    }

    markerLink(e) {
      console.log('main markerLink e=', e);
      this.linkOrigin = window.location.origin;
      this.linkTitle = '';
      this.linkURL = '';
      this.linkPageNbr = 1;
      this.linkDocumentData = null;
      this.linkLayerData = [];
      this.linkNoteData = null;
      this.linkMarkerData = null;
      this.linkSrcData = e;

      if ( this.uiMode === this.UI_DESKTOP ) {
         this.openCreateLinkDialog(e);
      } else {
          this.sidenavCreateLink();
        }
    }

    markerLinkComment(e) {
      console.log('main markerLinkComment e=', e);
      this.linkOrigin = window.location.origin;
      this.linkTitle = '';
      this.linkURL = '';
      this.linkPageNbr = 1;
      this.linkDocumentData = null;
      this.linkLayerData = [];
      this.linkNoteData = null;
      this.linkMarkerData = null;
      this.linkSrcData = e;

      if ( this.uiMode === this.UI_DESKTOP ) {
         this.openCreateLinkDialog(e);
      } else {
          this.sidenavCreateLink();
        }
    }

    markerSubmit(e) {
      console.log('markerSubmit e=', e);
//      if(e.dialogRef) {
//         e.dialogRef.destroy();
//       e.dialogRef = null;
//      }
//        if ( e && (e instanceof MarkerData) ) {

        // this.sidenavMain();
        this.leftNavMode = this.NAV_MAIN_MENU;

        if ( e ) {
          const itemType = e.type;
          const len = this.userLayer[itemType].length;
          e.owner = this.userInfo.email;
          this.userLayer[itemType][len] = e;
          //e.dialogRef.destroy();
          //e.dialogRef = null;
          this.destroyMarkerDialogRef(e.uuid);
          this.sendNotify(
                   {
                    document: this.get_current_document(this.documentsvc.activeDocument),
                    user: this.userInfo,
                    type: itemType
                   }
          );
          this.updateLayerData(this.layerUid);
      }
    }

    markerUpdate(e) {
       console.log("markerUpdate e=", e);
       console.log("markerUpdate e.uid=", e.uid);
       console.log("markerUpdate e.uuid=", e.uuid);
       console.log("markerUpdate e.comments=", e.comments);

       const type = e.type;
       const uuid = e.uuid;
       const index = this.noteData[type].findIndex( m => m.uuid === e.uuid );

       console.log("markerUpdate notedata=", this.noteData[type][index]);

       // this.sidenavMain();
       this.leftNavMode = this.NAV_MAIN_MENU;

       // Send notification
       this.sendNotify(
                        {
                          document: this.get_current_document(this.documentsvc.activeDocument),
                          user: this.userInfo,
                          type: 'comment'
                        }
                       );

       // Update the marker changes

       this.updateMarkerData(this.noteData[type][index]);
    }

    markerUpdateApproval(e) {
       console.log("markerUpdateApproval e=", e);
       console.log("markerUpdateApproval e.uid=", e.uid);
       console.log("markerUpdateApproval e.uuid=", e.uuid);
       console.log("markerUpdateApproval e.comments=", e.comments);

       const type = e.type;
       const uuid = e.uuid;
       const index = this.noteData[type].findIndex( m => m.uuid === e.uuid );

       console.log("markerUpdate notedata=", this.noteData[type][index]);

       // this.sidenavMain();
       this.leftNavMode = this.NAV_MAIN_MENU;

       // Send notification
       this.sendNotify(
                        {
                          document: this.get_current_document(this.documentsvc.activeDocument),
                          user: this.userInfo,
                          type: 'approval'
                        }
                       );

       // Update the marker changes
       try {
          const item = this.noteData[type][index];
          this.updateMarkerData(item, false);
          this.toast.pop('success', 'Success', `Approval response & marker updated.`);
       } catch (e) {
           this.toast.pop('error', 'Error', `Cant update approval response`);
         }
    }

    handleInternalLink(data) {
        console.log('handleInternalLink data=', data);
        const docID = data.docid;
        const docOBJ = data.docobj;
        this.loadNoteID = null;
        this.loadPageNbr = null;
        console.log('handleInternalLink docid=', docID);
        if ( data.noteid && data.noteid !== null ) {
           this.loadNoteID = data.noteid;
           this.loadPageNbr = null;
           this.loadDocument(docID, 1);
        } else if ( data.page && data.page !== null ) {
                  this.loadPageNbr = data.page;
                  this.loadNoteID = null;
                  this.loadDocument(docID, this.loadPageNbr);
               }
        console.log('handleInternalLink noteid=', this.loadNoteID);
        console.log('handleInternalLink page=', this.loadPageNbr);
//        if ( docOBJ && docOBJ !== null ) {
//           console.log('handleInternalLink open doc=', docOBJ);
//           // this.loadDocument(docID, this.loadPageNbr, this.loadNoteID);
//           this.openDocument(docOBJ);
//        }
    }

    handleApprovalRequests(data) {
        console.log('handleApprovalRequests=', data);
        //this.approvalMarkerData = data;
        //this.sidenavApprovalRequests();
        this.updateMarkerData(data, false);
    }

    _create_marker_dialog(elem: HTMLElement, name: string, template: number) {
        // Structural info from element id
        const sInfo = name.split('-');
        const itemType = sInfo[1];
        let itemDisplay;
        if (itemType === 'note') {
            itemDisplay = 'comment';
        }
        else {
            itemDisplay = itemType;
        }
        const itemIndex = parseInt(sInfo[2], 10) - 1;

        // console.log("itemType=", itemType);
        // console.log("itemIndex=", itemIndex);
        const item = this.noteData[itemType][itemIndex];

        if ( ! item.dialogRef ) {
           item.dialogRef = null;
        }

        const el = document.getElementById(`user-${itemType}-` + (itemIndex + 1));
        const top: number = parseInt(el.style.top.replace('px', ''), 10);
        const left: number = parseInt(el.style.left.replace('px', ''), 10);

//        if ( item.dialogRef === null ) {

          const obj = this.findDialogObj(item);
          if ( obj === null ) {
             let mDialog: ComponentRef<MarkerDialogComponent> = null;
             try {
              mDialog = this.overlayRef.createComponent(MarkerDialogComponent);
             } catch (e) {
               console.error('ERROR creating component ref! e=', e);
               this.toast.pop('error', 'Marker Dialog', `Cant create component.`);
             }

           // item.dialogRef = mDialog;

           //console.log('cmd mDialog', mDialog);
           //console.log('cmd dialog item=', item);

           if ( item.uuid !== null ) {
              const uiData = new MarkerUIData();
              uiData.uuid = item.uuid;
              uiData.type = itemType;
              uiData.index = itemIndex;
              uiData.dialogRef = mDialog;
              this.noteDialogs[itemType].push(uiData);
              // console.log('cmd uiData.dialogRef=', uiData.dialogRef);
              // console.log('cmd uiData=', uiData);
              //console.log('cmd noteDialogs=', this.noteDialogs);
           }

           // item.x = (srcEl.offsetHeight / 2);
           // item.y = (srcEl.offsetWidth / 2);
//         console.log('crmd item=', item);
//         console.log('x,y=', x + ',' + y);

           mDialog.location.nativeElement.borderTopRightRadius = '8px';

           mDialog.setInput( 'noteData', this.noteData );
           mDialog.instance.id = 'dialog-' + name;
           mDialog.instance.setTemplate( template );
           mDialog.instance.setUserInfo( this.userInfo );
           mDialog.instance.setItemData( item );
           mDialog.instance.setActiveDocument(this.documentsvc.activeDocument);
           mDialog.instance.setGroupList(this.groups);
           mDialog.instance.owner = item.owner;
           mDialog.instance.itemType = itemType;
           mDialog.instance.itemDisplay = itemDisplay;
           mDialog.instance.className = 'dialog-' + name;
           mDialog.instance.position = 'absolute';
           mDialog.instance.top = top + 20;
           mDialog.instance.left = left + 16;
           mDialog.instance.created = item.created_at;

           mDialog.instance.cancelEvent.subscribe( data => {
              this.markerCancel(data);
           });
           mDialog.instance.removeEvent.subscribe( async data => {
              await this.markerRemove(data);
           });
           mDialog.instance.linkEvent.subscribe( data => {
              this.markerLink(data);
           });
           mDialog.instance.linkCommentEvent.subscribe( data => {
              this.markerLinkComment(data);
           });
           mDialog.instance.submitEvent.subscribe( data => {
              this.markerSubmit(data);
           });
           mDialog.instance.updateEvent.subscribe( data => {
              this.markerUpdate(data);
           });
           mDialog.instance.approvalEvent.subscribe( data => {
              this.markerUpdateApproval(data);
           });
           mDialog.instance.scrollToNoteEvent.subscribe( data => {
              this.scrollToNoteUUID(data);
           });
           mDialog.instance.scrollToPageEvent.subscribe( data => {
              this.scrollToPage(data);
           });
           mDialog.instance.handleInternalLinkEvent.subscribe( data => {
              this.handleInternalLink(data);
           });
           mDialog.instance.requestEvent.subscribe( data => {
              this.handleApprovalRequests(data);
           });
           mDialog.instance.selectTextEvent.subscribe( data => {
              // this.addSelectTextListener(data);
              this.showHandTool = false;
           });
           mDialog.instance.helpEvent.subscribe( data => {
              this.openHelpDialog(data);
           });
           const dialogid = mDialog.location.nativeElement as HTMLElement;
           // console.log('dialogid=', dialogid);
           // console.log('marker ref=', mDialog);
           mDialog.changeDetectorRef.detectChanges();
           try {
               mDialog.instance.setClickLinkHandlers();
           } catch (e) {
                console.error('Failed to setClickLinkHandlers()');
             }
        }

        return;
    }

    checkCommentInput(evt) {
       console.log("checkCommentInput=", evt);
    }

    sendNotify(data) {
        this.notify.add(data).subscribe(
            (x) => console.log(x),
            (e) => console.log(e)
        );
    }

    countDocs(uid){
       if (this.documents) {
        return this.documents.filter((f) => f.groups_uid === uid).length;
       } else {
            return 0;
            }
    }

    countUsers(uid){
        if (this.groupMembers !== null) {
          if (uid in this.groupMembers) {
              return this.groupMembers[uid].length;
          }
        } else {
              return 0;
        }
    }


    async updateComments(itemData, closeDialog=true) {
        if (this.documentsvc.activeDocument) {
            const owner = itemData.owner;
            /* update user layer markers only */
            const indLayerData = {
                note: this.noteData.note.filter((f) => f.owner === owner),
                question: this.noteData.question.filter((f) => f.owner === owner),
                issue: this.noteData.issue.filter((f) => f.owner === owner),
                approval: this.noteData.approval.filter((f) => f.owner === owner),
            };

            console.log('updateComments indLayerData1=', indLayerData);

            // Remove any object references before stringify
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
              indLayerData[ntype].forEach((note, index) => {
                 if ( note.markerRef && note.markerRef !== null ) {
                   note.markerRef.destroy();
                   note.markerRef = null;
                 }
                 if ( closeDialog ) {
                   if ( note.dialogRef && note.dialogRef !== null ) {
                     note.dialogRef.destroy();
                     note.dialogRef = null;
                     this.destroyMarkerDialogRef(note.uuid);
                   }
                 }
              });
            });

           console.log('updateComments indLayerData2=', indLayerData);

           const data = JSON.stringify({
                layer_data: indLayerData,
                doc_orientation: this.documentsvc.pdfRotation
           });

           console.log('LAYER: ', itemData.uid);
           console.log('data: ', data);
           // update this users note data layer
           try {
               await this.layersvc.updateLayerData(itemData.uid, data);
           } catch (e) {
                 console.log('ERROR: markersvc.updateComments failed! e=', e);
             }

           this.loadLayers(this.documentsvc.activeDocument);
        }
    }

    async updateMarkerData(itemData: MarkerData, closeDialog=true) {
        if (this.documentsvc.activeDocument) {
            const owner = itemData.owner;
            /* update user layer markers only */
            const approvals = this.noteData.approval.filter((f) => f.owner === owner);
            console.log('approvals=', approvals);
            //approvals.forEach( (ap, i) => {
            //   ap.dialogRef
            //}

            // const nuapprovals = JSON.parse(JSON.stringify(approvals));


            // Remove any object references before stringify
            // NOTE ONCE OBJECT REFERENCES MOVED OUT OF ITEMDATA
            // THIS IS NO LONGER NEEDED.
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
              this.noteData[ntype].forEach((note, index) => {
                 if ( note.markerRef && note.markerRef !== null ) {
                   // This would be a spot to update comment/approval counts
                   // on the marker icon.
                   note.markerRef.destroy();
                   note.markerRef = null;
                 }
                 if ( closeDialog ) {
                   if ( note.dialogRef && note.dialogRef !== null ) {
                       note.dialogRef.destroy();
                       note.dialogRef = null;
                   }
                   this.destroyMarkerDialogRef(note.uuid);
                 } else {
                     if ( note.dialogRef && note.dialogRef !== null ) {
                      note.dialogRef = null;
                      console.log('updtMD note=', note);
                      console.log('updtMD note.dialogRef=', note.dialogRef);
                     }
                   }
              });
            });

            const indLayerData = {
                note: JSON.parse(JSON.stringify(this.noteData.note.filter((f) => f.owner === owner) )),
                question: JSON.parse(JSON.stringify(this.noteData.question.filter((f) => f.owner === owner) )),
                issue: JSON.parse(JSON.stringify(this.noteData.issue.filter((f) => f.owner === owner) )),
                approval: JSON.parse(JSON.stringify(this.noteData.approval.filter((f) => f.owner === owner) )),
            };

           console.log('updtMD indLayerData=', indLayerData);

           let data = '';
           try {
             data = JSON.stringify({
                  layer_data: indLayerData,
                  doc_orientation: this.documentsvc.pdfRotation
             });
           } catch (e) {
                 console.log('ERROR: updateMarkerData failed! e=', e);
             }

           console.log('LAYER: ', itemData.uid);
           // console.log('data: ', data);
           // update this users note data layer
           try {
               await this.layersvc.updateLayerData(itemData.uid, data);
           } catch (e) {
                 console.log('ERROR: markersvc.updateComments failed! e=', e);
             }

           this.loadLayers(this.documentsvc.activeDocument);
        }
    }

    // CJ - Save old method for ng2-pdf-viewer for now.
    /******
        OLD_redraw_notes(ntype: string) {
        // 350-CJ Templates make the list# arbitrary
        // const overlay = document.getElementById('cdk-drop-list-1');
        const ovrlist = document.getElementsByClassName('cdk-drop-list');
        const overlay = ovrlist[0];
        // const overlay1 = ovrlist[1];

        // console.log('redraw_notes overlay0=', overlay);
        // console.log('redraw_notes overlay1=', overlay1);
        // console.log('redraw_notes ovrlist=', ovrlist);

        this.noteData[ntype].forEach((n, index) => {
            const newEl = document.getElementById('tools-' + ntype).cloneNode(true) as HTMLElement;

            const newId = 'user-' + ntype + '-' + (index + 1);
            newEl.id = newId;
            newEl.className += ' note-added';
            newEl.style.position = 'absolute';
            newEl.style.top = n.y + 'px';
            newEl.style.left = n.x + 'px';

            this.render.listen(newEl, 'click', (ev) => {
                this._create_item_dialog(document.getElementById(newId).parentElement, newId);
            });
            overlay.appendChild(newEl);
        });

    }

****/

    // Redraw notes updated to handle multiple modes and portrait/landscape
    // widths
    redraw_notes(ntype: string) {
        // const overlay = document.getElementById('cdk-drop-list-1');
        // 350-CJ Templates make the list# arbitrary
        // const overlay = document.getElementById('cdk-drop-list-1');
        const debug = false;
        const ovrlist = document.getElementsByClassName('cdk-drop-list');
        const overlay = ovrlist[0];
        const ovlem = ovrlist[0] as HTMLElement;
        const viewer = document.getElementById('viewer') as HTMLElement;

//        const ovlinfo = overlay.getBoundingClientRect();
        const ovlinfo = viewer.getBoundingClientRect();
        const ovlh = ovlinfo.height;
        const  ovlw = ovlinfo.width;
        // current zoom
        const zoom = this.documentsvc.pdfZoom;
        // scale
        const scale = window.devicePixelRatio;
        // console.log('window scale=', scale);
        // console.log('overlay=', overlay);
        const wh = window.innerHeight;
        const ww = window.innerWidth;
        const dpr = window.devicePixelRatio;
        // console.log('wh=', wh);
        // console.log('ww=', ww);
        // console.log('dpr=', dpr);
        // let xo = -2;
        // let yo = -3;

        let r = (ww / wh);
        let xo = (-2 * r) + r;
        let yo = (-2 * r);
        if ( wh > ww ) {
           // xo = 0;
           r  = wh / ww;
           if( dpr > 2 ) {
              xo = (-8 * r) + r;
              yo = (-8 * r) + r;
           } else {
                 xo = (-8 * r) - r;
                 yo = (-8 * r) - r;
           }
        }

        if (debug) {console.log('xo=' + xo + ' ,yo=' + yo + ' r=' + r);}
        this.noteData[ntype].forEach((n, index) => {
            const newEl = document.getElementById('tools-' + ntype).cloneNode(true) as HTMLElement;

            const newId = 'user-' + ntype + '-' + (index + 1);
            newEl.id = newId;
            newEl.className += ' note-added';
            newEl.style.position = 'absolute';
//            const ovwr = ovlinfo.width * 0.0078889;
//            const ovho = ovlinfo.height * 0.0078889;

/***
            const ovwr = ovlinfo.width * ( 0.00485 / 2);
            const ovhr = ovlinfo.height * 0.00485;
            let ovwo = ovwr;
            let ovho = ovhr;

            if (debug) { console.log('ovlinfo=', ovlinfo);}

            // adjust for negative or positive zoom
            if ( zoom < 1.0 ) {
               ovwo = (((1 - zoom) * 8) * ovwr) * -1;
               ovho = (((1 - zoom) * 8) * ovhr) * -1;
               if ( zoom <= 0.25 ) {
                  ovwo = ovwo * 2.5;
                  ovho = ovho * 2.5;
               }
            } else {
                 ovwo = ((zoom - 1) * 4) * ovwr;
                 ovho = ((zoom - 1) * 4) * ovhr;
                 }

//            const newTop = (n.y * zoom) + yo + ovho;
//            const newLeft = (n.x * zoom) + xo + ovwo;
*****/
            const newTop = (n.y * zoom) + yo;
            const newLeft = (n.x * zoom) + xo;

            // #520 - fix placement on current zoom factor
            if (debug) { console.log('marker n=', n);}
            if (debug) { console.log('overlay=', overlay);}
            if (debug) { console.log('ovlem=', ovlem);}
            if (debug) { console.log('ovlwidth=', ovlinfo.width);}
            if (debug) { console.log('ovlheight=', ovlinfo.height);}
            if (debug) { console.log('n.y=', n.y);}
            if (debug) { console.log('n.x=', n.x);}
            if (debug) { console.log('newLeft=', newLeft);}
            if (debug) { console.log('newTop=', newTop);}
            if (debug) { console.log('yo=', yo);}
            if (debug) { console.log('xo=', xo);}
            if (debug) { console.log('zoom', zoom);}
//            newEl.style.top = ( n.y + yo )  + 'px';
//            newEl.style.left = ( n.x + xo ) + 'px';
            newEl.style.top = newTop + 'px';
            newEl.style.left = newLeft  + 'px';

            this.render.listen(newEl, 'click', (ev) => {
                  const item = this.noteData[ntype][index];
                  // console.log('redraw click item=', item);
                  this.scrollToNote( newEl.id );
            });

            overlay.appendChild(newEl);
        });

    }

    reset_shown() {
        /* the `shown` var should be removed, makes the display methodologies
         * complicated, and can be replaced by a check for the id of the dialog
         */
        this.noteData.note.forEach((n) => { n.shown = false; });
        this.noteData.question.forEach((n) => { n.shown = false; });
        this.noteData.issue.forEach((n) => { n.shown = false; });
        this.noteData.approval.forEach((n) => { n.shown = false; });
    }

    _remove_note_data(ntype, index) {
        this.userLayer[ntype].splice(index, 1);
    }

    async remove_note(uid) {
        const annotations = [...this.noteData.note, ...this.noteData.question, ...this.noteData.issue, ...this.noteData.approval];
        const layer = annotations.filter((f) => f.uuid === uid)[0];
        const userlayerUid = annotations.filter((f) => f.owner === this.userInfo.email)[0].uid;

        console.log('remove note layer.uid=', layer.uid);
        console.log('remove note layer.uid=', userlayerUid);
        console.log('remove note userLayer=', this.userLayer);
        this.layerUid=layer.uid;

        // Check if we are owner of the layer
        if (userlayerUid === layer.uid) {
            // Remove user's own annotation
            this.userLayer.note.forEach((n, i) => {
                if (n.uuid === uid) { this._remove_note_data('note', i); }
            });
            this.userLayer.question.forEach((n, i) => {
                if (n.uuid === uid) { this._remove_note_data('question', i); }
            });
            this.userLayer.issue.forEach((n, i) => {
                if (n.uuid === uid) { this._remove_note_data('issue', i); }
            });
            this.userLayer.approval.forEach((n, i) => {
                if (n.uuid === uid) { this._remove_note_data('approval', i); }
            });
            console.log('removing users layer data for uid=', uid);

        } else {
            // Remove annotation if when not owner
            // pull layer data, remove single entry, and post updated version
            try {
               // Retrieve the layer
               const x = await this.layersvc.getLayerPromise( layer.uid );
               const newLayers = JSON.parse(x.layer_data);
               x.layer_data = JSON.stringify(newLayers);
               // And update the layer...
               const resp = await this.layersvc.updateLayerDataPromise(newLayers.uid, x);
            } catch(e) {
                 console.error('Error removing marker note data! e=', e);
                 this.toast.pop('error', 'Delete Marker', 'Operation Failed e=' + e.message);
            }
        }

        this.updateLayerData(this.layerUid);
        this.loadLayers(this.documentsvc.activeDocument, true);
    }

    removeElementById(id: string) {
        const el = document.getElementById(id);
        el.parentNode.removeChild(el);
    }

    hide_dialogs() {
        console.log('hide_dialogs()');
    }

    clear_dialogs() {
//        const dialogs = document.getElementsByClassName('item-info');
          // Remove open dialogs
//        if (dialogs.length > 0) {
//            for (let i = dialogs.length - 1; i > -1; i--) {
//                dialogs[i].parentNode.removeChild(dialogs[i]);
//            }
//        }
          // Old method storing dialogrefs in the marker
          ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
              this.noteData[ntype].forEach((note, index) => {
                if ( note.dialogRef &&
                     note.dialogRef !== null ) {
                     try {
                        note.dialogRef.destroy();
                        note.dialogRef = null;
                     } catch(e) {
                         console.log('clear_dialogs: error removing dialog');
                       }
                }
              });
          });
          ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
              this.noteDialogs[ntype].forEach((obj, index) => {
                if ( obj.dialogRef &&
                     obj.dialogRef !== null ) {
                     try {
                        obj.dialogRef.destroy();
                        obj.dialogRef = null;
                        this.noteDialogs[ntype].splice(index, 1);
                     } catch(e) {
                        console.log('clear_dialogs: error removing dialog UI');
                       }
                }
              });
          });
    }

    clear_notes() {
        //this.clear_dialogs();
        const notes = document.getElementsByClassName('note-added');

        // Remove notes
        if (notes.length > 0) {
            for (let i = notes.length - 1; i > -1; i--) {

//                const d = document.getElementById('dialog-user-note-' + i);
//                if (d !== null) {
//                    d.parentNode.removeChild(d);
//                    this.noteData.note[i].shown = false;
//                }

                notes[i].parentNode.removeChild(notes[i]);
            }
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
                this.noteMarkers[ntype].forEach((obj, index) => {
                  if ( obj.markerRef &&
                     obj.markerRef !== null ) {
                     try {
                        obj.markerRef.destroy();
                        obj.markerRef = null;
                     } catch(e) {
                        console.log('clear_notes: error removing marker UI');
                       }
                  }
                });
            });
        }

    }

    hide_tools(){
        if (this.ishiding === true) {
            this.ishiding = false;
            // this actually redraws all annotation markers
            this._hide_notes();
            this.commentIcon = false;
        } else {
            this.showingComments = false;
            this.commentIcon = true;
            this.ishiding = true;
            this.clear_dialogs();
            ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
                this.noteData[ntype].forEach((note, index) => {
                    note.shown = false;
                    document.getElementById(`user-${ntype}-${index + 1}`).style.display = 'none';
                });
            });
        }
    }

    toggle_actions(){
        if (this.toggleActions === undefined) {
            this.toggleActions = true;
        }
        if (this.toggleActions) {
            Array.from(document.getElementsByClassName('action-button-group'))
            .forEach((x) => { (x as HTMLElement).style.display = 'none'; });
        }
        else {
            Array.from(document.getElementsByClassName('action-button-group'))
            .forEach((x) => { (x as HTMLElement).style.display = 'flex'; });
            this.toggleActions = false;
        }
    }

    hide_recents(){
        if (this.showingRecents === undefined) {
            this.showingRecents = true;
        }
        if (this.showingRecents === true) {
            Array.from(document.getElementsByClassName('recent-items-list')).forEach((x) => { (x as HTMLElement).style.display = 'none'; });
            this.showingRecents = false;
        }
        else {
            Array.from(document.getElementsByClassName('recent-items-list')).forEach((x) => { (x as HTMLElement).style.display = 'flex'; });
            this.showingRecents = true;
        }
    }

    show_comments(){
//        console.log( 'show_comments =', this.showingComments);
        if (this.showingComments === true) {
            // Show all marker dialogs since they not already showing
            ['issue', 'question', 'note', 'approval'].forEach((ntype) => {
                for (let i = 0; i < this.noteData[ntype].length; i++) {
                    this._create_marker_dialog(document.getElementById('content-layer'),`user-${ntype}-${i+1}`, MarkerDialog.TEMPLATE_ADD_COMMENT);
                    this.noteData[ntype][i].shown = true;
                    console.log('noteDialogs=', this.noteDialogs);
                }
            } ) ;
            this.showingComments = false;
            this.commentIcon = true;
        }
        else {
            // Hide marker dialogs since they are already showing
            ['issue', 'question', 'note', 'approval'].forEach((ntype) => {
                for (let i = 0; i < this.noteData[ntype].length; i++) {
                      if ( this.noteData[ntype][i].dialogRef ) {
                         this.noteData[ntype][i].dialogRef.destroy();
                      }
                      this.noteData[ntype][i].dialogRef = null;
                      this.noteData[ntype][i].shown = false;
                      this.destroyMarkerDialogRef(this.noteData[ntype][i].uuid);
                      console.log('noteDialogs=', this.noteDialogs);
                }
            } ) ;
            this.showingComments = true;
            this.commentIcon = false;
        }
    }

    // 56-248 CJ - New dialog for group create/rename
    updateDialogGroupList( item ) {
       // CHANGING SELECTED GROUP  SHOULD PROBABLY BE REPLACED WITH
       // AN OBSERVABLE ON groupsvc.selectedGroup
       //
       // This is for modals
       this.groupListEvent.emit(this.groups);

       // This is to re-inject data for mat-dialogs if they are open
       //
       if (this.uploadDialogRef && this.uploadDialogRef.componentInstance) {
               this.uploadDialogRef.componentInstance.data =
                  {groupList: item };
               this.uploadDialogRef.componentInstance.groupChange(this.selectedGroup);
               console.log('Updating uploadFiles dialog group data...');
       }
       if (this.quickStartDialogRef && this.quickStartDialogRef.componentInstance) {
               this.quickStartDialogRef.componentInstance.data =
                  {groupList: item };
               this.quickStartDialogRef.componentInstance.groupChange(this.selectedGroup);
               console.log('Updating quickstart dialog group data...');
       }
       if (this.groupManagerDialogRef && this.groupManagerDialogRef.componentInstance) {
               this.groupManagerDialogRef.componentInstance.data =
                  {groupList: item };
               this.groupManagerDialogRef.componentInstance.groupChange(this.selectedGroup);
               console.log('Updating groupManager dialog group data...');
       }
    }

    // 56-248 CJ - New dialog for quick start worklfow
    async openQuickStartDialog() {
        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '70%';
        dialogConfig.minHeight    = '90vh';
        dialogConfig.maxWidth     = '96vw';
        dialogConfig.maxHeight    = '99vh';

        let launchUpload = false;
        let groupName = null;
        let groupUID = null;

        dialogConfig.data         = {
            groupList: this.groups,
            userInfo:  this.userInfo
        };
        // Open the quickstart dialog
        this.quickStartDialogRef = this.quickDialog.open(QuickStartDialogComponent, dialogConfig);


        // callback to from Dialog to open createGroupDialog();
        this.quickStartDialogRef.componentInstance.createGroupEvent.subscribe(
            result => {
               console.log('quickstart: createGroupEvent result=', result);
               this.openCreateGroupDialog();
            }
         );

        // callback to from Dialog to open uploadFilesDialog
        this.quickStartDialogRef.componentInstance.uploadFilesEvent.subscribe(
            result => {
               console.log('onUploadFiles result=', result);
               if (result) {
                  this.selectedGroup = result.group;
               }
               this.openUploadFilesDialog();
            }
         );

        // callback to from Dialog to refresh_subs()
        this.quickStartDialogRef.componentInstance.refreshSubsEvent.subscribe(
            result => {
               console.log('refreshSubsEvent=', result);
               // this.settings.refresh_subs();
               // this.refresh_subs();
            }
         );

        // Callback after save from dialog
        this.quickStartDialogRef.afterClosed().subscribe(
            data => {
              console.log('main quick data=', data);
              if (data) {
                launchUpload = data.launchUpload;
                groupName  = data.groupName;
                groupUID   = data.groupUID;
                this.selectedGroup = data.groupUID;
                if (groupUID != null) {
                   this.selectedGroup = groupUID;
                }
                if (launchUpload) {
                   this.openUploadFilesDialog();
                }
              }
            } // Data
        );
    }


    async openUserSettingsDialog() {
        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = 'auto';
        dialogConfig.minHeight    = 'auto';
        dialogConfig.width        = 'auto';
        dialogConfig.maxWidth     = '99vw';
        dialogConfig.maxHeight    = '99vh';

        const groupName = null;
        const groupUID = null;

        dialogConfig.data         = {
            userInfo:  this.userInfo
        };

        this.userSettingsDialogRef = this.userSettingsDialog.open(UserSettingsDialogComponent, dialogConfig);

        // callback from Dialog to openHelpDialog()
        this.userSettingsDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );

        // callback to from Dialog to refresh_subs()
        /***
        this.userSettingsDialogRef.componentInstance.refreshSubsEvent.subscribe(
            result => {
               console.log('refreshSubsEvent=', result);
               // this.settings.refresh_subs();
               // this.refresh_subs();
            }
         );
         ***/

        // Callback after save from dialog
        this.userSettingsDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                 console.log('main settings data=', data);
              }
            } // Data
        );
    }

    // 279 CJ - New dialog for User SubAccounts
    async openUserSubAccountsDialog() {
        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '65vw';
        dialogConfig.minHeight    = 'auto';
        dialogConfig.maxWidth     = '95vw';
        dialogConfig.maxHeight    = '92vh';

        const groupName = null;
        const groupUID = null;

        dialogConfig.data         = {
            userInfo:  this.userInfo
        };

        this.userSubAccountsDialogRef = this.userSubAccountsDialog.open(UserSubAccountsDialogComponent, dialogConfig);

        // callback from Dialog to openHelpDialog()
        this.userSubAccountsDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main userSubAccount helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );

        // callback to from Dialog to refresh_subs()
        /***
        this.userSettingsDialogRef.componentInstance.refreshSubsEvent.subscribe(
            result => {
               console.log('refreshSubsEvent=', result);
               // this.settings.refresh_subs();
               // this.refresh_subs();
            }
         );
         ***/

        // Callback after save from dialog
        this.userSubAccountsDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                 console.log('main user-subaccounts data=', data);
              }
            } // Data
        );
    }

    async openOrgTabDialog() {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '25vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const title = 'Edit Company/Org. User';

      let org = null;

      console.log('openOrgUserDialog selectedOrg=', this.selectedOrg);
      if (!this.selectedOrg && this.userOrgList) {
         org = this.userOrgList[0];
         this.setSelectedOrg(org);       
      }
      console.log('openOrgTabDialog set selectedOrg=', this.selectedOrg);
      
      // make sure variables loaded
      org = await this.userOrgList.find((o) => o.uid === this.selectedOrg.uid);
      
      // rename true for rename, else create group
      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG,
         objMode: this.DisplayMode.VIEW,
         objData: this.selectedOrg,
         selectedOrg: this.selectedOrg,
         selectedOrgUser: this.selectedOrgUser,
         dialogTitle: title,
      };

      // Open form dialog (org tab mode)

      this.orgTabDialogRef = this.orgTabDialog.open(FormDialogComponent, dialogConfig);

      // Callback after save from dialog

      let returnData = null;
      this.orgTabDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnData = data.objData;
               console.log('formDialog orgtabd return=',
                            data.objData);
            }
          } // data
       );
    }

    async openOrgUserDialog() {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '25vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const title = 'Edit Company/Org. Users';

      let org = null;

      console.log('openOrgUserDialog selectedOrg=', this.selectedOrg);
      if (!this.selectedOrg && this.userOrgList) {
         org = this.userOrgList[0];
         this.setSelectedOrg(org);       
      }
      console.log('openOrgTabDialog set selectedOrg=', this.selectedOrg);
      
      // make sure variables loaded
      org = await this.userOrgList.find((o) => o.uid === this.selectedOrg.uid);
      

      // rename true for rename, else create group
      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_USR_LIST,
         objMode: this.DisplayMode.SELECT_LIST,
         objData: this.selectedOrgUser,  
         selectedOrg: this.selectedOrg,
         selectedOrgUser: this.selectedOrgUser,
         dialogTitle: title,
      };

      // Open form dialog (org tab mode)

      this.orgUserDialogRef = this.orgUserDialog.open(FormDialogComponent, dialogConfig);

      // Callback after save from dialog

      let returnData = null;
      this.orgUserDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnData = data.objData;
               console.log('formDialog orgtabd return=',
                            data.objData);
            }
          } // data
       );
    }

    async openOrgContactDialog() {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '25vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const title = 'Edit Company/Org. Contacts';

      let org = null;

      console.log('openOrgUserDialog selectedOrg=', this.selectedOrg);
      if (!this.selectedOrg && this.userOrgList) {
         org = this.userOrgList[0];
         this.setSelectedOrg(org);       
      }
      console.log('openOrgContactDialog set selectedOrg=', this.selectedOrg);
      
      // make sure variables loaded
      org = await this.userOrgList.find((o) => o.uid === this.selectedOrg.uid);
      

      // rename true for rename, else create group
      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_CONTACT_LIST,
         objMode: this.DisplayMode.SELECT_LIST,
         objData: this.selectedOrgContact,
         selectedOrg: this.selectedOrg,
         selectedOrgContact: this.selectedOrgContact,
         dialogTitle: title,
      };

      // Open form dialog (org tab mode)

      this.orgContactDialogRef = this.orgContactDialog.open(FormDialogComponent, dialogConfig);

      // Callback after save from dialog

      let returnData = null;
      this.orgContactDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnData = data.objData;
               console.log('formDialog orgtabd return=',
                            data.objData);
            }
          } // data
       );
    }

    async openGroupManagerDialog() {

        console.log('main selectedGroup=', this.selectedGroup);

        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '70%';
        dialogConfig.minHeight    = '95%';
        dialogConfig.maxWidth     = '100vw';
        dialogConfig.maxHeight    = '100vh';

        const launchUpload = false;
        const groupName = null;

        if (!this.selectedGroup && this.groups.length > 0) {
           this.selectedGroup = this.groups[0].uid;
        }
        // console.log('groumgr group=', this.selectedGroup);
        // console.log('groumgr groups=', this.groups);

        // if no selected group load the first one...
        if (! this.selectedGroup && this.groups ) {
           this.selectedGroup = this.groups[0].uid;
        }
        const memberlist = this.groupMembers[this.selectedGroup];

        // console.log('main grpmgr selectedGroup=', this.selectedGroup);

        dialogConfig.data         = {
            groupList: this.groups,
            groupUID: this.selectedGroup,
            group: this.selectedGroupObj,
            groupMembers: memberlist,
            selectedGroup: this.selectedGroup,
            userInfo:  this.userInfo,
            groupTabIndex: this.groupMgrTabIndex ?? 0,
            groupTabMember: this.groupMgrMember ?? 0,
            org: this.selectedOrg,
            orgunit: this.selectedOrgUnit
        };
        this.groupManagerDialogRef = this.groupManagerDialog.open(GroupManagerDialogComponent, dialogConfig);


        // callback from Dialog to openHelpDialog()
        this.groupManagerDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );

        // callback from Dialog to openCreateGroupDialog
        this.groupManagerDialogRef.componentInstance.createGroupEvent.subscribe(
            result => {
               console.log('main createGroupEvent result=', result);
               if (result) {
                  this.openCreateGroupDialog();
               }
            }
         );


        // callback from Dialog to open renameGroup
        this.groupManagerDialogRef.componentInstance.renameGroupEvent
            .subscribe(
               result => {
                  console.log('main renameGroupEvent result=', result);
                  if (result) {
                        this.selectedGroup = result;
                        this.openRenameGroupDialog(result);
                  }
               }
         );

        // callback from Dialog to handle Delete Group Event
        this.groupManagerDialogRef.componentInstance.deleteGroupEvent.subscribe(
            async result => {
               console.log('main: deleteGroupEvent result=', result);
               this.openDeleteGroupDialog(result.groupUID, result.groupName);
            }
         );

        // callback to from Dialog to get groups
        this.groupManagerDialogRef.componentInstance.getGroupsEvent.subscribe(
            result => {
               console.log('main getGroupsEvent=', result);
               this.updateDocumentList();
            }
         );

        // callback from Dialog to handle removeMemberEvent
        this.groupManagerDialogRef.componentInstance.removeMemberEvent.subscribe(
            evt => {
               console.log('main: removeMemberEvent member=', evt);
               this.openRemoveMemberDialog(evt.guid, evt.uuid);
            }
         );

        // callback from Dialog to handle displayUserInfoEvent
        this.groupManagerDialogRef.componentInstance.displayUserInfoEvent.subscribe(
            evt => {
               console.log('main: displayUserInfoEvent userInfo=', evt);
               this.openUserInfoDialog(evt);
            }
         );


        // callback to from Dialog to refresh_subs()
        this.groupManagerDialogRef.componentInstance.getUserSubAcctsEvent.subscribe(
            result => {
               console.log('refreshSubsEvent=', result);
               // this.settings.refresh_subs();
               // this.refresh_subs();
            }
         );

        // Callback after save from dialog
        this.groupManagerDialogRef.afterClosed().subscribe(
            data => {
              console.log('main quick data=', data);
              if (data) {
                this.groupName  = data.groupName;
                this.groupUID   = data.selectedGroup;
                if (this.groupUID != null) {
                   // this.selectedGroup = this.groupUID;
                   this.setSelectedGroup(this.groupUID);
                }
                this.updateDocumentList();
              }
            } // data
        );
    } // groupMgrDialog


    async openCreateGroupDialog() {
        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '50vw';
        dialogConfig.maxWidth     = '95vw';
        dialogConfig.maxHeight    = '99vh';

        const mode = this.DisplayMode.ADD;
        const obj_data = new GroupData();

        const objLabel = 'Group';
        const title = 'Create Group';

        dialogConfig.data = {
           isObjAdmin: true,
           userData: this.userData,
           objModel: this.DisplayModel.GROUP,
           objMode: mode,
           objData: obj_data,
           dialogTitle: title,
           /****
           message: msg,
           message2: msg2,
           ****/
           button1Color: 'red',
           message1Color: 'red',
           button1Text: 'Save',
        };

        this.groupNameDialogRef = this.formDialog.open(FormDialogComponent, dialogConfig);

        // Callback after dialog close
        let returnData = null;
        this.groupNameDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                this.updateDialogGroupList(this.groups);              
                returnData = data.objData;
                console.log('createGroup formDialog return=',
                             data.objData);
                if (returnData && returnData.uid ) {
                   // this.selectedGroup = returnData.uid;
                   this.setSelectedGroupObj(returnData);
                   this.refreshData();
                }
              }
              if (this.selectedGroup) {
                this.collapseComments('group-content');
                this.toggleFiber1 = !this.toggleFiber1;
              }
              this.updateDialogGroupList(this.groups);
            } // data
        );
    }



    async openRenameGroupDialog(guid) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const title = 'Edit Group';

      if (guid) {
         this.selectedGroup = guid;
      }

      // make sure variables loaded
      const grp = await this.groups.find((g) => g.uid === guid);

      const is_admin = (grp.owner === this.userData.uid) ? true : false;

      // rename true for rename, else create group
      dialogConfig.data = {
         //rename: true,
         //groupUID: grp.uid,
         //groupName: grp.name,
         //groupList: this.groups
         isObjAdmin: is_admin,
         userData: this.userData,
         objModel: this.DisplayModel.GROUP,
         objMode: this.DisplayMode.EDIT,
         objData: grp,
         dialogTitle: title,
      };

      // Open group name dialog (rename mode)

      this.groupNameDialogRef = this.formDialog.open(FormDialogComponent, dialogConfig);

      // Callback after save from dialog

      let returnData = null;
      this.groupNameDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnData = data.objData;
               console.log('formDialog rename group return=',
                            data.objData);
               if (returnData && returnData.uid) {
                 // groupName  = returnData.name;
                 this.selectedGroup = returnData.uid;
                 this.setSelectedGroupObj(returnData);
                 this.updateDialogGroupList(this.groups);
               }
            }
          } // data
       );
    }

    /***
    async openRenameGroupDialog(guid) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let groupName = null;
      const groupUID = null;

      if (guid) {
         this.selectedGroup = guid;
      }

      // make sure variables loaded
      const grp = await this.groups.find((g) => g.uid === guid);

      // rename true for rename, else create group
      dialogConfig.data = {
         rename: true,
         groupUID: grp.uid,
         groupName: grp.name,
         groupList: this.groups
      };

      // Open group name dialog (rename mode)
      this.groupNameDialogRef = this.groupNameDialog.open(GroupNameDialogComponent, dialogConfig);

      // Callback after save from dialog
      this.groupNameDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               groupName  = data.groupName;
               this.selectedGroup = guid;
               // this.handleRenameGroup(guid, groupName);
               this.setSelectedGroup(this.selectedGroup);
               this.updateDialogGroupList(this.groups);
               }
          } // data
       );
    }

    async handleRenameGroup(guid: string, name: string) {
        if (name === '')
        {
            this.toast.pop('error', 'Rename Failed', 'Please Enter a name');
            return;
        }
        const grp = await this.groups.filter((g) => g.uid === guid)[0];
        if (grp === undefined) {
              this.toast.pop('error', 'Error!', 'group id not found!');
              return;
        }
        const oldname = grp.name;
        try {
           await this.groupsvc.renameGroup(grp.uid, name);
           this.toast.pop('success', 'Rename Group Success', 'Group ' + oldname +
                          ' renamed to ' + name );
         } catch (e)  {
            this.toast.pop('error', 'Rename Group Failed',  'Error Group ' + oldname +
                           ' was not renamed to ' + name );

         }
    }
    ***/

  openDeleteGroupDialog(guid, gname) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      this.groupName = gname;
      this.groupUID = guid;

      // make sure variables loaded
      this.selectedGroup = this.groupUID;

      // Pass the seletected group data
      const grpData = {
         groupUID: this.selectedGroup,
         groupName: this.groupName
         };

      const msg = 'Are you sure you want to permanently delete group ' +
                 this.groupName + '?';
      const msg2 = 'Note: All group documents will also be removed.';

      dialogConfig.data = {
         intent: 'deleteGroup',
         title: 'Delete Group ' + this.groupName,
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Delete',
         dialogData: grpData
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.deleteGroupDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('deleteGroup action-prompt intent=',
                            data.intent);
               console.log('deleteGroup action-prompt return=',
                            data.choice);
               console.log('deleteGroup action-prompt return=',
                            data.dialogData);
            }
            this.groupsvc.deleteGroup(guid);
            this.groupUID = guid;
            console.log('Delete group groups=', this.groups);
            console.log('Delete group groups[0]', this.groups[0]);
            if( this.groups && this.groups[0] ) {
               this.selectedGroup = this.groups[0].uid;
               this.setSelectedGroup(this.selectedGroup);
            }
          } // data
      );
    }

  openDeleteFileDialog(doc) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const docUID = doc.uid;
      const docName = doc.name;

      // Pass the seletected group data
      const grpData = {
         groupUID: this.selectedGroup,
         groupName: this.groupName
         };

      const msg = 'Are you sure you want to permanently delete this file?';
      const msg2 = doc?.name + ' (size ' + doc?.size + ')';

      console.log('delete file dialog doc=', doc);

      dialogConfig.data = {
         intent: 'deleteFile',
         title: 'Delete File ' + docName,
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message2Color: 'red',
         button1Text: 'Delete',
         dialogData: doc
      };

      // Open action prompt dialog (delete file intent)
      this.actionPromptDialogRef = this.deleteGroupDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('deleteFile action-prompt intent=',
                            data.intent);
               console.log('deleteFile action-prompt return=',
                            data.choice);
               console.log('deleteFile action-prompt return=',
                            data.dialogData);
            }
            // this.documentsvc.removeDocument(doc.uid);
            this.removeDocument(doc.uid);
          } // data
      );
    }

  openCloseFileDialog() {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      // Pass the seletected group data

      const msg = 'Are you sure you want to close file?';

      console.log('close file dialog doc=', this.documentsvc.activeDocument);

      dialogConfig.data = {
         intent: 'closeFile',
         title: 'Close File ',
         message: msg,
         message2: '',
         button1Color: 'red',
         button1Text: 'Close',
         dialogData: null
      };

      // Open action prompt dialog (delete file intent)
      this.actionPromptDialogRef = this.deleteGroupDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('closeFile action-prompt intent=',
                            data.intent);
               console.log('closeFile action-prompt return=',
                            data.choice);
               console.log('closeFile action-prompt return=',
                            data.dialogData);
            }
            // this.documentsvc.removeDocument(doc.uid);
            this.resetDocumentState();
            this.sidenavleft.open();
            this.sidenavright.close();
          } // data
      );
    }

  openLogoutDialog() {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Are you sure you want to logout?';
      const msg2 = '';

      dialogConfig.data = {
         intent: 'logout',
         title: 'Markadoc',
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message2Color: 'red',
         button1Text: 'Yes',
         dialogData: 'LOGOUT'
      };

      // Open action prompt dialog (delete file intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('logout action-prompt intent=',
                            data.intent);
               console.log('logout action-prompt return=',
                            data.choice);
               console.log('logout action-prompt return=',
                            data.dialogData);
               this.logout();
            }
          } // data
      );
    }

   openProgressDialog(ptitle, pmsg, pmode) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '99vw';
      dialogConfig.maxHeight    = '99vh';

      dialogConfig.data = {
         title: ptitle,
         message: pmsg,
         progress: this.documentsvc.pdfProgress,
         mode: pmode
      };

      // Open action progress dialog
      this.progressDialogRef = this.progressDialog.open(ProgressDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let returnData = null;
      this.progressDialogRef.componentInstance.progressEvent.subscribe(
         data => {
            if (data) {
               returnData = data.dialogData;
            }
          } // data
      );
    }

   openPrintSheet() {
      // set data parameters
      const sheetData = {
        docname: this.activeDocumentName,
      };
      // Open bottom sheet
      this.bottomSheetRef = this.bottomSheet.open(PrintSheetComponent, {
        data: sheetData,
      });

      console.log('main bottomref=', this.bottomSheetRef.instance);
      const bsub = this.bottomSheetRef.instance.bottomSheetEvent.subscribe(
         data => {
           console.log('main bottom event=', data);
           switch (data) {
              case 'print':
                 this.PDFPrint();
                 break;
              case 'print-both':
                 this.PDFPrintBoth();
                 break;
              case 'print-annotated':
                 this.PDFPrintAnnotated();
                 break;
           }
         }
      );

   }

   openDownloadSheet() {
      // set data parameters
      const sheetData = {
        docname: this.activeDocumentName,
      };
      // Open bottom sheet
      this.bottomSheetRef = this.bottomSheet.open(DownloadSheetComponent, {
        data: sheetData,
      });

      console.log('main bottomref=', this.bottomSheetRef.instance);
      const bsub = this.bottomSheetRef.instance.bottomSheetEvent.subscribe(
         data => {
           console.log('main bottom event=', data);
           switch (data) {
              case 'download':
                 this.PDFDownload();
                 break;
              case 'download-both':
                 this.PDFDownloadBoth();
                 break;
              case 'download-annotated':
                 this.PDFDownloadAnnotated();
                 break;
           }
         }
      );

   }

   async openRemoveMemberDialog(guid, uuid) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const groupName = null;
      const groupUID = null;

      console.log('main removeMemberDialog guid=', guid);
      console.log('main removeMemberDialog uuid=', uuid);
      const grp = await this.groups.find((g) => g.uid === guid);
      const gname = grp.name;
      const mbrlist = await this.groupmembersvc.getGroupMembers(guid);
      console.log('main removeMemberDialog mbrlist=', mbrlist);
      const mbr = await mbrlist.find(m => m.uid === uuid);
      // console.log('main removeMemberDialog groupMembers=', this.groupMembers);
      console.log('main removeMemberDialog grp=', grp);
      console.log('main removeMemberDialog mbr=', mbr);
      const mbrname = mbr.name + ' ' + mbr.last_name + ' (' + mbr.email + ') ';

      // Pass the seletected group member data
      const grpMbrData = {
         groupUID: guid,
         userUID: uuid,
         groupName: gname,
         memberName: mbrname
         };

      const msg = 'Are you sure you want to remove member ' +
                 mbrname + ' from group ' + grp.name + '?';
      const msg2 = '';

      dialogConfig.data = {
         intent: 'deleteMember',
         title: 'Remove Group Member',
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Remove',
         dialogData: grpMbrData
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after save from dialog
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('action-prompt return=', data.dialogData);
            }
            this.removeMember(grp, mbr);
          } //
       );
    }

    handleGroupMemberCount(evt) {
      console.log('main handleCountMembersEvent evt=', evt);
      this.groupMemberCnt = evt;
    }

    handleRemoveMember(evt) {
      console.log('main removeMemberEvent evt=', evt);
      this.openRemoveMemberDialog(evt.guid, evt.uuid);
    }

    async removeMember(grp, mbr) {
      console.log('main removeMember grp=', grp);
      console.log('main removeMember mbr=', mbr);
      try {
         await this.groupmembersvc.removeGroupMember(grp.uid, mbr.uid);
         console.log('GMC removed group member', mbr);
         this.toast.pop('success', 'Remove Group Member',
         'Group member ' + mbr.name + ' ' + mbr.last_name + '(' +
          mbr.email + ') successfully removed.');
         // this.refreshMembersEvent.emit(this.groupUID);
         // this.groupChange(this.groupUID);
      } catch (e)  {
         console.error('main error removeMember grp=', grp);
         console.error('main error removeMember mbr=', mbr);
         console.log('main error removeMember e=', e);           this.toast.pop('error', 'Remove Group Member',
              'Error removing group member ' + mbr.email +
              ' from group ' + grp.name );
      }
    }

    async openMarkerReportDialog() {

        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = '90vw';
//        dialogConfig.minHeight    = '85vh';
        dialogConfig.width        = '90vw';
        dialogConfig.height       = 'auto';
        dialogConfig.maxWidth     = '100vw';
        dialogConfig.maxHeight    = '99vh';

        const tableTemplate = 1;

        console.log('main markerreport called.');

        dialogConfig.data         = {
            template: tableTemplate,
            noteData: this.noteData,
            userInfo: this.userInfo,
            documentUID: this.selectedDocUID,
            documentName: this.get_current_document(this.documentsvc.activeDocument)?.name,
        };
        this.markerReportDialogRef = this.markerReportDialog.open(MarkerReportDialogComponent, dialogConfig);

        // callback from Dialog to openHelpDialog()
        this.markerReportDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );

        // callback from Dialog to get PDF
        this.markerReportDialogRef.componentInstance.mrdPdfEvent.subscribe(
            result => {
               console.log('main PDFEvent result=', result);
               if (result) {
                  this.documentsvc.pdfSrc = result;
               }
            }
         );

        // callback from Dialog to scrollToNote
        this.markerReportDialogRef.componentInstance.mrdScrollToNoteEvent.subscribe(
            result => {
               console.log('main scrollToNoteEvent result=', result);
               if (result) {
                  this.scrollToNote(result);
                  this.markerReportDialogRef.close();
               }
            }
         );

        // callback from Dialog to scrollToPage
        this.markerReportDialogRef.componentInstance.mrdScrollToPageEvent.subscribe(
            result => {
               console.log('main scrollToPageEvent result=', result);
               if (result) {
                  this.documentsvc.pdfPageNbr = result;
                  this.scrollToPage(this.documentsvc.pdfPageNbr);
                  this.markerReportDialogRef.close();
               }
            }
         );

        // Callback after save from dialog
        this.markerReportDialogRef.afterClosed().subscribe(
                    data => {
              console.log('main markerReport data=', data);
              if (data) {
                 console.log('marker rpt data=', data);
              }
            } // data
        );
    } // markerReportDialog


    async openSendTokenDialog() {
        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = 'auto';
        dialogConfig.minHeight    = 'auto';
        dialogConfig.width        = 'auto';
        dialogConfig.maxWidth     = '99vw';
        dialogConfig.maxHeight    = '99vh';

        const groupName = null;
        const groupUID = null;

        dialogConfig.data         = {
            userInfo:  this.userInfo,
            noteData: this.noteData,
            activeDocument: this.documentsvc.activeDocument
        };

        this.sendTokenDialogRef = this.sendTokenDialog.open(SendTokenDialogComponent, dialogConfig);

        // callback from Dialog to openHelpDialog()
        this.sendTokenDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );

        // Callback after save from dialog
        this.sendTokenDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                 console.log('main sendToken data=', data);
              }
            } // Data
        );
    }

    async openFeedbackDialog() {

        const dialogConfig        = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = 'auto';
        dialogConfig.minHeight    = 'auto';
        dialogConfig.width        = 'auto';
        dialogConfig.maxWidth     = '99vw';
        dialogConfig.maxHeight    = '99vh';

        console.log('main feedback called.');

        dialogConfig.data         = {
            userInfo: this.userInfo,
        };
        this.feedbackDialogRef = this.feedbackDialog.open(FeedbackDialogComponent, dialogConfig);

        // callback from Dialog to openHelpDialog()
        this.feedbackDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('main helpEvent result=', result);
               if (result) {
                  this.openHelpDialog(result);
               }
            }
         );


        // Callback after save from dialog
        this.feedbackDialogRef.afterClosed().subscribe(
            data => {
              console.log('main feedbackDialog data=', data);
              if (data) {
                 console.log('feedback data=', data);
              }
            } // data
        );
    } // feedbackDialog

    openUserInfoDialog(uinfo) {
      console.log('main usrInfoDialog uinfo=', uinfo);

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '75vw';
      dialogConfig.maxHeight    = '99vh';

      if (!uinfo) {
         this.toast.pop('error', 'ERROR!',
                        'Must pass a UserInfo record to display');
      }
      dialogConfig.data = {
         UserInfo: uinfo,
         UserUID: uinfo.uid
      };

      // Open action prompt dialog (delete group intent)
      this.userInfoDialogRef = this.userInfoDialog.open(UserInfoDialogComponent, dialogConfig);

      // Callback after save from dialog
      // None at present.
      this.userInfoDialogRef.afterClosed().subscribe();
    }

    openFileInfoDialog(duid) {
      console.log('main fileInfoDialog uinfo=', duid);

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '75vw';
      dialogConfig.maxHeight    = '99vh';

      if (!duid) {
         this.toast.pop('error', 'ERROR!',
                        'Must pass a document uid to display');
      }
      dialogConfig.data = {
         documentUID: duid,
         UserInfo: this.userInfo
      };

      // Open action prompt dialog (delete group intent)
      this.fileInfoDialogRef = this.fileInfoDialog.open(FileInfoDialogComponent, dialogConfig);

      // callback from Dialog to handle displayUserInfoEvent
      this.fileInfoDialogRef.componentInstance.displayUserInfoEvent.subscribe(
            evt => {
               console.log('main: displayUserInfoEvent userInfo=', evt);
               this.openUserInfoDialog(evt);
            }
         );

      // Callback after save from dialog
      // None at present.
      this.fileInfoDialogRef.afterClosed().subscribe();
    }

    openCreateLinkDialog(e: any) {
      console.log('main createLinkDialog e=', e);

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.minHeight    = 'auto';
      dialogConfig.maxWidth     = '98vw';
      dialogConfig.maxHeight    = '98vh';

      dialogConfig.data = {
         selectedGroupObj: this.selectedGroupObj,
         linkOrigin: this.linkOrigin,
         userInfo: this.userInfo,
         linkSrcData: e
      };

      // Open create link  dialog
      this.createLinkDialogRef = this.createLinkDialog.open(CreateLinkDialogComponent, dialogConfig);

      // callback from Dialog to handle displayUserInfoEvent
      /***
      this.createLinkDialogRef.componentInstance.insertLinkEvent.subscribe(
            evt => {
               console.log('main: insertLinkEvent evt=', evt);
               // this.openUserInfoDialog(evt);
            }
         );
      ***/

      // Callback after save from dialog
      // None at present.
      this.createLinkDialogRef.afterClosed().subscribe(
         data => {
           if (data) {
              console.log('createLinkDialog data=', data);
           }
         }
      );
    }

    openAboutDialog() {
      console.log('main aboutDialog opened.');

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '75vw';
      dialogConfig.maxHeight    = '99vh';

      // Open action prompt dialog (delete group intent)
      this.aboutDialogRef = this.aboutDialog.open(AboutDialogComponent, dialogConfig);

      // Callback after save from dialog
      // None at present.
      this.aboutDialogRef.afterClosed().subscribe();
    }

    openHelpDialog(ctx) {
      console.log('main openHelpDialog ctx=', ctx);

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.disableClose = true;
      dialogConfig.restoreFocus = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      if (!ctx) {
         console.log('error: Must pass a Help context NBR to display');
      }
      dialogConfig.data = {
         context: ctx,
      };

      // Open action prompt dialog (delete group intent)
      this.helpDialogRef = this.helpDialog.open(HelpDialogComponent, dialogConfig);

      // Callback after save from dialog
      // None at present.
      this.helpDialogRef.afterClosed().subscribe();
    }


    // 56-248 CJ - New dialog for file conversions and uploads
    async openUploadFilesDialog() {
        // console.log('main.uploadFilesDialog groups=', this.groups);

        const dialogConfig        = new MatDialogConfig();
        const fname = '';

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus    = true;
        dialogConfig.panelClass   = 'panel-class';
        dialogConfig.minWidth     = 'auto';
        dialogConfig.minHeight    = 'auto';
        dialogConfig.width        = '70%';
        dialogConfig.maxWidth     = '99vw';
        dialogConfig.maxHeight    = '99vh';

        dialogConfig.data         = {
            groupList: this.groups,
            userInfo: this.userInfo,
            org: this.selectedOrg,
            orgunit: this.selectedOrgUnit,
            group: this.selectedGroup,
            groupObj: this.selectedGroupObj,
            imagesize: this.selectedImageSize,
            imagerotation: this.selectedImageRotation,
            addmargins: this.selectedAddMargins
        };

        // open upload files dialog
        this.uploadDialogRef = this.uploadDialog.open(UploadFilesDialogComponent,
                                                     dialogConfig);

        // Handle help emitter
        this.uploadDialogRef.componentInstance.helpEvent.subscribe(
            result => {
               console.log('sendUpdateDocs result=', result);
               this.openHelpDialog(result);
            }
        );

        // Handle createGroupEvent
        this.uploadDialogRef.componentInstance.createGroupEvent.subscribe(
            result => {
               console.log('upload createGroupEvent result=', result);
               this.openCreateGroupDialog();
            }
        );

        // Handle selectGroupEvent
        this.uploadDialogRef.componentInstance.selectGroupEvent.subscribe(
            result => {
               console.log('upload selectGroupEvent result=', result);
               // result is GroupData object
               this.setSelectedGroupObj( result );
            }
        );

        // Handle updateDocListEvent
        this.uploadDialogRef.componentInstance.updateDocListEvent.subscribe(
            result => {
               console.log('upload updateDocumentList result=', result);
               this.updateDocumentList();
            }
        );

        // Handle updateDocListEvent
        this.uploadDialogRef.componentInstance.saveEvent.subscribe(
            data => {
               console.log('upload saveEvent openDoc=', data);
               this.handleFileUploaded(data);
            }
        );

        this.uploadDialogRef.afterClosed().subscribe(
            data => {
              if (data) {
                 console.log('uploadFiles dialog closed.');
                 // this.handleFileUploaded(data);
              }
            }
        );
   }

   async handleFileUploaded(data) {
        // console.log('handle Files Uploaded output:', data);
        const errCode = data.errCode;
        const errMsg  = data.errMsg;
        this.selectedDocUID  = data.docUID;
        this.selectedDocObj  = data.docObj;
        // console.log('seleced doc obj=', this.selectedDocObj);
        if (this.selectedDocObj == null) {
             this.toast.pop ('error', 'Error!',
                            'Problem returning selected file!');
        } else {
           // 522 openDocument wants the full document object.
           // so call loadDocument instead
           // console.log('updating document list');
           await this.updateDocumentList();
           console.log('calling openDocument for doc=', this.selectedDocObj.name);
           this.openDocument(this.selectedDocObj);
          }
   }

   async toggleUIMode(){
     switch (this.uiMode) {
        case this.UI_DESKTOP:
           this.uiMode = this.UI_MOBILE;
           this.uiModeChange.emit(this.uiMode);
           break;
        case this.UI_MOBILE:
           this.uiMode = this.UI_DESKTOP;
           this.uiModeChange.emit(this.uiMode);
           break;
     }
     this.getScreenSize();
   }

   handleBackBtn() {
     if (this.uiMode === PLATFORM.UI_DESKTOP) {
       if ( window.location.href === this.baseURL ) {
                this.openLogoutDialog();
       }
     }
   }

   displayMarkerButtons( evt ) {
     if ( evt === true ) {
        console.log('display marker btns=', evt);
     } else {
          console.log('display marker btns=', evt);
     }
   }


   async appCanShare(): Promise<boolean> {
      const result: CanShareResult = await Share.canShare();
      console.log('canShare ShareResult=', result.value);
      return result.value;
   }

   async shareDocument(duid: string): Promise<ShareResult> {
      const origin = window.location.origin;
      const docUrl = origin + '?docid=' + duid;
      console.log('shareDocument url=', docUrl);
      const res: ShareResult = await Share.share({
         title: 'Markadoc Document Share',
         text: 'Someone shared a document link for review...',
         url: docUrl
      });
      return res;
   }


/***
  public onCheckboxClicked() {
      this.ngxPdfViewerService.find(this.searchText,
      this.highlightAll,
      this.matchCase,
      this.wholeWord,
      this.ignoreAccents);
  }
***/

  toggleFindTool() {
    this.showFindTool = !this.showFindTool;
    this.searchText = '';
  }

  private find() {
    if (!this.searchText) {
      this.findState = undefined;
      this.currentMatchNumber = 0;
      this.totalMatches = 0;
    }
    if (this.multipleSearchTerms) {
      this.ngxPdfService.findMultiple([this.searchText, this._searchText2, this._searchText3], {
        highlightAll: this.highlightAll,
        currentPage: this.currentPage,
        matchCase: this.matchCase,
        wholeWords: this.wholeWord,
        ignoreAccents: this.ignoreAccents,
        fuzzySearch: this.fuzzy,
        pageRange: this._pageRange,
      });
    } else {
      if (
        this.ngxPdfService.find(this.searchText, {
          highlightAll: this.highlightAll,
          currentPage: this.currentPage,
          matchCase: this.matchCase,
          wholeWords: this.wholeWord,
          ignoreAccents: this.ignoreAccents,
          fuzzySearch: this.fuzzy,
          pageRange: this._pageRange,
        })
      ) {
          console.log('no action for find().');
      }
    }
  }

  public findNext(): void {
    const r = this.ngxPdfService.findNext();
    console.log('findNext r=', r);
  }

  public findPrevious(): void {
    this.ngxPdfService.findPrevious();
  }

  public updateFindState(result: FindState) {
    this.findState = result;
  }

  public updateFindMatchesCount(result: FindResultMatchesCount) {
    this.currentMatchNumber = result.current;
    this.totalMatches = result.total;
    const matches = result.matches;
    const mlen = matches.length;
    const m0 = matches[0].length;
    this.searchResultPages = new Array<number>();
    for (let i = 0; i < matches.length; i++) {
       for (let j = 0; j < matches[i].length; j++) {
        this.searchResultPages.push(i+1);
       }
    }
    this.scrollToPage( this.getResultPage(this.currentMatchNumber) );

    // console.log('matches result=', result);
    // console.log('page matches =', mlen);
    // console.log('page 1 matches =', m0);
    // console.log('search pages =', this.searchResultPages);
  }

  public handleSelectTextMessage(obj) {
     console.log('toast message closed for obj=', obj);
  }

  public onHandToolChange(event: any): void {
     // this.handTool = !this.handTool;
     console.log('hand tool change=', this.showHandTool);
     if ( ! this.showHandTool ) {
        const obj = this.toast.message('Select text from the document', 'Select Text', 5000);
        obj.onHidden.subscribe((close) => {
            this.handleSelectTextMessage(obj);
        });
        this.addSelectTextListener(event);
        this.pdfViewerZIndex = 1010;
     } else {
        this.removeSelectTextListener();
        this.pdfViewerZIndex = 100;
     }
  }

  handleSelectText (event: any) {
      // this.getTextSelection();
      const selection = (window as any).getSelection();
      const text = selection.toString();

      if (selection) {
        console.log('selection=', selection);
      }
      if (text) {
        console.log('text=', text);
      }
      this.showHandTool = true;
  }

  public addSelectTextListener(data) {
    document.addEventListener('selectionchange', this.handleSelectText);
  }

  public removeSelectTextListener() {
    document.removeEventListener('selectionchange', this.handleSelectText);
  }

  getResultPage(r: number): number {
     // console.log('srp r =', r);
     // console.log('srp =', this.searchResultPages);
     // console.log('srp len =', this.searchResultPages.length);
     // console.log('srp <=', (r <= this.searchResultPages.length ));
     if (this.searchResultPages &&
         this.searchResultPages.length > 0 &&
         r <= this.searchResultPages.length ) {
            return this.searchResultPages[r-1];
     } else {
              console.error('searchResultPages has no data.');
              return 1;
       }
  }

  public onUpdateFindResult(event: FindResultMatchesCount): void {
    // console.log('onUpdateFindResult called.');
    const PDFViewerApplication: IPDFViewerApplication = (window as any).PDFViewerApplication;
    const matchIndexes = event.matches as Array<Array<number>>;
    const matchesLengths = event.matchesLength as Array<Array<number>>;
    const matchesColors = event.matchesColor as Array<Array<number>>;
    // console.log('onUpdateFindResult lengths=', matchesLengths);

    setTimeout(() => {
     matchIndexes.forEach((findings, page) => {
        if (findings?.length > 0) {
          const currentPage = PDFViewerApplication.pdfViewer._pages[page];
          if (currentPage.textHighlighter.textDivs) {
            if (page && matchesLengths[page][0] > 0) {
              const converted = currentPage.textHighlighter._convertMatches([matchIndexes[page]], [matchesLengths[page]], [matchesColors[page]]) as Array<any>;
              const allSpans = currentPage.div.querySelectorAll('.textLayer > span') as NodeList;
              allSpans.forEach((span, index) => {
                if (converted.some((highlight) => index >= highlight.begin.divIdx && index <= highlight.end.divIdx)) {
                  (span as HTMLElement).classList.remove('fade-out');
                } else {
                  (span as HTMLElement).classList.add('fade-out');
                  (span as HTMLElement).scrollIntoView(true);
                }
              });
            }
          }
        }
      });
    }, 200);
  }

  copyLink(duid: string): void {
    // let href = this.router.url.replace('#','');
    // href = href + '?docid=' + docid;
    // let marker = TDDO: optionally accept a marker and add as URL fragment
    // const urlTree = this.router.createUrlTree(['/'], {
    //    queryParams: { docid: duid },
    //    fragment: marker
    // });

    const args = '?docid=' + duid;
    const link = window.location.origin + args;
    console.log('copyLink=', link);
    try {
       const writeToClipboard = async () => {
          await Clipboard.write({
              string: link
          });
       };
       this.toast.notify('info', 'Copy Link',
                                 'Document link copied to clipboard');
       console.log('copyLink = ', link);
    } catch (e) {
       this.toast.notify('error', 'Copy Link',
                         'Error copying Document link to clipboard');
       console.log('copyLink error e=', e);
    }
  }

  notifyDocumentLink(): void {
     this.toast.notify('info', 'Copy Link',
          'Document link copied to clipboard for ' + this.activeDocumentName);
  }

  setDocumentLink(duid: string): string {
     // console.log('getDocumentLink activeDoc=', this.documentsvc.activeDocument);
     let args='';
     if (this.documentsvc.activeDocument) {
        args = '?docid=' + duid;
     }
     this.activeDocumentLink = window.location.origin + '/' + args;
     console.log('setDocumentLink=', this.activeDocumentLink);
     return this.activeDocumentLink;
  }

  goToLink(url: string){
    // window.open(url, "_blank");
    window.open(url, "_self");
  }

  onSwipeLeft(evt) {
     console.log( "swipeleft evt=", evt);
     this.toggle_left_nav();
  }
  onSwipeRight(evt) {
     console.log( "swiperight evt=", evt);
     this.toggle_right_nav();
  }
  onSwipe(evt) {
     console.log( "swipe evt=", evt);
  }
  onPan(evt) {
     console.log( "pan evt=", evt);
     console.log( "pan overlay=", this.maincontent);
     console.log( "pan top=", this.maincontent.nativeElement.scrollTop);
     console.log( "pan left=", this.maincontent.nativeElement.scrollLeft);


     //const curpos = this.scroller.getScrollPosition();
     //
     const offset = [ evt.deltaX, evt.deltaY ];
     console.log( "offset=", offset);
     const curX = this.maincontent.nativeElement.scrollLeft;
     const curY = this.maincontent.nativeElement.scrollTop;
     console.log( "current=", curX, ",", curY);
     if ( curX <= 24 ) {
         console.log( "curX < 24 =", curX);
     }
     this.contentoverlay.nativeElement.scrollBy(evt.deltaX, evt.deltaY);

     //this.contentoverlay.nativeElement.scrollTop =
     //this.contentoverlay.nativeElement.scrollTop + ( evt.deltaX );
     //this.contentoverlay.nativeElement.scrollLeft =
     //this.contentoverlay.nativeElement.scrollLeft + ( evt.deltaY );
     // this.scroller.scrollToPosition( [ resultX, resultY ] );
   }

   emitLink(e) {
   }

  linktest( action ) {
    console.log('main test action=', action);
  }

//********************** MOBILE UI METHODS ********************************

    sidenavClose() {
      this.sidenavleft.close();
    }
    sidenavMain() {
      this.sidenavleft.open();
      this.sidenavright.close();
      this.leftNavMode = this.NAV_MAIN_MENU;
    }
    sidenavRecents() {
      this.sidenavright.close();
      this.leftNavMode = this.NAV_RECENTS;
      this.helpContext = HELP.RECENTS;
      this.showSidenavHelp = true;
    }
    sidenavSubaccounts() {
      this.leftNavMode = this.NAV_SUBACCOUNTS;
      this.helpContext = HELP.USER_SUBACCOUNTS;
      this.showSidenavHelp = true;
    }
    sidenavGroupList() {
      this.leftNavMode = this.NAV_GROUP_LIST;
      this.helpContext = HELP.GROUP_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = true;
      this.showGroupSearchBtn = false;
      this.showGroupEditBtn = true;
    }
    sidenavGroup() {
      this.sidenavright.close();
      if ( ! this.selectedGroup ) {
        if ( this.groups && this.groups.length > 0 ) {
           this.setSelectedGroupObj( this.groups[0] );
        }
      }
      this.leftNavMode = this.NAV_GROUP;
      this.helpContext = HELP.GROUP;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
      this.showGroupSearchBtn = false;
      this.showGroupEditBtn = false;
    }
    sidenavGroupMembers() {
      this.leftNavMode = this.NAV_GROUP_MEMBERS;
      this.showGroupSearchBtn = true;
      this.showGroupEditBtn = false;
    }
    sidenavAddGroupMember() {
      this.leftNavMode = this.NAV_ADD_GROUP_MEMBER;
      this.showGroupSearchBtn = false;
      this.showGroupEditBtn = false;
    }
    sidenavAddGroupMembers() {
      this.leftNavMode = this.NAV_ADD_GROUP_MEMBERS;
      this.showGroupSearchBtn = false;
      this.showGroupEditBtn = false;
    }
    sidenavGroupUpload() {
      this.leftNavMode = this.NAV_GROUP_UPLOAD;
      this.helpContext = HELP.UPLOAD_CONTENT;
      this.showGroupSearchBtn = true;
      this.showSidenavHelp = true;
    }
    sidenavGroupManager() {
      this.leftNavMode = this.NAV_GROUP_MANAGER;
      this.helpContext = HELP.GROUP_MGR;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
      this.showGroupSearchBtn = true;
      this.showGroupEditBtn = false;
    }
    sidenavSetupGroupMgr() {
      this.leftNavMode = this.NAV_SETUP_GROUP_MGR;
      this.helpContext = HELP.GROUP_MGR;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
      this.showGroupSearchBtn = true;
      this.showGroupEditBtn = false;
    }
    sidenavOrgTab() {
      this.leftNavMode = this.NAV_ORG_TAB;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavOrgList() {
      this.leftNavMode = this.NAV_ORG_LIST;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = true;
    }
    sidenavOrgTable() {
      this.leftNavMode = this.NAV_ORG_TABLE;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavOrgSelect() {
      this.leftNavMode = this.NAV_ORG_SELECT;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavOrgCombo() {
      this.leftNavMode = this.NAV_ORG_COMBO;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavUsrOrgSelect() {
      this.leftNavMode = this.NAV_USR_ORG_SELECT;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavOrgExpandSelect() {
      this.leftNavMode = this.NAV_ORG_EXPAND_LIST;
      this.helpContext = HELP.ORG_LIST;
      this.showSidenavHelp = true;
      this.showTitleFilter = false;
    }
    sidenavQuickStart() {
      this.sidenavright.close();
      this.leftNavMode = this.NAV_QUICK_START;
      this.helpContext = HELP.QUICK_START;
      this.showSidenavHelp = true;
    }
    sidenavQuickStart1() {
      this.sidenavright.close();
      this.leftNavMode = this.NAV_QUICK_START_1;
      this.helpContext = HELP.QUICK_START;
      this.showSidenavHelp = true;
    }
    sidenavQuickStart2() {
      this.leftNavMode = this.NAV_QUICK_START_2;
      console.log('leftNavMode=', this.leftNavMode);
      this.helpContext = HELP.QUICK_START;
      this.showSidenavHelp = true;
    }
    sidenavQuickStart3() {
      this.leftNavMode = this.NAV_QUICK_START_3;
      this.helpContext = HELP.QUICK_START;
      this.showSidenavHelp = true;
    }
    sidenavQuickStart4() {
      this.leftNavMode = this.NAV_QUICK_START_4;
      this.helpContext = HELP.QUICK_START;
      this.showSidenavHelp = true;
    }
    sidenavSetup() {
      this.sidenavright.close();
      this.leftNavMode = this.NAV_SETUP;
    }
    sidenavUploadFiles() {
      console.log('sidenavUploadFiles called...');
      this.sidenavright.close();
      this.leftNavMode = this.NAV_UPLOAD_FILES;
      console.log('upload files..');
      this.helpContext = HELP.UPLOAD_CONTENT;
      this.showSidenavHelp = true;
    }
    sidenavUserList() {
      this.leftNavMode = this.NAV_USER_LIST;
      this.helpContext = HELP.USER_LIST;
      this.showSidenavHelp = true;
    }
    sidenavPreferences() {
      this.leftNavMode = this.NAV_PREFERENCES;
    }
    sidenavFeedback() {
      this.leftNavMode = this.NAV_FEEDBACK;
      this.helpContext = HELP.USER_FEEDBACK;
      this.showSidenavHelp = true;
    }
    sidenavUserSettings() {
      this.leftNavMode = this.NAV_USER_SETTINGS;
      this.helpContext = HELP.USER_SETTINGS;
      this.showSidenavHelp = true;
    }
    sidenavInviteFree() {
      this.leftNavMode = this.NAV_INVITE_FREE;
    }
    sidenavInviteToken() {
      console.log('sidenavInviteToken..');
      this.leftNavMode = this.NAV_INVITE_TOKEN;
      this.helpContext = HELP.SEND_TOKEN;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavCreateLink() {
      this.linkOrigin = window.location.origin;
      this.linkTitle = '';
      this.linkURL = '';
      this.linkPageNbr = 1;
      this.linkDocumentData = null;
      this.linkLayerData = [];
      this.linkNoteData = null;
      this.linkMarkerData = null;
      console.log('sidenavCreateLink..');
      this.leftNavMode = this.NAV_CREATE_LINK;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavLinkExternal() {
      console.log('sidenavExternalLink..');
      this.leftNavMode = this.NAV_LINK_EXTERNAL;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavLinkInternal() {
      console.log('sidenavInternalLink..');
      this.getInternalURL();
      this.leftNavMode = this.NAV_LINK_INTERNAL;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavLinkFile() {
      this.linkLayerData = [];
      this.linkNoteData = null;
      this.linkMarkerData = null;
      this.linkPageNbr = 1;
      console.log('sidenavLinkFile..');
      this.linkMarkerData = null;
      this.leftNavMode = this.NAV_LINK_FILE;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavLinkPage() {
      console.log('sidenavLinkPage..');
      this.leftNavMode = this.NAV_LINK_PAGE;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    async sidenavLinkMarker() {
      console.log('sidenavLinkMarker..');
      this.linkLayerData = await this.layersvc.loadLayerData(this.linkDocumentData.uid);
      console.log('linkLayerData=', this.linkLayerData);
      this.linkNoteData = await this.markersvc.getMarkerData(this.linkDocumentData.uid, this.linkLayerData);
      // console.log('linkNoteData=', this.linkNoteData);
      this.leftNavMode = this.NAV_LINK_MARKER;
      this.helpContext = HELP.CREATE_LINK;
      this.showSidenavHelp = true;
      this.sidenavright.close();
      this.sidenavleft.open();
    }
    sidenavApprovalRequests() {
      console.log('sidenavApprovalRequests..');
      this.leftNavMode = this.NAV_APPROVAL_REQUEST
      this.helpContext = HELP.APPROVAL_REQUEST;
      this.showSidenavHelp = true;
      // this.sidenavright.close();
      this.sidenavleft.open();
    }
    setLeftNavMode(m){
       this.leftNavMode = m;
    }
    rightNavMenu() {
      this.collapseComments('tools-button');
      this.collapseComments('tools');
      this.collapseComments('markup');
      this.collapseComments('marker-section');
      this.collapseComments('file-button');
      this.collapseComments('file-section');
      this.collapseComments('view-button');
      this.collapseComments('view-section');
      this.rightNavMode = this.RNAV_MENU;
    }
    rightNavMarkup() {
      this.collapseComments('tools-button');
      this.collapseComments('tools');
      this.collapseComments('markup');
      this.collapseComments('marker-section');
      this.rightNavMode = this.RNAV_MARKER_TOOLS;
    }
    rightNavFileTools() {
      this.collapseComments('file-button');
      this.collapseComments('file-tools-content');
      this.rightNavMode = this.RNAV_FILE_TOOLS;
    }
    rightNavViewTools() {
      this.collapseComments('view-button');
      this.collapseComments('view-tools-content');
      this.rightNavMode = this.RNAV_VIEW_TOOLS;
    }
    setRightNavMode(m){
       this.rightNavMode = m;
    }

    // CJ - At some point probably implement a stack as this gets too
    // complicated. Push NAV onto stack then pop it off for back button.
    //
    sidenavBack(){
     console.log('sidenavBack called for ', this.leftNavMode);
     this.showSidenavHelp = false;
     this.showTitleFilter = false;
     switch (this.leftNavMode) {
        case this.NAV_RECENTS:
        case this.NAV_SUBACCOUNTS:
        case this.NAV_GROUP:    
        case this.NAV_GROUP_LIST:
        case this.NAV_QUICK_START:
        case this.NAV_QUICK_START_1:
        case this.NAV_SETUP:
        case this.NAV_UPLOAD_FILES:
        case this.NAV_INVITE_TOKEN:
        case this.NAV_PREFERENCES:
           this.leftNavMode = this.NAV_MAIN_MENU;
           break;
        case this.NAV_FEEDBACK:
        case this.NAV_INVITE_FREE:
        case this.NAV_USER_LIST:
        case this.NAV_USER_SETTINGS:
        case this.NAV_ORG_TAB:
        case this.NAV_ORG_LIST:
        case this.NAV_ORG_TABLE:
        case this.NAV_ORG_SELECT:
        case this.NAV_ORG_COMBO:
        case this.NAV_ORG_EXPAND_LIST:
        case this.NAV_USR_ORG_SELECT:
           this.sidenavSetup();
           break;
        case this.NAV_LINK_EXTERNAL:
        case this.NAV_LINK_FILE:
           this.sidenavCreateLink();
           break;
        case this.NAV_LINK_INTERNAL:
           this.sidenavLinkMarker();
           break;
        case this.NAV_LINK_PAGE:
        case this.NAV_LINK_MARKER:
           this.sidenavLinkFile();
           break;
        case this.NAV_ADD_GROUP_MEMBER:
        case this.NAV_ADD_GROUP_MEMBERS:
        case this.NAV_GROUP_MEMBERS:
        case this.NAV_GROUP_UPLOAD:
        case this.NAV_GROUP_MANAGER:
           this.leftNavText = this.selectedGroupObj?.name;
           //this.sidenavGroupList();
           this.sidenavGroup();
           break;
        case this.NAV_CREATE_LINK:
        case this.NAV_APPROVAL_REQUEST:
        case this.NAV_MAIN_MENU:
           this.leftNavMode = this.NAV_MAIN_MENU;
           this.sidenavleft.close();
           break;
        case this.NAV_QUICK_START_2:
           this.sidenavQuickStart1();
           break;
        case this.NAV_QUICK_START_3:
           this.sidenavQuickStart2();
           break;
        case this.NAV_QUICK_START_4:
           this.sidenavQuickStart3();
           break;
     }
    }
    rightNavBack(){
      switch (this.rightNavMode) {
        case this.RNAV_MARKER_TOOLS:
        case this.RNAV_FILE_TOOLS:
        case this.RNAV_VIEW_TOOLS:
           this.rightNavMenu();
           break;
        case this.RNAV_MENU:
           this.sidenavright.close();
           break;
      }
    }

    userFilter(value: string) {
       console.log('userFilter value=', value);
       console.log('userFilter userList=', this.userList);
       console.log('userFilter userListObj=', this.userListObj);
       this.userList.applyFilter(value);
    }

    orgFilter(value: string) {
       console.log('orgFilter value=', value);
       console.log('orgFilter orgList=', this.orgList);
       console.log('orgFilter orgListObj=', this.orgListObj);
       this.orgList.applyFilter(value);
    }

    handleDeleteGroupEvent(evt) {
       this.openDeleteGroupDialog(evt.groupUID, evt.groupName);
    }
    handleRemoveMemberEvent(evt) {
       this.openRemoveMemberDialog(evt.guid, evt.uuid);
    }

    handleShowGroups() {
       // console.log('this.groupMgr =', this.groupMgr);
       this.groupMgr.handleShowGroups();
    }

    handleGroupTabEvent(e) {
       this.groupTabIndex = e;
       console.log('groupTabIndex=', this.groupTabIndex);
    }

    //  Supports Marker Links in Mobile
    getInternalURL() {
       console.log('getInternalURL doc=', this.linkDocumentData);
       console.log('getInternalURL mark=', this.linkMarkerData);
       console.log('getInternalURL title=', this.linkTitle);
       console.log('getInternalURL page=', this.linkPageNbr);
       this.linkOrigin = window.location.origin;
       let args = '';
       if ( this.linkDocumentData != null ) {
          args = "?docid=" + this.linkDocumentData.uid;
          if ( this.linkMarkerData !== null ) {
            args += '&noteid=' + this.linkMarkerData.uid;
          } else {
              args += '&page=' + this.linkPageNbr;
          }
       }
       this.linkURL = this.linkOrigin + '/' + args;
    }

    saveLinkURL(duid) {
       this.linkOrigin = window.location.origin;
       const args = "?docid=" + duid;
       this.linkURL = this.linkOrigin + '/' + args;
    }
    saveLinkDocument(d: DocumentData) {
      console.log('saveLinkDocument d=', d);
      this.linkOrigin = window.location.origin;
      this.linkDocumentData = d;
      this.linkTitle = d.name;
      this.saveLinkURL(d.uid);
    }
    saveLinkMarker(m) {
      console.log('saveLinkMarker m=', m);
      this.linkMarkerData = m;
      this.linkTitle = m.title;
    }
    saveLinkPage(p) {
      this.linkPageNbr = p;
    }
    setMarkerUrl(url: string) {
      this.linkURL = url;
    }

    setMarkerTitle(t: string) {
      this.linkTitle = t;
    }

    pasteMarkerUrl(e: ClipboardEvent) {
       console.log("pasteTitle=", e);
//       let clipboardData = e.clipboardData || window.clipboardData;
//       const clipboardData = e.clipboardData;
//       const pastedText = clipboardData.getData('text');
//       this.setMarkerUrl( pastedText );
       console.log("linkURL=", this.linkURL);
    }

    pasteMarkerTitle(e) {
       console.log("pasteTitle=", e);
       console.log("linkTitle=", this.linkTitle);
    }

    isAppAdmin(email): boolean {
       const appAdmins = ['tilo@susarc.com', 'cory@susarc.com'];
       const hc = appAdmins.includes(email);
       //if (hc !== true ) {
       //  check userInfo appAdmin Field
       //}
       return hc;
    }

    navAppMgr() {
       this.router.navigateByUrl('/mdmgr');
    }

}
