import { Observable, from } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { AuthService } from './service/auth.service';

import { environment } from '../environments/environment';

// @Injectable()
// export class CustomHttpInterceptor implements HttpInterceptor {
//     constructor() {}
//
//     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//         return from(this.handleAccess(request, next));
//     }
//
//     private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
//         let newRequest = request;
//
//         // copy headers
//         const headers: {[name: string]: string | string[]; } = {};
//
//         for (const key of request.headers.keys()) {
//             headers[key] = request.headers.getAll(key);
//         }
//
//         // force app/json
//         headers['Content-Type'] = 'application/json';
//         const newHeaders = new HttpHeaders(headers);
//
//         newRequest = request.clone({ headers: newHeaders });
//         console.log("intercept");
//         return next.handle(newRequest).toPromise();
//     }
// }

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = localStorage.getItem('id_token');

        if (environment.mock) {
            return next.handle(req);
        }

        // if going to api, just send request over
        if (req.url === environment.apiUrl + '/auth') {
            return next.handle(req);
        }

        if (idToken) {
            if (req.url === environment.apiUrl + '/upload') {
                // API upload requires multipart content type
                const cloned = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${idToken}`,
                    }
                });
                return next.handle(cloned);
            } else {
                const cloned = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                return next.handle(cloned);
            }

        } else {
            if (req.url === '/login') {
                return next.handle(req);
            } else {
                this.router.navigate(['/login'], {replaceUrl: true});		
                return next.handle(req);
            }
        }
    }
}
