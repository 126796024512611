import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { OrgUserData } from '../org/model/orguserdata.model';
import { OrgUserInfo } from '../org/model/orguserinfo.model';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';
import { StatData } from '../model/statdata.model';
import { AvgData } from '../model/avgdata.model';

import {AuthService} from './auth.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'any'
})

export class OrgUserService {

  baseUrl = environment.apiUrl;

  // static usersvc = new UserService();

  currentOrgUser: OrgUserData = null;

  public OrgUser: OrgUserData = null;
  public OrgUserList: OrgUserData[] = null;

  public OrgUserCnt: CountData = null;
  public OrgUserGuestCnt: CountData = null;

  public OrgUserSystemCnt: CountData = null;
  public OrgUserGuestSystemCnt: CountData = null;

  // public OrgUserSystemStorage: SumData = null;

  public orgUserListItems$: BehaviorSubject<OrgUserData[]> = new BehaviorSubject<OrgUserData[]>(this.OrgUserList);

  private orgUserItem$: BehaviorSubject<OrgUserData> = new BehaviorSubject<OrgUserData>(this.OrgUser);

  orgUserList$: Observable<OrgUserData[]> = this.orgUserListItems$.asObservable();

  orgUserData$: Observable<OrgUserData> = this.orgUserItem$.asObservable();

  private orgUserCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgUserCnt);
  orgUserCount$: Observable<CountData> = this.orgUserCountSubj$.asObservable();

  private orgUserSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgUserSystemCnt);
  orgUserSystemCount$: Observable<CountData> = this.orgUserSystemCountSubj$.asObservable();

  private orgUserGuestCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgUserGuestCnt);
  orgUserGuestCount$: Observable<CountData> = this.orgUserGuestCountSubj$.asObservable();

  private orgUserGuestSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgUserGuestSystemCnt);
  orgUserGuestSystemCount$: Observable<CountData> = this.orgUserGuestSystemCountSubj$.asObservable();

  userData: UserData = null;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toast: ToasterService,
    private usersvc: UserService,
    ) {
        this.userData = this.auth.getTokenInfo();
    }


  getAllOrgUsers$(){
   this.http.get<OrgUserData[]> (this.baseUrl + '/orguser')
    .subscribe( res => { this.orgUserListItems$.next(res);
                         console.log('all org users =', res);
                       });
  }

  getAllOrgUsers(){
   return this.http.get<OrgUserData[]> (this.baseUrl + '/orguser');
  }

  getOrgUsers$(oid: string){
   this.http.get<OrgUserData[]> (this.baseUrl + '/orguser/org/' + oid)
    .subscribe( res =>
                      {
                       console.log('orgusersvc res=', res);
                       // console.log('orgusersvc len=', res.length);
                       // console.log('orgusersvc type =', typeof(res));
                       // console.log('orgusersvc array =', this.OrgUserList instanceof Array );
                       // this.OrgUserList = res;
                       this.OrgUserList = Object.assign([], res);
                       this.orgUserListItems$.next(res);
                       
                      });
  }

  getOrgUser$(uid: string){
   return this.http.get<OrgUserData> (this.baseUrl + '/orguser/' + uid);
    /*****
    .subscribe( res =>
                      {
                       this.OrgUser = res;
                       this.orgUserItem$.next(res);
                       console.log('org-user-list =', res);
                       return res;
                      });
    *****/
  }

  getOrgUserPromise(uid: string){
    return this.http.get<OrgUserData> (this.baseUrl + '/orguser/' + uid)
    .toPromise()
    .then( res => { console.log('orgsvc org-user = ', res);
                    return res as OrgUserData;
                  });
  }

  getOrgUsersPromise(oid: string){
    return this.http.get<OrgUserData[]> (this.baseUrl + '/orguser/org/' + oid)
    .toPromise()
    .then( res => { console.log('orgsvc org-user-list = ', res);
                    this.OrgUserList = res;
                    this.orgUserListItems$.next(res);
                    return res as OrgUserData[];
                  });
  }

  getUserOrgListObs$(uid: string){
   return this.http.get<OrgUserData[]> (this.baseUrl + '/orguser/user/' + uid);
  }
  
  getUserOrgList$(uid: string){
   this.http.get<OrgUserData[]> (this.baseUrl + '/orguser/user/' + uid)
    .subscribe( res => { this.orgUserListItems$.next(res);
                         console.log('svc org user info list =', res);
                       });
  }

  getOrgUserByOrgAndUid$(oid: string, uid: string){
   this.http.get<OrgUserData> (this.baseUrl + '/orguser/org-user/' + oid + '/' + uid)
    .subscribe( res =>
                      { this.orgUserItem$.next(res);
                        console.log('org user by org = ', res);
                        return res;
                      });
  }

  getOrgUserByOrgAndEmail$(oid: string, email: string){
   this.http.get<OrgUserData> (this.baseUrl + '/orguser/org-email/' + oid + '/' + email)
    .subscribe( res =>
                      { this.orgUserItem$.next(res);
                        console.log('org user by org = ', res);
                      });
  }

 getOrgUserByOrgAndUidPromise(oid: string, uid: string){
   const url = this.baseUrl + '/orguser/org-user/' + oid + '/' + uid;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgUserData> (this.baseUrl + '/orguser/org-user/' + oid + '/' + uid).toPromise()
    .then( res => { return res;
                    console.log('org user by oid, uid = ', res);
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid,uid: ', e);
                    throw e;
                  }
    );
  }

 getOrgUserByOrgAndEmailPromise(oid: string, email: string){
   const url = this.baseUrl + '/orguser/org-user/' + oid + '/' + email;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgUserData> (this.baseUrl + '/orguser/org-email/' + oid + '/' + email).toPromise()
    .then( res => {
                    console.log('org user by email, res = ', res);
                    return res;
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid, email: ', e);
                    throw e;
                  }
    );
  }

  async addUserAccount(user_email: string) {
     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // Add the org from orguserdata (json) on the server
     let usr = await this.usersvc.getUserByEmailPromise(user_email);
        if ( !usr || (usr && Object.keys(usr).length===0) ) {
          const uinfo = {
           'email': user_email,
           'new_user': true,
           'notify': true,
           'accounts': 0,
           'free': false,
           'expires': snm,
           'created': snow,
           'modified': snow,
           'modified_by': this.userData.uid,
          }
          usr = await this.usersvc.addUserInfo(uinfo);
        }
        console.log('orgusersvc add account usr: ', usr);
        return usr;
  }

   addListItem(new_data: any) {
     console.log('orgusrsvc addListItem new_data=', new_data);
     console.log('orgusrsvc addListItem OrgUserList=', this.OrgUserList);
     if ( ! this.OrgUserList && this.OrgUserList !== null ) {
        this.OrgUserList.push(new_data);
        // some angular libraries require breaking the array reference
        // to pick up the update in the array and trigger change detection.
        // In that case, you can do fo
        this.OrgUserList = Object.assign([], this.OrgUserList);
     }
   }

  async addOrgUserPromise(orguserdata: OrgUserData) {
     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     let usr = await this.usersvc.getUserByEmailPromise(orguserdata.org_user_email);
     if ( !usr || (usr && Object.keys(usr).length===0) ) {
        usr = await this.addUserAccount(orguserdata.org_user_email);
     }
     orguserdata.org_user_uid = usr.uid;
     /***
     if (!orguserdata.expires) {
        orguserdata.expires = snm;
     }
     ***/
     orguserdata.created = snow;
     orguserdata.modified = snow;
     orguserdata.timestamp = epoch_time;

     const jdata = JSON.stringify( orguserdata );
     return this.http.post(environment.apiUrl + '/orguser', jdata )
     .toPromise()
     .then(
           (x : OrgUserData) => {
                console.log('orgusersvc add orguser x=: ', x);
                this.addListItem( x );
                this.orgUserListItems$.next( this.OrgUserList );                
                this.orgUserItem$.next( x );
                this.getOrgUsers$(orguserdata.org_uid);
                return x as OrgUserData;
            },
            (e) => {
                console.log('orgusersvc failed to create org: ', jdata,
                            ' error:', e);
                throw e;
            }
      );
   }

   updateListItem(new_data: any) {
     console.log('orgusersvc updateListItem new_data=: ', new_data);
     console.log('orgusersvc updateListItem OrgUserList=: ', this.OrgUserList);
     if ( this.OrgUserList ) {
        const idx = this.OrgUserList.findIndex(obj => obj.uid === new_data.uid);
        this.OrgUserList[idx] = new_data;
        console.log('orgusersvc updateListItem idx=: ', idx);
        console.log('orgusersvc updateListItem old_data=: ', this.OrgUserList[idx]);
     }

     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgUserList = Object.assign([], this.OrgUserList);
   }

   async updateOrgUserPromise( uid: string, orguserdata: OrgUserData ) {
        // Add the org from orgdata (json) on the server
        const jdata = JSON.stringify( orguserdata );
        return this.http.put(environment.apiUrl + '/orguser/' + uid, jdata )
        .toPromise()
        .then(
            (x : OrgUserData) => {
                console.log('orgusersvc edit org x=: ', x);
                // Next update the OrgUserList item
                this.updateListItem(x);
                // Update List Behavior Subject
                this.orgUserListItems$.next( this.OrgUserList );
                // Update BehaviorSubject       
                this.orgUserItem$.next( x );
                // retrieve the updated list for observables
                this.getOrgUsers$(orguserdata.org_uid);                              
                return x as OrgUserData;
            },
            (e) => {
                console.log('orgusersvc failed to edit org: ', jdata,
                            ' error:', e);
                throw e;
            }
        );
   }

  removeListItem(del_uid: any) {
    const idx = this.OrgUserList.findIndex(obj => obj.uid === del_uid);
    if ( idx ) {
       this.OrgUserList.splice(idx, 1);
       this.OrgUserList = Object.assign([], this.OrgUserList);
    }
  }

  // Remove Org User
  removeOrgUserPromise(uid): any {
    // remove orguser record
    return this.http.delete(environment.apiUrl + '/orguser/' + uid)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgusrsvc remove oid=: ', uid);
                  console.log('orgusrsvc remove x=: ', );                 
                  this.removeListItem(uid);
                  this.orgUserListItems$.next( this.OrgUserList );
                  return x;
              },
              (e) => {
                console.error('orgusrsvc remove org user uid =', uid);        
                console.error('orgusrsvc remove org user error=', e);           
                throw e;
             }
        );
  }

  // Transfer groups to org_user uid2
  xferOrgUserContentPromise(uid, uid2): any {
     if ( uid2 ) {
        return this.xferOrgUserContentToUser( uid, uid2);
     } else {
        return this.xferOrgUserContentToOwner( uid );
       }
  }

  // Transfer groups to org_user uid2
  xferOrgUserContentToUser(uid, uid2): any {
    // remove orguser record
    const xferData = {uid: uid, uid2: uid2};
    const jdata = JSON.stringify( xferData );
    console.log('ousvc: xfer org user from uid=', uid);
    console.log('ousvc: xfer org user to uid2=', uid2);    
    return this.http.put(environment.apiUrl + '/orguser/xfcu/' + uid  + '/' + uid2, jdata)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgusrsvc xfcu x=: ', x);
                  console.log('orgusrsvc xfcu uid=: ', uid);
                  console.log('orgusrsvc xfcu uid2=: ', uid2);	  
                  return x;
              },
              (e) => {
                console.error('orgusrsvc xfcu from uid =', uid);
                console.error('orgusrsvc xfcu to uid =', uid2);
                console.error('orgusrsvc xfcu error=', e);
                throw e;
             }
        );
  }

  // Transfer groups to org_user uid2
  xferOrgUserContentToOwner(uid ): any {
    // remove orguser record
    const xferData = {uid: uid};
    const jdata = JSON.stringify( xferData );
    console.log('xfer org user to owner from uid=', uid);
    return this.http.put(environment.apiUrl + '/orguser/xfco/' + uid, jdata)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgusrsvc xfco x=: ', x);
                  return x;
              },
              (e) => {
                console.error('orgusrsvc xfco from uid =', uid);
                console.error('orgusrsvc xfco error=', e);
                throw e;
             }
        );
  }

  // Remove Org User Org Content (groups, docs)
  removeOrgUserContentPromise(uid): any {
    // remove orguser record
    const rmcData = {uid: uid};
    const jdata = JSON.stringify( rmcData );    
    return this.http.put(environment.apiUrl + '/orguser/rmc/' + uid, jdata)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgusrsvc rmc uid=: ', uid);
                  return x;
              },
              (e) => {
                console.error('orgusrsvc rmc org user uid =', uid);
                console.error('orgusrsvc remove org user error=', e);           
                throw e;
             }
        );
  }

  getOrgUserCount$(oid: string){
   this.http.get<CountData> (this.baseUrl + '/orguser/count/org' + oid)
    .subscribe( cnt => { console.log('org user cnt oid=', oid);
                         console.log('org user org cnt=', cnt);
                         this.orgUserCountSubj$.next(cnt);
                         return cnt as CountData;
                       });
  }

  getOrgUserSystemCount$(){
   this.http.get<CountData> (this.baseUrl + '/orguser/count/system')
    .subscribe( cnt => { console.log('org user system cnt =', cnt);
                         this.orgUserSystemCountSubj$.next(cnt);
                         return cnt as CountData;
                       });
  }


} // OrganizationService