import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from '../../environments/environment';

@Injectable()
export class ValidationService {
    isValid: any;

    constructor(private http: HttpClient) { }

    // 450 - allow + in email username
    //
    isValidEmailAddress(emailField){
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) === false)
        {
            // alert('Invalid Email Address');
            return false;
        }
        return true;
    }

    extractEmailAddresses(text) {
      let t = text.match(/([a-zA-Z0-9_\-.+]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      t = t.join('\n');
      return t;
    }

   validatePassword(value): number {
        const password = value;
        if (password.length === 0) {
            // Show nothing when empty
            return 0;
        }
        const matchedCase = [];
        matchedCase.push('[$@$!%*#?&]');
        matchedCase.push('[A-Z]');
        matchedCase.push('[a-z]');
        matchedCase.push('[0-9]');

        let ctr = 0;
        for (const m of matchedCase) {
            if (new RegExp(m).test(password)) {
                ctr++;
            }
        }

        return ctr;
   }

   // Future function, perhaps to USPS API
   isValidZipCode(zip: string): boolean {
        return true;
   }
}
