import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../environments/environment';

import { StatusData } from '../model/statusdata.model';

@Component({
    selector: 'app-tos',
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.css']
})
export class TosComponent {
    systemStatus: StatusData = new StatusData();

    constructor(
        private http: HttpClient
    ) {
        this.http.get(environment.apiUrl + '/status')
            .subscribe(
                (x) => { this.systemStatus = (x as StatusData); },
                (e) => console.log(e)
            );
    }

    ctimeDate(timestamp) {
        return new Date(timestamp * 1000);
    }

}
