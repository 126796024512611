// CJ - Dialog to show help from local assets/help files or remote URLs
//
import { Component, OnInit, Inject, ViewChild, ElementRef, Output,
         EventEmitter, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

@Component({
    selector: 'app-progress-dialog',
    templateUrl: './progress.dialog.html',
    styleUrls: ['./progress.dialog.css']
})

export class ProgressDialogComponent implements OnInit, AfterViewInit {
    @Output() progressEvent  = new EventEmitter<any>();

    @ViewChild('content', {static: true}) content: ElementRef;

    dialogTitle: string;
    dialogMessage: string;
    dialogProgress: number;
    dialogMode = 'indeterminate';

    progressContext: number;
    helpContent: any;

    constructor(
          private dialogRef: MatDialogRef<ProgressDialogComponent>,
          @Inject(MAT_DIALOG_DATA) public data
          ) {
              if (data) {
                this.dialogTitle = data.title;
                this.dialogMessage = data.message;
                this.dialogProgress = data.progress ?? 0;
                this.dialogMode = data.mode;

                if ( ! data.mode ) {
                   this.dialogMode = 'indeterminate';
                } else {
                     this.dialogMode = data.mode;
                }
              }
              // console.log('ProgressDialog data=', data);
            }

    ngOnInit() {
    }

    async ngAfterViewInit() {
    }

    waitAndClose(ms) {
      console.log('Closing progress in ' + ms + 'ms');
      setTimeout (() => {
         this.close();
      }, ms);
    }

    close() {
      // User canceled or quit operation
      if ( this.dialogRef ) {
         this.dialogRef.close();
      }
    }

    save() {
      // bundle the intent and data
      this.close();
    }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }


}
