// CJ - Dialog to show help from local assets/help files or remote URLs
//
import { Component, OnInit, Inject, ViewChild, ElementRef, Output,
         EventEmitter, AfterViewInit } from '@angular/core';
import { ToasterService } from '../../service/toaster.service';
import { environment } from '../../../environments/environment';

import { UserInfo } from '../../model/userinfo.model';

import * as HELP from '../../service/help.service';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';


export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

@Component({
    selector: 'app-user-subaccounts-dialog',
    templateUrl: './user-subaccounts.dialog.html',
    styleUrls: ['./user-subaccounts.dialog.css']
})

export class UserSubAccountsDialogComponent {
    @Output() refreshSubsEvent = new EventEmitter<any>();
    @Output() helpEvent = new EventEmitter<any>();
    @Output() displayUserInfoEvent = new EventEmitter<any>();

    @ViewChild('content', {static: true}) content: ElementRef;

    userInfo: UserInfo = null;

    constructor(
        private toast: ToasterService,
        private dialogRef: MatDialogRef<UserSubAccountsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data )
        {
           if (data) {
              this.userInfo = data.userInfo;
           } // if (data)
           console.log('UserSubAccountDialog data=', data);
        }

    close() {
      // User canceled or quit operation
      if ( this.dialogRef ) {
         this.dialogRef.close();
      }
    }

    save() {
      // bundle the intent and data
      this.close();
    }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }

   helpDialog() {
      this.helpEvent.emit(HELP.USER_SUBACCOUNTS);
   }

   displayUserInfo(uid) {
      this.displayUserInfoEvent.emit(uid);
   }
}
