
<div #markerView style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; height: 100% !important; width: 100%; margin: 0; padding: 0; overflow: none;">

<div #markerHidden hidden></div>
<!--  
  <span class="title" style="width: 100%; margin: 0; font-size: 0.7rem">{{this.documentName}}</span>
 -->

  <!--   <button mat-button (click)="createPDF()">PRINT</button> -->  
  
  <div id="markerListTable" *ngIf="template===this.TABLE_MARKERS" #tableView style="margin: 0; padding: 0; margin-top: 0.5rem;">
   
   <mat-table #table [dataSource]="matSource" style="width: 100%; height: 100%; margin: 0;" matSort matSortActive="pageNbr" matSortDirection="asc">

  <!-- <mat-table #table [dataSource]="dataSource"> -->

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Page Column -->
    <ng-container matColumnDef="typeNbr">
      <mat-header-cell *matHeaderCellDef mat-sort-header>TypeNbr</mat-header-cell>
      <mat-cell *matCellDef="let marker">{{marker.typeNbr}}</mat-cell>
    </ng-container>

    <!-- Page Column -->
    <ng-container matColumnDef="pageNbr">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Page</mat-header-cell>
      <mat-cell *matCellDef="let marker">
      	<button mat-flat-button
		style="color: blue"
                (click)="scrollToPage(marker.pageNbr)">
                {{marker.pageNbr}}
                </button></mat-cell>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell *matCellDef="let marker">
      	<button mat-flat-button
		style="color: blue"
                (click)="scrollToNote(marker.element)">
                {{marker.id}}
                </button></mat-cell>
    </ng-container>

    <!-- Y Column -->
    <ng-container matColumnDef="yvalue">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Y Value</mat-header-cell>
      <mat-cell *matCellDef="let marker">{{marker.y}}</mat-cell>
    </ng-container>
    
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
      <mat-cell *matCellDef="let marker">{{getDate(marker)}}</mat-cell>
    </ng-container>
    
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let marker">
      	<button *ngIf="marker.type!=='approval';else typeApproval"
                mat-flat-button
		style="color: blue"
                (click)="scrollToNote(marker.element)">
                {{marker.type}}
        </button>
        <ng-template #typeApproval>
           <button mat-flat-button
                   style="color: blue"
                   (click)="scrollToNote(marker.element)">
                   {{marker.type}} ({{marker.approvalScope}})
           </button>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!--Owner Column -->
    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Creator</mat-header-cell>
      <mat-cell *matCellDef="let marker"
		style="cursor: default;"
                (click)="displayUserInfo(marker.uid)">
	        {{marker.owner}}</mat-cell>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
      <mat-cell *matCellDef="let marker">
	<button mat-flat-button
		style="color: blue"
                (click)="scrollToNote(marker.element)">
                {{marker.title}}
                </button></mat-cell>
    </ng-container>
    
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Description </mat-header-cell>
      <mat-cell *matCellDef="let marker"
		style="display: flex; flex-flow: column; justify-content: flex-start; align-items: flex-start;">
        <span style="color: gray; font-size: small">{{marker.owner}}</span>
        <span style="color: gray; font-size: small">{{getDateString(marker.created_at)}}</span><br>
	<!-- {{convertToPlain(marker.description)}} -->
	<div [innerHTML]="htmlClean(marker.description,false)"></div>
      </mat-cell>
    </ng-container>

    <!-- Comment Count Column -->
    <ng-container matColumnDef="#comments">
      <mat-header-cell *matHeaderCellDef mat-sort-header>#Comments</mat-header-cell>
      <mat-cell *matCellDef="let marker"> {{getCommentCount(marker)}} </mat-cell>
    </ng-container>
    
    <!-- Comments Column -->
    <ng-container matColumnDef="comments">
      <mat-header-cell *matHeaderCellDef>Comments</mat-header-cell>
      <mat-cell *matCellDef="let marker"
        style="display: flex; flex-flow: column; justify-content: flex-start; align-items: flex-start;">
	<ng-container *ngFor="let cmt of marker.comments">
          <span style="color: gray; font-size: small">{{cmt.owner}}</span>
          <span style="color: gray; font-size: small">{{getDateString(cmt.created_at)}}</span>
          <div [innerHTML]="htmlClean(cmt.comment,false)"></div><br>
	</ng-container>
      </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6">
        Loading data...
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6">
        No data.
      </mat-footer-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

<!---    
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':matSource!=null}"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(matSource!=null && matSource.data.length===0)}"></mat-footer-row>    
--->

  </mat-table>

 <!--
  <div #scrUp class="scroll" id="scroll-up" (click)="scroll_up();">
    <mat-icon style="align: middle">arrow_drop_up</mat-icon>
  </div>
  
  <div #scrDn class="scroll" id="scroll-down"
       style="opacity 1;"
       (click)="scroll_down();">
       <mat-icon>arrow_drop_down</mat-icon>
  </div>
--->

  </div> <!-- table view template -->

  <!-- Markers SideNav component Template -->

    <div *ngIf="template===SIDENAV_MARKERS"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">


  <!---
    <div class="ml-sidenav-section-header" (click)="collapseComments('ml-comment-content');toggleFiber = !toggleFiber" matTooltip="Toggle Markers" matTooltipPosition="right" aria-label="Toggle Markers">MARKERS<mat-icon id="fiber_manual_record" style="padding-top: 0%; padding-bottom: 0%">{{ toggleFiber ? 'fiber_manual_record' : 'remove' }}</mat-icon>
    </div>
   --->
            <div id="ml-comment-content">
                <div class="ml-sidenav-section-content list-questions">
                    <ul class="sidenav-list">
                        <li>
                           <div class="vert-align" id="question-color-left" (click)="minimize_comments('ml-ul-question')"  (click)="toggleStateQ = !toggleStateQ" matTooltip="Toggle Questions" matTooltipPosition="right" aria-label="Toggle Questions">			    
                            <img src="/assets/white_notation.png" class="side-icon"><span>QUESTIONS</span><mat-icon>{{ toggleStateQ ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                            </div>
                            <ul id="ml-ul-question">
                                <li class="list-line" *ngFor="let note of noteData.question; let index = index;"><img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-question-' + (index + 1))" matTooltip="{{note.title}}">{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="ml-sidenav-section-content list-notes">
                    <ul class="sidenav-list">
                        <li>
                            <div class="vert-align" id="comment-color-left" (click)="minimize_comments('ml-ul-note')" (click)="toggleState = !toggleState" matTooltip="Toggle Comments" matTooltipPosition="right" aria-label="Toggle Comments">                                 <img class="side-icon" src="/assets/white_notation.png" ><span>COMMENTS</span> <mat-icon>{{ toggleState ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                            </div>
                            <ul id="ml-ul-note">
                                <li
                                    class="list-line"
                                    *ngFor="let note of noteData.note; let index = index;">
                                        <img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-note-' + (index + 1))" matTooltip="{{note.title}}">{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="ml-sidenav-section-content list-issues">
                    <ul class="sidenav-list">
                        <li>
                            <div class="vert-align" id="issue-color-left" (click)="minimize_comments('ml-ul-issue')"  (click)="toggleStateI = !toggleStateI" matTooltip="Toggle Issues" matTooltipPosition="right" aria-label="Toggle Issues"><img src="/assets/white_notation.png" class="side-icon"><span>ISSUES</span><mat-icon>{{ toggleStateI ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon></div>
                            <ul id="ml-ul-issue">
                                <li class="list-line" *ngFor="let note of noteData.issue; let index = index;"><img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-issue-' + (index + 1))" matTooltip="{{note.title}}">{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
        </div> <!-- comment content -->
    </div> <!-- sidenav section -->


    <div *ngIf="template===MOBILE_MARKERS"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
	 
            <div id="ml-comment-content">
                <div class="mobile-sidenav-section-content list-questions">
                    <ul class="sidenav-list">
                        <li>
                           <div class="mobile-vert-align" id="question-color-left" (click)="minimize_comments('ml-ul-question')"  (click)="toggleStateQ = !toggleStateQ" aria-label="Toggle Questions">			    
<!--                            <img src="/assets/white_notation.png" class="side-icon"> --> <span>QUESTIONS</span><mat-icon>{{ toggleStateQ ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                            </div>
                            <ul id="ml-ul-question">
                                <li class="list-line" *ngFor="let note of noteData.question; let index = index;"><img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-question-' + (index + 1))" >{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="ml-sidenav-section-content list-notes">
                    <ul class="sidenav-list">
                        <li>
                            <div class="mobile-vert-align" id="comment-color-left" (click)="minimize_comments('ml-ul-note')" (click)="toggleState = !toggleState" aria-label="Toggle Comments"><!--<img class="side-icon" src="/assets/white_notation.png" > --><span>COMMENTS</span> <mat-icon>{{ toggleState ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
                            </div>
                            <ul id="ml-ul-note">
                                <li
                                    class="list-line"
                                    *ngFor="let note of noteData.note; let index = index;">
                                        <img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-note-' + (index + 1))" >{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="ml-sidenav-section-content list-issues">
                    <ul class="sidenav-list">
                        <li>
                            <div class="mobile-vert-align" id="issue-color-left" (click)="minimize_comments('ml-ul-issue')"  (click)="toggleStateI = !toggleStateI" aria-label="Toggle Issues"><img src="/assets/white_notation.png" class="side-icon"><span>ISSUES</span><mat-icon>{{ toggleStateI ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon></div>
                            <ul id="ml-ul-issue">
                                <li class="list-line" *ngFor="let note of noteData.issue; let index = index;"><img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-issue-' + (index + 1))" >{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="ml-sidenav-section-content list-approvals">
                    <ul class="sidenav-list">
                        <li>
                            <div class="mobile-vert-align" id="approval-color-left" (click)="minimize_comments('ml-ul-issue')"  (click)="toggleStateI = !toggleStateI" aria-label="Toggle Issues"><!--<img src="/assets/white_notation.png" class="side-icon">--><span>APPROVALS</span><mat-icon>{{ toggleStateI ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon></div>
                            <ul id="ml-ul-approval">
                                <li class="list-line" *ngFor="let note of noteData.approval; let index = index;"><img src="/assets/grey_comment_icon.svg" class="side-icon-grey">
                                        <a href='#' (click)="scrollToNote('user-approval-' + (index + 1))" >{{index+1}} {{note.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div> <!-- comment content -->
	    
    </div> <!-- END MOBILE_MARKER section -->

    <ng-template #loadingTemplate>
      <div style="margin-top: 25px; margin-left: 16px;">
	<span>Loading Data...</span>
      </div>
    </ng-template>
  <div *ngIf="template===LINK_MARKERS"
       style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
      <ng-container *ngIf="!loading;else loadingTemplate">
      <div *ngFor="let note of linkMarkerList; let index = index;">
        <div style="display: flex; flex-flow: row; justify-content: flex-start; padding-top: 1rem; padding-bottom: 1rem;" (click)="emitSelectMarker(note)" class="link-marker-item"
             *ngIf="note.uuid && note.uuid!==''"
	      (click)="emitSelectMarker(note)">
	  <!--
          <span *ngIf="note.uid">uid: {{note.uid}}</span>
	  <span *ngIf="note.uuid">uuid: {{note.uuid}}</span>	  
          -->
	  <ng-container [ngSwitch]="note.type">  	  
            <img #markerImg
		 *ngSwitchCase="'note'"
                 class="right-side-icon"
                 [src]="iconPathComment"
                 style="width: 16px; height: 20px;">
            <img #markerImg
		 *ngSwitchCase="'question'"
                 class="right-side-icon"
                 [src]="iconPathQuestion"
                 style="width: 16px; height: 20px;">
            <img #markerImg
		 *ngSwitchCase="'issue'"
                 class="right-side-icon"
                 [src]="iconPathIssue"
                 style="width: 16px; height: 20px;">

	    <span *ngSwitchCase="'note'"
	      style="margin-left: 0.5rem; margin-right: 0.5rem;">
	      C-{{index}}</span>
	    <span *ngSwitchCase="'question'"
	      style="margin-left: 0.5rem; margin-right: 0.5rem;">
	      Q-{{index}}</span>
	    <span *ngSwitchCase="'issue'"
	      style="margin-left: 0.5rem; margin-right: 0.5rem;">
	      I-{{index}}</span>
	    <span *ngSwitchCase="'approval'"
	      style="margin-left: 0.5rem; margin-right: 0.5rem;">
	      I-{{index}}</span>
	    <span *ngSwitchDefault
	      style="margin-left: 0.5rem; margin-right: 0.5rem;">
	      I-{{index}}</span>
	  </ng-container>
	  <span *ngIf="note.title!==''; else blank"
		style="margin-left: 0.5rem; margin-right: 0.5rem">
	        {{note.title}}</span>
	  <ng-template #blank>
	    <span style="margin-left: 0.5rem; margin-right: 0.5rem">
	      Blank Title</span>
	  </ng-template>
	  <!--
	  <span>{{note.description}}</span>
          -->
	</div>
        <mat-divider></mat-divider>
      </div>
     </ng-container>
  </div> <!-- END LINK_MARKER section -->

    <!--
    <div *ngIf="template===LINK_MARKERS"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 100%">
    <mat-list>
      <div *ngFor="let note of noteData.question; let index = index;">
	<mat-list-item class="link-marker-item"
                       *ngIf="note.uuid && note.uuid!==''"
		       (click)="emitSelectMarker(note)">
	  <div style="display: flex; flex-flow: row; justify-content: space-between" (click)="emitSelectMarker(note)">
          <span *ngIf="note.uid">uid: {{note.uid}}</span>
	  <span *ngIf="note.uuid">uuid: {{note.uuid}}</span>	  
	  <span>{{note.type}}</span>	  
	  <span>{{note.title}}</span>
	  <span>{{note.description}}</span>
	  </div>
          <mat-divider></mat-divider>
	</mat-list-item>
      </div>
    </mat-list>
    <mat-list>	
      <div *ngFor="let note of noteData.note; let index = index;">
	<mat-list-item class="link-marker-item"
	  *ngIf="note.uuid && note.uuid!==''"	  
          (click)="emitSelectMarker(note)">
	  <div style="display: flex; flex-flow: row; justify-content: space-between" (click)="emitSelectMarker(note)">
          <span *ngIf="note.uid">uid: {{note.uid}}</span>
	  <span *ngIf="note.uuid">uuid: {{note.uuid}}</span>	  
	  <span>{{note.type}}</span>	  
	  <span>{{note.title}}</span>
	  <span>{{note.description}}</span>
	  </div>
	</mat-list-item>
        <mat-divider></mat-divider>	  	
      </div>
    </mat-list>
    <mat-list>	
      <div class="link-marker-list" *ngFor="let note of noteData.issue; let index = index;">
	<mat-list-item *ngIf="note.uuid && note.uuid!==''"
	  class="link-marker-item"
         (click)="emitSelectMarker(note)">
	  <div style="display: flex; flex-flow: row; justify-content: space-between" (click)="emitSelectMarker(note)">
	  <span *ngIf="note.uid">uid: {{note.uid}}</span>
	  <span *ngIf="note.uuid">uuid: {{note.uuid}}</span>
	  <span>{{note.type}}</span>	  
	  <span>{{note.title}}</span>
	  <span>{{note.description}}</span>
	  </div>
	</mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>       
	  
    </div> <!-- END LINK_MARKER section -->
    
</div>  <!-- marker-list component -->  

