<!--  <div class="title" style="margin-left: 0rem;">User Settings</div> -->
<div class='settings-container'>
    <form [formGroup]="settings">  
    <div class='settings-content' [formGroup]="settings" (swipeleft)="swipe(get_index(), $event.type)" (swiperight)="swipe(get_index(), $event.type)">
        <div class="red" >
            <label class="hoverable" for="fileInput">
                <img [src]="url ? url : '/assets/Profile_icon.png'" style="height=10%"/> 
                <div class="hover-text">EDIT</div>
                <div class="background"></div>
            </label>
            <input id="fileInput" type='file' (change)="onSelectFile($event)" autocomplete="off">
                <p id="account-info">{{ userInfo.name }} {{ userInfo.last_name }}</p>
                <p id="account-info">{{ userInfo.company }}</p>
                <p id="account-info">{{ userInfo.phone }}</p>
                <!-- <p id="account-info">{{ userData.user }}</p> -->
                <p id="account-info">{{ userInfo.email }}</p>
                <p id="account-info">Renews/Expires:<br/>
	          <span style="font-size: 0.8rem">{{ userInfo.expires }}</span>
		</p>
        </div>
    

        <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectChange()" [formGroup]="settings" >
            <!-- Personal info -->
            <mat-tab (swipeleft)="swipe(1, $event.type)" (swiperight)="swipe(1, $event.type)">
                <ng-template mat-tab-label> <mat-icon style="padding-right:3px;">account_circle</mat-icon> User Profile</ng-template>
                <!-- <form [formGroup]="profileForm"> -->
                  <form>
                    <div class="mobile-look" >
                        <label class="hoverable" for="fileInput" id="red_icon">
                            <img [src]="url ? url : '/assets/redProfile_icon.png'" />
                        </label>
                        <input id="fileInput" type='file' onclick="this.value=null" (change)="onSelectFile($event)" autocomplete="off">
                    </div>
                    <div [formGroup]="infoSettings">
<!--                        <p id="title" > User Profile</p>  
  -->
                <p id="title" ></p> 
                        <mat-form-field>
                            <mat-label>First Name</mat-label>
                            <input matInput type="text" value="{{userInfo.name}}" formControlName="name" [readonly]="isReadonly" id="edit_focus" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Last Name</mat-label>
                            <input matInput type="text" value="{{userInfo.last_name}}" formControlName="last_name" [readonly]="isReadonly" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Company</mat-label>
                            <input matInput type="text" value="{{userInfo.company}}" formControlName="company" [readonly]="isReadonly" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Phone Number</mat-label>
                            <span matPrefix>+1 &nbsp;</span>
                            <input matInput type="tel" placeholder="555-555-1234" value="{{userInfo.phone}}" formControlName="phone" [readonly]="isReadonly" autocomplete="off">
                        </mat-form-field>
                         <mat-slide-toggle
			   ngDefaultControl color="primary"
			   [checked]="userInfo.notify"
			  (click)="toggle_notify()">Email Notifications
			</mat-slide-toggle>
		    </div>
                </form>		    
                <button mat-button *ngIf="show===false"(click)="editInfo()" [disabled]="isDisabled" id="small-edit-button" class="action-button" ><mat-icon>create</mat-icon> Edit</button>
                <button mat-button *ngIf="show" (click)="updateInfo()" class="action-button" style="margin-left: 1rem" >Update Info</button>
            </mat-tab>

            <!-- Password -->
            <mat-tab (swipeleft)="swipe(1, $event.type)" (swiperight)="swipe(1, $event.type)">
	      
      <ng-template mat-tab-label> <mat-icon style="padding-right:3px;">https</mat-icon>Password Reset</ng-template>	      

        <div class="pwd-reset">
                <mat-form-field style="margin-top: 2rem;"
	            appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email" readonly autocomplete="off">
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>
		
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput #password type="{{pwdType}}"
                        (keyup)="validate_password($event)"
                        (focusout)="clear_status($event)"
                           formControlName="password" autocomplete="off">
                      <mat-icon matSuffix (click)="toggle_pwd()">{{pwdIcon}}</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput #passwordconfirm type="{{cpwdType}}" (keyup.enter)="submit_form()" formControlName="passwordconfirm" autocomplete="off">
                    <mat-icon matSuffix (click)="toggle_cpwd()">{{cpwdIcon}}</mat-icon>		    
                </mat-form-field>

                <button mat-raised-button type="submit" (click)="submit_form()">Update Password</button>
                <div id='change-status'>{{changeStatus}}</div>
	</div>
    </mat-tab>	       
<!--		
		<div class="mobile-look" >
                <p id="title" ></p> 
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email" readonly autocomplete="off">
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput #password type="password"
                        (keyup)="validate_password($event)"
                        (focusout)="clear_status($event)"
                        formControlName="password" autocomplete="off">
                    <mat-icon matSuffix>https</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput #passwordconfirm type="password" (keyup.enter)="submit_form()" formControlName="passwordconfirm" autocomplete="off">
                    <mat-icon matSuffix>https</mat-icon>
                </mat-form-field>

                <button mat-raised-button class="action-button" type="submit" (click)="submit_form()">Update Password</button>
                <div id='change-status'>{{changeStatus}}</div>
                </div>
--->


<!-- OMITTING GUEST ACCOUNTS FOR ITS OWN DIALOG 
     MIGHT REPLACE WITH A USER PREFERENCES COMPONENT PAGE	    
            <mat-tab *ngIf="userInfo.accounts > 0" (swipeleft)="swipe(3, $event.type)" (swiperight)="swipe(3, $event.type)">
                <ng-template mat-tab-label>
                    <mat-icon style="padding-right: 3px;">assistant_photo</mat-icon>
                    Guest Accounts
                </ng-template>
                <div class="mobile-look">
                    <div id="tab-content">
                      <app-subaccount-edit #subEdit style="overflow-y: none;">
		      </app-subaccount-edit>
                    </div>
                </div>
            </mat-tab>
-->

            <!-- TERMS OF SERVICE -->
            <mat-tab (swipeleft)="swipe(4, $event.type)" (swiperight)="swipe(4, $event.type)"> 
                <ng-template mat-tab-label> <mat-icon style="padding-right:3px;">assistant_photo</mat-icon> Terms of Service</ng-template>
                <div class="mobile-look" >
                    <div id="tab-content">
                        <app-tos></app-tos>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</form>
</div>
