<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon style="margin-left: 0; padding-left: 0;">drag_indicator</mat-icon>
    <span class="title" >Send Us Your Feedback!</span>
  </div>
  <div class="dialog-header-right">
   <mat-icon (click)="helpDialog()"
             matTooltip="User Feedback Help"
             >help_outline</mat-icon>
   <mat-icon (click)="close()"
             matTooltip="Close Feedback Dialog"
	     style="margin-left: 0.5rem; align: right">close</mat-icon>
  </div>
</div>

<mat-dialog-content #content class="panel-class" style="margin: 0; padding: 0; border-top: 1px solid #1A567A;">
          <div id="content">
            <app-feedback-data #feedback
			       style="overflow-y: none;"
			       (submitEvent)="this.close()"
			       >
            </app-feedback-data>
          </div>
</mat-dialog-content>
  
