import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { UserInfo } from '../model/userinfo.model';
import { UserData } from '../model/userdata.model';
import { UserService } from '../service/user.service';
import { AuthService } from '../service/auth.service';
import { ToasterService } from '../service/toaster.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const PasswordStrengthValidator: ValidatorFn = (fg: FormGroup) => {
        const password = fg.get('password').value;
        const matchedCase = [];
        matchedCase.push('[$@$!%*#?&]');
        matchedCase.push('[A-Z]');
        matchedCase.push('[a-z]');
        matchedCase.push('[0-9]');

        let ctr = 0;
        for (const m of matchedCase) {
            if (new RegExp(m).test(password)) {
                ctr++;
            }
        }

        switch (ctr) {
            case 0:
            case 1:
            case 2:
                // Weak password
                return null;
            case 3:
                // Medium password
                return null;
                break;
            case 4:
                // Strong password
                return null;
                break;
        }

        return { complexity: true };
};

const PasswordMatchValidator: ValidatorFn = (fg: FormGroup) => {
    if (fg) {
        const p1 = fg.get('password').value;
        const p2 = fg.get('passwordconfirm').value;

        return p1 === p2 ? null : { match: true };
    } else {
        return null;
    }
};

@Component({
    selector: 'app-new-user-signup',
    templateUrl: './new-user-signup.component.html',
    styleUrls: ['./new-user-signup.component.css']
})

export class NewUserSignupComponent implements OnInit {
    newUserForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: [''],
        company: [''],
        phone: [''],
        password: ['', [Validators.required, Validators.minLength(8)] ],
        passwordconfirm: ['', [Validators.required, Validators.minLength(8)] ],
    },
    {
        validator: [ PasswordMatchValidator, PasswordStrengthValidator ]
    });
    newUser: boolean;
    changeStatus: string;
    inputDelay: any;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private http: HttpClient,
        private auth: AuthService,
        private usersvc: UserService,   
        private toast: ToasterService
    ) { }

    ngOnInit(): void {
        if (localStorage.getItem('signup')) {
            this.newUser = true;
        }
    }

    async onSubmit() {
        // localStorage.removeItem('signup');
        // this.newUser = false;

        const user = this.auth.getTokenInfo();
        const formdata = this.newUserForm.value;
        const newdata = {
            name: formdata.firstName,
            last_name: formdata.lastName,
            email: formdata.email,
            phone: formdata.phone,
            company: formdata.company,
            password: formdata.password
        };
        let x = null;
        try { 
            x = await this.usersvc.updateUserInfoPromise( user.uid, newdata );
            this.toast.pop('success', 'Update User', 'User information updated.');
            localStorage.removeItem('signup');
        } catch (e) {
                this.toast.pop('error', 'Update User', 'Failed to update user information.');   
          }
          
        /******
        this.http.put(environment.apiUrl + '/user/' + user.uid, newdata).subscribe(
            (x) => {
                this.toast.pop('success', 'Update User', 'User information updated.');
                localStorage.removeItem('signup');
                this.newUser = false;
            },
            (e) => {
                this.toast.pop('error', 'Update User', 'Failed to update user information.');   
            }
        );
        ******/ 
    }

    clear_status(event) {
        this.changeStatus = '';
        document.getElementById('change-status').style.color = 'black';
    }

    password_match(event) {
        const el = document.getElementById('change-status');
        this.newUserForm.controls.passwordconfirm.setValue(event.target.value);

        if (event.target.value === this.newUserForm.value.password) {
            el.style.color = 'black';
            this.changeStatus = '';
        } else {
            el.style.color = 'red';
            this.changeStatus = 'Passwords do not match';
        }
    }

    validate_password(event) {
        if (this.inputDelay) {
            clearTimeout(this.inputDelay);
        }

        this.inputDelay = setTimeout(() => {

            const password = event.target.value;
            if (password.length === 0) {
                // Show nothing when empty
                this.changeStatus = '';
                return;
            }
            const matchedCase = [];
            matchedCase.push('[$@$!%*#?&]');
            matchedCase.push('[A-Z]');
            matchedCase.push('[a-z]');
            matchedCase.push('[0-9]');

            let ctr = 0;
            for (const m of matchedCase) {
                if (new RegExp(m).test(password)) {
                    ctr++;
                }
            }

            let color = '';
            let strength = 'Password Strength: ';
            switch (ctr) {
                case 0:
                case 1:
                case 2:
                    strength += 'Very Weak';
                    color = 'red';
                    break;
                case 3:
                    strength += 'Medium';
                    color = 'orange';
                    break;
                case 4:
                    strength += 'Strong';
                    color = 'green';
                    break;
            }

            this.changeStatus = strength;
            document.getElementById('change-status').style.color = color;
            // if (strength !== 'Password Strength: Strong') {
            //     console.log('error');
            //     this.newUserForm.controls['password'].setErrors({complexity: true})
            // }
        }, 1000);

    }
}
