import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent {
  @Input() userInfo;

  constructor(
      private http: HttpClient
  ) { }

  changeImg( x: number) {
    switch (x) {
      case 1 :
        if (window.innerHeight > window.innerWidth) {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-1.svg)';
        } else {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-1-landscape.svg)';
        }
        document.getElementById('button-left').style.opacity = '0';
        document.getElementById('button-right').style.opacity = '1';
        document.getElementById('vertical-tittle').textContent = 'Save Time';
        document.getElementById('subtittle').textContent = 'Save time by correcting on the go';
        break;

      case 2 :
        if (window.innerHeight > window.innerWidth) {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-2.svg)';
        } else {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-2-landscape.svg)';
        }
        document.getElementById('button-left').style.opacity = '1';
        document.getElementById('button-right').style.opacity = '1';
        document.getElementById('vertical-tittle').textContent = 'Share your Ideas';
        document.getElementById('subtittle').textContent = 'Instantly share and receive comments, issues and questions on shared documents';
        break;

      case 3 :
        if (window.innerHeight > window.innerWidth) {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-3.svg)';
        } else {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-3-landscape.svg)';
        }
        document.getElementById('button-left').style.opacity = '1';
        document.getElementById('button-right').style.opacity = '0';
        document.getElementById('close-button').style.opacity = '1';
        document.getElementById('close-button2').style.opacity = '1';
        document.getElementById('vertical-tittle').textContent = 'Work Together';
        document.getElementById('subtittle').textContent = 'Set up your teams and stay connected wherever you are';
        break;

      default:
        if (window.innerHeight > window.innerWidth) {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-1.svg)';
        } else {
          document.getElementById('card').style.backgroundImage = 'url(/assets/screen-1-landscape.svg)';
        }
        document.getElementById('vertical-tittle').textContent = 'Save Time';
        document.getElementById('subtittle').textContent = 'Save time and paper by correcting documents on the go';
        break;
    }
  }

  close() {
    document.getElementById('container').style.display = 'none';
    this.userInfo.new_user = false;
    this.http.post(environment.apiUrl + '/user/' + this.userInfo.uid, JSON.stringify({ new_user: false }))
        .subscribe(
            (x) => {
                // console.log(x),
            },
            (e) => console.log(e)
        );
  }

  panLeft() {
    const tittle = document.getElementById('vertical-tittle').textContent;
    const dotOne = document.getElementById('page-link1') as HTMLInputElement;
    const dotTwo = document.getElementById('page-link2') as HTMLInputElement;
    switch (tittle) {
      case 'Save Time':
        dotOne.checked = true;
        break;
      case 'Share your Ideas':
        this.changeImg(1);
        dotOne.checked = true;
        break;
      case 'Work Together':
        this.changeImg( 2 );
        dotTwo.checked = true;
        break;
    }
  }

  panRight() {
    const tittle = document.getElementById('vertical-tittle').textContent;
    const dotTwo = document.getElementById('page-link2') as HTMLInputElement;
    const dotThree = document.getElementById('page-link3') as HTMLInputElement;
    switch (tittle) {
      case 'Save Time':
        this.changeImg(2);
        dotTwo.checked = true;
        break;
      case 'Share your Ideas':
        this.changeImg(3);
        dotThree.checked = true;
        break;
      case 'Work Together':
        dotThree.checked = true;
        break;
      default:
        this.changeImg(2);
        break;
    }
  }
}
