import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { OrgUserData } from '../org/model/orguserdata.model';
import { OrgUserInfo } from '../org/model/orguserinfo.model';
import { OrgUserExpirations } from '../org/model/orguserexpirations.model';
import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';
import { StatData } from '../model/statdata.model';
import { AvgData } from '../model/avgdata.model';

import {AuthService} from './auth.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'any'
})

export class OrgUserInfoService {

  baseUrl = environment.apiUrl;

  // static usersvc = new UserService();

  currentOrgUser: OrgUserData = null;
  currentOrgInfo: OrgUserInfo = null;  

  public OrgUserInfo: OrgUserInfo = null;
  public OrgUserInfoList: OrgUserInfo[] = null;

  public OrgUserExpirations: OrgUserExpirations = null;

  public OrgUserInfoCnt: CountData = null;
  public OrgUserInfoGuestCnt: CountData = null;

  public OrgUserInfoSystemCnt: CountData = null;
  public OrgUserInfoGuestSystemCnt: CountData = null;

  private orgUserInfoListItems$: BehaviorSubject<OrgUserInfo[]> = new BehaviorSubject<OrgUserInfo[]>(this.OrgUserInfoList);

  private orgUserInfoItem$: BehaviorSubject<OrgUserInfo> = new BehaviorSubject<OrgUserInfo>(this.OrgUserInfo);

  orgUserInfoList$: Observable<OrgUserInfo[]> = this.orgUserInfoListItems$.asObservable();

  orgUserInfo$: Observable<OrgUserInfo> = this.orgUserInfoItem$.asObservable();

  userData: UserData = null;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toast: ToasterService,
    private usersvc: UserService,
    ) {
        this.userData = this.auth.getTokenInfo();
    }


  getAllOrgUsersInfo$(){
   this.http.get<OrgUserInfo[]> (this.baseUrl + '/orguser/info')
    .subscribe( res => { this.orgUserInfoListItems$.next(res);
                         console.log('all org users =', res);
                       });
  }

  getAllOrgUsersInfo(){
   return this.http.get<OrgUserInfo[]> (this.baseUrl + '/orguser/info');
  }

  getOrgUsersInfo$(oid: string){
   this.http.get<OrgUserInfo[]> (this.baseUrl + '/orguser/info/org/' + oid)
    .subscribe( res =>
                      {
                       this.OrgUserInfoList = res;
                       this.orgUserInfoListItems$.next(res);
                       console.log('org-user-list =', res);
                      });
  }

  getOrgUserInfo$(uid: string){
   return this.http.get<OrgUserInfo> (this.baseUrl + '/orguser/info/' + uid);
    /*****
    .subscribe( res =>
                      {
                       this.OrgUserInfo = res;
                       this.orgUserInfoItem$.next(res);
                       console.log('org-user-info =', res);
                       return res;
                      });
    *****/
  }

  getOrgUsersPromise(oid: string){
    return this.http.get<OrgUserInfo[]> (this.baseUrl + '/orguser/info/org/' + oid)
    .toPromise()
    .then( res => { console.log('org-user-list = ', res);
                    this.OrgUserInfoList = res;
                    this.orgUserInfoListItems$.next(res);
                    return res as OrgUserInfo[];
                  });
  }

  getUserOrgList$(uid: string){
   this.http.get<OrgUserInfo[]> (this.baseUrl + '/orguser/info/user/' + uid)
    .subscribe( res => { this.orgUserInfoListItems$.next(res);
                         console.log('svc org user info list =', res);
                       });
  }

  getOrgUserByOrgAndUid$(oid: string, uid: string){
   this.http.get<OrgUserInfo> (this.baseUrl + '/orguser/info/org-user/' + oid + '/' + uid)
    .subscribe( res =>
                      { this.orgUserInfoItem$.next(res);
                        console.log('org user by org = ', res);
                        return res;
                      });
  }

  getOrgUserByOrgAndEmail$(oid: string, email: string){
   this.http.get<OrgUserInfo> (this.baseUrl + '/orguser/info/org-email/' + oid + '/' + email)
    .subscribe( res =>
                      { this.orgUserInfoItem$.next(res);
                        console.log('org user by org = ', res);
                      });
  }

 getOrgUserInfoByOrgAndUidPromise(oid: string, uid: string){
   const url = this.baseUrl + '/orguser/info/org-user/' + oid + '/' + uid;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgUserInfo> (this.baseUrl + '/orguser/info/org-user/' + oid + '/' + uid).toPromise()
    .then( res => { return res;
                    console.log('org user by oid, uid = ', res);
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid,uid: ', e);
                    throw e;
                  }
    );
  }

 getOrgUserInfoByOrgAndEmailPromise(oid: string, email: string){
   const url = this.baseUrl + '/orguser/info/org-user/' + oid + '/' + email;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgUserInfo> (this.baseUrl + '/orguser/org-email/' + oid + '/' + email).toPromise()
    .then( res => {
                    console.log('org user by email, res = ', res);
                    return res;
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid, email: ', e);
                    throw e;
                  }
    );
  }

  getOrgUserExpirationsPromise(uid: string){
    return this.http.get<OrgUserExpirations> (this.baseUrl + '/orguser/info/expirations/' + uid)
    .toPromise()
    .then( res => { console.log('org-user-info-expirations = ', res);
                    // this.OrgUserInfoExpirations = res;
                    return res as OrgUserExpirations;
                  });
  }


  /*******
  async addUserAccount(user_email: string) {
     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // Add the org from orguserdata (json) on the server
     let usr = await this.usersvc.getUserByEmailPromise(user_email);
        if ( !usr || (usr && Object.keys(usr).length===0) ) {
          const uinfo = {
           'email': user_email,
           'new_user': true,
           'notify': true,
           'accounts': 0,
           'free': false,
           'expires': snm,
           'created': snow,
           'modified': snow,
           'modified_by': this.userData.uid,
          }
          usr = await this.usersvc.addUserInfo(uinfo);
        }
        console.log('orgusersvc add account usr: ', usr);
        return usr;
  }

   addListItem(new_data: any) {
     this.OrgUserList.push(new_data);
     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgUserList = Object.assign([], this.OrgUserList);
   }

  async addOrgUserPromise(orguserdata: OrgUserData) {
     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     let usr = await this.usersvc.getUserByEmailPromise(orguserdata.org_user_email);
     if ( !usr || (usr && Object.keys(usr).length===0) ) {
        usr = await this.addUserAccount(orguserdata.org_user_email);
     }
     orguserdata.org_user_uid = usr.uid;
     orguserdata.created = snow;
     orguserdata.modified = snow;
     orguserdata.timestamp = epoch_time;

     const jdata = JSON.stringify( orguserdata );
     return this.http.post(environment.apiUrl + '/orguser', jdata )
     .toPromise()
     .then(
           (x : OrgUserData) => {
                console.log('orgusersvc add orguser x=: ', x);
                this.addListItem( x );
                this.orgUserListItems$.next( this.OrgUserList );		
                this.orgUserItem$.next( x );
                return x as OrgUserData;
            },
            (e) => {
                console.log('orgusersvc failed to create org: ', jdata,
                            ' error:', e);
                throw e;
            }
      );
   }

   updateListItem(new_data: any) {
     console.log('orgusersvc updateListItem new_data=: ', new_data);
     console.log('orgusersvc updateListItem OrgUserList=: ', this.OrgUserList);
     if ( this.OrgUserList ) {
        const idx = this.OrgUserList.findIndex(obj => obj.uid === new_data.uid);
        this.OrgUserList[idx] = new_data;
        console.log('orgusersvc updateListItem idx=: ', idx);
        console.log('orgusersvc updateListItem old_data=: ', this.OrgUserList[idx]);
     }

     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgUserList = Object.assign([], this.OrgUserList);
   }

   async updateOrgUserPromise( uid: string, orguserdata: OrgUserData ) {
        // Add the org from orgdata (json) on the server
        const jdata = JSON.stringify( orguserdata );
        return this.http.put(environment.apiUrl + '/orguser/' + uid, jdata )
        .toPromise()
        .then(
            (x : OrgUserData) => {
                console.log('orgusersvc edit org x=: ', x);
                // Next update the OrgUserList item
                this.updateListItem(x);
                // Update List Behavior Subject
                this.orgUserListItems$.next( this.OrgUserList );
                // Update BehaviorSubject	
                this.orgUserItem$.next( x );	
                return x as OrgUserData;
            },
            (e) => {
                console.log('orgusersvc failed to edit org: ', jdata,
                            ' error:', e);
                throw e;
            }
        );
   }

  removeListItem(del_uid: any) {
    const idx = this.OrgUserList.findIndex(obj => obj.uid === del_uid);
    if ( idx ) {
       this.OrgUserList.splice(idx, 1);
       this.OrgUserList = Object.assign([], this.OrgUserList);
    }
  }

  // Remove Org User
  removeOrgUserPromise(uid): any {
    // remove orguser record
    return this.http.delete(environment.apiUrl + '/orguser/' + uid)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgusrsvc remove uid=: ', uid);
                  console.log('orgusrsvc remove x=: ', );		  
                  this.removeListItem(uid);
                  this.orgUserListItems$.next( this.OrgUserList );
                  return x;
              },
              (e) => {
                console.error('orgusrsvc remove org user uid =', uid);	      
                console.error('orgusrsvc remove org user error=', e);		
                throw e;
             }
        );
  }
  *****/

} // OrgUserInfoService