import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { PDFDocument, PDFPage, PDFImage, popGraphicsState, pushGraphicsState,
         concatTransformationMatrix, degrees, grayscale, rgb } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  questionColor = '#7D2669';
  commentColor = '#2274A5';
  issueColor = '#F50010';

  xRectOffset = 3;
  yRectOffset = -22;
  rectWidth = 18;
  rectHeight = 22;
  xLineOffsetStart = 12;
  xLineOffsetEnd = 12;
  yLineOffsetStart = -22;
  yLineOffsetEnd = -26;
  xTextOffset = 5;
  yTextOffset = -8;

  async mergePdfDoc(buffer1, buffer2) {

      // load buffer 1
      const pdfFile = await PDFDocument.load(buffer1);
      let viewerPrefs = pdfFile.catalog.getOrCreateViewerPreferences();
      // console.log('buffer1 fw=', viewerPrefs.getFitWindow());
      viewerPrefs.setFitWindow(true);
      // console.log('buffer1 set fw=', viewerPrefs.getFitWindow());

      // load buffer 2
      const pdfFile2 = await PDFDocument.load(buffer2);
      const pdfCopiedPages = await pdfFile.copyPages(pdfFile2, pdfFile2.getPageIndices());
      pdfCopiedPages.forEach(async (page) => await pdfFile.addPage(page));

      viewerPrefs = pdfFile.catalog.getOrCreateViewerPreferences();
      console.log('buffer2 fw=', viewerPrefs.getFitWindow());

      return pdfFile;
  }

  async mergePdf(buffer1, buffer2) {
     // returns uint8Array
     const doc = await this.mergePdfDoc(buffer1, buffer2);
     const pdf = await doc.save();
     return pdf;
  }

  async mergePdfAsBase64(buffer1, buffer2) {
     const doc = await this.mergePdfDoc(buffer1, buffer2);
     const pdf = await doc.saveAsBase64();
     return pdf;
  }

  async annotatePdf(doc, noteData) {
      // load document
      const pdfFile = await PDFDocument.load(doc);
      const viewerPrefs = pdfFile.catalog.getOrCreateViewerPreferences();
      viewerPrefs.setFitWindow(true);

      const pdfPages = pdfFile.getPages();
      // console.log('pdfPages=', pdfPages);
      // console.log('annotate noteData=', noteData);

      if ( noteData.question ) {
         this.drawMarkers(pdfPages, noteData.question);
      }
      if ( noteData.note ) {
         this.drawMarkers(pdfPages, noteData.note);
      }
      if ( noteData.issue ) {
         this.drawMarkers(pdfPages, noteData.issue);
      }

      const pdf = await pdfFile.save();
      return pdf;
  }

  drawMarkers(pdfPages, data) {
     // console.log('addMarkers data=', data);
     let r = 0;
     let g = 0;
     let b = 0;
     let i = 1;
     // console.log('type, id, page, x, y');
     data.forEach( m => {
         switch ( m.type ) {
            case 'question':
                   m.id =  i;
                   r = 0.49;
                   g = 0.15;
                   b = 0.42;
                   this.drawNote( pdfPages[m.pageNbr - 1], m.type, m.id,
                                  m.pdfPageX, m.pdfPageY, r, g, b );
                   break;

            case 'note':
                   m.id =  i;
                   r = 0.134,
                   g = 0.455;
                   b = 0.65;
                   this.drawNote( pdfPages[m.pageNbr - 1], m.type, m.id,
                                  m.pdfPageX, m.pdfPageY, r, g, b );
                   break;

            case 'issue':
                   m.id =  i;
                   r = 0.96;
                   g = 0.0;
                   b = 0.06;
                   this.drawNote( pdfPages[m.pageNbr - 1], m.type, m.id,
                                  m.pdfPageX, m.pdfPageY, r, g, b );
                   break;
         } // switch
         i = i + 1;
     // console.log(m.type + ',' + m.id + ',' + m.pageNbr + ',' +
     //             m.pdfPageX + ',' + m.pdfPageY);
     });
  }

  drawNote( pg, type, id, pdfX, pdfY, cr, cg, cb ) {
    let txt = '';
    let spc = '';
    if ( id < 10 ) {
       spc = '  ';
    }
    if ( id < 100 && id >= 10 ) {
       spc = ' ';
    }
    switch ( type ) {
       case 'question':
             txt = '  Q\n';
             break;
       case 'note':
             txt = '  C\n';
             break;
       case 'issue':
             txt = '  I\n';
             break;
    }
    txt = txt + spc + id;
    pg.drawRectangle({
         x: pdfX + this.xRectOffset,
         y: pdfY + this.yRectOffset,
         width: this.rectWidth,
         height: this.rectHeight,
         rotate: degrees(0),
         borderWidth: 1,
         borderColor: rgb(cr, cg, cb),
         color: rgb(cr, cg, cb),
         opacity: 0.25,
         borderOpacity: 0.5,
    });
    pg.drawLine({
         start: { x: pdfX + this.xLineOffsetStart,
                  y: pdfY + this.yLineOffsetStart },
         end: { x: pdfX + this.xLineOffsetEnd,
                y: pdfY + this.yLineOffsetEnd },
         thickness: 1,
         color: rgb(cr, cg, cb),
         opacity: 0.75,
    });
    pg.drawText(
         txt,
        {
         x: pdfX + this.xTextOffset,
         y: pdfY + this.yTextOffset,
         size: 8,
         color: rgb(cr, cg, cb),
         lineHeight: 8,
         opacity: 0.9,
        },
    );
  }

} // PdfService
