import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';

//import { OrgUserData } from '../org/model/orguserdata.model';
//import { OrgUserInfo } from '../org/model/orguserinfo.model';
import { OrgContactData } from '../org/model/orgcontactdata.model';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';
import { StatData } from '../model/statdata.model';
import { AvgData } from '../model/avgdata.model';

import {AuthService} from './auth.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'any'
})

export class OrgContactService {

  baseUrl = environment.apiUrl;

  currentOrgContact: OrgContactData = null;

  public OrgContact: OrgContactData = null;
  public OrgContactList: OrgContactData[] = null;

  public OrgContactCnt: CountData = null;
  public OrgContactSystemCnt: CountData = null;

  private orgContactListItems$: BehaviorSubject<OrgContactData[]> = new BehaviorSubject<OrgContactData[]>(this.OrgContactList);

  private orgContactItem$: BehaviorSubject<OrgContactData> = new BehaviorSubject<OrgContactData>(this.OrgContact);

  orgContactList$: Observable<OrgContactData[]> = this.orgContactListItems$.asObservable();

  orgContactData$: Observable<OrgContactData> = this.orgContactItem$.asObservable();

  private orgContactCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgContactCnt);
  orgContactCount$: Observable<CountData> = this.orgContactCountSubj$.asObservable();

  private orgContactSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgContactSystemCnt);
  orgContactSystemCount$: Observable<CountData> = this.orgContactSystemCountSubj$.asObservable();


  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toast: ToasterService,
    // private usersvc: UserService,
    ) {
        // this.userData = this.auth.getTokenInfo();
    }

  getAllOrgContacts$(){
   this.http.get<OrgContactData[]> (this.baseUrl + '/orgcontact')
    .subscribe( res => { this.orgContactListItems$.next(res);
                         console.log('all org users =', res);
                       });
  }

  getAllOrgContacts(){
   return this.http.get<OrgContactData[]> (this.baseUrl + '/orgcontact');
  }

  getOrgContactList$(oid: string){
   this.http.get<OrgContactData[]> (this.baseUrl + '/orgcontact/org/' + oid)
    .subscribe( res =>
                      {
                       // console.log('org-contact-list res=', res);
                       // console.log('org-contact-list len=', res.length);
                       // console.log('org-contact-list type =', typeof(res));
                       // console.log('org-contact-list array =', this.OrgContactList instanceof Array );
                       this.OrgContactList = res;
                       this.orgContactListItems$.next(this.OrgContactList);
                      });
  }

  getOrgContact$(uid: string){
   return this.http.get<OrgContactData> (this.baseUrl + '/orgcontact/' + uid);
    /*****
    .subscribe( res =>
                      {
                       this.OrgContact = res;
                       this.orgUserItem$.next(res);
                       console.log('org-user-list =', res);
                       return res;
                      });
    *****/
  }

  getOrgContactListPromise(oid: string){
    return this.http.get<OrgContactData[]> (this.baseUrl + '/orgcontact/org/' + oid)
    .toPromise()
    .then( res => { console.log('org-contact-list = ', res);
                    this.OrgContactList = res;
                    this.orgContactListItems$.next(res);
                    return res as OrgContactData[];
                  });
  }

  /****
  getOrgContactByOrgAndEmail$(oid: string, email: string){
   this.http.get<OrgContactData> (this.baseUrl + '/orguser/org-email/' + oid + '/' + email)
    .subscribe( res =>
                      { this.orgUserItem$.next(res);
                        console.log('org user by org = ', res);
                      });
  }

 getOrgContactByOrgAndUidPromise(oid: string, uid: string){
   const url = this.baseUrl + '/orguser/org-user/' + oid + '/' + uid;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgContactData> (this.baseUrl + '/orguser/org-user/' + oid + '/' + uid).toPromise()
    .then( res => { return res;
                    console.log('org user by oid, uid = ', res);
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid,uid: ', e);
                    throw e;
                  }
    );
  }

 getOrgContactByOrgAndEmailPromise(oid: string, email: string){
   const url = this.baseUrl + '/orguser/org-user/' + oid + '/' + email;
   //console.log('get orguser oid uid = ', url);
   return this.http.get<OrgContactData> (this.baseUrl + '/orguser/org-email/' + oid + '/' + email).toPromise()
    .then( res => {
                    console.log('org user by email, res = ', res);
                    return res;
                  },
           (e) => {
                    console.error('orgusersvc: get orguser by oid, email: ', e);
                    throw e;
                  }
    );
  }
  ****/

   addListItem(new_data: any) {
     // console.log('orgcontactsvc addListItem1 OrgContactList=', this.OrgContactList);
     // console.log('org-contact-list addListItem type =', typeof(this.OrgContactList));
     // console.log('org-contact-list addListItem array =', this.OrgContactList instanceof Array );
     
     this.OrgContactList.push(new_data);
     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgContactList = Object.assign([], this.OrgContactList);
     // console.log('orgcontactsvc addListItem2 OrgContactList=', this.OrgContactList);
   }

  async addOrgContactPromise(orgcontactdata: OrgContactData) {
     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     orgcontactdata.created = snow;
     orgcontactdata.modified = snow;
     orgcontactdata.timestamp = epoch_time;

     const jdata = JSON.stringify( orgcontactdata );
     return this.http.post(environment.apiUrl + '/orgcontact', jdata )
     .toPromise()
     .then(
           (x : OrgContactData) => {
                console.log('orgcontactsvc add orguser x=: ', x);
                this.addListItem( x );
                this.orgContactListItems$.next( this.OrgContactList );
                this.orgContactItem$.next( x );
                return x as OrgContactData;
            },
            (e) => {
                console.log('orgcontactsvc failed to create contact: ', jdata,
                            ' error:', e);
                throw e;
            }
      );
   }

   updateListItem(new_data: any) {
     console.log('orgcontactsvc updateListItem new_data=: ', new_data);
     console.log('orgcontactsvc updateListItem OrgContactList=: ', this.OrgContactList);
     if ( this.OrgContactList ) {
        const idx = this.OrgContactList.findIndex(obj => obj.uid === new_data.uid);
        this.OrgContactList[idx] = new_data;
        console.log('orgcontactsvc updateListItem idx=: ', idx);
        console.log('orgcontactsvc updateListItem old_data=: ', this.OrgContactList[idx]);
     }

     // some angular libraries require breaking the array reference
     // to pick up the update in the array and trigger change detection.
     // In that case, you can do fo
     this.OrgContactList = Object.assign([], this.OrgContactList);
   }

   async updateOrgContactPromise( uid: string, orgcontactdata: OrgContactData ) {
        // Add the org contact from orgcontactdata (json) on the server
        const jdata = JSON.stringify( orgcontactdata );
        return this.http.put(environment.apiUrl + '/orgcontact/' + uid, jdata )
        .toPromise()
        .then(
            (x : OrgContactData) => {
                console.log('orgcontctsvc edit org x=: ', x);
                // Next update the OrgContactList item
                this.updateListItem(x);
                // Update List Behavior Subject
                this.orgContactListItems$.next( this.OrgContactList );
                // Update BehaviorSubject
                this.orgContactItem$.next( x );
                return x as OrgContactData;
            },
            (e) => {
                console.log('orgcontactsvc failed to edit org: ', jdata,
                            ' error:', e);
                throw e;
            }
        );
   }

  removeListItem(del_uid: any) {
    const idx = this.OrgContactList.findIndex(obj => obj.uid === del_uid);
    if ( idx ) {
       this.OrgContactList.splice(idx, 1);
       this.OrgContactList = Object.assign([], this.OrgContactList);
    }
  }

  // Remove Org User
  removeOrgContactPromise(uid): any {
    // remove orguser record
    console.log('orgcontactsvc remove uid', uid);
    return this.http.delete(environment.apiUrl + '/orgcontact/' + uid)
        .toPromise()
        .then(
              (x) => {
                  console.log('orgcontactsvc remove uid=: ', uid);
                  console.log('orgcontactsvc remove x=: ', x);
                  this.removeListItem(uid);
                  this.orgContactListItems$.next( this.OrgContactList );
                  return x;
              },
              (e) => {
                console.error('orgcontactsvc remove org user uid =', uid);
                console.error('orgcontactsvc remove org user error=', e);
                throw e;
             }
        );
  }

  getOrgContactCount$(oid: string){
   this.http.get<CountData> (this.baseUrl + '/orgcontact/count/org' + oid)
    .subscribe( cnt => { console.log('org contact cnt =', cnt);
                         this.orgContactCountSubj$.next(cnt);
                         return cnt as CountData;
                       });
  }

  getOrgContactSystemCount$(){
   this.http.get<CountData> (this.baseUrl + '/orgcontact/count/system')
    .subscribe( cnt => { console.log('org contact system cnt =', cnt);
                         this.orgContactSystemCountSubj$.next(cnt);
                         return cnt as CountData;
                       });
  }


} // OrgContactService
