// CJ - Dialog to show help from local assets/help files or remote URLs
//
import { Component, OnInit, Inject, ViewChild, ElementRef, Output,
         EventEmitter, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HelpService } from '../../service/help.service';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

@Component({
    selector: 'app-help-dialog',
    templateUrl: './help.dialog.html',
    styleUrls: ['./help.dialog.css']
})

export class HelpDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('content', {static: true}) content: ElementRef;

    helpContext: number;
    helpContent: any;

    constructor(
          private helpsvc: HelpService,
          private dialogRef: MatDialogRef<HelpDialogComponent>,
          @Inject(MAT_DIALOG_DATA) public data
          ) {
              if (data) {
                this.helpContext = data.context;
              }
              console.log('HelpDialog data=', data);
            }

    ngOnInit() {
    }

    async ngAfterViewInit() {
        this.content.nativeElement.innerHTML = '<br>Loading...<br>';
        try {
           this.helpContent = await this.helpsvc.getHelpContent(this.helpContext);
        } catch (e) {
             this.helpContent = '<h4>ERROR: No help available for this context ' + this.helpContext + ' e=' + e;
             console.log('help ERROR: no help available for this context! ctx=' + this.helpContext + ' e=' + e);
        }
        this.content.nativeElement.innerHTML = this.helpContent;
        console.log('help: helpContent:', this.helpContent);
        console.log('help: content:', this.content);
	this.scrollTop();
    }

    close() {
      // User canceled or quit operation
      if ( this.dialogRef ) {
      
         this.dialogRef.close();
      }
    }

    save() {
      // bundle the intent and data
      this.close();
    }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }


}
