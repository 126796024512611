<p id="title">Guest Accounts</p>
<div class='addsub-content'>
  <form #addform="ngForm" [formGroup]="newsub" (submit)="addSub()">
        <div class='sub_add_button'>
            <div class='email-input'>
                <mat-form-field style='width: 100%' appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="user@domain.com">
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button type="submit">Add Guest</button>
            </div>
        </div>
    </form>
    <div class='sub_add_button'>
        <div class='email-input'>
            Used: {{ getAccounts().length }}
        </div>
        <div>
            {{ userInfo.accounts }} available
        </div>
       <button mat-raised-button
            (click)="refresh_subs()"
	    style="margin-left: 1rem">Refresh</button>
    </div>
    <form *ngIf="getAccounts().length" [formGroup]="subs">
            <div formArrayName="accounts" *ngFor="let account of subs.get('accounts').value; let i = index;">
                <mat-list class='subaccount-list'>
                    <mat-list-item class='subaccount-list-item'>{{account.email}}</mat-list-item>
                    <mat-icon class='subaccount-item-icon' (click)="openRemoveSubaccountDialog(userInfo.uid, i)">remove_circle</mat-icon>
                </mat-list>
            </div>
    </form>
</div>
