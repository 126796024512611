
<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
   <mat-icon style="margin-left: 0; padding-left: 0;">drag_indicator</mat-icon>
   <span class="title" style="align-self: flex-start;">User Settings</span>
  </div>
  <div class="dialog-header-right">  
  <button #settingsMgrMoreBtn mat-icon-button class="icon-menu-button"
	  [matMenuTriggerFor]="settingsMgrMenu"
	  style="align-self: flex-start; align: top; margin-right:0;">
          <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #settingsMgrMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="helpDialog()"
          matTooltip="Manage Groups Help"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>help_outline</mat-icon>Help
      </button>
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(0)"
          matTooltip="Edit User Information"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>account_circle</mat-icon>Edit User Information
      </button>
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(1)"
          matTooltip="Change Password"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>https</mat-icon>Password Reset
      </button>
      <button mat-menu-item id="user-info" (click)="setSelectedTabIndex(2)"
          matTooltip="Terms of Service"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>assistant_photo</mat-icon>View Terms of Service
      </button>
      <button mat-menu-item id="close-x" (click)="close()"
          matTooltip="Close Settings Manager"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>close</mat-icon>Close Settings Manager
      </button>
  </mat-menu>
  <button mat-icon-button id="close-x" (click)="close()"
          matTooltip="Close Settings Manager"
          style="margin-left: 0.2rem; align-self: flex-top;">
          <mat-icon>close</mat-icon>
   </button>
  
  </div>
</div>

<mat-dialog-content id="settings" #content>

   <app-settings-dialog class="left-content-scroll"
         style="margin-left: 1rem; width: 60vw; max-width: 100vw; height: 90%; margin-top: 0; padding-top: 0;">
   </app-settings-dialog>

</mat-dialog-content>

<mat-dialog-actions>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center; border-top: 1px solid #1A567A; width: 100%" >

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%; font-size: 0.8rem">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>
  </button>

  
 </div>
  
</mat-dialog-actions>
