// CJ - Dialog to show marker annotation report
//
import { Component, OnInit, Inject, ViewChild, ElementRef, Output,
         EventEmitter, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';

import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MarkerListComponent } from '../../marker-list/marker-list.component';

import * as HELP from '../../service/help.service';

export const ERR_NONE         = 0;
export const ERR_NO_INTENT    = 101;
export const BUTTON_1         = 1;
export const BUTTON_2         = 2;
export const BUTTON_3         = 3;

@Component({
    selector: 'app-marker-report-dialog',
    templateUrl: './marker-report.dialog.html',
    styleUrls: ['./marker-report.dialog.css']
})

export class MarkerReportDialogComponent implements OnInit, AfterViewInit {
    @Output() mrdScrollToNoteEvent = new EventEmitter<any>();
    @Output() mrdScrollToPageEvent = new EventEmitter<any>();
    @Output() displayUserInfoEvent = new EventEmitter<any>();
    @Output() helpEvent = new EventEmitter<any>();
    @Output() mrdPdfEvent = new EventEmitter<any>();

    @ViewChild('paginator', {static: true}) paginator: MatPaginator;
    @ViewChild('content', {static: true}) content: ElementRef;
    @ViewChild('markerList', {static: true}) markerList: MarkerListComponent;

    template: any;
    noteData: any;
    userInfo: any;
    documentUID: any;
    documentName: string;

    constructor(
          private dialogRef: MatDialogRef<MarkerReportDialogComponent>,
          @Inject(MAT_DIALOG_DATA) public data
          ) {
              if (data) {
                this.template = data.template;
                this.noteData = data.noteData;
                this.userInfo = data.userInfo;
                this.documentUID = data.documentUID;
                this.documentName = data.documentName;
              }
              console.log('MarkerReportDialog data=', data);
            }

    ngOnInit() {
    }

    async ngAfterViewInit() {
    }

    close() {
      // User canceled or quit operation
      if ( this.dialogRef ) {
         this.dialogRef.close();
      }
    }

    save() {
      // bundle the intent and data
      this.close();
    }

   scrollTop() {
     if ( this.content.nativeElement ) {
           this.content.nativeElement.scrollTop = 0;
     }
   }

   scrollBottom() {
     if (this.content.nativeElement) {
       const el = this.content.nativeElement;
       el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
     }
   }

   scrollToNote(e) {
     this.mrdScrollToNoteEvent.emit(e);
   }

   scrollToPage(e) {
     console.log('mrd page=', e);
     this.mrdScrollToPageEvent.emit(e);
   }

   displayUserInfo(e) {
     this.displayUserInfoEvent.emit(e);
   }

   printPDF() {
      this.markerList.printPDF();
   }

   downloadPDF() {
      this.markerList.downloadPDF();
   }

   async getPDF() {
      const pdf = await this.markerList.getPDF();
      return pdf;
   }

   async getPDFArray() {
      const pdf = await this.markerList.getPDFArray();
      return pdf;
   }

   emitPDF(e) {
      console.log('mrd pdf=', e);
      this.mrdPdfEvent.emit(e);
   }

   helpDialog() {
      this.helpEvent.emit(HELP.MARKER_REPORT);
   }
}
