<div class="new-user-email-form">
    <div class="content">
        <form id='email-signup' [formGroup]='emailSignupForm' (ngSubmit)="onSubmit()">
            <div>
                <img src="/assets/Markadoc-logo-200TR.png" id="img-logo" alt="Mark a doc logo">
            </div>
            <p>Please enter your email address to sign up for a free account.</p>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="text" formControlName="email" autocomplete="off" required>
            </mat-form-field>

            <button id='send-email' mat-button type="submit" [disabled]="!emailSignupForm.valid">
                Sign up
            </button>
            <div class="invalid-notice" *ngIf="emailSignupForm.hasError('required')">
                Email address is required.
            </div>
            <div class="invalid-notice" *ngIf="emailSignupForm.get('email')">
                <div *ngIf="emailSignupForm.get('email').hasError('email')">
                    Email address must be valid.
                </div>
            </div>
            <div style="display: flex; justify-content: center">
                <div id='cap' style='margin-left: auto; margin-right: auto' class="g-recaptcha" data-sitekey="6LeqnJscAAAAANRSjH3EJJ_SGt5mvCSkExWuM8P4"></div>
            </div>
        </form>
    </div>
</div>
