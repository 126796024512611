<div mat-dialog-title class="dialog-header"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="dialog-header-left">
    <mat-icon>drag_indicator</mat-icon>  
    <span id="action-title" style="color: #1A567A; align-self: flex-start;">Document Information</span>
  </div>
  <div class="dialog-header-right">
      <mat-icon id="close-x" class="dialog-title"
	      (click)="close()">close</mat-icon>
   </div>
 </div>

  <mat-dialog-content class="panel-class" style="margin-top: 1rem;">
    <div style="margin-left: 0rem; margin-bottom: 0.25rem; border-bottom: 1px solid #1A567A;"></div>
  <div class="row">
    <div style="font-size: 1em; color: black">Name:</div>
    <div *ngIf="documentInfo && documentInfo.size"
         style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">
      {{documentInfo.name}}</div>
  </div>
  <!--
  <div class="row">
    <div style="font-size: 1em; color: black">File Name:</div>
    <div *ngIf="userInfo && userInfo.company" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{documentInfo.filename}}</div>    
  </div>
  -->
  <div class="row">
    <div style="font-size: 1em; color: black">Description:</div>
    <div *ngIf="userInfo && userInfo.company" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{documentInfo.description}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Owner:</div>
    <div *ngIf="userInfo && userInfo.name"
	 (click)="displayUserInfo(userInfo.uid)"
	 style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.name}}</div>
    <div *ngIf="userInfo && userInfo.last_name"
	 (click)="displayUserInfo(userInfo.uid)"
	 style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.last_name}}</div>        
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Email:</div>
    <div *ngIf="userInfo && userInfo.email"
	 (click)="displayUserInfo(userInfo.uid)"
	 style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{userInfo.email}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Group:</div>
    <div *ngIf="group && group.name" style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">{{group.name}}</div>    
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Size:</div>
    <div *ngIf="documentInfo && documentInfo.size"
         style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">
      {{documentInfo.size}}</div>
  </div>
  <div class="row">
    <div style="font-size: 1em; color: black">Accessed:</div>    
    <div *ngIf="documentInfo && documentInfo.timestamp"
	 style="margin-left: 0.5rem; font-size: 1em; color: #1A567A;">
      {{lastAccessDt}}</div>
  </div>
  
</mat-dialog-content>
  
<mat-dialog-actions style="align: end">
  <button mat-raised-button class="bottom-buttons"
	  (click)='this.close()'>Close</button>
  
</mat-dialog-actions>    
