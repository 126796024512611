<!-- org-contact.component.html -->
<mat-menu #objMenu="matMenu">
  <button mat-menu-item enabled (click)="setSelectedObj(); viewObj();">
    <mat-icon>contact_mail</mat-icon>              
    <span style="font-size: 0.9em">View Details</span>
  </button>
  <button *ngIf="(iam && userData.iam) || (isObjAdmin)"
	  mat-menu-item enabled (click)="editObj()">
          <mat-icon>edit</mat-icon>  
          <span style="font-size: 0.9em">Edit {{objLabel}}</span>
  </button>
  <button *ngIf="(iam && userData.iam) || (isObjAdmin)"
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon>        
          <span style="font-size: 0.9em">Add New {{objLabel}}</span>
  </button>
  <button *ngIf="(iam && userData.iam) || (isObjAdmin)"
	  mat-menu-item enabled (click)="deleteObj()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

  
<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<ng-template #notax>
  <span style="margin-left: 1em">Non-Taxable</span>
</ng-template>

<!-- border: 1px solid #1A567A;  -->
<div>

<div #org *ngIf="template === DisplayTemplate.FIELD_NAME">
 <div *ngIf="(uid && objData$ | async) as obj">
        <mat-form-field class="form-field" [floatLabel]="'always'" appearance="">	  
           <mat-label class="form-label">{{nameLabel}}</mat-label>
        <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>
           <input matInput placeholder=""
                  type="text" name="name" class="form-control"
		  [value]="obj.org_contact_email">
        </mat-form-field>
  </div>	
</div>

<div #org *ngIf="template === DisplayTemplate.SIDENAV_NAME">
  <div *ngIf="objData">
    <span class="sidenav-name">{{objData.org_contact_email}}</span>
  </div>
</div>

<div #org *ngIf="template === DisplayTemplate.LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 70vw !important;">
  <div #orgItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em;" (click)="titleAction()">
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName" style="display: flex; flex-flow: row; align-content: center; font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em; justify-content: space-between;">
    <div style="align-self: flex-start">
        <mat-icon style="margin-right: 1rem; background: #1A567A; color: white; padding-top: 0em;">contact_mail</mat-icon>    		
	{{objData.org_contact_email}}
    </div>
    <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align: end; align-self: end; font-size: 1.5rem; padding-top 0.5em; padding-bottom: 0.5em; padding-left: 0em; padding-right: 0em; background: #1A567A; color: white;">
	  check
    </mat-icon>
    </span>
    
    <ng-template #defaultObjName>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em;">
        <mat-icon style="margin-top: 1rem; margin-right: 1rem;">contact_mail</mat-icon>    			
         {{objData.org_contact_email}}
       </span>
    </ng-template>
    <button *ngIf="showDeleteButton && (isObjAdmin || (userData.iam && iam))"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.SELECT_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
 <!--    <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em">contact_mail</mat-icon>  -->
    <span *ngIf="selectedObj && selectedObj.uid === objData.uid; else slNotSelected"
	  style="font-size 1.5rem; font-weight: bold; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon style="margin-top: 0.8rem; margin-right: 1rem; background: #1A567A; color: white;">contact_mail</mat-icon>     	      
      {{objData.org_contact_email}}
    </span>
    <ng-template #slNotSelected>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon style="margin-top: 0.8rem; margin-right: 1rem; background: #1A567A; color: white;">contact_mail</mat-icon>     	
      {{objData.org_contact_email}}
    </span>
    </ng-template>
    <mat-icon *ngIf="selectedObj && selectedObj.uid === objData.uid"
	      style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: white; color: #1A567A;">
	check
    </mat-icon>
    <button *ngIf="showDeleteButton && (isObjAdmin || iam && userData.iam)"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.EXPAND_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;" (click)="setSelectedObj()">
  <div #orgitem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="setSelectedObj()">
    <button mat-stroked-button
            (click)="toggleDetail()"
      style="margin-right: 1.5em; color: white; background-color: #2274A5;">
      <mat-icon>expand_more</mat-icon>
    </button>
    <span *ngIf="orgData && selectedOrg && selectedOrg.uid === orgData.uid; else defaultOrgName"
          style="font-size 1rem; font-weight: bold; color: #2274A5; width: 100%">
          {{objData.org_contact_email}}
    </span>
    <ng-template #defaultOrgName>
       <span style="font-size: bold; font-weight: normal; color: black; width: 100%" (click)="setSelectedObj()"> 
         {{objData.org_contact_email}}
       </span>
    </ng-template>
  </div>

  <div #objItemButtons (click)="setSelectedObj()"
       style="display: flex; flex-flow: row; justify-content: space-around; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em;">
  <mat-icon style="color: #2274A5; align-self: flex-end;" *ngIf="selectedObj && selectedObj.uid === objData.uid">check</mat-icon>    
 </div>
</div>

<!-- border: 1px solid #1A567A;  -->
<div #objdetail *ngIf="template === DisplayTemplate.DETAIL"
     style="display: flex; flex-flow: column; justify-content: space-between; align-items: space-between; width: 90% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="toggleDetail()">
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
    <button *ngIf="this.mode === DisplayMode.LIST || this.mode === DisplayMode.EXPAND_LIST"
	  mat-stroked-button
          (click)="toggleDetail()"
          style="margin-right: 1.5em;">
    <mat-icon>expand_less</mat-icon>
    </button>
    <span style="margin-right: 1rem">{{objData.org_contact_email}}</span>
    <span style="width: 100%;">{{objData.org_contact_type}}</span>    
    <span style="width: 100%;">{{objData.org_contact_name}}</span>
    <span style="width: 100%;">{{objData.org_contact_last_name}}</span>
    <span style="width: 100%;">{{objData.org_contact_phone}}</span>
    <span style="width: 100%;">{{objData.org_contact_mobile_phone}}</span>
    <mat-icon *ngIf="objData && selectedObj && (objData.uid===selectedObj.uid)"
        style="margin-left: 0.5em; margin-right: 0.4em; color: #2274A5">
	check
    </mat-icon>
  </div>
  <span style="margin-left: 1.5em; width: 100%"></span><br>
 <div style="display: flex; flex-flow: column; margin-left: 0.5em; margin-right: 1em; border: 1px solid #1A567A; width: 95%% !important;">  
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
  </div>
  <div style="display:flex; flex-flow: column; justify-content: space-between; margin-left: 0.5em">
    <span style="">Contact</span>
    <span style="">Email :{{objData.org_owner_email}}</span>
    <span style="">Name  : {{objData.org_owner_name}}</span>
    <span style="">Phone : {{objData.org_owner_phone}}</span>
    <span style="">Mobile: {{objData.org_owner_mobile_phone}}</span>
    <span style="">Fax   : {{objData.org_owner_fax}}</span><br>            
    <span style="">Addr  : {{objData.org_addr}}</span>
    <span style="">Addr2 : {{objData.org_addr_2}}</span>
    <span style="">City  : {{objData.org_city}}</span>
    <span style="">State : {{objData.org_state}}</span>
    <span style="">Zip: {{objData.org_postal_code}}</span>                    
  </div>
  <span style=""></span><BR>
 </div>
</div>

<div #orgform *ngIf="template===DisplayTemplate.FORM">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

  <div *ngIf="showFormTitle">
   <div class="form-title" *ngIf="isAddMode">Create {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.EDIT">Edit {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.VIEW">View {{objLabel}}</div>  
  </div>


  <form id="objForm" [formGroup]="objForm">

   <!--
   <mat-tab-group style="overflow: hidden !important;">
    
    <mat-tab class="form-tab" label="Info">
      -->
      
     <ng-template mat-tab-label>
        <mat-icon>contact_mail</mat-icon>
	Setup
    </ng-template>
     
     <mat-form-field class="form-field"
		     [appearance]="formFieldStyle" [style.display]="'none'"
		     [floatLabel]="'always'">
<!--- [class.cdk-visually-hidden]="!showHidden" [style.display]="'none'" [floatLabel]="'always'">    -->
      <mat-label class="form-label">UID</mat-label>      
      <input matInput placeholder="UID" [hidden]="false" 
             type="text" name="uid" class="form-control" formControlName="uid">
    </mat-form-field>

     <mat-form-field class="form-field"
		     [appearance]="formFieldStyle" [class.cdk-visually-hidden]="!showHidden"
		     [style.display]="'none'" [floatLabel]="'always'">
      <mat-label class="form-label">Org UID</mat-label>      
      <input matInput placeholder="Org UID" [hidden]="!showHidden" 
             type="text" name="org_uid" class="form-control" formControlName="org_uid">
    </mat-form-field>

     <mat-form-field [formGroup]="objForm" class="form-field"
		     [appearance]="formFieldStyle"
		     [floatLabel]="'always'">
      <mat-label class="form-label">Contact Type</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">contact_mail</mat-icon>            
      <mat-select class="form-select"
	 name="org_contact_type"	
         formControlName="org_contact_type"
         placeholder="Select Contact Type"
	 [required]="'true'">
         <mat-option class="form-option"
		    *ngFor="let type of contactTypes" [value]="type.code">
           {{type.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_contact_type.touched || submitted) &&
                   objForm.controls.org_contact_type.errors?.required">
           Contact Type is Required!</span>
    </mat-error><br>


    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Email</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter Email Address"
             type="email" name="org_contact_email" class="form-control" formControlName="org_contact_email">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_email.touched || submitted) && 
               (objForm.controls.org_contact_email.errors?.required)">
	Contact Email is Required</span>
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter First Name"
             type="text" name="org_contact_name" class="form-control" formControlName="org_contact_name">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_name.touched || submitted) && 
               (objForm.controls.org_contact_name.errors?.required)">
	Contact First Name is Required</span>	
    </mat-error><br>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Last Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter Last Name"
             type="text" name="org_contact_last_name" class="form-control" formControlName="org_contact_last_name">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_last_name.touched || submitted) &&
               (objForm.controls.org_contact_last_name.errors?.required)">
	Contact Last Name is Required</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Phone Nbr.</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter Phone Nbr."
             type="tel" name="org_contact_phone" class="form-control" formControlName="org_contact_phone">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_phone.touched || submitted) &&
               (objForm.controls.org_contact_phone.errors?.required ||
                objForm.controls.org_contact_phone.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Mobile Phone</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter Mobile Phone Nbr."
             type="tel" name="org_contact_mobile_phone" class="form-control" formControlName="org_contact_mobile_phone">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_mobile_phone.touched || submitted) &&
               (objForm.controls.org_contact_mobile_phone.errors?.required ||
                objForm.controls.org_contact_mobile_phone.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Fax Nbr.</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter Fax Nbr."
             type="tel" name="org_contact_fax" class="form-control" formControlName="org_contact_fax">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_fax.touched || submitted) &&
               (objForm.controls.org_contact_fax.errors?.required ||
                objForm.controls.org_contact_fax.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Address</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>
      <input matInput placeholder="Address"
             type="text" name="org_contact_addr" class="form-control" formControlName="org_contact_addr">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_contact_addr.touched || submitted) && 
                objForm.controls.org_contact_addr.errors?.required">
	Address is required.</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Address Line 2</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Address Line 2"
             type="text" name="org_contact_addr_2" class="form-control" formControlName="org_contact_addr_2">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_contact_addr_2.touched || submitted) && 
                objForm.controls.org_contact_addr_2.errors?.required">
	Address line 2 is required.</span>	
    </mat-error>


    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">City</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="City"
             type="text" name="org_contact_city" class="form-control" formControlName="org_contact_city">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_contact_city.touched || submitted) 
               && objForm.controls.org_contact_city.errors?.required">
	Please enter city for the main location.</span>	
    </mat-error>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">State/Province/Region</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Enter State/Province"
             type="text" name="org_contact_state" class="form-control" formControlName="org_contact_state">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_state.touched || submitted) && 
                objForm.controls.org_contact_state.errors?.required">
	State/Province/Region is Required.</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Zip/Postal Code</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">contact_mail</mat-icon>      
      <input matInput placeholder="Zip Code/Postal Code"
             type="text" name="org_contact_postal_code" class="form-control" formControlName="org_contact_postal_code">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_postal_code.touched || submitted) 
               && objForm.controls.org_contact_postal_code.errors?.required">
	Postal Code Required.</span>	
    </mat-error>

    <mat-form-field [formGroup]="objForm" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Country</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">contact_mail</mat-icon>            
      <mat-select class="form-select"
	 name="org_contact_country_code"	
         formControlName="org_contact_country_code"
         placeholder="Select a Country"
	 [required]="'true'">
         <mat-option class="form-option"
	    *ngFor="let country of countryCodes" [value]="country.code">
           {{country.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_contact_country_code.touched 
                   || submitted) &&
                   objForm.controls.org_contact_country_code.errors?.required">
           Country is Required!</span>
    </mat-error><br>


    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Contact Notes</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Notes"
             type="text" name="org_contact_notes" class="form-control" formControlName="org_contact_notes">
    </mat-form-field>
    <!---
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_contact_last_name.touched || submitted) &&
               (objForm.controls.org_contact_last_name.errors?.required)">
	Contact Last Name is Required</span>	
    </mat-error>
    --->

  <div *ngIf="showActions" class="form-bottom-div">      
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more fields and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
<!--       *ngIf="objData && userData && getIsObjAdmin(objData, userData) && (mode === DisplayMode.VIEW)" class="submit-btn" -->
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>    

    <!--
    </mat-tab>
    
    <mat-tab class="form-tab" label="Users">
      <ng-template mat-tab-label>
       <mat-icon>manage_accounts</mat-icon>
       {{objLabel}} Users
      </ng-template>
         <app-org-user-list #orgUserList
	   style="width: 100%"
           [mode]="'S'"
	   [userInfo]="userInfo"
           [userData]="userData"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showMenuButton]="true"
           [showListViewButton]="true"			    
           [selectedOrg]="selectedObj"
	   >
       </app-org-user-list>
    </mat-tab>

    <mat-tab class="form-tab" label="Info">
      <ng-template mat-tab-label>
       <mat-icon>contact_mail</mat-icon>
       Contacts
      </ng-template>
    </mat-tab>

  </mat-tab-group>
  -->
  <!---
  <div class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>
  --->
  </form>
 </div>
</div>

 <!-- Delete Template -->
 <div #orgDelete *ngIf="template === DisplayTemplate.DELETE">
        <div *ngIf="selectedObj; else noObjSelected"
	     class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Delete Company/Org. Contact {{selectedObj.org_contact_email}}
	  </span>
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
	<ng-template #noObjSelected>
	  <div class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Error: No Company/Org. Contact selected!
	  </span>
          </div>
	</ng-template>
        <div class="form-btn-div">
             <button mat-stroked-button id="cancel-delete-obj" class="normal-btn" type="button" value="Cancel" (click)="onCancel()">Cancel</button>	  
             <button mat-stroked-button *ngIf="selectedObj" id="delete-obj" class="delete-btn" type="submit" value="Yes" (click)="deleteObjData(selectedObj.uid)">Yes</button>
        </div>
 </div>

<!-- org component -->    
